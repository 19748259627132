const getPrefix = (action: string): string => `ToastActionTypes.${action}`;

const Success = getPrefix('Success');

const Error = getPrefix('Error');

const Warning = getPrefix('Warning');

const Info = getPrefix('Info');

const Log = getPrefix('Log');

const ToastActionTypes = {
  Success,
  Error,
  Warning,
  Info,
  Log,
};

export default ToastActionTypes;
