import { flow, set } from 'lodash/fp';

import {
  IOpenOpportunitiesDrillDownFilters,
  IOpenOpportunitiesModel,
  OpenOpportunitiesDrillDownTabsTypes,
  OpenOpportunitiesDropDownOptionsTypes,
} from './types';
import {
  DefaultGroupByOption,
  direction,
  HierarchyLevel,
  IActionType,
  IDrillDownHierarchy,
  OpenOpportunitiesCardTypes,
  SIZE,
} from 'types';
import OpenOpportunitiesActionTypes from './openOpportunitiesActionTypes';
import {
  accumulateGroupContentAndGroupCount,
  getOpenOpportunitiesCountByWeek,
  getOpportunities,
  getSettings,
  getUserScope,
  initOpenOpportunitiesSelectedWeeks,
  parseOpenOpportunitiesDrillDownCards,
  parseStoreBreakDown,
} from 'store/api/apiParser';
import {
  checkIsOpportunityLeaf,
  checkIsStoreLeaf,
  getAvailableStoreHierarchyLevels,
  getCurrentHierarchyName,
  getDropDownOptions,
  getSelectedDropdownOption,
  getTopHierarchy,
  getTopHierarchyPath,
} from './openOpportunitiesHelper';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';
import SettingsActionTypes from 'store/settings/settingsActionTypes';
import { isEmptyArray } from 'utils';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

export const initialState: IOpenOpportunitiesModel = {
  opportunitiesCountByWeek: null,
  selectedWeeks: [-1, -1],
  storeBreakDown: [
    { status: OpenOpportunitiesCardTypes.NO_OPPORTUNITIES, count: null },
    { status: OpenOpportunitiesCardTypes.NO_RESPONSE, count: null },
    { status: OpenOpportunitiesCardTypes.IN_PROGRESS, count: null },
    { status: OpenOpportunitiesCardTypes.COMPLETED, count: null },
  ],
  selectedCard: OpenOpportunitiesCardTypes.RESPONSE_RATE,
  drillDown: {
    list: {
      cards: undefined,
      opportunities: undefined,
      totalCount: 0,
      highestValue: 0,
      nextPageOffset: 0,
      hasMore: false,
    },
    navigationTabs: {
      [OpenOpportunitiesDrillDownTabsTypes.OTHER]: 0,
      [OpenOpportunitiesDrillDownTabsTypes.BY_STORE]: 0,
      [OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY]: 0,
    },
    dropDownOptions: [
      OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE,
      OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES,
      OpenOpportunitiesDropDownOptionsTypes.INVESTIGATED_OPPORTUNITIES,
    ],
    hierarchyPath: [],
    hierarchyDepth: 1,
    topHierarchy: { index: 1, id: '' },
    availableStoreHierarchyLevels: {},
    totalFindingsByReasons: 0,
    filters: {
      searchValue: '',
      sortDirection: direction.DESC,
      hierarchyIndex: 0,
      selectedDropdownOption: OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE,
      selectedTab: OpenOpportunitiesDrillDownTabsTypes.OTHER,
    },
  },
};

const openOpportunitiesReducer = (
  state: IOpenOpportunitiesModel = initialState,
  action: IActionType,
) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    case SettingsActionTypes.getSettings.SUCCESS: {
      const settings = getSettings(payload);

      const hierarchyDepth = settings.storeHierarchyDepth;

      const stateToPush = [];

      // if no store hierarchy - set selected tab to be By Store
      if (hierarchyDepth === 0) {
        stateToPush.push(
          set('drillDown.filters.selectedTab', OpenOpportunitiesDrillDownTabsTypes.BY_STORE),
        );
      }

      stateToPush.push(set('drillDown.hierarchyDepth', hierarchyDepth));
      stateToPush.push(
        set(
          `drillDown.navigationTabs[${OpenOpportunitiesDrillDownTabsTypes.BY_STORE}]`,
          hierarchyDepth + 1,
        ),
      );
      stateToPush.push(
        set(
          `drillDown.navigationTabs[${OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY}]`,
          hierarchyDepth + 2,
        ),
      );

      return flow(stateToPush)(state);
    }
    case UserProfileActionTypes.getUserScope.SUCCESS: {
      const userProfile = getUserScope(payload);

      const topHierarchy = getTopHierarchy(userProfile);

      const availableStoreHierarchyLevels = getAvailableStoreHierarchyLevels(
        userProfile.availableStoreHierarchyLevels,
      );

      const { hierarchyPath, filters } = state.drillDown;

      const { hierarchyIndex } = filters;

      const stateToPush = [];

      if (hierarchyIndex === 0) {
        stateToPush.push(set('drillDown.filters.hierarchyIndex', topHierarchy.index));
      }

      if (hierarchyPath.length === 0) {
        const path = getTopHierarchyPath(topHierarchy, availableStoreHierarchyLevels);
        stateToPush.push(set('drillDown.hierarchyPath', [path]));
      }

      stateToPush.push(set('drillDown.topHierarchy', topHierarchy));
      stateToPush.push(
        set('drillDown.availableStoreHierarchyLevels', availableStoreHierarchyLevels),
      );
      stateToPush.push(
        set(
          `drillDown.navigationTabs[${OpenOpportunitiesDrillDownTabsTypes.OTHER}]`,
          topHierarchy.index,
        ),
      );

      return flow(stateToPush)(state);
    }

    case OpenOpportunitiesActionTypes.getOpenOpportunities.PENDING: {
      const { selectedCard } = state;

      const { selectedTab } = state.drillDown.filters;

      const updatedDropdownOptions = getDropDownOptions(selectedTab, selectedCard);

      return flow([
        set('drillDown.dropDownOptions', updatedDropdownOptions),
        set('drillDown.list.cards', undefined),
        set('drillDown.list.opportunities', undefined),
      ])(state);
    }

    case OpenOpportunitiesActionTypes.getOpenOpportunities.FAILURE: {
      return flow([set('drillDown.list.cards', null), set('drillDown.list.opportunities', null)])(
        state,
      );
    }

    case OpenOpportunitiesActionTypes.getOpenOpportunities.SUCCESS: {
      const { rows = [] } = payload;

      const result = getOpenOpportunitiesCountByWeek(rows);

      const selectedWeeks = initOpenOpportunitiesSelectedWeeks(state.selectedWeeks, result.length);

      const { cards, opportunities } = state.drillDown.list;

      const stateToPush = [];

      if (cards === undefined) {
        stateToPush.push(set('drillDown.list.cards', null));
      }

      if (opportunities === undefined) {
        stateToPush.push(set('drillDown.list.opportunities', null));
      }

      stateToPush.push(set('opportunitiesCountByWeek', result));
      stateToPush.push(set('selectedWeeks', selectedWeeks));

      return flow(stateToPush)(state);
    }

    case OpenOpportunitiesActionTypes.getStoreBreakdown.SUCCESS: {
      const { rows = [] } = payload;

      const newStoreBreakdown = parseStoreBreakDown(rows);

      return flow(set('storeBreakDown', newStoreBreakdown))(state);
    }

    case OpenOpportunitiesActionTypes.getOpportunities.SUCCESS: {
      const { page, productImagesMetadata } = requestPayload;

      const { from } = page;

      const { rows, totalCount = 0 } = payload;

      const { opportunities, nextPageOffset } = state.drillDown.list;

      let prevOpportunities = opportunities;

      if (!prevOpportunities || from === 0) {
        prevOpportunities = {
          list: [],
          groupContent: [],
          groupCount: [],
        };
      }

      const {
        opportunities: currentOpportunitiesList,
        cardsGroupHeader,
        cardsGroupCount,
      } = getOpportunities(rows, DefaultGroupByOption, productImagesMetadata);

      const { groupContent, groupCount } = accumulateGroupContentAndGroupCount(
        prevOpportunities.groupCount,
        prevOpportunities.groupContent,
        cardsGroupCount,
        cardsGroupHeader,
      );

      const list = [...prevOpportunities.list, ...currentOpportunitiesList];

      const updatedOpportunities = { list, groupContent, groupCount };

      const hasMore = list.length < totalCount;

      const pageOffset = hasMore ? nextPageOffset + SIZE : nextPageOffset;

      return flow([
        set(`drillDown.list.opportunities`, updatedOpportunities),
        set(`drillDown.list.hasMore`, hasMore),
        set(`drillDown.list.totalCount`, list.length),
        set(`drillDown.list.isLoading`, false),
        set(`drillDown.list.nextPageOffset`, pageOffset),
        set(`drillDown.list.cards`, null),
        set(`drillDown.list.highestValue`, 0),
      ])(state);
    }

    case OpenOpportunitiesActionTypes.getFindingsReasons.SUCCESS: {
      const { queryStats = {} } = payload;

      const issuesFound = queryStats?.issueFoundTotalCount;

      return set(`drillDown.totalFindingsByReasons`, issuesFound, state);
    }

    case OpenOpportunitiesActionTypes.getFilteredDrillDownItems.SUCCESS: {
      const { totalCount = 0, rows = [] } = payload;

      const { page } = requestPayload;

      const { from } = page;

      const { selectedDropdownOption } = state.drillDown.filters;

      const { cards: prevCards, nextPageOffset } = state.drillDown.list;

      const { cards: currentCards, highestValue } = parseOpenOpportunitiesDrillDownCards(
        rows,
        selectedDropdownOption,
      );

      const newCards = from === 0 ? currentCards : [...(prevCards || []), ...currentCards];

      const hasMore = newCards.length < totalCount;

      const pageOffset = hasMore ? nextPageOffset + SIZE : nextPageOffset;

      return flow(
        set('drillDown.list.hasMore', hasMore),
        set('drillDown.list.nextPageOffset', pageOffset),
        set('drillDown.list.totalCount', newCards.length),
        set(`drillDown.list.isLoading`, false),
        set(`drillDown.list.highestValue`, highestValue),
        set('drillDown.list.cards', newCards),
        set('drillDown.list.opportunities', null),
      )(state);
    }

    case OpenOpportunitiesActionTypes.addHierarchyFilter: {
      const { card } = payload;

      const {
        hierarchyPath,
        filters,
        navigationTabs,
        availableStoreHierarchyLevels,
      } = state.drillDown;

      const { hierarchyIndex, selectedTab, selectedDropdownOption: prevDropdownOption } = filters;

      const newIndex = hierarchyIndex + 1;

      const lastHierarchyLevel: IDrillDownHierarchy = {
        name: card.level,
        value: card.storeId ? card.storeId : card.level,
        hierarchyLevel: newIndex,
        hierarchyName: availableStoreHierarchyLevels[newIndex] || HierarchyLevel.STORES,
      };

      const stateToPush = [];

      const newPath = [...hierarchyPath, lastHierarchyLevel];

      stateToPush.push(set(`drillDown.hierarchyPath`, newPath));
      stateToPush.push(set(`drillDown.filters.hierarchyIndex`, newIndex));

      const isStoreLeaf = checkIsStoreLeaf(
        newIndex,
        navigationTabs[OpenOpportunitiesDrillDownTabsTypes.BY_STORE],
      );

      const isOpportunityLeaf = checkIsOpportunityLeaf(
        newIndex,
        navigationTabs[OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY],
      );

      let nextTab = selectedTab;

      // if store leaf or opportunities leaf increase tab
      if (isStoreLeaf || isOpportunityLeaf) {
        const { selectedCard } = state;

        nextTab = selectedTab + 1;

        const updatedOptions = getDropDownOptions(nextTab, selectedCard);

        const selectedOptionIndex = getSelectedDropdownOption(
          updatedOptions,
          selectedCard,
          prevDropdownOption,
          nextTab,
        );
        stateToPush.push(set(`drillDown.filters.selectedTab`, nextTab));
        stateToPush.push(
          set(`drillDown.filters.selectedDropdownOption`, updatedOptions[selectedOptionIndex]),
        );
        stateToPush.push(set(`drillDown.dropDownOptions`, updatedOptions));
      }

      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.OPEN_OPPORTUNITIES_DRILL_DOWN_HIERARCHY, {
        hierarchy: getCurrentHierarchyName(nextTab, newPath),
      });

      return flow(stateToPush)(state);
    }

    case OpenOpportunitiesActionTypes.selectCard: {
      const { cardType } = payload;

      const { selectedTab } = state.drillDown.filters;

      const updatedOptions = getDropDownOptions(selectedTab, cardType);

      const selectedOptionIndex = getSelectedDropdownOption(updatedOptions, cardType);
      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.SELECT_OPEN_OPPORTUNITIES_CARD, {
        card_type: cardType,
      });

      return flow(
        set(`selectedCard`, cardType),
        set(`drillDown.dropDownOptions`, updatedOptions),
        set(`drillDown.filters.selectedDropdownOption`, updatedOptions[selectedOptionIndex]),
      )(state);
    }

    case OpenOpportunitiesActionTypes.clearFilters: {
      const { topHierarchy, hierarchyDepth, availableStoreHierarchyLevels } = state.drillDown;

      const selectedCard = OpenOpportunitiesCardTypes.RESPONSE_RATE;

      const selectedTab =
        hierarchyDepth === 0
          ? OpenOpportunitiesDrillDownTabsTypes.BY_STORE
          : OpenOpportunitiesDrillDownTabsTypes.OTHER;

      const dropDownOptions = getDropDownOptions(selectedTab, selectedCard);

      const clearFilters: IOpenOpportunitiesDrillDownFilters = {
        selectedTab,
        selectedDropdownOption: dropDownOptions[0],
        searchValue: '',
        hierarchyIndex: topHierarchy.index,
        sortDirection: direction.DESC,
      };

      const hierarchyPath = getTopHierarchyPath(topHierarchy, availableStoreHierarchyLevels);

      return flow(
        set(`drillDown.hierarchyPath`, [hierarchyPath]),
        set(`drillDown.filters`, clearFilters),
        set(`drillDown.dropDownOptions`, dropDownOptions),
        set(`selectedCard`, OpenOpportunitiesCardTypes.RESPONSE_RATE),
      )(state);
    }

    case OpenOpportunitiesActionTypes.changeDateRange: {
      const { selectedWeeks = [] } = payload;

      return set('selectedWeeks', selectedWeeks, state);
    }

    case OpenOpportunitiesActionTypes.updateSearchFilter: {
      const { searchValue = '' } = payload;

      return set('drillDown.filters.searchValue', searchValue, state);
    }

    case OpenOpportunitiesActionTypes.sliceHierarchyFilter: {
      const { hierarchyPath, topHierarchy, navigationTabs } = state.drillDown;

      const { selectedDropdownOption: prevDropdownOption } = state.drillDown.filters;

      const { itemIndex = 1 } = payload;

      // get updated hierarchy index
      const newHierarchyIndex = hierarchyPath[itemIndex].hierarchyLevel || topHierarchy.index;

      // get updated hierarchy path
      const updateHierarchyPath =
        itemIndex === hierarchyPath.length - 1
          ? [...hierarchyPath]
          : hierarchyPath.slice(0, itemIndex + 1);

      const stateToPush = [];

      stateToPush.push(set(`drillDown.filters.hierarchyIndex`, newHierarchyIndex));
      stateToPush.push(set(`drillDown.hierarchyPath`, updateHierarchyPath));

      const isStoreLeaf = checkIsStoreLeaf(
        newHierarchyIndex,
        navigationTabs[OpenOpportunitiesDrillDownTabsTypes.BY_STORE],
      );

      const isOpportunityLeaf = checkIsOpportunityLeaf(
        newHierarchyIndex,
        navigationTabs[OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY],
      );

      // decrease tab
      if (!isOpportunityLeaf) {
        const { selectedCard } = state;

        const newTab = isStoreLeaf
          ? OpenOpportunitiesDrillDownTabsTypes.BY_STORE
          : OpenOpportunitiesDrillDownTabsTypes.OTHER;

        const updatedOptions = getDropDownOptions(newTab, selectedCard);

        const selectedOptionIndex = getSelectedDropdownOption(
          updatedOptions,
          selectedCard,
          prevDropdownOption,
          newTab,
        );

        AnalyticSrv.sendSelectEvent(
          AnalyticsEventCategory.OPEN_OPPORTUNITIES_DRILL_DOWN_HIERARCHY,
          {
            hierarchy: getCurrentHierarchyName(newTab, updateHierarchyPath),
          },
        );

        stateToPush.push(set(`drillDown.filters.selectedTab`, newTab));
        stateToPush.push(
          set(`drillDown.filters.selectedDropdownOption`, updatedOptions[selectedOptionIndex]),
        );
        stateToPush.push(set(`drillDown.dropDownOptions`, updatedOptions));
      }

      return flow(stateToPush)(state);
    }

    case OpenOpportunitiesActionTypes.toggleSortDirection: {
      const { sortDirection } = state.drillDown.filters;

      const newDirection = sortDirection === direction.ASC ? direction.DESC : direction.ASC;

      AnalyticSrv.sendSortEvent(true, '', newDirection);

      return set('drillDown.filters.sortDirection', newDirection, state);
    }

    case OpenOpportunitiesActionTypes.selectDropDownOption: {
      const { option = '' } = payload;

      AnalyticSrv.sendSortEvent(false, option);

      return set(`drillDown.filters.selectedDropdownOption`, option, state);
    }

    case OpenOpportunitiesActionTypes.selectTab: {
      const { id = 0 } = payload;

      const { selectedCard } = state;

      const { hierarchyPath, navigationTabs } = state.drillDown;

      if (isEmptyArray(hierarchyPath)) {
        return state;
      }

      const { selectedDropdownOption: prevDropdownOption } = state.drillDown.filters;

      const stateToPush = [];

      const updatedHierarchyIndex = navigationTabs[id];

      stateToPush.push(set(`drillDown.filters.hierarchyIndex`, updatedHierarchyIndex));

      const isLastHierarchyItemAStoreLeaf = checkIsStoreLeaf(
        hierarchyPath[hierarchyPath.length - 1].hierarchyLevel! - 1,
        navigationTabs[OpenOpportunitiesDrillDownTabsTypes.BY_STORE],
      );

      // update hierarchy path
      let updatedHierarchyPath = [...hierarchyPath];
      if (id === OpenOpportunitiesDrillDownTabsTypes.OTHER) {
        updatedHierarchyPath = hierarchyPath.slice(0, 1);
      } else if (
        id === OpenOpportunitiesDrillDownTabsTypes.BY_STORE &&
        isLastHierarchyItemAStoreLeaf
      ) {
        updatedHierarchyPath = hierarchyPath.slice(0, hierarchyPath.length - 1);
      }

      // updated dropdown options
      const updatedOptions = getDropDownOptions(id, selectedCard);

      const selectedOptionIndex = getSelectedDropdownOption(
        updatedOptions,
        selectedCard,
        prevDropdownOption,
        id,
      );

      stateToPush.push(set('drillDown.hierarchyPath', updatedHierarchyPath));
      stateToPush.push(set(`drillDown.filters.selectedTab`, id));
      stateToPush.push(set(`drillDown.dropDownOptions`, updatedOptions));
      stateToPush.push(
        set(`drillDown.filters.selectedDropdownOption`, updatedOptions[selectedOptionIndex]),
      );

      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.OPEN_OPPORTUNITIES_DRILL_DOWN_HIERARCHY, {
        hierarchy: getCurrentHierarchyName(id, updatedHierarchyPath),
      });

      return flow(stateToPush)(state);
    }

    default:
      return state;
  }
};

export default openOpportunitiesReducer;
