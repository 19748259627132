import { QueryParam, QueryParamTypes } from 'routes/types';
import {
  direction,
  IDate,
  ISearchParamsFilters,
  mapDateIntervalsToQueryParams,
  SelectedDateIntervals,
} from 'store/filters/types';
import { DrillDownTypes, IDrillDownHierarchy, ISelectedCategory } from 'types';

const buildDateQueryParams = (date: IDate) => {
  const params: QueryParam[] = [
    {
      key: QueryParamTypes.Date,
      value: mapDateIntervalsToQueryParams[date.type],
    },
  ];

  if (date.type === SelectedDateIntervals.customId) {
    params.push(
      {
        key: QueryParamTypes.From,
        value: date.from,
      },
      {
        key: QueryParamTypes.To,
        value: date.to,
      },
    );
  }

  return params;
};

const buildSelectedTabQueryParams = (tab: string) => {
  const params: QueryParam[] = [
    {
      key: QueryParamTypes.Tab,
      value: tab,
    },
  ];

  return params;
};

const buildIDQueryParams = (value: string, isOpportunityId = false) => {
  const params: QueryParam[] = [
    {
      key: isOpportunityId ? QueryParamTypes.OpId : QueryParamTypes.OID,
      value,
    },
  ];

  return params;
};

const buildDrilldownOrderQueryParams = (direction: direction, drilldownType: DrillDownTypes) => {
  let key = QueryParamTypes.ProductLevelOrder;

  if (drilldownType === DrillDownTypes.Store) {
    key = QueryParamTypes.StoreLevelOrder;
  } else if (drilldownType === DrillDownTypes.Other) {
    key = QueryParamTypes.OtherLevelOrder;
  }

  const params: QueryParam[] = [
    {
      key,
      value: direction,
    },
  ];

  return params;
};

const buildHierarchyPathQueryParams = (
  filters: IDrillDownHierarchy[],
  drilldownType: DrillDownTypes,
) => {
  const params: QueryParam[] = [];

  const level =
    drilldownType === DrillDownTypes.Store
      ? QueryParamTypes.StoreLevel
      : QueryParamTypes.ProductLevel;

  const name =
    drilldownType === DrillDownTypes.Store
      ? QueryParamTypes.StoreLevelName
      : QueryParamTypes.ProductLevelName;

  const value =
    drilldownType === DrillDownTypes.Store
      ? QueryParamTypes.StoreLevelId
      : QueryParamTypes.ProductLevelId;

  filters.forEach((filter, i) => {
    const hierarchyLevel = filter.hierarchyLevel || i + 1;

    params.push(
      {
        key: level + hierarchyLevel,
        value: filter.hierarchyName,
      },
      {
        key: name + hierarchyLevel,
        value: filter.name,
      },
    );

    if (filter.value && filter.value !== filter.name) {
      params.push({
        key: value + hierarchyLevel,
        value: filter.value,
      });
    }
  });

  return params;
};

const buildOtherDrilldownQueryParams = (
  filter: IDrillDownHierarchy,
  category?: ISelectedCategory,
) => {
  const params: QueryParam[] = [];

  if (category) {
    params.push(
      {
        key: QueryParamTypes.CategoryType,
        value: category.type,
      },
      {
        key: QueryParamTypes.CategoryValue,
        value: category.value,
      },
      {
        key: QueryParamTypes.CategoryLabel,
        value: category.label,
      },
    );
  }

  // push id
  if (filter?.value) {
    params.push(...buildIDQueryParams(filter.value));
  }

  // push name if exists and other than id
  if (filter?.name && filter?.name !== filter?.value) {
    params.push({
      key: QueryParamTypes.OtherLevelName,
      value: filter.name,
    });
  }

  return params;
};

const buildDrillDownQueryParams = (
  filters: IDrillDownHierarchy[],
  order: direction,
  drilldownType: DrillDownTypes,
  category?: ISelectedCategory,
): QueryParam[] => {
  const params: QueryParam[] = [];

  // push order
  params.push(...buildDrilldownOrderQueryParams(order, drilldownType));

  switch (drilldownType) {
    case DrillDownTypes.Product:
    case DrillDownTypes.Store: {
      params.push(...buildHierarchyPathQueryParams(filters, drilldownType));
      break;
    }
    case DrillDownTypes.Other: {
      params.push(...buildOtherDrilldownQueryParams(filters[0], category));
      break;
    }
    default:
      break;
  }

  return params;
};

// build query params from search params filters to create query string
export const buildQueryParamsFromSearchFilters = (searchFilters: ISearchParamsFilters) => {
  const { date, cardId, tab, other, product, store } = searchFilters;

  const params: QueryParam[] = [];

  if (date) {
    params.push(...buildDateQueryParams(date));
  }

  if (cardId) {
    params.push(...buildIDQueryParams(cardId, true));
  }

  if (tab) {
    params.push(...buildSelectedTabQueryParams(tab));
  }

  if (other) {
    const { order = direction.DESC, filter, category } = other;

    params.push(
      ...buildDrillDownQueryParams(filter ? [filter] : [], order, DrillDownTypes.Other, category),
    );
  }

  if (store) {
    const { order = direction.DESC, hierarchyPath = [] } = store;
    params.push(...buildDrillDownQueryParams(hierarchyPath, order, DrillDownTypes.Store));
  }

  if (product) {
    const { order = direction.DESC, hierarchyPath = [] } = product;

    params.push(...buildDrillDownQueryParams(hierarchyPath, order, DrillDownTypes.Product));
  }

  return params;
};
