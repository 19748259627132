import './filterControl.scss';
import cn from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SortButtons from 'components/myOpportunities/filters/ordersButtons/sortButtons';
import GroupByButtons from 'components/myOpportunities/filters/ordersButtons/groupByButtons';
import { SearchInput } from 'components/inputs';
import { IFilterControl } from 'components/myOpportunities/filters/types';
import { clearFilters, setFiltersSearch } from 'store/filters/filtersActions';
import FiltersSelector from 'store/filters/filtersSelector';
import { IRootState } from 'types';
import CategoriesAccordion from './categoriesAccordion/categoriesAccordion';

export const FilterControl = ({
  activeConcurrentFilter,
  hasFilterModified,
  clearFilters,
  isRtl,
  isFilterOn,
  setFiltersSearch,
  searchText,
}: IFilterControl) => {
  const [t] = useTranslation();

  const onTextChanged = (textToFilter: string) => {
    setFiltersSearch(textToFilter);
  };

  const isActive = isFilterOn || hasFilterModified;

  return (
    <>
      <div className="filter-search">
        <SearchInput
          id="opportunities-filter-input"
          onTextChanged={onTextChanged}
          defaultValue={searchText}
          isRtl={isRtl}
        />
      </div>
      <div className="filters-orders-groups">
        <div className="filter-total">
          <div className="filter-by">
            <div className="sub-title">
              {t('COMMON.FILTER_BY')} ({activeConcurrentFilter})
            </div>
            <div className="info">{t('MY_OPPORTUNITIES.FILTER.PRIMARY_PRODUCT_CATEGORY')}</div>
          </div>
          <button
            id="opportunities-filter-clear-button"
            className={cn('remove-filter', isActive && 'active')}
            disabled={!isActive}
            onClick={clearFilters}
          >
            {t('COMMON.CLEAR_ALL')}
          </button>
        </div>
        <CategoriesAccordion />
        <SortButtons />
        <GroupByButtons />
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { filters } = state.filters.myOpportunities;

  const { activeConcurrentFilter, hasFilterModified, search: searchText } = filters;

  const { isRtl } = state.settings;

  return {
    activeConcurrentFilter,
    hasFilterModified,
    isRtl,
    searchText,
    isFilterOn: FiltersSelector.isMyOpportunitiesFilterOn(state),
  };
};

const mapDispatchToProps = {
  clearFilters,
  setFiltersSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterControl);
