import { FC, useState } from 'react';
import cn from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UpdatePassword from 'components/settings/updatePassword/updatePassword';
import { SettingIcon } from 'components/Icons';
import { Dropdown } from 'components/dropdown';
import { ILanguage, languages } from 'constants/Languages';
import { IDropdownOption, IRootState } from 'types';
import './settings.scss';
import { updateLanguage } from 'store/settings/settingsActions';

export interface ISettings {
  language?: IDropdownOption;

  updateLanguage(language: string): void;
}

const Settings: FC<ISettings> = ({ language, updateLanguage }) => {
  const [t] = useTranslation();

  const [isPasswordChangeActive, setIsPasswordChangeActive] = useState(false);

  const onPasswordClose = () => setIsPasswordChangeActive(false);

  const onPasswordToggle = () => setIsPasswordChangeActive(!isPasswordChangeActive);

  const onLanguageChange = (language: IDropdownOption) => updateLanguage(language.key);

  return (
    <div className="page">
      <div className="page-header">{t('PAGES.SETTINGS')}</div>
      <div className="page-container">
        <div className={cn('page-list', 'setting-list')}>
          <div className="setting-list-container">
            <div className="setting-list-item">
              <span className="title">{t('SETTINGS.CHOOSE_LANGUAGE')}</span>
              <Dropdown
                onDropDownOpen={onPasswordClose}
                options={languages}
                selectedOption={language}
                onChange={onLanguageChange}
              />
            </div>
            <div className="setting-list-item">
              <span className="title">{t('SETTINGS.ACCOUNT')}</span>
              <div
                className={cn('updateCardContainer', { isActive: isPasswordChangeActive })}
                onClick={onPasswordToggle}
              >
                {t('SETTINGS.CHANGE_PASSWORD')}
              </div>
            </div>
          </div>
        </div>
        <div className="page-preview">
          <div className={cn('settings-preview', { isActive: isPasswordChangeActive })}>
            {isPasswordChangeActive ? <UpdatePassword /> : <SettingIcon />}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { language: languageKey } = state.settings;

  const language = languages.find((lang: ILanguage) => lang.key === languageKey);

  return {
    language,
  };
};

const mapDispatchToProps = {
  updateLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
