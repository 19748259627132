import { IResolvedLegacySearchParams } from 'store/filters/types';
import { QueryParamTypes, RoutePath } from 'routes/types';
import { buildSearchParamsString, getSearchParams } from './NavigationUtils';
import { OpportunitiesTabsOptions } from 'store/myOpportunities/types';
import { getLegacySearchFilters } from 'store/filters/filterHelper';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';

// URLON parser Regex
const keyParseRegexp = /[=:@$]/;

const valueParseRegexp = /[&;]/;

// parse legacy (mobile) query string (URLON parser function)
// this function was copied and refactored from URLON library used in mobile app
// to decode url string
export const parseURLONQueryString = (str: string): IResolvedLegacySearchParams => {
  let pos = 0;

  const decodedStr = decodeURI(str);

  const readToken = (regexp: string | RegExp) => {
    let token = '';

    for (; pos !== decodedStr.length; ++pos) {
      if (decodedStr.charAt(pos) === '/') {
        pos += 1;
        if (pos === decodedStr.length) {
          token += ';';
          break;
        }
      } else if (decodedStr.charAt(pos).match(regexp)) {
        break;
      }
      token += decodedStr.charAt(pos);
    }

    return token;
  };

  const parseToken = () => {
    const type = decodedStr.charAt(pos);
    pos += 1;

    switch (type) {
      case '=': {
        return readToken(valueParseRegexp);
      }
      case ':': {
        let token: any = readToken(valueParseRegexp);
        if (token === 'true') {
          return true;
        }
        if (token === 'false') {
          return false;
        }
        token = parseFloat(token);

        return Number.isNaN(token) ? null : token;
      }

      case '@': {
        const res: any[] = [];

        const loop = () => {
          // empty array
          if (pos >= decodedStr.length || decodedStr.charAt(pos) === ';') {
            return;
          }
          // parse array items
          while (!(pos >= decodedStr.length || decodedStr.charAt(pos) === ';')) {
            res.push(parseToken());
            if (pos >= decodedStr.length || decodedStr.charAt(pos) === ';') {
              return;
            }
            pos += 1;
          }
        };
        loop();
        pos += 1;

        return res;
      }

      case '$': {
        const res: { [key: string]: string } = {};

        const loop = () => {
          if (pos >= decodedStr.length || decodedStr.charAt(pos) === ';') {
            return;
          }
          while (!(pos >= decodedStr.length || decodedStr.charAt(pos) === ';')) {
            const name = readToken(keyParseRegexp);

            res[name] = parseToken();

            if (pos >= decodedStr.length || decodedStr.charAt(pos) === ';') {
              return;
            }
            pos += 1;
          }
        };
        loop();
        pos += 1;

        return res;
      }
      default: {
        // Error
        throw new Error(`Unexpected char ${type}`);
      }
    }
  };

  return parseToken();
};

export const resolveLegacyURLParams = (url: string) => {
  // get query string
  let query = url.split('?')[1];

  // split url path
  const legacyPaths = url.split('/');

  // last path item
  legacyPaths[legacyPaths.length - 1] = legacyPaths[legacyPaths.length - 1].split('?')[0];

  // set route path
  const path = query?.includes('task=') ? RoutePath.MyOpportunities : (legacyPaths[2] as RoutePath);

  // get drill down query
  if (path !== RoutePath.MyOpportunities) {
    query = decodeURIComponent(query?.split('=')[1]);
  }

  // get trends tab
  const tab = path === RoutePath.Trends ? legacyPaths[3] : undefined;

  return { path, tab, query };
};

export const resolveLegacyOpportunityQuery = (query: string) => {
  const searchParams = getSearchParams(query);

  let parsedQuery = '';

  const opId = searchParams.get('task');

  if (opId) {
    parsedQuery += `${QueryParamTypes.OpId}=${opId}`;
  }

  const state = searchParams.get('state');

  if (state) {
    parsedQuery += `&${QueryParamTypes.Tab}=${
      state === 'OPENED' ? OpportunitiesTabsOptions.Open : OpportunitiesTabsOptions.Completed
    }`;
  }

  return parsedQuery;
};

export const resolveLegacyDrillDownQuery = (query: string, selectedTab?: string) => {
  // build search filters from query string
  const searchFilters = getLegacySearchFilters(query);

  if (selectedTab) {
    searchFilters.tab = selectedTab;
  }

  // build search params from filters
  const searchParams = buildQueryParamsFromSearchFilters(searchFilters);

  // build query string from params
  return buildSearchParamsString(searchParams);
};
