import { IRootState } from 'types';

export const getReasonsSelector = (state: IRootState) => state.reasons.groups;

export const getMapReasonIdToTextSelector = (state: IRootState) => state.reasons.mapReasonIdToText;

const ReasonsSelector = {
  getReasonsSelector,
  getMapReasonIdToTextSelector,
};

export default ReasonsSelector;
