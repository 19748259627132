import { createSelector } from 'reselect';

import permissionsService from 'services/permissions/permissionsSrv';
import {
  DrillDownCategoriesTypes,
  DrillDownTypes,
  IDrillDownHierarchy,
  IRootState,
  ISelectedCategory,
  ISettingsModel,
  ITrendsDrillDown,
  ITrendsLineChartRow,
  ITrendsModel,
  SelectedDateIntervals,
} from 'types';
import { getDatesMonthsDiff, getYTDMonths, isEmptyArray } from 'utils';
import i18n from 'i18n/i18n';
import {
  getOtherDrillDownCategories,
  isSettingsLoadedSuccessfully,
} from 'store/settings/settingsHepler';
import {
  buildHitRateData,
  buildResponseRateData,
  getSearch,
  verifyIsFiltersApplied,
} from './trendsHelper';

const getDrillDownFilters = (state: IRootState) => state.trends.filters;

const getTrendsSelector = (state: IRootState) => state.trends;

const getUserProfileSelector = (state: IRootState) => state.userProfile;

const getSettingsSelector = (state: IRootState) => state.settings;

const getResponseRateDataSelector = (state: IRootState) => state.trends.responseRate.data;

const getResponseRateChainWideDataSelector = (state: IRootState) =>
  state.trends.responseRate.chainWideData;

const getHitRateDataSelector = (state: IRootState) => state.trends.hitRate.data;

const getHitRateChainWideDataSelector = (state: IRootState) => state.trends.hitRate.chainWideData;

const getDateSelector = (state: IRootState) => state.trends.filters.date;

const getHierarchyPath = (hierarchyPath: Array<IDrillDownHierarchy>) => {
  const updatedHierarchyPath = [...hierarchyPath];

  // Add all to start
  if (!isEmptyArray(hierarchyPath)) {
    updatedHierarchyPath.unshift({
      name: i18n.t('COMMON.ALL'),
      value: 'all',
      hierarchyName: '',
    });
  }

  return updatedHierarchyPath;
};

const getStoreDrillDown = createSelector(
  [
    getDrillDownFilters,
    getSettingsSelector,
    getTrendsSelector,
    isSettingsLoadedSuccessfully,
    getUserProfileSelector,
  ],
  (trendsFilter, settings, trends, isSettingsLoaded, userProfile): ITrendsDrillDown | undefined => {
    const { list, totalCount, isLoading, highestValue } = trends[DrillDownTypes.Store];

    const {
      product: { filters: productFilters },
      other: { filters: otherFilters },
      store: { activeFilters, mutualFilters, hasMore, hierarchyPath: currentHierarchyPath },
    } = trendsFilter;

    const isUserSingleStoreManager = permissionsService.isSingleStoreManager();

    if (!isSettingsLoaded || isUserSingleStoreManager || !userProfile.lastUserDeployDate) {
      return undefined;
    }

    const { storeHierarchy, storeHierarchyDepth } = settings;

    const { hierarchyIndex } = mutualFilters;

    const hierarchyName = storeHierarchy[hierarchyIndex] || i18n.t('COMMON.STORES');

    const hierarchyPath = getHierarchyPath(currentHierarchyPath);

    const filters = [...productFilters, ...otherFilters];

    return {
      list,
      totalCount,
      hierarchyPath,
      hasMore,
      filters,
      hierarchyDepth: storeHierarchyDepth,
      hierarchyName,
      activeFilters,
      isLoading,
      mutualFilters,
      highestValue,
    };
  },
);

const getProductDrillDown = createSelector(
  [
    getDrillDownFilters,
    getSettingsSelector,
    getTrendsSelector,
    isSettingsLoadedSuccessfully,
    getUserProfileSelector,
  ],
  (trendsFilter, settings, trends, isSettingsLoaded, userProfile): ITrendsDrillDown | undefined => {
    const { list, totalCount, isLoading, highestValue } = trends[DrillDownTypes.Product];

    const {
      product: { hasMore, activeFilters, mutualFilters, hierarchyPath: currentHierarchyPath },
      other: { filters: otherFilters },
      store: { filters: storeFilters },
    } = trendsFilter;

    if (!isSettingsLoaded || !userProfile.lastUserDeployDate) {
      return undefined;
    }

    const { productHierarchy, productHierarchyDepth } = settings;

    const { hierarchyIndex } = mutualFilters;

    const hierarchyName = productHierarchy[hierarchyIndex] || i18n.t('COMMON.PRODUCTS');

    const hierarchyPath = getHierarchyPath(currentHierarchyPath);

    const filters = [...storeFilters, ...otherFilters];

    return {
      list,
      totalCount,
      hierarchyPath,
      filters,
      hierarchyDepth: productHierarchyDepth,
      hierarchyName,
      activeFilters,
      isLoading,
      mutualFilters,
      hasMore,
      highestValue,
    };
  },
);

// priority for selected category:
// selected by user -> first product category -> first store category -> store -> product
const getOtherSelectedCategory = (settings: ISettingsModel, trends: ITrendsModel) => {
  const {
    activeFilters: { selectedCategory },
  } = trends.filters[DrillDownTypes.Other];
  let category = selectedCategory;

  const categories = getOtherDrillDownCategories(settings);

  const { productCategories, storeCategories } = categories;

  const isUserSingleStoreManager = permissionsService.isSingleStoreManager();

  // no category was selected yet
  if (!category) {
    // default
    category = {
      value: DrillDownCategoriesTypes.Product,
      label: i18n.t('COMMON.PRODUCT'),
      type: DrillDownCategoriesTypes.Product,
    };

    // if there is any product categories chose the first one
    if (!isEmptyArray(productCategories)) {
      const { label, value } = productCategories[0];
      category = { label, value, type: DrillDownCategoriesTypes.ProductCategory };
    }
    // otherwise, if there is any store categories chose the first one
    else if (!isEmptyArray(storeCategories)) {
      const { label, value } = storeCategories[0];
      category = { label, type: DrillDownCategoriesTypes.StoreCategory, value };
    }
    // if we reach here, there is no  categories for this user
    else if (!isUserSingleStoreManager) {
      // for not single store manager-> choose store
      category = {
        value: DrillDownCategoriesTypes.Store,
        label: i18n.t('COMMON.STORE'),
        type: DrillDownCategoriesTypes.Store,
      };
    } else {
      category = {
        label: i18n.t('COMMON.PRODUCT'),
        value: DrillDownCategoriesTypes.Product,
        type: DrillDownCategoriesTypes.Product,
      };
    }
  }

  return category;
};

const checkIsCategorySelected = (
  selectedCategory: ISelectedCategory,
  type: DrillDownCategoriesTypes,
  value: string,
) => {
  const { type: selectedCategoryType, value: selectedCategoryValue } = selectedCategory;

  return type === selectedCategoryType && value === selectedCategoryValue;
};

const getOtherDrillDown = createSelector(
  [
    getDrillDownFilters,
    getSettingsSelector,
    getTrendsSelector,
    getUserProfileSelector,
    isSettingsLoadedSuccessfully,
  ],
  (
    trendsFilters,
    settings,
    trends,
    userProfile,
    isSettingsLoaded,
  ): ITrendsDrillDown | undefined => {
    const {
      expendedDrillDown,
      product: { filters: productFilters },
      other: { activeFilters, mutualFilters, hasMore, hierarchyPath, filters: otherFilters },
      store: { filters: storeFilters },
    } = trendsFilters;

    const categories = getOtherDrillDownCategories(settings);

    const { productCategories, storeCategories } = categories;

    const isCategoriesEmpty = isEmptyArray(productCategories) && isEmptyArray(storeCategories);

    const isUserSingleStoreManager = permissionsService.isSingleStoreManager();

    const isHidden = isUserSingleStoreManager && isCategoriesEmpty;

    if (!isSettingsLoaded || isHidden || !userProfile.lastUserDeployDate) {
      return undefined;
    }

    const selectedCategory = getOtherSelectedCategory(settings, trends);

    // default- show only its own filters

    const filters = [...otherFilters];

    const { list, totalCount, isLoading, highestValue } = trends[DrillDownTypes.Other];

    const isOtherExpanded = expendedDrillDown === DrillDownTypes.Other;

    // if expended other drill down shows all filters
    if (isOtherExpanded) {
      filters.unshift(...productFilters);
      filters.unshift(...storeFilters);
    }

    const { label: hierarchyName } = selectedCategory;

    const isFiltersVisible = filters.length > 0 && !isOtherExpanded;

    activeFilters.selectedCategory = selectedCategory;

    return {
      list,
      isLoading,
      totalCount,
      activeFilters,
      mutualFilters,
      hierarchyPath,
      hasMore,
      filters,
      isFiltersVisible,
      hierarchyName,
      hierarchyDepth: 0,
      highestValue,
    };
  },
);

const getOtherCategories = createSelector(
  [getSettingsSelector, getTrendsSelector, getUserProfileSelector],
  (settings, trends) => {
    const drillDownCategories = getOtherDrillDownCategories(settings);

    const selectedCategory = getOtherSelectedCategory(settings, trends);

    const isSingleStore = permissionsService.isSingleStoreManager();

    const { productCategories, storeCategories } = drillDownCategories;

    const mappedProductCategories = productCategories.map((category) => {
      const type = DrillDownCategoriesTypes.ProductCategory;

      const { value, label } = category;

      const isChecked = checkIsCategorySelected(selectedCategory, type, value);

      return {
        label,
        value,
        type,
        isChecked,
      };
    });

    const mappedStoreCategories = storeCategories.map((category) => {
      const { label, value } = category;

      const type = DrillDownCategoriesTypes.StoreCategory;

      const isChecked = checkIsCategorySelected(selectedCategory, type, value);

      return {
        label,
        value,
        type,
        isChecked,
      };
    });

    const categories = [];

    if (!isSingleStore) {
      const type = DrillDownCategoriesTypes.Store;

      const isStoreSelected = checkIsCategorySelected(selectedCategory, type, type);
      categories.unshift({
        title: i18n.t('COMMON.STORE'),
        id: type,
        list: [
          {
            type,
            label: i18n.t('COMMON.STORES'),
            value: DrillDownCategoriesTypes.Store,
            isChecked: isStoreSelected,
          },
        ],
      });
    }

    if (!isEmptyArray(storeCategories)) {
      categories.unshift({
        id: DrillDownCategoriesTypes.StoreCategory,
        title: i18n.t('DRILL_DOWNS.STORE_CATEGORIES'),
        list: mappedStoreCategories,
      });
    }
    if (!isEmptyArray(productCategories)) {
      categories.unshift({
        id: DrillDownCategoriesTypes.ProductCategory,
        title: i18n.t('DRILL_DOWNS.PRODUCT_CATEGORIES'),
        list: mappedProductCategories,
      });
    }

    return categories;
  },
);

const getResponseRateData = createSelector(
  [getResponseRateDataSelector, getResponseRateChainWideDataSelector],
  (responseRateData, responseRateChainWideData): Array<ITrendsLineChartRow> | null => {
    return buildResponseRateData(responseRateData, responseRateChainWideData);
  },
);

const getHitRateData = createSelector(
  [getHitRateDataSelector, getHitRateChainWideDataSelector],
  (hitRateData, hitRateChainWideData): Array<ITrendsLineChartRow> | null => {
    return buildHitRateData(hitRateData, hitRateChainWideData);
  },
);

const checkIsThreeUpToMonthsRange = createSelector([getDateSelector], (date): boolean => {
  const { from, to, type } = date;

  const diff = getDatesMonthsDiff(from!, to!);

  const ytdDiff = getYTDMonths();

  const isCustomThreeMonths = type === SelectedDateIntervals.customId && diff < 3;

  const isYTDThreeMonths = type === SelectedDateIntervals.yearId && ytdDiff <= 3;

  return (
    isCustomThreeMonths ||
    isYTDThreeMonths ||
    type === SelectedDateIntervals.threeMonthsId ||
    type === SelectedDateIntervals.monthId
  );
});

const getSearchFilter = createSelector([getDrillDownFilters], (filters): string => {
  return getSearch(filters);
});

const checkIsFiltersApplied = createSelector([getDrillDownFilters], (filters): boolean => {
  return verifyIsFiltersApplied(filters);
});

const TrendsSelector = {
  getStoreDrillDown,
  getProductDrillDown,
  getOtherDrillDown,
  getOtherCategories,
  getResponseRateData,
  getHitRateData,
  checkIsThreeUpToMonthsRange,
  checkIsFiltersApplied,
  getSearchFilter,
};

export default TrendsSelector;
