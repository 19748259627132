import cn from 'clsx';
import { MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import SideMenuOpportunities from 'components/layout/SideMenuOpportunities';
import { SideMenuButton } from 'components/buttons/SideMenuButton';
import { LogoutIcon, SideBarIllustratorIcon, SideArrow } from 'components/Icons';
import SideMenuHeader from 'components/layout/SideMenuHeader';
import SideMenuMain from 'components/layout/SideMenuMain';
import SideMenuSettings from 'components/layout/SideMenuSettings';
import { RoutePath } from 'routes/types';
import permissionsService from 'services/permissions/permissionsSrv';
import routerService from 'services/router/routerService';
import { toggleControlMenu } from 'store/filters/filtersActions';
import {
  collapseSideMenu,
  hideSideMenu,
  selectActionId,
  toggleMenuCollapseExpand,
  toggleMenuHideExpand,
} from 'store/sideMenu/sideMenuActions';
import UserProfileSelector from 'store/userProfile/userProfileSelector';
import { IRootState, ISideMenuProps } from 'types';
import './SideMenu.scss';
import packagejson from '../../../package.json';
import { useMediaQuery } from 'components/hooks/useMediaQuery';

export const SideMenu = ({
  isControlMenuExpanded,
  selectedActionMenuId,
  selectActionId,
  isMenuCollapsed,
  isMenuHidden,
  toggleMenuCollapseExpand,
  toggleMenuHideExpand,
  toggleControlMenu,
  collapseSideMenu,
  hideSideMenu,
  alias,
  isScopeError,
}: ISideMenuProps) => {
  const location = useLocation();

  const [t] = useTranslation();

  const buildNumber = process.env.REACT_APP_BUILD_NUMBER;

  const mayViewDashboard: boolean = permissionsService.mayViewDashboards();

  // media query hook event listener
  useMediaQuery(collapseSideMenu, hideSideMenu);

  useEffect(() => {
    const paths = location.pathname.split('/');

    let [, actionName] = paths;

    actionName = actionName.toLowerCase();

    selectActionId(actionName as RoutePath);
  }, [location]);

  const onActionClicked = (selectedActionMenuId: string) => {
    switch (selectedActionMenuId) {
      case RoutePath.MyOpportunities:
        routerService.routeToMyOpportunities();
        break;
      case RoutePath.Summary:
        routerService.routeToSummary();
        break;
      case RoutePath.OpenOpportunities:
        routerService.routeToOpenOpportunities();
        break;
      case RoutePath.Findings:
        routerService.routeToFindings();
        break;
      case RoutePath.Trends:
        routerService.routeToTrends();
        break;
      case RoutePath.ContactUs:
        routerService.routeToContactUs();
        break;
      case RoutePath.KnowledgeCenter:
        routerService.routeToKnowledgeCenter();
        break;
      case RoutePath.Settings:
        routerService.routeToSettings();
        break;
      case RoutePath.Logout:
        routerService.routeToLogout();
        break;
      default:
        break;
    }

    if (isControlMenuExpanded && selectedActionMenuId !== RoutePath.MyOpportunities) {
      toggleControlMenu(true);
    }
  };

  const selectActiveMenu = (selectedActionMenuId: RoutePath) => {
    onActionClicked(selectedActionMenuId);
    selectActionId(selectedActionMenuId);
  };

  const renderAlias = () => (
    <div className="user">
      <div className="alias">{alias}</div>
    </div>
  );

  const onToggleMenuHideExpand = (event: MouseEvent) => {
    event.stopPropagation();
    toggleMenuHideExpand();
  };

  return (
    <div
      role="toolbar"
      className={cn('side-menu', isMenuCollapsed && 'collapsed', isMenuHidden && 'hidden')}
      onClick={toggleMenuCollapseExpand}
    >
      <div className="side-menu-expander" onClick={onToggleMenuHideExpand}>
        <SideArrow className={cn('expand-arrow', !isMenuHidden && 'shrink')} />
      </div>

      <div className="side-menu-content-wrapper">
        {!isMenuHidden && (
          <div className="side-menu-content">
            <SideMenuHeader isMenuCollapsed={isMenuCollapsed} />

            <SideMenuOpportunities
              isMenuCollapsed={isMenuCollapsed}
              selectedActionMenuId={selectedActionMenuId}
              selectMenuAction={selectActiveMenu}
            />

            {mayViewDashboard && (
              <>
                <SideMenuMain
                  isMenuCollapsed={isMenuCollapsed}
                  selectedActionMenuId={selectedActionMenuId}
                  selectMenuAction={selectActiveMenu}
                  mayViewDashboard={mayViewDashboard}
                />

                <div className="separator" />
              </>
            )}

            <SideMenuSettings
              isMenuCollapsed={isMenuCollapsed}
              selectedActionMenuId={selectedActionMenuId}
              selectMenuAction={selectActiveMenu}
              mayViewSettings={!isScopeError}
            />

            <div className={cn('side-image-container', !isMenuCollapsed && 'expended')}>
              {!isMenuCollapsed && <SideBarIllustratorIcon />}
            </div>

            <div className="separator" />

            <div className="side-menu-info">
              {!isMenuCollapsed && renderAlias()}
              <SideMenuButton
                id={RoutePath.Logout}
                icon={LogoutIcon}
                isMenuCollapsed={isMenuCollapsed}
                isActive={RoutePath.Logout === selectedActionMenuId}
                onClick={selectActiveMenu}
                label={t('COMMON.LOGOUT')}
              />
              <div className="version">
                <span>v</span>
                <span className="package">{packagejson.version}</span>
                {buildNumber && <span className="build">.{buildNumber}</span>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  alias: state.userProfile.alias,
  isControlMenuExpanded: state.filters.isControlMenuExpanded,
  selectedActionMenuId: state.sideMenu.selectedActionMenuId,
  isMenuCollapsed: state.sideMenu.isMenuCollapsed,
  isMenuHidden: state.sideMenu.isMenuHidden,
  isScopeError: UserProfileSelector.isScopeError(state),
});

const mapDispatchToProps = {
  selectActionId,
  toggleMenuCollapseExpand,
  toggleControlMenu,
  collapseSideMenu,
  toggleMenuHideExpand,
  hideSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
