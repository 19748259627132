const getPrefix = (action: string): string => `AlertActionTypes.${action}`;

const Show = getPrefix('Show');

const Hide = getPrefix('Hide');

const ButtonClick = getPrefix('ButtonClick');

const SetChecklist = getPrefix('SetChecklist');

const SetRadioList = getPrefix('SetRadioList');

const SetDateRange = getPrefix('SetDateRange');

const SetTreeSelection = getPrefix('SetTreeSelection');

const AlertActionTypes = {
  Show,
  Hide,
  ButtonClick,
  SetChecklist,
  SetDateRange,
  SetRadioList,
  SetTreeSelection,
};

export default AlertActionTypes;
