import LoadingActions from 'store/loading/LoadingActions';
import APIConstants, { getBaseUrl } from 'store/api/APIConstants';
import { getTokens } from 'utils';
import { handleErrors, handleException, handleSuccess } from 'store/api/apiHelper';

const REQUEST_TIMEOUT = 30000;

const createApiMiddleware = (apiRequest: string, apiEndpoint: any) => ({
  dispatch,
  getState,
}: any) => (next: any) => async (action: any) => {
  if (!action || action.type !== apiRequest) {
    return next(action);
  }

  const { url, meta, baseAction, payload } = action;

  const workingEnv = getState().environment.currentEnvironment;

  const { deviceToken } = getState().userProfile;

  const projectId = payload?.projectId ? payload.projectId : getState().userProfile.projectIds[0];

  const { token } = getTokens();

  // TODO we support only base url type, extend also for any apiEndpoint if needed
  const envEndPoint = getBaseUrl(workingEnv, apiEndpoint);

  const fetchUrl = encodeURI(`${envEndPoint}${url}`);

  const configs: any = {
    headers: {},
  };

  if (apiRequest === APIConstants.API_REQUEST) {
    if (projectId !== undefined) {
      configs.headers.projectId = projectId;
    }
    configs.method = meta.method;
    configs.headers.Authorization = `Bearer ${token}`;
    // configs.headers['tenant'] = 'dev';
    configs.headers['device-token'] = deviceToken;
    configs.timeout = REQUEST_TIMEOUT;
  }

  if (meta.formData) {
    const body = new FormData();

    Object.keys(meta.body).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(meta.body, key)) {
        body.append(key, meta.body[key]);
      }
    });

    configs.body = body;
  } else {
    configs.headers['content-type'] = 'application/json; charset=utf-8';

    if (meta.body && meta.method !== 'GET') {
      configs.body = JSON.stringify(meta.body);
    }
  }

  // trigger PENDING
  dispatch({
    type: baseAction.PENDING,
    payload,
  });

  dispatch(LoadingActions.addLoading(baseAction.BASE));

  return fetch(fetchUrl, configs)
    .then((response) => handleErrors(response, dispatch))
    .then((response) => handleSuccess(response, dispatch, action))
    .catch((response) => handleException(response, dispatch, action));
};

const apiMiddlewares = [createApiMiddleware(APIConstants.API_REQUEST, APIConstants.BASE_URL)];

export default apiMiddlewares;
