import { FC } from 'react';
import cn from 'clsx';

import { CheckMarkIcon } from 'components/Icons';

import './toggleButton.scss';

export interface IToggleButton {
  isActive: boolean;
  text: string;

  onClick(): void;
}

export const ToggleButton: FC<IToggleButton> = ({ isActive, onClick, text }) => {
  return (
    <div className={cn('toggle-button-container', { isActive })} onClick={onClick}>
      <span>{text}</span>
      <CheckMarkIcon />
    </div>
  );
};
