import { FC } from 'react';
import { Line, LabelList, LineChart, XAxis, YAxis } from 'recharts';

import { IPrintPageChart } from 'types';

const CHART_HEIGHT = 80;

const CHART_WIDTH = 180;

const Chart: FC<IPrintPageChart> = ({ lastSimilarSalesValues, isRtl, lastSalesValues }) => {
  const Square = (props: any) => {
    const { cx, cy, key } = props;

    return <rect key={key} x={cx - 2} y={cy - 2} className="square-dot" />;
  };

  const Circle = (props: any) => {
    const { cx, cy, key } = props;

    return <circle key={key} r="3" className="circle-dot" cx={cx} cy={cy} />;
  };

  const LineLabel = (payload: any) => {
    const { x, y, value } = payload;

    return (
      <text x={x} y={y} dy={-5} textAnchor="middle" className="print-chart-axis-label">
        ${Math.round(value)}
      </text>
    );
  };

  return (
    <LineChart
      className="print-chart"
      width={CHART_WIDTH}
      height={CHART_HEIGHT}
      margin={{ top: 12, bottom: 5 }}
    >
      <Line
        isAnimationActive={false}
        activeDot={false}
        className="sales-line"
        dot={Circle}
        data={lastSalesValues}
        type="linear"
        dataKey="value"
      >
        <LabelList content={<LineLabel />} />
      </Line>
      <Line
        key={Math.random()}
        isAnimationActive={false}
        dot={Square}
        activeDot={false}
        className="similar-sales-line"
        data={lastSimilarSalesValues}
        type="linear"
        dataKey="value"
      >
        <LabelList content={<LineLabel />} />
      </Line>
      <XAxis
        height={1}
        allowDuplicatedCategory={false}
        padding={{ left: 20, right: 20 }}
        strokeWidth={0.2}
        dataKey="date"
        tick={false}
        reversed={isRtl}
        tickLine={false}
      />
      <YAxis
        width={1}
        type="number"
        domain={['dataMin', 'dataMax']}
        orientation={isRtl ? 'right' : 'left'}
        padding={{ top: 10, bottom: 10 }}
        strokeWidth={0.2}
        tick={false}
        tickLine={false}
      />
    </LineChart>
  );
};

export default Chart;
