import cn from 'clsx';
import { ChangeEvent, FC, RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, SearchIcon } from 'components/Icons';
import './SearchInput.scss';
import AnalyticSrv from 'services/analytics/AnalyticSrv';

export interface ISearchInputProps {
  onTextChanged: (text: string) => void;
  placeHolder?: string;
  inputRef?: RefObject<HTMLInputElement>;
  id: string;
  defaultValue?: string;
  containerClassName?: string;
  onClear?: () => void;
  isRtl?: boolean;
}

export const SearchInput: FC<ISearchInputProps> = ({
  onTextChanged,
  placeHolder,
  inputRef,
  containerClassName,
  defaultValue,
  id,
  onClear,
  isRtl,
}) => {
  const [t] = useTranslation();

  const [text, setText] = useState(defaultValue ?? '');

  useEffect(() => {
    if (defaultValue) {
      setText(defaultValue);
    }
  }, [defaultValue]);

  const onChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setText(newText);
    onTextChanged(newText);
    AnalyticSrv.sendSearchEvent();
  }, []);

  const onClearClick = () => {
    setText('');
    onTextChanged('');
    if (onClear) {
      onClear();
    }
  };

  const randomID = Math.random() * 100;

  return (
    <div role="search" className={cn('search-field', [containerClassName])}>
      <div className="search-icon-container">
        <SearchIcon className="search-text-icon" />
      </div>
      <input
        id={`search-text-${id}-${randomID}`}
        role="searchbox"
        type="text"
        placeholder={placeHolder || t('COMMON.SEARCH')}
        onChange={onChanged}
        ref={inputRef}
        value={text}
      />
      {!!text && (
        <div className={cn('close-icon-container', isRtl && 'rtl')} onClick={onClearClick}>
          <CloseIcon className="close-text-icon" />
        </div>
      )}
    </div>
  );
};
