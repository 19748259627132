import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import SettingsActionTypes from 'store/settings/settingsActionTypes';

export const getSettings = (projectId: string) => ({
  type: APIConstants.API_REQUEST,
  payload: { projectId },
  url: '/v1/report/MetaDataQuery',
  meta: {
    method: HttpMethod.GET,
  },
  baseAction: SettingsActionTypes.getSettings,
});

export const updatePassword = (formData: any, alias: string) => ({
  type: APIConstants.API_REQUEST,
  url: `/unifiedpush-server/rest/alias/${alias}/password?oauth2=true`,
  meta: {
    method: HttpMethod.POST,
    body: {
      ...formData,
    },
  },

  baseAction: SettingsActionTypes.updatePassword,
});

export const updateLanguage = (chosenLanguage: string) => ({
  type: SettingsActionTypes.updateLanguage,
  payload: { chosenLanguage },
});

export const resetServerResponseStatus = () => ({
  type: SettingsActionTypes.resetServerResponseStatus,
  payload: {},
});

const SettingsActions = {
  getSettings,
  updateLanguage,
  updatePassword,
};

export default SettingsActions;
