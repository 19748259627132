import { set, flow } from 'lodash/fp';

import EnvironmentActionTypes from 'store/environment/environmentActionTypes';
import { IActionType } from 'types';
import { setEnv } from 'utils';
import { IEnvironmentModel } from './types';

export const initialState: IEnvironmentModel = {
  shouldShowDevOptions: false,
  currentEnvironment: 'browserBaseUrl',
};

const environmentReducer = (state: IEnvironmentModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case EnvironmentActionTypes.showEnvironmentDebugSettings: {
      const { shouldShowDevOptions } = payload;

      return flow([set('shouldShowDevOptions', shouldShowDevOptions)])(state);
    }

    case EnvironmentActionTypes.setEnvironmentDebugSettings: {
      const { currentEnvironment } = payload;
      setEnv(currentEnvironment);

      return flow([set('currentEnvironment', currentEnvironment)])(state);
    }
    default:
      return state;
  }
};

export default environmentReducer;
