import { createRef, FC, ReactNode, useEffect } from 'react';

export interface IClickOutsideProps {
  children: ReactNode;
  containerClassName?: string;
  onClickOutside(event: any): void;
}

export const ClickOutside: FC<IClickOutsideProps> = ({
  children,
  onClickOutside,
  containerClassName,
}): any => {
  const containerRef = createRef<HTMLDivElement>();

  const handle = (event: any) => {
    if (!containerRef?.current?.contains(event.target)) {
      onClickOutside(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handle, true);

    return () => document.removeEventListener('click', handle, true);
  }, []);

  return (
    <div className={containerClassName} ref={containerRef}>
      {children}
    </div>
  );
};
