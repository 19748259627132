import { FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import {
  AlertIds,
  DrillDownTypes,
  IAlert,
  IFindings,
  IPopupModal,
  IRootState,
  ISearchParamsFilters,
  PopupIds,
  SelectedDateIntervals,
} from 'types';
import { DateButton } from 'components/buttons';
import { show } from 'store/alert/alertActions';
import { show as showOpportunityPopup } from 'store/popup/popupActions';
import './findings.scss';
import { isEmptyArray } from 'utils';
import { getDrillDown, getFindingsReasons } from 'store/findings/findingsActions';
import FindingsPreview from './FindingsPreview';
import FindingsSelector from 'store/findings/findingsSelector';
import DrillDowns from 'components/findings/drillDowns/DrillDowns';
import loadingSelector from 'store/loading/LoadingSelector';
import { useSkipFirstEffect } from 'components/hooks';
import routerService from 'services/router/routerService';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';

export const Findings: FC<IFindings> = ({
  otherDrillDown,
  storeDrillDown,
  productDrillDown,
  getFindingsReasons,
  reasonsList,
  showDateMenu,
  filters,
  otherDrillDownCategories,
  showOtherDrillDownOptions,
  getDrillDown,
  showOpportunityPopup,
  lastUserDeployDate,
}) => {
  const [t] = useTranslation();

  const { expendedDrillDown } = filters;

  const isListExpended = !!expendedDrillDown;

  // update Query params when filters change
  useEffect(() => {
    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date: filters.date,
      [DrillDownTypes.Other]: {
        category: filters[DrillDownTypes.Other]?.activeFilters.selectedCategory,
        filter: filters[DrillDownTypes.Other]?.filters[0],
        order: filters[DrillDownTypes.Other]?.activeFilters.order.direction,
      },
      [DrillDownTypes.Store]: {
        hierarchyPath: filters[DrillDownTypes.Store]?.hierarchyPath,
        order: filters[DrillDownTypes.Store]?.activeFilters.order.direction,
      },
      [DrillDownTypes.Product]: {
        hierarchyPath: filters[DrillDownTypes.Product]?.hierarchyPath,
        order: filters[DrillDownTypes.Product]?.activeFilters.order.direction,
      },
    };

    const params = buildQueryParamsFromSearchFilters(searchFilters);

    routerService.updateSearchParams(params);
  }, [filters]);

  const getReasons = useCallback(() => {
    getFindingsReasons(
      filters,
      reasonsList,
      storeDrillDown?.hierarchyDepth || 0,
      productDrillDown?.hierarchyDepth || 0,
      lastUserDeployDate,
      otherDrillDown?.activeFilters.selectedCategory,
    );
  }, [
    getFindingsReasons,
    filters,
    reasonsList,
    storeDrillDown,
    productDrillDown,
    lastUserDeployDate,
  ]);

  const getDrillDownByType = useCallback(
    (drillDownType: DrillDownTypes, pageOffset?: number) => {
      const isVisible = !expendedDrillDown || expendedDrillDown === drillDownType;

      if (isVisible && reasonsList) {
        getDrillDown(
          drillDownType,
          storeDrillDown?.hierarchyDepth,
          productDrillDown?.hierarchyDepth,
          otherDrillDown?.activeFilters.selectedCategory,
          pageOffset,
        );
      }
    },
    [filters, reasonsList, storeDrillDown, otherDrillDown, productDrillDown],
  );

  // Any change in the dependencies below effect all page
  // For each update we send all the requests and update the page
  useEffect(() => {
    const isReasonsLoaded = !isEmptyArray(reasonsList.AV) || !isEmptyArray(reasonsList.IA);

    getReasons();

    if (isReasonsLoaded) {
      getDrillDownByType(DrillDownTypes.Store);
      getDrillDownByType(DrillDownTypes.Product);
      getDrillDownByType(DrillDownTypes.Other);
    }
  }, [
    storeDrillDown?.mutualFilters,
    productDrillDown?.mutualFilters,
    otherDrillDown?.mutualFilters,
    otherDrillDown?.filters.length,
    filters.date,
    reasonsList,
  ]);

  // Any change in the dependencies below effect store drill down (first one)
  // For each change we update only store drill down
  useSkipFirstEffect(() => getDrillDownByType(DrillDownTypes.Store), [
    storeDrillDown?.activeFilters,
    filters.activeReasonFilters,
    filters.expendedDrillDown,
  ]);

  // Any change in the dependencies below effect product drill down (second one)
  // For each change we update only product drill down
  useSkipFirstEffect(() => getDrillDownByType(DrillDownTypes.Product), [
    productDrillDown?.activeFilters,
    filters.activeReasonFilters,
    filters.expendedDrillDown,
  ]);

  // Any change in the dependencies below effect other drill down (Third one)
  // For each change we update only other drill down
  useSkipFirstEffect(() => {
    if (otherDrillDown?.activeFilters.selectedCategory) {
      getDrillDownByType(DrillDownTypes.Other);
    }
  }, [otherDrillDown?.activeFilters, filters.expendedDrillDown, filters.activeReasonFilters]);

  const onDateButtonClick = useCallback(() => {
    const {
      date: { from, to, type },
    } = filters;

    const radioIds = [
      SelectedDateIntervals.twelveMonthsId,
      SelectedDateIntervals.yearId,
      SelectedDateIntervals.threeMonthsId,
      SelectedDateIntervals.monthId,
      SelectedDateIntervals.lastDeployment,
      SelectedDateIntervals.customId,
    ];

    const radioList = radioIds.map((id) => ({
      id,
      hideOnClick: id !== SelectedDateIntervals.customId,
      isChecked: id === type,
      label: t(id),
    }));

    const options: IAlert = {
      id: AlertIds.OpenFindingsDateMenu,
      radioList,
      withOverlay: true,
      withDatePicker: true,
      selectedDates: {
        from,
        to,
      },
      alertCustomClassName: 'findings-date-menu',
    };

    showDateMenu(options);
  }, [filters, showDateMenu]);

  const onShowOtherDrillDownOptions = useCallback(() => {
    if (!otherDrillDown) {
      return;
    }

    const mappedCategories = otherDrillDownCategories.map((category) => {
      const { list, id, title } = category;

      const options = list.map(({ label, type, isChecked, value }) => ({
        label,
        value,
        isChecked,
        id: type,
        hideOnClick: true,
      }));

      return {
        options,
        id,
        title,
      };
    });

    const options: IAlert = {
      id: AlertIds.OpenFindingsOtherDrillDownOptions,
      treeSelection: mappedCategories,
      withOverlay: true,
      alertCustomClassName: 'alert-drill-down-menu',
    };

    showOtherDrillDownOptions(options);
  }, [otherDrillDown, otherDrillDownCategories]);

  const onLoadMoreRows = useCallback(
    (drillDownType: DrillDownTypes) => {
      const { nextPageOffset, hasMore } = filters[drillDownType];

      if (drillDownType === expendedDrillDown && hasMore) {
        getDrillDownByType(drillDownType, nextPageOffset);
      }
    },
    [filters],
  );

  const onOpportunityClick = (selectedOpportunityId: string) => {
    const options: IPopupModal = {
      id: PopupIds.FindingsOpportunity,
      selectedOpportunityId,
    };
    showOpportunityPopup(options);
  };

  return (
    <div className={cn('page', 'findings')}>
      <div className="page-header">
        <div className="date-title-container">
          <span className="title">{t('PAGES.FINDINGS')}</span>
          <DateButton
            dates={
              filters.date.type === SelectedDateIntervals.customId
                ? [filters.date.from, filters.date.to]
                : undefined
            }
            label={
              filters.date.type !== SelectedDateIntervals.customId
                ? t(filters.date.type)
                : undefined
            }
            onClick={onDateButtonClick}
          />
        </div>
      </div>
      <div className="page-container opposite-page">
        <div className="page-preview">
          <FindingsPreview />
        </div>
        <div
          className={cn('page-list', 'findings-list-content', {
            'list-expended': isListExpended,
          })}
        >
          <div className="share-button" />
          <div className={cn('drill-downs-list', { expended: isListExpended })}>
            <DrillDowns
              onOpportunityClick={onOpportunityClick}
              onTitleClick={onShowOtherDrillDownOptions}
              productDrillDown={productDrillDown}
              otherDrillDown={otherDrillDown}
              storeDrillDown={storeDrillDown}
              onLoadMore={onLoadMoreRows}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    findings: { filters },
    reasons: { groups: reasonsList },
    userProfile: { lastUserDeployDate },
  } = state;

  const storeDrillDown = FindingsSelector.getStoreDrillDown(state);

  const productDrillDown = FindingsSelector.getProductDrillDown(state);

  const otherDrillDown = FindingsSelector.getOtherDrillDown(state);

  const otherDrillDownCategories = FindingsSelector.getOtherCategories(state);

  const isReasonFilterGroupLoading = loadingSelector.isGetFindingsReasons(state);

  return {
    storeDrillDown,
    filters,
    productDrillDown,
    otherDrillDownCategories,
    reasonsList,
    otherDrillDown,
    isReasonFilterGroupLoading,
    lastUserDeployDate,
  };
};

const mapDispatchToProps = {
  showDateMenu: show,
  showOpportunityPopup,
  showOtherDrillDownOptions: show,
  getFindingsReasons,
  getDrillDown,
};

export default connect(mapStateToProps, mapDispatchToProps)(Findings);
