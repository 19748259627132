import { FC, ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import './lineChart.scss';
import { ITrendsLineChart, ITrendsLineChartRow } from 'types';
import {
  getMonth,
  getShortDateFormat,
  getShortMonthFormat,
  getShortYearFormat,
  isEmptyArray,
  toFixed,
} from 'utils';
import { MILLISECONDS } from 'constants/constantsVaribles';
import YearLineLabel from 'components/trends/yearLinelLabel/YearLineLabel';

import colors from 'styles/_colors.module.scss';

const CHART_HEIGHT = 380;

const SMALL_CHART_HEIGHT = 270;

const LEGEND_HEIGHT = 60;

const MARGIN = 12;

const RIGHT_MARGIN = 20;

const LineChart: FC<ITrendsLineChart> = ({
  data,
  isSmallChart,
  shouldDisplayVerticalGrid,
  shouldDisplayLegend,
  isSummaryPage,
  shouldDisplayYearLineLabel,
  isRtl,
  isWeeklyResolution,
}) => {
  const [t] = useTranslation();

  const [isToolTipVisible, setIsToolTipVisible] = useState(false);

  const xAxisValues = data.map((item) => item.date);

  const chartHeight = isSmallChart ? SMALL_CHART_HEIGHT : CHART_HEIGHT;

  const shouldDisplayDot = data.length === 1;

  const MyStoresActiveDot = isToolTipVisible && <circle className="my-stores-dot" />;

  const formatDate = (date: number) => {
    let formattedDate = getShortMonthFormat(date);

    if (isWeeklyResolution) {
      formattedDate = getShortDateFormat(date);
    }

    return formattedDate;
  };

  const formatXAxis = (tickItem: number) => formatDate(tickItem);

  const formatYAxis = (tickItem: number) => `${toFixed(tickItem)}%`;

  const onChartClick = () => setIsToolTipVisible(!isToolTipVisible);

  const ToolTipContent: FC<any> = ({ payload, isActive }) => {
    if (!payload || !payload![0]?.payload) {
      return null;
    }

    const details: ITrendsLineChartRow = payload![0]?.payload;

    const { chainWideValue, myStoresValue, date } = details;

    const formattedDate = formatDate(date);

    const formattedMyStoresValue = `${toFixed(myStoresValue)}%`;

    const formattedChainWidesValue = `${toFixed(chainWideValue)}%`;

    return (
      <div className={cn('trends-line-tooltip', { hide: !isActive })}>
        <span>{formattedDate}</span>
        <span className="tooltip-content">
          <span className="my-stores-tooltip">{formattedMyStoresValue}</span>
          <span> {t('COMMON.VS')}</span>
          <span> {formattedChainWidesValue}</span>
        </span>
        <span className="chain-wide-tooltip">{t('COMMON.CHAIN_WIDE')}</span>
      </div>
    );
  };

  const renderLegend = useCallback(() => {
    if (!shouldDisplayLegend) {
      return null;
    }

    return (
      <Legend
        height={LEGEND_HEIGHT}
        layout="horizontal"
        verticalAlign="bottom"
        payload={[
          {
            color: colors.darkGreen,
            value: t('COMMON.MY_STORES'),
            type: 'plainline',
            payload: { strokeDasharray: '0 0' },
          },
          {
            color: colors.orange,
            value: t('COMMON.CHAIN_WIDE'),
            type: 'plainline',
            payload: { strokeDasharray: '12 4' },
          },
        ]}
      />
    );
  }, [shouldDisplayLegend]);

  // Adding line with year before new year start
  const renderReferenceLine = () => {
    // preventing adding line after last month displayed
    const slicedData = data.slice(0, -1);

    // Searching for the last date in the year
    const decemberDate = slicedData.find((item, index) => {
      const isLastMonth = getMonth(item.date) === 12;

      // next data is in January
      const isNextDataIsNextYear = getMonth(data![index + 1].date) === 1;

      return isLastMonth && isNextDataIsNextYear;
    });

    // Adding line of the year after the last year
    if (!decemberDate) {
      return null;
    }

    const { date } = decemberDate;

    const text = `'${Number(getShortYearFormat(date)) + 1}`;

    const x = date + MILLISECONDS.WEEK * 2;

    let label: string | ReactElement = <YearLineLabel text={text} />;
    if (!shouldDisplayYearLineLabel) {
      label = '';
    }

    return <ReferenceLine x={x} label={label} />;
  };

  return (
    <div className={cn('trends-line-chart-container', { summary: isSummaryPage })}>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <RechartsLineChart
          onClick={onChartClick}
          margin={{ top: MARGIN, right: RIGHT_MARGIN }}
          className="trends-line-chart"
          data={data}
        >
          <CartesianGrid vertical={shouldDisplayVerticalGrid} />
          {!isEmptyArray(xAxisValues) && (
            <XAxis
              reversed={isRtl}
              interval={0}
              type="number"
              ticks={xAxisValues}
              domain={['dataMin', 'dataMax']}
              tickMargin={MARGIN}
              tickFormatter={formatXAxis}
              strokeWidth={0}
              dataKey="date"
            />
          )}
          <YAxis
            domain={[0, 100]}
            orientation={isRtl ? 'right' : 'left'}
            tickFormatter={formatYAxis}
            tickMargin={MARGIN}
            strokeWidth={0}
          />
          <Tooltip
            cursor={isToolTipVisible}
            trigger="click"
            content={<ToolTipContent isActive={isToolTipVisible} />}
          />
          <Line activeDot={false} className="chain-wide" type="monotone" dataKey="chainWideValue" />
          <Line
            activeDot={MyStoresActiveDot}
            className={cn('my-stores', { 'display-dot': shouldDisplayDot })}
            type="monotone"
            dataKey="myStoresValue"
          />
          {renderLegend()}
          {renderReferenceLine()}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
