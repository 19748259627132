import ToastActionTypes from 'store/toast/toastActionTypes';
import { verbosityLevel } from 'store/toast/types';

export const success = (text: string) => ({
  type: ToastActionTypes.Success,
  payload: { text },
});

export const error = (text: string) => ({
  type: ToastActionTypes.Error,
  payload: { text },
});

export const warning = (text: string) => ({
  type: ToastActionTypes.Warning,
  payload: { text },
});

export const info = (text: string) => ({
  type: ToastActionTypes.Info,
  payload: { text },
});

// this is an overload function, in case there several toast verbosity
// import this func will allow using all toast verbosity
export const log = (text: string, verbosity: verbosityLevel) => {
  let logType = null;

  switch (verbosity) {
    case verbosityLevel.Error: {
      logType = ToastActionTypes.Error;
      break;
    }
    case verbosityLevel.Success: {
      logType = ToastActionTypes.Success;
      break;
    }
    case verbosityLevel.Warning: {
      logType = ToastActionTypes.Warning;
      break;
    }
    case verbosityLevel.Info: {
      logType = ToastActionTypes.Info;
      break;
    }
    default: {
      logType = ToastActionTypes.Info;
    }
  }

  return {
    type: logType,
    payload: { text },
  };
};

const ToastActions = {
  success,
  error,
  warning,
  info,
  log,
};

export default ToastActions;
