import { FC } from 'react';
import cn from 'clsx';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { getPercent, toLanguageNumberFormat } from 'utils';
import { FindingsPieChartLocation, IFindingsPieChartItem } from 'types';

interface IFindingsChart {
  height?: number;
  data: Array<IFindingsPieChartItem>;
  totalCount: number | null;
  isAllCellsActive: boolean;
  canToggleActiveCell: boolean;
  isRtl?: boolean;
  location?: FindingsPieChartLocation;
  onCellClick(groupId: number, isActive: boolean): void;
}

const FindingsChart: FC<IFindingsChart> = ({
  height,
  isAllCellsActive,
  canToggleActiveCell,
  onCellClick,
  totalCount,
  data,
  location = FindingsPieChartLocation.Findings,
  isRtl,
}) => {
  const renderCustomizedLabel = (props: any) => {
    const { cx, cy, midAngle, outerRadius, color, value } = props;

    const RADIAN = Math.PI / 180;

    const outerRadiusPadding: number = 5;

    const radius = outerRadius + outerRadiusPadding;

    const x = cx + radius * Math.cos(-midAngle * RADIAN);

    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Prevent 0%
    const percent = Math.max(1, getPercent(value, totalCount || 1));

    let shouldDisplayStart = x > cx;
    if (isRtl) {
      shouldDisplayStart = x <= cx;
    }

    const textAnchor = shouldDisplayStart ? 'start' : 'end';

    return (
      <text
        className="findings-chart-label"
        x={x}
        y={y}
        fill={color}
        textAnchor={textAnchor}
        dominantBaseline="central"
      >
        {`${percent}%`}
      </text>
    );
  };

  const ToolTipContent: FC<any> = ({ active, payload }) => {
    const isToolTipVisible = active && payload![0]?.payload;

    if (!isToolTipVisible) {
      return null;
    }

    const { name, value } = payload[0];

    return (
      <div className="findings-tooltip">
        {name}
        <br />
        {toLanguageNumberFormat(value)}
      </div>
    );
  };

  const handleCellClick = (id: number, activeState: boolean) => {
    if (activeState && !canToggleActiveCell) {
      return;
    }

    onCellClick(id, !activeState);
  };

  const LegendContent: FC<any> = ({ payload }) => {
    return (
      <div className="findings-legend-container">
        {payload.map((element: any, index: number) => {
          const { id, isActive } = data[index];

          const isLegendActive = isActive || isAllCellsActive;

          const { color: background, value } = element;

          return (
            <div
              key={`${value}-${String(index)}`}
              className={cn('legend-row', { active: isLegendActive })}
              onClick={() => handleCellClick(id, isActive)}
            >
              <div className="findings-line-legend" style={{ background }} />
              <span>{value}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderChartCells = () =>
    data.map((element, index) => {
      const { color, isActive, id } = element;

      const isPieChartActive = isActive || isAllCellsActive;

      const opacity = isPieChartActive ? 1 : 0.6;

      return (
        <Cell
          className={cn('findings-pie-cell', { active: isActive })}
          onClick={() => handleCellClick(id, isActive)}
          opacity={opacity}
          key={`${id}-${String(index)}`}
          fill={color}
        />
      );
    });

  let chartLocation = '50%';
  if (location === FindingsPieChartLocation.Summary) {
    chartLocation = isRtl ? '30%' : '70%';
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <Pie
          labelLine={false}
          label={renderCustomizedLabel}
          isAnimationActive={false}
          cx={chartLocation}
          data={data}
          dataKey="value"
        >
          {renderChartCells()}
        </Pie>
        <Tooltip content={ToolTipContent} />

        <Legend verticalAlign="bottom" content={LegendContent} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default FindingsChart;
