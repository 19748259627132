import { FC } from 'react';
import cn from 'clsx';

import { IndicatorLineProps } from 'types';
import './indicatorLine.scss';

const IndicatorLine: FC<IndicatorLineProps> = ({ status }) => {
  return <div className={cn('indicator', [status])} />;
};

export default IndicatorLine;
