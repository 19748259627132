import {
  ArrowIconDirection,
  CurrencySymbolOptions,
  DrillDownTypes,
  IAlertModel,
  IHierarchyPath,
  ILeaderBoardRow,
  IDropdownOption,
  IReasonFilterGroups,
  ISelectedCategory,
  ITopFindingsDrillDown,
  ITrendsLineChartRow,
  ReasonsGroups,
  SelectedDateIntervals,
  Store,
} from 'types';

export interface ISummary {
  currency: CurrencySymbolOptions;
  setKpiDateFilter(value: SelectedDateIntervals): void;
  setTopFindingsDateFilter(value: SelectedDateIntervals): void;
  setLeaderBoardDateFilter(value: SelectedDateIntervals): void;
  setActiveComparisonTab(value: string): void;
  setStoreFilter(store?: Store): void;
  dropdownOptions: IDropdownOption[];
  selectedDropdownOption: IDropdownOption;
  kpiDateFilter: SelectedDateIntervals;
  topFindingsDateFilter: SelectedDateIntervals;
  leaderBoardDateFilter: SelectedDateIntervals;
  activeComparisonTab: string;
  leaderBoardTopData: Array<ILeaderBoardRow>;
  leaderBoardBottomData: Array<ILeaderBoardRow>;
  isRtl?: boolean;
  haveOpportunitiesBeenDeployed: boolean;
}

export interface IWeeklyComparisonWrapper {
  numberOfWeeksForResponseRateComparison: number;
  numberOfWeeksForHitRateComparison: number;
  responseRateValue: number;
  responseRateCompareToValue: number;
  hitRateValue: number;
  hitRateCompareToValue: number;
  selectedStoreFilter: Store | null;
  getOpenOpportunities(selectedStoreFilterName?: string): void;
  getRateComparison(): void;
  numberOfOpenTasks: number;
  openTasksDate: string;
  lastUserDeployDate: number;
  showRatePopup: (options: IAlertModel) => void;
  responseRateArrowDirection: ArrowIconDirection | undefined;
  hitRateArrowDirection: ArrowIconDirection | undefined;
  isLoadingOpenOpportunities: boolean;
  isLoadingResponseRateComparison: boolean;
  isLoadingHitRateComparison: boolean;
  selectedCategory?: ISelectedCategory;
}

export interface IHighLevelKpisWrapper {
  totalOpportunitiesCaptured: number;
  totalOpportunitiesCapturedChainWide: number;
  salesIncrease: number;
  salesIncreaseChainWide: number;
  selectedStoreFilter: Store | null;
  getSalesIncrease(): void;
  getIssueFoundByReasons(): void;
  currency: CurrencySymbolOptions;
  kpiDateFilter: SelectedDateIntervals;
  isGetMyIssueFoundByReasonsLoading: boolean;
  isGetMySalesIncreaseLoading: boolean;
  trendsSelectedCategory?: ISelectedCategory;
  findingsSelectedCategory?: ISelectedCategory;
}

export const AYearIntervalOption = [
  SelectedDateIntervals.twelveMonthsId,
  SelectedDateIntervals.yearId,
];

export interface ILeaderBoardWrapper {
  setLeaderBoardDateFilter(value: SelectedDateIntervals): void;
  leaderBoardDateFilter: SelectedDateIntervals;
  leaderBoardTopData: Array<ILeaderBoardRow>;
  leaderBoardBottomData: Array<ILeaderBoardRow>;
  getLeaderBoardPrepare(): void;
  isGetLeaderBoardLoading: boolean;
  userPeersLevel: IHierarchyPath | null;
  isLeaderBoardPeersSelected: boolean;
  selectedStoreFilter: Store | null;
  setLeaderBoardPeersSelected(isPeersSelected: boolean): void;
}

export const leaderBoardDateFilterOptions = [
  SelectedDateIntervals.monthId,
  SelectedDateIntervals.threeMonthsId,
  SelectedDateIntervals.sixMonthsId,
];

export interface ITopFindings {
  topFindingsDateFilter: SelectedDateIntervals;
  activeReasonFilters: Array<number>;
  isGetFindingsReasonsLoading: boolean;
  issueFoundTotalCount: number | null;
  otherDrillDown?: ITopFindingsDrillDown;
  toggleGroupSelection(groupId: number, isActive: boolean): void;
  getDrillDown(drillDownType: DrillDownTypes, selectedCategory?: ISelectedCategory): void;
  getFindingsReasons(
    topFindingsDateFilter: SelectedDateIntervals,
    reasons: ReasonsGroups,
    storeNameFilter?: string,
  ): void;
  reasonsList: ReasonsGroups;
  reasonsFilterGroups: IReasonFilterGroups | null;
  selectedStoreFilter: Store | null;
  isRtl?: boolean;
  selectedCategory?: ISelectedCategory;
}

export type dateFilterOption = { label: string; key: string };

export interface ISummaryDateFilter {
  options: Array<SelectedDateIntervals>;
  onChange({ key }: IDropdownOption): void;
  selectedOption: dateFilterOption;
}

export enum LeaderBoardCompareFactor {
  ALL = 'All',
  PEERS = 'Peers',
}

export interface IRateComparisonWrapper {
  setActiveComparisonTab(activeComparisonTab: number): void;
  getRateHistograms(): void;
  selectedStoreFilter: Store | null;
  activeComparisonTab: number;
  isRtl: boolean | undefined;
  peersLevel: string;
  isHitRateLoading: boolean;
  isResponseRateLoading: boolean;
  hitRateData: Array<ITrendsLineChartRow> | null;
  responseRateData: Array<ITrendsLineChartRow> | null;
  hitRateTotal: number;
  rateComparisonTimeSpanMonths: number;
  responseRateTotal: number;
  selectedCategory?: ISelectedCategory;
}

export interface IRateComparisonChartContainer {
  activeComparisonTab: number;
  isRtl: boolean | undefined;
  lineChartData: Array<ITrendsLineChartRow> | null;
  rate: number;
  emptyStateLabel: string;
  peersLevel: string;
  isLoading: boolean;
  onSeeDetailsClick(): void;
}

export enum RateComparisonLabels {
  RESPONSE_RATE = 'Response Rate',
  HIT_RATE = 'Hit Rate',
}

export interface IRateComparisonTab {
  id: number;
  label: string;
}

export const RATE_COMPARISON_TABS = [
  { label: RateComparisonLabels.RESPONSE_RATE, id: 1 },
  { label: RateComparisonLabels.HIT_RATE, id: 2 },
];
