import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';
import './dropdown.scss';

import { ClickOutside } from 'components/clickOutside/ClickOutside';
import { Checkbox } from 'components/checkbox';
import { IDropdown, IDropdownOption, NO_RESULTS } from 'types';
import { ArrowIcon } from 'components/Icons';
import { SearchInput } from 'components/inputs';

export const Dropdown: FC<IDropdown> = ({
  disabled,
  onDropDownOpen,
  options,
  selectedOption,
  onChange,
  icon,
  isSearchableDropdown,
  onClear,
  placeHolder,
  className,
  isRtl,
}) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState(options);

  // update options when changes
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const toggleIsOpen = () => {
    if (disabled) return;
    if (!isOpen && onDropDownOpen) {
      onDropDownOpen();
    }
    setIsOpen(!isOpen);
  };

  const onClearClick = () => {
    setFilteredOptions(options);
    if (onClear) {
      onClear();
    }
  };

  const onSearchTextChanged = (text: string) => {
    if (!isOpen) {
      setIsOpen(true);
    }

    const filtered = options.filter((option) =>
      (option?.label.toLowerCase() ?? '').includes(text.toLowerCase()),
    );

    // if empty push item 'no results'
    if (!filtered.length) {
      filtered.push({ label: t('COMMON.NO_RESULTS'), key: NO_RESULTS });
    }
    setFilteredOptions(filtered);
  };

  const rowRenderer = useCallback(
    (item: IDropdownOption) => {
      const { key, label } = item;

      const isChecked = key === selectedOption?.key;

      const onClick = () => {
        if (key !== NO_RESULTS) {
          onChange(item);
          setIsOpen(false);
        }
      };

      return (
        <div className={cn('dropdown-row', { isChecked })} key={key} onClick={onClick}>
          <span>{label}</span>
          {key !== NO_RESULTS && <Checkbox isChecked={isChecked} />}
        </div>
      );
    },
    [selectedOption, onChange],
  );

  return (
    <ClickOutside onClickOutside={() => setIsOpen(false)}>
      <div
        className={cn(
          'dropdown-container',
          className,
          { disabled },
          isSearchableDropdown && 'searchable',
        )}
      >
        <div className="dropdown-row select-bar" onClick={toggleIsOpen}>
          {icon}
          {isSearchableDropdown ? (
            <div className="search-input-wrapper">
              <SearchInput
                onTextChanged={onSearchTextChanged}
                id={`dropdown-search-input${Math.random()}`}
                placeHolder={placeHolder}
                defaultValue={selectedOption?.label}
                containerClassName="dropdown-search-input"
                onClear={onClearClick}
                isRtl={isRtl}
              />
            </div>
          ) : (
            <span>{selectedOption?.label}</span>
          )}
          {!disabled && !isSearchableDropdown && <ArrowIcon className="arrow-icon" />}
        </div>
        <div className={cn('dropdown-list', { isOpen })}>
          {filteredOptions.map((item) => rowRenderer(item))}
        </div>
      </div>
    </ClickOutside>
  );
};
