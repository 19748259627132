import { createSelector } from 'reselect';

import { IRootState } from 'store/types';

const getLastUserDeployDate = (state: IRootState) => state.userProfile.lastUserDeployDate;

const getUserScopeStatus = (state: IRootState) => state.userProfile.isScopeError;

const haveOpportunitiesBeenDeployed = createSelector(
  [getLastUserDeployDate],
  (lastUserDeployDate) => {
    return lastUserDeployDate !== 0;
  },
);

const isScopeError = createSelector([getUserScopeStatus], (userScopeStatus) => {
  return userScopeStatus !== undefined && userScopeStatus === true;
});

const UserProfileSelector = {
  haveOpportunitiesBeenDeployed,
  isScopeError,
};

export default UserProfileSelector;
