import analyticsService from 'services/analytics/AnalyticSrv';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';
import { IActionType } from 'types';

const userProfileMiddleware = () => (next: any) => (action: IActionType) => {
  const { type, payload } = action;

  switch (type) {
    case UserProfileActionTypes.getUserScope.SUCCESS: {
      const { role = '' } = payload;
      analyticsService.setUserRole(role);
      break;
    }
    default:
      break;
  }

  next(action);
};

export default userProfileMiddleware;
