import './SideMenu.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenuButton } from 'components/buttons/SideMenuButton';
import { ContactIcon, KnowledgeCenterIcon, SettingsIcon } from 'components/Icons';
import { RoutePath } from 'routes/types';
import { IMenuAction, ISideMenuButtonProps } from './types';
import featureFlagsService from 'services/permissions/featureFlagsService';

const SideMenuSettings: FC<ISideMenuButtonProps> = ({
  isMenuCollapsed,
  selectedActionMenuId,
  selectMenuAction,
  mayViewSettings,
}) => {
  const [t] = useTranslation();

  const mayViewKnowledgeCenter: boolean = featureFlagsService.mayViewKnowledgeCenter();

  const menuActions: Array<IMenuAction> = [
    {
      label: t('PAGES.SETTINGS'),
      id: RoutePath.Settings,
      icon: SettingsIcon,
      canUserView: mayViewSettings,
    },
    {
      label: t('PAGES.KNOWLEDGE_CENTER'),
      id: RoutePath.KnowledgeCenter,
      icon: KnowledgeCenterIcon,
      canUserView: mayViewKnowledgeCenter,
    },
    {
      label: t('PAGES.CONTACT_US'),
      id: RoutePath.ContactUs,
      icon: ContactIcon,
      canUserView: true,
    },
  ];

  return (
    <div className="side-menu-settings">
      {menuActions
        .filter((menuAction) => menuAction.canUserView)
        .map((menuAction) => (
          <SideMenuButton
            id={menuAction.id}
            key={menuAction.id}
            icon={menuAction.icon}
            isMenuCollapsed={isMenuCollapsed}
            isActive={menuAction.id === selectedActionMenuId}
            onClick={() => selectMenuAction(menuAction.id)}
            label={menuAction.label}
          />
        ))}
    </div>
  );
};

export default SideMenuSettings;
