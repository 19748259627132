import { FC } from 'react';
import cn from 'clsx';

import { ICategory } from 'types';

const Category: FC<ICategory> = ({ name, value, withSeparator }) => (
  <div className={cn('category-container', { 'with-separator': withSeparator })}>
    <div className="category-name">{name}</div>
    <div className="category-value">{value}</div>
  </div>
);

export default Category;
