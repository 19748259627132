import { FC, useEffect, useState } from 'react';
import cn from 'clsx';
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import './barChart.scss';

import {
  getMonth,
  getShortMonthFormat,
  getShortYearFormat,
  toLanguageNumberFormat,
  toShortCurrencyFormat,
} from 'utils';
import { MILLISECONDS } from 'constants/constantsVaribles';
import { ITrendsBarChart } from 'types';
import YearLineLabel from 'components/trends/yearLinelLabel/YearLineLabel';
import { RightArrow } from 'components/Icons';

const CHART_HEIGHT = 380;

const TICK_MARGIN_AXIS = 10;

const BAR_MARGIN_BOTTOM = 30;

const X_AXIS_PADDING = 10;

const RADIUS = 20;

const BAR_SIZE = 20;

const MAX_DISPLAYED_BARS = 12;

const CELL_ID = 'bar-chart-cell';

const BarChart: FC<ITrendsBarChart> = ({
  selectedMonth,
  isRtl,
  data,
  currency,
  onClick,
  onClearSelection,
}) => {
  const firstShowIndex = Math.max(data.length - MAX_DISPLAYED_BARS, 0);

  const [firstIndex, setFirstIndex] = useState(firstShowIndex);

  useEffect(() => {
    setFirstIndex(firstShowIndex);
  }, [data]);

  const displayedData = data.slice(firstIndex, firstIndex + MAX_DISPLAYED_BARS);

  const formatXAxis = (tickItem: number) => getShortMonthFormat(tickItem);

  const formatYAxis = (value: number) => toShortCurrencyFormat(value, currency, 0);

  const onBarClick = (clickedBar: { date: number }) => onClick(clickedBar.date);

  const renderCells = () => {
    return displayedData.map((element) => {
      const { date } = element;

      const isAllCellsActive = !selectedMonth;

      const isActive = date === selectedMonth;

      const isBarChartActive = isActive || isAllCellsActive;

      return <Cell id={CELL_ID} className={cn({ blur: !isBarChartActive })} key={date} />;
    });
  };

  // Adding lines with year before new year start
  const renderReferenceLines = () => {
    // preventing adding line after last month displayed
    const slicedData = displayedData.slice(0, -1);

    // Getting all dates in december that next data in the next year
    const decemberDates = slicedData.filter((item, index) => {
      const isLastMonth = getMonth(item.date) === 12;

      // next data is in January
      const isNextDataIsNextYear = getMonth(displayedData![index + 1].date) === 1;

      return isLastMonth && isNextDataIsNextYear;
    });

    // Adding line of the year after each last month of the year beside the last one
    return decemberDates.map((item) => {
      const { date } = item;

      const text = `'${Number(getShortYearFormat(date)) + 1}`;

      const x = date + MILLISECONDS.WEEK * 2;

      return <ReferenceLine x={x} label={<YearLineLabel text={text} />} />;
    });
  };

  const onPrevClick = () => {
    const prevSection = firstIndex - MAX_DISPLAYED_BARS;

    const nextFirstIndex = Math.max(0, prevSection);

    setFirstIndex(nextFirstIndex);
  };

  const onBackgroundClick = (_: any, e: any) => {
    // Verify the target is the bg and not a specific cell
    if (e?.target?.id !== CELL_ID) {
      // Clear selection on bg click
      onClearSelection();
    }
  };

  const onNextClick = () => {
    const nextSection = firstIndex + MAX_DISPLAYED_BARS;

    const maxIndex = data.length - MAX_DISPLAYED_BARS;

    const nextFirstIndex = Math.min(nextSection, maxIndex);

    setFirstIndex(nextFirstIndex);
  };

  const xAxisValues = displayedData.map((item) => item.date);

  const isPrevArrowVisible = firstIndex !== 0;

  const isNextArrowVisible = firstIndex < data.length - MAX_DISPLAYED_BARS;

  const shouldDisplayPartialLastBar = isPrevArrowVisible || data.length === MAX_DISPLAYED_BARS;

  const ToolTipContent: FC<any> = ({ active, payload }) => {
    const isToolTipVisible = active && payload![0]?.payload;

    if (!isToolTipVisible) {
      return null;
    }

    const { value } = payload[0];

    return (
      <div className="trends-tooltip">
        {currency}
        {toLanguageNumberFormat(value)}
      </div>
    );
  };

  return (
    <div className="bar-chart-wrapper">
      <RightArrow
        onClick={onPrevClick}
        className={cn('prev-arrow', { visible: isPrevArrowVisible })}
      />
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
          <RechartsBarChart
            onClick={onBackgroundClick}
            margin={{ bottom: BAR_MARGIN_BOTTOM }}
            className="trends-bar-chart"
            data={displayedData}
            barSize={BAR_SIZE}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              reversed={isRtl}
              interval={0}
              allowDuplicatedCategory
              type="number"
              ticks={xAxisValues}
              domain={xAxisValues.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
              padding={{ left: X_AXIS_PADDING, right: X_AXIS_PADDING }}
              tickMargin={TICK_MARGIN_AXIS}
              dataKey={xAxisValues.length > 0 ? 'date' : undefined}
              tickFormatter={formatXAxis}
            />
            {renderReferenceLines()}
            <YAxis
              domain={['auto', 'dataMax']}
              orientation={isRtl ? 'right' : 'left'}
              tickMargin={TICK_MARGIN_AXIS}
              dataKey="value"
              tickFormatter={formatYAxis}
            />
            <Bar
              dataKey="value"
              onClick={onBarClick}
              radius={RADIUS}
              background={shouldDisplayPartialLastBar && { radius: RADIUS }}
            >
              {renderCells()}
            </Bar>
            <Tooltip content={ToolTipContent} />
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
      <RightArrow onClick={onNextClick} className={cn({ visible: isNextArrowVisible })} />
    </div>
  );
};

export default BarChart;
