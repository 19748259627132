import { FC, useCallback } from 'react';

import { ToggleButton } from 'components/buttons/toggleButton';
import { ITreeGroup, ITreeOption } from 'types';

interface IOtherHierarchyTree {
  treeSelection?: Array<ITreeGroup>;
  onChange(selection: ITreeOption, treeId: string): void;
}

const OtherHierarchyTree: FC<IOtherHierarchyTree> = ({ treeSelection, onChange }) => {
  const renderTreeOptions = useCallback((options: Array<ITreeOption>, groupId: string) => {
    return options.map((option) => {
      const { label, isChecked } = option;

      return (
        <ToggleButton
          key={`${groupId}-${label}`}
          onClick={() => onChange(option, groupId)}
          isActive={isChecked}
          text={label}
        />
      );
    });
  }, []);

  if (!treeSelection) {
    return null;
  }

  return (
    <>
      {treeSelection.map((treeGroup) => (
        <div key={`${treeGroup.id}-${treeGroup.title}`} className="tree-group">
          <span className="tree-title">{treeGroup.title}</span>
          <div className="options">{renderTreeOptions(treeGroup.options, treeGroup.id)}</div>
        </div>
      ))}
    </>
  );
};

export default OtherHierarchyTree;
