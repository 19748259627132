import cn from 'clsx';
import { ChangeEvent, createRef, FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

const MODAL_ID = 'cb4-modal';

export interface IModal {
  onClickOutside?: (event: ChangeEvent) => void;
  onEsc(event: HTMLButtonElement): void;
  onKeydown?: (event: ChangeEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  isPrintMode?: boolean;
  withOverlay: boolean;
}

/**
 * Modal component.
 * Is the base (not standalone) component to construct another components with special behavior using composition
 * pattern (Dialog, Popup). Any special abilities and behavior should be implemented in wrappers.
 */
const Modal: FC<IModal> = ({
  onClickOutside,
  onEsc,
  children,
  onKeydown,
  withOverlay,
  isPrintMode,
  ...rest
}) => {
  // parent element of modal
  const [containerEl] = useState(document.createElement('div'));

  // refs
  const contentRef = createRef<HTMLDivElement>();

  const modalRef = createRef<HTMLDivElement>();

  /** Handle click */
  const handleClick = (event: ChangeEvent<any>) => {
    event.stopPropagation();

    if (onClickOutside && modalRef.current === event.target) {
      return onClickOutside(event);
    }

    return event;
  };

  /** Handle KeyDown */
  const handleKeyDown = (event: any) => {
    // in case onKeydown provided onEsc isn't invokes
    if (onKeydown) {
      if (event.stopPropagation) {
        event.stopPropagation();
      }

      return onKeydown(event);
    }

    // onEsc invokes only in case onKeydown Handler isn't provided
    if (onEsc && (event.keyCode === 27 || (event.target && event.target.keyCode === 27))) {
      if (event.stopPropagation) {
        event.stopPropagation();
      }

      return onEsc(event);
    }

    return event;
  };

  useEffect(() => {
    // add containerEl container element to the body
    document.body.appendChild(containerEl);

    // add click listener
    document.body.addEventListener('keydown', handleKeyDown);

    // add special class to body.
    document.body.classList.add(MODAL_ID);

    return () => {
      // remove special class from body
      document.body.classList.remove(MODAL_ID);

      // remove parent element of modal
      document.body.removeChild(containerEl);

      // remove key down listener
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return createPortal(
    <div
      className={cn('modal-container', { withOverlay }, { 'print-mode': isPrintMode })}
      ref={modalRef}
      onClick={handleClick}
    >
      <div className={cn('content', withOverlay && 'withOverlay')} ref={contentRef} {...rest}>
        {children}
      </div>
    </div>,
    containerEl,
  );
};

Modal.displayName = 'Modal';

export default Modal;
