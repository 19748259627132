import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import { FlavourTypes } from 'types';
import ReasonsActionTypes from './reasonsActionTypes';

export const getReasons = (flavour: FlavourTypes) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: { flavour },
    url: '/unifiedpush-server/rest/database/reason?limit=1',
    meta: {
      method: HttpMethod.GET,
    },
    baseAction: ReasonsActionTypes.getReasons,
  };
};

const ReasonsActions = {
  getReasons,
};

export default ReasonsActions;
