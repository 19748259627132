import { flow, set } from 'lodash/fp';
import { LOCATION_CHANGE } from 'connected-react-router';

import { getFilterLabels, getFilterOrderedCategories } from 'store/api/apiParser';
import {
  buildSearchFiltersOnLocationChange,
  clearFilterByCategory,
  getCaseCategory,
  getFilterCategoriesLevels,
  pushSelectedLabelsListByCategory,
} from 'store/filters/filterHelper';
import FiltersActionTypes from 'store/filters/filtersActionTypes';
import MyOpportunitiesActionTypes from 'store/myOpportunities/myOpportunitiesActionTypes';
import SettingsActionTypes from 'store/settings/settingsActionTypes';
import {
  categoryLabelsMapper,
  categoryName,
  DefaultGroupByOption,
  direction,
  filterValue,
  IActionType,
  IMapLabel,
  OpportunitiesTabsOptions,
  orderBy,
} from 'types';
import { IFilterModel, sortAndGroupDefaultInitState } from './types';
import { RoutePath } from 'routes/types';

export const initialState: IFilterModel = {
  isControlMenuExpanded: false,
  myOpportunities: {
    // Active filter
    activeFilter: {
      activeTab: filterValue.OPENED, // Translated to state key
      productCategory: [],
      storeCategory: [],
      storeName: [],
      planogram: [],
      search: '',
      nextPageOffset: 0,
      orderBy: orderBy.salesTarget,
      groupBy: DefaultGroupByOption,
      direction: direction.DESC,
    },
    // Filters UI representation/BackEnd Reflection
    // we build the filters using BE contract, and mark active slicers
    filters: {
      hasFilterModified: false,
      matchFilterOpportunities: null,
      activeTab: filterValue.OPENED, // Translated to state key
      orderBy: sortAndGroupDefaultInitState.orderBy,
      groupBy: sortAndGroupDefaultInitState.groupBy,
      direction: sortAndGroupDefaultInitState.direction,
      search: '',
      totalOpen: 0,
      totalClose: 0,
      totalResponseRate: 0,
      productCategories: [],
      storeCategories: [],
      storeNamesCategories: [],
      planogramAttributes: [],
      activeConcurrentFilter: 0,
      productCategoriesLabels: {
        0: {
          categoryFamily: categoryName.PRODUCT_CATEGORY,
          selectedLabels: [],
          labels: [],
          labelsMap: {},
        },
      },
      storeCategoriesLabels: {
        0: {
          categoryFamily: categoryName.STORE_CATEGORY,
          selectedLabels: [],
          labels: [],
          labelsMap: {},
        },
      },
      storeNamesLabels: {
        0: {
          categoryFamily: categoryName.STORE_NAME,
          selectedLabels: [],
          labels: [],
          labelsMap: {},
        },
      },
      planogramAttributesLabels: {
        0: {
          categoryFamily: categoryName.PLANOGRAM_ATTRIBUTES,
          selectedLabels: [],
          labels: [],
          labelsMap: {},
        },
      },
    },
  },
};

const filtersReducer = (state: IFilterModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    // my opportunities impact on filters
    case MyOpportunitiesActionTypes.setActiveTab: {
      const { activeTab } = payload;

      const newActiveTab =
        activeTab === OpportunitiesTabsOptions.Open ? filterValue.OPENED : filterValue.CLOSED;

      return flow([
        set('myOpportunities[filters][activeTab]', newActiveTab),
        set('myOpportunities[activeFilter].nextPageOffset', 0),
      ])(state);
    }

    case FiltersActionTypes.ToggleControlMenu: {
      const { shouldClose } = payload;

      const { isControlMenuExpanded } = state;

      const { orderBy, groupBy, direction } = state.myOpportunities.activeFilter;

      const controlNewState: Array<typeof set> = [];

      // when filter control (side menu)  is closed we make sure that the active filter data is reflected
      // Meaning that if you didn't apply the filter the prev info will be loaded
      if (isControlMenuExpanded) {
        controlNewState.push(set(`myOpportunities[filters].orderBy`, orderBy));

        controlNewState.push(set(`myOpportunities[filters].groupBy`, groupBy));

        controlNewState.push(set(`myOpportunities[filters][direction][${orderBy}]`, direction));
      }

      // force close or toggle
      const controlMenuState = shouldClose === true ? false : !isControlMenuExpanded;

      controlNewState.push(set('isControlMenuExpanded', controlMenuState));

      return flow(controlNewState)(state);
    }

    case FiltersActionTypes.setActiveTabFilter: {
      const { activeTab } = payload;

      return flow([set('myOpportunities[activeFilter][activeTab]', activeTab)])(state);
    }
    case FiltersActionTypes.setMyOpportunitiesSearch: {
      const { search } = payload;

      return flow([set('myOpportunities[activeFilter][search]', search)])(state);
    }
    case FiltersActionTypes.setFilterSearch: {
      const { search } = payload;

      return flow([set('myOpportunities[filters][search]', search)])(state);
    }
    case SettingsActionTypes.getSettings.SUCCESS: {
      const productCategoriesOrder = payload?.taskListMetadata?.filters?.productCategories;

      const metaDataProductCategories = payload?.productCategories
        ? payload?.productCategories
        : { 0: '' };

      const storeCategoriesOrder = payload?.taskListMetadata?.filters?.storeCategories;

      const metaDataStoreCategories = payload?.storeCategories
        ? payload?.storeCategories
        : { 0: '' };

      const planogramOrder = payload?.taskListMetadata?.filters?.planogram;

      const planogramAttributes = payload?.planogramAttributes ? payload?.planogramAttributes : {};

      const productCategories = getFilterOrderedCategories(
        metaDataProductCategories,
        productCategoriesOrder,
      );

      const storeCategories = getFilterOrderedCategories(
        metaDataStoreCategories,
        storeCategoriesOrder,
      );

      const planogram = getFilterOrderedCategories(planogramAttributes, planogramOrder);

      // Keeping the same convention, this is client side category
      const storeNames = [{ title: 'Store Name', level: 0 }];

      return flow([
        set('myOpportunities[filters][productCategories]', productCategories),
        set('myOpportunities[filters][storeCategories]', storeCategories),
        set('myOpportunities[filters][storeNamesCategories]', storeNames),
        set('myOpportunities[filters][planogramAttributes]', planogram),
      ])(state);
    }

    case FiltersActionTypes.getStoreFilterLabels.SUCCESS:
    case FiltersActionTypes.getProductFilterLabels.SUCCESS:
    case FiltersActionTypes.getStoreNameFilterLabels.SUCCESS:
    case FiltersActionTypes.getPlanogramFilterLabels.SUCCESS: {
      const { caseCategoryMapper, caseCategoryName } = getCaseCategory(type);

      const { index } = requestPayload;

      const { rows } = payload;

      const { activeFilter, filters } = state.myOpportunities;

      const { activeTab } = filters;

      const categoryActiveFilter = activeFilter?.[caseCategoryName]?.[index];

      const { labels, labelsMap } = getFilterLabels(
        activeTab,
        caseCategoryName,
        categoryActiveFilter,
        rows,
      );

      const categoryPrevSelectedLabels: string[] =
        filters?.[caseCategoryMapper]?.[index]?.selectedLabels || [];

      // if there are selected filters (also those that were not yet applied)
      // if prev selected filters labels do not exist in the new list add them to the list with (0) count
      // if prev selected filters labels exist in the new list mark them as selected
      if (categoryPrevSelectedLabels?.length > 0) {
        categoryPrevSelectedLabels.forEach((label) => {
          const existingLabel = Object.values(labelsMap).find((item: any) => item.label === label);

          const encodedLabel = encodeURI(label);

          if (!existingLabel) {
            labels.unshift(encodedLabel);

            const mapLabel: IMapLabel = { label, isSelected: true, count: 0 };
            labelsMap[encodedLabel] = mapLabel;
          } else {
            labelsMap[encodedLabel].isSelected = true;
          }
        });
      }

      return flow([
        set(
          `myOpportunities[filters][${caseCategoryMapper}][${index}][categoryFamily]`,
          caseCategoryName,
        ),
        set(`myOpportunities[filters][${caseCategoryMapper}][${index}][labels]`, labels),
        set(`myOpportunities[filters][${caseCategoryMapper}][${index}][labelsMap]`, labelsMap),
        set(
          `myOpportunities[filters][${caseCategoryMapper}][${index}][selectedLabels]`,
          categoryPrevSelectedLabels,
        ),
      ])(state);
    }

    case FiltersActionTypes.setFilterSelection: {
      const { label, selection, categoryLabelsMapper, index } = payload;

      let updatedSelectedLabels = [
        ...state.myOpportunities.filters?.[categoryLabelsMapper]?.[index].selectedLabels,
      ];

      let stateActiveConcurrentFilter = state.myOpportunities.filters.activeConcurrentFilter;

      if (selection) {
        updatedSelectedLabels.push(label);
        stateActiveConcurrentFilter += 1;
      } else {
        updatedSelectedLabels = updatedSelectedLabels?.filter(
          (selLabel: any) => selLabel !== label,
        );
        stateActiveConcurrentFilter -= 1;
      }

      return flow([
        set(
          `myOpportunities[filters][${categoryLabelsMapper}][${index}][labelsMap][${encodeURI(
            label,
          )}.isSelected]`,
          selection,
        ),
        set(
          `myOpportunities[filters][${categoryLabelsMapper}][${index}][selectedLabels]`,
          updatedSelectedLabels,
        ),
        set(`myOpportunities[filters][activeConcurrentFilter]`, stateActiveConcurrentFilter),
        set(`myOpportunities[filters][hasFilterModified]`, true),
      ])(state);
    }

    case FiltersActionTypes.clearFilters: {
      const {
        storeNamesCategories,
        storeCategories,
        productCategories,
        planogramAttributes,
      } = state.myOpportunities.filters;

      const selectedLabelsToClean: Array<typeof set> = [];

      // Clear Product selected labels/filters
      clearFilterByCategory(
        selectedLabelsToClean,
        getFilterCategoriesLevels(productCategories),
        state.myOpportunities.filters[categoryLabelsMapper.PRODUCT_CATEGORY],
        categoryLabelsMapper.PRODUCT_CATEGORY,
      );

      // Clear Store selected labels/filters
      clearFilterByCategory(
        selectedLabelsToClean,
        getFilterCategoriesLevels(storeCategories),
        state.myOpportunities.filters[categoryLabelsMapper.STORE_CATEGORY],
        categoryLabelsMapper.STORE_CATEGORY,
      );

      // Clear Store names selected labels/filters
      clearFilterByCategory(
        selectedLabelsToClean,
        getFilterCategoriesLevels(storeNamesCategories),
        state.myOpportunities.filters[categoryLabelsMapper.STORE_NAME],
        categoryLabelsMapper.STORE_NAME,
      );

      // Clear planogram selected labels/filters
      clearFilterByCategory(
        selectedLabelsToClean,
        getFilterCategoriesLevels(planogramAttributes),
        state.myOpportunities.filters[categoryLabelsMapper.PLANOGRAM_ATTRIBUTES],
        categoryLabelsMapper.PLANOGRAM_ATTRIBUTES,
      );

      // Clean filter counter
      selectedLabelsToClean.push(set(`myOpportunities[filters][activeConcurrentFilter]`, 0));

      // Reset active filter
      selectedLabelsToClean.push(
        set(`myOpportunities[activeFilter][${categoryName.PRODUCT_CATEGORY}]`, []),
      );

      selectedLabelsToClean.push(
        set(`myOpportunities[activeFilter][${categoryName.STORE_CATEGORY}]`, []),
      );

      selectedLabelsToClean.push(
        set(`myOpportunities[activeFilter][${categoryName.STORE_NAME}]`, []),
      );

      selectedLabelsToClean.push(
        set(`myOpportunities[activeFilter][${categoryName.PLANOGRAM_ATTRIBUTES}]`, []),
      );

      // Reset Active Filter order,group and direction
      selectedLabelsToClean.push(
        set(`myOpportunities[activeFilter].orderBy`, sortAndGroupDefaultInitState.orderBy),
      );

      selectedLabelsToClean.push(
        set(`myOpportunities[activeFilter].groupBy`, sortAndGroupDefaultInitState.groupBy),
      );

      // Reset Filter UI order,group and direction

      selectedLabelsToClean.push(set(`myOpportunities[activeFilter].direction`, direction.DESC));

      selectedLabelsToClean.push(
        set(`myOpportunities[filters].orderBy`, sortAndGroupDefaultInitState.orderBy),
      );

      selectedLabelsToClean.push(
        set(`myOpportunities[filters].groupBy`, sortAndGroupDefaultInitState.groupBy),
      );

      selectedLabelsToClean.push(
        set(`myOpportunities[filters].direction`, sortAndGroupDefaultInitState.direction),
      );

      selectedLabelsToClean.push(set(`myOpportunities[filters][hasFilterModified]`, false));

      return flow(selectedLabelsToClean)(state);
    }

    case FiltersActionTypes.applyMyOpportunitiesFilters: {
      const {
        storeCategories,
        productCategories,
        storeCategoriesLabels,
        productCategoriesLabels,
        storeNamesCategories,
        storeNamesLabels,
        planogramAttributes,
        planogramAttributesLabels,
        groupBy,
        orderBy,
        direction,
      } = state.myOpportunities.filters;

      const currentSortingDirection = direction[orderBy];

      const {
        groupBy: activeGroupBy,
        orderBy: activeOrderBy,
        direction: activeDirection,
      } = state.myOpportunities.activeFilter;

      const newFilterState: Array<typeof set> = [];

      pushSelectedLabelsListByCategory(
        newFilterState,
        getFilterCategoriesLevels(productCategories),
        productCategoriesLabels,
        categoryName.PRODUCT_CATEGORY,
      );

      pushSelectedLabelsListByCategory(
        newFilterState,
        getFilterCategoriesLevels(storeCategories),
        storeCategoriesLabels,
        categoryName.STORE_CATEGORY,
      );

      pushSelectedLabelsListByCategory(
        newFilterState,
        getFilterCategoriesLevels(storeNamesCategories),
        storeNamesLabels,
        categoryName.STORE_NAME,
      );

      pushSelectedLabelsListByCategory(
        newFilterState,
        getFilterCategoriesLevels(planogramAttributes),
        planogramAttributesLabels,
        categoryName.PLANOGRAM_ATTRIBUTES,
      );

      newFilterState.push(set(`myOpportunities[activeFilter].orderBy`, orderBy));
      newFilterState.push(set(`myOpportunities[activeFilter].groupBy`, groupBy));
      newFilterState.push(set(`myOpportunities[activeFilter].direction`, currentSortingDirection));
      newFilterState.push(set(`myOpportunities[filters][hasFilterModified]`, false));

      // if sorting/grouping/direction has change we should fetch from the beginning
      if (
        activeDirection[orderBy] !== currentSortingDirection ||
        activeOrderBy !== orderBy ||
        activeGroupBy !== groupBy
      ) {
        newFilterState.push(set('myOpportunities[activeFilter].nextPageOffset', 0));
      }

      return flow(newFilterState)(state);
    }

    case FiltersActionTypes.updateLoadMoreOffset: {
      const { nextPageOffset } = payload;

      return flow([set('myOpportunities[activeFilter].nextPageOffset', nextPageOffset)])(state);
    }

    case FiltersActionTypes.setMyOpportunitiesFiltersOrder: {
      const { orderBy } = payload;

      const { orderBy: stateOrderBy, direction: stateDirection } = state.myOpportunities.filters;

      const filter = [
        set('myOpportunities[filters].orderBy', orderBy),
        set(`myOpportunities[filters][hasFilterModified]`, true),
      ];

      // if we clicked the same sort button
      if (stateOrderBy === orderBy) {
        const newSortDirection =
          stateDirection[orderBy] === direction.ASC ? direction.DESC : direction.ASC;
        filter.push(set(`myOpportunities[filters][direction][${orderBy}]`, newSortDirection));
      }

      return flow(filter)(state);
    }

    case FiltersActionTypes.setMyOpportunitiesGroupByOrder: {
      const { groupBy } = payload;

      return flow([
        set('myOpportunities[filters].groupBy', groupBy),
        set(`myOpportunities[filters][hasFilterModified]`, true),
      ])(state);
    }

    case FiltersActionTypes.getMyFilterStats.SUCCESS: {
      const { rows } = payload;

      const { activeTab } = requestPayload;

      let matchFilterOpportunities = 0;
      rows.forEach((row: { state: any; count: number }) => {
        if (row.state === activeTab) {
          matchFilterOpportunities += row.count;
        }
      });

      return flow([
        set('myOpportunities[filters].matchFilterOpportunities', matchFilterOpportunities),
      ])(state);
    }

    // listen for location change and in case this is a
    // deep link (location.state.isDeepLink || router.isFirstRendering)
    // then update filters with search params
    case LOCATION_CHANGE: {
      const { location, isFirstRendering, action } = payload;

      const searchFilters = buildSearchFiltersOnLocationChange(
        location,
        action,
        isFirstRendering,
        RoutePath.MyOpportunities,
      );

      if (searchFilters) {
        const { tab } = searchFilters;

        const parsedTab = tab
          ? (Number(tab) as OpportunitiesTabsOptions)
          : OpportunitiesTabsOptions.Open;

        const activeTabFilter =
          parsedTab === OpportunitiesTabsOptions.Open ? filterValue.OPENED : filterValue.CLOSED;

        return flow([
          set('myOpportunities[filters][activeTab]', activeTabFilter),
          set('myOpportunities[activeFilter][activeTab]', activeTabFilter),
        ])(state);
      }

      return state;
    }
    default:
      return state;
  }
};

export default filtersReducer;
