// Opportunities
import i18n from 'i18n/i18n';
import {
  ActionTypes,
  ActionTypeTranslationId,
  DrillDownByEarningApi,
  DrillDownByHitRateApi,
  DrillDownByResponseRateApi,
  DrillDownProductByResponseRateApi,
  DrillDownProductCategoryByEarningApi,
  DrillDownProductCategoryByResponseRateApi,
  DrillDownProductsByEarningApi,
  DrillDownProductsByHitRateApi,
  DrillDownStoreCategoryByEarningApi,
  DrillDownStoreCategoryByResponseRateApi,
  DrillDownStoresByEarningApi,
  DrillDownStoresByHitRateApi,
  DrillDownStoresByResponseRateApi,
  fieldName,
  filterValue,
  FlavourTypes,
  FreeTextOptions,
  groupBy,
  HierarchyLevel,
  HitRateHistogramApi,
  hitRateScoreRequiredFields,
  IDrillDownRow,
  IFeatureFlag,
  IGroupByOption,
  IGroupBySource,
  IHierarchyPath,
  IHitRateComparisonApiResponseRow,
  IHitRateScore,
  IInventoryRawData,
  ILeaderBoardRow,
  IMyOpportunity,
  IOpenOpportunitiesDrillDownCard,
  IRateRow,
  IReasonApi,
  IReasonFilterGroupsMap,
  IReasonFilterToColorMap,
  IReasonsApi,
  IReasonsModel,
  IResponseRateComparisonApiResponseRow,
  ISalesValues,
  ISettingsModel,
  IVideo,
  LeaderBoardResponseApi,
  mapHierarchyLevelToOrder,
  mapResponseHierarchyToHierarchyLevel,
  OpenOpportunitiesApiStoreResponseTypes,
  OpenOpportunitiesCardTypes,
  OpenOpportunitiesDropDownOptionsTypes,
  OpenOpportunitiesRowApi,
  OpportunitiesCounter,
  ProductImagesMetadataOptions,
  ProductImagesMetadataTypes,
  RateHistogram,
  ReasonsGroups,
  ReasonState,
  ResponseRateHistogramApi,
  SalesIncreaseMyHistogram,
  SalesIncreaseMyHistogramApi,
  Store,
  StoreBreakdownResponse,
} from 'types';
import { IAuthUserInfo, IUserScopeInfo } from 'services/auth/keycloak/core';
import {
  getLongDateFormat,
  getMilliseconds,
  getObjectKeys,
  isEmpty,
  isEmptyArray,
  secondsToMilliSeconds,
  toMoment,
} from 'utils';
import { ReasonFilterGroupColors } from 'constants/Colors';
import { PRODUCT_KEY, SIZE_KEY, SKU_KEY } from 'constants/constantsVaribles';

export const getOpportunitySalesValues = (apiOpportunitySales: any) => {
  let total = 0;

  if (!apiOpportunitySales) {
    return {
      SalesValues: null,
      total,
    };
  }

  const SalesValues: ISalesValues = [];

  apiOpportunitySales.forEach((sale: any) => {
    if (sale.summable) {
      total += sale.value;
    }
    SalesValues.push({
      date: sale.date ? sale.date : 0,
      summable: sale.summable ? sale.summable : false,
      value: sale.value ? sale.value : 0,
    });
  });

  return {
    SalesValues,
    total,
  };
};

// Get product image with the best resolution
const getProductImage = (
  images: { [key in ProductImagesMetadataTypes]?: string },
  productImagesMetadata: Array<string>,
) => {
  let productImages: any = { highResolutionProductImage: null, lowResolutionProductImage: null };

  if (images) {
    // find largest image
    let highResolutionProductImage: string | null = null;
    let lowResolutionProductImage: string | null = null;

    // Go through the resolutions to find the highest and lowest resolution
    // Order - the highest resolution will be first the lowest resolution will be last
    for (let i = 0; i < productImagesMetadata.length; i++) {
      const resolution = productImagesMetadata[i];

      const imageSrc = images[resolution];

      // Image in this resolution exists
      if (imageSrc) {
        // High resolution image didn't set yet - current image is with the highest resolution
        if (!highResolutionProductImage) {
          highResolutionProductImage = imageSrc;
        }

        // If image exists - it's with the lowest resolution found so far
        lowResolutionProductImage = imageSrc;
      }
    }

    productImages = { lowResolutionProductImage, highResolutionProductImage };
  }

  return productImages;
};

export const getOpportunity = (
  apiOpportunity: any,
  productImagesMetadata: Array<string>,
): IMyOpportunity => {
  const id = apiOpportunity.id ? apiOpportunity.id : null;

  const productName = apiOpportunity.productName ? apiOpportunity.productName : null;

  const extendedSkus = apiOpportunity.extendedSkus ? apiOpportunity.extendedSkus : [];

  const { response: apiReason, lifecycle } = apiOpportunity;

  const productSkusAndSizes = apiOpportunity.productSkusAndSizes
    ? apiOpportunity.productSkusAndSizes
    : [];

  const productCategories = apiOpportunity.productCategories
    ? apiOpportunity.productCategories
    : null;

  const storeName = apiOpportunity.storeName ? apiOpportunity.storeName : null;

  const storeId = apiOpportunity.storeId ? apiOpportunity.storeId : '';

  const storeIdentifier = apiOpportunity.storeIdentifier ? apiOpportunity.storeIdentifier : '';

  const deployDate = apiOpportunity.deployDate ? apiOpportunity.deployDate : 0;

  const originalDeployDate = apiOpportunity.originalDeployDate || 0;

  const targetSales = apiOpportunity.targetSales ? apiOpportunity.targetSales : null;

  const targetUnits = apiOpportunity.targetUnits ? apiOpportunity.targetUnits : null;

  const potentialRevenue = apiOpportunity.potentialRevenue || 0;

  const potentialUnitsSales = apiOpportunity.potentialUnitsSales || 0;

  const planogramAttributes = apiOpportunity.planogramAttributes
    ? apiOpportunity.planogramAttributes
    : null;

  const { highResolutionProductImage, lowResolutionProductImage } = getProductImage(
    apiOpportunity.productImages,
    productImagesMetadata,
  );

  const state = apiOpportunity.state || '';

  const flavour = apiOpportunity.flavour || '';

  const action = apiOpportunity.action || '';

  const inventoryLatestUpdateDate = apiOpportunity.inventoryLatestUpdateDate || null;

  const inventoryLevel = apiOpportunity.inventoryLevel || null;

  const lastSalesValues = getOpportunitySalesValues(apiOpportunity.lastSalesValues);

  const lastUnitsValues = getOpportunitySalesValues(apiOpportunity.lastUnitsValues);

  const postLastSalesValues = getOpportunitySalesValues(apiOpportunity.postLastSalesValues);

  const lastSimilarSalesValues = getOpportunitySalesValues(apiOpportunity.lastSimilarSalesValues);

  const lastSimilarUnitsValues = getOpportunitySalesValues(apiOpportunity.lastSimilarUnitsValues);

  let selectedReason;
  if (apiReason) {
    const { reasonId, updateDate, freeText: description, responseType } = apiReason;

    const updatedReasonId = reasonId ? Number(reasonId) : undefined;
    selectedReason = { reasonId: updatedReasonId, updateDate, description, responseType };
  }

  return {
    id,
    deployDate,
    productName,
    productSkusAndSizes,
    productCategories,
    storeName,
    targetSales,
    action,
    flavour,
    targetUnits,
    highResolutionProductImage,
    lowResolutionProductImage,
    state,
    selectedReason,
    lifecycle,
    originalDeployDate,
    inventoryLatestUpdateDate,
    inventoryLevel,
    potentialRevenue,
    potentialUnitsSales,
    planogramAttributes,
    postLastSalesValues: postLastSalesValues.SalesValues || [],
    lastSalesValues: lastSalesValues.SalesValues || [],
    lastUnitsValues: lastUnitsValues.SalesValues,
    lastSalesValuesTotal: lastSalesValues.total,
    lastUnitsValuesTotal: lastUnitsValues.total,
    lastSimilarSalesValues: lastSimilarSalesValues.SalesValues || [],
    lastSimilarUnitsValues: lastSimilarUnitsValues.SalesValues,
    lastSimilarSalesValuesTotal: lastSimilarSalesValues.total,
    lastSimilarUnitsValuesTotal: lastSimilarUnitsValues.total,
    storeId,
    storeIdentifier,
    extendedSkus,
  };
};

export const getKnowledgeCenterVideos = (videos: Array<any>): Array<IVideo> =>
  videos.map((video) => ({
    id: video.id || '',
    videoSrc: video.src || '',
    posterSrc: video.poster || '',
    title: video.title || '',
    duration: video.duration ? secondsToMilliSeconds(video.duration) : 0,
    date: video.date || 0,
    info: video.info || '',
    supportedSubtitles: video.supportedSubtitles || [],
  }));

const isReasonRelevant = (reason: IReasonApi, flavour: FlavourTypes) => {
  const isUserFlavour = reason.flavour === flavour;

  const isSupportedAction = reason.action === ActionTypes.AV || reason.action === ActionTypes.IA;

  const isSelectable = reason.selectable;

  return isSelectable && isSupportedAction && isUserFlavour;
};

export const getUpdateGroupCountAndGroupContent = (
  groupCount: any,
  groupHeader: any,
  opportunityToRemoveIndex: number,
) => {
  const opportunitiesGroupCount = [...groupCount];

  const opportunitiesGroupContent = [...groupHeader];
  let counter = 0;
  let index = 0;

  // Finding the group the opportunity related to
  while (index < groupCount.length && counter <= opportunityToRemoveIndex) {
    counter += groupCount[index];
    index += 1;
  }

  const groupIndex = index - 1;

  if (opportunitiesGroupCount![groupIndex]) {
    opportunitiesGroupCount[groupIndex] -= 1;

    // It was the last reason on the group
    if (opportunitiesGroupCount[groupIndex] === 0) {
      opportunitiesGroupCount.splice(groupIndex, 1);
      opportunitiesGroupContent.splice(groupIndex, 1);
    }
  }

  return { opportunitiesGroupContent, opportunitiesGroupCount };
};

export const getReasons = (
  apiReasons: Array<{ content?: { reasons: IReasonsApi } }>,
  flavour: FlavourTypes,
): IReasonsModel => {
  const reasonsList = apiReasons![0]?.content?.reasons;

  if (!reasonsList) {
    return { groups: { AV: [], IA: [] }, mapReasonIdToText: {} };
  }

  const mapReasonIdToText = {};

  const { groups, doneReasons, undoneReasons } = reasonsList;

  const possibleGroupsAmount = Object.keys(groups).length;

  const groupsOptions: ReasonsGroups = {
    AV: new Array(possibleGroupsAmount),
    IA: new Array(possibleGroupsAmount),
  };

  const reasons = [...doneReasons, ...undoneReasons];

  for (let index = 0; index < reasons.length; index++) {
    const currentReason = reasons[index];

    // Check if the current reason is relevant for this user
    if (isReasonRelevant(currentReason, flavour)) {
      const groupIndex = Number(currentReason.groupId);

      const currentGroupReason = groupsOptions[currentReason.action][groupIndex];

      const formattedReason = {
        isReasonDescriptionMandatory: currentReason.freeText === FreeTextOptions.MANDATORY,
        id: currentReason.id,
        reasonState: currentReason.done ? ReasonState.DONE : ReasonState.NOT_DONE,
        minimumCharsCount: currentReason.minimalChars,
        text: currentReason.text,
      };
      mapReasonIdToText[currentReason.id] = currentReason.text;
      if (currentGroupReason) {
        // The group is already exists
        // Add this reason to the rest of the options
        currentGroupReason.options.push(formattedReason);
      } else {
        // First reason for this group
        const groupTitle = groups[groupIndex].text;
        // Add all group details
        groupsOptions[currentReason.action][groupIndex] = {
          options: [formattedReason],
          label: groupTitle,
          order: groupIndex,
        };
      }
    }
  }

  // Remove empty groups
  return {
    mapReasonIdToText,
    groups: {
      AV: groupsOptions.AV.filter((option) => option),
      IA: groupsOptions.IA.filter((option) => option),
    },
  };
};

export const MovePrvSelectedVideoToEndOfQueue = (
  videos: Array<IVideo>,
  lastSelectedVideoId: string,
) => {
  const lastSelectedVideo = videos.find((video) => video.id === lastSelectedVideoId);
  let updatedVideos = videos;

  if (lastSelectedVideo) {
    updatedVideos = [...videos.filter((video) => video !== lastSelectedVideo)];
    updatedVideos.push(lastSelectedVideo);
  }

  return updatedVideos;
};

const getOpportunitiesGroupHeader = (
  opportunity: IMyOpportunity,
  opportunitiesGroupBy: IGroupByOption,
) => {
  let header = '';

  if (opportunitiesGroupBy.source === IGroupBySource.DEFAULT) {
    switch (opportunitiesGroupBy.key) {
      case groupBy.receivedWeek: {
        const week = getLongDateFormat(opportunity[fieldName.deployDate]);
        header = i18n.t('MY_OPPORTUNITIES.WEEK_OF', { week });

        break;
      }
      case groupBy.opportunityType: {
        header =
          opportunity[fieldName.action] === ActionTypes.AV
            ? ActionTypeTranslationId.AV
            : ActionTypeTranslationId.IA;

        break;
      }
      case groupBy.storeName: {
        header = opportunity[fieldName.storeName];

        break;
      }
      default:
        break;
    }
  } else {
    const keyValue = opportunitiesGroupBy.key.split('.');

    const key = keyValue[0];

    const value = Number(keyValue?.[1]);

    header = `${opportunity[key][value]} (${opportunitiesGroupBy.value})`;
  }

  return header;
};

export const getOpportunities = (
  apiOpportunities: any,
  groupBy: IGroupByOption,
  productImagesMetadata: Array<string>,
) => {
  const opportunities = [];

  const cardsGroupCount: Array<number> = [];

  const cardsGroupHeader: Array<string> = [];

  let opportunity = null;

  if (apiOpportunities && apiOpportunities.length > 0) {
    let groupCount = 0;
    let groupHeader = getOpportunitiesGroupHeader(apiOpportunities[0], groupBy);

    cardsGroupHeader.push(groupHeader);
    let currentOppHeader = groupHeader;

    for (let i = 0; i < apiOpportunities.length; i++) {
      currentOppHeader = getOpportunitiesGroupHeader(apiOpportunities[i], groupBy);

      if (currentOppHeader === groupHeader) {
        groupCount += 1;
      } else {
        // new group header
        // init counter
        cardsGroupCount.push(groupCount);
        groupCount = 1;

        // update header name
        groupHeader = currentOppHeader;
        cardsGroupHeader.push(groupHeader);
      }

      opportunity = getOpportunity(apiOpportunities[i], productImagesMetadata);
      opportunities.push(opportunity);
    }

    cardsGroupCount.push(groupCount);
  }

  return {
    opportunities,
    cardsGroupCount,
    cardsGroupHeader,
  };
};

export const getUserInfo = (apiUserInfo: IAuthUserInfo): any => ({
  email: apiUserInfo.email ? apiUserInfo.email : '',
  emailVerified: apiUserInfo.email_verified ? apiUserInfo.email_verified : '',
  preferredUsername: apiUserInfo.preferred_username ? apiUserInfo.preferred_username : '',
  sub: apiUserInfo.email ? apiUserInfo.email : '',
  mayViewDashboard: apiUserInfo.mayViewDashboard ?? true,
  alias: apiUserInfo.alias ? apiUserInfo.alias : '',
});

const getStoresList = (apiUserScopeStores: any) => {
  const stores: Array<Store> = [];

  if (!apiUserScopeStores) {
    return stores;
  }

  Object.keys(apiUserScopeStores).forEach((id: string) =>
    stores.push({ id, storeName: apiUserScopeStores[id] }),
  );

  return stores;
};

const getHierarchyPath = (hierarchyPath: object = {}) => {
  const userHighestHierarchyPath: Array<{ level: HierarchyLevel; id: string }> = [];

  Object.keys(hierarchyPath).forEach((hierarchyId: string) => {
    const level = mapResponseHierarchyToHierarchyLevel[hierarchyId];

    userHighestHierarchyPath.push({
      level,
      id: hierarchyPath[hierarchyId],
    });
  });

  return userHighestHierarchyPath.sort(
    (hierarchy1, hierarchy2) =>
      mapHierarchyLevelToOrder[hierarchy1.level] - mapHierarchyLevelToOrder[hierarchy2.level],
  );
};

const getUserHighestHierarchy = (userHighestHierarchyLowestLevel: Object) => {
  let highestHierarchy: IHierarchyPath = { id: 'all', level: HierarchyLevel.ALL };
  if (!isEmpty(userHighestHierarchyLowestLevel)) {
    const [key, id] = Object.entries(userHighestHierarchyLowestLevel)[0];

    const level = mapResponseHierarchyToHierarchyLevel[key];
    highestHierarchy = { id, level };
  }

  return highestHierarchy;
};

const getIsAllUser = (
  userHighestHierarchyPath: Array<IHierarchyPath>,
  userHighestHierarchyLowestLevel: IHierarchyPath,
) => {
  return (
    userHighestHierarchyPath.length === 1 &&
    userHighestHierarchyLowestLevel?.level === HierarchyLevel.ALL
  );
};

const getPeersLevel = (userHighestHierarchyPath: Array<IHierarchyPath>) => {
  let hierarchy = null;

  if (userHighestHierarchyPath && userHighestHierarchyPath.length > 2) {
    hierarchy = userHighestHierarchyPath[userHighestHierarchyPath.length - 2];
  }

  return hierarchy;
};

// TODO add proper parser
export const getUserScope = (apiUserScope: IUserScopeInfo): any => {
  const userHighestHierarchyPath = getHierarchyPath(apiUserScope.userHighestHierarchyPath);

  const userHighestHierarchyLowestLevel = getUserHighestHierarchy(
    apiUserScope.userHighestHierarchyLowestLevel,
  );

  return {
    stores: getStoresList(apiUserScope.stores),
    userHighestHierarchyPath,
    userHighestHierarchyLowestLevel,
    isAllUser: getIsAllUser(userHighestHierarchyPath, userHighestHierarchyLowestLevel),
    userPeersLevel: getPeersLevel(userHighestHierarchyPath),
    userHierarchyPaths: getHierarchyPath(apiUserScope.userHierarchyPaths![0] || {}),
    firstTenantDeployDate: apiUserScope.firstTenantDeployDate
      ? getMilliseconds(toMoment(apiUserScope.firstTenantDeployDate))
      : 0,
    lastTenantDeployDate: apiUserScope.lastTenantDeployDate
      ? getMilliseconds(toMoment(apiUserScope.lastTenantDeployDate))
      : 0,
    firstUserDeployDate: apiUserScope.firstUserDeployDate
      ? getMilliseconds(toMoment(apiUserScope.firstUserDeployDate))
      : 0,
    lastUserDeployDate: apiUserScope.lastUserDeployDate
      ? getMilliseconds(toMoment(apiUserScope.lastUserDeployDate))
      : 0,
    dashboards: apiUserScope.dashboards ? apiUserScope.dashboards : '',
    role: apiUserScope.role ? apiUserScope.role : '',
    alias: apiUserScope.alias ? apiUserScope.alias : '',
    selfReportTimeSpent: apiUserScope.selfReportTimeSpent
      ? apiUserScope.selfReportTimeSpent
      : false,
    mayViewSalesIncrease: apiUserScope.dashboards
      ? apiUserScope.dashboards.includes('EARNINGS')
      : false,
    projectIds: apiUserScope.projectIds ? apiUserScope.projectIds : '',
    fullName: apiUserScope.fullName ? apiUserScope.fullName : '',
    mayViewDashboard: apiUserScope.mayViewDashboard ? apiUserScope.mayViewDashboard : false,
    mayViewOpportunities: apiUserScope.mayViewTask ? apiUserScope.mayViewTask : false,
    availableStoreHierarchyLevels: apiUserScope.availableStoreHierarchyLevels
      ? apiUserScope.availableStoreHierarchyLevels
      : '',
    availableProductHierarchyLevels: apiUserScope.availableProductHierarchyLevels
      ? apiUserScope.availableProductHierarchyLevels
      : '',
  };
};

export const getProductImagesMetadata = (productImagesMetadata?: ProductImagesMetadataOptions) => {
  if (!productImagesMetadata) {
    return [];
  }

  return getObjectKeys(productImagesMetadata).sort(
    (a, b) => productImagesMetadata[b].relativeSize - productImagesMetadata[a].relativeSize,
  );
};

export const getSettings = (apiMetaData: any): ISettingsModel => {
  const firstDayOfWeek = apiMetaData?.featureFlags?.firstDayOfWeek
    ? apiMetaData?.featureFlags?.firstDayOfWeek
    : 'MONDAY';

  const flavour = apiMetaData?.featureFlags?.flavour
    ? apiMetaData?.featureFlags?.flavour
    : 'ASSORTMENT';

  const showInventoryData = apiMetaData?.featureFlags?.inventoryData
    ? apiMetaData?.featureFlags?.inventoryData
    : false;

  const showLeaderBoard = apiMetaData?.featureFlags?.leaderBoard
    ? apiMetaData?.featureFlags?.leaderBoard
    : false;

  const showPotentialRevenue = apiMetaData?.featureFlags?.potentialRevenue
    ? apiMetaData?.featureFlags?.potentialRevenue
    : false;

  const showPotentialUnits = apiMetaData?.featureFlags?.potentialUnits
    ? apiMetaData?.featureFlags?.potentialUnits
    : false;

  const showTargetSales = apiMetaData?.featureFlags?.targetSales
    ? apiMetaData?.featureFlags?.targetSales
    : false;

  const showTargetUnits = apiMetaData?.featureFlags?.targetUnits
    ? apiMetaData?.featureFlags?.targetUnits
    : false;

  const isAdditionalProductInfoEnabled = apiMetaData?.featureFlags?.generalMetadata
    ?.additionalProductInfoEnabled
    ? apiMetaData?.featureFlags?.generalMetadata?.additionalProductInfoEnabled
    : false;

  const isInAppMessagingEnabled = apiMetaData?.featureFlags?.generalMetadata?.inAppMessagingEnabled
    ? apiMetaData?.featureFlags?.generalMetadata?.inAppMessagingEnabled
    : true;

  const isLiveInventoryEnabled = apiMetaData?.featureFlags?.generalMetadata?.liveInventoryEnabled
    ? apiMetaData?.featureFlags?.generalMetadata?.liveInventoryEnabled
    : false;

  const showOnboardingVideoOnFirstTimeLogin = apiMetaData?.featureFlags?.generalMetadata
    ?.showOnboardingVideoOnFirstTimeLogin
    ? apiMetaData?.featureFlags?.generalMetadata?.showOnboardingVideoOnFirstTimeLogin
    : true;

  const showProductImages = apiMetaData?.featureFlags?.generalMetadata?.showProductImages
    ? apiMetaData?.featureFlags?.generalMetadata?.showProductImages
    : false;

  const showTaskUnitsGraph = apiMetaData?.featureFlags?.showTaskUnitsGraph
    ? apiMetaData?.featureFlags?.showTaskUnitsGraph
    : false;

  const showResponseImpactOnSales = apiMetaData?.featureFlags?.generalMetadata
    ?.showResponseImpactOnSales
    ? apiMetaData?.featureFlags?.generalMetadata?.showResponseImpactOnSales
    : false;

  const isVideoKnowledgeCenterEnabled = apiMetaData?.featureFlags?.generalMetadata
    ?.videoKnowledgeCenterEnabled
    ? apiMetaData?.featureFlags?.generalMetadata?.videoKnowledgeCenterEnabled
    : false;

  const featureFlags: IFeatureFlag = {
    showInventoryData,
    showLeaderBoard,
    showPotentialRevenue,
    showPotentialUnits,
    showTargetSales,
    showTargetUnits,
    showTaskUnitsGraph,
    isAdditionalProductInfoEnabled,
    isInAppMessagingEnabled,
    isLiveInventoryEnabled,
    showOnboardingVideoOnFirstTimeLogin,
    showProductImages,
    showResponseImpactOnSales,
    isVideoKnowledgeCenterEnabled,
  };

  const productImagesMetadata = apiMetaData?.featureFlags?.generalMetadata?.productImagesMetadata
    ? getProductImagesMetadata(apiMetaData?.featureFlags?.generalMetadata?.productImagesMetadata)
    : [];

  const additionalProductInfoAddress = apiMetaData?.featureFlags?.generalMetadata
    ?.additionalProductInfoAddress
    ? apiMetaData?.featureFlags?.generalMetadata?.additionalProductInfoAddress
    : null;

  const currency = apiMetaData?.featureFlags?.generalMetadata?.currency
    ? apiMetaData?.featureFlags?.generalMetadata?.currency
    : '€';

  const language = apiMetaData?.featureFlags?.generalMetadata?.language
    ? apiMetaData?.featureFlags?.generalMetadata?.language
    : 'en_US';

  const general = {
    additionalProductInfoAddress,
    currency,
    productImagesMetadata,
  };

  const taskExpiryDays = {
    availability: apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.availability
      ? apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.availability
      : 28,
    increaseOrder: apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.increaseOrder
      ? apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.increaseOrder
      : 28,
    introduceToAssortment: apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays
      ?.introduceToAssortment
      ? apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.introduceToAssortment
      : 28,
    order: apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.order
      ? apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.order
      : 28,
    visibility: apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.visibility
      ? apiMetaData?.featureFlags?.generalMetadata?.taskExpiryDays?.visibility
      : 28,
  };

  const taskItemsHeaders = apiMetaData?.featureFlags?.generalMetadata?.taskItemsHeaders
    ? apiMetaData?.featureFlags?.generalMetadata?.taskItemsHeaders
    : [
        {
          value: '',
          title: '',
        },
      ];

  const productHierarchy = apiMetaData?.productHierarchy
    ? apiMetaData?.productHierarchy
    : { 1: '' };

  const productCategories = apiMetaData?.productCategories
    ? apiMetaData?.productCategories
    : { 0: '' };

  const storeCategories = apiMetaData?.storeCategories ? apiMetaData?.storeCategories : { 0: '' };

  const storeHierarchy = apiMetaData?.storeHierarchy ? apiMetaData?.storeHierarchy : { 1: '' };

  const messagingMetadata = apiMetaData?.messagingMetadata ? apiMetaData?.messagingMetadata : {};

  const notifications = apiMetaData?.notifications ? apiMetaData?.notifications : {};

  const planogramAttributes = apiMetaData?.planogramAttributes
    ? apiMetaData?.planogramAttributes
    : {};

  const storeHierarchyDepth = getObjectKeys(storeHierarchy).filter(
    (key) => storeHierarchy[key] !== '',
  ).length;

  const productHierarchyDepth = getObjectKeys(productHierarchy).filter(
    (key) => productHierarchy[key] !== '',
  ).length;

  return {
    featureFlags,
    general,
    taskItemsHeaders,
    taskExpiryDays,
    firstDayOfWeek,
    flavour,
    messagingMetadata,
    notifications,
    productCategories,
    productHierarchy,
    storeCategories,
    storeHierarchy,
    storeHierarchyDepth,
    productHierarchyDepth,
    planogramAttributes,
    language,
    serverResponseStatus: null,
  };
};

export const getFilterLabels = (
  activeTab: string,
  category: string,
  categoryActiveFilter: any,
  apiLabels: any,
): any => {
  const labels = [];

  const labelsMap = {};
  let labelInfo = null;
  let isSelected = null;
  let labelName = null;

  // filter only rows that are not 0
  const filteredLabels = apiLabels.filter((row: any) => {
    return activeTab === filterValue.OPENED ? row.openedTasks > 0 : row.closedTasks > 0;
  });

  for (let i = 0; i < filteredLabels.length; i++) {
    labelInfo = filteredLabels[i];
    isSelected = false;
    labelName = labelInfo[category];

    if (labelName) {
      labels.push(encodeURI(labelName));
      if (categoryActiveFilter) {
        const { selectedLabels } = categoryActiveFilter;
        isSelected = selectedLabels.includes(labelName);
      }

      labelsMap[encodeURI(labelName)] = {
        label: labelName,
        count: activeTab === filterValue.OPENED ? labelInfo.openedTasks : labelInfo.closedTasks,
        isSelected,
      };
    }
  }

  return { labels, labelsMap };
};

export const getFindingsReasonsFilterGroup = (
  apiStatistic: Array<{ reasonId: string; count: number }>,
  reasons: ReasonsGroups,
  reasonFilterToColorMap: IReasonFilterToColorMap,
): {
  reasonFilterGroups: Array<number>;
  reasonFilterGroupsMap: IReasonFilterGroupsMap;
  reasonFilterToColorMap: IReasonFilterToColorMap;
} => {
  const allReasons = [...reasons.AV, ...reasons.IA];

  const reasonFilterGroupsMap: IReasonFilterGroupsMap = {};

  for (let i = 0; i < apiStatistic.length; i++) {
    const { reasonId, count: value } = apiStatistic[i];

    const currentReasonId = Number(reasonId);

    // Find the current reason id group
    const group = allReasons.find((reasonGroup) =>
      reasonGroup.options.find((reason) => reason.id === currentReasonId),
    );

    if (group) {
      // Find the current reason details
      const reason = group.options.find((option) => option.id === currentReasonId);

      if (reason) {
        const { id, text } = reason;

        const { label, order } = group;

        // Get the current group - if exists
        const currentGroup = reasonFilterGroupsMap[order];

        // Current reason filter object
        const currentReasonFilter = {
          label: text,
          id,
          value,
          isActive: false,
        };
        // If it's the group's first item - create the group
        // Otherwise, just add the item to the reasonFilter array
        if (!currentGroup) {
          reasonFilterGroupsMap[order] = {
            value,
            label,
            reasonFilter: [currentReasonFilter],
          };
        } else {
          reasonFilterGroupsMap[order].reasonFilter.push(currentReasonFilter);
          reasonFilterGroupsMap[order].value += value;
        }
      }
    }
  }

  // Get groups order by count
  const reasonFilterGroups = Object.keys(reasonFilterGroupsMap)
    .map((reasonFilterGroupId) => Number(reasonFilterGroupId))
    .sort(
      (reasonGroupA, reasonGroupB) =>
        reasonFilterGroupsMap[reasonGroupB].value - reasonFilterGroupsMap[reasonGroupA].value,
    );

  const updatedReasonFilterToColorMap = { ...reasonFilterToColorMap };

  const chosenColorsLength = getObjectKeys(reasonFilterToColorMap).length;

  // The remaining colors that can be assigned if needed
  const unChosenColors = [...ReasonFilterGroupColors].slice(chosenColorsLength);

  // Update colors
  reasonFilterGroups.forEach((groupKey) => {
    if (!reasonFilterToColorMap[groupKey]) {
      updatedReasonFilterToColorMap[groupKey] = unChosenColors[0];
      unChosenColors.shift();
    }
  });

  return {
    reasonFilterGroups,
    reasonFilterGroupsMap,
    reasonFilterToColorMap: updatedReasonFilterToColorMap,
  };
};

// get drill down list for hierarchy response by hit rate
export const getDrillDownListByHitRate = (apiDrillDownDown?: DrillDownByHitRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiDrillDownDown) {
    drillDownList = apiDrillDownDown.map(
      ({ name = '', noIssueFoundCount = 0, issueFoundCount = 0, hitRate = 0 }) => ({
        name,
        value: name,
        count: issueFoundCount,
        totalCount: issueFoundCount + noIssueFoundCount,
        rate: hitRate,
      }),
    );
  }

  return drillDownList;
};

export const getStoresDrillDownListByHitRate = (apiDrillDownDown?: DrillDownStoresByHitRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiDrillDownDown) {
    drillDownList = apiDrillDownDown.map(
      ({ storeName = '', noIssueFoundCount = 0, issueFoundCount = 0, hitRate = 0 }) => ({
        name: storeName,
        value: '',
        count: issueFoundCount,
        totalCount: issueFoundCount + noIssueFoundCount,
        rate: hitRate,
      }),
    );
  }

  return drillDownList;
};

export const getProductsDrillDownListByHitRate = (
  apiDrillDownDown?: DrillDownProductsByHitRateApi,
) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiDrillDownDown) {
    drillDownList = apiDrillDownDown.map(
      ({ productDisplayName = '', noIssueFoundCount = 0, issueFoundCount = 0, hitRate = 0 }) => ({
        name: productDisplayName,
        value: '',
        count: issueFoundCount,
        totalCount: issueFoundCount + noIssueFoundCount,
        rate: hitRate,
      }),
    );
  }

  return drillDownList;
};

// get drill down list for stores response by hit rate
export const getStoresByHitRate = (apiResponse?: DrillDownStoresByHitRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(
      ({
        storeName = '',
        noIssueFoundCount = 0,
        storeId = '',
        issueFoundCount = 0,
        hitRate = 0,
      }) => ({
        name: storeName,
        value: storeId,
        count: issueFoundCount,
        totalCount: issueFoundCount + noIssueFoundCount,
        rate: hitRate,
      }),
    );
  }

  return drillDownList;
};

// get drill down list for products response by hit rate
export const getProductsByHitRate = (apiResponse?: DrillDownProductsByHitRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(
      ({ productDisplayName = '', noIssueFoundCount = 0, issueFoundCount = 0, hitRate = 0 }) => ({
        name: productDisplayName,
        value: productDisplayName,
        count: issueFoundCount,
        totalCount: issueFoundCount + noIssueFoundCount,
        rate: hitRate,
      }),
    );
  }

  return drillDownList;
};

// get drill down list for hierarchy by response rate
export const getDrillDownListByResponseRate = (apiDrillDownDown?: DrillDownByResponseRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiDrillDownDown) {
    drillDownList = apiDrillDownDown.map(
      ({ level = '', responseRate = 0, openedTasks = 0, closedTasks = 0 }) => ({
        name: level,
        value: level,
        count: closedTasks,
        totalCount: openedTasks + closedTasks,
        rate: responseRate,
      }),
    );
  }

  return drillDownList;
};

export const getProductCategoriesByResponseRate = (
  apiDrillDownDown?: DrillDownProductCategoryByResponseRateApi,
) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiDrillDownDown) {
    drillDownList = apiDrillDownDown.map(
      ({ productCategory = '', openedTasks = 0, closedTasks = 0, responseRate = 0 }) => ({
        name: productCategory,
        value: productCategory,
        count: closedTasks,
        totalCount: closedTasks + openedTasks,
        rate: responseRate,
      }),
    );
  }

  return drillDownList;
};

export const getStoreCategoriesByResponseRate = (
  apiDrillDownDown?: DrillDownStoreCategoryByResponseRateApi,
) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiDrillDownDown) {
    drillDownList = apiDrillDownDown.map(
      ({ storeCategory = '', openedTasks = 0, closedTasks = 0, responseRate = 0 }) => ({
        name: storeCategory,
        value: storeCategory,
        count: closedTasks,
        totalCount: closedTasks + openedTasks,
        rate: responseRate,
      }),
    );
  }

  return drillDownList;
};

export const getStoresByResponseRate = (apiResponse?: DrillDownStoresByResponseRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(
      ({ storeName = '', storeId = '', openedTasks = 0, closedTasks = 0, responseRate = 0 }) => ({
        name: storeName,
        value: storeId,
        count: closedTasks,
        totalCount: openedTasks + closedTasks,
        rate: responseRate,
      }),
    );
  }

  return drillDownList;
};

export const getProductsByResponseRate = (apiResponse?: DrillDownProductByResponseRateApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(
      ({ productDisplayName = '', openedTasks = 0, closedTasks = 0, responseRate = 0 }) => ({
        name: productDisplayName,
        value: productDisplayName,
        count: closedTasks,
        totalCount: openedTasks + closedTasks,
        rate: responseRate,
      }),
    );
  }

  return drillDownList;
};

export const getProductCategoriesByEarning = (
  apiResponse?: DrillDownProductCategoryByEarningApi,
) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(({ productCategory = '', revenueIncrease = 0 }) => ({
      name: productCategory,
      value: productCategory,
      count: revenueIncrease,
      totalCount: revenueIncrease,
      rate: revenueIncrease,
    }));
  }

  return drillDownList;
};

export const getStoreCategoriesByEarning = (apiResponse?: DrillDownStoreCategoryByEarningApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(({ storeCategory = '', revenueIncrease = 0 }) => ({
      name: storeCategory,
      value: storeCategory,
      count: revenueIncrease,
      totalCount: revenueIncrease,
      rate: revenueIncrease,
    }));
  }

  return drillDownList;
};

export const getDrillDownListByEarning = (apiResponse?: DrillDownByEarningApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(({ level = '', revenueIncrease = 0 }) => ({
      name: level,
      value: level,
      count: revenueIncrease,
      totalCount: revenueIncrease,
      rate: revenueIncrease,
    }));
  }

  return drillDownList;
};

export const getProductsByEarning = (apiResponse?: DrillDownProductsByEarningApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(({ productDisplayName = '', revenueIncrease = 0 }) => ({
      name: productDisplayName,
      value: productDisplayName,
      count: revenueIncrease,
      totalCount: revenueIncrease,
      rate: revenueIncrease,
    }));
  }

  return drillDownList;
};

export const getStoresByEarning = (apiResponse?: DrillDownStoresByEarningApi) => {
  let drillDownList: Array<IDrillDownRow> = [];
  if (apiResponse) {
    drillDownList = apiResponse.map(({ storeName = '', storeId = '', revenueIncrease = 0 }) => ({
      name: storeName,
      value: storeId,
      count: revenueIncrease,
      totalCount: revenueIncrease,
      rate: 0,
    }));
  }

  return drillDownList;
};

// combine the prev count and content with the new ones
export const accumulateGroupContentAndGroupCount = (
  firstGroupCount: Array<number>,
  firstGroupContent: Array<string>,
  secondGroupCount: Array<number>,
  secondGroupContent: Array<string>,
) => {
  let groupContent = [...firstGroupContent];

  let groupCount = [...firstGroupCount];

  if (!isEmptyArray(firstGroupContent) && !isEmptyArray(firstGroupContent)) {
    // check if there is an overlap
    if (firstGroupContent[firstGroupContent.length - 1] === secondGroupContent[0]) {
      const countToAdd = secondGroupCount[0];
      groupCount[groupCount.length - 1] += countToAdd;
      secondGroupContent.shift();
      secondGroupCount.shift();
    }
    groupCount.push(...secondGroupCount);
    groupContent.push(...secondGroupContent);
  } else if (!isEmptyArray(secondGroupContent)) {
    groupCount = secondGroupCount;
    groupContent = secondGroupContent;
  }

  return { groupCount, groupContent };
};

export const getResponseRate = (apiResponse?: Array<IResponseRateComparisonApiResponseRow>) => {
  let rateComparison: Array<IRateRow> = [];
  if (apiResponse) {
    rateComparison = apiResponse.map(
      ({ totalNumberOfTasks = 0, numberOfResponses = 0, deployDate = 0 }) => ({
        total: totalNumberOfTasks,
        value: numberOfResponses,
        deployDate,
      }),
    );
  }

  return rateComparison;
};

export const getHitRate = (apiResponse?: Array<IHitRateComparisonApiResponseRow>) => {
  let rateComparison: Array<IRateRow> = [];
  if (apiResponse) {
    rateComparison = apiResponse.map(
      ({ numberOfResponses = 0, totalIssueFound = 0, deployDate = 0 }) => ({
        total: numberOfResponses,
        value: totalIssueFound,
        deployDate,
      }),
    );
  }

  return rateComparison;
};

export const getSalesIncreaseHistogram = (apiResponse: SalesIncreaseMyHistogramApi) => {
  let response: SalesIncreaseMyHistogram = [];

  const total = apiResponse.reduce((acc, item) => acc + item.revenueIncrease, 0);

  if (apiResponse) {
    response = apiResponse.map(({ month, revenueIncrease = 0 }) => ({
      value: revenueIncrease,
      date: Number(month),
    }));
  }

  return { response, total };
};

export const getSalesIncreaseChainWideTotal = (apiResponse: SalesIncreaseMyHistogramApi = []) => {
  return apiResponse.reduce((acc, item) => acc + item.revenueIncrease, 0);
};

export const getHitRateHistogram = (apiResponse: HitRateHistogramApi) => {
  let response: RateHistogram = [];
  if (apiResponse) {
    response = apiResponse.map(({ date, hitRate }) => ({
      value: hitRate,
      date: Number(date),
    }));
  }

  return response;
};

export const getResponseRateHistogram = (apiResponse: ResponseRateHistogramApi) => {
  let response: RateHistogram = [];
  if (apiResponse) {
    response = apiResponse.map(({ date, responseRate }) => ({
      value: responseRate,
      date: Number(date),
    }));
  }

  return response;
};

export const getLeaderBoard = (apiResponse: LeaderBoardResponseApi) => {
  let response: Array<ILeaderBoardRow> = [];
  if (apiResponse) {
    response = apiResponse.map(({ name, storeName, storeId, hitRate, issueFoundCount }, index) => ({
      rank: index + 1,
      name: storeName || name || '',
      storeId,
      hitRate,
      issueFoundCount,
    }));
  }

  return response;
};

export const parseOpenOpportunitiesApiStoreResponseType = (
  type: OpenOpportunitiesApiStoreResponseTypes,
) => {
  let actualType;

  switch (type) {
    case OpenOpportunitiesApiStoreResponseTypes.NO_TASKS:
      actualType = OpenOpportunitiesCardTypes.NO_OPPORTUNITIES;
      break;
    default:
      actualType = (type as unknown) as OpenOpportunitiesCardTypes;
  }

  return actualType;
};

export const getOpenOpportunitiesCountByWeek = (rows: OpenOpportunitiesRowApi[]) => {
  const opportunitiesCountByWeek: OpportunitiesCounter[] = [];

  if (!isEmptyArray(rows)) {
    // avoid duplicates
    const weeksOf = Array.from(
      new Set(rows.map((item: OpenOpportunitiesRowApi) => Number(item.week))),
    );

    weeksOf.forEach((week) => {
      const weekItems = rows.filter((item: OpenOpportunitiesRowApi) => Number(item.week) === week);

      opportunitiesCountByWeek.push({
        open: weekItems
          .filter((item: OpenOpportunitiesRowApi) => item.state.toUpperCase() === 'OPENED')
          .reduce((sum: number, item: OpenOpportunitiesRowApi) => sum + item.count, 0),
        total: weekItems.reduce(
          (sum: number, item: OpenOpportunitiesRowApi) => sum + item.count,
          0,
        ),
        week,
      });
    });
  }

  return opportunitiesCountByWeek;
};

export const parseStoreBreakDown = (rows: StoreBreakdownResponse[]) => {
  const storeBreakdownArr: StoreBreakdownResponse[] = rows.map((row: any) => {
    return {
      status: parseOpenOpportunitiesApiStoreResponseType(row.status),
      count: row.count ?? 0,
    };
  });

  return storeBreakdownArr;
};

export const initOpenOpportunitiesSelectedWeeks = (
  selectedWeeks: [number, number],
  totalWeeksCount: number,
) => {
  let updated = selectedWeeks;

  const totalWeeks = totalWeeksCount > 0 ? totalWeeksCount : 1;

  if (selectedWeeks[0] === -1 || selectedWeeks[1] === -1) {
    updated = [0, totalWeeks - 1];
  }

  return updated;
};

export const parseOpenOpportunitiesDrillDownCards = (
  items: any[],
  selectedDropdownOption: OpenOpportunitiesDropDownOptionsTypes,
) => {
  const cards: IOpenOpportunitiesDrillDownCard[] = items.map((item: any) => {
    const card: IOpenOpportunitiesDrillDownCard = {
      level: item?.level || item?.storeName || '',
      open: item?.openedTasks || item?.OPENED || 0,
      total: (item?.openedTasks || item?.OPENED || 0) + (item?.closedTasks || item?.CLOSED || 0),
      value: 0,
    };

    if (item?.storeId) {
      card.storeId = item.storeId;
    }

    switch (selectedDropdownOption) {
      case OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES:
        card.value = card?.open || 0;
        break;
      case OpenOpportunitiesDropDownOptionsTypes.INVESTIGATED_OPPORTUNITIES:
        card.value = item?.closedTasks || item?.CLOSED || 0;
        break;
      case OpenOpportunitiesDropDownOptionsTypes.NUMBER_OF_STORES:
        card.value = item?.storeCount || 0;
        break;
      case OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE:
        card.value = Math.round(item?.responseRate || 0);
        break;
      default:
        break;
    }

    return card;
  });

  const highestValue = Math.max(...cards.map((item) => item.value));

  return { cards, highestValue };
};

export const parseInventoryListJoinSizeBySku = (
  inventory: IInventoryRawData | null,
  opportunity: IMyOpportunity | null,
): IInventoryRawData | null => {
  if (inventory && opportunity) {
    const { t } = i18n;

    const { headers, stockData, headlines } = inventory;

    const { extendedSkus, productSkusAndSizes } = opportunity;

    const productHasSizes = productSkusAndSizes.length > 0 && productSkusAndSizes[0].size;

    let skusAndSizes: any[] = [];

    // if has no sizes then extendedSkus is empty so use productSkusAndSizes
    if (extendedSkus && extendedSkus.length > 0) {
      skusAndSizes = extendedSkus;
    } else if (productSkusAndSizes) {
      skusAndSizes = productSkusAndSizes;
    }

    const modifiedStockData: any[] = [];

    const joinedInventory: IInventoryRawData = {
      headers,
      stockData,
      headlines,
    };

    // Add Size header and data by joining server result and clients productSkusAndSizes list
    skusAndSizes.forEach((skuAndSize, i) => {
      if (i === 0 && productHasSizes && joinedInventory.stockData[i][PRODUCT_KEY]) {
        joinedInventory.headers.push({ [SIZE_KEY]: t('COMMON.SIZE') });
      }

      // go through data rows
      stockData.forEach((item) => {
        if (productHasSizes && item[PRODUCT_KEY].toString() === skuAndSize[SKU_KEY]) {
          const data = {
            ...item,
            [SIZE_KEY]: skuAndSize[SIZE_KEY],
          };
          modifiedStockData.push(data);
        }
      });
    });

    if (modifiedStockData.length > 0) {
      joinedInventory.stockData = modifiedStockData;
    }

    return joinedInventory;
  }

  return null;
};

const validateHitRateScoreResponse = (hitRateScoreResponse: IHitRateScore): boolean => {
  const hitRateScoreFields = Object.keys(hitRateScoreResponse);

  const hitRateScoreFieldsFilteredByRequired = hitRateScoreRequiredFields.filter((requiredField) =>
    hitRateScoreFields.includes(requiredField),
  );

  if (hitRateScoreRequiredFields.length !== hitRateScoreFieldsFilteredByRequired.length) {
    return false;
  }

  return hitRateScoreFields.every((prop) => typeof hitRateScoreResponse[prop] === 'number');
};

export const parseHitRateScore = (data: IHitRateScore) => {
  const isValidResponse = validateHitRateScoreResponse(data);

  let hitRateScore: IHitRateScore | null = null;

  if (isValidResponse) {
    hitRateScore = data;
  }

  return hitRateScore;
};

export const getFilterOrderedCategories = (
  categories: { [key: string]: string },
  orderKeys?: number[],
) => {
  const data = orderKeys || Object.keys(categories);

  return data.map((k) => {
    return {
      title: categories[k],
      level: Number(k),
    };
  });
};
