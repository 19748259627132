import { set, flow } from 'lodash/fp';

import { IActionType, IKnowledgeCenterModel, IVideo } from 'types';
import KnowledgeCenterActionTypes from './knowledgeCenterActionTypes';
import { getKnowledgeCenterVideos, MovePrvSelectedVideoToEndOfQueue } from 'store/api/apiParser';

export const initialState: IKnowledgeCenterModel = {
  videos: null,
  totalCount: 0,
  selectedVideoId: null,
};

const knowledgeCenterReducer = (
  state: IKnowledgeCenterModel = initialState,
  action: IActionType,
) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case KnowledgeCenterActionTypes.getVideos.SUCCESS: {
      const { content } = payload[0];

      const videos = getKnowledgeCenterVideos(content);

      const selectedVideoId = state.selectedVideoId ? state.selectedVideoId : videos[0]?.id;

      return flow([
        set('videos', videos),
        set('totalCount', videos.length - 1),
        set('selectedVideoId', selectedVideoId),
      ])(state);
    }

    case KnowledgeCenterActionTypes.setSelectedVideo: {
      const { selectedVideoId } = payload;

      const { videos, selectedVideoId: PrvSelectedVideoId } = state;
      let sortedVideos: Array<IVideo> | null = videos;

      if (videos && PrvSelectedVideoId) {
        sortedVideos = MovePrvSelectedVideoToEndOfQueue(videos, PrvSelectedVideoId);
      }

      return flow([set('selectedVideoId', selectedVideoId), set('videos', sortedVideos)])(state);
    }
    default:
      return state;
  }
};

export default knowledgeCenterReducer;
