import { FC } from 'react';
import cn from 'clsx';

import { EMPTY_STATE_DEFAULT_LABEL, IFindingsPieChart, PieChartSizeTypes } from 'types';
import './findingsCharts.scss';
import EmptyPieChart from './EmptyPieChart';
import FindingsChart from './FindingsChart';

const FindingsPieChart: FC<IFindingsPieChart> = ({
  isAllCellsActive,
  onCellClick,
  data,
  totalCount,
  sizeType,
  emptyStateLabel = EMPTY_STATE_DEFAULT_LABEL,
  canToggleActiveCell,
  location,
  className,
  isRtl,
}) => {
  const chartHeights = {
    [PieChartSizeTypes.SMALL]: 275,
    [PieChartSizeTypes.REGULAR]: 375,
  };

  const shouldShowEmptyState = totalCount === 0;

  const chartHeight = chartHeights[sizeType];

  const renderChart = () => {
    if (shouldShowEmptyState) {
      return <EmptyPieChart height={chartHeight} emptyStateLabel={emptyStateLabel} />;
    }

    return (
      <FindingsChart
        isRtl={isRtl}
        height={chartHeight}
        data={data}
        totalCount={totalCount}
        isAllCellsActive={isAllCellsActive}
        canToggleActiveCell={canToggleActiveCell}
        onCellClick={onCellClick}
        location={location}
      />
    );
  };

  return (
    <div className={cn('findings-chart-container', className, [sizeType])}>{renderChart()}</div>
  );
};

export default FindingsPieChart;
