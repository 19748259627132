import {
  direction,
  DrillDownTypes,
  IDate,
  IDrillDownHierarchy,
  IDrillDownRow,
  IFilterItem,
  ISelectedCategory,
} from 'types';

export interface ITrendsDrillDownActiveFilters {
  orderDirection: direction;
  selectedCategory?: ISelectedCategory;
}

export interface ITrendsDrillDownMutualFilters {
  search: string;
  hierarchyIndex: number;
}

export interface ITrendsDrillDownFilters {
  activeFilters: ITrendsDrillDownActiveFilters;
  mutualFilters: ITrendsDrillDownMutualFilters;
  hasMore: boolean;
  hierarchyPath: Array<IDrillDownHierarchy>;
  filters: Array<IFilterItem>;
  nextPageOffset: number;
}

export interface ITrendsFilter {
  date: IDate;
  selectedTab: TrendsTabOptions;
  expendedDrillDown: DrillDownTypes | null;
  selectedMonth: number | null;
  [DrillDownTypes.Store]: ITrendsDrillDownFilters;
  [DrillDownTypes.Product]: ITrendsDrillDownFilters;
  [DrillDownTypes.Other]: ITrendsDrillDownFilters;
}

export interface ITrendsDrillDownModel {
  list: Array<IDrillDownRow> | null;
  totalCount: number;
  highestValue: number;
  isLoading: boolean;
}

export type SalesIncreaseMyHistogram = Array<{ date: number; value: number }>;

export type RateHistogram = Array<{ date: number; value: number }>;

export interface ITrendsModel {
  [DrillDownTypes.Store]: ITrendsDrillDownModel;
  [DrillDownTypes.Product]: ITrendsDrillDownModel;
  [DrillDownTypes.Other]: ITrendsDrillDownModel;
  hitRate: {
    total: number;
    tooltip: number | null;
    data: RateHistogram | null;
    chainWideData: RateHistogram;
  };
  responseRate: {
    total: number;
    tooltip: number | null;
    data: RateHistogram | null;
    chainWideData: RateHistogram;
  };
  salesIncrease: {
    totalValueMyStores: number;
    totalValueChainWide: number;
    data: SalesIncreaseMyHistogram | null;
  };
  filters: ITrendsFilter;
}

export interface ITrendsDrillDown {
  hierarchyDepth: number;
  categories?: Array<{ title: string; list: Array<string> }>;
  hasMore: boolean;
  isFiltersVisible?: boolean;
  filters: Array<IFilterItem>;
  hierarchyName: string;
  list: Array<IDrillDownRow> | null;
  totalCount: number;
  hierarchyPath: Array<IDrillDownHierarchy>;
  highestValue: number;
  isLoading: boolean;
  activeFilters: ITrendsDrillDownActiveFilters;
  mutualFilters: ITrendsDrillDownMutualFilters;
}

export enum TrendsTabOptions {
  Earning = 'Earnings',
  HitRate = 'HitRate',
  ResponseRate = 'ResponseRate',
}

/* *****************Response rate api***************** */

export type DrillDownByResponseRateApi = Array<{
  closedTasks: number;
  level: string;
  openedTasks: number;
  responseRate: number;
}>;

export type DrillDownProductCategoryByResponseRateApi = Array<{
  closedTasks: number;
  productCategory: string;
  openedTasks: number;
  responseRate: number;
}>;

export type DrillDownStoreCategoryByResponseRateApi = Array<{
  closedTasks: number;
  storeCategory: string;
  openedTasks: number;
  responseRate: number;
}>;

export type DrillDownStoresByResponseRateApi = Array<{
  closedTasks: number;
  openedTasks: number;
  responseRate: number;
  storeId: string;
  storeName: string;
}>;

export type DrillDownProductByResponseRateApi = Array<{
  closedTasks: number;
  openedTasks: number;
  responseRate: number;
  productDisplayName: string;
}>;

/* *****************Earning api***************** */
export type DrillDownByEarningApi = Array<{
  revenueIncrease: number;
  level: string;
}>;

export type DrillDownProductCategoryByEarningApi = Array<{
  revenueIncrease: number;
  productCategory: string;
}>;

export type DrillDownStoreCategoryByEarningApi = Array<{
  revenueIncrease: number;
  storeCategory: string;
}>;

export type DrillDownStoresByEarningApi = Array<{
  revenueIncrease: number;
  storeId: string;
  storeName: string;
}>;

export type DrillDownProductsByEarningApi = Array<{
  revenueIncrease: number;
  productDisplayName: string;
}>;

export type HitRateHistogramApi = Array<{
  date: number;
  hitRate: number;
  issueFoundCount: number;
  noIssueFoundCount: number;
}>;

export type ResponseRateHistogramApi = Array<{
  closedTasks: number;
  date: number;
  openedTasks: number;
  responseRate: number;
}>;
