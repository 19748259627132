import { DrillDownCategoriesTypes, DrillDownTypes, HierarchyLevel } from 'types';

/* *****************Search filters**************** */

// Get search field by category type
// Hit rate and response rate
export const mapCategoryTypeToSearchKey = {
  [DrillDownCategoriesTypes.ProductCategory]: 'productCategories',
  [DrillDownCategoriesTypes.StoreCategory]: 'storeCategories',
  [DrillDownCategoriesTypes.Store]: 'storeName',
  [DrillDownCategoriesTypes.Product]: 'productDisplayName',
};

// Get search field by category type
// Sales increase
export const mapCategoryTypeToSearchKeyByEarning = {
  [DrillDownCategoriesTypes.ProductCategory]: 'task.productCategories',
  [DrillDownCategoriesTypes.StoreCategory]: 'task.storeCategories',
  [DrillDownCategoriesTypes.Store]: 'task.storeName',
  [DrillDownCategoriesTypes.Product]: 'task.productDisplayName',
};

// Get search field by Drill down type to leaf
// Hit rate and response rate
export const mapLeafDrillDownTypeToSearchKey = {
  [DrillDownTypes.Store]: 'storeName',
  [DrillDownTypes.Product]: 'productDisplayName',
};

// Get search field by Drill down type to leaf
// Sales increase
export const mapLeafDrillDownTypeToSearchKeyByEarning = {
  [DrillDownTypes.Store]: 'task.storeName',
  [DrillDownTypes.Product]: 'task.productDisplayName',
};

// Get search field by Drill down type
export const mapDrillDownTypeToSearchKey = {
  [DrillDownTypes.Store]: 'storeHierarchy',
  [DrillDownTypes.Product]: 'productHierarchy',
};

// Get search field by Drill down type
export const mapDrillDownTypeToSearchKeyByEarning = {
  [DrillDownTypes.Store]: 'task.storeHierarchy',
  [DrillDownTypes.Product]: 'task.productHierarchy',
};

/* *****************Filters fields**************** */

// Get drill down type to filter field
// Hit rate and response rate
export const mapDrillDownTypeToFilterField = {
  [DrillDownTypes.Store]: 'storeHierarchy',
  [DrillDownTypes.Product]: 'productHierarchy',
};

// Get drill down type to filter field
// Sales increase
export const mapDrillDownTypeToFilterFieldByEarning = {
  [DrillDownTypes.Store]: 'task.storeHierarchy',
  [DrillDownTypes.Product]: 'task.productHierarchy',
};

// Get filter key to leaf by drill down type
export const mapDrillDownTypesToLeafFilter = {
  [DrillDownTypes.Store]: 'storeId',
  [DrillDownTypes.Product]: 'productDisplayName',
};

// Get filter key to leaf by drill down type by earning
export const mapDrillDownTypesToLeafFilterByEarning = {
  [DrillDownTypes.Store]: 'task.storeId',
  [DrillDownTypes.Product]: 'task.productDisplayName',
};

// Get filter key to leaf by category type
// Hit rate and response rate
export const mapCategoriesToFilter = {
  [DrillDownCategoriesTypes.Store]: 'storeId',
  [DrillDownCategoriesTypes.Product]: 'productDisplayName',
  [DrillDownCategoriesTypes.ProductCategory]: 'productCategories',
  [DrillDownCategoriesTypes.StoreCategory]: 'storeCategories',
};

// Get filter key to leaf by category type
// Sales increase
export const mapCategoriesToFilterByEarning = {
  [DrillDownCategoriesTypes.Store]: 'task.storeId',
  [DrillDownCategoriesTypes.Product]: 'task.productDisplayName',
  [DrillDownCategoriesTypes.ProductCategory]: 'task.productCategories',
  [DrillDownCategoriesTypes.StoreCategory]: 'task.storeCategories',
};

/* *****************Endpoints**************** */

// Get hierarchy endpoint by drill down type
// Hit rate and response rate
export const mapDrillDownToHierarchyName = {
  [DrillDownTypes.Store]: 'StoreHierarchy',
  [DrillDownTypes.Product]: 'ProductHierarchy',
};

// Get hierarchy endpoint by drill down type
// Sales increase
export const mapDrillDownToHierarchyNameBySalesIncrease = {
  [DrillDownTypes.Store]: 'RevenueBySingleStoreHierarchyQuery',
  [DrillDownTypes.Product]: 'RevenueBySingleProductHierarchyQuery',
};

// Get category endpoint by category type
// Response rate and hit rate
export const mapCategoriesTypeToCategory = {
  [DrillDownCategoriesTypes.ProductCategory]: 'ProductCategory',
  [DrillDownCategoriesTypes.StoreCategory]: 'StoreCategory',
};

// Get category endpoint by category type
// Sales increase
export const mapCategoriesTypeToCategoryByEarning = {
  [DrillDownCategoriesTypes.ProductCategory]: 'RevenueByProductCategoryQuery',
  [DrillDownCategoriesTypes.StoreCategory]: 'RevenueByStoreCategoryQuery',
};

// Get hierarchy order by hierarchy level
// Summary Leaders Board
export const HierarchyLevelOrder = {
  [HierarchyLevel.ALL]: 0,
  [HierarchyLevel.LEVEL1]: 1,
  [HierarchyLevel.LEVEL2]: 2,
  [HierarchyLevel.LEVEL3]: 3,
  [HierarchyLevel.LEVEL4]: 4,
  [HierarchyLevel.LEVEL5]: 5,
  [HierarchyLevel.LEAF]: 10,
};
