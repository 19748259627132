import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `ReasonsActionTypes.${action}`;

const getReasons = APIConstants.createAPIRequestAction(getPrefix('getReasons'));

const ReasonsActionTypes = {
  getReasons,
};

export default ReasonsActionTypes;
