export enum AlertIds {
  OpenPrintMenu = 'OpenPrintMenu',
  OpenFindingsDateMenu = 'OpenFindingsDateMenu',
  OpenFindingsOtherDrillDownOptions = 'OpenFindingsOtherDrillDownOptions',
  OpenTrendsOtherDrillDownOptions = 'OpenTrendsOtherDrillDownOptions',
  OpenTrendsDateMenu = 'OpenTrendsDateMenu',
  OpenSummaryResponseRateInfo = 'OpenSummaryResponseRateInfo',
  OpenSummaryHitRateInfo = 'OpenSummaryHitRateInfo',
  OpenComparableStores = 'OpenComparableStores',
}

export const AlertButtonsIds = {
  Cancel: 'cancel',
  Continue: 'Continue',
  OK: 'OK',
};

export const AlertChecklistIds = {
  PrintImage: 'PrintImage',
  PrintReasons: 'PrintReasons',
};

export interface IAlert {
  id: string;
  title?: string;
  text?: string;
  buttons?: Array<IAlertButton>;
  checklist?: Array<IAlertListItem>;
  treeSelection?: Array<ITreeGroup>;
  radioList?: Array<IAlertListItem>;
  withOverlay?: boolean;
  withDatePicker?: boolean;
  selectedDates?: {
    from?: number;
    to?: number;
  };
  alertCustomClassName?: string;
}

export interface IAlertButton {
  id: string;
  text: string;
  icon?: Function;
}

export interface IAlertListItem {
  id: string;
  label: string;
  isChecked: boolean;
}

export interface IAlertRadioItem {
  id: string;
  label: string;
  isChecked: boolean;
  hideOnClick: boolean;
}

export interface ITreeOption {
  label: string;
  id?: string;
  value: string;
  isChecked: boolean;
  hideOnClick: boolean;
}

export interface ITreeGroup {
  id: string;
  title: string;
  options: Array<ITreeOption>;
}
