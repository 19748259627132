import { flow, set } from 'lodash/fp';

import AlertActionTypes from 'store/alert/alertActionTypes';
import { IActionType } from 'types';
import { IAlertModel } from './types';

export const initialState: IAlertModel = {
  isVisible: false,
  id: '',
  title: '',
  text: '',
  buttons: [],
  checklist: undefined,
  radioList: undefined,
  treeSelection: undefined,
  withOverlay: false,
  alertCustomClassName: '',
};

const alertReducer = (state: IAlertModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case AlertActionTypes.Show: {
      const {
        id,
        title,
        text,
        withDatePicker,
        buttons,
        checklist,
        radioList,
        withOverlay,
        alertCustomClassName,
        treeSelection,
        selectedDates,
      } = payload;

      return flow([
        set('isVisible', true),
        set('id', id),
        set('title', title),
        set('text', text),
        set('buttons', buttons),
        set('checklist', checklist),
        set('withOverlay', withOverlay),
        set('withDatePicker', withDatePicker),
        set('selectedDates', selectedDates),
        set('treeSelection', treeSelection),
        set('radioList', radioList),
        set('alertCustomClassName', alertCustomClassName),
      ])(state);
    }

    case AlertActionTypes.Hide: {
      return initialState;
    }

    case AlertActionTypes.SetChecklist: {
      const { checklist } = payload;

      return flow([set('checklist', checklist)])(state);
    }
    case AlertActionTypes.SetRadioList: {
      const { radioList } = payload;

      return flow([set('radioList', radioList)])(state);
    }

    case AlertActionTypes.SetDateRange: {
      const { from, to } = payload;

      return flow([set('selectedDates', { from, to })])(state);
    }

    case AlertActionTypes.SetTreeSelection: {
      const { selection, treeId } = payload;

      const { treeSelection = [] } = state;

      const treeIndex = treeSelection.findIndex((tree) => tree.id === treeId);

      const { options } = treeSelection[treeIndex];

      const treeItemIndex = options.findIndex(({ label }) => label === selection);

      return flow([set(`treeSelection[${treeIndex}].list[${treeItemIndex}].isChecked`, true)])(
        state,
      );
    }

    default:
      return state;
  }
};

export default alertReducer;
