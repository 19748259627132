import { FC, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import './liveInventoryPopup.scss';
import { useTranslation } from 'react-i18next';

import Popup from 'components/common/popup/Popup';
import { animationType, IInventoryTableItems, ILiveInventoryPopup, IRootState } from 'types';
import { getDateAndTimeFormat } from 'utils';
import { getLiveInventory } from 'store/myOpportunities/myOpportunitiesActions';
import { ProgressAnimation } from 'components/animation';
import myOpportunitiesSelector from 'store/myOpportunities/myOpportunitiesSelector';
import loadingSelector from 'store/loading/LoadingSelector';
import { PRODUCT_KEY, SIZE_KEY } from 'constants/constantsVaribles';

const LiveInventoryPopup: FC<ILiveInventoryPopup> = ({
  liveInventory,
  isLoading,
  getLiveInventory,
  currentOpportunity,
  language,
}) => {
  const [t] = useTranslation();

  const showEmptyState = !isLoading && liveInventory && !liveInventory;

  const showTable = !isLoading && liveInventory && liveInventory;

  // get live inventory data
  useEffect(() => {
    if (currentOpportunity) {
      let product = '';
      if (currentOpportunity.extendedSkus) {
        product = currentOpportunity.extendedSkus.map((product) => product.sku).join(',');
      } else if (currentOpportunity.productSkusAndSizes) {
        product = currentOpportunity.productSkusAndSizes[0].sku;
      }

      const storeId = currentOpportunity.storeIdentifier || '';

      const squareBracketsRegex = /\[(\d+)\]/; // number inside square brackets

      const itemNumberMatches = currentOpportunity.productName.match(squareBracketsRegex);

      getLiveInventory(
        storeId,
        product,
        language,
        itemNumberMatches ? itemNumberMatches[1] : undefined,
      );
    }
  }, []);

  const emptyState = (
    <div className="inventory-empty-state">{t('MY_OPPORTUNITIES.NO_INVENTORY')}</div>
  );

  const getTableHeaders = (headers: IInventoryTableItems, withSkuSizeJoined: boolean) => {
    let sizeHeaderText = '';

    const tableHeaders = { ...headers };

    // if has size header and withSkuSizeJoined = true then join size & sku headers
    if (tableHeaders[SIZE_KEY] && withSkuSizeJoined) {
      delete tableHeaders[SIZE_KEY];
      sizeHeaderText = SIZE_KEY;
    }

    return (
      <thead>
        <tr>
          {Object.keys(tableHeaders).map((key) => {
            return (
              <th key={`inventory_key_${key}`}>
                <div className="table-header-col">
                  {withSkuSizeJoined && key === PRODUCT_KEY ? (
                    <span className="table-header-sub-text">{sizeHeaderText}</span>
                  ) : (
                    <span className="table-header-text">{tableHeaders[key]}</span>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const getTableBody = (
    headers: IInventoryTableItems,
    tableRows: IInventoryTableItems[],
    withSkuSizeJoined: boolean,
  ) => {
    const sizeColumnFiltered = { ...headers };

    // if has size header and withSkuSizeJoined = true then join size & sku headers
    if (sizeColumnFiltered[SIZE_KEY] && withSkuSizeJoined) {
      delete sizeColumnFiltered[SIZE_KEY];
    }

    return (
      <tbody>
        {tableRows.map((row) => (
          <tr key={`inventory_row_${Math.random()}`}>
            {Object.keys(sizeColumnFiltered).map((value) => {
              const text = value === PRODUCT_KEY ? row[SIZE_KEY] : row[value];

              return (
                <td key={`inventory_value_${value}`}>
                  <div className="table-row-col">
                    <span>{text}</span>
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  const getTable = () => {
    if (liveInventory) {
      const { headers, stockData } = liveInventory;

      // withSkuSize - is a special case, if the header exist it will be unite with product (sku)
      const withSkuSizeJoined = !!headers.find((header) => header[SIZE_KEY]);

      // spread headers into dictionary
      const tableHeaders: { [key: string]: string } = headers.reduce(
        (r, c) => Object.assign(r, c),
        {},
      );

      // sort table rows
      const tableRows = stockData.sort((a, b) => {
        if (a[PRODUCT_KEY] && b[PRODUCT_KEY]) {
          return Number(a[PRODUCT_KEY]) - Number(b[PRODUCT_KEY]);
        }

        return 0;
      });

      return (
        <table className="live-inventory-table">
          {getTableHeaders(tableHeaders, withSkuSizeJoined)}
          {getTableBody(tableHeaders, tableRows, withSkuSizeJoined)}
        </table>
      );
    }

    return '';
  };

  return (
    <Popup popupClassName="inventory-popup-container" withOverlay>
      <Popup.Header>
        <div className="header-description">{currentOpportunity?.productName}</div>
        <div className="header-updated-on">
          {t('MY_OPPORTUNITIES.UPDATED_ON')} {getDateAndTimeFormat(Date.now())}
        </div>
        <div className="header-headlines">
          {liveInventory?.headlines.map((headline) => {
            return (
              <div className="header-headline">
                {headline.name} : {headline.values.join(', ')}
              </div>
            );
          })}
        </div>
      </Popup.Header>
      <Popup.Content>
        <ProgressAnimation
          className="live-inventory-spinner"
          animationType={animationType.Spinner}
          isLoading={isLoading}
        />
        {showEmptyState && emptyState}
        {showTable && getTable()}
      </Popup.Content>
    </Popup>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { liveInventory } = state.myOpportunities;

  const { language } = state.settings;

  const currentOpportunity = myOpportunitiesSelector.getSelectedOpportunitySelector(state);

  const isLoading = loadingSelector.isLiveInventoryLoading(state);

  return {
    liveInventory,
    isLoading,
    currentOpportunity,
    language,
  };
};

const mapDispatchToProps = { getLiveInventory };

export default connect(mapStateToProps, mapDispatchToProps)(memo(LiveInventoryPopup));
