import './SideMenu.scss';
import { FC } from 'react';

import { ISideMenuHeaderProps } from 'components/layout/types';
import { LogoIcon, LogoTitleIcon } from 'components/Icons';

const SideMenuHeader: FC<ISideMenuHeaderProps> = ({ isMenuCollapsed }) => {
  return (
    <div className="side-menu-header">{isMenuCollapsed ? <LogoIcon /> : <LogoTitleIcon />}</div>
  );
};

export default SideMenuHeader;
