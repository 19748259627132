export enum EnvironmentTypes {
  MCS = 'mcs',
  STAGING_WCS = 'staging-wcs',
  STAGING_MCS = 'staging-mcs',
}

export const getDeviceInfo = () => {
  const { userAgent } = window.navigator;

  const regex = /\((.*?)\)/;

  const deviceInfoMatch = userAgent.match(regex);

  if (deviceInfoMatch) {
    const deviceInfo = deviceInfoMatch[1];

    if (deviceInfo) {
      return deviceInfo.replace(/[;-]/g, '_').trim();
    }
  }

  return 'N/A';
};

export const isStagingUrl = (url: string) => {
  return (
    url.toLowerCase().includes('staging-wcs.c-b4.com') ||
    url.toLowerCase().includes('staging-mcs.c-b4.com') ||
    url.toLowerCase().includes('localhost')
  );
};

export const getEnvType = () => {
  const hostName = window && window.location && window.location.hostname;

  return isStagingUrl(hostName) ? EnvironmentTypes.STAGING_WCS : EnvironmentTypes.MCS;
};
