// The order of the list is important to the theme
// '0' (yellow) is the group with the biggest slice
// '1' 2nd big, etc…
// The assignment of color is persistent per session
export const ReasonFilterGroupColors = [
  '#FFD500',
  '#0056BC',
  '#96B8B2',
  '#E3482F',
  '#B278B3',
  '#67808D',
  '#E5BEC0',
  '#FB8C34',
];
