import { Fragment, FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import Header from './Header';
import './print.scss';
import { IMyOpportunitiesPrintPage, IMyOpportunity, IRootState } from 'types';
import CardPrint from './card/Card';
import FiltersSelector from 'store/filters/filtersSelector';
import Reasons from './Reasons';
import PrintActions from 'store/print/printActions';

const MyOpportunitiesPrintPage: FC<IMyOpportunitiesPrintPage> = ({
  productCategories,
  totalCount,
  filterList,
  currency,
  opportunitiesGroupContent,
  opportunitiesGroupCount,
  reasons,
  opportunities,
  language,
  isRtl,
  withProductImages,
  withReasonsList,
  showTargetSales,
  showTargetUnits,
  showPotentialUnits,
  showPotentialRevenue,
  showInventoryUpdate,
  hide,
}) => {
  const getOpportunityCard = useCallback(
    (opportunity: IMyOpportunity, index: number) => {
      const productCategoriesLabels = Object.entries(opportunity.productCategories).map(
        ([key, label]) => ({
          title: productCategories[key],
          label,
        }),
      );

      const productImage = withProductImages ? opportunity.lowResolutionProductImage : null;

      const targetSales = showTargetSales ? opportunity.targetSales : undefined;

      const targetUnits = showTargetUnits ? opportunity.targetUnits : undefined;

      const potentialSales = showPotentialRevenue ? opportunity.potentialRevenue : undefined;

      const potentialUnits = showPotentialUnits ? opportunity.potentialUnitsSales : undefined;

      const inventoryUpdate = showInventoryUpdate
        ? {
            date: opportunity?.inventoryLatestUpdateDate || 0,
            value: opportunity?.inventoryLevel || 0,
          }
        : undefined;

      return (
        <CardPrint
          isRtl={isRtl}
          index={index}
          key={opportunity.id}
          currency={currency}
          totalCount={totalCount}
          action={opportunity.action}
          inventoryUpdate={inventoryUpdate}
          targetUnits={targetUnits}
          potentialUnits={potentialUnits}
          potentialRevenue={potentialSales}
          productName={opportunity.productName}
          productSkusAndSizes={opportunity.productSkusAndSizes}
          productCategories={productCategoriesLabels}
          storeName={opportunity.storeName}
          targetSales={targetSales}
          productImage={productImage}
          lastSalesValues={opportunity.lastSalesValues}
          lastSimilarSalesValues={opportunity.lastSimilarSalesValues}
        />
      );
    },
    [productCategories, totalCount, currency],
  );

  const getOpportunitiesCards = useCallback(() => {
    if (!opportunities) {
      return null;
    }

    let startIndex = 0;

    // Organize opportunities by groups
    return opportunitiesGroupContent.map((groupByHeader: string, groupIndex: number) => {
      const groupLength = opportunitiesGroupCount[groupIndex];

      const endIndex = startIndex + groupLength;

      const currentOpportunitiesGroup = opportunities.slice(startIndex, endIndex);

      const opportunitiesGroup = currentOpportunitiesGroup.map((opportunity, index) =>
        getOpportunityCard(opportunity, index + startIndex + 1),
      );
      startIndex += groupLength;

      return (
        <Fragment key={groupByHeader}>
          <tr>
            <td>
              <div className="group-header">{groupByHeader}</div>
            </td>
          </tr>
          {opportunitiesGroup}
        </Fragment>
      );
    });
  }, [opportunities, opportunitiesGroupContent, opportunitiesGroupCount, getOpportunityCard]);

  useEffect(() => {
    window.addEventListener('afterprint', hide);
    setTimeout(() => {
      window.print();
    }, 0);

    return () => {
      window.removeEventListener('afterprint', hide);
    };
  }, []);

  return (
    <table className="card-print-page-table">
      <Header totalCount={totalCount} filterList={filterList} />
      <tbody>
        {withReasonsList && <Reasons language={language} reasons={reasons} />}
        {getOpportunitiesCards()}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { general, productCategories, language, isRtl } = state.settings;

  const {
    showTargetSales,
    showTargetUnits,
    showPotentialRevenue,
    showPotentialUnits,
    showInventoryData,
    isLiveInventoryEnabled,
  } = state.settings.featureFlags;

  const { groups } = state.reasons;

  const showInventoryUpdate = showInventoryData && !isLiveInventoryEnabled;

  const {
    PrintReasons: withReasonsList,
    PrintImage: withProductImages,
  } = state.print.selectedPrintingOption;

  const { currency } = general;

  const {
    opportunities,
    opportunitiesGroupContent,
    opportunitiesGroupCount,
  } = state.myOpportunities;

  const totalCount = opportunities?.length || 0;

  return {
    reasons: groups,
    opportunities,
    withReasonsList,
    withProductImages,
    totalCount,
    currency,
    opportunitiesGroupContent,
    opportunitiesGroupCount,
    language,
    productCategories,
    isRtl,
    showTargetSales,
    showTargetUnits,
    showPotentialRevenue,
    showPotentialUnits,
    showInventoryUpdate,
    filterList: FiltersSelector.getMyOpportunitiesFilterList(state),
  };
};

const mapDispatchToProps = {
  hide: PrintActions.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOpportunitiesPrintPage);
