import './previewHeader.scss';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { IPreviewHeaderProps } from './types';
import { SearchInput } from 'components/inputs';
import Breadcrumb from 'components/breadcrumb/Breadcrumb';
import { getCardDecoration } from 'components/openOpportunities/helpers';
import { IRootState } from 'store/types';
import {
  sliceHierarchyFilter,
  updateSearchFilter,
} from 'store/openOpportunities/openOpportunitiesActions';
import { DEBOUNCE_SEARCH_DELAY } from 'constants/constantsVaribles';

export const PreviewHeader: FC<IPreviewHeaderProps> = ({
  type,
  searchValue = '',
  updateSearchFilter,
  sliceHierarchyFilter,
  hierarchyPath = [],
  isRtl,
}) => {
  const [t] = useTranslation();

  const breadcrumbItems = hierarchyPath.map((hierarchy) => hierarchy.name);

  const { className } = getCardDecoration(type);

  // add delay on search
  const onSearchChange = debounce((text: string) => {
    updateSearchFilter(text);
  }, DEBOUNCE_SEARCH_DELAY);

  return (
    <div className={cn('open-opportunities-preview-header-container', className)}>
      <div className="top">
        <div className="title font-bold">{t(`OPEN_OPPORTUNITIES.${type}`)}</div>
        <SearchInput
          id="open-opportunities-search-input"
          defaultValue={searchValue}
          containerClassName="open-opportunities-search-container"
          onTextChanged={onSearchChange}
          isRtl={isRtl}
        />
      </div>
      <div className="bottom">
        <Breadcrumb separator=">" onItemClick={sliceHierarchyFilter} items={breadcrumbItems} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { hierarchyPath } = state.openOpportunities.drillDown;

  const { isRtl } = state.settings;

  return {
    isRtl,
    hierarchyPath,
  };
};

const mapDispatchToProps = {
  updateSearchFilter,
  sliceHierarchyFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PreviewHeader));
