import { FC, useState } from 'react';
import 'components/accordion/accordion.scss';
import { Collapse } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import './accordion.scss';

const { Panel } = Collapse;

export interface IAccordionProps {
  panels: Array<any>;
  isRtl?: boolean;
  renderPanelHeader: ({ panel }: any) => JSX.Element;
  renderPanelContent: ({ panel }: any) => JSX.Element;
  showExpandIcon?: boolean;
}

export const Accordion: FC<IAccordionProps> = ({
  panels,
  isRtl,
  renderPanelHeader: RenderPanelHeader,
  renderPanelContent,
  showExpandIcon = true,
}: IAccordionProps) => {
  const [activeKey, setActiveKey] = useState('0');

  const onChange = (key: string | string[]) => {
    setActiveKey(key as string);
  };

  const expandIconPosition = isRtl ? 'left' : 'right';

  const renderExpandIcon = (panelProps: { isActive?: boolean }) => {
    if (!showExpandIcon) {
      return <div />;
    }

    let rotationAngle = 0;
    if (isRtl) {
      rotationAngle = panelProps.isActive ? -90 : 90;
    } else {
      rotationAngle = panelProps.isActive ? 90 : 0;
    }

    return <RightOutlined rotate={rotationAngle} />;
  };

  return (
    <Collapse
      onChange={onChange}
      defaultActiveKey={activeKey}
      className="collapse"
      ghost
      accordion
      expandIcon={renderExpandIcon}
      expandIconPosition={expandIconPosition}
    >
      {panels.map((panel: any) => (
        <Panel
          id={`panel-${panel.id}`}
          header={<RenderPanelHeader activeKey={activeKey} panel={panel} />}
          key={panel.id}
          className="panel"
        >
          {renderPanelContent(panel)}
        </Panel>
      ))}
    </Collapse>
  );
};
