import { FC } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { NegativeArrowIcon, PositiveArrowIcon, QuestionMarkIcon, UpArrow } from 'components/Icons';
import {
  ActionButton,
  ActionButtonClasses,
  ArrowIconDirection,
  ISummaryCard,
  SummaryCardTypes,
} from 'types';
import './summaryCard.scss';
import { ProgressAnimation } from 'components/animation';
import { animationType } from 'components/animation/types';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

export const summaryCardTestId = 'summary-card';

const SummaryCard: FC<ISummaryCard> = ({
  cardId,
  cardType,
  headerText,
  mainText,
  arrowIconDirection,
  subText,
  footerInfoText,
  onSeeDetailsClick,
  showRatePopup,
  isLoading = false,
  shouldDisplayAction = true,
}) => {
  const [t] = useTranslation();

  const onQuestionMarkClicked = () => {
    if (showRatePopup) {
      AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_RATE_QUESTION_MARK, {
        card_type: cardId,
      });
      showRatePopup(cardId);
    }
  };

  const shouldShowQuestionMarkIcon = cardType === SummaryCardTypes.RATE;

  const shouldShowArrowIconDirection =
    arrowIconDirection !== undefined && cardType === SummaryCardTypes.RATE;

  const isArrowDirectionUp = arrowIconDirection === ArrowIconDirection.UP;

  return (
    <article
      id={cardId}
      className={cn('summary-card-container', cardType)}
      data-testid={summaryCardTestId}
    >
      <div className={cn('header')}>
        {headerText}
        {shouldShowQuestionMarkIcon && (
          <QuestionMarkIcon className="question-mark" onClick={onQuestionMarkClicked} />
        )}
      </div>
      <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
      <div className={cn('main-content')}>
        <div className="title-container">
          <h2 className="title">{mainText}</h2>
          {shouldShowArrowIconDirection &&
            (isArrowDirectionUp ? <PositiveArrowIcon /> : <NegativeArrowIcon />)}
        </div>
        <h4 className={cn('sub-text', { 'sub-text-empty': !subText })}>{subText}</h4>
      </div>

      <div className={cn('footer')}>
        <h3 className="footer-info">{footerInfoText}</h3>
        {shouldDisplayAction && (
          <ActionButton
            text={t('SUMMARY.SEE_DETAILS')}
            Icon={UpArrow}
            className={ActionButtonClasses.SEE_DETAILS}
            onClick={onSeeDetailsClick}
          />
        )}
      </div>
    </article>
  );
};

export default SummaryCard;
