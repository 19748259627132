import { FC } from 'react';
import { Breadcrumb as BreadcrumbAnt } from 'antd';

import './breadcrumb.scss';

export interface IBreadcrumb {
  separator?: string;
  items: Array<string>;
  onItemClick(itemIndex: number): void;
}

const Breadcrumb: FC<IBreadcrumb> = ({ separator, onItemClick, items }) => {
  return (
    <BreadcrumbAnt separator={separator}>
      {items.map((item, index) => (
        <BreadcrumbAnt.Item key={item} onClick={() => onItemClick(index)}>
          {item}
        </BreadcrumbAnt.Item>
      ))}
    </BreadcrumbAnt>
  );
};

export default Breadcrumb;
