import { flow, set } from 'lodash/fp';

import permissionsService from 'services/permissions/permissionsSrv';
import { httpResponseStatus } from 'store/api/APIConstants';
import { getUserInfo, getUserScope } from 'store/api/apiParser';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';
import { IActionType, UserPrivileges, UserRole } from 'types';
import { generateWebToken } from 'utils';
import { IUserProfileModel } from './types';

export const initialState: IUserProfileModel = {
  // Tenant
  uid: '',
  pid: '',
  cid: '',
  projectIds: [],
  email: '',
  emailVerified: false,
  alias: '',
  preferredUsername: '',
  sub: '',
  deviceToken: '',
  role: UserRole.NONE,
  serverResponseStatus: null,
  isAllUser: false,
  stores: [],
  userHighestHierarchyPath: [],
  userHierarchyPaths: [],
  userHighestHierarchyLowestLevel: null,
  userPeersLevel: null,
  // TODO when impl "client side" user management decide if to show dashboard first following mayViewDashboardToken
  // mayUserViewDashboard = mayViewDashboardToken && mayViewDashboard
  // If we pass permissions via the token, then we need to refer to it as well (see CAPP-18878)
  // Tokens mayViewDashboardToken should always take precedence mayViewDashboard
  mayViewDashboard: false,
  mayViewSalesIncrease: false,
  lastUserDeployDate: 0,
  mayViewOpportunities: false,
  isScopeError: undefined,
};

const userProfileReducer = (state: IUserProfileModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case UserProfileActionTypes.getUserScope.PENDING: {
      return flow([set('isScopeError', undefined)])(state);
    }

    case UserProfileActionTypes.getUserScope.SUCCESS: {
      const userProfile = getUserScope(payload);

      permissionsService.setUserProfile(userProfile);

      return flow([
        set('stores', userProfile.stores),
        set('userHighestHierarchyPath', userProfile.userHighestHierarchyPath),
        set('userHighestHierarchyLowestLevel', userProfile.userHighestHierarchyLowestLevel),
        set('userHierarchyPaths', userProfile.userHierarchyPaths),
        set('firstTenantDeployDate', userProfile.firstTenantDeployDate),
        set('lastTenantDeployDate', userProfile.lastTenantDeployDate),
        set('firstUserDeployDate', userProfile.firstUserDeployDate),
        set('lastUserDeployDate', userProfile.lastUserDeployDate),
        set('dashboards', userProfile.dashboards),
        set('isAllUser', userProfile.isAllUser),
        set('userPeersLevel', userProfile.userPeersLevel),
        set('role', userProfile.role),
        set('alias', userProfile.alias),
        set('selfReportTimeSpent', userProfile.selfReportTimeSpent),
        set('projectIds', userProfile.projectIds),
        set('fullName', userProfile.fullName),
        set('mayViewDashboard', userProfile.mayViewDashboard),
        set('mayViewOpportunities', userProfile.mayViewOpportunities),
        set('mayViewSalesIncrease', userProfile.mayViewSalesIncrease),
        set('availableStoreHierarchyLevels', userProfile.availableStoreHierarchyLevels),
        set('availableProductHierarchyLevels', userProfile.availableProductHierarchyLevels),
        set('isScopeError', false),
      ])(state);
    }

    case UserProfileActionTypes.getUserScope.FAILURE: {
      return flow([set('isScopeError', true)])(state);
    }

    case UserProfileActionTypes.setUserInfo: {
      const {
        userInfo,
        userTenant: { uid, pid, cid },
      } = payload;

      const {
        email,
        emailVerified,
        preferredUsername,
        sub,
        mayViewDashboard,
        alias,
        mayViewOpportunities,
      } = getUserInfo(userInfo);

      const deviceToken = generateWebToken(email);

      return flow([
        set('email', email),
        set('emailVerified', emailVerified),
        set('preferredUsername', preferredUsername),
        set('alias', alias),
        set('sub', sub),
        set('uid', uid),
        set('pid', pid),
        set('cid', cid),
        set('deviceToken', deviceToken),
        set(`${UserPrivileges.MAY_VIEW_DASHBOARDS_TOKEN}`, mayViewDashboard),
        set(`${UserPrivileges.MAY_VIEW_OPPORTUNITIES}`, mayViewOpportunities),
      ])(state);
    }

    case UserProfileActionTypes.submitContactUs.PENDING: {
      return flow([set('serverResponseStatus', null)])(state);
    }

    case UserProfileActionTypes.submitContactUs.SUCCESS: {
      return flow([set('serverResponseStatus', httpResponseStatus.Success)])(state);
    }

    case UserProfileActionTypes.submitContactUs.FAILURE: {
      return flow([set('serverResponseStatus', httpResponseStatus.Failure)])(state);
    }

    case UserProfileActionTypes.resetServerResponseStatus: {
      return flow([set('serverResponseStatus', null)])(state);
    }

    default:
      return state;
  }
};

export default userProfileReducer;
