import { Action } from 'history';

import { IResolvedURLParams, QueryParam } from 'routes/types';
import { resolveLegacyURLParams } from './UrlMappingUtils';

const getSearchParamsDictionary = (params: QueryParam[]) => {
  const searchParamsDict: { [key: string]: string } = params.reduce((accumulator, currentValue) => {
    if (currentValue.value) {
      return { ...accumulator, [currentValue.key]: currentValue.value };
    }

    return { ...accumulator };
  }, {});

  return searchParamsDict;
};

export const removeSlashesFromPathName = (path: string) => {
  return path.replace('/', '');
};

export const getSearchParams = (str: string) => {
  return new URLSearchParams(decodeURI(str));
};

export const buildSearchParamsString = (params: QueryParam[]) => {
  const searchParamsDict = getSearchParamsDictionary(params);

  const searchParams = new URLSearchParams(searchParamsDict);

  return encodeURI(searchParams.toString());
};

export const resolveLocationChangeParams = (
  location: any,
  action: Action,
  isFirstRendering = false,
) => {
  const { hash, pathname, search } = location;

  const data: IResolvedURLParams = {
    path: removeSlashesFromPathName(pathname), // get route path without '/',
    isDeepLink: location?.state?.isDeepLink || isFirstRendering || action === 'POP',
    isLegacyDeepLink: false,
    query: search,
  };

  // if legacy (mobile) deep link
  if (hash) {
    const { path, tab, query } = resolveLegacyURLParams(hash); // get query string from mobile deep link

    data.path = path;
    data.query = query;
    data.tab = tab;
    data.isLegacyDeepLink = true;
  }

  return data;
};
