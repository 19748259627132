import { set, flow } from 'lodash/fp';
import { LOCATION_CHANGE } from 'connected-react-router';

import MyOpportunitiesActionTypes from 'store/myOpportunities/myOpportunitiesActionTypes';
import {
  getOpportunities,
  getProductImagesMetadata,
  getUpdateGroupCountAndGroupContent,
  parseHitRateScore,
  parseInventoryListJoinSizeBySku,
} from 'store/api/apiParser';
import {
  emptyStateAnimationList,
  filterKey,
  filterValue,
  IActionType,
  IMyOpportunity,
  OpportunitiesTabsOptions,
} from 'types';
import { IMyOpportunitiesModel, SIZE } from './types';
import { isEmptyArray } from 'utils';
import SettingsActionTypes from 'store/settings/settingsActionTypes';
import { buildSearchFiltersOnLocationChange } from 'store/filters/filterHelper';
import { RoutePath } from 'routes/types';
import { buildMyOpportunitiesFilters, calculateAnimationKey } from './myOpportunitiesHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

export const initialState: IMyOpportunitiesModel = {
  opportunities: null,
  hasMore: false,
  opportunitiesGroupContent: [],
  opportunitiesGroupCount: [],
  totalCount: 0,
  totalCountOpenTab: 0, // not necessarily accurate (might contain filter - this is not the tab count)
  selectedMyOpportunity: null,
  nextPageOffset: 0,
  activeTab: OpportunitiesTabsOptions.Open,
  deepLinkOpportunityId: '',
  productImagesMetadata: [],
  liveInventory: null,
  motivationAnimationData: null,
  animatedEmptyState: false,
  isLastReasonSubmitted: false,
};

const myOpportunitiesReducer = (
  state: IMyOpportunitiesModel = initialState,
  action: IActionType,
) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    case MyOpportunitiesActionTypes.getMyOpportunities.SUCCESS: {
      const { rows, totalCount = 0 } = payload;

      const { filter, groupBy } = requestPayload;

      const { productImagesMetadata, deepLinkOpportunityId } = state;

      const {
        page: { from },
        filter: { filters },
      } = filter;

      const {
        nextPageOffset,
        opportunities,
        opportunitiesGroupContent: prevCardsGroupHeader,
        opportunitiesGroupCount: prevCardsGroupCount,
      } = state;

      const stateToUpdate = [];

      const stateOpportunities = opportunities || [];

      const stateCardsGroupCount = from === 0 ? [] : prevCardsGroupCount;

      const stateCardsGroupHeader = from === 0 ? [] : prevCardsGroupHeader;

      const {
        opportunities: myOpportunities,
        cardsGroupHeader,
        cardsGroupCount,
      } = getOpportunities(rows, groupBy, productImagesMetadata);

      if (
        !isEmptyArray(stateCardsGroupHeader) &&
        stateCardsGroupHeader![stateCardsGroupHeader.length - 1] === cardsGroupHeader![0]
      ) {
        cardsGroupCount[0] += stateCardsGroupCount[stateCardsGroupCount.length - 1];
        stateCardsGroupCount.pop();
        stateCardsGroupHeader.pop();
      }

      const opportunitiesGroupCount = [...stateCardsGroupCount, ...cardsGroupCount];

      const opportunitiesGroupContent = [...stateCardsGroupHeader, ...cardsGroupHeader];

      stateToUpdate.push(set('opportunitiesGroupContent', opportunitiesGroupContent));
      stateToUpdate.push(set('opportunitiesGroupCount', opportunitiesGroupCount));

      const newOpportunities =
        from === 0 ? myOpportunities : [...stateOpportunities, ...myOpportunities];
      stateToUpdate.push(set('opportunities', newOpportunities));

      const hasMore = newOpportunities.length < totalCount;
      stateToUpdate.push(set('totalCount', totalCount));

      let isOpenStateFilter = false;

      if (newOpportunities.length) {
        isOpenStateFilter = newOpportunities[0].state === 'OPENED';
        stateToUpdate.push(
          set(
            'activeTab',
            isOpenStateFilter ? OpportunitiesTabsOptions.Open : OpportunitiesTabsOptions.Completed,
          ),
        );
      } else {
        isOpenStateFilter = filters.find(
          (filter: { key: filterKey; value: filterValue }) =>
            filter.key === filterKey.STATE && filter.value === filterValue.OPENED,
        );
      }

      if (isOpenStateFilter) {
        stateToUpdate.push(set('totalCountOpenTab', totalCount));
      }

      const pageOffset = hasMore ? nextPageOffset + SIZE : nextPageOffset;
      stateToUpdate.push(set('nextPageOffset', pageOffset));
      stateToUpdate.push(set('hasMore', hasMore));

      const selectedOppId = state.selectedMyOpportunity?.id || deepLinkOpportunityId;

      if (from === 0) {
        // selectedOpportunityId is either from deeplink if App open using a link
        // Or persistent with prev fetch id
        // the default is the first one if none was selected
        const selectedOpportunityId =
          selectedOppId && newOpportunities.find((opp: IMyOpportunity) => opp.id === selectedOppId)
            ? selectedOppId
            : myOpportunities[0]?.id;

        const selectedMyOpportunity = newOpportunities.find(
          (opp: IMyOpportunity) => opp.id === selectedOpportunityId,
        );

        stateToUpdate.push(set('selectedMyOpportunity', selectedMyOpportunity));
      }

      return flow(stateToUpdate)(state);
    }

    case MyOpportunitiesActionTypes.removeOpportunityDeepLink: {
      return flow([set('deepLinkOpportunityId', '')])(state);
    }

    // On open tab - setting prev opportunity as selected
    // if the opportunity is the first select the next one
    // if there is just one opportunity, update selectedOpportunity to null
    // remove the selected opportunity from the list
    case MyOpportunitiesActionTypes.submitOpportunityReason.PENDING: {
      const {
        opportunities,
        opportunitiesGroupCount: stateOpportunitiesGroupCount,
        opportunitiesGroupContent: stateOpportunitiesGroupContent,
        selectedMyOpportunity,
        activeTab,
        totalCountOpenTab,
      } = state;
      if (!opportunities || !selectedMyOpportunity) {
        return state;
      }

      const stateToUpdate = [];

      const { id: stateSelectedOpportunityId } = selectedMyOpportunity;

      const selectedOpportunityIndex = opportunities.findIndex(
        (opportunity) => opportunity.id === stateSelectedOpportunityId,
      );
      if (activeTab === OpportunitiesTabsOptions.Open) {
        let selectedOpportunity = null;
        if (opportunities.length > 1) {
          if (selectedOpportunityIndex === 0) {
            selectedOpportunity = opportunities[1];
          } else {
            selectedOpportunity = opportunities[selectedOpportunityIndex - 1];
          }
        }

        const updatedOpportunities = opportunities.filter(
          (opportunity) => opportunity.id !== stateSelectedOpportunityId,
        );

        const {
          opportunitiesGroupCount,
          opportunitiesGroupContent,
        } = getUpdateGroupCountAndGroupContent(
          stateOpportunitiesGroupCount,
          stateOpportunitiesGroupContent,
          selectedOpportunityIndex,
        );
        stateToUpdate.push(set('opportunities', updatedOpportunities));
        stateToUpdate.push(set('selectedMyOpportunity', selectedOpportunity));
        stateToUpdate.push(set('selectedMyOpportunityId', selectedOpportunity?.id));
        stateToUpdate.push(set('totalCountOpenTab', totalCountOpenTab - 1));
        stateToUpdate.push(set('opportunitiesGroupCount', opportunitiesGroupCount));
        stateToUpdate.push(set('opportunitiesGroupContent', opportunitiesGroupContent));
      } else if (selectedOpportunityIndex !== -1) {
        const { selectedReason } = payload;

        const selectedOpportunity = { ...selectedMyOpportunity };
        selectedOpportunity.selectedReason = selectedReason;

        opportunities[selectedOpportunityIndex] = selectedOpportunity;
        stateToUpdate.push(set('opportunities', opportunities));
        stateToUpdate.push(set('selectedMyOpportunity', selectedOpportunity));
      }

      return flow(stateToUpdate)(state);
    }

    case MyOpportunitiesActionTypes.submitOpportunityReason.SUCCESS: {
      const stateToUpdate = [];

      const { opportunities } = state;

      if (!opportunities?.length) {
        stateToUpdate.push(set('animatedEmptyState', true));
      }

      return flow(stateToUpdate)(state);
    }

    case SettingsActionTypes.getSettings.SUCCESS: {
      const { featureFlags } = payload;

      const apiProductImagesMetadata = featureFlags?.generalMetadata?.productImagesMetadata;

      const productImagesMetadata = getProductImagesMetadata(apiProductImagesMetadata);

      return flow([set('productImagesMetadata', productImagesMetadata)])(state);
    }

    case MyOpportunitiesActionTypes.getHitRateScore.SUCCESS: {
      const { rows = [] } = payload;

      const stateToUpdate = [];

      // if motivationAnimationData = null
      stateToUpdate.push(set('animatedEmptyState', false));

      if (rows.length) {
        const hitRateScore = parseHitRateScore(rows[0]);

        if (hitRateScore) {
          const emptyStateAnimationKey = calculateAnimationKey(hitRateScore);

          const animationType = Number.isNaN(emptyStateAnimationKey)
            ? null
            : emptyStateAnimationList[emptyStateAnimationKey];

          // send google analytics event
          if (animationType) {
            const eventLabel = animationType
              ? `Level ${emptyStateAnimationKey + 1} (${animationType})`
              : 'All Clear';

            AnalyticSrv.sendViewEvent(AnalyticsEventCategory.ANIMATED_EMPTY_STATE, {
              eventLabel,
            });
          }

          let motivationAnimationData = null;

          if (animationType) {
            motivationAnimationData = {
              hitRateScore,
              animationType,
            };
          }

          if (motivationAnimationData) {
            stateToUpdate.push(set('motivationAnimationData', motivationAnimationData));
            stateToUpdate.push(set('animatedEmptyState', true));
          }
        }
      }

      return flow(stateToUpdate)(state);
    }

    case MyOpportunitiesActionTypes.getLiveInventory.SUCCESS: {
      const { data = null } = payload;

      const { selectedMyOpportunity } = state;

      // join sizes and skus
      const joinedInventory = parseInventoryListJoinSizeBySku(data, selectedMyOpportunity);

      return set('liveInventory', joinedInventory, state);
    }

    case MyOpportunitiesActionTypes.selectMyOpportunity: {
      const { opportunityId } = payload;

      const { opportunities } = state;

      let selectedMyOpportunity = opportunities?.find((opp) => opp.id === opportunityId);

      // Fallback selection is the first item in list
      if (!selectedMyOpportunity && opportunities!.length > 0) {
        selectedMyOpportunity = opportunities![0];
      }

      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.SELECT_OPPORTUNITY, {
        opportunity_id: opportunityId,
      });

      return flow([
        set('selectedMyOpportunity', selectedMyOpportunity),
        set('liveInventory', null),
      ])(state);
    }

    case MyOpportunitiesActionTypes.setActiveTab: {
      const { activeTab } = payload;

      return flow([set('activeTab', activeTab), set('opportunities', null)])(state);
    }

    // listen for location change and in case this is a
    // deep link (location.state.isDeepLink || router.isFirstRendering)
    // then update filters with search params
    case LOCATION_CHANGE: {
      const { location, isFirstRendering, action } = payload;

      const stateToUpdate = [];

      // if animatedEmptyState was true and location had changed (including tab switch)
      // set animatedEmptyState to false so animation will not reappear
      const { animatedEmptyState } = state;

      // toggle animatedEmptyState
      if (animatedEmptyState) {
        stateToUpdate.push(set('animatedEmptyState', false));
        stateToUpdate.push(set('motivationAnimationData', null));
      }

      const searchFilters = buildSearchFiltersOnLocationChange(
        location,
        action,
        isFirstRendering,
        RoutePath.MyOpportunities,
      );

      if (searchFilters) {
        const { selectedTab, deepLinkId } = buildMyOpportunitiesFilters(searchFilters);

        stateToUpdate.push(set('deepLinkOpportunityId', deepLinkId));
        stateToUpdate.push(set('activeTab', selectedTab));
      }

      return flow(stateToUpdate)(state);
    }

    default:
      return state;
  }
};

export default myOpportunitiesReducer;
