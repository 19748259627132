import { cloneDeep, isEmpty } from 'lodash';

import {
  direction,
  DrillDownCategoriesTypes,
  DrillDownTypes,
  filterKey,
  IDate,
  IFilterItem,
  IFindingsFilter,
  IDrillDownHierarchy,
  ISearchParamsFilters,
  ISelectedCategory,
  ITrendsDrillDownMutualFilters,
  ITrendsFilter,
  RateHistogram,
  SelectedDateIntervals,
  TrendsTabOptions,
} from 'types';
import { FilterBuilder } from 'store/filters/filterBuilder';
import { MonthsInterval, SIZE } from 'constants/constantsVaribles';
import {
  mapCategoriesToFilter,
  mapCategoriesToFilterByEarning,
  mapCategoriesTypeToCategoryByEarning,
  mapCategoryTypeToSearchKey,
  mapCategoryTypeToSearchKeyByEarning,
  mapDrillDownToHierarchyNameBySalesIncrease,
  mapDrillDownTypesToLeafFilter,
  mapDrillDownTypesToLeafFilterByEarning,
  mapDrillDownTypeToFilterField,
  mapDrillDownTypeToFilterFieldByEarning,
  mapDrillDownTypeToSearchKey,
  mapDrillDownTypeToSearchKeyByEarning,
  mapLeafDrillDownTypeToSearchKey,
  mapLeafDrillDownTypeToSearchKeyByEarning,
} from 'constants/hierarchy';
import { checkIsLeaf } from 'store/helpers/drillDown';
import {
  getCurrentDate,
  getDatesMonthsDiff,
  getEndOfMonth,
  getSubtractDateByMonths,
  isEmptyArray,
} from 'utils';
import { mapTrendsTabOptionToOrderFieldName } from 'components/trends/helper';
import { buildDrilldownFilterFromQuery } from 'store/filters/filterHelper';
import { DropDownFiltersInitialState } from './trendsReducer';

const addSearchFilterByType = (
  filterBuilder: FilterBuilder,
  type: DrillDownTypes,
  hierarchyDepth: number,
  mutualFilters: ITrendsDrillDownMutualFilters,
  isEarning: boolean,
) => {
  const { search, hierarchyIndex } = mutualFilters;

  const hierarchyMapper: any = isEarning
    ? mapDrillDownTypeToSearchKeyByEarning
    : mapDrillDownTypeToSearchKey;

  const leafMapper: any = isEarning
    ? mapLeafDrillDownTypeToSearchKeyByEarning
    : mapLeafDrillDownTypeToSearchKey;

  if (!isEmpty(search)) {
    const isLeaf = checkIsLeaf(hierarchyIndex, hierarchyDepth);
    let fieldName = `${hierarchyMapper[type]}.${hierarchyIndex}`;

    if (isLeaf) {
      fieldName = `${leafMapper[type]}`;
    }
    filterBuilder.and().freeText(search, [fieldName]);
  }
};

const addOtherDrillDownSearch = (
  filterBuilder: FilterBuilder,
  text: string,
  isEarning: boolean,
  selectedCategory?: ISelectedCategory,
) => {
  if (!isEmpty(text) && selectedCategory) {
    const mapper: any = isEarning
      ? mapCategoryTypeToSearchKeyByEarning
      : mapCategoryTypeToSearchKey;

    const { value, type } = selectedCategory;
    let fieldName = `${mapper[type]}`;
    if (
      type === DrillDownCategoriesTypes.StoreCategory ||
      type === DrillDownCategoriesTypes.ProductCategory
    ) {
      const key = value.split('_')[3];

      fieldName += `.${key}`;
    }

    const fields = fieldName ? [fieldName] : undefined;

    filterBuilder.and().freeText(text, fields);
  }
};

const addOtherDrillDownFilters = (
  filterBuilder: FilterBuilder,
  filters: Array<IFilterItem>,
  isEarning: boolean,
) => {
  const mapper = isEarning ? mapCategoriesToFilterByEarning : mapCategoriesToFilter;

  filters.forEach((filter) => {
    const { hierarchyValue, categoryType, value } = filter;

    if (categoryType && hierarchyValue) {
      // default field is leaf field
      // e.g. for stores-> storeId
      let field = `${mapper[categoryType]}`;

      // check if it's category
      if (
        categoryType === DrillDownCategoriesTypes.StoreCategory ||
        categoryType === DrillDownCategoriesTypes.ProductCategory
      ) {
        // get category value.
        // e.g. for PRODUCT_CATEGORY_1 -> 1
        const key = hierarchyValue.split('_')[3];

        // e.g. for PRODUCT_CATEGORY_1 -> 1
        field += `.${key}`;
      }

      filterBuilder.and().term(field, value);
    }
  });
};

const addSearchFilter = (
  filterBuilder: FilterBuilder,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  filters: ITrendsFilter,
  tab: TrendsTabOptions,
  selectedCategory?: ISelectedCategory,
) => {
  const isEarning = tab === TrendsTabOptions.Earning;

  const { mutualFilters: storeMutualFilters } = filters[DrillDownTypes.Store];

  const { mutualFilters: productMutualFilters } = filters[DrillDownTypes.Product];

  const {
    mutualFilters: { search: searchOther },
  } = filters[DrillDownTypes.Other];

  addSearchFilterByType(
    filterBuilder,
    DrillDownTypes.Store,
    storeHierarchyDepth,
    storeMutualFilters,
    isEarning,
  );

  addSearchFilterByType(
    filterBuilder,
    DrillDownTypes.Product,
    productHierarchyDepth,
    productMutualFilters,
    isEarning,
  );

  addOtherDrillDownSearch(filterBuilder, searchOther, isEarning, selectedCategory);
};

const addDrillDownFiltersByType = (
  filterBuilder: FilterBuilder,
  drillDownType: DrillDownTypes,
  isEarning: boolean,
  hierarchyPath: Array<IDrillDownHierarchy>,
  hierarchyDepth: number,
  shouldExcludeLastHierarchy: boolean,
) => {
  const hierarchyPathLength = shouldExcludeLastHierarchy
    ? hierarchyPath.length - 1
    : hierarchyPath.length;

  const hierarchyMapper = isEarning
    ? mapDrillDownTypeToFilterFieldByEarning
    : mapDrillDownTypeToFilterField;

  const leafMapper = isEarning
    ? mapDrillDownTypesToLeafFilterByEarning
    : mapDrillDownTypesToLeafFilter;

  // go through the hierarchy path
  for (let i = 0; i < hierarchyPathLength; i++) {
    // default filter key
    let key = `${hierarchyMapper[drillDownType]}.${i + 1}`;

    const { value } = hierarchyPath[i];

    // check if it's leaf
    if (i >= hierarchyDepth) {
      // change filter key according to the drill-down type
      key = leafMapper[drillDownType];
    }

    filterBuilder.and().term(key, value);
  }
};

const addDrillDownFilters = (
  filterBuilder: FilterBuilder,
  filters: ITrendsFilter,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  tab: TrendsTabOptions,
  shouldExcludeLastHierarchy: boolean,
  selectedCategory?: ISelectedCategory,
) => {
  const {
    store: { hierarchyPath: storeHierarchyPath },
    product: { hierarchyPath: productHierarchyPath },
    other: { filters: otherFilters },
  } = filters;

  const isEarning = tab === TrendsTabOptions.Earning;

  addDrillDownFiltersByType(
    filterBuilder,
    DrillDownTypes.Store,
    isEarning,
    storeHierarchyPath,
    storeHierarchyDepth,
    shouldExcludeLastHierarchy,
  );

  addDrillDownFiltersByType(
    filterBuilder,
    DrillDownTypes.Product,
    isEarning,
    productHierarchyPath,
    productHierarchyDepth,
    shouldExcludeLastHierarchy,
  );

  addOtherDrillDownFilters(filterBuilder, otherFilters, isEarning);

  addSearchFilter(
    filterBuilder,
    storeHierarchyDepth,
    productHierarchyDepth,
    filters,
    tab,
    selectedCategory,
  );
};

const addTimeFilter = (
  filterBuilder: FilterBuilder,
  filters: ITrendsFilter,
  tab: TrendsTabOptions,
) => {
  const {
    date: { type, from, to },
  } = filters;

  const isEarning = tab === TrendsTabOptions.Earning;

  const field = isEarning ? filterKey.EARNING_DATE : filterKey.DEPLOY_DATE;

  if (type !== SelectedDateIntervals.all) {
    if (type === SelectedDateIntervals.customId) {
      filterBuilder.and().time(field, from, to);
    } else {
      const monthsToSubtract = MonthsInterval[type];

      const from = getSubtractDateByMonths(monthsToSubtract);

      filterBuilder.and().time(field, from);
    }
  }
};

const addDrillDownTimeFilter = (filterBuilder: FilterBuilder, filters: ITrendsFilter) => {
  const { selectedMonth, selectedTab } = filters;

  const isEarning = selectedTab === TrendsTabOptions.Earning;

  const field = isEarning ? filterKey.EARNING_DATE : filterKey.DEPLOY_DATE;

  if (selectedMonth) {
    const to = getEndOfMonth(selectedMonth);
    filterBuilder.and().time(field, selectedMonth, to);
  } else {
    addTimeFilter(filterBuilder, filters, selectedTab);
  }
};

// filter for drill downs
export const buildDrillDownFilter = (
  filters: ITrendsFilter,
  drillDownType: DrillDownTypes,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  pageOffset: number,
  selectedCategory?: ISelectedCategory,
) => {
  const filterBuilder = new FilterBuilder();

  const { selectedTab } = filters;

  const shouldExcludeLastHierarchy = false;

  addDrillDownFilters(
    filterBuilder,
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    selectedTab,
    shouldExcludeLastHierarchy,
    selectedCategory,
  );

  addDrillDownTimeFilter(filterBuilder, filters);

  const filter = filterBuilder.build();

  const { orderDirection: direction } = filters[drillDownType].activeFilters;

  const fieldName = mapTrendsTabOptionToOrderFieldName[selectedTab];

  const isExpended = filters.expendedDrillDown === drillDownType;

  const PAGE_SIZE = isExpended ? SIZE : 3;

  const orders = [{ direction, fieldName }];

  const filterBody: any = {
    orders,
    page: {
      from: pageOffset,
      size: PAGE_SIZE,
    },
  };
  if (!isEmpty(filter)) {
    filterBody.filter = filter;
  }

  return filterBody;
};

const buildGetHistogramRequestFilter = (
  filters: ITrendsFilter,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  tab: TrendsTabOptions,
  shouldExcludeLastHierarchy: boolean,
  selectedCategory?: ISelectedCategory,
) => {
  const filterBuilder = new FilterBuilder();

  addDrillDownFilters(
    filterBuilder,
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    tab,
    shouldExcludeLastHierarchy,
    selectedCategory,
  );

  addTimeFilter(filterBuilder, filters, tab);

  const filter = filterBuilder.build();

  if (isEmpty(filter)) {
    return {};
  }

  return { filter };
};

export const buildGetHistogramRequestFilterBody = (
  type: TrendsTabOptions,
  filters: ITrendsFilter,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  selectedCategory?: ISelectedCategory,
): any => {
  const myStoresFilter = buildGetHistogramRequestFilter(
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    type,
    false,
    selectedCategory,
  );

  const peersFilter = buildGetHistogramRequestFilter(
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    type,
    true,
    selectedCategory,
  );

  return { myStoresFilter, peersFilter };
};

export const getSalesIncreaseHierarchyEndpoint = (
  drillDownType: DrillDownTypes,
  hierarchyIndex: number,
) => {
  return `${mapDrillDownToHierarchyNameBySalesIncrease[drillDownType]}/LEVEL${hierarchyIndex}`;
};

export const getSalesIncreaseCategoryEndpoint = (selectedCategory: ISelectedCategory) => {
  const { value, type } = selectedCategory;

  return `${mapCategoriesTypeToCategoryByEarning[type]}/${value}`;
};

// Add /MONTH to request with time frame bigger then 3 months
export const getRateHistogramEndpoint = (selectedDate: IDate) => {
  let endpoint = '';

  const { type, from, to } = selectedDate;

  const isCustomDate = type === SelectedDateIntervals.customId;

  if (isCustomDate) {
    const diff = getDatesMonthsDiff(from!, to!);

    if (diff > 3) {
      endpoint = '/MONTH';
    }
  } else if (
    type !== SelectedDateIntervals.monthId &&
    type !== SelectedDateIntervals.threeMonthsId
  ) {
    endpoint = '/MONTH';
  }

  return endpoint;
};

export const verifyIsFiltersApplied = (filters: IFindingsFilter | ITrendsFilter) => {
  const { filters: storeFilters } = filters[DrillDownTypes.Store];

  const { filters: productFilters } = filters[DrillDownTypes.Product];

  const { filters: otherFilters } = filters[DrillDownTypes.Other];

  return (
    !isEmptyArray(storeFilters) || !isEmptyArray(productFilters) || !isEmptyArray(otherFilters)
  );
};

export const getSearch = (filters: IFindingsFilter | ITrendsFilter) => {
  const { expendedDrillDown } = filters;

  const { search: storeSearch } = filters[DrillDownTypes.Store].mutualFilters;

  const { search: productSearch } = filters[DrillDownTypes.Product].mutualFilters;

  const { search: otherSearch } = filters[DrillDownTypes.Other].mutualFilters;

  let search = '';
  if (expendedDrillDown === DrillDownTypes.Store) {
    search = storeSearch;
  } else if (expendedDrillDown === DrillDownTypes.Product) {
    search = productSearch;
  } else if (expendedDrillDown === DrillDownTypes.Other) {
    search = otherSearch;
  }

  return search;
};

export const buildResponseRateData = (
  responseRateData: RateHistogram | null,
  responseRateChainWideData: RateHistogram,
) => {
  if (!responseRateData) {
    return null;
  }

  return responseRateData.map((item, index) => {
    const { date, value: myStoresValue } = item;

    const chainWideValue = responseRateChainWideData![index]?.value || 0;

    return { date, myStoresValue, chainWideValue };
  });
};

export const buildHitRateData = (
  hitRateData: RateHistogram | null,
  hitRateChainWideData: RateHistogram,
) => {
  if (!hitRateData) {
    return null;
  }

  return hitRateData.map((item, index) => {
    const { date, value: myStoresValue } = item;

    const chainWideValue = hitRateChainWideData![index]?.value || 0;

    return { date, myStoresValue, chainWideValue };
  });
};

const buildEmptyFilters = (date?: IDate, tab?: string | null) => {
  const filters: ITrendsFilter = {
    date: date || {
      type: SelectedDateIntervals.twelveMonthsId,
      from: getCurrentDate(),
      to: getCurrentDate(),
    },
    selectedMonth: null,
    selectedTab: tab ? (tab as TrendsTabOptions) : TrendsTabOptions.Earning,
    expendedDrillDown: null,
    [DrillDownTypes.Store]: cloneDeep(DropDownFiltersInitialState),
    [DrillDownTypes.Product]: cloneDeep(DropDownFiltersInitialState),
    [DrillDownTypes.Other]: cloneDeep(DropDownFiltersInitialState),
  };

  return filters;
};

export const buildFiltersFromSearchParams = (
  searchFilters: ISearchParamsFilters,
  currentFilter: ITrendsFilter,
) => {
  const { date, tab, store, product, other } = searchFilters;

  const newFilters = buildEmptyFilters(date, tab);

  if (other) {
    const { filter, category, order = direction.DESC } = other;

    const currentSelectedCategory =
      currentFilter[DrillDownTypes.Other].activeFilters.selectedCategory;

    if (filter) {
      const otherFilters = [buildDrilldownFilterFromQuery(filter, DrillDownTypes.Other, category)];

      newFilters[DrillDownTypes.Other].filters = otherFilters;
    }

    // set selected category if not equal to current
    newFilters[DrillDownTypes.Other].activeFilters.selectedCategory =
      category || currentSelectedCategory;
    newFilters[DrillDownTypes.Other].activeFilters.orderDirection = order;
  }

  if (store) {
    const { filter, hierarchyPath = [], order = direction.DESC, hierarchyIndex = 1 } = store;

    if (filter) {
      const storeFilters = [buildDrilldownFilterFromQuery(filter, DrillDownTypes.Store)];
      newFilters[DrillDownTypes.Store].filters = storeFilters;
    }
    newFilters[DrillDownTypes.Store].hierarchyPath = [...hierarchyPath];
    newFilters[DrillDownTypes.Store].activeFilters.orderDirection = order;
    newFilters[DrillDownTypes.Store].mutualFilters.hierarchyIndex = hierarchyIndex;
  }

  if (product) {
    const { filter, hierarchyPath = [], order = direction.DESC, hierarchyIndex = 1 } = product;

    if (filter) {
      const productFilters = [buildDrilldownFilterFromQuery(filter, DrillDownTypes.Product)];
      newFilters[DrillDownTypes.Product].filters = productFilters;
    }
    newFilters[DrillDownTypes.Product].hierarchyPath = [...hierarchyPath];
    newFilters[DrillDownTypes.Product].activeFilters.orderDirection = order;
    newFilters[DrillDownTypes.Product].mutualFilters.hierarchyIndex = hierarchyIndex;
  }

  return newFilters;
};
