import { createSelector } from 'reselect';

import i18n from 'i18n/i18n';
import { IVideo, IVideoPreview, IRootState, IVideoCard } from 'types';
import { getBaseUrl } from 'store/api/APIConstants';

const getSelectedVideoIdSelector = (state: IRootState) => state.knowledgeCenter.selectedVideoId;

const getVideosSelector = (state: IRootState) => state.knowledgeCenter.videos;

const getEnvironmentSelector = (state: IRootState) => state.environment.currentEnvironment;

const getVideoCardsSelector = createSelector(
  [getVideosSelector, getSelectedVideoIdSelector, getEnvironmentSelector],
  (
    getVideosSelector,
    getSelectedVideoIdSelector,
    getEnvironmentSelector,
  ): Array<IVideoCard> | null => {
    if (!getVideosSelector) {
      return null;
    }

    const { language } = i18n;

    const DEFAULT_LANGUAGE = 'en-US';

    const baseUrl = getBaseUrl(getEnvironmentSelector);

    return getVideosSelector
      .filter((video) => video.id !== getSelectedVideoIdSelector)
      .map((video: IVideo) => ({
        id: video.id || '',
        title: video.title[language] || video.title[DEFAULT_LANGUAGE] || '',
        date: video.date || 0,
        info: video.info[language] || video.info[DEFAULT_LANGUAGE] || '',
        posterSrc: video.posterSrc ? baseUrl + video.posterSrc : '',
        duration: video.duration || 0,
      }));
  },
);

const getSelectedVideosSelector = createSelector(
  [getSelectedVideoIdSelector, getVideosSelector, getEnvironmentSelector],
  (getSelectedVideoIdSelector, getVideosSelector, getEnvironmentSelector): IVideoPreview | null => {
    if (!getSelectedVideoIdSelector || !getVideosSelector) {
      return null;
    }

    const { language } = i18n;

    const DEFAULT_LANGUAGE = 'en-US';

    const selectedVideo: IVideo | undefined = getVideosSelector.find(
      (video) => video.id === getSelectedVideoIdSelector,
    );

    if (!selectedVideo) {
      return null;
    }

    const subtitleLang =
      selectedVideo.supportedSubtitles?.find((supportSubtitle) => supportSubtitle === language) ||
      DEFAULT_LANGUAGE;

    const baseUrl = getBaseUrl(getEnvironmentSelector);

    const { posterSrc, videoSrc } = selectedVideo;

    const subtitleSrc = videoSrc
      ? `${videoSrc.slice(0, videoSrc.lastIndexOf('.'))}_${subtitleLang}.vtt`
      : undefined;

    const title = selectedVideo.title[language] || selectedVideo.title[DEFAULT_LANGUAGE] || '';

    const info = selectedVideo.info[language] || selectedVideo.info[DEFAULT_LANGUAGE] || '';

    return {
      posterSrc: posterSrc ? baseUrl + posterSrc : '',
      videoSrc: videoSrc ? baseUrl + videoSrc : '',
      id: selectedVideo.id || '',
      duration: selectedVideo.duration || 0,
      date: selectedVideo.date || 0,
      subtitleSrc: subtitleSrc ? baseUrl + subtitleSrc : '',
      info,
      title,
    };
  },
);

const KnowledgeCenterSelector = {
  getSelectedVideosSelector,
  getVideoCardsSelector,
};

export default KnowledgeCenterSelector;
