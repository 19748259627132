import {
  ActionTypes,
  CurrencySymbolOptions,
  direction,
  FlavourTypes,
  ISalesValues,
  LifecycleOptions,
  ResponseTypes,
} from 'types';

export enum DrillDownTypes {
  Store = 'store',
  Product = 'product',
  Other = 'other',
}

export interface IDrillDownHierarchy {
  name: string;
  value: string;
  hierarchyName: string;
  hierarchyLevel?: number;
}

export interface IDrillDownLegacyHierarchy {
  id: string;
  level: string;
  name?: string;
}

export interface IHeaderFilters {
  filters: Array<IFilterItem>;
  isVisible: boolean;
  onRemoveFilter(filter: IFilterItem): void;
  onSearchChange(text: string): void;
  isRtl?: boolean;
}

export interface IDrillDownHeader {
  title: string;
  subTitle: string;
  isActive: boolean;
  hierarchyPath: Array<IDrillDownHierarchy>;
  orderDirection: direction;
  filters: Array<IFilterItem>;
  isFreeTextOnly: boolean;
  shouldDisplayFreeText: boolean;
  isFiltersVisible?: boolean;
  isTitleClickable?: boolean;
  onTitleClick?: () => void;
  isRtl?: boolean;

  onFreeTextToggle(): void;
  onToggleDirection(): void;

  onRemoveFilter(filter: IFilterItem): void;

  onHierarchyPathClick(hierarchyItemIndex: number): void;

  onSearchChange(text: string): void;
}

export interface IProductSkuAndSize {
  sku: string;
  size?: string;
}

export interface IDrillDownOpportunitySelectedReason {
  type: ResponseTypes;
  completedDate?: string;
  title: string;
  description?: string;
}

export interface IDrillDownOpportunity {
  productName: string;
  lifecycle: LifecycleOptions;
  productImage: string | null;
  storeName: string;
  id: string;
  productSkusAndSizes: Array<IProductSkuAndSize>;
  productCategories: { [key: number]: string };
  selectedReason: IDrillDownOpportunitySelectedReason;
  targetSales: number;
  potentialRevenue: number;
  potentialUnitsSales: number;
  targetUnits: number;
  inventoryLatestUpdateDate?: number;
  planogramAttributes?: { [key: string]: string };
  inventoryLevel?: number;
  lastSalesValues: ISalesValues;
  lastSimilarSalesValues: ISalesValues;
  lastUnitsValues: ISalesValues | null;
  lastSimilarUnitsValues: ISalesValues | null;
  lastSalesValuesTotal: number;
  lastSimilarSalesValuesTotal: number;
  lastUnitsValuesTotal: number;
  lastSimilarUnitsValuesTotal: number;
  action: ActionTypes;
  flavour: FlavourTypes;
}

export interface IDrillDownOpportunityProps {
  productName: string;
  isExpired: boolean;
  storeName: string;
  productSku: string;
  productCategories: { [key: number]: string };
  reason: string;
  id: string;
  description?: string;
  onOpportunityClick(opportunityId: string): void;
}

export interface IDrillDownOpportunitiesProps {
  list: Array<IDrillDownOpportunity> | null;
  opportunitiesGroupContent?: Array<string>;
  opportunitiesGroupCount?: Array<number>;
  emptyState: any;
  isLoading: boolean;
  totalCount: number;
  shouldCalcHeight?: boolean;
  onOpportunityClick(selectedOpportunityId: string): void;
  onLoadMore(): void;
}

export interface IDrillDownRow {
  value: string;
  name: string;
  rate: number;
  count: number;
  totalCount: number;
}

export interface IFilterItem {
  hierarchyName: string;
  value: string;
  name: string;
  drillDownType: DrillDownTypes;
  hierarchyValue?: string;
  categoryType?: DrillDownCategoriesTypes;
}

export interface IDrillDownItemProps extends IDrillDownRow {
  shouldShowOptions: boolean;
  isLastHierarchyLevel: boolean;
  isActive: boolean;
  shouldDisplayShortCurrency: boolean;
  shouldDisplayTotalAndPercent: boolean;
  currency: CurrencySymbolOptions;
  progressTotalValue?: number;
  onActiveToggle(): void;
  onShowOpportunitiesClick(filter: { name: string; value: string }): void;
  onFilterClick(filter: { name: string; value: string }): void;
}

export interface IDrillDownOpportunities {
  list: Array<IDrillDownOpportunity>;
  groupContent: Array<string>;
  groupCount: Array<number>;
}

export interface IDrillDownBase {
  isExpended: boolean;
  hierarchyPath: Array<IDrillDownHierarchy>;
  order: direction;
  filters: Array<IFilterItem>;
  isFreeTextOnly: boolean;
  totalCount: number;
  isFiltersVisible?: boolean;
  isOpportunitySelected: boolean;
  list: Array<IDrillDownRow> | null;
  isLoading: boolean;
  opportunities: IDrillDownOpportunities | null;
  hierarchyName: string;
}

export interface IDrillDownProps extends IDrillDownBase {
  id: string;
  isLastHierarchyLevel: boolean;
  isTitleClickable: boolean;
  isHidden: boolean;
  currency: CurrencySymbolOptions;
  isOptionBarEnable: boolean;
  onTitleClick?: () => void;
  shouldDisplayShortCurrency: boolean;
  shouldDisplayTotalAndPercent: boolean;
  headerSubTitle: string;
  isNumericData: boolean;
  highestValue: number;
  searchText: string;
  isFiltersApplied: boolean;
  isRtl?: boolean;
  onToggleDirection(): void;
  onOpportunityClick(selectedOpportunityId: string): void;
  onFreeTextToggle(): void;
  onLoadMore(): void;
  onRemoveFilter(filter: IFilterItem): void;
  onHierarchyPathClick(hierarchyItemIndex: number): void;
  onSearchChange(text: string): void;
  onExpendedToggle(): void;
  onFilterClick(filter: IFilterItem): void;
  onShowOpportunitiesClick(): void;
}

export interface IDrillDownList {
  list: Array<IDrillDownRow> | null;
  isLoading: boolean;
  isLastHierarchyLevel: boolean;
  totalCount: number;
  shouldDisplayShortCurrency: boolean;
  shouldDisplayTotalAndPercent: boolean;
  hierarchyName: string;
  isNumericData: boolean;
  emptyState: any;
  currency: CurrencySymbolOptions;
  isOptionBarEnable: boolean;
  highestValue: number;
  onLoadMore(): void;
  onFilterClick(filter: IFilterItem): void;
  onShowOpportunitiesClick(): void;
}

// other drill down categories options
export enum DrillDownCategoriesTypes {
  ProductCategory = 'PRODUCT_CATEGORY',
  StoreCategory = 'STORE_CATEGORY',
  Store = 'Store',
  Product = 'Product',
  Opportunity = 'Opportunity',
}

// legacy other drill down categories options
export enum LegacyDrillDownCategoriesTypes {
  ProductCategory = 'PRODUCT_CATEGORY',
  StoreCategory = 'STORE_CATEGORY',
  Store = 'LEAF',
  Product = 'PRODUCT',
}

export interface ILegacyOtherDrilDownFilter {
  [category: string]: string[];
}

export const parseLegacyDrillDownCategoryToCategory = {
  [LegacyDrillDownCategoriesTypes.Product]: DrillDownCategoriesTypes.Product,
  [LegacyDrillDownCategoriesTypes.Store]: DrillDownCategoriesTypes.Store,
  [LegacyDrillDownCategoriesTypes.ProductCategory]: DrillDownCategoriesTypes.ProductCategory,
  [LegacyDrillDownCategoriesTypes.StoreCategory]: DrillDownCategoriesTypes.StoreCategory,
};

export interface IActiveOptionMenu {
  name: string;
  isActive: boolean;
  onFilter(): void;
  onClose(): void;
  onShowOpportunities(): void;
}

export interface ISelectedCategory {
  label: string;
  value: string;
  type: DrillDownCategoriesTypes;
}

export interface IOtherSearchDrilldown {
  filter?: IDrillDownHierarchy;
  category?: ISelectedCategory;
  order?: direction;
}

export interface ISearchDrilldown {
  filter?: IDrillDownHierarchy;
  hierarchyPath?: IDrillDownHierarchy[];
  hierarchyIndex?: number;
  order?: direction;
}
