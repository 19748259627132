import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ResizeObserver from 'resize-observer-polyfill';
import 'polyfill/IntlPolyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import 'styles/fonts.scss';
import 'antd/dist/antd.compact.min.css';
import analyticsService from 'services/analytics/AnalyticSrv';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

analyticsService.init();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
