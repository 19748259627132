import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IGroupedReasonsModel, IPrintReasons } from 'types';

const Reasons: FC<IPrintReasons> = ({ reasons, language }) => {
  const [t] = useTranslation();

  const getReasonRow = (label: string, index: number) => (
    <div className="reason-print-row" key={index}>
      <div className="reason-number-print">{index}&nbsp;</div>
      <span className="option-text">{label}</span>
    </div>
  );

  const getReasons = (reasonsList: Array<IGroupedReasonsModel>) => {
    if (!reasonsList) {
      return null;
    }

    let startOptionIndex = 1;

    return reasonsList.map((groupedReasons) => {
      const label = groupedReasons.label[language];

      const options = groupedReasons.options.map((option, index) => ({
        label: option.text[language],
        index: startOptionIndex + index,
      }));
      startOptionIndex += options.length;

      return (
        <div className="reasons-print-list" key={groupedReasons.order}>
          <span className="reason-list-title">{label}</span>
          <div className="reasons-print-options">
            {options.map((option) => getReasonRow(option.label, option.index))}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <tr>
        <td>
          <div className="reasons-print-container">
            <span className="reasons-print-title">{t('MY_OPPORTUNITIES.ACTION_TYPES.AV')}</span>
            <div className="reasons-print-group">{getReasons(reasons.AV)}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div className="reasons-print-container">
            <span className="reasons-print-title">{t('MY_OPPORTUNITIES.ACTION_TYPES.IA')}</span>
            <div className="reasons-print-group">{getReasons(reasons.IA)}</div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default Reasons;
