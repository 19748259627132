import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCurrentDate, toLanguageNumberFormat, toShortCurrencyFormat } from 'utils';
import {
  DrillDownTypes,
  IDate,
  IHighLevelKpisWrapper,
  IRootState,
  ISearchParamsFilters,
  ISelectedCategory,
  SummaryCardIds,
  SummaryCardTypes,
  TrendsTabOptions,
} from 'types';
import SummaryCard from 'components/summary/summaryCard/SummaryCard';
import 'components/pages/summary/summary.scss';
import { getIssueFoundByReasons, getSalesIncrease } from 'store/summary/summaryActions';
import RouterService from 'services/router/routerService';
import LoadingSelector from 'store/loading/LoadingSelector';
import { DecorativeFooter } from 'components/decorativeFooter';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';
import { RoutePath } from 'routes/types';
import { getStoresCategory } from 'store/filters/filterHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';
import permissionsService from 'services/permissions/permissionsSrv';

const HighLevelKpisWrapper: FC<IHighLevelKpisWrapper> = ({
  kpiDateFilter,
  totalOpportunitiesCaptured,
  totalOpportunitiesCapturedChainWide,
  salesIncrease,
  salesIncreaseChainWide,
  currency,
  getIssueFoundByReasons,
  getSalesIncrease,
  selectedStoreFilter,
  isGetMyIssueFoundByReasonsLoading,
  isGetMySalesIncreaseLoading,
  trendsSelectedCategory,
  findingsSelectedCategory,
}) => {
  const [t] = useTranslation();

  const handleRouteWithParams = (path: RoutePath) => {
    // build date
    const date: IDate = {
      type: kpiDateFilter,
      from: getCurrentDate(),
      to: getCurrentDate(),
    };

    // selected category
    let category: ISelectedCategory | undefined;
    if (selectedStoreFilter) {
      category = getStoresCategory();
    } else {
      category = path === RoutePath.Trends ? trendsSelectedCategory : findingsSelectedCategory;
    }

    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date,
      tab: path === RoutePath.Trends ? TrendsTabOptions.Earning : undefined,
      [DrillDownTypes.Other]: {
        category,
        filter: {
          value: selectedStoreFilter?.id || '',
          name: selectedStoreFilter?.storeName || '',
          hierarchyName: '',
        },
      },
    };

    const queryParams = buildQueryParamsFromSearchFilters(searchFilters);

    if (path === RoutePath.Trends) {
      AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_TRENDS, {
        tab_name: 'Sales increase',
      });
      RouterService.routeToTrends(queryParams);
    } else {
      AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_FINDINGS);
      RouterService.routeToFindings(queryParams);
    }
  };

  useEffect(() => {
    getIssueFoundByReasons();
    getSalesIncrease();
  }, [kpiDateFilter, selectedStoreFilter]);

  const mayViewSalesIncrease = permissionsService.mayViewEarnings();

  return (
    <>
      {mayViewSalesIncrease && (
        <SummaryCard
          cardId={SummaryCardIds.SALES_INCREASE}
          cardType={SummaryCardTypes.KPI}
          headerText={t('SUMMARY.SALES_INCREASE')}
          mainText={`${toShortCurrencyFormat(salesIncrease, currency, 2)}`}
          footerInfoText={t('SUMMARY.CHAIN_WIDE', {
            chainWide: `${toShortCurrencyFormat(salesIncreaseChainWide, currency, 2)}`,
          })}
          onSeeDetailsClick={() => handleRouteWithParams(RoutePath.Trends)}
          isLoading={isGetMySalesIncreaseLoading}
        />
      )}

      <SummaryCard
        cardId={SummaryCardIds.TOTAL_OPPORTUNITIES_CAPTURED}
        cardType={SummaryCardTypes.KPI}
        headerText={t('SUMMARY.TOTAL_OPPORTUNITIES_CAPTURED')}
        mainText={`${toLanguageNumberFormat(totalOpportunitiesCaptured)}`}
        footerInfoText={t('SUMMARY.CHAIN_WIDE', {
          chainWide: toLanguageNumberFormat(totalOpportunitiesCapturedChainWide),
        })}
        onSeeDetailsClick={() => handleRouteWithParams(RoutePath.Findings)}
        isLoading={isGetMyIssueFoundByReasonsLoading}
      />

      <DecorativeFooter
        title={t('SUMMARY.DECORATIVE_FOOTER_TITLE')}
        text={t('SUMMARY.DECORATIVE_FOOTER_TEXT')}
        isSmall={mayViewSalesIncrease}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    totalOpportunitiesCaptured,
    totalOpportunitiesCapturedChainWide,
    salesIncrease,
    salesIncreaseChainWide,
    selectedStoreFilter,
  } = state.summary;

  const { selectedCategory: trendsSelectedCategory } = state.trends.filters[
    DrillDownTypes.Other
  ].activeFilters;

  const { selectedCategory: findingsSelectedCategory } = state.findings.filters[
    DrillDownTypes.Other
  ].activeFilters;

  const { kpiDateFilter } = state.summary.activeFilters;

  const { currency } = state.settings.general;

  const isGetMyIssueFoundByReasonsLoading = LoadingSelector.isGetMyIssueFoundByReasons(state);

  const isGetMySalesIncreaseLoading = LoadingSelector.isGetMySalesIncrease(state);

  return {
    kpiDateFilter,
    totalOpportunitiesCaptured,
    totalOpportunitiesCapturedChainWide,
    salesIncrease,
    salesIncreaseChainWide,
    currency,
    isGetMyIssueFoundByReasonsLoading,
    isGetMySalesIncreaseLoading,
    selectedStoreFilter,
    trendsSelectedCategory,
    findingsSelectedCategory,
  };
};

const mapDispatchToProps = {
  getIssueFoundByReasons,
  getSalesIncrease,
};

export default connect(mapStateToProps, mapDispatchToProps)(HighLevelKpisWrapper);
