import { FC } from 'react';
import cn from 'clsx';

import './decorativeFooter.scss';

import { SideBarIllustratorIcon } from 'components/Icons';

interface IDecorativeFooter {
  title: string;
  text: string;
  isSmall: boolean;
}

export const DecorativeFooter: FC<IDecorativeFooter> = ({ text, title, isSmall }) => {
  return (
    <div data-testid="decorative-footer" className={cn('decorative-footer', { small: isSmall })}>
      <div className="text-container">
        <div className="title">{title}</div>
        <div className="text">{text}</div>
      </div>
      <SideBarIllustratorIcon />
    </div>
  );
};
