import { IPopupModal } from './types';
import PopupActionTypes from './popupActionTypes';

export const show = ({ id, selectedOpportunityId }: IPopupModal) => ({
  type: PopupActionTypes.Show,
  payload: {
    id,
    selectedOpportunityId,
  },
});

export const hide = () => ({
  type: PopupActionTypes.Hide,
});

const PopupActions = {
  show,
  hide,
};

export default PopupActions;
