import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import SummaryActionTypes from './summaryActionTypes';
import {
  IHierarchyPath,
  DrillDownTypes,
  ISelectedCategory,
  LeaderBoardCompareFactor,
  ISort,
  ReasonsGroups,
  SelectedDateIntervals,
  Store,
} from 'types';
import {
  buildGetReasonsRequestFilterBody,
  buildOpenOpportunitiesFilter,
} from 'store/summary/summaryHelper';

export const getOpenOpportunities = (storeFilterName?: string) => {
  const filter = buildOpenOpportunitiesFilter(storeFilterName);

  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/TaskStateHistogramQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        filter,
      },
    },
    baseAction: SummaryActionTypes.getOpenOpportunities,
  };
};

export const getMyIssueFoundByReasons = (filterBody: object) => ({
  type: APIConstants.API_REQUEST,
  url: `/v1/report/issue/reasons/my`,
  meta: {
    method: HttpMethod.POST,
    body: {
      ...filterBody,
    },
  },

  baseAction: SummaryActionTypes.getMyIssueFoundByReasons,
});

export const getAllIssueFoundByReasons = (filterBody: object) => ({
  type: APIConstants.API_REQUEST,
  url: `/v1/report/issue/reasons`,
  meta: {
    method: HttpMethod.POST,
    body: {
      ...filterBody,
    },
  },

  baseAction: SummaryActionTypes.getAllIssueFoundByReasons,
});

export const getIssueFoundByReasons = () => ({
  type: SummaryActionTypes.getIssueFoundByReasons,
});

export const getMySalesIncrease = (filterBody: object) => ({
  type: APIConstants.API_REQUEST,
  url: `/v1/report/sales/count/my`,
  meta: {
    method: HttpMethod.POST,
    body: {
      ...filterBody,
    },
  },

  baseAction: SummaryActionTypes.getMySalesIncrease,
});

export const getAllSalesIncrease = (filterBody: object) => ({
  type: APIConstants.API_REQUEST,
  url: `/v1/report/sales/count`,
  meta: {
    method: HttpMethod.POST,
    body: {
      ...filterBody,
    },
  },

  baseAction: SummaryActionTypes.getAllSalesIncrease,
});

export const getSalesIncrease = () => ({
  type: SummaryActionTypes.getSalesIncrease,
});

export const getResponseRateComparison = (diffInDays: number, filterBody: object) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/summary/response/rate/comparison/${diffInDays}`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filterBody },
    },
    baseAction: SummaryActionTypes.getResponseRateComparison,
  };
};

export const getHitRateComparison = (diffInDays: number, filterBody: any) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/summary/hit/rate/comparison/${diffInDays}`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filterBody },
    },
    baseAction: SummaryActionTypes.getHitRateComparison,
  };
};

export const getRateComparison = () => ({
  type: SummaryActionTypes.getRateComparison,
});

export const getFindingsReasons = (
  topFindingsDateFilter: SelectedDateIntervals,
  reasons: ReasonsGroups,
  selectedStoreNameFilter?: string,
) => {
  const filter = buildGetReasonsRequestFilterBody(topFindingsDateFilter, selectedStoreNameFilter);

  return {
    type: APIConstants.API_REQUEST,
    payload: { reasons },
    url: `/v1/report/IssueFoundResponsesByReasonsTypes`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: SummaryActionTypes.getFindingsReasons,
  };
};

export const toggleGroupSelection = (groupId: number, isActive: boolean) => ({
  type: SummaryActionTypes.toggleGroupSelection,
  payload: { groupId, isActive },
});

export const getHierarchyDrillDownData = (
  filter: object,
  drillDownType: DrillDownTypes,
  endpoint: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType },
    baseAction: SummaryActionTypes.getHierarchyDrillDownData,
  };
};

export const setStoreFilter = (store: Store) => {
  return {
    type: SummaryActionTypes.setStoreFilter,
    payload: { store },
  };
};

export const getDrillDown = (
  drillDownType: DrillDownTypes,
  selectedCategory?: ISelectedCategory,
) => ({
  type: SummaryActionTypes.getDrillDown,
  payload: {
    drillDownType,
    selectedCategory,
  },
});

export const setKpiDateFilter = (selectedKpiDateFilter: SelectedDateIntervals) => {
  return {
    type: SummaryActionTypes.setKpiDateFilter,
    payload: { selectedKpiDateFilter },
  };
};

export const setTopFindingsDateFilter = (selectedTopFindingsDateFilter: SelectedDateIntervals) => {
  return {
    type: SummaryActionTypes.setTopFindingsDateFilter,
    payload: { selectedTopFindingsDateFilter },
  };
};

export const setLeaderBoardDateFilter = (selectedLeaderBoardDateFilter: SelectedDateIntervals) => {
  return {
    type: SummaryActionTypes.setLeaderBoardDateFilter,
    payload: { selectedLeaderBoardDateFilter },
  };
};

export const setActiveComparisonTab = (selectedRateComparisonTab: number) => {
  return {
    type: SummaryActionTypes.setActiveComparisonTab,
    payload: { selectedRateComparisonTab },
  };
};

export const getProducts = (filter: object, drillDownType: DrillDownTypes) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Product`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType },
    baseAction: SummaryActionTypes.getProducts,
  };
};

export const getStores = (filter: object, drillDownType: DrillDownTypes) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Store`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType },
    baseAction: SummaryActionTypes.getStores,
  };
};

export const getLeaderBoardPrepare = () => {
  return {
    type: SummaryActionTypes.getLeaderBoardPrepare,
  };
};

export const setLeaderBoardPeersSelected = (isPeersSelected: boolean) => {
  return {
    type: SummaryActionTypes.setLeaderBoardPeersSelected,
    payload: { isPeersSelected },
  };
};

export const getLeaderBoardData = (
  filter: any,
  orders: Array<ISort>,
  compareFactor: LeaderBoardCompareFactor,
  userHighestHierarchyLowestLevel: IHierarchyPath,
  hierarchyLevel: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: { userHighestHierarchyLowestLevel },
    url: `/v1/report/HitRate/Comparison/${compareFactor}/hierarchyLevel/${hierarchyLevel}`,
    meta: {
      method: HttpMethod.POST,
      body: { filter, orders },
    },
    baseAction: SummaryActionTypes.getLeaderBoardData,
  };
};

export const getRateHistograms = () => ({
  type: SummaryActionTypes.getRateHistograms,
});

export const getHitRateHistogram = (filterBody: object) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/My/HistogramQuery/MONTH`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filterBody,
      },
    },
    baseAction: SummaryActionTypes.getHitRateHistogram,
  };
};

export const getHitRateChainWideHistogram = (filterBody: object, compareScope: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate${compareScope}/HistogramQuery/MONTH`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filterBody,
      },
    },
    baseAction: SummaryActionTypes.getHitRateChainWideHistogram,
  };
};

export const getResponseRateHistogram = (filterBody: object) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate/My/HistogramQuery/MONTH`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filterBody,
      },
    },
    baseAction: SummaryActionTypes.getResponseRateHistogram,
  };
};

export const getResponseRateChainWideHistogram = (filterBody: object, compareScope: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate${compareScope}/HistogramQuery/MONTH`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filterBody,
      },
    },
    baseAction: SummaryActionTypes.getResponseRateChainWideHistogram,
  };
};
