import { cloneDeep } from 'lodash';

import { FilterBuilder } from 'store/filters/filterBuilder';
import {
  buildStatsFilter,
  getLabelBaseFilter,
  getResponseImpactFilter,
} from 'store/filters/filterHelper';
import {
  getAllFilterLabels,
  getMyFilterStats,
  getPlanogramFilterLabels,
  getProductFilterLabels,
  getStoreFilterLabels,
  getStoreNameFilterLabels,
  prepareMyFilterStats,
} from 'store/filters/filtersActions';
import FiltersActionTypes from 'store/filters/filtersActionTypes';
import { categoryName, IActionType, IFilterCategory } from 'types';
import permissionsService from 'services/permissions/permissionsSrv';

const filterMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: IActionType) => {
  const { type, payload } = action;

  switch (type) {
    case FiltersActionTypes.prepareMyFilterStats: {
      const {
        productCategories,
        productCategoriesLabels,
        storeCategories,
        storeCategoriesLabels,
        storeNamesCategories,
        storeNamesLabels,
        planogramAttributes,
        planogramAttributesLabels,
      } = getState().filters.myOpportunities.filters;

      const { activeTab } = payload;

      const categoriesMap = {
        productCategoriesLabels: cloneDeep(productCategoriesLabels),
        storeCategoriesLabels: cloneDeep(storeCategoriesLabels),
        storeNamesLabels: cloneDeep(storeNamesLabels),
        planogramAttributesLabels: cloneDeep(planogramAttributesLabels),
      };

      const { showResponseImpactOnSales } = getState().settings.featureFlags;

      const filterOutput = buildStatsFilter(
        productCategories,
        storeCategories,
        storeNamesCategories,
        planogramAttributes,
        showResponseImpactOnSales,
        activeTab,
        categoriesMap,
      );

      dispatch(getMyFilterStats(filterOutput, activeTab));
      break;
    }

    case FiltersActionTypes.getAllFilterLabels: {
      const {
        productCategories,
        storeCategories,
        planogramAttributes,
      } = getState().filters.myOpportunities.filters;

      const { activeTab } = payload;

      const { showResponseImpactOnSales } = getState().settings.featureFlags;

      const prodLabelFilter = getLabelBaseFilter(
        categoryName.PRODUCT_CATEGORY,
        activeTab,
        showResponseImpactOnSales,
      );

      productCategories.forEach((category: IFilterCategory) => {
        dispatch(getProductFilterLabels(category.title, category.level, prodLabelFilter));
      });

      // if not single store manager get store categories and stores
      if (!permissionsService.isSingleStoreManager()) {
        const storeLabelFilter = getLabelBaseFilter(
          categoryName.STORE_CATEGORY,
          activeTab,
          showResponseImpactOnSales,
        );

        storeCategories.forEach((category: IFilterCategory) => {
          dispatch(getStoreFilterLabels(category.title, category.level, storeLabelFilter));
        });

        const storeNameLabelFilter = getLabelBaseFilter(
          categoryName.STORE_NAME,
          activeTab,
          showResponseImpactOnSales,
        );

        dispatch(getStoreNameFilterLabels('storeName', 0, storeNameLabelFilter));
      }

      const planogramLabelFilter = getLabelBaseFilter(
        categoryName.PLANOGRAM_ATTRIBUTES,
        activeTab,
        showResponseImpactOnSales,
      );

      planogramAttributes.forEach((category: IFilterCategory) => {
        dispatch(getPlanogramFilterLabels(category.title, category.level, planogramLabelFilter));
      });

      break;
    }

    case FiltersActionTypes.ToggleControlMenu: {
      if (!getState().filters.isControlMenuExpanded) {
        const { activeTab } = getState().filters.myOpportunities.filters;

        dispatch(getAllFilterLabels(activeTab));
        dispatch(prepareMyFilterStats(activeTab));
      }
      break;
    }
    case FiltersActionTypes.setActiveTabFilter: {
      const { activeTab } = payload;

      dispatch(getAllFilterLabels(activeTab));
      dispatch(prepareMyFilterStats(activeTab));

      break;
    }

    case FiltersActionTypes.clearFilters: {
      const filterBuilder = new FilterBuilder();

      const { showResponseImpactOnSales } = getState().settings.featureFlags;

      const { activeTab } = getState().filters.myOpportunities.filters;

      getResponseImpactFilter(filterBuilder, showResponseImpactOnSales, activeTab);

      const filterOutput = filterBuilder.build();

      dispatch(getMyFilterStats(filterOutput, activeTab));
      break;
    }

    // When filter is checked we reflect the slicing
    // this is a relatively costly operation build filter + trigger request
    case FiltersActionTypes.setFilterSelection: {
      const { label, selection, categoryLabelsMapper, index } = payload;

      const {
        productCategories,
        productCategoriesLabels,
        storeCategories,
        storeCategoriesLabels,
        storeNamesCategories,
        storeNamesLabels,
        planogramAttributes,
        planogramAttributesLabels,
        activeTab,
      } = getState().filters.myOpportunities.filters;

      const categoriesMap = {
        productCategoriesLabels: cloneDeep(productCategoriesLabels),
        storeCategoriesLabels: cloneDeep(storeCategoriesLabels),
        storeNamesLabels: cloneDeep(storeNamesLabels),
        planogramAttributesLabels: cloneDeep(planogramAttributesLabels),
      };

      const { showResponseImpactOnSales } = getState().settings.featureFlags;

      // on clearFilters case we don't need to modify the last label
      if (label) {
        let modifiedCategory = categoriesMap[categoryLabelsMapper][index].selectedLabels;

        if (selection) {
          modifiedCategory.push(label);
        } else {
          modifiedCategory = modifiedCategory.filter((currLabel: string) => {
            return currLabel !== label;
          });
          categoriesMap[categoryLabelsMapper][index].selectedLabels = modifiedCategory;
        }
      }

      const filterOutput = buildStatsFilter(
        productCategories,
        storeCategories,
        storeNamesCategories,
        planogramAttributes,
        showResponseImpactOnSales,
        activeTab,
        categoriesMap,
      );

      dispatch(getMyFilterStats(filterOutput, activeTab));

      break;
    }
    default:
      break;
  }

  next(action);
};

export default filterMiddleware;
