import { FC } from 'react';

import { IReasonFilterGroup, ValueType } from '../types';
import { getPercent } from 'utils';
import ReasonFilterGroup from './reasonFilterGroup/ReasonFilterGroup';

export interface IReasonsFilterGroups {
  reasonsGroups: Array<IReasonFilterGroup> | null;
  valueType: ValueType;
  issueFoundTotalCount: number;
  toggleReasonFilter(reasonId: number, groupId: number): void;
}

const ReasonsFilterGroups: FC<IReasonsFilterGroups> = ({
  toggleReasonFilter,
  reasonsGroups,
  issueFoundTotalCount,
  valueType,
}) => {
  const BuildReasonsFilterGroups = () => {
    if (!reasonsGroups) {
      return null;
    }

    return reasonsGroups.map((reasonFilterGroup) => {
      const { reasonFilter, color, id: reasonGroupId } = reasonFilterGroup;

      const list = reasonFilter.map((reasonFilterItem) => {
        const { label, id, value, isActive } = reasonFilterItem;

        // Prevent 0%
        const percent = Math.max(getPercent(value, issueFoundTotalCount), 1);

        // change count according to the valueType
        const count = valueType === ValueType.Number ? value : percent;

        return {
          label,
          id,
          isActive,
          value: count,
        };
      });

      return {
        color,
        list,
        id: reasonGroupId,
      };
    });
  };

  const reasonsFilterGroups = BuildReasonsFilterGroups();

  return (
    <div className="reasons-groups">
      {reasonsFilterGroups?.map((reasonGroup) => (
        <ReasonFilterGroup
          key={reasonGroup.id}
          groupId={reasonGroup.id}
          list={reasonGroup.list}
          color={reasonGroup.color}
          type={valueType}
          onReasonClick={toggleReasonFilter}
        />
      ))}
    </div>
  );
};

export default ReasonsFilterGroups;
