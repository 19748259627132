import { FC, useState } from 'react';
import { DatePicker as DatePicketAnt } from 'antd';

import { toMoment } from 'utils';

const { RangePicker } = DatePicketAnt;

interface IDatePicker {
  selectedDates?: { from: number; to: number };
  onChange(from: number, to: number): void;
}

const DatePicker: FC<IDatePicker> = ({ selectedDates, onChange }) => {
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  const startDate = toMoment(selectedDates?.from);

  const endDate = toMoment(selectedDates?.to);

  const onDateChange = (dates: any) => {
    if (dates.length === 2) {
      const from = dates[0]?.toDate()?.getTime();

      const to = dates[1]?.toDate()?.getTime();
      onChange(from, to);
    }
  };

  const value: any = isDateRangeOpen ? [] : [startDate, endDate];

  return (
    <RangePicker
      onOpenChange={setIsDateRangeOpen}
      value={value}
      clearIcon={false}
      className="date-picker"
      onChange={onDateChange}
    />
  );
};

export default DatePicker;
