import { set, flow } from 'lodash/fp';

import { RoutePath } from 'routes/types';
import SideMenuActionTypes from 'store/sideMenu/sideMenuActionTypes';
import { ISideMenuModel } from 'store/sideMenu/types';
import { IActionType } from 'types';

export const initialState: ISideMenuModel = {
  selectedActionMenuId: RoutePath.Home,
  isMenuCollapsed: false,
  isMenuHidden: false,
};

const sideMenuReducer = (state: ISideMenuModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case SideMenuActionTypes.SelectActionId: {
      const { selectedActionMenuId } = payload;

      return flow([set('selectedActionMenuId', selectedActionMenuId)])(state);
    }

    case SideMenuActionTypes.ToggleMenuCollapseExpand: {
      return flow([set('isMenuCollapsed', !state.isMenuCollapsed)])(state);
    }

    case SideMenuActionTypes.ToggleMenuHideExpand: {
      return flow([set('isMenuHidden', !state.isMenuHidden)])(state);
    }

    case SideMenuActionTypes.CollapseSideMenu: {
      return flow([set('isMenuCollapsed', true)])(state);
    }

    case SideMenuActionTypes.HideSideMenu: {
      return flow([set('isMenuHidden', true)])(state);
    }

    default:
      return state;
  }
};

export default sideMenuReducer;
