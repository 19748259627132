import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyState } from 'components/emptyState';
import { NoResultIcon } from 'components/Icons';

interface IDrillDownEmptyState {
  isFiltersApplied: boolean;
  searchText: string;
}

const DrillDownEmptyState: FC<IDrillDownEmptyState> = ({ isFiltersApplied, searchText }) => {
  const [t] = useTranslation();

  if (searchText) {
    return (
      <EmptyState
        title={t('DRILL_DOWNS.EMPTY_STATES.NO_RESULT_FOUND_SEARCH', { search: searchText })}
        icon={NoResultIcon}
      />
    );
  }

  if (isFiltersApplied) {
    return (
      <EmptyState
        title={t('FINDINGS.EMPTY_STATE.EMPTY_BY_FILTERS_TITLE')}
        icon={NoResultIcon}
        text={t('FINDINGS.EMPTY_STATE.EMPTY_BY_FILTERS_TEXT')}
      />
    );
  }

  return (
    <EmptyState
      title={t('FINDINGS.EMPTY_STATE.EMPTY_TITLE')}
      icon={NoResultIcon}
      text={t('FINDINGS.EMPTY_STATE.EMPTY_TEXT')}
    />
  );
};

export default DrillDownEmptyState;
