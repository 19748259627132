import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ClickableBox from 'components/trends/clickableBox/ClickableBox';
import { IClickableBoxWrapper, IRootState, TrendsTabOptions } from 'types';
import { toFixed, toShortCurrencyFormat } from 'utils';
import { setSelectedTab } from 'store/trends/trendsActions';
import LoadingSelector from 'store/loading/LoadingSelector';
import permissionsService from 'services/permissions/permissionsSrv';

const ClickableBoxWrapper: FC<IClickableBoxWrapper> = ({
  currency,
  selectedTab,
  totalHitRate,
  totalResponseRate,
  totalSalesIncreaseMyStores,
  totalSalesIncreaseChainWide,
  setSelectedTab,
  shouldDisplaySalesChainWide,
  isHitRateLoading,
  isResponseRateLoading,
  isSalesIncreaseLoading,
}) => {
  const [t] = useTranslation();

  const chainWideSalesIncrease = t('TRENDS.SALES_INCREASE_INFO', {
    value: toShortCurrencyFormat(totalSalesIncreaseChainWide, currency, 1),
  });

  const chainWideSalesIncreaseText = shouldDisplaySalesChainWide ? chainWideSalesIncrease : '';

  const mayViewSalesIncrease = permissionsService.mayViewEarnings();

  return (
    <div className="boxes">
      {mayViewSalesIncrease && (
        <ClickableBox
          id="trends-sales-increase"
          isLoading={isSalesIncreaseLoading}
          type={TrendsTabOptions.Earning}
          title={t('TRENDS.SALES_INCREASE')}
          text={chainWideSalesIncreaseText}
          value={toShortCurrencyFormat(totalSalesIncreaseMyStores, currency, 1)}
          isActive={selectedTab === TrendsTabOptions.Earning}
          onClick={setSelectedTab}
        />
      )}
      <ClickableBox
        id="trends-response-rate"
        isLoading={isResponseRateLoading}
        type={TrendsTabOptions.ResponseRate}
        title={t('COMMON.RESPONSE_RATE')}
        text={t('COMMON.AVG')}
        value={`${toFixed(totalResponseRate)}%`}
        isActive={selectedTab === TrendsTabOptions.ResponseRate}
        onClick={setSelectedTab}
      />
      <ClickableBox
        id="trends-hit-rate"
        isLoading={isHitRateLoading}
        type={TrendsTabOptions.HitRate}
        title={t('COMMON.HIT_RATE')}
        text={t('COMMON.AVG')}
        value={`${toFixed(totalHitRate)}%`}
        isActive={selectedTab === TrendsTabOptions.HitRate}
        onClick={setSelectedTab}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    general: { currency },
  } = state.settings;

  const { isAllUser } = state.userProfile;

  const shouldDisplaySalesChainWide = !isAllUser;

  const {
    filters: { selectedTab },
    hitRate: { total: totalHitRate },
    responseRate: { total: totalResponseRate },
    salesIncrease: {
      totalValueMyStores: totalSalesIncreaseMyStores,
      totalValueChainWide: totalSalesIncreaseChainWide,
    },
  } = state.trends;

  const isSalesIncreaseLoading = LoadingSelector.isSalesIncreaseLoading(state);

  const isHitRateLoading = LoadingSelector.isHitRateLoading(state);

  const isResponseRateLoading = LoadingSelector.isResponseRateLoading(state);

  return {
    currency,
    selectedTab,
    totalResponseRate,
    totalSalesIncreaseMyStores,
    totalSalesIncreaseChainWide,
    totalHitRate,
    shouldDisplaySalesChainWide,
    isSalesIncreaseLoading,
    isHitRateLoading,
    isResponseRateLoading,
  };
};

const mapDispatchToProps = {
  setSelectedTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClickableBoxWrapper);
