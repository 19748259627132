import { FC } from 'react';

import { animationType, ILeaderBoard, ILeaderBoardRow } from 'types';
import './leaderBoard.scss';
import LeaderBoardRow from './LeaderBoardRow';
import { ThreeDots } from 'components/Icons';
import { ProgressAnimation } from 'components/animation';
import LeaderBoardEmptyState from './LeaderBoardEmptyState';
import { isEmptyArray } from 'utils';

const LeaderBoard: FC<ILeaderBoard> = ({
  leaderBoardTopData,
  leaderBoardBottomData,
  emptyStateLabel = '',
  isLoading = true,
}) => {
  if (!isLoading && isEmptyArray(leaderBoardTopData)) {
    return <LeaderBoardEmptyState label={emptyStateLabel} />;
  }

  return (
    <div className="leader-board-container">
      <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
      <div className="leader-board-items-container">
        {leaderBoardTopData.map(
          ({ rank, name, hitRate, issueFoundCount, isSelected }: ILeaderBoardRow) => (
            <LeaderBoardRow
              key={`${name}_${Math.random()}`}
              rank={rank}
              name={name}
              hitRate={hitRate}
              issueFoundCount={issueFoundCount}
              isSelected={isSelected}
            />
          ),
        )}
      </div>
      {leaderBoardBottomData.length !== 0 && (
        <>
          <div className="three-dots-container">
            <ThreeDots />
          </div>

          <div className="leader-board-items-container">
            {leaderBoardBottomData.map(
              ({ rank, name, hitRate, issueFoundCount, isSelected }: ILeaderBoardRow) => (
                <LeaderBoardRow
                  key={`${name}_${Math.random()}`}
                  rank={rank}
                  name={name}
                  hitRate={hitRate}
                  issueFoundCount={issueFoundCount}
                  isSelected={isSelected}
                />
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LeaderBoard;
