export enum PopupIds {
  FindingsOpportunity = 'findingsOpportunity',
  OpenOpportunitiesOpportunity = 'OpenOpportunitiesOpportunity',
  OpportunityProductImage = 'opportunityProductImage',
  OpportunityLiveInventory = 'OpportunityLiveInventory',
}

export type IPopupModal = {
  id: PopupIds | null;
  selectedOpportunityId: string | null;
};
