import {
  deepLinkOpportunityId,
  emptyStateAnimationType,
  IMyOpportunities,
  IMyOpportunity,
  ReasonState,
} from 'types';

export type IMyOpportunitiesModel = {
  opportunities: IMyOpportunities | null;
  selectedMyOpportunity: IMyOpportunity | null;
  hasMore: boolean;
  totalCount: number;
  totalCountOpenTab: number;
  nextPageOffset: number;
  activeTab: OpportunitiesTabsOptions;
  opportunitiesGroupContent: Array<string>;
  opportunitiesGroupCount: Array<number>;
  productImagesMetadata: Array<string>;
  deepLinkOpportunityId: deepLinkOpportunityId;
  liveInventory: IInventoryRawData | null;
  motivationAnimationData: IAnimationData | null;
  animatedEmptyState: boolean;
  isLastReasonSubmitted: boolean;
};

export interface IAnimationData {
  hitRateScore: IHitRateScore;
  animationType: emptyStateAnimationType;
}

export interface ISetReasonRequestBody {
  state: ReasonState;
  alias: string;
  reasonId: number;
  responseAlias: string;
  remark?: string;
  uniqueTaskId: string;
  userGuid: string;
  updateDate: number;
  version: string;
}

export interface IInventoryHeadline {
  name: string;
  values: string[];
}

export interface IInventoryTableItems {
  [key: string]: string;
}

export interface IInventoryRawData {
  headers: IInventoryTableItems[];
  stockData: IInventoryTableItems[];
  headlines: IInventoryHeadline[];
}

export const SIZE = 50;

export const DEEP_LINK_NOT_AVAILABLE = 'removeAnyLink';

export enum OpportunitiesTabsOptions {
  Open = 1,
  Completed = 2,
}

export const OpportunitiesTabs = [
  { id: OpportunitiesTabsOptions.Open, label: 'MY_OPPORTUNITIES.OPEN' },
  { id: OpportunitiesTabsOptions.Completed, label: 'MY_OPPORTUNITIES.COMPLETED' },
];

export interface IHitRateScore {
  score: number;
  totalIssueCount: number;
  issueFoundCount: number;
  curHitRate: number;
  deltaHitRateAndAvgChainHitRate: number;
  avgChainHitRate: number;
}

export const hitRateScoreRequiredFields = [
  'totalIssueCount',
  'issueFoundCount',
  'curHitRate',
  'avgChainHitRate',
];

export const emptyStateAnimationDecisionValues = {
  MIN_ISSUE_RESPONDED_THRESHOLD: 3,
  MAX_HIT_RATE: 99.99,
  FIFTY_PERCENT_HIT_RATE: 50.0,
  TWENTY_PERCENT_HIT_RATE: 20.0,
};

export const IMPACT_SALES_WEEKS_TO_SHOW_POST_DEPLOY = 5;
