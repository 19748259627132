import cn from 'clsx';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { httpResponseStatus } from 'store/api/APIConstants';
import { resetServerResponseStatus, submitContactUs } from 'store/userProfile/userProfileActions';
import { IContactUsForm, IRootState } from 'types';
import { InputField, TextArea } from 'components/inputs';
import packagejson from '../../../package.json';
import { getSearchParams } from 'utils/NavigationUtils';

const ContactUsForm: FC<IContactUsForm> = ({
  submitContactUs,
  email,
  role,
  serverResponseStatus,
  resetServerResponseStatus,
}) => {
  const [subject, setSubject] = useState('');

  const [description, setDescription] = useState('');

  const location = useLocation();

  const [t] = useTranslation();

  useEffect(() => {
    // get subject field from search params if exists
    const searchParams = getSearchParams(location.search);

    const subject = searchParams.get('subject');

    if (subject) {
      setSubject(subject);
    }

    return () => resetServerResponseStatus();
  }, []);

  const isSubmitDisabled =
    subject.length === 0 ||
    description.length === 0 ||
    serverResponseStatus === httpResponseStatus.Failure;

  const onSubmitClick = () => {
    submitContactUs({
      subject,
      description,
      email,
      '*reply': 'email',
      '*redirect': window.location.href,
      isMobileApp: false,
      appVersion: packagejson.version,
      site: window.location.origin,
      role,
    });
  };

  const messageText =
    serverResponseStatus === httpResponseStatus.Failure
      ? t('CONTACT_US.SUBMISSION_FAILED')
      : t('CONTACT_US.SENT_SUCCESSFULLY');

  return (
    <div className="contact-us-form">
      <InputField
        id="contact-us-subject"
        focus
        onChange={setSubject}
        value={subject}
        className="contact-us-input-container"
        placeholder={t('CONTACT_US.ENTER_SUBJECT')}
        title={t('CONTACT_US.SUBJECT')}
      />
      <TextArea
        id="contact-us-description"
        onChange={setDescription}
        value={description}
        className="contact-us-input-container"
        placeholder={t('CONTACT_US.ENTER_DESCRIPTION')}
        title={t('CONTACT_US.DESCRIPTION')}
      />
      <button
        id="submit-contact-us-form"
        className="submit-contact-us-form"
        onClick={onSubmitClick}
        disabled={isSubmitDisabled}
      >
        {t('COMMON.SEND')}
      </button>
      <div className="contact-us-message-container">
        {serverResponseStatus !== null && (
          <div
            id="contact-us-message"
            className={cn(
              'contact-us-message',
              serverResponseStatus === httpResponseStatus.Failure && 'error',
            )}
          >
            {messageText}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { email, role, serverResponseStatus } = state.userProfile;

  return {
    email,
    role,
    serverResponseStatus,
  };
};

const mapDispatchToProps = {
  submitContactUs,
  resetServerResponseStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm);
