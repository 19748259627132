import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import OpenOpportunitiesActionTypes from './openOpportunitiesActionTypes';
import {
  IOpenOpportunitiesDrillDownCard,
  OpenOpportunitiesCardTypes,
  OpenOpportunitiesDropDownOptionsTypes,
} from 'types';
import { buildOpenOpportunitiesFilter } from './openOpportunitiesHelper';

export const getOpenOpportunities = () => {
  const filter = buildOpenOpportunitiesFilter();

  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/TaskStateHistogramQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        filter,
      },
    },
    baseAction: OpenOpportunitiesActionTypes.getOpenOpportunities,
  };
};

export const getOpportunities = (filter: any, productImagesMetadata: Array<string>) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/TaskRawDataQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { page: filter.page, productImagesMetadata },
    baseAction: OpenOpportunitiesActionTypes.getOpportunities,
  };
};

export const getStoreBreakdown = (weeks: [number, number]) => {
  const filter = buildOpenOpportunitiesFilter(weeks);

  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponsesByStoreProgressQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        filter,
      },
    },
    baseAction: OpenOpportunitiesActionTypes.getStoreBreakdown,
  };
};

export const getFilteredDrillDownItems = (filter: any, endPoint: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/${endPoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { page: filter.page },
    baseAction: OpenOpportunitiesActionTypes.getFilteredDrillDownItems,
  };
};

export const getFindingsReasons = (filter: Object) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/IssueFoundResponsesByReasonsTypes`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: OpenOpportunitiesActionTypes.getFindingsReasons,
  };
};

export const getDrillDownItems = (
  cardType: OpenOpportunitiesCardTypes,
  pageOffset: number = 0,
) => ({
  type: OpenOpportunitiesActionTypes.getDrillDownItems,
  payload: { pageOffset, cardType },
});

export const selectCard = (cardType: OpenOpportunitiesCardTypes) => ({
  type: OpenOpportunitiesActionTypes.selectCard,
  payload: { cardType },
});

export const changeDateRange = (selectedWeeks: [number, number]) => ({
  type: OpenOpportunitiesActionTypes.changeDateRange,
  payload: { selectedWeeks },
});

export const selectTab = (id: number) => ({
  type: OpenOpportunitiesActionTypes.selectTab,
  payload: { id },
});

export const selectDropDownOption = (option: OpenOpportunitiesDropDownOptionsTypes) => ({
  type: OpenOpportunitiesActionTypes.selectDropDownOption,
  payload: { option },
});

export const updateSearchFilter = (searchValue: string) => ({
  type: OpenOpportunitiesActionTypes.updateSearchFilter,
  payload: { searchValue },
});

export const addHierarchyFilter = (card: IOpenOpportunitiesDrillDownCard) => ({
  type: OpenOpportunitiesActionTypes.addHierarchyFilter,
  payload: { card },
});

export const sliceHierarchyFilter = (itemIndex: number) => ({
  type: OpenOpportunitiesActionTypes.sliceHierarchyFilter,
  payload: { itemIndex },
});

export const toggleSortDirection = () => ({
  type: OpenOpportunitiesActionTypes.toggleSortDirection,
  payload: {},
});

export const clearFilters = () => ({
  type: OpenOpportunitiesActionTypes.clearFilters,
  payload: {},
});
