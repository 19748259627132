import { FC } from 'react';
import './infoBox.scss';
import cn from 'clsx';

import { IInfoBox } from 'types';
import NumberUtils from 'utils/NumberUtils';

export const InfoBox: FC<IInfoBox> = ({
  amount = 0,
  isUnitsValues = false,
  className,
  title,
  currency,
  icon,
}) => {
  const formattedAmount = isUnitsValues ? amount : NumberUtils.toCurrency(amount, currency);

  return (
    <div className={cn('info-box', className)}>
      <div className={cn('box-header', { 'with-icon': !!icon })}>
        <div className="info-title">{title}</div>
        {icon}
      </div>
      <span className="amount">{formattedAmount}</span>
    </div>
  );
};
