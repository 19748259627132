export const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-J8CK12WKVW';

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    gtag_debug: any;
    dataLayer: any[];
  }
}

export enum AnalyticsEventActions {
  CLICKED = 'clicked',
  CHANGED = 'changed',
  SELECTED = 'selected',
  MSG_SENT = 'message_sent',
  MSG_RETRY = 'message_retry',
  MSG_DELETE = 'message_deleted',
  MSG_VIEWED = 'message-viewed',
  PLAY_VIDEO = 'video_play',
  PAGE_VIEW = 'page_view',
  DATE_CHANGED = 'date_changed',
  SORT = 'sort',
  SEARCH = 'search',
  TIMING = 'timing',
  VIEWED = 'viewed',
}

export enum AnalyticsEventCategory {
  SEARCH = 'Search',
  TAB = 'Tab',
  REASONS = 'Reasons',
  SWITCH = 'Switch',
  PAGE_VIEW = 'Page View',
  VIDEO_PLAY_TIME = 'Video play time',
  DATE_CHANGED = 'Date changed',
  SORT_DIRECTION = 'Sort direction',
  SORT_BY = 'Sort by',
  DATE_SLIDER = 'Date slider changed',
  LIVE_INVENTORY = 'Live inventory',
  SUMMARY_KPIS_TIME = 'Summary kpis time',
  SUMMARY_LEADER_BOARD_TIME = 'Summary Leader board time',
  SUMMARY_TOP_FINDINGS_TIME = 'Summary top findings time',
  SUMMARY_TRENDS_TAB = 'Summary trends tab',
  SUMMARY_LEADER_BOARD_TAB = 'Summary leader board tab',
  SUMMARY_RATE_QUESTION_MARK = 'Summary rate question mark',
  SUMMARY_TO_MY_OPPORTUNITIES = 'Summary to my Opportunities',
  SUMMARY_TO_OPEN_OPPORTUNITIES = 'Summary to open Opportunities',
  SUMMARY_TO_FINDINGS = 'Summary to Findings',
  SUMMARY_TO_TRENDS = 'Summary to trends',
  MESSAGING = 'Messaging',
  SELECT_OPPORTUNITY = 'Select opportunity',
  SELECT_OPEN_OPPORTUNITIES_CARD = 'Select open opportunities by card type',
  OPEN_OPPORTUNITIES_DRILL_DOWN_HIERARCHY = 'Open opportunities drill down hierarchy',
  DRILL_DOWN_FILTER = 'Drill down filter',
  DRILL_DOWN_FILTER_REMOVE = 'Drill down filter remove',
  DRILL_DOWN_OPPORTUNITIES = 'Drill down opportunities',
  DRILL_DOWN_OPPORTUNITY_DETAILS = 'Drill down opportunity details',
  LOGIN_FLOW = 'Login Flow',
  ANIMATED_EMPTY_STATE = 'Animated empty state',
}

export enum AnalyticsAuthEventsId {
  BIND_WITH_SSO = 'Bind with sso',
  BIND_WITH_SSO_FAILURE = 'Bind with sso failure',
  AUTH_SUCCESS = 'Auth success',
  AUTH_TOKEN_REFRESH = 'Token refresh',
  USER_TENANT_FAILURE = 'USER TENANT FAILURE',
}
