import { handleErrors, handleException } from 'store/api/apiHelper';
import { HttpMethod } from 'store/api/APIConstants';
import { getTokens } from 'utils';

class FetchService {
  private static instance: FetchService;

  private constructor() {
    if (!FetchService.instance) {
      FetchService.instance = this;
    }
  }

  public static Init(): FetchService {
    if (!FetchService.instance) {
      FetchService.instance = new FetchService();
    }

    return FetchService.instance;
  }

  // Basic UPS request
  public static async postUPSData(fetchUrl: string) {
    const { token } = getTokens();

    const configs: any = {
      method: HttpMethod.POST,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    return FetchService.getData(fetchUrl, configs);
  }

  public static async getData(fetchUrl: string, configs: RequestInit) {
    try {
      const fetchedResponse = await fetch(fetchUrl, configs);

      return handleErrors(fetchedResponse, undefined);
    } catch (error: any) {
      return handleException(error, undefined, undefined);
    }
  }
}

export default FetchService;
