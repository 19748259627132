import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import FiltersActionTypes from 'store/filters/filtersActionTypes';
import { categoryLabelsMapper, filterValue, ICommonFilter, IGroupByOption, orderBy } from 'types';

export const setActiveTabFilter = (activeTab: filterValue) => ({
  type: FiltersActionTypes.setActiveTabFilter,
  payload: { activeTab },
});

export const setMyOpportunitiesSearch = (search: string) => ({
  type: FiltersActionTypes.setMyOpportunitiesSearch,
  payload: { search },
});

export const toggleControlMenu = (shouldClose = false) => ({
  type: FiltersActionTypes.ToggleControlMenu,
  payload: { shouldClose },
});

export const getAllFilterLabels = (activeTab: filterValue) => ({
  type: FiltersActionTypes.getAllFilterLabels,
  payload: { activeTab },
});

export const prepareMyFilterStats = (activeTab: filterValue) => ({
  type: FiltersActionTypes.prepareMyFilterStats,
  payload: { activeTab },
});

export const getProductFilterLabels = (labelFilter: string, index: number, filter: any) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: {
      labelFilter,
      index,
      filter,
    },
    url: `/v1/report/TaskStateByProductCategoryQuery/PRODUCT_CATEGORY_ID_${index}`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filter },
    },
    baseAction: FiltersActionTypes.getProductFilterLabels,
  };
};

export const getStoreFilterLabels = (labelFilter: string, index: number, filter: any) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: {
      labelFilter,
      index,
      filter,
    },
    url: `/v1/report/TaskStateByStoreCategoryQuery/STORE_CATEGORY_ID_${index}`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filter },
    },
    baseAction: FiltersActionTypes.getStoreFilterLabels,
  };
};

export const getStoreNameFilterLabels = (labelFilter: string, index: number, filter: any) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: {
      labelFilter,
      index,
      filter,
    },
    url: `/v1/report/TaskStateByStoreQuery`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filter },
    },
    baseAction: FiltersActionTypes.getStoreNameFilterLabels,
  };
};

export const getPlanogramFilterLabels = (labelFilter: string, index: number, filter: any) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: {
      labelFilter,
      index,
      filter,
    },
    url: `/v1/report/TaskStateByPlanogramQuery/PLANOGRAM_ID_${index}`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filter },
    },
    baseAction: FiltersActionTypes.getPlanogramFilterLabels,
  };
};

export const setFilterSelection = (
  label: string,
  selection: boolean,
  categoryLabelsMapper: categoryLabelsMapper,
  index: number,
) => ({
  type: FiltersActionTypes.setFilterSelection,
  payload: { label, selection, categoryLabelsMapper, index },
});

export const setFiltersSearch = (search: string) => ({
  type: FiltersActionTypes.setFilterSearch,
  payload: { search },
});

export const clearFilters = () => ({
  type: FiltersActionTypes.clearFilters,
  payload: {},
});

export const updateLoadMoreOffset = (nextPageOffset: number) => ({
  type: FiltersActionTypes.updateLoadMoreOffset,
  payload: { nextPageOffset },
});

export const applyMyOpportunitiesFilters = () => ({
  type: FiltersActionTypes.applyMyOpportunitiesFilters,
  payload: {},
});

export const setMyOpportunitiesFiltersOrder = (orderBy: orderBy) => ({
  type: FiltersActionTypes.setMyOpportunitiesFiltersOrder,
  payload: { orderBy },
});

export const setMyOpportunitiesGroupByOrder = (groupBy: IGroupByOption) => ({
  type: FiltersActionTypes.setMyOpportunitiesGroupByOrder,
  payload: { groupBy },
});

export const getMyFilterStats = (filter: ICommonFilter, activeTab: filterValue) => {
  return {
    type: APIConstants.API_REQUEST,
    payload: { filter, activeTab },
    url: `/v1/report/TaskStateHistogramQuery`,
    meta: {
      method: HttpMethod.POST,
      body: { filter },
    },
    baseAction: FiltersActionTypes.getMyFilterStats,
  };
};

const FiltersActions = {
  toggleControlMenu,
  getProductFilterLabels,
  setFilterSelection,
  clearFilters,
  applyMyOpportunitiesFilters,
  updateLoadMoreOffset,
  getStoreNameFilterLabels,
  setMyOpportunitiesFiltersOrder,
  getMyFilterStats,
  getAllFilterLabels,
  setFiltersSearch,
  getPlanogramFilterLabels,
};

export default FiltersActions;
