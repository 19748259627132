import './categoriesAcordion.scss';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CategoriesMapper, IFilterCategory, IRootState } from 'types';
import { ICategoriesAccordionProps, ICategoryPanel, ISubCategoryPanel } from './types';
import { Accordion } from 'components/accordion';
import SubCategoriesAccordion from './subCategoriesAccordion';
import { isEmptyArray } from 'utils';
import filtersSelector from 'store/filters/filtersSelector';

export const CategoriesAccordion = ({ filters, categories, isRtl }: ICategoriesAccordionProps) => {
  const [t] = useTranslation();

  const prepareCategoriesList = useCallback(() => {
    const categoriesList: Array<ICategoryPanel> = [];

    categories.forEach((category: string, index: number) => {
      categoriesList.push({
        id: category,
        title: t(`MY_OPPORTUNITIES.FILTER.CATEGORIES.${category}`),
        index,
      });
    });

    return categoriesList;
  }, [categories]);

  const categoriesList = prepareCategoriesList();

  const renderPanelHeader = useCallback(
    ({ panel }: any) => {
      return (
        <div className="main-category-header">
          <div className="title">{panel.title}</div>
        </div>
      );
    },
    [filters],
  );

  const renderPanelContent = useCallback(
    (panel: ICategoryPanel) => {
      const mappers = CategoriesMapper[panel.id];

      const subCategoriesList: Array<ISubCategoryPanel> = [];

      mappers.forEach((categoryMapper) => {
        const { mapper, labelsMapper: subCategoryMapper } = categoryMapper;

        const subCategories = filters?.[mapper];

        subCategories.forEach((category: IFilterCategory) => {
          if (category) {
            subCategoriesList.push({
              id: `filter-sub-category-item-${category.title}`,
              title: category.title,
              index: category.level,
              mapper: subCategoryMapper,
            });
          }
        });
      });

      if (isEmptyArray(subCategoriesList)) {
        return <div />;
      }

      return <SubCategoriesAccordion categories={subCategoriesList} />;
    },
    [filters],
  );

  return (
    <div className="categories-accordion main-categories">
      <Accordion
        isRtl={isRtl}
        panels={categoriesList}
        renderPanelHeader={renderPanelHeader}
        renderPanelContent={renderPanelContent}
        showExpandIcon={false}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { filters } = state.filters.myOpportunities;

  const { isRtl } = state.settings;

  return {
    filters,
    categories: filtersSelector.getMyOpportunitiesMainCategoriesSelector(state),
    isRtl,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesAccordion);
