import './SideMenu.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenuButton } from 'components/buttons/SideMenuButton';
import { FindingsIcon, OpportunitiesIcon, SummaryIcon, TrendsIcon } from 'components/Icons';
import { RoutePath } from 'routes/types';
import permissionsService from 'services/permissions/permissionsSrv';
import { IMenuAction, ISideMenuButtonProps } from './types';

const SideMenuMain: FC<ISideMenuButtonProps> = ({
  isMenuCollapsed,
  selectedActionMenuId,
  selectMenuAction,
  mayViewDashboard,
}) => {
  const [t] = useTranslation();

  const mayViewOpenOpportunities: boolean = !permissionsService.isSingleStoreManager();

  const menuActions: Array<IMenuAction> = [
    {
      label: t('PAGES.SUMMARY'),
      id: RoutePath.Summary,
      icon: SummaryIcon,
      canUserView: mayViewDashboard,
    },
    {
      label: t('PAGES.OPPORTUNITIES'),
      id: RoutePath.OpenOpportunities,
      icon: OpportunitiesIcon,
      canUserView: mayViewDashboard && mayViewOpenOpportunities,
    },
    {
      label: t('PAGES.FINDINGS'),
      id: RoutePath.Findings,
      icon: FindingsIcon,
      canUserView: mayViewDashboard,
    },
    {
      label: t('PAGES.TRENDS'),
      id: RoutePath.Trends,
      icon: TrendsIcon,
      canUserView: mayViewDashboard,
    },
  ];

  return (
    <div className="side-menu-main">
      {menuActions.map((menuAction) => (
        <SideMenuButton
          id={menuAction.id}
          key={menuAction.id}
          icon={menuAction.icon}
          isMenuCollapsed={isMenuCollapsed}
          isActive={menuAction.id === selectedActionMenuId}
          onClick={() => selectMenuAction(menuAction.id)}
          label={menuAction.label}
          hide={!menuAction.canUserView}
        />
      ))}
    </div>
  );
};

export default SideMenuMain;
