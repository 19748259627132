import { message } from 'antd';

import i18n from 'i18n/i18n';
import KnowledgeCenterActionTypes from 'store/knowledgecenter/knowledgeCenterActionTypes';
import MyOpportunitiesActionTypes from 'store/myOpportunities/myOpportunitiesActionTypes';
import ToastActions from 'store/toast/toastActions';
import ToastActionTypes from 'store/toast/toastActionTypes';
import { DEEP_LINK_NOT_AVAILABLE, IActionType } from 'types';
import ReasonsActionTypes from 'store/reasons/reasonsActionTypes';

message.config({
  duration: 3,
  maxCount: 5,
});

const toastMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: IActionType) => {
  const { type, payload } = action;

  switch (type) {
    case ToastActionTypes.Success: {
      const { text } = payload;
      message.success({
        content: text,
        rtl: getState().settings.isRtl,
      });
      break;
    }

    case ToastActionTypes.Error: {
      const { text } = payload;
      message.error({
        content: text,
        rtl: getState().settings.isRtl,
      });
      break;
    }

    case ToastActionTypes.Warning: {
      const { text } = payload;
      message.warning({
        content: text,
        rtl: getState().settings.isRtl,
      });
      break;
    }

    case ToastActionTypes.Info: {
      const { text } = payload;
      message.info({
        content: text,
        rtl: getState().settings.isRtl,
      });
      break;
    }

    case MyOpportunitiesActionTypes.submitOpportunityReason.FAILURE:
    case ReasonsActionTypes.getReasons.FAILURE:
    case MyOpportunitiesActionTypes.getMyOpportunities.FAILURE:
    case KnowledgeCenterActionTypes.getVideos.FAILURE: {
      // eslint-disable-next-line no-console
      console.log('Fail to fetch data', type);
      dispatch(ToastActions.error(i18n.t('TOAST.FAILED_TO_FETCH_DATA')));
      break;
    }

    case MyOpportunitiesActionTypes.removeOpportunityDeepLink: {
      const { id } = payload;

      if (id === DEEP_LINK_NOT_AVAILABLE) {
        dispatch(ToastActions.info(i18n.t('TOAST.SELECTED_OPPORTUNITY_NO_LONGER_AVAILABLE')));
      }

      break;
    }

    default:
      break;
  }

  next(action);
};

export default toastMiddleware;
