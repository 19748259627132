import { FC, useContext, useEffect } from 'react';

import 'components/pages/logout/logout.scss';
import { ILogout } from 'components/pages/logout/types';
import { reactKeycloakWebContext } from 'services/auth/keycloak/web/context';
import { clearTokens } from 'utils';

export const Logout: FC<ILogout> = () => {
  const ctx = useContext(reactKeycloakWebContext);

  useEffect(() => {
    if (ctx && ctx.authClient) {
      ctx.authClient.redirectUri = window.location.origin;
      ctx.authClient.logout();
    }

    clearTokens();
  }, []);

  return (
    <div className="logout-container">
      <div id="logout-loader">
        <img className="loading" alt="loading" src={`${process.env.PUBLIC_URL}login-loader.gif`} />
      </div>
    </div>
  );
};
