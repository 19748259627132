import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import FindingsActionTypes from './findingsActionTypes';
import {
  DrillDownTypes,
  IFilterItem,
  IFindingsFilter,
  ISelectedCategory,
  ReasonsGroups,
  SelectedDateIntervals,
  ValueType,
} from 'types';
import { buildGetReasonsRequestFilterBody } from 'store/findings/findingsHelper';

export const getStores = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Store`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: FindingsActionTypes.getStores,
  };
};

export const getOpportunities = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number,
  productImagesMetadata: Array<string>,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/TaskRawDataQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType, productImagesMetadata },
    baseAction: FindingsActionTypes.getOpportunities,
  };
};

export const getProducts = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Product`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: FindingsActionTypes.getProducts,
  };
};

// GET hierarchy or category data -> not leaf
export const getHierarchyDrillDownData = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number,
  endpoint: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType, pageOffset },
    baseAction: FindingsActionTypes.getHierarchyDrillDownData,
  };
};

export const getFindingsReasons = (
  filters: IFindingsFilter,
  reasons: ReasonsGroups,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  lastUserDeployDate: number,
  selectedCategory?: ISelectedCategory,
) => {
  const filter = buildGetReasonsRequestFilterBody(
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    lastUserDeployDate,
    selectedCategory,
  );

  return {
    type: APIConstants.API_REQUEST,
    payload: { reasons },
    url: `/v1/report/IssueFoundResponsesByReasonsTypes`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: FindingsActionTypes.getFindingsReasons,
  };
};

export const toggleReasonFilter = (reasonId: number, groupId: number) => ({
  type: FindingsActionTypes.toggleReasonFilter,
  payload: { reasonId, groupId },
});

export const toggleGroupSelection = (groupId: number, isActive: boolean) => ({
  type: FindingsActionTypes.toggleGroupSelection,
  payload: { groupId, isActive },
});

export const changeNumberRepresentation = (valueType: ValueType) => ({
  type: FindingsActionTypes.changeNumberRepresentation,
  payload: { valueType },
});

export const clearAllSelection = () => ({
  type: FindingsActionTypes.clearAllSelection,
});

export const setDateInterval = (
  type: SelectedDateIntervals,
  selectedDates: { from: number; to: number } | null = null,
) => ({
  type: FindingsActionTypes.setDateInterval,
  payload: { type, selectedDates },
});

export const toggleDirection = (drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.toggleDirection,
  payload: { drillDownType },
});

export const toggleExpended = (drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.toggleExpended,
  payload: { drillDownType },
});

export const removeDrillDownFilter = (filter: IFilterItem) => ({
  type: FindingsActionTypes.removeDrillDownFilter,
  payload: { filter },
});

export const sliceHierarchyPath = (hierarchyItemIndex: number, drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.sliceHierarchyPath,
  payload: { hierarchyItemIndex, drillDownType },
});

export const onDrillDownSearch = (search: string, drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.onDrillDownSearch,
  payload: { search, drillDownType },
});

export const setOtherDrillDownSelectedCategory = (value: string, label: string, id: string) => ({
  type: FindingsActionTypes.setOtherDrillDownSelectedCategory,
  payload: { value, label, id },
});

export const toggleFreeTextOnly = (drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.toggleFreeTextOnly,
  payload: { drillDownType },
});

export const addFilter = (filter: IFilterItem, drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.addFilter,
  payload: { filter, drillDownType },
});

export const onShowOpportunities = (drillDownType: DrillDownTypes) => ({
  type: FindingsActionTypes.onShowOpportunities,
  payload: { drillDownType },
});

export const setFiltersFromSearchParams = (queryStr: string) => ({
  type: FindingsActionTypes.setFiltersFromSearchParams,
  payload: { queryStr },
});

// Get drill down data -> call for all drill downs
// Navigate to the needed action in the middleware
export const getDrillDown = (
  drillDownType: DrillDownTypes,
  storeHierarchyDepth: number = 0,
  productHierarchyDepth: number = 0,
  selectedCategory?: ISelectedCategory,
  pageOffset: number = 0,
) => ({
  type: FindingsActionTypes.getDrillDown,
  payload: {
    drillDownType,
    selectedCategory,
    pageOffset,
    productHierarchyDepth,
    storeHierarchyDepth,
  },
});

const FindingsActions = {
  getFindingsReasons,
  changeNumberRepresentation,
  toggleGroupSelection,
  toggleReasonFilter,
  clearAllSelection,
  toggleDirection,
  sliceHierarchyPath,
  setDateInterval,
  onDrillDownSearch,
  toggleExpended,
  getDrillDown,
  removeDrillDownFilter,
  addFilter,
  toggleFreeTextOnly,
};

export default FindingsActions;
