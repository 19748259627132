import { FC } from 'react';
import { LineChart, XAxis, Line, Tooltip, CartesianGrid, ReferenceLine } from 'recharts';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { getShortDateFormat } from 'utils';
import { MILLISECONDS } from 'constants/constantsVaribles';
import { IImpactOnSalesChart } from 'types';
import './ImpactOnSales.scss';
import NumberUtils from 'utils/NumberUtils';

const WEEKS_TO_SHOW = 8;

const CHART_HEIGHT = 96;

const CHART_WIDTH = 440;

const ImpactOnSalesChart: FC<IImpactOnSalesChart> = ({
  chartValues,
  respondReasonDate,
  isNoIssueFoundOrExpired,
  currency,
  isRtl,
}) => {
  const eightWeeksChartValues = chartValues.slice(0, WEEKS_TO_SHOW);

  const lastDate = eightWeeksChartValues![eightWeeksChartValues.length - 1]?.date || 0;

  const futureDatesLength = WEEKS_TO_SHOW - eightWeeksChartValues.length;

  const [t] = useTranslation();

  const futureDates = [...new Array(futureDatesLength)].map((_, index) => ({
    date: lastDate + MILLISECONDS.WEEK * (index + 1),
  }));

  const data = eightWeeksChartValues
    .map((sale): any => ({
      date: sale.date,
      value: sale.value,
    }))
    .concat(futureDates);

  const xAxisValues = data.map((item) => item.date);

  const ResponseLabel = (referenceLineProps: any) => {
    const { viewBox, offset } = referenceLineProps;

    const { x: lineXPos, height: lineHeight } = viewBox;

    const labelBoxWidth = 70;

    const labelBoxHeight = 14;

    const paddingFromAxis = 4;

    const labelY = lineHeight + offset + paddingFromAxis + labelBoxHeight;

    return (
      <g className={cn('respond-line-chart', { 'no-found-or-expired': isNoIssueFoundOrExpired })}>
        <line x1={lineXPos} y1={labelY} x2={lineXPos} />
        <rect
          x={lineXPos - labelBoxWidth / 2}
          y={labelY}
          width={labelBoxWidth}
          height={labelBoxHeight}
        />
        <foreignObject
          height={labelBoxHeight}
          className="line-text"
          x={lineXPos - labelBoxWidth / 2}
          y={labelY}
          width={labelBoxWidth}
        >
          <span> {t('MY_OPPORTUNITIES.RESPONDED')}</span>
        </foreignObject>
      </g>
    );
  };

  const ToolTipComponent: FC<any> = ({ active, payload }) => {
    if (active && payload.length > 0) {
      const { value: saleAmount, date } = payload![0]?.payload;

      const formattedSalesAmount = NumberUtils.toCurrency(saleAmount, currency);

      return (
        <div className="tooltip">
          <span>
            {t('COMMON.SALES')}: <b>{formattedSalesAmount}</b>
          </span>
          <span>
            {t('COMMON.DATE')}: <b>{getShortDateFormat(date)}</b>
          </span>
        </div>
      );
    }

    return null;
  };

  const formatXAxis = (tickItem: number) => getShortDateFormat(tickItem);

  return (
    <div className="impact-on-sales-chart">
      <LineChart
        margin={{ left: 20, right: 20, top: 4, bottom: 4 }}
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        data={data}
      >
        <CartesianGrid horizontal={false} className="grid-impact-on-sales" />
        <Line
          activeDot={false}
          className={cn('line-impact-on-sales', { 'no-found-or-expired': isNoIssueFoundOrExpired })}
          isAnimationActive={false}
          type="linear"
          dataKey="value"
        />
        <XAxis
          type="number"
          reversed={isRtl}
          interval={0}
          tickFormatter={formatXAxis}
          style={{ fontSize: 10 }}
          dataKey="date"
          ticks={xAxisValues}
          domain={[data![0]?.date, data![WEEKS_TO_SHOW - 1]?.date]}
          strokeWidth={0}
        />
        <Tooltip content={<ToolTipComponent />} />
        <ReferenceLine x={respondReasonDate} label={ResponseLabel} />
      </LineChart>
    </div>
  );
};

export default ImpactOnSalesChart;
