import cn from 'clsx';
import { FC } from 'react';

import { ICategoriesSection } from 'types';
import Category from './Category';

const CategoriesSection: FC<ICategoriesSection> = ({ categories, withSeparator }) => {
  return (
    <div
      className={cn('categories-section-container', {
        'with-separator': withSeparator,
      })}
    >
      {categories &&
        categories.map((category) => (
          <Category {...category} key={`${category.value}_${category.name}`} />
        ))}
    </div>
  );
};

export default CategoriesSection;
