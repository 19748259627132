import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components/inputs';
import './updatePassword.scss';
import { IRootState } from 'types';
import { resetServerResponseStatus, updatePassword } from 'store/settings/settingsActions';
import { httpResponseStatus } from 'store/api/APIConstants';

interface IUpdatePassword {
  serverResponseStatus: number | null;
  email: string;

  resetServerResponseStatus(): void;

  updatePassword(form: any, email: string): void;
}

const UpdatePassword: FC<IUpdatePassword> = ({
  updatePassword,
  serverResponseStatus,
  email,
  resetServerResponseStatus,
}) => {
  const [t] = useTranslation();

  const [currentPassword, setCurrentPassword] = useState('');

  const [newPassword, setNewPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const isPasswordsNotMatch = confirmPassword !== newPassword;
  useEffect(() => {
    return () => resetServerResponseStatus();
  }, []);

  const disabled =
    isFormSubmitted || isPasswordsNotMatch || newPassword === '' || currentPassword === '';

  const onSubmit = () => {
    setIsFormSubmitted(true);
    updatePassword({ currentPassword, newPassword }, email);
  };

  return (
    <div className="update-password-container">
      <InputField
        id="current-password"
        type="password"
        onChange={setCurrentPassword}
        value={currentPassword}
        title={t('SETTINGS.CURRENT_PASSWORD')}
        disabled={isFormSubmitted}
        placeholder={t('SETTINGS.CURRENT_PASSWORD')}
        className="update-password-input"
      />
      <InputField
        id="new-password"
        type="password"
        onChange={setNewPassword}
        value={newPassword}
        disabled={isFormSubmitted}
        title={t('SETTINGS.NEW_PASSWORD')}
        isError={isPasswordsNotMatch}
        placeholder={t('SETTINGS.NEW_PASSWORD')}
        className="update-password-input"
      />
      <InputField
        id="current-password-confirmation"
        type="password"
        disabled={isFormSubmitted}
        onChange={setConfirmPassword}
        value={confirmPassword}
        errorMessage={t('SETTINGS.PASSWORDS_NOT_MATCH')}
        title={t('SETTINGS.CONFIRM_PASSWORD')}
        isError={isPasswordsNotMatch}
        placeholder={t('SETTINGS.CONFIRM_PASSWORD')}
        className="update-password-input"
      />
      <button disabled={disabled} onClick={onSubmit} className="update-password-button">
        {t('COMMON.SUBMIT')}
      </button>
      <div className="server-message-container">
        {isFormSubmitted && (
          <>
            {serverResponseStatus === httpResponseStatus.Failure && (
              <span className="error-message">{t('SETTINGS.PASSWORD_UPDATE_FAIL')}</span>
            )}
            {serverResponseStatus === httpResponseStatus.Success && (
              <span className="success-message">{t('SETTINGS.PASSWORD_UPDATE_SUCCESS')}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { email } = state.userProfile;

  const { serverResponseStatus } = state.settings;

  return {
    email,
    serverResponseStatus,
  };
};

const mapDispatchToProps = {
  updatePassword,
  resetServerResponseStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
