import { ElementType, FC } from 'react';
import cn from 'clsx';

import './actionButton.scss';

export interface IActionButton {
  text: string;
  Icon?: ElementType;
  onClick: () => void;
  className?: string;
}

export enum ActionButtonClasses {
  SEE_DETAILS = 'see-details',
}

export const ActionButton: FC<IActionButton> = ({ text, Icon, onClick, className }) => {
  return (
    <div className={cn('container', className)} onClick={onClick} role="button" tabIndex={-1}>
      <div className="text">{text}</div>
      {Icon && <Icon className="icon" />}
    </div>
  );
};
