import cn from 'clsx';
import { FC } from 'react';

import './SideMenuButton.scss';
import { RoutePath } from 'routes/types';

export interface SideMenuButtonProps {
  /**
   * button id
   */
  id: RoutePath;
  /**
   * Is this the button active
   */
  isActive?: boolean;
  /**
   * Is this the button expanded
   */
  isMenuCollapsed?: boolean;
  /**
   * Button contents
   */
  label: string;
  /**
   * Button icon
   */
  icon?: Function;
  /**
   * Optional click handler
   */
  onClick?: (id: RoutePath) => void;
  /**
   * Optional click handler
   */
  tabIndex?: number;
  /**
   * Optional hide button
   */
  hide?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const SideMenuButton: FC<SideMenuButtonProps> = ({
  isActive,
  isMenuCollapsed = true,
  icon: Icon = null,
  label,
  id,
  onClick,
  tabIndex,
  hide,
  ...props
}) => {
  const onButtonClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClick) {
      onClick(id);
    }
  };

  const active = isActive ? 'active' : '';

  return (
    <div
      id={`side-menu-${id}`}
      role="button"
      tabIndex={tabIndex}
      className={cn(
        'button-side-menu',
        'no-selectable',
        active,
        isMenuCollapsed && 'collapsed',
        hide && 'hide',
      )}
      onClick={onButtonClick}
    >
      <div className={cn('indicator', active)} />
      <div className={cn('button-box', 'no-selectable', active)}>
        {Icon && <Icon isActive={isActive} />}
        {!isMenuCollapsed && (
          <button type="button" className={cn('button', active)} {...props}>
            {label}
          </button>
        )}
      </div>
    </div>
  );
};
