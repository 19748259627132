import { ICommonFilter, FilterType, filterKey, termValue } from './types';

// Term filter builder allow constructing primitive filter all interface must invoke after invoking
export interface ITermFilterBuilder {
  // most times will be key in filterKey
  // in drill downs it can be other string
  term(key: filterKey | string, value: termValue): IOperatorFilterBuilder;
  freeText(searchValue: termValue, fields?: string[]): IOperatorFilterBuilder;
  time(key: filterKey, from: number, to?: number): IOperatorFilterBuilder;
  relative(key: filterKey, timeAgo: number): IOperatorFilterBuilder;
  nonempty(key: filterKey): IOperatorFilterBuilder;
  // open bracket must follow IOperatorFilterBuilder
  openBracket(): ITermFilterBuilder;
}

// Operator filter builder allow concat terms filter with operator and execute filter build
export interface IOperatorFilterBuilder {
  and(): ITermFilterBuilder;
  or(): ITermFilterBuilder;
  build(): ICommonFilter;
  // close bracket must follow ITermFilterBuilder
  closeBracket(): IOperatorFilterBuilder;
}

// push filter to filter list (create filter list if not exist)
export const pushFilter = (filter: ICommonFilter, filterToPush: ICommonFilter): void => {
  if (!filter.filters) {
    filter.filters = [];
  }
  filter.filters.push(filterToPush);
};

export class FilterBuilder implements ITermFilterBuilder, IOperatorFilterBuilder {
  private inProcessFilter?: ICommonFilter;

  private openFilterStack: ICommonFilter[] = [];

  private addTermFilter(filter: ICommonFilter): void {
    // first filter (must be term)
    if (!this.inProcessFilter) {
      this.inProcessFilter = filter;
    } else {
      // filter exist - push to filter list
      pushFilter(this.inProcessFilter, filter);
    }
  }

  private addOperatorFilter(filter: ICommonFilter): void {
    if (!this.inProcessFilter) {
      return;
    }
    if (this.inProcessFilter.type !== filter.type) {
      const lastProcessFilter = this.inProcessFilter;
      this.inProcessFilter = filter;
      // add previous filter to operator filter list
      pushFilter(this.inProcessFilter, lastProcessFilter);
    }
    // same operator as before nothing to do
  }

  // add term filter with type FilterType.TERM
  public term(key: filterKey | string, value: termValue): IOperatorFilterBuilder {
    this.addTermFilter({
      type: FilterType.TERM,
      key,
      value,
    });

    return this;
  }

  // add nonempty filter with type FilterType.NON_EMPTY
  public nonempty(key: filterKey): IOperatorFilterBuilder {
    this.addTermFilter({
      type: FilterType.NON_EMPTY,
      key,
    });

    return this;
  }

  // add term filter with type FilterType.TEXT_FREE
  public freeText(searchValue: termValue, fields?: string[]): IOperatorFilterBuilder {
    this.addTermFilter({
      type: FilterType.FREE_TEXT,
      searchValue,
      fields,
    });

    return this;
  }

  // add term filter with type FilterType.TIME
  public time(key: filterKey, from: number, to?: number): IOperatorFilterBuilder {
    this.addTermFilter({
      type: FilterType.TIME,
      key,
      from,
      to,
    });

    return this;
  }

  // add term filter with type FilterType.RELATIVE
  public relative(key: filterKey, timeAgo: number): IOperatorFilterBuilder {
    this.addTermFilter({
      type: FilterType.RELATIVE,
      key,
      timeAgo,
    });

    return this;
  }

  // add operator filter FilterType.AND
  public and(): ITermFilterBuilder {
    this.addOperatorFilter({
      type: FilterType.AND,
    });

    return this;
  }

  // add operator filter FilterType.OR
  public or(): ITermFilterBuilder {
    this.addOperatorFilter({
      type: FilterType.OR,
    });

    return this;
  }

  // open bracket
  public openBracket(): ITermFilterBuilder {
    if (!this.inProcessFilter) {
      this.openFilterStack.push({});

      return this;
    }
    this.openFilterStack.push(this.inProcessFilter);
    this.inProcessFilter = undefined;

    return this;
  }

  // close bracket
  public closeBracket(): IOperatorFilterBuilder {
    if (this.openFilterStack.length === 0 || !this.inProcessFilter) {
      throw new Error('condition must have open bracket before closing');
    }

    const filter = this.openFilterStack.pop();

    if (filter && filter.type) {
      pushFilter(filter, this.inProcessFilter);
      this.inProcessFilter = filter;
    }

    return this;
  }

  // execute build and return the constructed filter
  public build(): ICommonFilter {
    if (!this.inProcessFilter) {
      return {};
    }

    return this.inProcessFilter;
  }

  public hasFilter(): boolean {
    return !!this.inProcessFilter;
  }
}
