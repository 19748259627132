import { fieldName, TrendsTabOptions } from 'types';

export const mapSelectedTabToEmptyText = {
  [TrendsTabOptions.Earning]: 'TRENDS.EMPTY_STATE.SALES_INCREASE_TEXT',
  [TrendsTabOptions.HitRate]: 'TRENDS.EMPTY_STATE.RATE_TEXT',
  [TrendsTabOptions.ResponseRate]: 'TRENDS.EMPTY_STATE.RATE_TEXT',
};

export const mapSelectedTabToEmptyTitle = {
  [TrendsTabOptions.Earning]: 'TRENDS.EMPTY_STATE.SALES_INCREASE_TITLE',
  [TrendsTabOptions.HitRate]: 'TRENDS.EMPTY_STATE.RATE_TITLE',
  [TrendsTabOptions.ResponseRate]: 'TRENDS.EMPTY_STATE.RATE_TITLE',
};

export const mapSelectedTabToFooterTitle = {
  [TrendsTabOptions.Earning]: 'TRENDS.SALES_INCREASE',
  [TrendsTabOptions.HitRate]: 'TRENDS.HIT_RATE',
  [TrendsTabOptions.ResponseRate]: 'TRENDS.RESPONSE_RATE',
};

export const mapSelectedTabToFooterText = {
  [TrendsTabOptions.Earning]: 'TRENDS.FOOTER.SALES_INCREASE_INFO',
  [TrendsTabOptions.HitRate]: 'TRENDS.FOOTER.HIT_RATE_INFO',
  [TrendsTabOptions.ResponseRate]: 'TRENDS.FOOTER.RESPONSE_RATE_INFO',
};

export const mapTrendsTabOptionToOrderFieldName = {
  [TrendsTabOptions.HitRate]: fieldName.hitRate,
  [TrendsTabOptions.ResponseRate]: fieldName.responseRate,
  [TrendsTabOptions.Earning]: fieldName.revenueIncrease,
};

export const mapSelectedTabToHeaderSubTitle = {
  [TrendsTabOptions.Earning]: 'DRILL_DOWNS.BY_SALES_INCREASE',
  [TrendsTabOptions.HitRate]: 'DRILL_DOWNS.BY_HIT_RATE',
  [TrendsTabOptions.ResponseRate]: 'DRILL_DOWNS.BY_RESPONSE_RATE',
};
