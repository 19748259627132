import { connect } from 'react-redux';
import { FC } from 'react';
import 'components/pages/myOpportunities/myOpportunities.scss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
  animationType as progressAnimationType,
  IMyOpportunitiesEmptyState,
  IRootState,
  OpportunitiesTabsOptions,
} from 'types';
import { EmptyState } from 'components/emptyState';
import { CompleteIcon, NoResultIcon, OpenTabIcon, WelcomeIcon } from 'components/Icons';
import { clearFilters } from 'store/filters/filtersActions';
import FiltersSelector from 'store/filters/filtersSelector';
import { toFixed } from 'utils';
import { EmptyStateWithAnimation } from 'components/emptyState/emptyStateWithAnimation';
import routerService from 'services/router/routerService';
import userProfileSelector from 'store/userProfile/userProfileSelector';
import { ProgressAnimation } from 'components/animation';
import LoadingSelector from 'store/loading/LoadingSelector';

export const MyOpportunitiesEmptyState: FC<IMyOpportunitiesEmptyState> = ({
  search,
  isFilterOn,
  isOpenTabActive,
  taskExpiryDays,
  clearFilters,
  filterList,
  animatedEmptyState,
  motivationAnimationData,
  haveOpportunitiesBeenDeployed,
  isEmptyStateAnimationLoading,
}) => {
  const [t] = useTranslation();

  const isSearchApplied = search !== '';

  const isAnimationLoading =
    isEmptyStateAnimationLoading || (animatedEmptyState && !motivationAnimationData);

  const getEmptyState = () => {
    if (isSearchApplied) {
      const title = t(`MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_FOUND_SEARCH`, {
        search,
      });

      return <EmptyState title={title} icon={NoResultIcon} dataTestId="no-result-empty-state" />;
    }

    if (isFilterOn) {
      const title = t(`MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_FOUND_FILTER_TITLE`);

      const text = t(`MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_FOUND_FILTER_TEXT`, {
        filters: `${filterList.toString()}`,
      });

      const clickableText = t(`MY_OPPORTUNITIES.EMPTY_STATE.CLEAR_ALL_FILTERS`);

      return (
        <EmptyState
          clickableText={clickableText}
          onClick={clearFilters}
          title={title}
          text={text}
          icon={NoResultIcon}
          dataTestId="no-filter-empty-state"
        />
      );
    }

    if (isAnimationLoading) {
      return (
        <ProgressAnimation
          className="empty-state-animation-spinner"
          animationType={progressAnimationType.Spinner}
          isLoading={isAnimationLoading}
        />
      );
    }

    if (!isEmptyStateAnimationLoading && animatedEmptyState && motivationAnimationData) {
      const { hitRateScore, animationType } = motivationAnimationData;

      const {
        totalIssueCount,
        issueFoundCount,
        curHitRate,
        deltaHitRateAndAvgChainHitRate,
      } = hitRateScore;

      const titleKey = `MY_OPPORTUNITIES.EMPTY_STATE.ANIMATED.${animationType}.TITLE`;

      const title = i18next.exists(titleKey) ? t(titleKey) : undefined;

      const footerTitleKey = `MY_OPPORTUNITIES.EMPTY_STATE.ANIMATED.${animationType}.FOOTER_TITLE`;

      const footerTitle = i18next.exists(footerTitleKey) ? t(footerTitleKey) : undefined;

      const footerLinkKey = `MY_OPPORTUNITIES.EMPTY_STATE.ANIMATED.${animationType}.FOOTER_LINK`;

      const footerLink = i18next.exists(footerLinkKey) ? t(footerLinkKey) : undefined;

      const subTitle = t(`MY_OPPORTUNITIES.EMPTY_STATE.ANIMATED.${animationType}.SUBTITLE`, {
        totalFound: issueFoundCount,
        totalTasks: totalIssueCount,
        hitRate: toFixed(curHitRate),
        chainDelta: toFixed(Math.abs(deltaHitRateAndAvgChainHitRate)),
      });

      return (
        <EmptyStateWithAnimation
          animationType={animationType}
          footerLink={footerLink}
          title={title}
          footerTitle={footerTitle}
          onClickFooterLink={() => {
            routerService.routeToContactUs([
              {
                key: 'subject',
                value: t('CONTACT_US.SUBJECTS.HIT_RATE_HELP'),
              },
            ]);
          }}
          subTitle={subTitle}
          dataTestId="animation-empty-state"
        />
      );
    }

    const showWelcomeText = isOpenTabActive && !haveOpportunitiesBeenDeployed;

    // opportunities have not yet been deployed (new project)
    if (showWelcomeText) {
      const title = t('MY_OPPORTUNITIES.EMPTY_STATE.WELCOME');

      const text = t(`MY_OPPORTUNITIES.EMPTY_STATE.WELCOME_TEXT`);

      return (
        <EmptyState
          title={title}
          text={text}
          icon={WelcomeIcon}
          dataTestId="no-deploy-empty-state"
        />
      );
    }

    if (isOpenTabActive) {
      const title = t('MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_OPEN_TAB_TITLE');

      const text = t(`MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_OPEN_TAB_TEXT`);

      return (
        <EmptyState
          title={title}
          text={text}
          icon={OpenTabIcon}
          dataTestId="open-tab-empty-state"
        />
      );
    }

    const title = t(`MY_OPPORTUNITIES.EMPTY_STATE.COMPLETED_OPPORTUNITIES`);

    const text = t(`MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_COMPLETED_TAB_TEXT`, { taskExpiryDays });

    return (
      <EmptyState title={title} text={text} icon={CompleteIcon} dataTestId="default-empty-state" />
    );
  };

  return getEmptyState();
};

const mapStateToProps = (state: IRootState) => {
  const { availability: taskExpiryDays } = state.settings.taskExpiryDays;

  const { activeTab, animatedEmptyState, motivationAnimationData } = state.myOpportunities;

  const activeFilter = FiltersSelector.getMyOpportunitiesFilterSelector(state);

  const { search } = activeFilter;

  return {
    isFilterOn: FiltersSelector.isMyOpportunitiesFilterOn(state),
    filterList: FiltersSelector.getMyOpportunitiesFilterList(state),
    isEmptyStateAnimationLoading: LoadingSelector.isEmptyStateAnimationLoading(state),
    haveOpportunitiesBeenDeployed: userProfileSelector.haveOpportunitiesBeenDeployed(state),
    isOpenTabActive: activeTab === OpportunitiesTabsOptions.Open,
    motivationAnimationData,
    search,
    taskExpiryDays,
    animatedEmptyState,
  };
};

const mapDispatchToProps = {
  clearFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOpportunitiesEmptyState);
