import { FC } from 'react';
import './yearLineLabel.scss';

const LABEL_BOX_SIZE = 23;

const PADDING = 4;

const YearLineLabel: FC<any> = (referenceLineProps) => {
  const { viewBox, offset, text } = referenceLineProps;

  const { x: lineXPos, height: lineHeight } = viewBox;

  const labelY = lineHeight + offset + PADDING + LABEL_BOX_SIZE;

  return (
    <g className="year-line-chart">
      <line x1={lineXPos} y1={labelY} x2={lineXPos} />
      <foreignObject
        height={LABEL_BOX_SIZE}
        className="circle-text"
        x={lineXPos - LABEL_BOX_SIZE / 2}
        y={labelY}
        width={LABEL_BOX_SIZE}
      >
        <span>{text}</span>
      </foreignObject>
    </g>
  );
};

export default YearLineLabel;
