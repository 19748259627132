export enum ArrowDirection {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
  None = 'none',
}

export interface IDateButton {
  label?: string;
  dates?: [number, number];
  hasWeeksLabel?: boolean;
  arrowDirection?: ArrowDirection;
  onClick(): void;
}
