import { flow, set } from 'lodash/fp';

import { IActionType, IPopupModal } from 'types';
import PopupActionTypes from './popupActionTypes';

export const initialState: IPopupModal = {
  id: null,
  selectedOpportunityId: null,
};

const popupReducer = (state: IPopupModal = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case PopupActionTypes.Show: {
      const { id, selectedOpportunityId } = payload;

      return flow([set('id', id), set('selectedOpportunityId', selectedOpportunityId)])(state);
    }

    case PopupActionTypes.Hide: {
      return initialState;
    }

    default:
      return state;
  }
};

export default popupReducer;
