import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IPrintCard } from 'types';
import Chart from './Chart';
import './card.scss';
import NumberUtils from 'utils/NumberUtils';
import { getFullDate } from 'utils';

const Card: FC<IPrintCard> = ({
  index,
  totalCount,
  productName,
  storeName,
  action,
  productSkusAndSizes,
  targetSales,
  potentialRevenue,
  potentialUnits,
  currency,
  targetUnits,
  productImage,
  productCategories,
  lastSalesValues,
  lastSimilarSalesValues,
  inventoryUpdate,
  isRtl,
}) => {
  const [t] = useTranslation();

  const summableLastSalesValues = lastSalesValues.filter((sale) => sale.summable);

  const summableSimilarLastSalesValues = lastSimilarSalesValues.filter((sale) => sale.summable);

  const sumLastSalesValues = summableLastSalesValues.reduce(
    (total, sale2) => total + sale2.value,
    0,
  );

  const sumSimilarLastSalesValues = summableSimilarLastSalesValues.reduce(
    (total, sale2) => total + sale2.value,
    0,
  );

  const formattedSumLastSalesValues = NumberUtils.toCurrency(sumLastSalesValues, currency);

  const formattedSumSimilarLastSalesValues = NumberUtils.toCurrency(
    sumSimilarLastSalesValues,
    currency,
  );

  const summableWeeksCount = summableLastSalesValues.length;

  const targetSalesValue = targetSales ? Math.round(targetSales) : null;

  const potentialSalesValue = potentialRevenue ? Math.round(potentialRevenue) : null;

  const getHeader = useCallback(() => {
    const skusAndSizes = productSkusAndSizes.map((item) => {
      return `[${t('MY_OPPORTUNITIES.SIZE')} ${item.size} | ${t('MY_OPPORTUNITIES.SKU')} ${
        item.sku
      }]`;
    });

    let skusAndSizesString = skusAndSizes.join(' | ');

    if (skusAndSizesString !== '') {
      skusAndSizesString = ` | ${skusAndSizesString}`;
    }

    const actionText = ` | ${t(`MY_OPPORTUNITIES.ACTION_TYPES.${action}`)}`;

    const headerText = `${productName}${skusAndSizesString}${actionText} | ${storeName}`;

    return (
      <div className="card-header">
        <div className="text">{headerText}</div>
        <div className="card-serial">
          {index}/{totalCount}
        </div>
      </div>
    );
  }, [action, storeName, productName, totalCount, index]);

  const getCategoriesList = () => {
    if (!productCategories) {
      return null;
    }

    return productCategories.map((category) => (
      <div className="category" key={`${category.label}_${category.title}`}>
        <div className="category-title">{category.title}</div>
        <div className="value">{category.label}</div>
      </div>
    ));
  };

  return (
    <tr>
      <td>
        <div className="card-print-container">
          <div className="card-info-container">
            <div className="text">{getHeader()}</div>
            <div className="card-content">
              {productImage && (
                <img alt="product" src={productImage} className="product-image-print" />
              )}
              <div className="categories-container products">
                <div className="title">{t('MY_OPPORTUNITIES.PRINT.CATEGORIES_INFORMATION')}</div>
                <div className="category-list">{getCategoriesList()}</div>
              </div>
              {(targetSalesValue || targetUnits) && (
                <div className="categories-container">
                  <div className="title">{t('MY_OPPORTUNITIES.PRINT.MONTHLY_SALES')}</div>
                  <div className="category-list">
                    {targetSalesValue && (
                      <div className="category">
                        <div className="category-title">
                          {t('MY_OPPORTUNITIES.PRINT.TARGET', { currency })}
                        </div>
                        <div className="value">{targetSalesValue}</div>
                      </div>
                    )}
                    {targetUnits && (
                      <div className="category">
                        <div className="category-title">
                          {t('MY_OPPORTUNITIES.PRINT.TARGET_UNITS')}
                        </div>
                        <div className="value">{targetUnits}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(potentialSalesValue || potentialUnits) && (
                <div className="categories-container">
                  <div className="title">{t('MY_OPPORTUNITIES.PRINT.POTENTIAL_SALES')}</div>

                  <div className="category-list">
                    {potentialSalesValue && (
                      <div className="category">
                        <div className="category-title">
                          {t('MY_OPPORTUNITIES.PRINT.TARGET', { currency })}
                        </div>
                        <div className="value">{potentialSalesValue}</div>
                      </div>
                    )}
                    {potentialUnits && (
                      <div className="category">
                        <div className="category-title">
                          {t('MY_OPPORTUNITIES.PRINT.TARGET_UNITS')}
                        </div>
                        <div className="value">{potentialUnits}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {inventoryUpdate && (
                <div className="categories-container">
                  <div className="title">{t('MY_OPPORTUNITIES.PRINT.INVENTORY_UPDATE')} </div>
                  <div className="category-list">
                    <div className="category">
                      <div className="category-title">{t('MY_OPPORTUNITIES.PRINT.DATE')}</div>
                      <div className="value">{getFullDate(inventoryUpdate.date)}</div>
                    </div>
                    <div className="category">
                      <div className="category-title">{t('MY_OPPORTUNITIES.PRINT.VALUE')}</div>
                      <div className="value">{inventoryUpdate.value}</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="categories-container">
                <div className="title">{t('MY_OPPORTUNITIES.PRINT.LAST_FOUR_WEEKS')}</div>
                <Chart
                  isRtl={isRtl}
                  lastSalesValues={lastSalesValues}
                  lastSimilarSalesValues={lastSimilarSalesValues}
                />
                <div className="title">
                  {t('MY_OPPORTUNITIES.OVER_THE_LAST_WEEKS', {
                    summableWeeksCount,
                  })}
                </div>
                <div className="sum-sales-compare">
                  <div className="sales your-stores">
                    <span>{t('MY_OPPORTUNITIES.YOUR_STORE')}</span>
                    {formattedSumLastSalesValues}
                  </div>
                  <div className="sales">
                    <span>{t('MY_OPPORTUNITIES.COMPARABLE_STORES')}</span>
                    {formattedSumSimilarLastSalesValues}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="reason-found">{t('MY_OPPORTUNITIES.PRINT.REASON_FOUND')}</div>
        </div>
      </td>
    </tr>
  );
};

export default Card;
