import { FC, useCallback, useState } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { MessageIcon, UpArrow, WhiteMessageIcon } from 'components/Icons';
import { IPanel } from 'types';
import './panel.scss';

const ANIMATION_DURATION = 1500;

const Panel: FC<IPanel> = ({
  isReasonDescriptionMandatory,
  id,
  minimumCharsCount,
  onSubmitClick,
  description,
  isOptionSelected,
  reasonState,
  label,
  isExpended,
  onPanelClick,
}) => {
  const [panelDescription, setPanelDescription] = useState<string>(description);

  const [isAnimate, setIsAnimate] = useState(false);

  const [t] = useTranslation();

  const onTextChange = useCallback((e: any) => setPanelDescription(e.target.value), []);

  const disableButtons =
    isReasonDescriptionMandatory && panelDescription.length <= minimumCharsCount;

  const isActive = isAnimate || isOptionSelected;

  const onClickMessage = () => {
    if (isExpended && isOptionSelected) {
      setPanelDescription(description);
    }
    onPanelClick(id);
  };

  const onSubmit = () => {
    setIsAnimate(true);
    if (isExpended) {
      onPanelClick(id);
    }
    setTimeout(() => {
      setIsAnimate(false);
      onSubmitClick(id, reasonState, panelDescription);
    }, ANIMATION_DURATION);
  };

  const onItemClick = () => {
    if (isReasonDescriptionMandatory) {
      onPanelClick(id);
    } else if (!isExpended) {
      onSubmit();
    }
  };

  const renderIcon = useCallback(() => {
    if (isExpended) {
      return <UpArrow />;
    }

    if (isActive) {
      return <WhiteMessageIcon />;
    }

    return <MessageIcon />;
  }, [isExpended, isOptionSelected, isAnimate]);

  return (
    <div className="panel-container" id={`reason-panel-${id}`}>
      <div className={`${cn('panel-label-container', { active: isActive })}`}>
        <button className="panel-label" onClick={onItemClick} disabled={isAnimate}>
          {label}
        </button>
        <button
          className={cn('panel-icon-container', {
            no_border: isReasonDescriptionMandatory,
          })}
          disabled={isAnimate}
          onClick={onClickMessage}
        >
          {renderIcon()}
        </button>
      </div>
      <div className={`${cn('panel-inner', { 'open-panel': isExpended })}`}>
        <p className="panel-content">
          <textarea
            className="panel-input"
            placeholder={t('COMMON.ENTER_TEXT')}
            onChange={onTextChange}
            value={panelDescription}
          />
        </p>
        <div className="panel-buttons-container">
          <button
            className="submit-button"
            id="reason-submit-button"
            onClick={onSubmit}
            disabled={disableButtons}
          >
            {t('COMMON.SUBMIT')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Panel;
