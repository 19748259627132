import { FC } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { CloseIcon, FilterIcon, OpportunitiesIcon } from 'components/Icons';
import './activeOptionMenu.scss';
import { IActiveOptionMenu } from 'types';

const ActiveOptionMenu: FC<IActiveOptionMenu> = ({
  onClose,
  isActive,
  onShowOpportunities,
  onFilter,
  name,
}) => {
  const [t] = useTranslation();

  return (
    <div data-testid="options-bar" className={cn('options-bar', { active: isActive })}>
      <div className="filter-container" onClick={onFilter}>
        <FilterIcon />
        <span className="filter">{t('COMMON.FILTER')}</span>
        <span className="label">{name}</span>
      </div>
      <div className="show-opportunities" onClick={onShowOpportunities}>
        <OpportunitiesIcon />
        <span className="text">{t('DRILL_DOWNS.SHOW_OPPORTUNITIES')}</span>
      </div>
      <div className="close-icon-container">
        <CloseIcon onClick={onClose} title="close-icon" />
      </div>
    </div>
  );
};

export default ActiveOptionMenu;
