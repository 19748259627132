import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import './openOpportunitiesCard.scss';
import { animationType, OpenOpportunitiesCardTypes } from 'types';
import { ProgressAnimation } from 'components/animation';
import { getCardDecoration } from 'components/openOpportunities/helpers';
import { IOpenOpportunitiesCardProps } from './types';

const OpenOpportunitiesCard: FC<IOpenOpportunitiesCardProps> = ({
  type,
  value,
  onCardClick,
  isSelected,
  symbol,
  isLoading = false,
}) => {
  const [t] = useTranslation();

  const { className, icon } = getCardDecoration(type);

  const isDisabledCard =
    value === 0 &&
    type !== OpenOpportunitiesCardTypes.RESPONSE_RATE &&
    type !== OpenOpportunitiesCardTypes.OPEN_OPPORTUNITIES;

  const onClick = useCallback(() => {
    onCardClick(type);
  }, [type, onCardClick]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={cn('open-opportunity-card-wrapper', [className], {
        active: isSelected,
        disabled: isDisabledCard,
      })}
      onClick={onClick}
    >
      <span className="card-text">{t(`OPEN_OPPORTUNITIES.${type}`)}</span>
      <ProgressAnimation
        className="open-opportunities-card-spinner"
        animationType={animationType.Spinner}
        isLoading={isLoading}
      />
      {!isLoading && (
        <span className="card-value font-bold">
          {value}
          {symbol}
        </span>
      )}
      {!isLoading && icon}
    </div>
  );
};

export default memo(OpenOpportunitiesCard);
