import { FC } from 'react';

import { ICheckbox } from 'types';
import './checkbox.scss';

export const Checkbox: FC<ICheckbox> = ({ isChecked, onClick }) => {
  return (
    <label className={`c-radio-item ${isChecked ? 'checked' : ''}`}>
      <input type="radio" defaultChecked={isChecked} onClick={onClick} />
      <span className="c-check-mark" />
    </label>
  );
};
