import { FC } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { direction, IDrillDownHeader, IFilterItem } from 'types';
import './drillDownHeader.scss';
import { UpArrow } from 'components/Icons';
import Breadcrumb from 'components/breadcrumb/Breadcrumb';
import HeaderFilters from 'components/drilldown/header/headerFilters/HeaderFilters';
import { FilterButton } from 'components/buttons/filterButton/filterButton';
import { OrderButton } from 'components/buttons';
import { CheckboxField } from 'components/checkbox';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const DrillDownHeader: FC<IDrillDownHeader> = ({
  title,
  subTitle,
  hierarchyPath,
  orderDirection,
  onHierarchyPathClick,
  onToggleDirection,
  filters,
  isActive,
  onRemoveFilter,
  onSearchChange,
  isTitleClickable,
  onTitleClick,
  isFiltersVisible,
  shouldDisplayFreeText,
  onFreeTextToggle,
  isFreeTextOnly,
  isRtl,
}) => {
  const [t] = useTranslation();

  const handleRemoveFilter = (filter: IFilterItem) => {
    AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.DRILL_DOWN_FILTER_REMOVE, {
      filter_name: filter.name,
    });
    onRemoveFilter(filter);
  };

  const handleHierarchyPathClick = (index: number) => {
    onHierarchyPathClick(index);
    AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.DRILL_DOWN_FILTER, {
      filter_name: hierarchyPath[index].name,
    });
  };

  const handleToggleDirection = () => {
    onToggleDirection();
    AnalyticSrv.sendSortEvent(
      true,
      title,
      orderDirection === direction.DESC ? direction.ASC : direction.DESC,
    );
  };

  const Filters = filters.map((filter) => (
    <FilterButton
      key={filter.name}
      label={filter.name}
      id={filter.hierarchyName}
      onRemoveFilter={() => handleRemoveFilter(filter)}
    />
  ));

  const breadcrumbItems = hierarchyPath.map((hierarchy) => hierarchy.name);

  return (
    <div className="drill-down-header-container">
      <div
        data-testid="drill-down-header"
        className={cn('drill-down-top-container', { active: isActive })}
      >
        <div className="text-button-container">
          <div className="text-container">
            <div className={cn('title-container', { clickable: isTitleClickable })}>
              {t('COMMON.TOP')}&nbsp;
              <div onClick={onTitleClick} className="title">
                {title}
                &nbsp;
                {isTitleClickable && <UpArrow />}
              </div>
            </div>
            <span className="sub-title">{subTitle}</span>
          </div>
          <OrderButton
            id="drill-down"
            label=""
            buttonKey="drill-down"
            onClick={handleToggleDirection}
            sortDirection={orderDirection}
          />
        </div>
        <div className="bread-crumb-free-text-container">
          <div className="bread-crumb-container">
            {isFiltersVisible && (
              <div data-testid="filters" className="filters-container">
                {Filters}
              </div>
            )}
            <Breadcrumb
              separator=">"
              onItemClick={handleHierarchyPathClick}
              items={breadcrumbItems}
            />
          </div>
          <div
            data-testid="free-text"
            className={cn('free-text-container', {
              hidden: !shouldDisplayFreeText,
            })}
          >
            <CheckboxField
              id="checkbox-field-drill-down"
              label={t('DRILL_DOWNS.SHOW_FREE_TEXT_ONLY')}
              isChecked={isFreeTextOnly}
              onChange={onFreeTextToggle}
            />
          </div>
        </div>
      </div>
      <HeaderFilters
        isVisible={isActive}
        key={`${isActive ? '1' : '2'}-${title}`}
        onSearchChange={onSearchChange}
        onRemoveFilter={onRemoveFilter}
        filters={filters}
        isRtl={isRtl}
      />
    </div>
  );
};

export default DrillDownHeader;
