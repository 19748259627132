import cn from 'clsx';
import { FC } from 'react';

import { SortAscIcon, SortDescIcon } from 'components/Icons';
import 'components/buttons/orderButton/orderButton.scss';
import { direction } from 'store/filters/types';

export interface SortButtonProps {
  /**
   * button id
   */
  id: string;
  /**
   * Is this button selected
   */
  isSelected?: boolean;
  /**
   * What is the sort direction
   */
  sortDirection?: direction;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: (key: string) => void;
  /**
   * Optional click handler
   */
  tabIndex?: number;
  /**
   * Sorting key
   */
  buttonKey: string;
}

/**
 * Primary UI component for user interaction
 */
export const OrderButton: FC<SortButtonProps> = ({
  isSelected,
  label,
  id,
  onClick,
  tabIndex,
  sortDirection,
  buttonKey,
  ...props
}) => {
  const onButtonClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (onClick) {
      onClick(buttonKey);
    }
  };

  const renderIcon = () => {
    if (sortDirection === undefined) {
      return null;
    }

    return sortDirection === direction.DESC ? <SortDescIcon /> : <SortAscIcon />;
  };

  return (
    <div
      id={`sort-button-${id}`}
      role="button"
      tabIndex={tabIndex}
      className={cn('sort-button', 'no-selectable', isSelected && 'selected')}
      onClick={onButtonClick}
    >
      <button type="button" className={cn('button', isSelected && 'selected')} {...props}>
        <span className="button-text">{label}</span>
        {renderIcon()}
      </button>
    </div>
  );
};
