import { IActionType } from 'types';
import FindingsActionTypes from './findingsActionTypes';
import {
  getHierarchyDrillDownData,
  getOpportunities,
  getProducts,
  getStores,
} from './findingsActions';
import { buildDrillDownFilter } from './findingsHelper';
import { shouldGetProducts, shouldGetStores, getHierarchyEndpoint } from 'store/helpers/drillDown';

const findingsMiddleware = ({ dispatch, getState }: any) => (next: any) => (
  action: IActionType,
) => {
  const { type, payload } = action;

  switch (type) {
    case FindingsActionTypes.getDrillDown: {
      const {
        drillDownType,
        selectedCategory,
        pageOffset,
        productHierarchyDepth,
        storeHierarchyDepth,
      } = payload;

      const { filters } = getState().findings;

      const { lastUserDeployDate } = getState().userProfile;

      const {
        mutualFilters: { hierarchyIndex },
        activeFilters: { isOpportunitySelected },
      } = filters[drillDownType];

      const filter = buildDrillDownFilter(
        filters,
        drillDownType,
        storeHierarchyDepth,
        productHierarchyDepth,
        pageOffset,
        lastUserDeployDate,
        selectedCategory,
      );

      const shouldGetOpportunities = isOpportunitySelected;

      const isStoreLeaf = hierarchyIndex > storeHierarchyDepth;

      const isProductLeaf = hierarchyIndex > productHierarchyDepth;

      const shouldGetStore = shouldGetStores(drillDownType, selectedCategory, isStoreLeaf);

      const shouldGetProduct = shouldGetProducts(drillDownType, selectedCategory, isProductLeaf);

      if (shouldGetOpportunities) {
        const { productImagesMetadata } = getState().settings.general;
        dispatch(getOpportunities(filter, drillDownType, pageOffset, productImagesMetadata || []));
      } else if (shouldGetProduct) {
        dispatch(getProducts(filter, drillDownType, pageOffset));
      } else if (shouldGetStore) {
        dispatch(getStores(filter, drillDownType, pageOffset));
      } else {
        // hierarchy or category need to get drill down data
        const endpoint = getHierarchyEndpoint(drillDownType, hierarchyIndex, selectedCategory);
        dispatch(getHierarchyDrillDownData(filter, drillDownType, pageOffset, endpoint));
      }

      break;
    }

    default:
      break;
  }
  next(action);
};

export default findingsMiddleware;
