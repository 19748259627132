export interface ILanguage {
  label: string;
  key: string;
}

export const languages: Array<ILanguage> = [
  {
    label: 'English',
    key: 'en-US',
  },
  {
    label: 'עברית',
    key: 'he-IL',
  },
  {
    label: 'French',
    key: 'fr-CA',
  },
];
