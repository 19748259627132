import { FC, useState } from 'react';
import {
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

import { getShortDateFormat } from 'utils';
import { IOpportunitiesSalesChart } from 'types';
import NumberUtils from 'utils/NumberUtils';

import './opportunitiesSalesChart.scss';
import colors from 'styles/_colors.module.scss';

const CHART_HEIGHT = 220;

const AXIS_STROKE_WIDTH = 0.2;

const AXIS_PADDING = 20;

const SIMILAR_STORES_ID = 'similarStores';

const YOUR_STORES_ID = 'yourStores';

const OpportunitiesSalesChart: FC<IOpportunitiesSalesChart> = ({
  lastSalesValues,
  lastSimilarSalesValues,
  currency,
  isUnitsValues = false,
  isRtl,
}) => {
  const [t] = useTranslation();

  const [linesOpacity, setLinesOpacity] = useState<any>({
    similarStores: 1,
    yourStores: 1,
  });

  const formatXAxis = (tickItem: number) => getShortDateFormat(tickItem);

  const ToolTipComponent: FC<any> = ({ active, payload }) => {
    const isToolTipVisible = active && payload![0]?.payload && payload![1]?.payload;

    if (isToolTipVisible) {
      const { value: saleAmount, date } = payload[0].payload;

      const { value: saleAmount1 } = payload[1].payload;

      const formattedSaleAmount = isUnitsValues
        ? saleAmount
        : NumberUtils.toCurrency(saleAmount, currency);

      const formattedSaleAmount1 = isUnitsValues
        ? saleAmount1
        : NumberUtils.toCurrency(saleAmount1, currency);

      return (
        <div className="tooltip">
          <b>{getShortDateFormat(date)}</b>
          <span>
            {t('MY_OPPORTUNITIES.YOUR_STORE')}: <b>{formattedSaleAmount}</b>
          </span>
          <span>
            {t('MY_OPPORTUNITIES.COMPARABLE_STORES')}: <b>{formattedSaleAmount1}</b>
          </span>
        </div>
      );
    }

    return null;
  };

  const Square = (props: any) => {
    const { cx, cy, key } = props;

    return (
      <rect
        key={key}
        opacity={linesOpacity[SIMILAR_STORES_ID]}
        x={cx - 5}
        y={cy - 5}
        className="square-dot"
      />
    );
  };

  const onLegendMouseEnter = (o: any) => {
    const { id } = o;

    const otherId = id === SIMILAR_STORES_ID ? YOUR_STORES_ID : SIMILAR_STORES_ID;

    setLinesOpacity({ [id]: 1, [otherId]: 0.2 });
  };

  const onLegendMouseLeave = () => {
    setLinesOpacity({ similarStores: 1, yourStores: 1 });
  };

  const LineLabel = (payload: any) => {
    const { x, y, value, index } = payload;

    let dy = -10;
    let textAnchor = 'start';

    const laseSimilarSalesValue = lastSimilarSalesValues![index]?.value || 0;

    const laseSalesValue = lastSalesValues![index]?.value || 0;

    // Check if the current value is the lower one
    if (value < laseSimilarSalesValue || value < laseSalesValue) {
      dy = 15;
      textAnchor = 'end';
    }

    return (
      <text x={x} y={y} dy={dy} textAnchor={textAnchor}>
        {!isUnitsValues && '$'}
        {Math.round(value)}
      </text>
    );
  };

  return (
    <div className="opportunities-sales-chart">
      <span className="opportunities-chart-title">{t('MY_OPPORTUNITIES.LAST4WEEKS')}</span>
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <LineChart>
          <Legend
            onMouseLeave={onLegendMouseLeave}
            onMouseEnter={onLegendMouseEnter}
            iconSize={10}
            payload={[
              {
                id: SIMILAR_STORES_ID,
                color: colors.secondarySummerGreen,
                value: t('MY_OPPORTUNITIES.COMPARABLE_STORES'),
                type: 'square',
              },
              {
                id: YOUR_STORES_ID,
                color: colors.secondaryRegalBlue,
                value: t('MY_OPPORTUNITIES.YOUR_STORE'),
              },
            ]}
            verticalAlign="top"
            layout="vertical"
            align={isRtl ? 'left' : 'right'}
          />

          <Line
            opacity={linesOpacity[YOUR_STORES_ID]}
            activeDot={false}
            isAnimationActive={false}
            id={YOUR_STORES_ID}
            className="sales-line"
            data={lastSalesValues}
            type="linear"
            dataKey="value"
          >
            <LabelList content={<LineLabel />} />
          </Line>
          <Line
            opacity={linesOpacity[SIMILAR_STORES_ID]}
            id={SIMILAR_STORES_ID}
            activeDot={false}
            dot={Square}
            isAnimationActive={false}
            className="similar-sales-line"
            data={lastSimilarSalesValues}
            type="linear"
            dataKey="value"
          >
            <LabelList content={<LineLabel />} />
          </Line>
          <XAxis
            padding={{ left: AXIS_PADDING, right: AXIS_PADDING }}
            allowDuplicatedCategory={false}
            strokeWidth={AXIS_STROKE_WIDTH}
            tickLine={false}
            reversed={isRtl}
            tickFormatter={formatXAxis}
            dataKey="date"
          />
          <YAxis
            width={20}
            orientation={isRtl ? 'right' : 'left'}
            padding={{ top: AXIS_PADDING, bottom: AXIS_PADDING }}
            strokeWidth={AXIS_STROKE_WIDTH}
            tick={false}
            tickLine={false}
          />
          <Tooltip cursor={false} content={<ToolTipComponent />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OpportunitiesSalesChart;
