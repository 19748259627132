import { createSelector } from 'reselect';

import {
  categoryLabelsMapper,
  categoryMapper,
  IFilterCategoryLabels,
  IGroupByOption,
  IGroupBySource,
  IRootState,
  majorCategory,
  sortAndGroupDefaultInitState,
} from 'store/types';
import { getObjectValues, isEmptyArray, parseObjToOrderedList } from 'utils';
import { constructGroupByOptions } from './filterHelper';
import permissionsService from 'services/permissions/permissionsSrv';

const getMyOpportunityFiltersSelector = (state: IRootState) =>
  state.filters.myOpportunities.activeFilter;

const getFiltersSelector = (state: IRootState) => state.filters.myOpportunities.filters;

const getProductCategoriesSelector = (state: IRootState) => state.settings.productCategories;

const getProductHierarchySelector = (state: IRootState) => state.settings.productHierarchy;

const getMyOpportunitiesFilterSelector = createSelector(
  [getMyOpportunityFiltersSelector],
  (getMyOpportunityFiltersSelector) => {
    return getMyOpportunityFiltersSelector;
  },
);

const getMyOpportunitiesMainCategoriesSelector = createSelector([getFiltersSelector], (filters) => {
  const mainCategories = getObjectValues(majorCategory);

  const isSingleStoreManager = permissionsService.isSingleStoreManager();

  // filter empty categories
  const filteredMainCategories = mainCategories.filter((category) => {
    const isStoreCategory = category === majorCategory.STORES;

    const showStoreNames =
      isStoreCategory && filters[categoryLabelsMapper.STORE_NAME][0].labels.length > 0;

    const shouldHideStores = isSingleStoreManager && isStoreCategory;

    const showFilterCategories = filters[categoryMapper[category]].length > 0;

    return !shouldHideStores && (showStoreNames || showFilterCategories);
  });

  return filteredMainCategories;
});

const getMyOpportunitiesGroupByOptionsSelector = createSelector(
  [getProductCategoriesSelector, getProductHierarchySelector],
  (productCategories, productHierarchyMap) => {
    const productHierarchy = parseObjToOrderedList(productHierarchyMap);

    const isProductCategoriesAvailable = !isEmptyArray(productCategories);

    const isProductHierarchyAvailable = !isEmptyArray(productHierarchy);

    let groupByOptions: IGroupByOption[] = [];

    if (isProductHierarchyAvailable) {
      groupByOptions = constructGroupByOptions(IGroupBySource.PRODUCT_HIERARCHY, productHierarchy);
    } else if (isProductCategoriesAvailable) {
      groupByOptions = constructGroupByOptions(IGroupBySource.PRODUCT_CATEGORY, productCategories);
    } else {
      groupByOptions = constructGroupByOptions(IGroupBySource.DEFAULT);
    }

    // get top 3 options
    if (groupByOptions.length > 3) {
      groupByOptions = groupByOptions.slice(0, 3);
    }

    return groupByOptions;
  },
);

const getMyOpportunitiesFilterList = createSelector(
  [getMyOpportunitiesFilterSelector],
  (getMyOpportunitiesFilterSelector) => {
    const {
      productCategory,
      storeCategory,
      storeName,
      planogram,
    } = getMyOpportunitiesFilterSelector;

    const FilterList: Array<string> = [];

    productCategory.forEach(
      (category) =>
        category &&
        !isEmptyArray(category.selectedLabels) &&
        FilterList.push(category.selectedLabels.toString()),
    );

    storeCategory.forEach(
      (category) =>
        category &&
        !isEmptyArray(category.selectedLabels) &&
        FilterList.push(category.selectedLabels.toString()),
    );

    storeName.forEach(
      (category) =>
        category &&
        !isEmptyArray(category.selectedLabels) &&
        FilterList.push(category.selectedLabels.toString()),
    );

    planogram.forEach(
      (category) =>
        category &&
        !isEmptyArray(category.selectedLabels) &&
        FilterList.push(category.selectedLabels.toString()),
    );

    return FilterList;
  },
);

const checkIsOptionSelected = (options: IFilterCategoryLabels) => {
  const selectedLabelsAmount = options
    .filter((option) => option !== undefined)
    .reduce((acc, value) => acc + value.selectedLabels.length, 0);

  return selectedLabelsAmount > 0;
};

const isMyOpportunitiesFilterOn = createSelector(
  [getMyOpportunityFiltersSelector],
  (getMyOpportunityFiltersSelector) => {
    const {
      productCategory,
      storeCategory,
      storeName,
      planogram,
      orderBy,
      groupBy,
      direction,
    } = getMyOpportunityFiltersSelector;

    const isGroupByFilterChanged = groupBy.key !== sortAndGroupDefaultInitState.groupBy.key;

    const isOrderByFilterChanged = orderBy !== sortAndGroupDefaultInitState.orderBy;

    const isDirectionChanged = direction !== sortAndGroupDefaultInitState.direction[orderBy];

    const isProductCategorySelected = checkIsOptionSelected(productCategory);

    const isStoreCategorySelected = checkIsOptionSelected(storeCategory);

    const isStoreSelected = checkIsOptionSelected(storeName);

    const isPlanogramSelected = checkIsOptionSelected(planogram);

    return (
      isProductCategorySelected ||
      isStoreCategorySelected ||
      isStoreSelected ||
      isGroupByFilterChanged ||
      isOrderByFilterChanged ||
      isPlanogramSelected ||
      isDirectionChanged
    );
  },
);

const FiltersSelector = {
  getMyOpportunitiesFilterSelector,
  isMyOpportunitiesFilterOn,
  getMyOpportunitiesFilterList,
  getMyOpportunitiesGroupByOptionsSelector,
  getMyOpportunitiesMainCategoriesSelector,
};

export default FiltersSelector;
