import { FC } from 'react';

import { emptyStateAnimationType } from 'components/animation/types';

export type EmptyStateProps = {
  title: string;
  text?: string;
  clickableText?: string;
  onClick?: () => void;
  icon: FC;
  theme?: EmptyStateThemes;
  dataTestId?: string;
};

export type EmptyStateWithAnimationProps = {
  title?: string;
  subTitle: string;
  footerLink?: string;
  footerTitle?: string;
  onClickFooterLink?: () => void;
  animationType: emptyStateAnimationType;
  dataTestId?: string;
};

export enum EmptyStateThemes {
  Dark = 'dark',
  Light = 'light',
}
