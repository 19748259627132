import { createRef, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';
import { debounce } from 'lodash';

import DrillDownHeader from './header/DrillDownHeader';
import DrillDownList from './drillDownList/DrillDownList';
import './drillDown.scss';
import { IDrillDownProps } from 'types';
import OpportunitiesList from './opportunities/OpportunitiesList';
import { UpArrow } from 'components/Icons';
import DrillDownEmptyState from './DrillDownEmptyState';

const DEBOUNCE_SEARCH_DELAY = 500;

const DrillDown: FC<IDrillDownProps> = ({
  id,
  isLastHierarchyLevel,
  list,
  isLoading,
  totalCount,
  filters,
  hierarchyPath,
  order,
  isExpended,
  onLoadMore,
  isOpportunitySelected,
  onRemoveFilter,
  onToggleDirection,
  onHierarchyPathClick,
  onSearchChange,
  onExpendedToggle,
  isFiltersVisible,
  onFilterClick,
  isTitleClickable,
  hierarchyName,
  onShowOpportunitiesClick,
  onTitleClick,
  opportunities,
  isFreeTextOnly,
  onFreeTextToggle,
  isHidden,
  onOpportunityClick,
  isOptionBarEnable,
  shouldDisplayTotalAndPercent,
  shouldDisplayShortCurrency,
  currency,
  headerSubTitle,
  isNumericData,
  highestValue,
  searchText,
  isFiltersApplied,
  isRtl,
}) => {
  const [t] = useTranslation();

  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (isExpended) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpended]);

  const text = isExpended ? t('COMMON.CLOSE') : `${t('COMMON.SEE_ALL')} ${hierarchyName}`;

  const onSearch = debounce((text: string) => {
    onSearchChange(text);
  }, DEBOUNCE_SEARCH_DELAY);

  const EmptyState = (
    <DrillDownEmptyState searchText={searchText} isFiltersApplied={isFiltersApplied} />
  );

  return (
    <div
      id={id}
      ref={containerRef}
      data-testid="drill-downֹֹֹֹֹֹ"
      className={cn('drill-down', { expended: isExpended }, { hidden: isHidden })}
    >
      <div className="drill-down-inner">
        <DrillDownHeader
          onFreeTextToggle={onFreeTextToggle}
          shouldDisplayFreeText={isOpportunitySelected}
          isFreeTextOnly={isFreeTextOnly}
          title={hierarchyName}
          subTitle={headerSubTitle}
          isActive={isExpended}
          isFiltersVisible={isFiltersVisible}
          hierarchyPath={hierarchyPath}
          orderDirection={order}
          isTitleClickable={isTitleClickable}
          filters={filters}
          onTitleClick={onTitleClick}
          onToggleDirection={onToggleDirection}
          onRemoveFilter={onRemoveFilter}
          onHierarchyPathClick={onHierarchyPathClick}
          onSearchChange={onSearch}
          isRtl={isRtl}
        />
        <div className={cn('drill-down-list-container', { expended: isExpended })}>
          {isOpportunitySelected && (
            <OpportunitiesList
              isLoading={isLoading}
              totalCount={totalCount}
              emptyState={EmptyState}
              list={opportunities?.list || null}
              opportunitiesGroupContent={opportunities?.groupContent}
              opportunitiesGroupCount={opportunities?.groupCount}
              onOpportunityClick={onOpportunityClick}
              onLoadMore={onLoadMore}
            />
          )}
          {!isOpportunitySelected && (
            <DrillDownList
              isNumericData={isNumericData}
              highestValue={highestValue}
              currency={currency}
              shouldDisplayTotalAndPercent={shouldDisplayTotalAndPercent}
              shouldDisplayShortCurrency={shouldDisplayShortCurrency}
              isOptionBarEnable={isOptionBarEnable}
              emptyState={EmptyState}
              list={list}
              isLoading={isLoading}
              isLastHierarchyLevel={isLastHierarchyLevel}
              totalCount={totalCount}
              hierarchyName={hierarchyName}
              onLoadMore={onLoadMore}
              onFilterClick={onFilterClick}
              onShowOpportunitiesClick={onShowOpportunitiesClick}
            />
          )}
        </div>
        <div
          id={`${id}-footer-button`}
          data-testid="footer"
          onClick={onExpendedToggle}
          className={cn('footer-button', { expended: isExpended })}
        >
          {text}
          <UpArrow />
        </div>
      </div>
    </div>
  );
};

export default DrillDown;
