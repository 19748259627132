import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import { IDrillDownOpportunityProps } from 'types';
import './opportunityCard.scss';
import { DecoratedText } from 'components/text';
import { AddressIcon, CategoryIcon } from 'components/Icons';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const OpportunityCard: FC<IDrillDownOpportunityProps> = ({
  productSku,
  id,
  reason,
  description,
  productName,
  productCategories,
  storeName,
  isExpired,
  onOpportunityClick,
}) => {
  const [t] = useTranslation();

  const onClick = useCallback(() => {
    onOpportunityClick(id);
    AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.DRILL_DOWN_OPPORTUNITY_DETAILS, {
      opportunity_id: id,
    });
  }, [id]);

  return (
    <div onClick={onClick} data-testid="opportunity-card" className="drill-down-opportunity-card">
      <div className="drill-down-content">
        <div className="header">
          <span className="title">{productName}</span>
          <span data-testid="expired-label" className={cn('expired', { visible: isExpired })}>
            {t('MY_OPPORTUNITIES.OPPORTUNITY_EXPIRED')}
          </span>
        </div>
        <div className="card-id">
          {`${t('MY_OPPORTUNITIES.ITEM_ID')}: `}
          <div className="card-id-text">{productSku}</div>
        </div>
        <div data-testid="drill-down-footer" className="drill-down-content-footer">
          <DecoratedText
            containerClass="card-footer-category"
            textClass="card-footer-text"
            icon={<CategoryIcon />}
            text={productCategories![0]}
            isHidden={!productCategories![0]}
          />
          <DecoratedText
            containerClass="card-footer-category"
            textClass="card-footer-text store-text"
            icon={<AddressIcon />}
            text={storeName}
            isHidden={!storeName}
          />
        </div>
      </div>
      <div className="reason-code-footer">
        <span className="reason">{reason}</span>
        <span className="description">{description}</span>
      </div>
    </div>
  );
};

export default OpportunityCard;
