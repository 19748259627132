import { set } from 'lodash/fp';

import i18n from 'i18n/i18n';
import {
  direction,
  DrillDownCategoriesTypes,
  DrillDownTypes,
  IDrillDownOpportunities,
  IDrillDownOpportunity,
  IDrillDownOpportunitySelectedReason,
  IDrillDownRow,
  IOpportunitiesModel,
  ISelectedCategory,
  ResponseTypes,
} from 'types';
import { SIZE } from 'constants/constantsVaribles';
import { mapCategoriesTypeToCategory, mapDrillDownToHierarchyName } from 'constants/hierarchy';
import { getDateAndTimeFormat } from 'utils';

export const mapOpportunityProductCategories = (
  productCategories: Array<string>,
  productCategoriesValues: { [key: string]: string },
): Array<{ name: string; value: string }> => {
  if (!productCategories) {
    return [];
  }

  if (!productCategoriesValues) {
    return productCategories.map(() => ({
      name: '',
      value: '',
    }));
  }

  return Object.keys(productCategories).map((item) => ({
    name: productCategories[item],
    value: productCategoriesValues[item],
  }));
};

export const shouldGetProducts = (
  drillDownType: DrillDownTypes,
  selectedCategory: ISelectedCategory,
  isLeaf: boolean,
) => {
  const isProductDrillDown = drillDownType === DrillDownTypes.Product;

  const isOtherDrillDown = drillDownType === DrillDownTypes.Other;

  const isProductSelectedCategory =
    isOtherDrillDown && selectedCategory?.type === DrillDownCategoriesTypes.Product;

  return (isProductDrillDown && isLeaf) || isProductSelectedCategory;
};

export const buildStateToPushPostDrillDownRequest = (
  drillDownType: DrillDownTypes,
  pageOffset: number,
  currentList: Array<IDrillDownRow> | null,
  responseList: Array<IDrillDownRow>,
  isExpended: boolean,
  responseTotalCount: number,
  orderDirection: direction,
) => {
  const prevList = pageOffset === 0 ? [] : currentList || [];

  let nextPageOffset = pageOffset;

  const drillDownList = [...prevList, ...responseList];

  const totalCount = isExpended ? responseTotalCount : drillDownList.length;

  const hasMore = drillDownList.length < totalCount;

  let highestValueIndex = 0;

  if (orderDirection === direction.ASC) {
    highestValueIndex = drillDownList.length - 1;
  }

  const highestValue = drillDownList[highestValueIndex]?.count || 1;

  if (isExpended && hasMore) {
    nextPageOffset += SIZE;
  }

  return [
    set(`${drillDownType}.list`, drillDownList),
    set(`${drillDownType}.totalCount`, totalCount),
    set(`${drillDownType}.isLoading`, false),
    set(`${drillDownType}.highestValue`, highestValue),
    set(`filters[${drillDownType}].hasMore`, hasMore),
    set(`filters[${drillDownType}].nextPageOffset`, nextPageOffset),
  ];
};

export const shouldGetStores = (
  drillDownType: DrillDownTypes,
  selectedCategory: ISelectedCategory,
  isLeaf: boolean,
) => {
  const isStoreDrillDown = drillDownType === DrillDownTypes.Store;

  const isOtherDrillDown = drillDownType === DrillDownTypes.Other;

  const isStoreSelectedCategory =
    isOtherDrillDown && selectedCategory?.type === DrillDownCategoriesTypes.Store;

  return (isStoreDrillDown && isLeaf) || isStoreSelectedCategory;
};

export const checkIsLeaf = (hierarchyIndex: number, hierarchyDepth: number) =>
  hierarchyIndex > hierarchyDepth;

export const getHierarchyEndpoint = (
  drillDownType: DrillDownTypes,
  hierarchyIndex: number,
  selectedCategory: ISelectedCategory,
) => {
  let endpoint;
  if (drillDownType === DrillDownTypes.Other) {
    const { value, type } = selectedCategory;
    endpoint = `${mapCategoriesTypeToCategory[type]}/${value}`;
  } else {
    endpoint = `${mapDrillDownToHierarchyName[drillDownType]}/LEVEL${hierarchyIndex}`;
  }

  return endpoint;
};

export const getProductCategories = (
  selectedOpportunity: IDrillDownOpportunity | null,
  settingsProductCategories: Array<string>,
) => {
  if (!selectedOpportunity || !settingsProductCategories) {
    return [];
  }

  return mapOpportunityProductCategories(
    settingsProductCategories,
    selectedOpportunity.productCategories,
  );
};

export const getOpportunitiesDrillDown = (
  opportunities: IOpportunitiesModel | null,
  mapReasonsIdToText: object,
): IDrillDownOpportunities | null => {
  const { language } = i18n;

  let parsedOpportunities = null;
  if (opportunities) {
    const { list, groupContent, groupCount } = opportunities;

    const parsedList = list.map((opportunity) => {
      const {
        productName,
        lifecycle,
        storeName,
        lowResolutionProductImage: productImage,
        lastSalesValues,
        lastUnitsValues,
        targetSales,
        id,
        lastSalesValuesTotal,
        lastUnitsValuesTotal,
        lastSimilarSalesValues,
        lastSimilarUnitsValues,
        lastSimilarSalesValuesTotal,
        lastSimilarUnitsValuesTotal,
        productSkusAndSizes,
        productCategories,
        selectedReason,
        action,
        potentialRevenue,
        potentialUnitsSales,
        targetUnits,
        planogramAttributes,
        flavour,
      } = opportunity;

      const reason: IDrillDownOpportunitySelectedReason = {
        title: '',
        type: ResponseTypes.ISSUE_FOUND,
      };
      if (selectedReason?.reasonId) {
        const { reasonId, description: text, updateDate } = selectedReason;
        reason.description = text;
        reason.completedDate = getDateAndTimeFormat(updateDate);

        // get the selected reason text object (key in languages)
        const reasonTitle = mapReasonsIdToText[reasonId];

        if (reasonTitle) {
          reason.title = reasonTitle![language] || reasonTitle!['en-US'];
        }
      }

      return {
        productName,
        id,
        storeName,
        selectedReason: reason,
        productCategories,
        productSkusAndSizes,
        lifecycle,
        lastSalesValuesTotal,
        lastUnitsValuesTotal,
        lastSimilarSalesValues,
        lastSimilarUnitsValues,
        lastSimilarSalesValuesTotal,
        lastSimilarUnitsValuesTotal,
        productImage,
        lastSalesValues,
        lastUnitsValues,
        targetSales,
        action,
        potentialRevenue,
        potentialUnitsSales,
        targetUnits,
        planogramAttributes,
        flavour,
      };
    });
    parsedOpportunities = { list: parsedList, groupContent, groupCount };
  }

  return parsedOpportunities;
};
