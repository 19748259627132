import { ActionTypes, FlavourTypes, FreeTextOptions } from 'types';

export interface IReasonApi {
  flavour: FlavourTypes;
  action: ActionTypes;
  label: string;
  id: number;
  groupId: string;
  done: boolean;
  selectable: boolean;
  freeText: FreeTextOptions;
  minimalChars: number;
  text: { [key: string]: string };
}

export interface IReasonsApi {
  groups: { [key: string]: { order: number; text: { [key: string]: string } } };
  doneReasons: Array<IReasonApi>;
  undoneReasons: Array<IReasonApi>;
}

export interface IReasonModel {
  isReasonDescriptionMandatory: boolean;
  id: number;
  reasonState: ReasonState;
  minimumCharsCount: number;
  text: { [key: string]: string };
}

export interface IGroupedReasonsModel {
  options: Array<IReasonModel>;
  label: { [key: string]: string };
  order: number;
}

export type ReasonsGroups = {
  [key in ActionTypes]: Array<IGroupedReasonsModel>;
};

export interface IReasonsModel {
  groups: ReasonsGroups;
  mapReasonIdToText: {
    [key in number]: Object;
  };
}

export enum ReasonState {
  DONE = 'DONE',
  NOT_DONE = 'NOT_DONE',
}
