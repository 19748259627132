import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { EmptyState } from 'components/emptyState';
import {
  NoOpportunities,
  NoResultIcon,
  StoreGrayIcon,
  TrophyIcon,
  WelcomeIcon,
} from 'components/Icons';
import {
  direction,
  DrillDownTypes,
  EmptyStateProps,
  IDate,
  IDrillDownHierarchy,
  IRootState,
  ISearchParamsFilters,
  OpenOpportunitiesCardTypes,
  SelectedDateIntervals,
} from 'types';
import routerService from 'services/router/routerService';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';
import { getCurrentDate } from 'utils';
import { prepareHierarchyPathForQueryFilter } from 'store/openOpportunities/openOpportunitiesHelper';

type PreviewEmptyStateProps = {
  cardType: OpenOpportunitiesCardTypes;
  isStoreOpportunities: boolean;
  issuesFound?: number;
  searchText?: string;
  storeName?: string;
  weeksTimePeriod?: number;
  isNoOpportunities?: boolean;
  hierarchyFilter: IDrillDownHierarchy[];
  sortDirection: direction;
  haveOpportunitiesBeenDeployed: boolean;
};

export const PreviewEmptyState: FC<PreviewEmptyStateProps> = ({
  isStoreOpportunities,
  cardType,
  issuesFound = 0,
  searchText = '',
  storeName = '',
  weeksTimePeriod = 4,
  isNoOpportunities,
  hierarchyFilter = [],
  sortDirection = direction.DESC,
  haveOpportunitiesBeenDeployed,
}) => {
  const [t] = useTranslation();

  // if specific store selected and received no opportunities
  const isNoOpportunitiesReceived =
    isStoreOpportunities && cardType === OpenOpportunitiesCardTypes.NO_OPPORTUNITIES;

  // if no specific store selected and there are no opportunities
  const isNoOpportunitiesToShow = !isStoreOpportunities;

  // if no specific store selected and there are no opportunities because they all completed
  const isNoOpportunitiesToShowText =
    isNoOpportunitiesToShow && cardType !== OpenOpportunitiesCardTypes.NO_OPPORTUNITIES;

  const navigateToFindings = () => {
    const monthPeriod = weeksTimePeriod > 1 && weeksTimePeriod <= 4;

    const date: IDate = {
      type: monthPeriod ? SelectedDateIntervals.monthId : SelectedDateIntervals.lastDeployment,
      from: getCurrentDate(),
      to: getCurrentDate(),
    };

    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date,
      [DrillDownTypes.Store]: {
        hierarchyPath: hierarchyFilter,
        order: sortDirection,
      },
    };

    const params = buildQueryParamsFromSearchFilters(searchFilters);
    routerService.routeToFindings(params);
  };

  const getTimePeriodLabel = () => {
    return weeksTimePeriod === 1 ? t('DATES_FILTER.LAST_WEEK') : t('DATES_FILTER.LAST_MONTH');
  };

  const getEmptyStateDetails = () => {
    const details: EmptyStateProps = {
      title: t('OPEN_OPPORTUNITIES.EMPTY_STATE.NO_OPPORTUNITIES'),
      icon: NoOpportunities,
    };

    if (searchText) {
      details.title = t('DRILL_DOWNS.EMPTY_STATES.NO_RESULT_FOUND_SEARCH', { search: searchText });
      details.icon = NoResultIcon;
    } else if (!haveOpportunitiesBeenDeployed) {
      details.title = t('MY_OPPORTUNITIES.EMPTY_STATE.WELCOME');
      details.icon = WelcomeIcon;
      details.text = t('MY_OPPORTUNITIES.EMPTY_STATE.WELCOME_TEXT');
    } else if (isNoOpportunities) {
      details.text = t('OPEN_OPPORTUNITIES.EMPTY_STATE.NO_OPPORTUNITIES_TEXT');
    } else if (isNoOpportunitiesReceived) {
      details.title = t('OPEN_OPPORTUNITIES.EMPTY_STATE.NO_OPPORTUNITIES_RECEIVED');
      details.icon = StoreGrayIcon;
      details.text = t('OPEN_OPPORTUNITIES.EMPTY_STATE.NO_OPPORTUNITIES_RECEIVED_TEXT', {
        store: storeName,
      });
    } else if (isNoOpportunitiesToShow) {
      details.title = t('OPEN_OPPORTUNITIES.EMPTY_STATE.NO_OPPORTUNITIES_TO_SHOW');
      details.icon = NoResultIcon;
      details.clickableText = isNoOpportunitiesToShowText
        ? `${t('OPEN_OPPORTUNITIES.EMPTY_STATE.CLICKABLE_TEXT', {
            number: issuesFound,
          })} ${getTimePeriodLabel()}`
        : '';
    } else {
      details.title = t('OPEN_OPPORTUNITIES.EMPTY_STATE.ALL_INVESTIGATED');
      details.icon = TrophyIcon;
      details.clickableText = `${t('OPEN_OPPORTUNITIES.EMPTY_STATE.CLICKABLE_TEXT', {
        number: issuesFound,
      })} ${getTimePeriodLabel()}`;
      details.text = t('OPEN_OPPORTUNITIES.EMPTY_STATE.ALL_INVESTIGATED_TEXT', {
        store: storeName,
      });
    }

    return details;
  };

  const details = getEmptyStateDetails();

  return (
    <EmptyState
      title={details.title}
      icon={details.icon}
      clickableText={details.clickableText}
      text={details.text}
      onClick={navigateToFindings}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { hierarchyPath, filters } = state.openOpportunities.drillDown;

  const { sortDirection } = filters;

  const hierarchyFilter = prepareHierarchyPathForQueryFilter(hierarchyPath);

  return {
    hierarchyFilter,
    sortDirection,
  };
};

export default connect(mapStateToProps)(memo(PreviewEmptyState));
