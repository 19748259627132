import { getObjectKeys } from 'utils';

export const UPS_REST_PATH: string = '/unifiedpush-server/rest';

export const BIND_WITH_SSO: string = '/registry/type/bindWithSSO';

const EXT_BASE_URL = {
  auto: 'https://',
  qa1release: 'https://',
  qa1snapshot: 'https://',
  qa3release: 'https://',
  qa4release: 'https://',
  staging: 'https://',
  production: 'https://',
};

// contracts prefixes
const BASE_URL: { [env: string]: string } = {
  browserBaseUrl:
    window && window.location && window.location.host && window.location.protocol
      ? `${window.location.protocol}//${window.location.host}`
      : '',
  dev1: 'https://dev1-staging-mcs.c-b4.com',
  dev3: 'https://dev3-staging-mcs.c-b4.com',
  'web-dev1': 'https://web-dev1-staging-mcs.c-b4.com',
  'web-dev3': 'https://web-dev3-staging-mcs.c-b4.com',
  qa3automation: 'https://qa3automation-staging-wcs.c-b4.com',
  qa1release: 'https://qa1release-staging-wcs.c-b4.com',
  qa2release: 'https://qa2release-staging-wcs.c-b4.com',
  qa3release: 'https://qa3release-staging-wcs.c-b4.com',
  qa4release: 'https://qa4release-staging-wcs.c-b4.com',
  qa1snapshot: 'https://qa1snapshot-staging-wcs.c-b4.com',
  qa2snapshot: 'https://qa1snapshot-staging-wcs.c-b4.com',
  qa3snapshot: 'https://qa3snapshot-staging-wcs.c-b4.com',
  manual: 'https://qa4release-staging-wcs.c-b4.com', // TODO make text input
  qa4snapshot: 'https://qa4snapshot-staging-wcs.c-b4.com/',
  cb4mart: 'https://cbmart-mcs.c-b4.com',
  qapocrelease: 'https://qapocrelease-staging-wcs.c-b4.com',
  qa1desktop: 'https://qa1desktop-staging-wcs.c-b4.com',
  demo: 'https://dashboard2-demo-mcs.c-b4.com',
  gap: 'https://gap-mcs.c-b4.com',
};

export const ENVIRONMENTS = getObjectKeys(BASE_URL);

export const getBaseUrl: any = (env: string) => {
  const hostName = window && window.location && window.location.hostname;
  let url = BASE_URL[env];

  if (hostName === 'localhost' && env === 'browserBaseUrl') {
    url = BASE_URL.qa3release; // default wa env
  }

  return url;
};

const createAPIRequestAction = (actionType: string) => ({
  PENDING: `${actionType}_PENDING`,
  SUCCESS: `${actionType}_SUCCESS`,
  FAILURE: `${actionType}_FAILURE`,
  BASE: actionType,
});

const API_REQUEST = 'API_REQUEST';

const API_EXT_REQUEST = 'API_EXT_REQUEST';

const APIConstants = {
  createAPIRequestAction,
  API_REQUEST,
  API_EXT_REQUEST,
  BASE_URL,
  EXT_BASE_URL,
};

export enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum httpResponseStatus {
  Failure,
  Success,
}

export default APIConstants;
