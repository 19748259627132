import { FC } from 'react';
import cn from 'clsx';

import { animationType, IClickableBox } from 'types';
import './clickableBox.scss';
import { ProgressAnimation } from 'components/animation';

const ClickableBox: FC<IClickableBox> = ({
  id,
  isActive,
  isLoading,
  type,
  onClick,
  value,
  title,
  text,
}) => {
  const onBoxClick = () => onClick(type);

  return (
    <div
      id={id}
      data-testid="clickable-box"
      onClick={onBoxClick}
      className={cn('clickable-box', { active: isActive })}
    >
      <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
      <span className="title">{title}</span>
      <span className="text">{text}</span>
      <span className="value">{value}</span>
    </div>
  );
};

export default ClickableBox;
