import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `SettingsActionTypes.${action}`;

const getSettings = APIConstants.createAPIRequestAction(getPrefix('getSettings'));

const updatePassword = APIConstants.createAPIRequestAction(getPrefix('updatePassword'));

const updateLanguage = getPrefix('updateLanguage');

const resetServerResponseStatus = getPrefix('resetServerResponseStatus');

const SettingsActionTypes = {
  getSettings,
  updateLanguage,
  updatePassword,
  resetServerResponseStatus,
};

export default SettingsActionTypes;
