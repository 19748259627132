import { FC } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { ILeaderBoardRow } from 'types';
import { BronzeMedal, GoldMedal, SilverMedal, ThumbUp } from 'components/Icons';
import './leaderBoardRow.scss';
import { toLanguageNumberFormat } from 'utils';

export const ICON_BY_RANK = {
  1: <GoldMedal />,
  2: <SilverMedal />,
  3: <BronzeMedal />,
  4: <ThumbUp />,
};

export const getIconByRank = (rank: number) => {
  const iconKey = rank <= 3 ? rank : 4;

  return ICON_BY_RANK[iconKey];
};

const LeaderBoardRow: FC<ILeaderBoardRow> = ({
  rank,
  name,
  hitRate,
  issueFoundCount,
  isSelected,
}) => {
  const [t] = useTranslation();

  return (
    <div className={cn('leader-board-item-wrapper', { selected: isSelected })}>
      <div className="leader-board-item-container">
        <div className="leader-board-item" key={name}>
          <div className="rank-number">
            <span className="numero-symbol">{t('SUMMARY.NUMBER')}</span>
            <span className="rank-number-value">{rank}</span>
          </div>
          <div className="leader-name" title={name}>
            {name}
          </div>
          <div className="leader-value">{Math.round(hitRate)} %</div>
          <div className="issue-found">({toLanguageNumberFormat(issueFoundCount)})</div>
          <div className="icon">{getIconByRank(rank)}</div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardRow;
