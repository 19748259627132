import { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import { DashboardEmptyStateIcon } from 'components/Icons';
import { EmptyState } from 'components/emptyState';
import UserProfileSelector from 'store/userProfile/userProfileSelector';
import {
  AYearIntervalOption,
  EmptyStateThemes,
  IRootState,
  ISummary,
  SelectedDateIntervals,
} from 'types';
import {
  setKpiDateFilter,
  setLeaderBoardDateFilter,
  setStoreFilter,
  setTopFindingsDateFilter,
} from 'store/summary/summaryActions';
import './summary.scss';
import WeeklyComparisonWrapper from 'components/summary/WeeklyComparisonWrapper';
import HighLevelKpisWrapper from 'components/summary/HighLevelKpisWrapper';
import TopFindingsWrapper from 'components/summary/TopFindingsWrapper';
import RateComparisonWrapper from 'components/summary/RateComparisonWrapper';
import LeaderBoardWrapper from 'components/summary/leaderBoard/LeaderBoardWrapper';
import { DateFilter } from 'components/summary/DateFilter';
import permissionsSrv from 'services/permissions/permissionsSrv';
import { Dropdown } from 'components/dropdown/Dropdown';
import summarySelector from 'store/summary/summarySelector';
import featureFlagsService from 'services/permissions/featureFlagsService';

export const Summary: FC<ISummary> = ({
  topFindingsDateFilter,
  kpiDateFilter,
  setKpiDateFilter,
  setTopFindingsDateFilter,
  setStoreFilter,
  dropdownOptions,
  selectedDropdownOption,
  isRtl,
  haveOpportunitiesBeenDeployed,
}) => {
  const [t] = useTranslation();

  const isSingleStoreManager = permissionsSrv.isSingleStoreManager();

  const showLeaderBoard = featureFlagsService.mayViewLeaderBoard();

  return (
    <div className={cn('page', 'summary')} id="summary">
      <div className="page-header">
        <span className="title">{t('PAGES.SUMMARY')}</span>
        {!isSingleStoreManager && (
          <Dropdown
            className="summary-filter-by-store"
            options={dropdownOptions}
            placeHolder={t('SUMMARY.FILTER_BY_STORE')}
            onClear={setStoreFilter}
            onChange={(option) => setStoreFilter({ storeName: option.label, id: option.key })}
            selectedOption={selectedDropdownOption}
            isSearchableDropdown
            isRtl={isRtl}
          />
        )}
      </div>
      <div id="summary-container" className={cn('page-container')}>
        <div className="page-preview">
          <div className="page-preview-top">
            <div className="weekly-comparison">
              <span className="column-header">{t('SUMMARY.WEEKLY_COMPARISON')}</span>
              <div className="weekly-comparison-cards">
                <WeeklyComparisonWrapper />
              </div>
            </div>
            <div className="top-findings">
              <span className="column-header">
                {t('SUMMARY.TOP_FINDINGS')}
                <DateFilter
                  options={AYearIntervalOption}
                  onChange={({ key }) => setTopFindingsDateFilter(key as SelectedDateIntervals)}
                  selectedOption={{
                    label: t(topFindingsDateFilter),
                    key: topFindingsDateFilter,
                  }}
                />
              </span>
              <div className="top-findings-wrapper">
                {haveOpportunitiesBeenDeployed ? (
                  <TopFindingsWrapper />
                ) : (
                  <EmptyState
                    text={t('COMMON.NO_OPPORTUNITIES_DEPLOYED')}
                    title={t('COMMON.NO_OPPORTUNITIES_DEPLOYED_TITLE')}
                    icon={DashboardEmptyStateIcon}
                    theme={EmptyStateThemes.Light}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="page-list">
          <div className="page-list-top">
            <span className="column-header">
              {t('SUMMARY.HIGH_LEVEL_KPI')}
              <DateFilter
                options={AYearIntervalOption}
                onChange={({ key }) => setKpiDateFilter(key as SelectedDateIntervals)}
                selectedOption={{ label: t(kpiDateFilter), key: kpiDateFilter }}
              />
            </span>
            <div className="kpi-cards">
              <HighLevelKpisWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className="page-footer">
        <div className="page-preview-bottom">
          <div className="rate-comparison-wrapper">
            <RateComparisonWrapper />
          </div>
        </div>
        {showLeaderBoard && (
          <div className="page-list-bottom">
            <div className="hit-rate-leaders">
              <LeaderBoardWrapper />
            </div>
          </div>
        )}
      </div>
      <div className="page-footer-decorator">
        <span className="powered-by">
          <span className="message">{t('COMMON.POWERED_BY')}</span>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { topFindingsDateFilter, kpiDateFilter } = state.summary.activeFilters;

  const dropdownOptions = summarySelector.getStoreDropdownOptions(state);

  const selectedDropdownOption = summarySelector.getStoreSelectedDropdownOption(state);

  const { isRtl } = state.settings;

  const haveOpportunitiesBeenDeployed = UserProfileSelector.haveOpportunitiesBeenDeployed(state);

  return {
    topFindingsDateFilter,
    kpiDateFilter,
    dropdownOptions,
    selectedDropdownOption,
    isRtl,
    haveOpportunitiesBeenDeployed,
  };
};

const mapDispatchToProps = {
  setKpiDateFilter,
  setTopFindingsDateFilter,
  setLeaderBoardDateFilter,
  setStoreFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
