import { Checkbox } from 'antd';
import cn from 'clsx';
import { FC } from 'react';

import { ICheckboxField } from 'types';

import './checkboxField.scss';

export const CheckboxField: FC<ICheckboxField> = ({
  isChecked,
  onChange,
  className,
  label,
  count,
  height,
  id,
}) => {
  return (
    <div
      id={`checkbox-field-${id}`}
      style={{ height: `${height}px` }}
      key={label}
      className={cn('custom-checkbox', className)}
    >
      <Checkbox onChange={onChange} checked={isChecked}>
        <span className={cn('label', isChecked && 'checked')}>{label}</span>
        <span className={cn('count', isChecked && 'checked')}>{count}</span>
      </Checkbox>
    </div>
  );
};
