import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './storeBreakdownList.scss';

import { IStoreBreakdownListProps } from './types';
import OpenOpportunitiesCard from 'components/openOpportunities/card/openOpportunitiesCard';
import { IRootState } from 'store/types';
import OpenOpportunitiesSelector from 'store/openOpportunities/openOpportunitiesSelector';
import LoadingSelector from 'store/loading/LoadingSelector';

const StoreBreakdownList: FC<IStoreBreakdownListProps> = ({
  storeBreakDown,
  totalStoreBreakdownCount,
  selectedCard,
  isRtl,
  isLoading,
  onCardClick,
  isAllCompletedState,
}) => {
  const [t] = useTranslation();

  const getUpdatedStoreBreakdownList = () => {
    let updatedStoreBreakDown = [...storeBreakDown];

    // remove “in progress” and “no response” options which are the first 2 options.
    if (isAllCompletedState) {
      updatedStoreBreakDown = updatedStoreBreakDown.slice(2);
    }

    return updatedStoreBreakDown;
  };

  const buildStoreCardsList = useCallback(() => {
    const updatedStoreBreakDown = getUpdatedStoreBreakdownList();

    return updatedStoreBreakDown.map((card) => {
      return (
        <OpenOpportunitiesCard
          key={card.status}
          onCardClick={() => onCardClick(card.status)}
          isSelected={selectedCard === card.status}
          type={card.status}
          value={card.count || 0}
          isRtl={isRtl}
          isLoading={isLoading}
        />
      );
    });
  }, [storeBreakDown, isRtl, selectedCard, isLoading]);

  return (
    <div className="store-breakdown-list-wrapper">
      <div className="store-breakdown-title">
        {totalStoreBreakdownCount} {t('OPEN_OPPORTUNITIES.STORES_BREAKDOWN')}
      </div>
      <div className="store-breakdown-list">{buildStoreCardsList()}</div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { storeBreakDown } = state.openOpportunities;

  const { isRtl } = state.settings;

  const totalStoreBreakdownCount = OpenOpportunitiesSelector.getTotalStoreBreakdownCountSelector(
    state,
  );

  const isLoading = LoadingSelector.isGetStoreBreakdownLoading(state);

  return {
    storeBreakDown,
    totalStoreBreakdownCount,
    isLoading,
    isRtl,
  };
};

export default connect(mapStateToProps, {})(memo(StoreBreakdownList));
