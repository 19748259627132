import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `MyOpportunitiesActionTypes.${action}`;

const getMyOpportunities = APIConstants.createAPIRequestAction(getPrefix('getMyOpportunities'));

const getLiveInventory = APIConstants.createAPIRequestAction(getPrefix('getLiveInventory'));

const selectMyOpportunity = getPrefix('selectMyOpportunity');

const clearOpportunities = getPrefix('clearOpportunities');

const removeOpportunityDeepLink = getPrefix('removeOpportunityDeepLink');

const submitOpportunityReason = APIConstants.createAPIRequestAction(
  getPrefix('submitOpportunityReason'),
);

const setActiveTab = getPrefix('setActiveTab');

const getHitRateScore = APIConstants.createAPIRequestAction(getPrefix('getHitRateScore'));

const MyOpportunitiesActionTypes = {
  getMyOpportunities,
  selectMyOpportunity,
  setActiveTab,
  clearOpportunities,
  submitOpportunityReason,
  removeOpportunityDeepLink,
  getLiveInventory,
  getHitRateScore,
};

export default MyOpportunitiesActionTypes;
