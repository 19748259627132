import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IChartEmptyState, TrendsTabOptions } from 'types';
import { mapSelectedTabToEmptyText, mapSelectedTabToEmptyTitle } from '../helper';
import { EmptyState } from 'components/emptyState';
import { DashboardEmptyStateIcon, NoResultIcon } from 'components/Icons';

const ChartEmptyState: FC<IChartEmptyState> = ({
  isFiltersApplied,
  haveOpportunitiesBeenDeployed,
  selectedTab,
  searchText,
}) => {
  const [t] = useTranslation();

  if (searchText) {
    return (
      <EmptyState
        title={t('DRILL_DOWNS.EMPTY_STATES.NO_RESULT_FOUND_SEARCH', {
          search: searchText,
        })}
        icon={NoResultIcon}
      />
    );
  }

  if (isFiltersApplied) {
    return (
      <EmptyState
        title={t('FINDINGS.EMPTY_STATE.EMPTY_BY_FILTERS_TITLE')}
        icon={NoResultIcon}
        text={t('FINDINGS.EMPTY_STATE.EMPTY_BY_FILTERS_TEXT')}
      />
    );
  }

  if (!haveOpportunitiesBeenDeployed) {
    if (selectedTab === TrendsTabOptions.Earning) {
      return (
        <EmptyState
          title={t('TRENDS.EMPTY_STATE.NOT_ENOUGH_DATA_TITLE')}
          icon={DashboardEmptyStateIcon}
          text={t('TRENDS.EMPTY_STATE.NOT_ENOUGH_DATA_TEXT')}
        />
      );
    }

    return (
      <EmptyState
        title={t('TRENDS.EMPTY_STATE.HANG_TIGHT_TITLE')}
        icon={DashboardEmptyStateIcon}
        text={t('TRENDS.EMPTY_STATE.HANG_TIGHT_TEXT')}
      />
    );
  }

  const text = mapSelectedTabToEmptyText[selectedTab];

  const title = mapSelectedTabToEmptyTitle[selectedTab];

  return <EmptyState title={t(title)} text={t(text)} icon={NoResultIcon} />;
};

export default ChartEmptyState;
