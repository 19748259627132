import { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ClickableBoxWrapper from 'components/trends/preview/ClickableBoxWrapper';
import ChartWrapper from 'components/trends/preview/ChartWrapper';
import { DecorativeFooter } from 'components/decorativeFooter';
import { ProgressAnimation } from 'components/animation';
import { mapSelectedTabToFooterText, mapSelectedTabToFooterTitle } from 'components/trends/helper';
import { animationType, IRootState, ITrendsPreview, TrendsTabOptions } from 'types';
import LoadingSelector from 'store/loading/LoadingSelector';

const TrendsPreview: FC<ITrendsPreview> = ({
  selectedTab,
  isSalesIncreaseLoading,
  isResponseRateLoading,
  isHitRateLoading,
}) => {
  const [t] = useTranslation();

  const mapSelectedTabToIsLoading = {
    [TrendsTabOptions.Earning]: isSalesIncreaseLoading,
    [TrendsTabOptions.ResponseRate]: isResponseRateLoading,
    [TrendsTabOptions.HitRate]: isHitRateLoading,
  };

  const isLoading = mapSelectedTabToIsLoading[selectedTab];

  const footerTitle = mapSelectedTabToFooterTitle[selectedTab];

  const footerText = mapSelectedTabToFooterText[selectedTab];

  return (
    <div className="preview-container">
      <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
      <div className="trends-header">
        <div className="header-title">{t('TRENDS.ACHIEVEMENT_OVERVIEW')}</div>
        <ClickableBoxWrapper />
      </div>
      <div className="preview-content">
        <ChartWrapper />
        <DecorativeFooter title={t(footerTitle)} text={t(footerText)} isSmall={false} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { selectedTab } = state.trends.filters;

  const isSalesIncreaseLoading = LoadingSelector.isSalesIncreaseLoading(state);

  const isHitRateLoading = LoadingSelector.isHitRateLoading(state);

  const isResponseRateLoading = LoadingSelector.isResponseRateLoading(state);

  return { selectedTab, isSalesIncreaseLoading, isHitRateLoading, isResponseRateLoading };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TrendsPreview);
