import { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  DrillDownTypes,
  IFilterItem,
  IRootState,
  ITrendsDrillDownWrapper,
  TrendsTabOptions,
} from 'types';
import {
  addFilter,
  onDrillDownSearch,
  removeDrillDownFilter,
  sliceHierarchyPath,
  toggleDirection,
  toggleExpended,
} from 'store/trends/trendsActions';
import DrillDown from 'components/drilldown/DrillDown';
import { mapSelectedTabToHeaderSubTitle } from '../helper';
import trendsSelector from 'store/trends/trendsSelector';

const TrendsDrillDownWrapper: FC<ITrendsDrillDownWrapper> = ({
  id,
  drillDown,
  expendedDrillDown,
  onExpendedToggle,
  onToggleDirection,
  onFilterClick,
  onHierarchyPathClick,
  onRemoveFilter,
  onTitleClick,
  onSearchChange,
  type,
  onLoadMore,
  currency,
  shouldDisplayShortCurrency,
  shouldDisplayTotal,
  selectedTab,
  isFiltersApplied,
  isRtl,
}) => {
  const [t] = useTranslation();

  if (!drillDown) {
    return null;
  }

  const {
    activeFilters: { orderDirection, selectedCategory },
    mutualFilters: { hierarchyIndex, search },
    hierarchyPath,
    hierarchyName,
    isFiltersVisible,
    hierarchyDepth,
    isLoading,
    highestValue,
  } = drillDown;

  // check if it's the last level
  const isLeaf = hierarchyIndex > hierarchyDepth;

  const isTitleClickable = type === DrillDownTypes.Other;

  const onClick = isTitleClickable ? onTitleClick : undefined;

  const isExpended = expendedDrillDown === type;

  const isHidden = !isExpended && !!expendedDrillDown;

  const onFilter = (filter: IFilterItem) => {
    const updatedFilter = { ...filter };

    if (type === DrillDownTypes.Other) {
      // set values for other drill down
      updatedFilter.hierarchyValue = selectedCategory?.value;
      updatedFilter.categoryType = selectedCategory?.type;
    }

    onFilterClick(updatedFilter, type);
  };

  const headerSubTitleKey = mapSelectedTabToHeaderSubTitle[selectedTab];

  const isNumericData = selectedTab === TrendsTabOptions.Earning;

  return (
    <DrillDown
      id={id}
      isFiltersApplied={isFiltersApplied}
      searchText={search}
      highestValue={highestValue}
      headerSubTitle={t(headerSubTitleKey)}
      isNumericData={isNumericData}
      shouldDisplayShortCurrency={shouldDisplayShortCurrency}
      shouldDisplayTotalAndPercent={shouldDisplayTotal}
      currency={currency}
      isOptionBarEnable={false}
      isHidden={isHidden}
      isFreeTextOnly={false}
      onFreeTextToggle={() => {}}
      isOpportunitySelected={false}
      opportunities={null}
      onTitleClick={onClick}
      hierarchyName={hierarchyName}
      isLoading={isLoading}
      isFiltersVisible={isFiltersVisible}
      onSearchChange={(text) => onSearchChange(text, type)}
      onHierarchyPathClick={(index) => onHierarchyPathClick(index, type)}
      isTitleClickable={isTitleClickable}
      hierarchyPath={hierarchyPath}
      onLoadMore={() => onLoadMore(type)}
      onRemoveFilter={(filter) => onRemoveFilter(filter)}
      list={drillDown.list}
      totalCount={drillDown.totalCount}
      onExpendedToggle={() => onExpendedToggle(type)}
      onFilterClick={onFilter}
      onShowOpportunitiesClick={() => {}}
      onToggleDirection={() => onToggleDirection(type)}
      order={orderDirection}
      onOpportunityClick={() => {}}
      filters={drillDown.filters}
      isLastHierarchyLevel={isLeaf}
      isExpended={isExpended}
      isRtl={isRtl}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { expendedDrillDown, selectedTab } = state.trends.filters;

  const isEarning = selectedTab === TrendsTabOptions.Earning;

  const isFiltersApplied = trendsSelector.checkIsFiltersApplied(state);

  const shouldDisplayShortCurrency = isEarning;

  const shouldDisplayTotal = !isEarning;

  const { currency } = state.settings.general;

  const { isRtl } = state.settings;

  return {
    expendedDrillDown,
    shouldDisplayTotal,
    shouldDisplayShortCurrency,
    currency,
    selectedTab,
    isFiltersApplied,
    isRtl,
  };
};

const mapDispatchToProps = {
  onToggleDirection: toggleDirection,
  onRemoveFilter: removeDrillDownFilter,
  onHierarchyPathClick: sliceHierarchyPath,
  onSearchChange: onDrillDownSearch,
  onExpendedToggle: toggleExpended,
  onFilterClick: addFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendsDrillDownWrapper);
