import { createRef, FC, useCallback, useEffect } from 'react';
import { debounce, isEmpty } from 'lodash';

import { Accordion } from './accordion';
import './reasonsList.scss';
import { EVENTS } from 'constants/constantsVaribles';
import { IReason, IReasonsList } from 'types';

const DEBOUNCE_DELAY_MS: number = 50;

export const ReasonsList: FC<IReasonsList> = ({
  list,
  onTabChange,
  onSubmitClick,
  scrollIntoTabId,
  selectedReasonId,
  selectedReasonDescription,
  reasonReceivedDate,
  reasonCompleteDate,
}) => {
  const innerListRef = createRef<HTMLDivElement>();

  const getTabIdByScrollPos = useCallback(
    (elements: any, scrollTop: number): number => {
      type ElementDetails = { start: number; height: number };
      let active = 0;
      if (!isEmpty(elements)) {
        let lastItem: ElementDetails;
        let currItem: ElementDetails;

        const elementsDetails: Array<ElementDetails> = [
          // minus 1 is because scrollHeight is rounded so if the height is 17.6 it will be 18
          { start: 0, height: elements[0].scrollHeight - 1 },
        ];

        for (let i = 1; i < elements.length; i += 1) {
          lastItem = elementsDetails[i - 1];
          currItem = {
            start: lastItem.height + lastItem.start,
            height: elements[i].scrollHeight - 1,
          };
          elementsDetails.push(currItem);

          if (scrollTop > lastItem.start && scrollTop < currItem.start) {
            return list![i - 1]?.order;
          }

          if (scrollTop >= currItem.start) {
            active = i;
          }
        }
      }

      return list![active]?.order;
    },
    [list],
  );

  const onListScroll = debounce(
    useCallback(
      (e: any) => {
        if (list && list.length > 0) {
          onTabChange(getTabIdByScrollPos(e.target.children, e.target.scrollTop));
        }
      },
      [list],
    ),
    DEBOUNCE_DELAY_MS,
  );
  useEffect(() => {
    if (list && list.length > 0 && innerListRef?.current) {
      // Scroll to top when list changed
      if (innerListRef.current.scrollTop !== 0) {
        innerListRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
      onTabChange(list[0].order);
    }
  }, [list, onTabChange]);
  useEffect(() => {
    innerListRef?.current?.addEventListener(EVENTS.SCROLL, onListScroll);

    return () => {
      innerListRef?.current?.removeEventListener(EVENTS.SCROLL, onListScroll);
    };
  }, [onListScroll]);

  return (
    <div ref={innerListRef} className="reasons-list-container" id="reasons-list-container">
      {list.map((reasonGroup: IReason) => {
        const { options, order, label, key } = reasonGroup;

        return (
          <Accordion
            reasonReceivedDate={reasonReceivedDate}
            reasonCompleteDate={reasonCompleteDate}
            selectedPanelId={selectedReasonId}
            selectedPanelDescription={selectedReasonDescription}
            options={options}
            id={order}
            label={label}
            scrollIntoTabId={scrollIntoTabId}
            key={key}
            onSubmitClick={onSubmitClick}
          />
        );
      })}
    </div>
  );
};
