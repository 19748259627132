import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { toLanguageNumberFormat } from 'utils';
import { ActionButton } from 'components/buttons';
import { UpArrow } from 'components/Icons';
import { ProgressAnimation } from 'components/animation';
import { animationType } from 'components/animation/types';
import { ISummaryTopThreeFindings } from 'types';
import SummaryTopFindingsReasonCard from 'components/summary/summaryTopFindingsReasonCard/SummaryTopFindingsReasonCard';

export const SummaryTopThreeFindings: FC<ISummaryTopThreeFindings> = ({
  title,
  list,
  isLoading,
  emptyStateLabel,
  onSeeDetailsClick,
}) => {
  const [t] = useTranslation();

  const shouldShowEmptyState = !list.length;

  return (
    <div className="top-reason-cards">
      <div className="header">
        <span className="title">{title}</span>
        <ActionButton
          text={t('SUMMARY.SEE_DETAILS')}
          Icon={UpArrow}
          className="see-details"
          onClick={onSeeDetailsClick}
        />
      </div>
      <div className="reason-cards">
        <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
        {list.map(({ name, count, rate }) => (
          <SummaryTopFindingsReasonCard
            key={`${name}_${Math.random()}`}
            name={name}
            count={toLanguageNumberFormat(Number(count))}
            rate={rate}
          />
        ))}
        {shouldShowEmptyState && <div className="empty-state">{emptyStateLabel}</div>}
      </div>
    </div>
  );
};
