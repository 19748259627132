import { FC, useCallback } from 'react';

import { IDrillDownOpportunitiesProps, LifecycleOptions } from 'types';
import { Cards } from 'components/cards';
import OpportunityCard from './opportinityCard/OpportunityCard';

const CARD_HEIGHT = 115;

const HEADER_HEIGHT = 40;

const OpportunitiesList: FC<IDrillDownOpportunitiesProps> = ({
  opportunitiesGroupContent,
  opportunitiesGroupCount,
  list,
  onLoadMore,
  onOpportunityClick,
  emptyState,
  totalCount,
  isLoading,
  shouldCalcHeight = true,
}) => {
  const itemContent = useCallback(
    (index: number) => {
      const currentOpportunity = list![index];

      if (!currentOpportunity) {
        return null;
      }

      const {
        productName,
        productSkusAndSizes,
        lifecycle,
        productCategories,
        storeName,
        selectedReason,
        id,
      } = currentOpportunity;

      const isExpired = lifecycle === LifecycleOptions.EXPIRED;

      const productSku = productSkusAndSizes![0]?.sku || '';

      return (
        <OpportunityCard
          onOpportunityClick={onOpportunityClick}
          id={id}
          productName={productName}
          isExpired={isExpired}
          storeName={storeName}
          productSku={productSku}
          productCategories={productCategories}
          reason={selectedReason.title}
          description={selectedReason.description}
        />
      );
    },
    [list],
  );

  const groupContent = useCallback(
    (index: number) => {
      const groupHeader = opportunitiesGroupContent![index];

      return <div className="cards-header small">{groupHeader}</div>;
    },
    [opportunitiesGroupContent],
  );

  let height: string | number = '100%';

  if (shouldCalcHeight) {
    const cardsHeight = totalCount * CARD_HEIGHT;

    const headerHeight = (opportunitiesGroupContent?.length || 0) * HEADER_HEIGHT;
    height = cardsHeight + headerHeight;
  }

  return (
    <Cards
      dataTestId="opportunities-list"
      isLoading={isLoading}
      totalCount={totalCount}
      emptyState={emptyState}
      containerHeight={height}
      itemContent={itemContent}
      cards={list}
      groupContent={groupContent}
      groupCounts={opportunitiesGroupCount}
      onLoadMore={onLoadMore}
    />
  );
};

export default OpportunitiesList;
