import { QueryParam, RoutePath } from 'routes/types';
import { history } from 'store/configureStore';
import { buildSearchParamsString } from 'utils/NavigationUtils';
import { resolveLegacyDrillDownQuery, resolveLegacyOpportunityQuery } from 'utils/UrlMappingUtils';
import AnalyticSrv from 'services/analytics/AnalyticSrv';

class RouterService {
  // when received query params -> add isDeepLink = true to the router state so we know to update
  // the relevant filters accordingly
  public navigateToPage(path: RoutePath, params?: QueryParam[]) {
    const isDeepLink = !!params;

    const queryStr = buildSearchParamsString(params || []);

    history.push({
      pathname: `/${path}`,
      search: queryStr,
      state: { isDeepLink },
    });
    AnalyticSrv.sendPageViewEvent();
  }

  public redirectLegacyUrl(path: RoutePath, legacyQuery = '', selectedTab?: string) {
    let parsedQuery = '';

    switch (path) {
      case RoutePath.MyOpportunities: {
        parsedQuery = resolveLegacyOpportunityQuery(legacyQuery);
        break;
      }

      case RoutePath.Findings:
      case RoutePath.Trends: {
        parsedQuery = resolveLegacyDrillDownQuery(legacyQuery, selectedTab);
        break;
      }
      default:
        break;
    }

    history.replace({
      pathname: path,
      search: parsedQuery,
      state: { isDeepLink: true },
    });
  }

  public updateSearchParams(params: QueryParam[]) {
    const newQueryStr = buildSearchParamsString(params);

    history.push({
      search: newQueryStr,
    });
  }

  public routeToSummary() {
    this.navigateToPage(RoutePath.Summary);
  }

  public routeToContactUs(params?: QueryParam[]) {
    this.navigateToPage(RoutePath.ContactUs, params);
  }

  public routeToMyOpportunities(params?: QueryParam[]) {
    this.navigateToPage(RoutePath.MyOpportunities, params);
  }

  public routeToFindings(params?: QueryParam[]) {
    this.navigateToPage(RoutePath.Findings, params);
  }

  public routeToOpenOpportunities(params?: QueryParam[]) {
    this.navigateToPage(RoutePath.OpenOpportunities, params);
  }

  public routeToTrends(params?: QueryParam[]) {
    this.navigateToPage(RoutePath.Trends, params);
  }

  public routeToKnowledgeCenter() {
    this.navigateToPage(RoutePath.KnowledgeCenter);
  }

  public routeToSettings() {
    this.navigateToPage(RoutePath.Settings);
  }

  public routeToLogout() {
    this.navigateToPage(RoutePath.Logout);
  }

  public routeToHome() {
    this.navigateToPage(RoutePath.Home);
  }
}

const routerService = new RouterService();

export default routerService;
