import { createSelector } from 'reselect';

import MyOpportunitiesActionTypes from 'store/myOpportunities/myOpportunitiesActionTypes';
import ReasonsActionTypes from 'store/reasons/reasonsActionTypes';
import SettingsActionTypes from 'store/settings/settingsActionTypes';
import { IRootState } from 'store/types';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';
import KnowledgeCenterActionTypes from 'store/knowledgecenter/knowledgeCenterActionTypes';
import findingsActionTypes from 'store/findings/findingsActionTypes';
import TrendsActionTypes from 'store/trends/trendsActionTypes';
import SummaryActionTypes from 'store/summary/summaryActionTypes';
import OpenOpportunitiesActionTypes from 'store/openOpportunities/openOpportunitiesActionTypes';

const getActionTypeForLoading = (actionType: string) => actionType.replace('.', '-');

const progressSelector = (state: IRootState) => state.loading.inProgress;

const counterSelector = (state: IRootState) => state.loading.counter;

const getInProgressStatus = (inProgress: any, type: string) => {
  if (inProgress[type]) {
    return inProgress[type].count > 0;
  }

  return false;
};

const isGetMyOpportunitiesLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(MyOpportunitiesActionTypes.getMyOpportunities.BASE);

  return getInProgressStatus(inProgress, type);
});

const isContentReady = createSelector([counterSelector], (counter) => counter === 0);

const getIsHistogramLoadingByType = (
  inProgress: any,
  myStoresActionType: string,
  chainWideActionType: string,
) => {
  const myStoresType = getActionTypeForLoading(myStoresActionType);

  const chainWideType = getActionTypeForLoading(chainWideActionType);

  const isChainWideInProgress = getInProgressStatus(inProgress, chainWideType);

  const isMyStoresInProgress = getInProgressStatus(inProgress, myStoresType);

  return isChainWideInProgress || isMyStoresInProgress;
};

const isSalesIncreaseLoading = createSelector([progressSelector], (inProgress) => {
  return getIsHistogramLoadingByType(
    inProgress,
    TrendsActionTypes.getSalesIncreaseHistogram.BASE,
    TrendsActionTypes.geSalesIncreaseChainWideHistogram.BASE,
  );
});

const isResponseRateLoading = createSelector([progressSelector], (inProgress) => {
  return getIsHistogramLoadingByType(
    inProgress,
    TrendsActionTypes.getStoresResponseRate.BASE,
    TrendsActionTypes.getResponseRateChainWideHistogram.BASE,
  );
});

const isHitRateLoading = createSelector([progressSelector], (inProgress) => {
  return getIsHistogramLoadingByType(
    inProgress,
    TrendsActionTypes.getHitRateHistogram.BASE,
    TrendsActionTypes.getHitRateChainWideHistogram.BASE,
  );
});

const isGetKnowledgeCenterVideos = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(KnowledgeCenterActionTypes.getVideos.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetFindingsReasons = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(findingsActionTypes.getFindingsReasons.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetSummaryFindingsReasons = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SummaryActionTypes.getFindingsReasons.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetUserScopeLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(UserProfileActionTypes.getUserScope.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetSummaryOpenOpportunitiesLoading = createSelector([progressSelector], (inProgress) => {
  const summaryPageType = getActionTypeForLoading(SummaryActionTypes.getOpenOpportunities.BASE);

  return getInProgressStatus(inProgress, summaryPageType);
});

const isGetOpenOpportunitiesLoading = createSelector([progressSelector], (inProgress) => {
  const opportunitiesPageType = getActionTypeForLoading(
    OpenOpportunitiesActionTypes.getOpenOpportunities.BASE,
  );

  return getInProgressStatus(inProgress, opportunitiesPageType);
});

const isGetStoreBreakdownLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(OpenOpportunitiesActionTypes.getStoreBreakdown.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetSummaryResponseRateComparison = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SummaryActionTypes.getResponseRateComparison.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetSummaryHitRateComparison = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SummaryActionTypes.getHitRateComparison.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetMyIssueFoundByReasons = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SummaryActionTypes.getMyIssueFoundByReasons.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetMySalesIncrease = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SummaryActionTypes.getMySalesIncrease.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetSettingsLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SettingsActionTypes.getSettings.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetReasonLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(ReasonsActionTypes.getReasons.BASE);

  return getInProgressStatus(inProgress, type);
});

const isGetLeaderBoardLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(SummaryActionTypes.getLeaderBoardData.BASE);

  return getInProgressStatus(inProgress, type);
});

const isSummaryResponseRateLoading = createSelector([progressSelector], (inProgress) => {
  return getIsHistogramLoadingByType(
    inProgress,
    SummaryActionTypes.getResponseRateHistogram.BASE,
    SummaryActionTypes.getResponseRateChainWideHistogram.BASE,
  );
});

const isSummaryHitRateLoading = createSelector([progressSelector], (inProgress) => {
  return getIsHistogramLoadingByType(
    inProgress,
    SummaryActionTypes.getHitRateHistogram.BASE,
    SummaryActionTypes.getHitRateChainWideHistogram.BASE,
  );
});

const isOpenOpportuntiesDrilldownLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(OpenOpportunitiesActionTypes.getFilteredDrillDownItems.BASE);

  return getInProgressStatus(inProgress, type);
});

const isOpportuntiesLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(OpenOpportunitiesActionTypes.getOpportunities.BASE);

  return getInProgressStatus(inProgress, type);
});

const isLiveInventoryLoading = createSelector([progressSelector], (inProgress) => {
  const type = getActionTypeForLoading(MyOpportunitiesActionTypes.getLiveInventory.BASE);

  return getInProgressStatus(inProgress, type);
});

const isEmptyStateAnimationLoading = createSelector([progressSelector], (inProgress) => {
  const hitRateScore = getActionTypeForLoading(MyOpportunitiesActionTypes.getHitRateScore.BASE);

  const submitReason = getActionTypeForLoading(
    MyOpportunitiesActionTypes.submitOpportunityReason.BASE,
  );

  const getOpportunities = getActionTypeForLoading(
    MyOpportunitiesActionTypes.getMyOpportunities.BASE,
  );

  const isHitRateScoreProgress = getInProgressStatus(inProgress, hitRateScore);

  const isSubmitReasonProgress = getInProgressStatus(inProgress, submitReason);

  const isGetOpportunitiesProgress = getInProgressStatus(inProgress, getOpportunities);

  return isHitRateScoreProgress || isSubmitReasonProgress || isGetOpportunitiesProgress;
});

const LoadingSelector = {
  isContentReady,
  isGetMyOpportunitiesLoading,
  isGetUserScopeLoading,
  isGetKnowledgeCenterVideos,
  isGetFindingsReasons,
  isGetSummaryFindingsReasons,
  isSalesIncreaseLoading,
  isHitRateLoading,
  isResponseRateLoading,
  isGetOpenOpportunitiesLoading,
  isGetSummaryOpenOpportunitiesLoading,
  isGetStoreBreakdownLoading,
  isGetSummaryResponseRateComparison,
  isGetSummaryHitRateComparison,
  isGetMyIssueFoundByReasons,
  isGetMySalesIncrease,
  isGetSettingsLoading,
  isGetReasonLoading,
  isGetLeaderBoardLoading,
  isSummaryResponseRateLoading,
  isSummaryHitRateLoading,
  isOpenOpportuntiesDrilldownLoading,
  isLiveInventoryLoading,
  isOpportuntiesLoading,
  isEmptyStateAnimationLoading,
};

export default LoadingSelector;
