import { useContext } from 'react';

import { reactKeycloakWebContext } from './context';

export const useKeycloak = () => {
  const ctx = useContext(reactKeycloakWebContext);

  if (!ctx) {
    throw new Error('useKeycloak hook must be used inside ReactKeycloakProvider context');
  }

  if (!ctx.authClient) {
    // eslint-disable-next-line no-console
    console.warn('authClient has not been assigned to ReactKeycloakProvider');
  }

  const { authClient, initialized, isUserInfoLoaded } = ctx;

  return {
    initialized,
    keycloak: authClient,
    isUserInfoLoaded,
  };
};
