import './ContentReady.scss';
import { connect } from 'react-redux';

import LoadingSelector from 'store/loading/LoadingSelector';
import { IRootState } from 'store/types';

export interface IContentReady {
  isReady?: boolean;
}

const ContentReady = ({ isReady }: IContentReady) => {
  return (
    <div id="content-ready" className="content-ready">
      {isReady ? 'True' : 'False'}
    </div>
  );
};

export const mapStateToProps = (state: IRootState) => ({
  isReady: LoadingSelector.isContentReady(state),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContentReady);
