import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import { HitRateIcon, ResponseRateIcon } from 'components/Icons';
import 'components/pages/summary/summary.scss';
import { getRateHistograms, setActiveComparisonTab } from 'store/summary/summaryActions';
import {
  DrillDownTypes,
  IDate,
  IRateComparisonTab,
  IRateComparisonWrapper,
  IRootState,
  ISearchParamsFilters,
  RATE_COMPARISON_TABS,
  SelectedDateIntervals,
  SummaryComparisonTab,
  TrendsTabOptions,
} from 'types';
import LoadingSelector from 'store/loading/LoadingSelector';
import SummarySelector from 'store/summary/summarySelector';
import RateComparisonChartContainer from 'components/summary/RateComparisonChartContainer';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';
import RouterService from 'services/router/routerService';
import { getCurrentDate } from 'utils';
import { getStoresCategory } from 'store/filters/filterHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const RateComparisonWrapper: FC<IRateComparisonWrapper> = ({
  setActiveComparisonTab,
  getRateHistograms,
  activeComparisonTab,
  isRtl,
  rateComparisonTimeSpanMonths,
  hitRateData,
  responseRateData,
  hitRateTotal,
  responseRateTotal,
  isHitRateLoading,
  isResponseRateLoading,
  peersLevel,
  selectedStoreFilter,
  selectedCategory,
}) => {
  const [t] = useTranslation();

  const { TabPane } = Tabs;

  const isResponseRate = activeComparisonTab === 1;

  const lineChartData = isResponseRate ? responseRateData : hitRateData;

  const rate = isResponseRate ? responseRateTotal : hitRateTotal;

  const handleRouteToTrends = () => {
    const date: IDate = {
      type: SelectedDateIntervals.twelveMonthsId,
      from: getCurrentDate(),
      to: getCurrentDate(),
    };

    const trendTab =
      activeComparisonTab === SummaryComparisonTab.HIT_RATE
        ? TrendsTabOptions.HitRate
        : TrendsTabOptions.ResponseRate;

    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date,
      tab: trendTab,
      [DrillDownTypes.Other]: {
        category: selectedStoreFilter ? getStoresCategory() : selectedCategory,
        filter: {
          value: selectedStoreFilter?.id || '',
          name: selectedStoreFilter?.storeName || '',
          hierarchyName: '',
        },
      },
    };

    AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_TRENDS, {
      tab_name: trendTab,
      time_span: SelectedDateIntervals.twelveMonthsId,
    });

    const queryParams = buildQueryParamsFromSearchFilters(searchFilters);
    RouterService.routeToTrends(queryParams);
  };

  const mapTabIdToIcon = {
    1: <ResponseRateIcon />,
    2: <HitRateIcon />,
  };

  useEffect(() => {
    getRateHistograms();
  }, [selectedStoreFilter]);

  const renderTab = (tabItem: IRateComparisonTab) => (
    <>
      <span>{tabItem.label}</span>
      {mapTabIdToIcon[tabItem.id]}
    </>
  );

  const renderTabs = () =>
    RATE_COMPARISON_TABS.map((item) => <TabPane tab={renderTab(item)} key={item.id} />);

  const timeSpanText =
    rateComparisonTimeSpanMonths === 1
      ? t('SUMMARY.LAST_MONTH')
      : t('SUMMARY.LAST_X_MONTHS', { timeSpan: rateComparisonTimeSpanMonths });

  return (
    <>
      <div className="column-header">
        <div className="title-container">
          <span className="title">{t('SUMMARY.HOW_DO_I_COMPARE')}</span>
          {rateComparisonTimeSpanMonths && <span className="time-span">{timeSpanText}</span>}
        </div>

        <Tabs
          className="tabs-container"
          type="card"
          activeKey={String(activeComparisonTab)}
          onChange={(activeTab) => setActiveComparisonTab(Number(activeTab))}
        >
          {renderTabs()}
        </Tabs>
      </div>

      <div className="rate-comparison-container">
        <RateComparisonChartContainer
          activeComparisonTab={activeComparisonTab}
          rate={rate}
          lineChartData={lineChartData}
          isRtl={isRtl}
          peersLevel={peersLevel}
          onSeeDetailsClick={handleRouteToTrends}
          emptyStateLabel={t('SUMMARY.RATE_COMPARISON_EMPTY_STATE_LABEL')}
          isLoading={isHitRateLoading || isResponseRateLoading}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { activeComparisonTab } = state.summary.activeFilters;

  const { userPeersLevel } = state.userProfile;

  const peersLevel = userPeersLevel?.id || '';

  const {
    hitRate: { total: hitRateTotal },
    responseRate: { total: responseRateTotal },
    selectedStoreFilter,
  } = state.summary;

  const { selectedCategory } = state.trends.filters[DrillDownTypes.Other].activeFilters;

  const { isRtl } = state.settings;

  const isHitRateLoading = LoadingSelector.isSummaryHitRateLoading(state);

  const isResponseRateLoading = LoadingSelector.isSummaryResponseRateLoading(state);

  const responseRateData = SummarySelector.getResponseRateData(state);

  const hitRateData = SummarySelector.getHitRateData(state);

  const rateComparisonTimeSpanMonths = SummarySelector.calculateHitRateComparisonTimeSpan(state);

  return {
    activeComparisonTab,
    isRtl,
    rateComparisonTimeSpanMonths,
    peersLevel,
    isHitRateLoading,
    isResponseRateLoading,
    responseRateData,
    hitRateData,
    hitRateTotal,
    responseRateTotal,
    selectedStoreFilter,
    selectedCategory,
  };
};

const mapDispatchToProps = { setActiveComparisonTab, getRateHistograms };

export default connect(mapStateToProps, mapDispatchToProps)(RateComparisonWrapper);
