import { createRef, FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import ReasonsWrapperConnected from 'components/myOpportunities/cardPreview/reasons/ReasonsWrapper';
import PreviewHeader from 'components/myOpportunities/cardPreview/cardDetails/previewHeader/PreviewHeader';
import 'components/pages/myOpportunities/myOpportunities.scss';
import MyOpportunitiesSelector from 'store/myOpportunities/myOpportunitiesSelector';
import {
  ICategory,
  IMyOpportunity,
  IPopupModal,
  IRootState,
  LifecycleOptions,
  PopupIds,
  ResponseTypes,
} from 'types';
import PreviewContent from 'components/myOpportunities/cardPreview/cardDetails/PreviewContent';
import { show as showProductImagePopup } from 'store/popup/popupActions';

export interface IMyOpportunitiesProps {
  selectedOpportunity: IMyOpportunity | null;
  productCategories: any;
  planogramAttributes: Array<ICategory>;
  showProductImagePopup(options: IPopupModal): void;
}

const MyOpportunitiesPreview: FC<IMyOpportunitiesProps> = ({
  selectedOpportunity,
  productCategories,
  showProductImagePopup,
  planogramAttributes,
}) => {
  const previewRef = createRef<HTMLDivElement>();

  const isExpired = selectedOpportunity?.lifecycle === LifecycleOptions.EXPIRED;

  const isNoIssueFound =
    selectedOpportunity?.selectedReason?.responseType === ResponseTypes.NO_ISSUE_FOUND;

  const inventoryUpdate = {
    date: selectedOpportunity?.inventoryLatestUpdateDate || 0,
    value: selectedOpportunity?.inventoryLevel || 0,
  };

  useEffect(() => {
    // Scroll to top every opportunity change
    if (previewRef?.current) {
      previewRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const onProductImageClick = useCallback(() => {
    const options: IPopupModal = {
      id: PopupIds.OpportunityProductImage,
      selectedOpportunityId: null,
    };

    showProductImagePopup(options);
  }, [selectedOpportunity]);

  return (
    <div className="preview-container white">
      {selectedOpportunity && (
        <>
          <PreviewHeader
            action={selectedOpportunity.action}
            key={selectedOpportunity.id}
            onProductImageClick={onProductImageClick}
            isExpired={isExpired}
            isNoIssueFound={isNoIssueFound}
            productName={selectedOpportunity?.productName}
            productSku={selectedOpportunity?.productSkusAndSizes}
            storeName={selectedOpportunity?.storeName}
            productImage={selectedOpportunity?.lowResolutionProductImage}
          />
          <div className="preview-data" ref={previewRef}>
            <div className="product-sale">
              <PreviewContent
                productCategories={productCategories}
                planogramAttributes={planogramAttributes}
                productSkusAndSizes={selectedOpportunity?.productSkusAndSizes || {}}
                flavour={selectedOpportunity?.flavour}
                inventoryUpdate={inventoryUpdate}
                targetUnits={selectedOpportunity?.targetUnits || 0}
                targetSales={selectedOpportunity?.targetSales || 0}
                potentialUnits={selectedOpportunity?.potentialUnitsSales || 0}
                potentialSales={selectedOpportunity?.potentialRevenue || 0}
                lastSalesValues={selectedOpportunity?.lastSalesValues || []}
                lastUnitsValues={selectedOpportunity?.lastUnitsValues}
                lastSalesValuesTotal={selectedOpportunity?.lastSalesValuesTotal}
                lastUnitsValuesTotal={selectedOpportunity?.lastUnitsValuesTotal}
                lastSimilarSalesValues={selectedOpportunity?.lastSimilarSalesValues || []}
                lastSimilarUnitsValues={selectedOpportunity?.lastSimilarUnitsValues}
                lastSimilarSalesValuesTotal={selectedOpportunity?.lastSimilarSalesValuesTotal}
                lastSimilarUnitsValuesTotal={selectedOpportunity?.lastSimilarUnitsValuesTotal}
              />
            </div>
            <ReasonsWrapperConnected />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    selectedOpportunity: MyOpportunitiesSelector.getSelectedOpportunitySelector(state),
    productCategories: MyOpportunitiesSelector.getSelectedProductCategoriesSelector(state),
    planogramAttributes: MyOpportunitiesSelector.getPlanogramAttributesSelector(state),
  };
};

const mapDispatchToProps = {
  showProductImagePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOpportunitiesPreview);
