import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `UserProfileActionTypes.${action}`;

const getUserScope = APIConstants.createAPIRequestAction(getPrefix('getUserScope'));

const submitContactUs = APIConstants.createAPIRequestAction(getPrefix('submitContactUs'));

const setUserInfo = getPrefix('setUserInfo');

const resetServerResponseStatus = getPrefix('resetServerResponseStatus');

const UserProfileActionTypes = {
  getUserScope,
  setUserInfo,
  submitContactUs,
  resetServerResponseStatus,
};

export default UserProfileActionTypes;
