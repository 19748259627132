import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `FiltersActionTypes.${action}`;

const setActiveTabFilter = getPrefix('setActiveTabFilter');

const ToggleControlMenu = getPrefix('ToggleControlMenu');

const getProductFilterLabels = APIConstants.createAPIRequestAction(
  getPrefix('getProductFilterLabels'),
);

const getStoreFilterLabels = APIConstants.createAPIRequestAction(getPrefix('getStoreFilterLabels'));

const getStoreNameFilterLabels = APIConstants.createAPIRequestAction(
  getPrefix('getStoreNameFilterLabels'),
);

const getPlanogramFilterLabels = APIConstants.createAPIRequestAction(
  getPrefix('getPlanogramFilterLabels'),
);

const getAllFilterLabels = getPrefix('getAllFilterLabels');

const prepareMyFilterStats = getPrefix('prepareMyFilterStats');

const getMyFilterStats = APIConstants.createAPIRequestAction(getPrefix('getMyFilterStats'));

const setFilterSelection = getPrefix('setFilterSelection');

const setFilterSearch = getPrefix('setFilterSearch');

const clearFilters = getPrefix('clearFilters');

const updateLoadMoreOffset = getPrefix('updateLoadMoreOffset');

const applyMyOpportunitiesFilters = getPrefix('applyMyOpportunitiesFilters');

const setMyOpportunitiesFiltersOrder = getPrefix('setMyOpportunitiesFiltersOrder');

const setMyOpportunitiesGroupByOrder = getPrefix('setMyOpportunitiesGroupByOrder');

const setMyOpportunitiesSearch = getPrefix('setMyOpportunitiesSearch');

const FiltersActionTypes = {
  ToggleControlMenu,
  applyMyOpportunitiesFilters,
  getProductFilterLabels,
  getStoreFilterLabels,
  getStoreNameFilterLabels,
  setFilterSelection,
  clearFilters,
  updateLoadMoreOffset,
  setActiveTabFilter,
  setMyOpportunitiesFiltersOrder,
  setMyOpportunitiesGroupByOrder,
  getMyFilterStats,
  setMyOpportunitiesSearch,
  getAllFilterLabels,
  prepareMyFilterStats,
  setFilterSearch,
  getPlanogramFilterLabels,
};

export default FiltersActionTypes;
