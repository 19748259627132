import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ILeaderBoardWrapper,
  IRootState,
  leaderBoardDateFilterOptions,
  SelectedDateIntervals,
} from 'types';
import 'components/pages/summary/summary.scss';
import LeaderBoard from 'components/summary/leaderBoard/LeaderBoard';
import PeersSwitch from 'components/summary/leaderBoard/PeersSwitch';
import {
  getLeaderBoardPrepare,
  setLeaderBoardDateFilter,
  setLeaderBoardPeersSelected,
} from 'store/summary/summaryActions';
import loadingSelector from 'store/loading/LoadingSelector';
import { DateFilter } from 'components/summary/DateFilter';

const LeaderBoardWrapper: FC<ILeaderBoardWrapper> = ({
  getLeaderBoardPrepare,
  leaderBoardDateFilter,
  setLeaderBoardDateFilter,
  leaderBoardTopData,
  leaderBoardBottomData,
  isGetLeaderBoardLoading = false,
  userPeersLevel,
  isLeaderBoardPeersSelected,
  setLeaderBoardPeersSelected,
  selectedStoreFilter,
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    getLeaderBoardPrepare();
  }, [leaderBoardDateFilter, isLeaderBoardPeersSelected, selectedStoreFilter]);

  const hasPeers = Boolean(userPeersLevel?.level);

  return (
    <>
      <div className="column-header leader-board">
        <div className="header-title-container">
          {t('SUMMARY.HIT_RATE_LEADERS')}
          <div className="date-select-container">
            <DateFilter
              options={leaderBoardDateFilterOptions}
              onChange={({ key }) => setLeaderBoardDateFilter(key as SelectedDateIntervals)}
              selectedOption={{ label: t(leaderBoardDateFilter), key: leaderBoardDateFilter }}
            />
          </div>
        </div>
        <PeersSwitch
          isPeersSelected={isLeaderBoardPeersSelected}
          hasPeers={hasPeers}
          onChange={(isPeersSelected: boolean) => setLeaderBoardPeersSelected(isPeersSelected)}
          peersLabel={userPeersLevel?.id}
        />
      </div>
      <LeaderBoard
        leaderBoardTopData={leaderBoardTopData}
        leaderBoardBottomData={leaderBoardBottomData}
        emptyStateLabel={t('SUMMARY.LEADER_BOARD_EMPTY_STATE')}
        isLoading={isGetLeaderBoardLoading}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { leaderBoardTopData, leaderBoardBottomData, selectedStoreFilter } = state.summary;

  const { userPeersLevel } = state.userProfile;

  const { leaderBoardDateFilter, isLeaderBoardPeersSelected } = state.summary.activeFilters;

  const isGetLeaderBoardLoading = loadingSelector.isGetLeaderBoardLoading(state);

  return {
    leaderBoardDateFilter,
    leaderBoardTopData,
    leaderBoardBottomData,
    isGetLeaderBoardLoading,
    userPeersLevel,
    isLeaderBoardPeersSelected,
    selectedStoreFilter,
  };
};

const mapDispatchToProps = {
  getLeaderBoardPrepare,
  setLeaderBoardDateFilter,
  setLeaderBoardPeersSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardWrapper);
