import { FC } from 'react';

import { DrillDownTypes, ITrendsDrillDowns } from 'types';
import TrendsDrillDownWrapper from './TrendsDrillDownWrapper';

const DrillDowns: FC<ITrendsDrillDowns> = ({
  storeDrillDown,
  productDrillDown,
  otherDrillDown,
  onLoadMore,
  onTitleClick,
}) => {
  return (
    <>
      <TrendsDrillDownWrapper
        id="trends-drilldown-store"
        drillDown={storeDrillDown}
        type={DrillDownTypes.Store}
        onTitleClick={onTitleClick}
        onLoadMore={onLoadMore}
      />
      <TrendsDrillDownWrapper
        id="trends-drilldown-product"
        drillDown={productDrillDown}
        type={DrillDownTypes.Product}
        onTitleClick={onTitleClick}
        onLoadMore={onLoadMore}
      />
      <TrendsDrillDownWrapper
        id="trends-drilldown-other"
        drillDown={otherDrillDown}
        type={DrillDownTypes.Other}
        onTitleClick={onTitleClick}
        onLoadMore={onLoadMore}
      />
    </>
  );
};

export default DrillDowns;
