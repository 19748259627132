import { IActionType } from 'types';

const isDevelopmentMode = process.env.NODE_ENV === 'development';

const logMiddleware = () => (next: any) => (action: IActionType) => {
  if (isDevelopmentMode) {
    // eslint-disable-next-line no-console
    console.log(action);
  }

  return next(action);
};

export default logMiddleware;
