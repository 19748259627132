import { FC } from 'react';

import { IVideoPreview } from 'types';
import './videoPreview.scss';
import { VideoPlayer } from 'components/videoPlayer';
import VideoDetails from 'components/knowledgeCenter/videoDetails/VideoDetails';

export const VideoPreview: FC<IVideoPreview> = ({
  videoSrc,
  title,
  date,
  info,
  duration,
  posterSrc,
  subtitleSrc,
}) => {
  return (
    <div className="video-preview">
      <VideoPlayer
        videoSrc={videoSrc}
        posterSrc={posterSrc}
        subtitlesSrc={subtitleSrc}
        duration={duration}
        title={title}
      />
      <VideoDetails title={title} date={date} info={info} />
    </div>
  );
};
