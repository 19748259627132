import { FC } from 'react';
import { Progress } from 'antd';

import { ISummaryTopFindingsItem } from 'types';
import './summaryTopFindingsReasonCard.scss';

const SummaryTopFindingsReasonCard: FC<ISummaryTopFindingsItem> = ({ name, count, rate }) => (
  <div className="reason-card">
    <div className="name">{name}</div>
    <div className="count">{count}</div>
    <Progress percent={rate} size="small" showInfo={false} />
  </div>
);

export default SummaryTopFindingsReasonCard;
