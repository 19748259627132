import { FC } from 'react';
import { connect } from 'react-redux';

import { IChartWrapper, IRootState, TrendsTabOptions } from 'types';
import TrendsSelector from 'store/trends/trendsSelector';
import BarChart from 'components/trends/barChart/BarChart';
import LineChart from 'components/trends/lineChart/LineChart';
import { isEmptyArray } from 'utils';
import { setSelectedMonth } from 'store/trends/trendsActions';
import ChartEmptyState from './ChartEmptyState';
import UserProfileSelector from 'store/userProfile/userProfileSelector';

const ChartWrapper: FC<IChartWrapper> = ({
  selectedTab,
  currency,
  responseRateData,
  salesIncreaseData,
  hitRateData,
  isRtl,
  setSelectedMonth,
  selectedMonth,
  shouldDisplayInWeeksResolution,
  isFiltersApplied,
  searchText,
  haveOpportunitiesBeenDeployed,
}) => {
  const isBarChart = selectedTab === TrendsTabOptions.Earning;

  const isHitRate = selectedTab === TrendsTabOptions.HitRate;

  const lineChartData = isHitRate ? hitRateData : responseRateData;

  const isBarChartEmpty = isBarChart && isEmptyArray(salesIncreaseData);

  const isLineChartEmpty = !isBarChart && isEmptyArray(lineChartData);

  if (isBarChartEmpty || isLineChartEmpty) {
    return (
      <div className="trends-empty-state-container">
        <ChartEmptyState
          haveOpportunitiesBeenDeployed={haveOpportunitiesBeenDeployed}
          searchText={searchText}
          isFiltersApplied={isFiltersApplied}
          selectedTab={selectedTab}
        />
      </div>
    );
  }

  const onBarClick = (date: number) => {
    let updatedSelectedMonth: number | null = date;
    if (date === selectedMonth) {
      updatedSelectedMonth = null;
    }
    setSelectedMonth(updatedSelectedMonth);
  };

  const onClearSelection = () => setSelectedMonth(null);

  return (
    <div className="trends-chart">
      {isBarChart && (
        <BarChart
          onClearSelection={onClearSelection}
          data={salesIncreaseData || []}
          currency={currency}
          selectedMonth={selectedMonth}
          isRtl={isRtl}
          onClick={onBarClick}
        />
      )}
      {!isBarChart && (
        <LineChart
          isWeeklyResolution={shouldDisplayInWeeksResolution}
          isRtl={isRtl}
          data={lineChartData || []}
          shouldDisplayYearLineLabel
          isSmallChart={false}
          shouldDisplayLegend
          shouldDisplayVerticalGrid={false}
          isSummaryPage={false}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    general: { currency },
    isRtl,
  } = state.settings;

  const responseRateData = TrendsSelector.getResponseRateData(state);

  const hitRateData = TrendsSelector.getHitRateData(state);

  const shouldDisplayInWeeksResolution = TrendsSelector.checkIsThreeUpToMonthsRange(state);

  const isFiltersApplied = TrendsSelector.checkIsFiltersApplied(state);

  const searchText = TrendsSelector.getSearchFilter(state);

  const haveOpportunitiesBeenDeployed = UserProfileSelector.haveOpportunitiesBeenDeployed(state);

  const {
    filters: { selectedTab, selectedMonth },
    salesIncrease: { data: salesIncreaseData },
  } = state.trends;

  return {
    currency,
    selectedTab,
    responseRateData,
    salesIncreaseData,
    hitRateData,
    isRtl,
    selectedMonth,
    shouldDisplayInWeeksResolution,
    isFiltersApplied,
    searchText,
    haveOpportunitiesBeenDeployed,
  };
};

const mapDispatchToProps = {
  setSelectedMonth,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartWrapper);
