import { FC } from 'react';
import { connect } from 'react-redux';

import { IPopups, IRootState, PopupIds } from 'types';
import PopupActions from 'store/popup/popupActions';
import OpportunityPopup from 'components/opportunityPopup/opportunityPopup';
import ProductImagePopup from 'components/myOpportunities/productImagePopup/ProductImagePopup';
import LiveInventoryPopup from 'components/myOpportunities/cardPreview/cardDetails/categories/liveInventoryPopup/liveInventoryPopup';

const Popups: FC<IPopups> = ({ id }) => {
  if (!id) {
    return null;
  }

  return (
    <>
      {(id === PopupIds.FindingsOpportunity || id === PopupIds.OpenOpportunitiesOpportunity) && (
        <OpportunityPopup />
      )}
      {id === PopupIds.OpportunityLiveInventory && <LiveInventoryPopup />}
      {id === PopupIds.OpportunityProductImage && <ProductImagePopup />}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { id } = state.popup;

  return {
    id,
  };
};

const mapDispatchToProps = {
  hide: PopupActions.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
