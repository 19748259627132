import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';

import './previewHeader.scss';
import { IFindingsPreviewHeader, ValueType } from 'types';
import { toLanguageNumberFormat } from 'utils';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const PreviewHeader: FC<IFindingsPreviewHeader> = ({
  valueType,
  totalCount,
  totalIssueFound,
  onValueTypeChange,
}) => {
  const [t] = useTranslation();

  const percent = Math.round((totalIssueFound / totalCount) * 100);

  const displayedTotalIssueFound = toLanguageNumberFormat(totalIssueFound);

  const displayedTotalCount = toLanguageNumberFormat(totalCount);

  const totalText = `${t('COMMON.TOTAL')} ${displayedTotalIssueFound}`;

  const hitRateText = `${t(
    'COMMON.HIT_RATE',
  )} ${percent}% ${displayedTotalIssueFound}/${displayedTotalCount}`;

  const isDefaultChecked = valueType === ValueType.Number;

  const onToggle = (isChecked: boolean) => {
    const newValueType = isChecked ? ValueType.Number : ValueType.Percent;
    AnalyticSrv.sendChangeEvent(AnalyticsEventCategory.SWITCH, { value: newValueType });
    onValueTypeChange(newValueType);
  };

  return (
    <div className="findings-header-container">
      <div className="text">
        {totalText}&ensp;|&ensp;{hitRateText}
      </div>
      <Switch
        checkedChildren={ValueType.Number}
        unCheckedChildren={ValueType.Percent}
        defaultChecked={isDefaultChecked}
        onChange={onToggle}
      />
    </div>
  );
};

export default PreviewHeader;
