import './NoMatch.scss';
import { useTranslation } from 'react-i18next';

const NoMatch = () => {
  const [t] = useTranslation();

  return <div className="no-match">{t('COMMON.PAGE_NOT_FOUND')}</div>;
};

export default NoMatch;
