import { FC } from 'react';
import './videoPoster.scss';

import { formatDurationToMinuteSeconds } from 'utils';
import { PlayIcon } from 'components/Icons';

type VideoPosterProps = {
  posterSrc: string;
  onClick?: () => void;
  duration?: number;
};

export const VideoPoster: FC<VideoPosterProps> = ({ posterSrc, onClick, duration }) => {
  return (
    <div className="video-poster-container" onClick={onClick}>
      <img alt="poster" className="video-poster" src={posterSrc} />
      <div className="poster-icon-container">
        <PlayIcon />
      </div>

      {duration && (
        <span className="c-video-duration">{formatDurationToMinuteSeconds(duration)}</span>
      )}
    </div>
  );
};
