import { FC, useCallback, useState } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch } from 'antd';

import { show } from 'store/alert/alertActions';
import OpportunitiesSalesChart from './opportunitiesSalesChart/OpportunitiesSalesChart';
import { InfoBox } from 'components/infoBox';
import Categories from './categories/Categories';
import {
  AlertButtonsIds,
  AlertIds,
  IAlertModel,
  IPopupModal,
  IPreviewContent,
  IRootState,
  PopupIds,
  ValueType,
} from 'types';
import NumberUtils from 'utils/NumberUtils';
import { show as showLiveInventoryPopup } from 'store/popup/popupActions';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';
import SelectedReason from 'components/opportunityPopup/selectedReason/selectedReason';
import featureFlagsService from 'services/permissions/featureFlagsService';

const PreviewContent: FC<IPreviewContent> = ({
  targetSales,
  targetUnits,
  potentialSales,
  potentialUnits,
  productCategories,
  lastSalesValuesTotal,
  lastSimilarSalesValuesTotal,
  lastUnitsValues,
  lastSimilarUnitsValuesTotal,
  lastSimilarUnitsValues,
  lastUnitsValuesTotal,
  lastSalesValues,
  isRtl,
  currency,
  lastSimilarSalesValues,
  inventoryUpdate,
  productSkusAndSizes,
  showLiveInventoryPopup,
  planogramAttributes,
  show,
  showReason,
  flavour,
}) => {
  const [t] = useTranslation();

  const [isUnitsValues, setIsUnitsValues] = useState(false);

  const lastValues = isUnitsValues ? lastUnitsValues || [] : lastSalesValues;

  const lasSimilarValues = isUnitsValues ? lastSimilarUnitsValues || [] : lastSimilarSalesValues;

  const formattedTargetSales = NumberUtils.toCurrency(targetSales, currency);

  const formattedPotentialSales = NumberUtils.toCurrency(potentialSales, currency);

  const summableWeeksCount = lastValues.filter((sale) => sale.summable).length || 0;

  const mayViewUnitsGraph =
    featureFlagsService.mayViewTaskUnitsGraph() && lastUnitsValues && lastSimilarUnitsValues;

  const onLiveInventoryClick = useCallback(() => {
    const options: IPopupModal = {
      id: PopupIds.OpportunityLiveInventory,
      selectedOpportunityId: null,
    };

    AnalyticSrv.sendClickEvent(AnalyticsEventCategory.LIVE_INVENTORY);
    showLiveInventoryPopup(options);
  }, [showLiveInventoryPopup]);

  const onQuestionMarkClick = useCallback(() => {
    const options: IAlertModel = {
      isVisible: true,
      id: AlertIds.OpenComparableStores,
      withOverlay: true,
      title: t(`MY_OPPORTUNITIES.COMPARABLE_STORES`),
      text: t(`MY_OPPORTUNITIES.COMPARABLE_STORES_POPUP_INFO`),
      buttons: [{ id: AlertButtonsIds.OK, text: AlertButtonsIds.OK }],
      alertCustomClassName: 'comparable_stores_alert',
    };

    show(options);
  }, [show]);

  const Icon = (
    <div className="question-mark" onClick={onQuestionMarkClick}>
      ?
    </div>
  );

  const onChartSwitchChanged = () => {
    setIsUnitsValues(!isUnitsValues);
  };

  return (
    <div className="product-sale-data">
      <div className="product-sale-data-header">
        <div className="title">{t('MY_OPPORTUNITIES.PRODUCT_SALES_LAST_WEEKS')}</div>
        {mayViewUnitsGraph && (
          <Switch
            className="opportunity-toggle"
            checkedChildren={ValueType.Number}
            unCheckedChildren={currency}
            onChange={onChartSwitchChanged}
          />
        )}
      </div>

      <div className="chart-info">
        <div className="chart">
          <OpportunitiesSalesChart
            lastSalesValues={lastValues}
            isRtl={isRtl}
            isUnitsValues={isUnitsValues}
            currency={currency}
            lastSimilarSalesValues={lasSimilarValues}
          />
          <div className="sub-title">
            {t('MY_OPPORTUNITIES.OVER_THE_LAST_WEEKS', { summableWeeksCount })}
          </div>
          <div className="info">
            <InfoBox
              icon={Icon}
              currency={currency}
              isUnitsValues={isUnitsValues}
              title={t('MY_OPPORTUNITIES.COMPARABLE_STORES')}
              amount={isUnitsValues ? lastSimilarUnitsValuesTotal : lastSimilarSalesValuesTotal}
              className={cn('large-info-box', 'comparable-store')}
            />
            <InfoBox
              currency={currency}
              isUnitsValues={isUnitsValues}
              title={t('MY_OPPORTUNITIES.YOUR_STORE')}
              amount={isUnitsValues ? lastUnitsValuesTotal : lastSalesValuesTotal}
              className="large-info-box"
            />
          </div>
        </div>
        <div className="info">
          <Categories
            productSkusAndSizes={productSkusAndSizes}
            productCategories={productCategories}
            inventoryUpdate={inventoryUpdate}
            monthlySalesTarget={formattedTargetSales}
            monthlySalesTargetUnits={targetUnits}
            potentialUnits={potentialUnits}
            potentialRevenue={formattedPotentialSales}
            onLiveInventoryClick={onLiveInventoryClick}
            planogramAttributes={planogramAttributes}
            flavour={flavour}
          />
        </div>
      </div>
      {showReason && <SelectedReason />}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    general: { currency },
    isRtl,
  } = state.settings;

  return {
    currency,
    isRtl,
  };
};

const mapDispatchToProps = {
  show,
  showLiveInventoryPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContent);
