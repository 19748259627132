import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DecoratedText } from 'components/text';
import { AddressIcon, CategoryIcon, TargetIcon } from 'components/Icons';
import { CurrencySymbolOptions } from 'types';
import NumberUtils from 'utils/NumberUtils';

type FooterProps = {
  productCategories: string;
  storeName: string;
  targetSales: number;
  currency: CurrencySymbolOptions;
};

const MyOpportunityCardFooter: FC<FooterProps> = ({
  productCategories,
  storeName,
  targetSales,
  currency,
}) => {
  const [t] = useTranslation();

  const formattedTargetSales = NumberUtils.toCurrency(targetSales, currency);

  return (
    <div className="card-footer">
      <DecoratedText
        containerClass="card-footer-store"
        textClass="card-footer-text"
        icon={<AddressIcon />}
        text={storeName}
        isHidden={!storeName}
      />
      <DecoratedText
        containerClass="card-footer-category"
        textClass="card-footer-text"
        icon={<CategoryIcon />}
        text={productCategories}
        isHidden={!productCategories}
      />
      <DecoratedText
        containerClass="card-footer-sales"
        textClass="card-footer-text"
        icon={<TargetIcon />}
        text={t('MY_OPPORTUNITIES.TARGET_SALES', { targetSales: formattedTargetSales })}
        isHidden={!targetSales}
      />
    </div>
  );
};

export default MyOpportunityCardFooter;
