import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { OrderButton } from 'components/buttons';
import 'components/myOpportunities/filters/ordersButtons/ordersButtons.scss';
import { setMyOpportunitiesGroupByOrder } from 'store/filters/filtersActions';
import { IGroupByOption, IRootState } from 'store/types';
import FiltersSelector from 'store/filters/filtersSelector';

export interface IGroupByProps {
  selectedGroupBy: IGroupByOption;
  groupByOptions: IGroupByOption[];
  setMyOpportunitiesGroupByOrder(groupBy: IGroupByOption): void;
}

const GroupByButtons: FC<IGroupByProps> = ({
  selectedGroupBy,
  groupByOptions,
  setMyOpportunitiesGroupByOrder,
}) => {
  const [t] = useTranslation();

  const onChangeGroupBy = useCallback(
    (key: string) => {
      const selectedOption = groupByOptions.find((option) => option.key === key);

      if (selectedOption) {
        setMyOpportunitiesGroupByOrder(selectedOption);
      }
    },
    [groupByOptions],
  );

  return (
    <div className="group-options">
      <div className="sub-title">{t('MY_OPPORTUNITIES.GROUP.GROUP_BY')}</div>
      <div className="sort-buttons">
        {groupByOptions.map((option) => {
          return (
            <OrderButton
              key={option.key}
              id={option.value}
              label={t(option.value)}
              isSelected={selectedGroupBy.key === option.key}
              buttonKey={option.key}
              onClick={onChangeGroupBy}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedGroupBy: state.filters.myOpportunities.filters.groupBy,
  groupByOptions: FiltersSelector.getMyOpportunitiesGroupByOptionsSelector(state),
});

const mapDispatchToProps = {
  setMyOpportunitiesGroupByOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupByButtons);
