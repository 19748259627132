import { FC } from 'react';
import { Tabs as TabsAnt } from 'antd';
import cn from 'clsx';

import { ITab, ITabs } from 'types';

import './tabs.scss';

const { TabPane } = TabsAnt;

export const Tabs: FC<ITabs> = ({
  activeTab,
  tabs,
  onChange,
  isRtl,
  textClassName,
  containerClassName,
  id,
}) => {
  const direction = isRtl ? 'rtl' : 'ltr';

  const renderTab = (item: ITab) => (
    <span
      id={`${id}-${item.id}`}
      className={cn('tab', [textClassName], {
        activeTab: item.id === activeTab,
      })}
    >
      {item.label}
      {item.amount && <span className={cn('amount', direction)}>{item.amount}</span>}
    </span>
  );

  const onTabChange = (id: string) => onChange(Number(id));

  return (
    <div className={containerClassName}>
      <TabsAnt direction={direction} activeKey={String(activeTab)} onChange={onTabChange}>
        {tabs.map((item) => (
          <TabPane tab={renderTab(item)} key={item.id} />
        ))}
      </TabsAnt>
    </div>
  );
};
