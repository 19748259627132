import { SelectedDateIntervals } from 'types';
import DateUtils from 'utils/DateUtils';

export const xmlns = 'http://www.w3.org/2000/svg';

export const EVENTS = {
  SCROLL: 'scroll',
};

export const MEDIA_COLLAPSE_WIDTH = 1280;

export const MEDIA_HIDE_WIDTH = 1024;

const DAY_IN_MILLISECONDS = 60 * 60 * 1000 * 24;

export const PERCENTAGE_SYMBOL = '%';

export const SIZE = 50;

export const MILLISECONDS: {
  HOUR: number;
  DAY: number;
  WEEK: number;
  MONTH: number;
  YEAR: number;
} = {
  HOUR: DAY_IN_MILLISECONDS / 24,
  DAY: DAY_IN_MILLISECONDS,
  WEEK: DAY_IN_MILLISECONDS * 7,
  MONTH: DAY_IN_MILLISECONDS * 7 * 4,
  YEAR: DAY_IN_MILLISECONDS * 365,
};

export const DEBOUNCE_SEARCH_DELAY = 500;

export const PRODUCT_KEY = 'product';

export const SKU_KEY = 'sku';

export const SIZE_KEY = 'size';

export const DateIntervals: { [key: string]: number } = {
  [SelectedDateIntervals.twelveMonthsId]: MILLISECONDS.YEAR,
  [SelectedDateIntervals.threeMonthsId]: MILLISECONDS.MONTH * 3,
  [SelectedDateIntervals.sixMonthsId]: MILLISECONDS.YEAR / 2,
  [SelectedDateIntervals.monthId]: MILLISECONDS.MONTH,
  [SelectedDateIntervals.weekId]: MILLISECONDS.WEEK,
  [SelectedDateIntervals.yearId]: DateUtils.getYearToDate(),
};

export const MonthsInterval = {
  [SelectedDateIntervals.twelveMonthsId]: 12,
  [SelectedDateIntervals.sixMonthsId]: 6,
  [SelectedDateIntervals.threeMonthsId]: 3,
  [SelectedDateIntervals.monthId]: 1,
  [SelectedDateIntervals.yearId]: DateUtils.getYTDMonths(),
};
