import {
  direction,
  fieldName,
  filterKey,
  HierarchyLevel,
  IHierarchyPath,
  ILeaderBoardRow,
  IDrillDownRow,
  IRateRow,
  IReasonFilterGroupsMap,
  ISummaryActiveFilters,
  LEADER_BOARD_LIST_SIZE,
  SelectedDateIntervals,
} from 'types';
import { FilterBuilder } from 'store/filters/filterBuilder';
import { DateIntervals, MonthsInterval } from 'constants/constantsVaribles';
import { getSubtractDateByMonths, getPercent, isEmptyArray } from 'utils';

export const buildFindingsFilter = (
  filterBuilder: FilterBuilder,
  topFindingsDateFilter: SelectedDateIntervals,
) => {
  filterBuilder.and().relative(filterKey.DEPLOY_DATE, DateIntervals[topFindingsDateFilter]);

  filterBuilder.and().term(filterKey.RESPONSE_TYPE, 'ISSUE_FOUND');

  return filterBuilder;
};

export const buildTopFindingsFilter = (
  filterBuilder: FilterBuilder,
  topFindingsDateFilter: SelectedDateIntervals,
) => {
  filterBuilder.and().relative(filterKey.DEPLOY_DATE, DateIntervals[topFindingsDateFilter]);

  return filterBuilder;
};

export const buildGetReasonsRequestFilterBody = (
  topFindingsDateFilter: SelectedDateIntervals,
  storeNameFilter?: string,
): any => {
  const filterBuilder = new FilterBuilder();

  buildTopFindingsFilter(filterBuilder, topFindingsDateFilter);

  if (storeNameFilter) {
    filterBuilder.and().term(fieldName.storeName, storeNameFilter);
  }

  const filter = filterBuilder.build();

  const orders = [{ direction: direction.DESC, fieldName: fieldName.count }];

  return { filter, orders };
};

export const buildTotalOpportunitiesCapturedFilterBody = (
  activeFilters: ISummaryActiveFilters,
  storeFilterName?: string,
): any => {
  const filterBuilder = new FilterBuilder();

  const { kpiDateFilter } = activeFilters;

  filterBuilder.and().relative(filterKey.DEPLOY_DATE, DateIntervals[kpiDateFilter]);

  if (storeFilterName) {
    filterBuilder.and().term(fieldName.storeName, storeFilterName);
  }

  const filter = filterBuilder.build();

  const orders = [{ direction: direction.DESC, fieldName: fieldName.deployDate }];

  return { filter, orders };
};

export const buildComparisonFilterBody = (storeFilterName?: string) => {
  const orders = [{ direction: direction.DESC, fieldName: fieldName.deployDate }];

  if (storeFilterName) {
    const filterBuilder = new FilterBuilder();

    filterBuilder.and().term(fieldName.storeName, storeFilterName);

    const filter = filterBuilder.build();

    return { filter, orders };
  }

  return { orders };
};

export const buildSalesIncreaseFilterBody = (
  activeFilters: ISummaryActiveFilters,
  storeFilterName?: string,
): any => {
  const filterBuilder = new FilterBuilder();

  const { kpiDateFilter } = activeFilters;

  const monthsToSubtract = MonthsInterval[kpiDateFilter];

  const from = getSubtractDateByMonths(monthsToSubtract);

  filterBuilder.and().time(filterKey.EARNING_DATE, from);

  if (storeFilterName) {
    filterBuilder.and().term(`task.${fieldName.storeName}`, storeFilterName);
  }

  const filter = filterBuilder.build();

  return { filter };
};

export const calculateComparison = (
  rateComparison: Array<IRateRow>,
): { value: number; compareToValue: number } => {
  let value = 0;
  if (!isEmptyArray(rateComparison)) {
    const { total } = rateComparison[0];
    value = total ? rateComparison[0].value / total : 0;
  }

  rateComparison.shift();

  const numberOfResponses = rateComparison.reduce(
    (accumulator, currentValue) => {
      return {
        response: accumulator.response + currentValue.value,
        total: accumulator.total + currentValue.total,
      };
    },
    { response: 0, total: 0 },
  );

  return {
    value,
    compareToValue:
      numberOfResponses.response === 0 ? 0 : numberOfResponses.response / numberOfResponses.total,
  };
};

const addReasonFilters = (filterBuilder: FilterBuilder, reasonFilter: Array<number>) => {
  reasonFilter.forEach((reasonId) => {
    filterBuilder.or().term(filterKey.REASON_ID, reasonId);
  });
};

// filter for drill downs
export const buildDrillDownFilter = (activeFilters: ISummaryActiveFilters) => {
  const { activeReasonFilters, topFindingsDateFilter } = activeFilters;

  const filterBuilder = new FilterBuilder();

  addReasonFilters(filterBuilder, activeReasonFilters);
  buildFindingsFilter(filterBuilder, topFindingsDateFilter);

  const filter = filterBuilder.build();

  const orders = [{ direction: direction.DESC, fieldName: fieldName.issueFoundCount }];

  return {
    filter,
    orders,
    page: {
      from: 0,
      size: 3,
    },
  };
};

export const buildRateHistogramFilterBody = (storeFilterName?: string): any => {
  const from = getSubtractDateByMonths(12);

  const filterBuilder = new FilterBuilder();
  filterBuilder.and().time(filterKey.DEPLOY_DATE, from);

  if (storeFilterName) {
    filterBuilder.and().term(fieldName.storeName, storeFilterName);
  }

  const filter = filterBuilder.build();

  return { filter };
};

export const getUpdatedActiveReasons = (
  reasonFilterGroupsMap: IReasonFilterGroupsMap,
  groupId: number,
  isActive: boolean,
) => {
  const updateReasonFilterGroup = { ...reasonFilterGroupsMap };

  const updateActiveReasonFilter: Array<number> = [];

  Object.entries(updateReasonFilterGroup).forEach(([reasonFilterGroupId, reasonFilterGroup]) => {
    const isInClickedGroup = groupId === Number(reasonFilterGroupId);

    const { reasonFilter: currentReasonFilter } = reasonFilterGroup;
    reasonFilterGroup.reasonFilter = currentReasonFilter.map((reasonFilterItem) => {
      const { value, id, label } = reasonFilterItem;

      if (isActive && isInClickedGroup) {
        updateActiveReasonFilter.push(id);
      }

      return {
        value,
        id,
        label,
        isActive: isActive && isInClickedGroup,
      };
    });
  });

  return {
    activeReasonFilters: updateActiveReasonFilter,
    reasonFilterGroupsMap: updateReasonFilterGroup,
  };
};

export const calculateOthersRateAsPercentageOfFirst = (list: Array<IDrillDownRow>) => {
  if (list[0].count === 0) {
    return list;
  }

  return list.map((item: IDrillDownRow, index: number) => ({
    ...item,
    rate: index === 0 ? 100 : getPercent(item.count, list[0].count),
  }));
};

export const buildLeaderBoardFilterBody = (leaderBoardDateFilter: number): any => {
  const filterBuilder = new FilterBuilder();
  filterBuilder.relative(filterKey.DEPLOY_DATE, DateIntervals[leaderBoardDateFilter]);

  return filterBuilder.build();
};

export const getMyIndex = (
  rows: Array<ILeaderBoardRow>,
  userHighestHierarchyLowestLevel: IHierarchyPath,
) =>
  rows.findIndex(
    (row: ILeaderBoardRow) =>
      row.storeId === userHighestHierarchyLowestLevel.id ||
      row.name === userHighestHierarchyLowestLevel.id,
  );

export const getLeaderBoardTopData = (rows: Array<ILeaderBoardRow>, myIndex: number) => {
  const sliceTo = myIndex <= 4 ? LEADER_BOARD_LIST_SIZE.MAXIMUM : LEADER_BOARD_LIST_SIZE.REGULAR;

  return rows.slice(0, sliceTo).map((row: ILeaderBoardRow, index: number) => {
    return {
      ...row,
      isSelected: index === myIndex,
    };
  });
};

export const getLeaderBoardBottomData = (
  rows: Array<ILeaderBoardRow>,
  myIndex: number,
  currentLevel: HierarchyLevel,
): Array<ILeaderBoardRow> => {
  if (currentLevel !== HierarchyLevel.ALL) {
    const begin = myIndex - 1;

    return rows
      .slice(begin, begin + LEADER_BOARD_LIST_SIZE.REGULAR)
      .map((row: any, index: number) => {
        return {
          ...row,
          isSelected: myIndex === index + begin,
        };
      });
  }

  return [];
};

export const buildOpenOpportunitiesFilter = (storeFilterName?: string) => {
  const filterBuilder = new FilterBuilder();

  const weeks = SelectedDateIntervals.monthId;

  filterBuilder.and().relative(filterKey.DEPLOY_DATE, DateIntervals[weeks]);
  filterBuilder.and().term(filterKey.LIFECYCLE, 'ACTIVE');

  if (storeFilterName) {
    filterBuilder.and().term(fieldName.storeName, storeFilterName);
  }

  return filterBuilder.build();
};
