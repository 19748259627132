import { FlavourTypes } from 'types';

export type ISettingsModel = {
  featureFlags: IFeatureFlag;
  general: IGeneralMetadata;
  taskItemsHeaders: ITaskItemsHeaders;
  taskExpiryDays: ITaskExpiryDays;
  firstDayOfWeek: string;
  flavour: FlavourTypes;
  messagingMetadata: any;
  notifications: any;
  productCategories: IProductCategories;
  productHierarchy: IHierarchy;
  storeCategories: ICategories;
  storeHierarchy: IHierarchy;
  productHierarchyDepth: number;
  storeHierarchyDepth: number;
  planogramAttributes: any;
  isRtl?: boolean;
  language: string;
  serverResponseStatus: number | null;
};

export interface IFeatureFlag {
  showInventoryData: boolean;
  showLeaderBoard: boolean;
  showPotentialRevenue: boolean;
  showPotentialUnits: boolean;
  showTargetSales: boolean;
  showTargetUnits: boolean;
  isLiveInventoryEnabled: boolean;
  isAdditionalProductInfoEnabled: boolean;
  isInAppMessagingEnabled: boolean;
  showOnboardingVideoOnFirstTimeLogin: boolean;
  showProductImages: boolean;
  showTaskUnitsGraph: boolean;
  showResponseImpactOnSales: boolean;
  isVideoKnowledgeCenterEnabled: boolean;
}

export interface IHierarchy {
  [key: string]: string;
}

type IProductCategories = Array<string>;

type ICategories = Array<string>;

export enum CurrencySymbolOptions {
  EUR = '€',
  USD = '$',
  ILS = '₪',
  PLN = 'zł',
  CZK = 'Kč',
  INR = '₹',
  JPY = '¥',
  GBP = '£',
  CNY = '¥',
  KRW = '₩',
  SEK = 'kr',
}

export interface IGeneralMetadata {
  additionalProductInfoAddress: null | string;
  currency: CurrencySymbolOptions;
  productImagesMetadata: string[];
}

type ITaskItemsHeaders = Array<any>;

interface ITaskExpiryDays {
  [key: string]: number;
}
