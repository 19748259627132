import { FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { VideoCard, VideoPreview } from 'components/knowledgeCenter';
import { getVideos, setSelectedVideo } from 'store/knowledgecenter/knowledgeCenterActions';
import LoadingSelector from 'store/loading/LoadingSelector';
import { IRootState, IVideoCard, IVideoPreview } from 'types';
import KnowledgeCenterSelector from 'store/knowledgecenter/knowledgeCenterSelector';
import { Cards } from 'components/cards';

interface IKnowledgeCenter {
  getVideos: Function;
  videos: Array<IVideoCard> | null;
  isLoading: boolean;
  selectedVideo: IVideoPreview | null;
  totalCount: number;

  setSelectedVideo(videoId: string): void;
}

export const KnowledgeCenter: FC<IKnowledgeCenter> = ({
  selectedVideo,
  getVideos,
  videos,
  setSelectedVideo,
  isLoading,
  totalCount,
}) => {
  const [t] = useTranslation();
  useEffect(() => {
    getVideos();
  }, []);

  const itemContent = useCallback(
    (index: number) => {
      const currentVideo = videos && videos![index];

      if (!currentVideo) {
        return null;
      }

      return (
        <VideoCard
          onVideoCardClick={setSelectedVideo}
          id={currentVideo.id}
          title={currentVideo.title}
          posterSrc={currentVideo.posterSrc}
          info={currentVideo.info}
          date={currentVideo.date}
          duration={currentVideo.duration}
        />
      );
    },
    [videos],
  );

  return (
    <div className="page">
      <div className="page-header">{t('PAGES.KNOWLEDGE_CENTER')}</div>
      <div className="page-container opposite-page">
        <div className="page-preview">
          <VideoPreview
            info={selectedVideo?.info || ''}
            videoSrc={selectedVideo?.videoSrc || ''}
            posterSrc={selectedVideo?.posterSrc || ''}
            title={selectedVideo?.title || ''}
            subtitleSrc={selectedVideo?.subtitleSrc || ''}
            date={selectedVideo?.date || 0}
            duration={selectedVideo?.duration}
            id={selectedVideo?.id || ''}
          />
        </div>
        <div className="page-list">
          <Cards
            dataTestId="knowledge-center-videos"
            isLoading={isLoading}
            cards={videos}
            // TODO - fetch more with filters
            onLoadMore={() => {}}
            totalCount={totalCount}
            itemContent={itemContent}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const isLoading = LoadingSelector.isGetKnowledgeCenterVideos(state);

  return {
    isLoading,
    videos: KnowledgeCenterSelector.getVideoCardsSelector(state),
    selectedVideo: KnowledgeCenterSelector.getSelectedVideosSelector(state),
    totalCount: state.knowledgeCenter.totalCount,
  };
};

const mapDispatchToProps = {
  getVideos,
  setSelectedVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeCenter);
