import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import backend from 'i18next-xhr-backend';

const options: any = {
  fallbackLng: 'en-US',
  react: {
    useSuspense: false,
  },
  load: 'currentOnly',
};

i18n.use(backend).use(initReactI18next).init(options);

export default i18n;
