import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISummaryDateFilter } from 'components/pages/summary/types';
import { Dropdown } from 'components/dropdown';
import { CalendarIcon } from 'components/Icons';
import { SelectedDateIntervals } from 'store/filters/types';

export const DateFilter: FC<ISummaryDateFilter> = ({ options, onChange, selectedOption }) => {
  const [t] = useTranslation();

  const buildDateFilterOptions = (options: Array<SelectedDateIntervals>) => {
    return options.map((dateInterval) => ({ label: t(dateInterval), key: dateInterval }));
  };

  return (
    <div className="date-select-container">
      <Dropdown
        options={buildDateFilterOptions(options)}
        onChange={onChange}
        selectedOption={selectedOption}
        icon={<CalendarIcon className="date-select-icon" />}
      />
    </div>
  );
};
