import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import APIConstants from 'store/api/APIConstants';
import LoadingActions from 'store/loading/LoadingActions';
import ToastActions from 'store/toast/toastActions';
import routerService from 'services/router/routerService';

export const handleErrors = (response: Response, dispatch: Dispatch<AnyAction> | undefined) => {
  // if user unauthorized we will navigate him to the logout page for a clean logout
  if (response.status === 401) {
    // eslint-disable-next-line no-console
    console.warn('Unauthorized request');
    if (dispatch) {
      dispatch(ToastActions.error('Unauthorized request'));
    }

    routerService.routeToLogout();
  }

  if (!response.ok) {
    throw response;
  }

  // zendesk response is ok with error message in the body, we refer it as successful form submission
  if (response.ok && response.url.includes('/enformed/3jou9ew0')) {
    return Response;
  }

  return response.json();
};

export const handleSuccess = (response: Response, dispatch: Dispatch<AnyAction>, action: any) => {
  if (dispatch) {
    const {
      baseAction = action.baseAction ?? APIConstants.createAPIRequestAction('INVALID_API'),
      payload,
    } = action;

    dispatch({
      type: baseAction.SUCCESS,
      payload: response,
      requestPayload: payload,
    });

    dispatch(LoadingActions.removeLoading(baseAction.BASE));
  }
};

export const handleException = (
  error: Response,
  dispatch: Dispatch<AnyAction> | undefined,
  action: any | undefined,
) => {
  let operationBaseAction = APIConstants.createAPIRequestAction('INVALID_API');
  let operationPayload = {};

  if (dispatch && action) {
    operationBaseAction = action.baseAction ? action.baseAction : operationBaseAction;
    operationPayload = action.payload ? action.payload : {};
  }

  if (error.json) {
    error
      .json()
      .then((jsonResponse) => {
        // eslint-disable-next-line no-console
        console.warn('Error response', jsonResponse);
        if (dispatch) {
          dispatch({
            type: operationBaseAction.FAILURE,
            payload: jsonResponse,
            requestPayload: operationPayload,
          });

          dispatch(LoadingActions.removeLoading(operationBaseAction.BASE));
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.warn('Error', Error);
        if (dispatch) {
          dispatch({
            type: operationBaseAction.FAILURE,
            payload: { error },
            requestPayload: operationPayload,
          });

          dispatch(LoadingActions.removeLoading(operationBaseAction.BASE));
        }
      });
  } else {
    // eslint-disable-next-line no-console
    console.warn('Error', Error);
    if (dispatch) {
      dispatch({
        type: operationBaseAction.FAILURE,
        payload: { error },
        requestPayload: operationPayload,
      });

      dispatch(LoadingActions.removeLoading(operationBaseAction.BASE));
    }
  }
};
