import { FC, memo } from 'react';
import { connect } from 'react-redux';
import './OpenOpportunities.scss';

import PreviewHeader from 'components/openOpportunities/preview/previewHeader/previewHeader';
import { IOpenOpportunitiesPreviewProps } from './types';
import PreviewFilters from 'components/openOpportunities/preview/previewFilters/previewFilters';
import { IRootState } from 'store/types';
import PreviewCardsList from 'components/openOpportunities/preview/previewCardsList/previewCardsList';

const OpenOpportunitiesPreview: FC<IOpenOpportunitiesPreviewProps> = ({
  selectedCard,
  filters,
}) => {
  const { selectedTab, selectedDropdownOption, searchValue, sortDirection } = filters;

  return (
    <div className="preview-container white">
      <PreviewHeader type={selectedCard} key={selectedCard} searchValue={searchValue} />

      <PreviewFilters
        selectedDropdownOption={selectedDropdownOption}
        selectedTab={selectedTab}
        type={selectedCard}
        sortDirection={sortDirection}
      />

      <div className="preview-content-container">
        <PreviewCardsList cardType={selectedCard} filters={filters} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { filters } = state.openOpportunities.drillDown;

  return {
    filters,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(memo(OpenOpportunitiesPreview));
