import { FC } from 'react';

import ReasonFilter from 'components/findings/reasons/reasonFilter/ReasonFilter';
import './reasonFilterGroup.scss';
import { IReasonFilterGroupProps } from 'types';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const ReasonFilterGroup: FC<IReasonFilterGroupProps> = ({
  groupId,
  type,
  onReasonClick,
  list,
  color,
}) => {
  const onReasonFilterClick = (reasonId: number) => {
    onReasonClick(reasonId, groupId);
    AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.REASONS);
  };

  return (
    <div className="reason-filter-group" data-testid="reason-filter-group" style={{ color }}>
      {list.map((reason) => (
        <ReasonFilter
          key={reason.id}
          isActive={reason.isActive}
          id={reason.id}
          label={reason.label}
          value={reason.value}
          type={type}
          onReasonClick={onReasonFilterClick}
        />
      ))}
    </div>
  );
};

export default ReasonFilterGroup;
