import cn from 'clsx';
import { FC } from 'react';
import './WideButton.scss';

export interface WideButtonProps {
  /**
   * button id
   */
  id: string;
  /**
   * Is this the button active
   */
  isActive?: boolean;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: (id: string) => void;
  /**
   * Optional click handler
   */
  tabIndex?: number;
}

/**
 * Primary UI component for user interaction
 */
export const WideButton: FC<WideButtonProps> = ({
  isActive,
  label,
  id,
  onClick,
  tabIndex,
  ...props
}) => {
  const onButtonClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div
      id={`wide-button-${id}`}
      role="button"
      tabIndex={tabIndex}
      className={cn('wide-button', 'no-selectable', isActive && 'active')}
      onClick={onButtonClick}
    >
      <button type="button" className={cn('button', isActive && 'active')} {...props}>
        {label}
      </button>
    </div>
  );
};
