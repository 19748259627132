import { FC } from 'react';
import './contactUs.scss';
import { useTranslation } from 'react-i18next';

import ContactUsForm from './ContactUsForm';
import { ContactUsIcon } from 'components/Icons';

export const ContactUs: FC = () => {
  const [t] = useTranslation();

  return (
    <div className="page">
      <div className="page-header">{t('PAGES.CONTACT_US')}</div>
      <div className="page-container">
        <div className="page-list">
          <div className="contact-us-form-container">
            <div className="form_title">{t('CONTACT_US.QUESTIONS')}</div>
            <div className="form_text">{t('CONTACT_US.WANT_YOUR_INPUT')}</div>
            <ContactUsForm />
            <div className="spacer" />
          </div>
        </div>
        <div className="page-preview contact-us-image-container">
          <ContactUsIcon className="contact-us-image" />
        </div>
      </div>
    </div>
  );
};
