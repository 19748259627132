import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import cn from 'clsx';

import Modal from 'components/common/Modal/Modal';
import PopupActions from 'store/popup/popupActions';
import { CloseIcon } from 'components/Icons';
import './popup.scss';
import { IPopup } from 'types';
import { transformToArray } from 'utils';

const Header: FC = ({ children }) => <div className="popup-header">{children}</div>;

const Content: FC = ({ children }) => <div className="popup-content">{children}</div>;

const Popup = ({ children, popupClassName, withOverlay, hide }: IPopup) => {
  const processChildren = (): { content: null | ReactNode; header: null | ReactNode } => {
    const emptyArray = {
      content: null,
      header: null,
    };

    if (!children) return emptyArray;

    // transform to array
    const childrenArray = transformToArray(children);

    return childrenArray.reduce((acc, child) => {
      const { name } = child.type;

      if (name === Content.name) {
        return { ...acc, content: child };
      }

      if (name === Header.name) {
        return { ...acc, header: child };
      }

      return acc;
    }, emptyArray);
  };

  const popupData = processChildren();

  return (
    <Modal onEsc={hide} onClickOutside={hide} withOverlay={!!withOverlay}>
      <div className="popup-container">
        <button onClick={hide} type="button" className={cn('button', 'popup-close-button')}>
          <CloseIcon id="popup-close-button-icon" />
        </button>
        <div className={cn([popupClassName])}>
          {popupData.header}
          {popupData.content}
        </div>
      </div>
    </Modal>
  );
};

Popup.Header = Header;
Popup.Content = Content;

const mapDispatchToProps = {
  hide: PopupActions.hide,
};

export default connect(null, mapDispatchToProps)(Popup);
