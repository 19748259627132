import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

import {
  IReasonFilterGroups,
  IRootState,
  ISettingsModel,
  ITopFindingsDrillDown,
} from 'store/types';
import { ArrowIconDirection, DrillDownTypes, ITrendsLineChartRow } from 'types';
import { getDatesMonthsDiff, getLongDateFormat, isEmptyArray } from 'utils';
import {
  getOtherDrillDownCategories,
  isSettingsLoadedSuccessfully,
} from 'store/settings/settingsHepler';
import {
  getOtherDrillDownIsHidden,
  getOtherSelectedCategoryHelper,
  getReasonsFilterGroupsHelper,
} from 'store/findings/findingsHelper';
import { calculateOthersRateAsPercentageOfFirst } from 'store/summary/summaryHelper';
import { buildHitRateData, buildResponseRateData } from 'store/trends/trendsHelper';

const getSummarySelector = (state: IRootState) => state.summary;

const getReasonsGroups = (state: IRootState) => state.summary.reasonFilterGroups;

const getReasonGroupsMap = (state: IRootState) => state.summary.reasonFilterGroupsMap;

const getSettingsSelector = (state: IRootState) => state.settings;

const getReasonFilterToColorMap = (state: IRootState) => state.summary.reasonFilterToColorMap;

const getResponseRateDataSelector = (state: IRootState) => state.summary.responseRate.data;

const getResponseRateChainWideDataSelector = (state: IRootState) =>
  state.summary.responseRate.chainWideData;

const getHitRateDataSelector = (state: IRootState) => state.summary.hitRate.data;

const getHitRateChainWideDataSelector = (state: IRootState) => state.summary.hitRate.chainWideData;

const getResponseRateArrowDirection = createSelector([getSummarySelector], (getSummarySelector) => {
  const {
    numberOfWeeksForResponseRateComparison,
    responseRateValue,
    responseRateCompareToValue,
  } = getSummarySelector;

  if (numberOfWeeksForResponseRateComparison === 1) {
    return undefined;
  }

  return responseRateValue * 100 >= responseRateCompareToValue * 100
    ? ArrowIconDirection.UP
    : ArrowIconDirection.DOWN;
});

const getHitRateArrowDirection = createSelector([getSummarySelector], (getSummarySelector) => {
  const {
    numberOfWeeksForHitRateComparison,
    hitRateValue,
    hitRateCompareToValue,
  } = getSummarySelector;

  if (numberOfWeeksForHitRateComparison === 1) {
    return undefined;
  }

  return hitRateValue * 100 >= hitRateCompareToValue * 100
    ? ArrowIconDirection.UP
    : ArrowIconDirection.DOWN;
});

const lastUserDeployDate = (state: IRootState) => state.userProfile.lastUserDeployDate;

const openTasksWeekOf = (state: IRootState) => state.summary.openTasksWeekOf;

const selectedStoreFilter = (state: IRootState) => state.summary.selectedStoreFilter;

const stores = (state: IRootState) => state.userProfile.stores;

const getStoreDropdownOptions = createSelector([stores], (options) => {
  return (
    options?.map((option) => ({
      label: option.storeName,
      key: option.id,
    })) || []
  );
});

const getStoreSelectedDropdownOption = createSelector(
  [getStoreDropdownOptions, selectedStoreFilter],
  (dropdownOptions, store) => {
    return (
      dropdownOptions?.find((option) => option.key === store?.id) || {
        label: '',
        key: '',
      }
    );
  },
);

const getOpenTasksDate = createSelector(
  [lastUserDeployDate, openTasksWeekOf],
  (lastUserDeployDate, openTasksWeekOf) => {
    return getLongDateFormat(openTasksWeekOf || lastUserDeployDate);
  },
);

const getReasonsFilterGroups = createSelector(
  [getReasonsGroups, getReasonGroupsMap, getSettingsSelector, getReasonFilterToColorMap],
  (
    getReasonsGroups,
    getReasonGroupsMap,
    getSettingsSelector,
    reasonFilterToColorMap,
  ): IReasonFilterGroups | null => {
    return getReasonsFilterGroupsHelper(
      getReasonsGroups,
      getReasonGroupsMap,
      getSettingsSelector,
      reasonFilterToColorMap,
    );
  },
);

const getOtherSelectedCategory = (settings: ISettingsModel) => {
  const categories = getOtherDrillDownCategories(settings);

  const { productCategories, storeCategories } = categories;

  return getOtherSelectedCategoryHelper(productCategories, storeCategories);
};

const getOtherDrillDown = createSelector(
  [getSettingsSelector, getSummarySelector, isSettingsLoadedSuccessfully],
  (settings, summary, isSettingsLoaded): ITopFindingsDrillDown | undefined => {
    const isHidden = getOtherDrillDownIsHidden(settings);

    if (!isSettingsLoaded || isHidden) {
      return undefined;
    }

    const selectedCategory = getOtherSelectedCategory(settings);

    const { list, totalCount, isLoading } = summary[DrillDownTypes.Other];

    const listWithCalculatedRate = list && calculateOthersRateAsPercentageOfFirst(list);

    return {
      list: listWithCalculatedRate,
      isLoading,
      totalCount,
      selectedCategory,
    };
  },
);

const getResponseRateData = createSelector(
  [getResponseRateDataSelector, getResponseRateChainWideDataSelector],
  (responseRateData, responseRateChainWideData): Array<ITrendsLineChartRow> | null => {
    return buildResponseRateData(responseRateData, responseRateChainWideData);
  },
);

const getHitRateData = createSelector(
  [getHitRateDataSelector, getHitRateChainWideDataSelector],
  (hitRateData, hitRateChainWideData): Array<ITrendsLineChartRow> | null => {
    return buildHitRateData(hitRateData, hitRateChainWideData);
  },
);

const calculateHitRateComparisonTimeSpan = createSelector(
  [getHitRateDataSelector],
  (hitRateData): number => {
    const earliestHitRateDate = _(hitRateData).minBy('date')?.date;

    if (isEmptyArray(hitRateData) || !earliestHitRateDate) {
      return 0;
    }

    const now = moment().utc(true).valueOf();

    const monthsDiff = getDatesMonthsDiff(earliestHitRateDate, now);

    if (monthsDiff <= 1) {
      return 1;
    }
    if (monthsDiff <= 3) {
      return 3;
    }

    return 12;
  },
);

const SummarySelector = {
  getResponseRateArrowDirection,
  getHitRateArrowDirection,
  getOpenTasksDate,
  getReasonsFilterGroups,
  getOtherDrillDown,
  getResponseRateData,
  getHitRateData,
  calculateHitRateComparisonTimeSpan,
  getStoreDropdownOptions,
  getStoreSelectedDropdownOption,
};

export default SummarySelector;
