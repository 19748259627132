import { set, flow } from 'lodash/fp';

import { getReasons } from 'store/api/apiParser';
import { IActionType, IReasonsModel } from 'types';
import ReasonsActionTypes from './reasonsActionTypes';

export const initialState: IReasonsModel = {
  groups: {
    AV: [],
    IA: [],
  },
  mapReasonIdToText: {},
};

const reasonsReducer = (state: IReasonsModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    case ReasonsActionTypes.getReasons.SUCCESS: {
      const { flavour } = requestPayload;

      if (!payload) {
        return state;
      }

      const { groups, mapReasonIdToText } = getReasons(payload, flavour);

      return flow([set('groups', groups), set('mapReasonIdToText', mapReasonIdToText)])(state);
    }

    default:
      return state;
  }
};

export default reasonsReducer;
