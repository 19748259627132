import { flow, set, unset } from 'lodash/fp';

import LoadingActionTypes from 'store/loading/LoadingActionTypes';
import { ILoadingState } from 'store/loading/types';
import { IActionType } from 'types';

export const initialState: ILoadingState = {
  inProgress: {}, // Mapping of action type string to object
  counter: 0,
};

const loadingReducer = (state: ILoadingState = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case LoadingActionTypes.AddLoading: {
      const { inProgress, counter } = state;

      const loadingType = payload.type.replace('.', '-');

      let count = 1;

      if (inProgress[loadingType]) {
        count = inProgress[loadingType] + 1;
      }

      return flow([set(`inProgress[${loadingType}].count`, count), set('counter', counter + 1)])(
        state,
      );
    }

    case LoadingActionTypes.RemoveLoading: {
      const { counter } = state;

      const loadingType = payload.type.replace('.', '-');

      return flow([unset(`inProgress[${loadingType}]`), set('counter', counter - 1)])(state);
    }
    default:
      return state;
  }
};

export default loadingReducer;
