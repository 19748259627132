import { memo, useCallback } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Keycloak from 'keycloak-js';
import { I18nextProvider } from 'react-i18next';

import ContentReady from 'components/contentReady/ContentReady';
import Alert from 'components/common/alert';
import i18n from 'i18n/i18n';
import Routes from 'routes';
import analyticsService from 'services/analytics/AnalyticSrv';
import { initOptions } from 'services/auth/keycloak';
import { AuthClientTokens, IAuthUserInfo, IUserTenantRelations } from 'services/auth/keycloak/core';
import { ReactKeycloakProvider } from 'services/auth/keycloak/web';
import { getBaseUrl } from 'store/api/APIConstants';
import configureStore, { history } from 'store/configureStore';
import { getUserScope, setUserInfo } from 'store/userProfile/userProfileActions';
import GuidUtils from 'utils/GuidUtils';
import { getTokens, getEnv, setTokens } from 'utils/LocalStorage';
import Print from 'components/common/print/Print';
import Popups from 'components/common/popup/Popups';
import FetchService from 'services/fetchService/FetchService';

const { store, persistor } = configureStore();

// Used to load different env - the default is managed inside getBaseUrl below
// we override when running with localhost, then we don't need to load keycloak
const path = window.location.pathname;

const isEnvPath = path === '/env';

let keycloak: Keycloak.KeycloakInstance | null = null;

if (!isEnvPath) {
  // eslint-disable-next-line no-console
  console.log('Loading developer env settings');

  const env = getEnv() ? getEnv() : store.getState().environment.currentEnvironment;

  const keycloakUrl = `${getBaseUrl(env)}/keycloak.json?ver=${Date.now()}`;
  keycloak = Keycloak(keycloakUrl);
}

const App = () => {
  const onTokenChanged = useCallback((tokens: AuthClientTokens) => {
    setTokens(tokens);
    analyticsService.setClientId(keycloak?.clientId);
    analyticsService.setEnvironment();
    analyticsService.setDeviceInfo();
  }, []);

  const onUserInfoLoaded = useCallback(
    (userInfo: IAuthUserInfo, userTenant: IUserTenantRelations) => {
      store.dispatch(setUserInfo(userInfo, userTenant));

      const userId =
        userInfo && userInfo.sub
          ? GuidUtils.generateFromUserId(userInfo.sub)
          : GuidUtils.generateFake();

      // Setting userId to be able to count unique sessions
      // if we have user id we will transform it to Intractable unique id,
      // if the userID hasn't returned we will use fake id as fallback and still log to Google Analytics
      analyticsService.setUserId(userId);

      store.dispatch(getUserScope());
    },
    [],
  );

  // This is called whenever new locations come in
  // the action is POP, PUSH, or REPLACE
  history.listen((location, action) => {
    if (action === 'POP') {
      // https://domainame/page
      // when click 'goback' (browser) we will go to the last 'page' excluding the filter
      history.replace({
        pathname: location.pathname.split('/')[1],
        search: '',
      });
    }
  });

  const tokens = getTokens();

  FetchService.Init();

  return (
    <Provider store={store} context={ReactReduxContext}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={{ ...initOptions, ...tokens }}
          onTokens={onTokenChanged}
          onUserInfoLoaded={onUserInfoLoaded}
        >
          <I18nextProvider i18n={i18n}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
            <Alert />
            <Print />
            <Popups />
            <ContentReady />
          </I18nextProvider>
        </ReactKeycloakProvider>
      </PersistGate>
    </Provider>
  );
};

export default memo(App);
