import moment from 'moment';

import 'moment/locale/he';
import 'moment/locale/es';
import 'moment/locale/sk';
import 'moment/locale/cs';
import 'moment/locale/zh-hk';
import 'moment/locale/zh-cn';
import 'moment/locale/pl';
import 'moment/locale/nl';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/hu';
import 'moment/locale/pt';
import 'moment/locale/sv';
import 'moment/locale/fr-ca';

export const getShortDateFormat = (date: number) => moment(date).format('DD MMM');

export const getMonthDayFormat = (date: number) => moment(date).format('MMM DD');

export const getShortDayFormat = (date: number) => moment(date).format('DD');

export const getShortMonthFormat = (date: number) => moment(date).format('MMM');

export const getLongDateFormat = (date: number) => moment(date).format('MMM DD, YYYY ');

export const getShortYearFormat = (date: number) => moment(date).format('YY');

export const getMonth = (date: number) => moment(date).month() + 1;

export const getDateAndTimeFormat = (date: number) => moment(date).format('LLL');

export const getFullDate = (date: number) => moment(date).format('ll');

export const setDateLocale = (language: string) => moment.locale(language);

export const getSubtractDateByMonths = (months: number) =>
  moment().subtract(months, 'M').startOf('M').valueOf();

export const formatDurationToMinuteSeconds = (duration: number) =>
  moment.utc(duration).format('mm:ss');

export const toMoment = (time?: number | string) => moment(time);

export const getYearToDate = () => {
  const now = moment().utc(true);

  const beginningOfYear = moment().utc(true).startOf('year');

  return now.valueOf() - beginningOfYear.valueOf();
};

export const getEndOfMonth = (time: number) =>
  moment(time).utc(false).add(1, 'M').startOf('D').valueOf();

export const getCurrentDate = () => moment().valueOf();

export const getDiffInDaysFromToday = (fromDay: number) => moment().diff(moment(fromDay), 'days');

export const getMilliseconds = (date: moment.Moment) => moment(date).valueOf();

export const getDatesMonthsDiff = (from: number, to: number) =>
  moment(to).diff(moment(from), 'month');

export const getDatesWeeksDiff = (from: number, to: number) =>
  moment(to).diff(moment(from), 'week');

export const getYTDMonths = (): number => {
  const yearToDate = getYearToDate();

  return moment(yearToDate).get('months');
};

const DateUtils = {
  getYearToDate,
  getYTDMonths,
};

export default DateUtils;
