import { applyMiddleware, createStore } from 'redux';
import { createTransform, persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

// Reducers
import myOpportunities from 'store/myOpportunities/myOpportunitiesReducer';
import openOpportunities from 'store/openOpportunities/openOpportunitiesReducer';
import knowledgeCenter from 'store/knowledgecenter/knowledgeCenterReducer';
import environment from 'store/environment/environmentReducer';
import sideMenu from 'store/sideMenu/sideMenuReducer';
import { IRootState } from 'store/types';
import userProfile from 'store/userProfile/userProfileReducer';
import loading from 'store/loading/loadingReducer';
import settings from 'store/settings/settingsReducer';
import filters from 'store/filters/filtersReducer';
import alert from 'store/alert/alertReducer';
import print from 'store/print/printReducer';
import reasons from 'store/reasons/reasonsReducer';
import findings from 'store/findings/findingsReducer';
import popup from 'store/popup/popupReducer';
import trends from 'store/trends/trendsReducer';
import summary from 'store/summary/summaryReducer';
// Middlewares
import logMiddleware from 'store/log/logMiddleware';
import toastMiddleware from 'store/toast/toastMiddleware';
import apiMiddlewares from 'store/api/apiMiddlewares';
import settingsMiddleware from 'store/settings/settingsMiddleware';
import myOpportunitiesMiddleware from 'store/myOpportunities/myOpportunitiesMiddleware';
import filterMiddleware from 'store/filters/filterMiddleware';
import alertMiddleware from 'store/alert/alertMiddleware';
import userProfileMiddleware from 'store/userProfile/userProfileMiddleware';
import findingsMiddleware from 'store/findings/findingsMiddleware';
import trendsMiddleware from 'store/trends/trendsMiddleware';
import summaryMiddleware from 'store/summary/summaryMiddleware';
import openOpportunitiesMiddleware from 'store/openOpportunities/openOpportunitiesMiddleware';

export const history = createBrowserHistory();

const settingsTransform = createTransform(
  (inbound: any) => ({ language: inbound.language, isRtl: inbound.isRtl }),
  (outbound) => outbound,
  { whitelist: ['settings'] },
);

const configureStore = () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['environment', 'settings'],
    transforms: [settingsTransform],
  };

  const rootReducer: any = {
    filters,
    sideMenu,
    environment,
    loading,
    userProfile,
    settings,
    myOpportunities,
    knowledgeCenter,
    alert,
    print,
    reasons,
    findings,
    popup,
    trends,
    summary,
    openOpportunities,
    router: connectRouter(history),
  };

  const persistedReducer = persistCombineReducers<IRootState>(persistConfig, rootReducer);

  // Same as middleware, the name controller is to hint the the middleware actually assist of orchestrating some logical flow
  const controllers: any[] = [settingsMiddleware, filterMiddleware, myOpportunitiesMiddleware];

  const middlewares = [
    logMiddleware,
    ...apiMiddlewares,
    alertMiddleware,
    toastMiddleware,
    userProfileMiddleware,
    findingsMiddleware,
    trendsMiddleware,
    summaryMiddleware,
    openOpportunitiesMiddleware,
    routerMiddleware(history),
    ...controllers,
  ];

  const storeEnhancers = composeWithDevTools(applyMiddleware(...middlewares));

  const store = createStore(persistedReducer, storeEnhancers);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
