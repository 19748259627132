import {
  DrillDownTypes,
  IDrillDownHierarchy,
  IDrillDownLegacyHierarchy,
  ILegacyOtherDrilDownFilter,
  IOtherSearchDrilldown,
  ISearchDrilldown,
  ISelectedCategory,
  LegacyDrillDownCategoriesTypes,
} from 'components/drilldown/types';
import { HierarchyLevel } from 'store/userProfile/types';

export type IFilterModel = {
  isControlMenuExpanded: boolean;
  myOpportunities: {
    activeFilter: IMyOpportunityFilters;
    filters: IFilters;
  };
};

export type IFilterCategory = {
  title: string;
  level: number;
};

// interface that represents the UI
export interface IFilters {
  hasFilterModified: boolean;
  matchFilterOpportunities: number | null;
  activeTab: filterValue;
  totalOpen: number;
  totalClose: number;
  totalResponseRate: number;
  activeConcurrentFilter: number;
  productCategories: Array<IFilterCategory>;
  storeCategories: Array<IFilterCategory>;
  storeNamesCategories: Array<IFilterCategory>;
  planogramAttributes: Array<IFilterCategory>;
  productCategoriesLabels: { [key: number]: ICategoriesLabels };
  storeCategoriesLabels: { [key: number]: ICategoriesLabels };
  storeNamesLabels: { [key: number]: ICategoriesLabels };
  planogramAttributesLabels: { [key: number]: ICategoriesLabels };
  orderBy: orderBy;
  groupBy: IGroupByOption;
  direction: { [key: string]: direction };
  search: string;
}

// Interface which represents the in action filter
// Change to the store will trigger a data fetch
export interface IMyOpportunityFilters {
  nextPageOffset: number;
  activeTab: filterValue;
  productCategory: IFilterCategoryLabels;
  storeCategory: IFilterCategoryLabels;
  storeName: IFilterCategoryLabels;
  planogram: IFilterCategoryLabels;
  orderBy: orderBy;
  search: string;
  groupBy: IGroupByOption;
  direction: direction;
}

export type IGroupByOption = { value: string; key: string; source: IGroupBySource };

export type IFilterCategoryLabels = Array<{ selectedLabels: Array<string> }>;

export interface IDate {
  type: SelectedDateIntervals;
  from: number;
  to: number;
}

export interface IMyOpportunityFilter {
  type: filterOperator;
  filters: Array<IFilter>;
}

export type IOrder = {
  direction: direction;
  fieldName: fieldName;
};

export enum IGroupBySource {
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT_HIERARCHY = 'PRODUCT_HIERARCHY',
  DEFAULT = 'DEFAULT',
}

export const mapGroupSourceToFilterKey = {
  [IGroupBySource.PRODUCT_HIERARCHY]: 'productHierarchy',
  [IGroupBySource.PRODUCT_CATEGORY]: 'productCategories',
};

export enum fieldName {
  id = 'id',
  targetSales = 'targetSales',
  hitRate = 'hitRate',
  responseRate = 'responseRate',
  revenueIncrease = 'revenueIncrease',
  productName = 'productName',
  action = 'action',
  storeName = 'storeName',
  deployDate = 'deployDate',
  count = 'count',
  issueFoundCount = 'issueFoundCount',
  name = 'name',
}

export enum direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ISort {
  direction: direction;
  fieldName: string;
}

export type IFilterType = 'term';

export enum filterKey {
  LIFECYCLE = 'lifecycle',
  STATE = 'state',
  STORE_ID = 'storeId',
  REASON_ID = 'response.reasonId',
  DEPLOY_DATE = 'deployDate',
  EARNING_DATE = 'earningDate',
  RESPONSE_TYPE = 'response.responseType',
  RESPONSE_TEXT = 'response.freeText',
  ID = 'id',
}

export interface ICategoriesLabels {
  categoryFamily: categoryName;
  selectedLabels: Array<string>;
  labels: Array<string>;
  labelsMap: {
    [key: string]: IMapLabel | string;
  };
}

export interface IMapLabel {
  label: string;
  count: number;
  isSelected: boolean;
}

export interface IMyOpportunityFiltersRequest {
  filter: ICommonFilter;
  orders: Array<IOrder>;
  page: IMyOpportunityPage;
}

export interface IMyOpportunityPage {
  from: number;
  size: number;
}

export enum filterValue {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

export enum TaskLifeCycleValue {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
}

export enum categoryName {
  PRODUCT_CATEGORY = 'productCategory',
  STORE_CATEGORY = 'storeCategory',
  STORE_NAME = 'storeName',
  PLANOGRAM_ATTRIBUTES = 'planogram',
}

export enum categoryLabelsMapper {
  PRODUCT_CATEGORY = 'productCategoriesLabels',
  STORE_CATEGORY = 'storeCategoriesLabels',
  STORE_NAME = 'storeNamesLabels',
  PLANOGRAM_ATTRIBUTES = 'planogramAttributesLabels',
}

export enum categoryMapper {
  PRODUCTS = 'productCategories',
  STORES = 'storeCategories',
  STORE_NAME = 'storeNamesCategories',
  PLANOGRAM = 'planogramAttributes',
}

export enum majorCategory {
  PRODUCTS = 'PRODUCTS',
  STORES = 'STORES',
  PLANOGRAM = 'PLANOGRAM',
}

export type ICategoryMappers = {
  mapper: string;
  labelsMapper: categoryLabelsMapper;
};

export const CategoriesMapper: { [key: string]: ICategoryMappers[] } = {
  PRODUCTS: [
    {
      mapper: categoryMapper.PRODUCTS,
      labelsMapper: categoryLabelsMapper.PRODUCT_CATEGORY,
    },
  ],
  STORES: [
    {
      mapper: categoryMapper.STORES,
      labelsMapper: categoryLabelsMapper.STORE_CATEGORY,
    },
    {
      mapper: categoryMapper.STORE_NAME,
      labelsMapper: categoryLabelsMapper.STORE_NAME,
    },
  ],
  PLANOGRAM: [
    {
      mapper: categoryMapper.PLANOGRAM,
      labelsMapper: categoryLabelsMapper.PLANOGRAM_ATTRIBUTES,
    },
  ],
};

interface IFilter {
  type: IFilterType;
  key: filterKey;
  value: string;
}

export type termValue = string | number;

export enum FilterType {
  OR = 'or',
  AND = 'and',
  TIME = 'time',
  TERM = 'term',
  DATE = 'date',
  RELATIVE = 'relativeTime',
  FREE_TEXT = 'freeText',
  NON_EMPTY = 'nonempty',
}

export interface ICommonFilter {
  type?: FilterType;
  key?: string;
  value?: termValue;
  searchValue?: termValue;
  fields?: string[];
  timeAgo?: number;
  to?: number;
  from?: number;
  filters?: ICommonFilter[];
}

export interface ISearchParamsFilters {
  tab?: string;
  cardId?: string;
  date?: IDate;
  [DrillDownTypes.Product]?: ISearchDrilldown;
  [DrillDownTypes.Store]?: ISearchDrilldown;
  [DrillDownTypes.Other]?: IOtherSearchDrilldown;
}

export interface IResolvedSearchParams {
  tab?: string;
  cardId?: string;
  dateType?: SelectedDateIntervals;
  dateTo?: number;
  dateFrom?: number;
  storeDrillDownHierarchy?: IDrillDownHierarchy[];
  storeDrillDownOrder?: direction;
  productDrillDownHierarchy?: IDrillDownHierarchy[];
  productDrillDownOrder?: direction;
  otherDrillDownHierarchy?: IDrillDownHierarchy;
  otherDrillDownOrder?: direction;
  category?: ISelectedCategory;
}

// legacy (mobile) representation of multi drill down filter
export interface IResolvedLegacySearchParams {
  // date
  date?: string;
  // from
  from?: number;
  // to
  to?: number;
  // tab
  tab?: string;
  // store level
  sl?: HierarchyLevel;
  // store path
  sp?: IDrillDownLegacyHierarchy[];
  // store order
  so?: direction;
  // product level
  pl?: HierarchyLevel;
  // product path
  pp?: IDrillDownLegacyHierarchy[];
  // product order
  po?: direction;
  // other level
  ol?: LegacyDrillDownCategoriesTypes;
  // other aggregate by id
  oa?: string;
  // other aggregate by label
  on?: string;
  // other filters
  of?: ILegacyOtherDrilDownFilter;
  // other order
  oo?: direction;
}

export type filterOperator = 'and' | 'or';

export enum orderBy {
  salesTarget = 'salesTarget',
  receivedDate = 'receivedDate',
  productName = 'productName',
}

export enum groupBy {
  receivedWeek = 'receivedWeek',
  opportunityType = 'opportunityType',
  storeName = 'storeName',
}

export const mapGroupByToTranslationKey: { [key: string]: string } = {
  [groupBy.receivedWeek]: 'RECEIVED_WEEK',
  [groupBy.opportunityType]: 'OPPORTUNITY_TYPE',
  [groupBy.storeName]: 'STORE_NAME',
};

export const mapOrderByToFieldName: { [key: string]: string } = {
  [orderBy.salesTarget]: fieldName.targetSales,
  [orderBy.receivedDate]: fieldName.deployDate,
  [orderBy.productName]: fieldName.productName,
};

export const DefaultGroupByOption: IGroupByOption = {
  value: `MY_OPPORTUNITIES.GROUP.${mapOrderByToFieldName[groupBy.receivedWeek]}`,
  key: groupBy.receivedWeek,
  source: IGroupBySource.DEFAULT,
};

export const sortAndGroupDefaultInitState = {
  direction: {
    [orderBy.salesTarget]: direction.DESC,
    [orderBy.receivedDate]: direction.DESC,
    [orderBy.productName]: direction.DESC,
  },
  orderBy: orderBy.salesTarget,
  groupBy: DefaultGroupByOption,
};

export enum SelectedDateIntervals {
  all = 'DATES_FILTER.ALL',
  twelveMonthsId = 'DATES_FILTER.TWELVE_MONTHS',
  sixMonthsId = 'DATES_FILTER.SIX_MONTHS',
  yearId = 'DATES_FILTER.YEAR',
  threeMonthsId = 'DATES_FILTER.THREE_MONTHS',
  monthId = 'DATES_FILTER.MONTH',
  weekId = 'DATES_FILTER.WEEK',
  customId = 'DATES_FILTER.CUSTOM',
  lastDeployment = 'DATES_FILTER.LAST_DEPLOY',
}

export const mapDateIntervalsToQueryParams = {
  [SelectedDateIntervals.all]: 'ALL',
  [SelectedDateIntervals.twelveMonthsId]: '12M',
  [SelectedDateIntervals.sixMonthsId]: '6M',
  [SelectedDateIntervals.yearId]: 'YTD',
  [SelectedDateIntervals.threeMonthsId]: '3M',
  [SelectedDateIntervals.monthId]: '1M',
  [SelectedDateIntervals.weekId]: '1W',
  [SelectedDateIntervals.customId]: 'CUSTOM',
  [SelectedDateIntervals.lastDeployment]: 'LAST_DEPLOYMENT',
};

export const mapQueryParamsToDateIntervals = {
  ALL: SelectedDateIntervals.all,
  '12M': SelectedDateIntervals.twelveMonthsId,
  '6M': SelectedDateIntervals.sixMonthsId,
  YTD: SelectedDateIntervals.yearId,
  '3M': SelectedDateIntervals.threeMonthsId,
  '1M': SelectedDateIntervals.monthId,
  '1W': SelectedDateIntervals.weekId,
  CUSTOM: SelectedDateIntervals.customId,
  LAST_DEPLOYMENT: SelectedDateIntervals.lastDeployment,
};
