import { ReactNode } from 'react';

export interface IDropdownOption {
  key: string;
  label: string;
}

export interface IDropdown {
  options: Array<IDropdownOption>;
  selectedOption?: IDropdownOption;
  disabled?: boolean;
  onDropDownOpen?: () => void;
  onChange(option: IDropdownOption): void;
  onClear?(): void;
  isSearchableDropdown?: boolean;
  placeHolder?: string;
  className?: string;
  icon?: ReactNode;
  isRtl?: boolean;
}

export const NO_RESULTS = 'No Results';
