import cn from 'clsx';
import { FC } from 'react';

import './reasonFilter.scss';
import { IReasonFilterProps, ValueType } from 'types';
import { toLanguageNumberFormat } from 'utils';

const ReasonFilter: FC<IReasonFilterProps> = ({
  isActive,
  type,
  label,
  value,
  id,
  onReasonClick,
}) => {
  const onClick = () => onReasonClick(id);

  const renderValue = () => {
    let displayedValue = `${value}%`;
    if (type === ValueType.Number) {
      displayedValue = toLanguageNumberFormat(value);
    }

    return displayedValue;
  };

  return (
    <div
      onClick={onClick}
      data-testid="reason-filter"
      className={cn('reason-filter', { active: isActive })}
    >
      <span className="label">{label}</span>
      <span className="value">{renderValue()}</span>
    </div>
  );
};

export default ReasonFilter;
