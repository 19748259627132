import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton, ActionButtonClasses, IRateComparisonCard } from 'types';
import './rateComparisonCard.scss';
import { UpArrow } from 'components/Icons';

const RateComparisonCard: FC<IRateComparisonCard> = ({
  tabId,
  rate,
  peersLevel,
  onSeeDetailsClick,
}) => {
  const [t] = useTranslation();

  const subText = tabId === 1 ? t('COMMON.RESPONSE_RATE') : t('COMMON.HIT_RATE');

  return (
    <div className="rate-comparison-card-container">
      <article className="rate-comparison-card">
        <div className="top-container">
          <div className="header">{t('COMMON.AVG')}</div>
          <h2 className="rate">{`${Math.round(rate)}%`}</h2>
          <h4 className="sub-text">{subText}</h4>
        </div>

        <div className="bottom-container">
          <div className="chart-legends">
            <div className="my-stores legend">
              <div className="chart-line-reference" />
              <span className="title">{t('COMMON.MY_STORES')}</span>
            </div>
            <div className="chain-wide legend">
              <div className="chart-line-reference" />
              <span className="title">{peersLevel || t('COMMON.CHAIN_WIDE')}</span>
            </div>
          </div>

          <div className="separator" />

          <div className="footer">
            <ActionButton
              text={t('SUMMARY.SEE_DETAILS')}
              Icon={UpArrow}
              className={ActionButtonClasses.SEE_DETAILS}
              onClick={onSeeDetailsClick}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default RateComparisonCard;
