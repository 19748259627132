import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cn from 'clsx';

import {
  AlertIds,
  DrillDownTypes,
  IAlert,
  IRootState,
  ISearchParamsFilters,
  ITrends,
  SelectedDateIntervals,
  TrendsTabOptions,
} from 'types';
import './trends.scss';
import TrendsSelector from 'store/trends/trendsSelector';
import { getDrillDown, getHistogram } from 'store/trends/trendsActions';
import DrillDowns from 'components/trends/drillDowns/DrillDowns';
import { useSkipFirstEffect } from 'components/hooks';
import { DateButton } from 'components/buttons';
import { show } from 'store/alert/alertActions';
import TrendsPreview from './TrendsPreview';
import routerService from 'services/router/routerService';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';

const Trends: FC<ITrends> = ({
  otherDrillDown,
  filters,
  storeDrillDown,
  productDrillDown,
  getDrillDown,
  getHistogram,
  showPopup,
  otherDrillDownCategories,
}) => {
  const [t] = useTranslation();

  const { expendedDrillDown, selectedTab, selectedMonth } = filters;

  // update Query params when filters change
  useEffect(() => {
    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date: filters.date,
      tab: filters.selectedTab,
      [DrillDownTypes.Other]: {
        category: filters[DrillDownTypes.Other]?.activeFilters.selectedCategory,
        filter: filters[DrillDownTypes.Other]?.filters[0],
        order: filters[DrillDownTypes.Other]?.activeFilters.orderDirection,
      },
      [DrillDownTypes.Store]: {
        hierarchyPath: filters[DrillDownTypes.Store]?.hierarchyPath,
        order: filters[DrillDownTypes.Store]?.activeFilters.orderDirection,
      },
      [DrillDownTypes.Product]: {
        hierarchyPath: filters[DrillDownTypes.Product]?.hierarchyPath,
        order: filters[DrillDownTypes.Product]?.activeFilters.orderDirection,
      },
    };

    const params = buildQueryParamsFromSearchFilters(searchFilters);

    routerService.updateSearchParams(params);
  }, [filters]);

  const getDrillDownByType = useCallback(
    (drillDownType: DrillDownTypes, pageOffset?: number) => {
      const isVisible = !expendedDrillDown || expendedDrillDown === drillDownType;

      const isDrillDownInitialized = otherDrillDown || storeDrillDown || productDrillDown;

      if (isVisible && isDrillDownInitialized) {
        getDrillDown(
          drillDownType,
          storeDrillDown?.hierarchyDepth || 0,
          productDrillDown?.hierarchyDepth || 0,
          otherDrillDown?.activeFilters.selectedCategory,
          pageOffset,
        );
      }
    },
    [filters, storeDrillDown, otherDrillDown, productDrillDown],
  );

  const getHistogramDataByTab = useCallback(() => {
    const { selectedTab } = filters;

    getHistogram(
      selectedTab,
      storeDrillDown?.hierarchyDepth || 0,
      productDrillDown?.hierarchyDepth || 0,
      otherDrillDown?.activeFilters.selectedCategory,
    );
  }, [filters, storeDrillDown, otherDrillDown, productDrillDown]);

  useEffect(() => {
    getHistogramDataByTab();
    getDrillDownByType(DrillDownTypes.Store);
    getDrillDownByType(DrillDownTypes.Product);
    getDrillDownByType(DrillDownTypes.Other);
  }, [
    storeDrillDown?.mutualFilters,
    productDrillDown?.mutualFilters,
    otherDrillDown?.mutualFilters,
    otherDrillDown?.filters.length,
    filters.date,
  ]);

  // Any change in the dependencies below effect store drill down (first one)
  // For each change we update only store drill down
  useSkipFirstEffect(() => {
    getDrillDownByType(DrillDownTypes.Store);
  }, [storeDrillDown?.activeFilters, expendedDrillDown, selectedTab, selectedMonth]);

  // Any change in the dependencies below effect product drill down (second one)
  // For each change we update only product drill down
  useSkipFirstEffect(() => {
    getDrillDownByType(DrillDownTypes.Product);
  }, [productDrillDown?.activeFilters, expendedDrillDown, selectedTab, filters.selectedMonth]);

  // Any change in the dependencies below effect other drill down (Third one)
  // For each change we update only other drill down
  useSkipFirstEffect(() => {
    if (otherDrillDown?.activeFilters.selectedCategory) {
      getDrillDownByType(DrillDownTypes.Other);
    }
  }, [otherDrillDown?.activeFilters, expendedDrillDown, selectedTab, selectedMonth]);

  const onShowOtherDrillDownOptions = useCallback(() => {
    if (!otherDrillDown) {
      return;
    }

    const treeSelection = otherDrillDownCategories.map((category) => {
      const { list, id, title } = category;

      const options = list.map(({ label, type, isChecked, value }) => ({
        label,
        value,
        isChecked,
        id: type,
        hideOnClick: true,
      }));

      return {
        options,
        id,
        title,
      };
    });

    const options: IAlert = {
      id: AlertIds.OpenTrendsOtherDrillDownOptions,
      treeSelection,
      withOverlay: true,
      alertCustomClassName: 'alert-drill-down-menu',
    };

    showPopup(options);
  }, [otherDrillDown, otherDrillDownCategories]);

  const onLoadMoreRows = useCallback(
    (drillDownType: DrillDownTypes) => {
      const { nextPageOffset, hasMore } = filters[drillDownType];

      if (drillDownType === expendedDrillDown && hasMore) {
        getDrillDownByType(drillDownType, nextPageOffset);
      }
    },
    [filters],
  );

  const onDateButtonClick = useCallback(() => {
    const {
      date: { from, to, type },
      selectedTab,
    } = filters;

    const radioIds = [
      SelectedDateIntervals.twelveMonthsId,
      SelectedDateIntervals.yearId,
      SelectedDateIntervals.sixMonthsId,
      SelectedDateIntervals.threeMonthsId,
      SelectedDateIntervals.monthId,
      SelectedDateIntervals.customId,
    ];
    if (selectedTab === TrendsTabOptions.Earning) {
      radioIds.unshift(SelectedDateIntervals.all);
    }

    const radioList = radioIds.map((id) => ({
      id,
      hideOnClick: id !== SelectedDateIntervals.customId,
      isChecked: id === type,
      label: t(id),
    }));

    const options: IAlert = {
      id: AlertIds.OpenTrendsDateMenu,
      radioList,
      withOverlay: true,
      withDatePicker: true,
      selectedDates: {
        from,
        to,
      },
      alertCustomClassName: 'trends-date-menu',
    };

    showPopup(options);
  }, [filters, showPopup]);

  return (
    <div className={cn('page', 'trends')}>
      <div className="page-header">
        <div className="date-title-container">
          <span className="title"> {t('PAGES.TRENDS')}</span>
          <DateButton
            dates={
              filters.date.type === SelectedDateIntervals.customId
                ? [filters.date.from, filters.date.to]
                : undefined
            }
            label={
              filters.date.type !== SelectedDateIntervals.customId
                ? t(filters.date.type)
                : undefined
            }
            onClick={onDateButtonClick}
          />
        </div>
      </div>
      <div className="page-container opposite-page">
        <div className="page-preview">
          <TrendsPreview />
        </div>
        <div className="page-list trends-list-content">
          <div className="share-button" />
          <div className={cn('drill-downs-list')}>
            <DrillDowns
              storeDrillDown={storeDrillDown}
              productDrillDown={productDrillDown}
              otherDrillDown={otherDrillDown}
              onLoadMore={onLoadMoreRows}
              onTitleClick={onShowOtherDrillDownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    trends: { filters },
  } = state;

  const storeDrillDown = TrendsSelector.getStoreDrillDown(state);

  const productDrillDown = TrendsSelector.getProductDrillDown(state);

  const otherDrillDown = TrendsSelector.getOtherDrillDown(state);

  const otherDrillDownCategories = TrendsSelector.getOtherCategories(state);

  return { storeDrillDown, filters, productDrillDown, otherDrillDown, otherDrillDownCategories };
};

const mapDispatchToProps = {
  getDrillDown,
  getHistogram,
  showPopup: show,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trends);
