import { FC } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { EMPTY_STATE_THEME } from 'types';

interface IEmptyPieChart {
  height: number;
  emptyStateLabel: string;
}

const EmptyPieChart: FC<IEmptyPieChart> = ({ height, emptyStateLabel }) => {
  const data = EMPTY_STATE_THEME;

  const renderChartCells = () =>
    data.map((element, index) => (
      <Cell className="findings-pie-cell" key={`${String(index)}`} fill={element.color} />
    ));

  const outerRadius = height / 2;

  const innerRadius = 0.7 * outerRadius;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <Pie
          labelLine={false}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          isAnimationActive={false}
          data={data}
          dataKey="value"
        >
          <Label
            width={innerRadius}
            value={emptyStateLabel}
            position="center"
            className="findings-empty-state-text"
          />
          {renderChartCells()}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default EmptyPieChart;
