import {
  direction,
  fieldName,
  HierarchyLevel,
  IActionType,
  LeaderBoardCompareFactor,
  RateRequestScope,
} from 'types';
import {
  getAllIssueFoundByReasons,
  getAllSalesIncrease,
  getHierarchyDrillDownData,
  getHitRateComparison,
  getLeaderBoardData,
  getMyIssueFoundByReasons,
  getMySalesIncrease,
  getResponseRateComparison,
  getHitRateHistogram,
  getHitRateChainWideHistogram,
  getResponseRateHistogram,
  getResponseRateChainWideHistogram,
} from './summaryActions';
import SummaryActionTypes from './summaryActionTypes';
import {
  buildLeaderBoardFilterBody,
  buildDrillDownFilter,
  buildRateHistogramFilterBody,
  buildSalesIncreaseFilterBody,
  buildTotalOpportunitiesCapturedFilterBody,
  buildComparisonFilterBody,
} from './summaryHelper';
import { getDiffInDaysFromToday } from 'utils';
import { getHierarchyEndpoint, shouldGetProducts, shouldGetStores } from 'store/helpers/drillDown';
import { getProducts, getStores } from 'store/summary/summaryActions';

const summaryMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: IActionType) => {
  const { type, payload } = action;

  switch (type) {
    case SummaryActionTypes.getIssueFoundByReasons: {
      const { activeFilters, selectedStoreFilter } = getState().summary;

      const myFilterBody = buildTotalOpportunitiesCapturedFilterBody(
        activeFilters,
        selectedStoreFilter?.storeName,
      );

      const allFilterBody = buildTotalOpportunitiesCapturedFilterBody(activeFilters);

      dispatch(getMyIssueFoundByReasons(myFilterBody));
      dispatch(getAllIssueFoundByReasons(allFilterBody));

      break;
    }

    case SummaryActionTypes.getSalesIncrease: {
      const { activeFilters, selectedStoreFilter } = getState().summary;

      const myFilterBody = buildSalesIncreaseFilterBody(
        activeFilters,
        selectedStoreFilter?.storeName,
      );

      const allFilterBody = buildSalesIncreaseFilterBody(activeFilters);

      dispatch(getMySalesIncrease(myFilterBody));
      dispatch(getAllSalesIncrease(allFilterBody));

      break;
    }

    case SummaryActionTypes.getRateComparison: {
      const { lastUserDeployDate } = getState().userProfile;

      const { selectedStoreFilter } = getState().summary;

      const filterBody = buildComparisonFilterBody(selectedStoreFilter?.storeName);

      const diffInDays = getDiffInDaysFromToday(lastUserDeployDate);
      dispatch(getResponseRateComparison(diffInDays, filterBody));
      dispatch(getHitRateComparison(diffInDays, filterBody));
      break;
    }

    case SummaryActionTypes.getDrillDown: {
      const { drillDownType, selectedCategory } = payload;

      const { activeFilters } = getState().summary;

      const filter = buildDrillDownFilter(activeFilters);

      const hierarchyIndex = 1;

      const shouldGetStore = shouldGetStores(drillDownType, selectedCategory, false);

      const shouldGetProduct = shouldGetProducts(drillDownType, selectedCategory, false);

      if (shouldGetProduct) {
        dispatch(getProducts(filter, drillDownType));
      } else if (shouldGetStore) {
        dispatch(getStores(filter, drillDownType));
      } else {
        const endpoint = getHierarchyEndpoint(drillDownType, hierarchyIndex, selectedCategory);
        dispatch(getHierarchyDrillDownData(filter, drillDownType, endpoint));
      }

      break;
    }

    case SummaryActionTypes.getLeaderBoardPrepare: {
      const { userHighestHierarchyLowestLevel } = getState().userProfile;

      const { selectedStoreFilter } = getState().summary;

      const {
        leaderBoardDateFilter,
        isLeaderBoardPeersSelected,
      } = getState().summary.activeFilters;

      let { level } = userHighestHierarchyLowestLevel;

      level = level === HierarchyLevel.ALL ? HierarchyLevel.LEVEL1 : level;

      const storeHierarchy = HierarchyLevel.STORES.toLowerCase();

      const hierarchyLevel =
        level === HierarchyLevel.LEAF || selectedStoreFilter ? storeHierarchy : level;

      const compareFactor = isLeaderBoardPeersSelected
        ? LeaderBoardCompareFactor.PEERS
        : LeaderBoardCompareFactor.ALL;

      const filter = buildLeaderBoardFilterBody(leaderBoardDateFilter);

      const nameFieldName =
        hierarchyLevel === storeHierarchy ? fieldName.storeName : fieldName.name;

      const orders = [
        { direction: direction.DESC, fieldName: fieldName.hitRate },
        { direction: direction.DESC, fieldName: fieldName.issueFoundCount },
        { direction: direction.ASC, fieldName: nameFieldName },
      ];

      dispatch(
        getLeaderBoardData(
          filter,
          orders,
          compareFactor,
          userHighestHierarchyLowestLevel,
          hierarchyLevel,
        ),
      );

      break;
    }

    case SummaryActionTypes.getRateHistograms: {
      const { selectedStoreFilter } = getState().summary;

      const myFilterBody = buildRateHistogramFilterBody(selectedStoreFilter?.storeName);

      const allFilterBody = buildRateHistogramFilterBody();

      const hasPeers = false; // TODO get from userProfile

      const compareScope = hasPeers ? RateRequestScope.PEERS : RateRequestScope.ALL;

      dispatch(getHitRateHistogram(myFilterBody));

      dispatch(getHitRateChainWideHistogram(allFilterBody, compareScope));

      dispatch(getResponseRateHistogram(myFilterBody));

      dispatch(getResponseRateChainWideHistogram(allFilterBody, compareScope));

      break;
    }

    default:
      break;
  }
  next(action);
};

export default summaryMiddleware;
