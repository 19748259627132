import { DrillDownTypes, IActionType, IRootState, TrendsTabOptions } from 'types';
import TrendsActionTypes from './trendsActionTypes';
import {
  geSalesIncreaseChainWideDetails,
  getCategoryDrillDownDataSalesIncrease,
  getHierarchyDrillDownDataHitRate,
  getHierarchyDrillDownDataResponseRate,
  getHierarchyDrillDownDataSalesIncrease,
  getHitRateChainWideHistogram,
  getHitRateHistogram,
  getProductResponseRate,
  getProductSalesIncrease,
  getProductsHitRate,
  getResponseRateChainWideHistogram,
  getResponseRateHistogram,
  getSalesIncreaseDetails,
  getStoresHitRate,
  getStoresResponseRate,
  getStoresSalesIncrease,
} from './trendsActions';
import {
  buildDrillDownFilter,
  buildGetHistogramRequestFilterBody,
  getRateHistogramEndpoint,
  getSalesIncreaseCategoryEndpoint,
  getSalesIncreaseHierarchyEndpoint,
} from './trendsHelper';
import { getHierarchyEndpoint, shouldGetProducts, shouldGetStores } from 'store/helpers/drillDown';

const trendsMiddleware = ({
  dispatch,
  getState,
}: {
  dispatch: any;
  getState: () => IRootState;
}) => (next: any) => (action: IActionType) => {
  const { type, payload } = action;

  switch (type) {
    case TrendsActionTypes.getDrillDown: {
      const {
        drillDownType,
        selectedCategory,
        pageOffset,
        productHierarchyDepth,
        storeHierarchyDepth,
      } = payload;

      const { filters } = getState().trends;

      const { selectedTab } = filters;

      const {
        mutualFilters: { hierarchyIndex },
      } = filters[drillDownType];

      const filter = buildDrillDownFilter(
        filters,
        drillDownType,
        storeHierarchyDepth,
        productHierarchyDepth,
        pageOffset,
        selectedCategory,
      );

      const isStoreLeaf = hierarchyIndex > storeHierarchyDepth;

      const isProductLeaf = hierarchyIndex > productHierarchyDepth;

      const shouldGetStore = shouldGetStores(drillDownType, selectedCategory, isStoreLeaf);

      const shouldGetProduct = shouldGetProducts(drillDownType, selectedCategory, isProductLeaf);

      // Hit rate requests
      if (selectedTab === TrendsTabOptions.HitRate) {
        if (shouldGetProduct) {
          dispatch(getProductsHitRate(filter, drillDownType, pageOffset));
        } else if (shouldGetStore) {
          dispatch(getStoresHitRate(filter, drillDownType, pageOffset));
        } else {
          // hierarchy or category need to get drill down data
          const endpoint = getHierarchyEndpoint(drillDownType, hierarchyIndex, selectedCategory);
          dispatch(getHierarchyDrillDownDataHitRate(filter, drillDownType, pageOffset, endpoint));
        }
      }
      // Response rate requests
      else if (selectedTab === TrendsTabOptions.ResponseRate) {
        if (shouldGetProduct) {
          dispatch(getProductResponseRate(filter, drillDownType, pageOffset));
        } else if (shouldGetStore) {
          dispatch(getStoresResponseRate(filter, drillDownType, pageOffset));
        } else {
          // hierarchy or category need to get drill down data
          const endpoint = getHierarchyEndpoint(drillDownType, hierarchyIndex, selectedCategory);
          dispatch(
            getHierarchyDrillDownDataResponseRate(filter, drillDownType, pageOffset, endpoint),
          );
        }
      }
      // Sales increase requests
      else if (selectedTab === TrendsTabOptions.Earning) {
        if (shouldGetProduct) {
          dispatch(getProductSalesIncrease(filter, drillDownType, pageOffset));
        } else if (shouldGetStore) {
          dispatch(getStoresSalesIncrease(filter, drillDownType, pageOffset));
        } else if (drillDownType === DrillDownTypes.Other) {
          // Its category
          const endpoint = getSalesIncreaseCategoryEndpoint(selectedCategory);
          dispatch(getCategoryDrillDownDataSalesIncrease(filter, pageOffset, endpoint));
        } else {
          const endpoint = getSalesIncreaseHierarchyEndpoint(drillDownType, hierarchyIndex);
          dispatch(
            getHierarchyDrillDownDataSalesIncrease(filter, drillDownType, pageOffset, endpoint),
          );
        }
      }
      break;
    }

    case TrendsActionTypes.getHistogram: {
      const { storeHierarchyDepth, productHierarchyDepth, selectedCategory } = payload;

      const state: IRootState = getState();

      const { isAllUser } = state.userProfile;

      const { filters } = state.trends;

      const { date } = filters;

      const {
        myStoresFilter: salesFilter,
        peersFilter: salesPeerFilter,
      } = buildGetHistogramRequestFilterBody(
        TrendsTabOptions.Earning,
        filters,
        storeHierarchyDepth,
        productHierarchyDepth,
        selectedCategory,
      );

      const {
        myStoresFilter: hitRateFilter,
        peersFilter: hitRatePeersFilter,
      } = buildGetHistogramRequestFilterBody(
        TrendsTabOptions.HitRate,
        filters,
        storeHierarchyDepth,
        productHierarchyDepth,
        selectedCategory,
      );

      const {
        myStoresFilter: responseRateFilter,
        peersFilter: responseRatePeerFilter,
      } = buildGetHistogramRequestFilterBody(
        TrendsTabOptions.ResponseRate,
        filters,
        storeHierarchyDepth,
        productHierarchyDepth,
        selectedCategory,
      );

      const shouldGetAll = !isAllUser;

      const rateEndpoint = getRateHistogramEndpoint(date);

      dispatch(getHitRateHistogram(hitRateFilter, rateEndpoint));

      dispatch(getHitRateChainWideHistogram(hitRatePeersFilter, rateEndpoint));

      dispatch(getResponseRateHistogram(responseRateFilter, rateEndpoint));

      dispatch(getResponseRateChainWideHistogram(responseRatePeerFilter, rateEndpoint));

      dispatch(getSalesIncreaseDetails(salesFilter));

      if (shouldGetAll) {
        dispatch(geSalesIncreaseChainWideDetails(salesPeerFilter));
      }

      break;
    }

    default:
      break;
  }
  next(action);
};

export default trendsMiddleware;
