import { cloneDeep, isEmpty } from 'lodash';

import permissionsService from 'services/permissions/permissionsSrv';
import {
  direction,
  DrillDownCategoriesTypes,
  DrillDownTypes,
  fieldName,
  filterKey,
  IDate,
  IFilterItem,
  IFindingsDrillDownMutualFilters,
  IFindingsFilter,
  IDrillDownHierarchy,
  IReasonFilterGroupsMap,
  IReasonFilterToColorMap,
  ISearchParamsFilters,
  ISelectedCategory,
  ISettingsModel,
  SelectedDateIntervals,
} from 'types';
import { FilterBuilder } from 'store/filters/filterBuilder';
import { DateIntervals, SIZE } from 'constants/constantsVaribles';
import { checkIsLeaf } from 'store/helpers/drillDown';
import {
  mapCategoriesToFilter,
  mapCategoryTypeToSearchKey,
  mapDrillDownTypesToLeafFilter,
  mapDrillDownTypeToFilterField,
  mapDrillDownTypeToSearchKey,
  mapLeafDrillDownTypeToSearchKey,
} from 'constants/hierarchy';
import { getOtherDrillDownCategories } from 'store/settings/settingsHepler';
import { getCurrentDate, isEmptyArray } from 'utils';
import i18n from 'i18n/i18n';
import { DropDownFiltersInitialState } from './findingsReducer';
import { buildDrilldownFilterFromQuery } from 'store/filters/filterHelper';

const addDrillDownFiltersByType = (
  filterBuilder: FilterBuilder,
  drillDownType: DrillDownTypes,
  hierarchyPath: Array<IDrillDownHierarchy>,
  hierarchyDepth: number,
) => {
  const hierarchyPathLength = hierarchyPath.length;

  // go through the hierarchy path
  for (let i = 0; i < hierarchyPathLength; i++) {
    const hierarchyLevel = hierarchyPath[i].hierarchyLevel
      ? hierarchyPath[i].hierarchyLevel! - 1
      : i;

    // default filter key
    let key = `${mapDrillDownTypeToFilterField[drillDownType]}.${i + 1}`;

    const { value } = hierarchyPath[i];

    // check if it's leaf
    if (hierarchyLevel >= hierarchyDepth) {
      // change filter key according to the drill-down type
      key = mapDrillDownTypesToLeafFilter[drillDownType];
    }

    filterBuilder.and().term(key, value);
  }
};

const addOtherDrillDownFilters = (filterBuilder: FilterBuilder, filters: Array<IFilterItem>) => {
  filters.forEach((filter) => {
    const { hierarchyValue, categoryType, value } = filter;

    if (categoryType && hierarchyValue) {
      // default field is leaf field
      // e.g. for stores-> storeId
      let field = mapCategoriesToFilter[categoryType];

      // check if it's category
      if (
        categoryType === DrillDownCategoriesTypes.StoreCategory ||
        categoryType === DrillDownCategoriesTypes.ProductCategory
      ) {
        // get category value.
        // e.g. for PRODUCT_CATEGORY_1 -> 1
        const key = hierarchyValue.split('_')[3];

        // e.g. for PRODUCT_CATEGORY_1 -> 1
        field += `.${key}`;
      }

      filterBuilder.and().term(field, value);
    }
  });
};

const addSearchFilterByType = (
  filterBuilder: FilterBuilder,
  type: DrillDownTypes,
  hierarchyDepth: number,
  mutualFilters: IFindingsDrillDownMutualFilters,
) => {
  const { search, hierarchyIndex } = mutualFilters;

  if (!isEmpty(search)) {
    const isLeaf = checkIsLeaf(hierarchyIndex, hierarchyDepth);
    let fieldName = `${mapDrillDownTypeToSearchKey[type]}.${hierarchyIndex}`;

    if (isLeaf) {
      fieldName = mapLeafDrillDownTypeToSearchKey[type];
    }
    filterBuilder.and().freeText(search, [fieldName]);
  }
};

const addOtherDrillDownSearch = (
  filterBuilder: FilterBuilder,
  text: string,
  selectedCategory?: ISelectedCategory,
) => {
  if (!isEmpty(text) && selectedCategory) {
    const { value, type } = selectedCategory;
    let fieldName = mapCategoryTypeToSearchKey[type];
    if (
      type === DrillDownCategoriesTypes.StoreCategory ||
      type === DrillDownCategoriesTypes.ProductCategory
    ) {
      const key = value.split('_')[3];

      fieldName += `.${key}`;
    }

    const fields = fieldName ? [fieldName] : undefined;

    filterBuilder.and().freeText(text, fields);
  }
};

const addSearchFilter = (
  filterBuilder: FilterBuilder,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  filters: IFindingsFilter,
  selectedCategory?: ISelectedCategory,
) => {
  const { mutualFilters: storeMutualFilters } = filters[DrillDownTypes.Store];

  const { mutualFilters: productMutualFilters } = filters[DrillDownTypes.Product];

  const {
    mutualFilters: { search: searchOther },
  } = filters[DrillDownTypes.Other];

  addSearchFilterByType(
    filterBuilder,
    DrillDownTypes.Store,
    storeHierarchyDepth,
    storeMutualFilters,
  );

  addSearchFilterByType(
    filterBuilder,
    DrillDownTypes.Product,
    productHierarchyDepth,
    productMutualFilters,
  );

  addOtherDrillDownSearch(filterBuilder, searchOther, selectedCategory);
};

const addDrillDownFilters = (
  filterBuilder: FilterBuilder,
  filters: IFindingsFilter,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  selectedCategory?: ISelectedCategory,
) => {
  const {
    store: {
      hierarchyPath: storeHierarchyPath,
      mutualFilters: { isFreeTextOnly: isStoreFreeTextOnly },
    },
    product: {
      hierarchyPath: productHierarchyPath,
      mutualFilters: { isFreeTextOnly: isProductFreeTextOnly },
    },
    other: {
      filters: otherFilters,
      mutualFilters: { isFreeTextOnly: isOtherFreeTextOnly },
    },
  } = filters;

  const isFreeTextOnly = isStoreFreeTextOnly || isProductFreeTextOnly || isOtherFreeTextOnly;

  addDrillDownFiltersByType(
    filterBuilder,
    DrillDownTypes.Store,
    storeHierarchyPath,
    storeHierarchyDepth,
  );

  addDrillDownFiltersByType(
    filterBuilder,
    DrillDownTypes.Product,
    productHierarchyPath,
    productHierarchyDepth,
  );

  addOtherDrillDownFilters(filterBuilder, otherFilters);

  addSearchFilter(
    filterBuilder,
    storeHierarchyDepth,
    productHierarchyDepth,
    filters,
    selectedCategory,
  );
  if (isFreeTextOnly) {
    filterBuilder.and().nonempty(filterKey.RESPONSE_TEXT);
  }
};

const addReasonFilters = (filterBuilder: FilterBuilder, reasonFilter: Array<number>) => {
  reasonFilter.forEach((reasonId) => {
    filterBuilder.or().term(filterKey.REASON_ID, reasonId);
  });
};

export const buildFindingsFilter = (
  filterBuilder: FilterBuilder,
  filters: IFindingsFilter,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  lastUserDeployDate: number,
  selectedCategory?: ISelectedCategory,
) => {
  const {
    date: { type, from, to },
  } = filters;

  // Add custom date filter
  if (type === SelectedDateIntervals.customId) {
    filterBuilder.and().time(filterKey.DEPLOY_DATE, from, to);
  } else if (type === SelectedDateIntervals.lastDeployment) {
    filterBuilder.and().time(filterKey.DEPLOY_DATE, lastUserDeployDate);
  } else {
    filterBuilder.and().relative(filterKey.DEPLOY_DATE, DateIntervals[type]);
  }

  addDrillDownFilters(
    filterBuilder,
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    selectedCategory,
  );

  return filterBuilder;
};

export const buildGetReasonsRequestFilterBody = (
  filters: IFindingsFilter,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  lastUserDeployDate: number,
  selectedCategory?: ISelectedCategory,
): any => {
  const filterBuilder = new FilterBuilder();
  buildFindingsFilter(
    filterBuilder,
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    lastUserDeployDate,
    selectedCategory,
  );

  const filter = filterBuilder.build();

  const { direction, fieldName } = filters.order;

  const orders = [{ direction, fieldName }];

  return { filter, orders };
};

// filter for drill downs
export const buildDrillDownFilter = (
  filters: IFindingsFilter,
  drillDownType: DrillDownTypes,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  pageOffset: number,
  lastUserDeployDate: number,
  selectedCategory?: ISelectedCategory,
) => {
  const filterBuilder = new FilterBuilder();

  // Add selected reasons filters
  addReasonFilters(filterBuilder, filters.activeReasonFilters);
  buildFindingsFilter(
    filterBuilder,
    filters,
    storeHierarchyDepth,
    productHierarchyDepth,
    lastUserDeployDate,
    selectedCategory,
  );
  filterBuilder.and().term(filterKey.RESPONSE_TYPE, 'ISSUE_FOUND');

  const filter = filterBuilder.build();

  const {
    activeFilters: {
      order: { direction, fieldName },
    },
  } = filters[drillDownType];

  const isExpended = filters.expendedDrillDown === drillDownType;

  const PAGE_SIZE = isExpended ? SIZE : 3;

  const orders = [{ direction, fieldName }];

  return {
    filter,
    orders,
    page: {
      from: pageOffset,
      size: PAGE_SIZE,
    },
  };
};

export const getOtherDrillDownIsHidden = (settings: ISettingsModel): boolean => {
  const categories = getOtherDrillDownCategories(settings);

  const { productCategories, storeCategories } = categories;

  const isCategoriesEmpty = isEmptyArray(productCategories) && isEmptyArray(storeCategories);

  const isUserSingleStoreManager = permissionsService.isSingleStoreManager();

  return isUserSingleStoreManager && isCategoriesEmpty;
};

export const getReasonsFilterGroupsHelper = (
  getReasonsGroups: Array<number> | null,
  getReasonGroupsMap: IReasonFilterGroupsMap | null,
  getSettingsSelector: ISettingsModel,
  reasonFilterToColorMap: IReasonFilterToColorMap,
) => {
  if (!getReasonsGroups || !getReasonGroupsMap || !getSettingsSelector) {
    return null;
  }

  const { language } = getSettingsSelector;

  // Flag that indicate if ant of the reasons filter are active
  let noActiveReasons = true;

  const reasonsGroups = getReasonsGroups.map((reasonGroupId) => {
    const currentReasonGroup = getReasonGroupsMap[reasonGroupId];

    const color = reasonFilterToColorMap[reasonGroupId];

    const { label: labelObject, value, reasonFilter } = currentReasonGroup;

    const label = (labelObject[language] || labelObject['en-US']).toLowerCase();

    let selectedReasonsCount = 0;

    const reasonFilterList = reasonFilter.map((reason) => {
      const { label: labelObject, value: reasonValue, isActive, id } = reason;

      const label = labelObject[language] || labelObject['en-US'];

      if (isActive) {
        selectedReasonsCount += reasonValue;

        // Found active item - set noActiveReasons to false
        noActiveReasons = false;
      }

      return { label, value: reasonValue, isActive, id };
    });

    return {
      color,
      value,
      label,
      reasonFilter: reasonFilterList,
      id: reasonGroupId,
      selectedReasonsCount,
    };
  });

  return { reasonsGroups, noActiveReasons };
};

export const getOtherSelectedCategoryHelper = (productCategories: any, storeCategories: any) => {
  const isUserSingleStoreManager = permissionsService.isSingleStoreManager();

  let category = {
    label: i18n.t('COMMON.PRODUCTS'),
    value: DrillDownCategoriesTypes.Product,
    type: DrillDownCategoriesTypes.Product,
  };
  // if there is any product categories chose the first one
  if (!isEmptyArray(productCategories)) {
    const { label, value } = productCategories[0];
    category = { label, value, type: DrillDownCategoriesTypes.ProductCategory };
  }
  // otherwise, if there is any store categories chose the first one
  else if (!isEmptyArray(storeCategories)) {
    const { label, value } = storeCategories[0];
    category = { label, value, type: DrillDownCategoriesTypes.StoreCategory };
  }
  // if we reach here, there is no  categories for this user
  else if (!isUserSingleStoreManager) {
    // for not single store manager-> choose store
    category = {
      label: i18n.t('COMMON.STORES'),
      value: DrillDownCategoriesTypes.Store,
      type: DrillDownCategoriesTypes.Store,
    };
  }

  return category;
};

const buildEmptyFilters = (date?: IDate) => {
  const filters: IFindingsFilter = {
    date: date || {
      type: SelectedDateIntervals.monthId,
      from: getCurrentDate(),
      to: getCurrentDate(),
    },
    expendedDrillDown: null,
    activeReasonFilters: [],
    order: {
      direction: direction.DESC,
      fieldName: fieldName.count,
    },
    [DrillDownTypes.Store]: cloneDeep(DropDownFiltersInitialState),
    [DrillDownTypes.Product]: cloneDeep(DropDownFiltersInitialState),
    [DrillDownTypes.Other]: cloneDeep(DropDownFiltersInitialState),
  };

  return filters;
};

export const buildFiltersFromSearchParams = (
  searchFilters: ISearchParamsFilters,
  currentFilter: IFindingsFilter,
) => {
  const { date, store, product, other } = searchFilters;

  const newFilters = buildEmptyFilters(date);

  const sortFieldName = fieldName.issueFoundCount;

  if (other) {
    const { filter, category, order = direction.DESC } = other;

    const currentSelectedCategory =
      currentFilter[DrillDownTypes.Other].activeFilters.selectedCategory;

    if (filter) {
      const otherFilters = [buildDrilldownFilterFromQuery(filter, DrillDownTypes.Other, category)];
      newFilters[DrillDownTypes.Other].filters = otherFilters;
    }

    // set selected category if not equal to current
    newFilters[DrillDownTypes.Other].activeFilters.selectedCategory =
      category || currentSelectedCategory;
    newFilters[DrillDownTypes.Other].activeFilters.order.direction = order;
    newFilters[DrillDownTypes.Other].activeFilters.order.fieldName = sortFieldName;
  }

  if (store) {
    const { filter, hierarchyPath = [], order = direction.DESC, hierarchyIndex = 1 } = store;

    if (filter) {
      const storeFilters = [buildDrilldownFilterFromQuery(filter, DrillDownTypes.Store)];
      newFilters[DrillDownTypes.Store].filters = storeFilters;
      newFilters[DrillDownTypes.Store].hierarchyPath = [...hierarchyPath];
    }
    newFilters[DrillDownTypes.Store].activeFilters.order.direction = order;
    newFilters[DrillDownTypes.Store].activeFilters.order.fieldName = sortFieldName;
    newFilters[DrillDownTypes.Store].mutualFilters.hierarchyIndex = hierarchyIndex;
  }

  if (product) {
    const { filter, hierarchyPath = [], order = direction.DESC, hierarchyIndex = 1 } = product;

    if (filter) {
      const productFilters = [buildDrilldownFilterFromQuery(filter, DrillDownTypes.Product)];
      newFilters[DrillDownTypes.Product].filters = productFilters;
      newFilters[DrillDownTypes.Product].hierarchyPath = [...hierarchyPath];
    }
    newFilters[DrillDownTypes.Product].activeFilters.order.direction = order;
    newFilters[DrillDownTypes.Product].activeFilters.order.fieldName = sortFieldName;
    newFilters[DrillDownTypes.Product].mutualFilters.hierarchyIndex = hierarchyIndex;
  }

  return newFilters;
};
