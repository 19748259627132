import { flow, set } from 'lodash/fp';

import { IActionType, IPrintModel } from 'types';
import PrintActionTypes from './printActionTypes';

export const initialState: IPrintModel = {
  id: null,
  selectedPrintingOption: {
    PrintReasons: false,
    PrintImage: false,
  },
};

const printReducer = (state: IPrintModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case PrintActionTypes.Show: {
      const { id, selectedPrintingOption } = payload;

      return flow([set('id', id), set('selectedPrintingOption', selectedPrintingOption)])(state);
    }

    case PrintActionTypes.Hide: {
      return initialState;
    }

    default:
      return state;
  }
};

export default printReducer;
