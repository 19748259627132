import cn from 'clsx';
import { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { Button } from 'components/buttons/Button';
import { ENVIRONMENTS, getBaseUrl } from 'store/api/APIConstants';
import { setEnvironmentDebugSettings } from 'store/environment/environmentActions';
import './developerOptions.scss';
import { clearTokens } from 'utils';
import packagejson from '../../../../package.json';
import { IRootState } from 'store/types';

interface IDeveloperOptionsState {
  numberOfTaps: number;
  lastTapTime: Date | null;
}

const DeveloperOptions: FC<any> = ({ setEnvironmentDebugSettings, currentEnvironment }) => {
  const [showDeveloperOptions, setShowDeveloperOptions] = useState(false);

  const [developerOptions, setDeveloperOptions] = useState<IDeveloperOptionsState>({
    numberOfTaps: 0,
    lastTapTime: null,
  });

  const onOptionsTapped = useCallback(() => {
    let currentNumberOfTaps = 1;

    const dateNow = new Date();

    if (developerOptions.lastTapTime !== null) {
      const lastTap = developerOptions.lastTapTime ? developerOptions.lastTapTime.getTime() : 0;

      const secondsBetweenTaps = Math.abs(dateNow.getTime() - lastTap) / 1000;

      if (secondsBetweenTaps < 2) {
        currentNumberOfTaps = developerOptions.numberOfTaps + 1;
      }
    }

    if (currentNumberOfTaps >= 7) {
      setShowDeveloperOptions(true);
    } else {
      setDeveloperOptions({
        numberOfTaps: currentNumberOfTaps,
        lastTapTime: dateNow,
      });
    }
  }, [developerOptions]);

  const onApplyClicked = () => {
    clearTokens();
    window.location.href = '/';
  };

  const buildNumber = process.env.REACT_APP_BUILD_NUMBER;

  return (
    <div className="dev-options">
      <div role="button" tabIndex={0} className="page-header" onClick={onOptionsTapped}>
        {`Developer Info [${currentEnvironment}]`}
      </div>
      <div className="page-container">
        <div className="contracts">{`Api url: [${getBaseUrl(currentEnvironment)}]`}</div>
        <div className="version">{`Package version: ${packagejson.version} build #${buildNumber}`}</div>
        {showDeveloperOptions && (
          <div className="adv-options">
            <ul className="supported-env">
              {ENVIRONMENTS.map((env) => {
                const isActive = env === currentEnvironment;

                const envString = `${env}${isActive ? ' [Active]' : ''}`;

                return (
                  <li
                    className={cn('env', isActive && 'active')}
                    onClick={() => setEnvironmentDebugSettings(env)}
                  >
                    {' '}
                    {envString}
                  </li>
                );
              })}
            </ul>
            <Button className="env-button" label="Apply" onClick={onApplyClicked} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentEnvironment: state.environment.currentEnvironment,
});

const mapDispatchToProps = (dispatch: any) => ({
  setEnvironmentDebugSettings: (currentEnvironment: string) =>
    dispatch(setEnvironmentDebugSettings(currentEnvironment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperOptions);
