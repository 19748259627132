export enum UserRole {
  STORE_MANAGER = 'STORE_MANAGER',
  REGIONAL_MANAGER = 'REGIONAL_MANAGER',
  NONE = 'NONE',
}

export enum UserPrivileges {
  MAY_VIEW_DASHBOARDS = 'mayViewDashboard', // includes Summary, finding, trends and open opportunities page
  MAY_VIEW_DASHBOARDS_TOKEN = 'mayViewDashboardToken', // retrieved from jwt complementary to mayViewOpportunities
  MAY_VIEW_OPPORTUNITIES = 'mayViewOpportunities', // referring to 'open opportunities' which single store manager can't see
  MAY_VIEW_SALES_INCREASE = 'mayViewSalesIncrease', // referring to 'Sales Increase' in summary and trends
}

export type Store = { id: string; storeName: string };

export interface IHierarchyPath {
  level: HierarchyLevel;
  id: string;
}

export type IUserProfileModel = {
  projectIds: Array<number>;
  email: string;
  emailVerified: boolean;
  alias: string;
  preferredUsername: string;
  sub: string;
  uid: string;
  pid: string;
  cid: string;
  deviceToken: string;
  role: UserRole;
  isAllUser: boolean;
  serverResponseStatus: number | null;
  userHighestHierarchyPath: Array<IHierarchyPath>;
  userHierarchyPaths: Array<IHierarchyPath>;
  userHighestHierarchyLowestLevel: IHierarchyPath | null;
  mayViewDashboard: boolean;
  mayViewSalesIncrease: boolean;
  userPeersLevel: IHierarchyPath | null;
  stores: Array<Store>;
  lastUserDeployDate: number;
  mayViewOpportunities: boolean;
  isScopeError?: boolean;
};

export enum HierarchyLevel {
  ALL = 'ALL',
  LEVEL1 = 'LEVEL1',
  LEVEL2 = 'LEVEL2',
  LEVEL3 = 'LEVEL3',
  LEVEL4 = 'LEVEL4',
  LEVEL5 = 'LEVEL5',
  LEAF = 'LEAF',
  STORES = 'Stores',
}

export const mapResponseHierarchyToHierarchyLevel = {
  store: HierarchyLevel.LEAF,
  'storeHierarchy.0': HierarchyLevel.ALL,
  'storeHierarchy.1': HierarchyLevel.LEVEL1,
  'storeHierarchy.2': HierarchyLevel.LEVEL2,
  'storeHierarchy.3': HierarchyLevel.LEVEL3,
  'storeHierarchy.4': HierarchyLevel.LEVEL4,
  'storeHierarchy.5': HierarchyLevel.LEVEL5,
};

export const mapHierarchyLevelToOrder = {
  [HierarchyLevel.ALL]: 0,
  [HierarchyLevel.LEVEL1]: 1,
  [HierarchyLevel.LEVEL2]: 2,
  [HierarchyLevel.LEVEL3]: 3,
  [HierarchyLevel.LEVEL4]: 4,
  [HierarchyLevel.LEVEL5]: 5,
  [HierarchyLevel.LEAF]: 10,
};

export interface IProductImageMetadata {
  relativeSize: number;
}

export enum ProductImagesMetadataTypes {
  THUMBNAIL = 'THUMBNAIL',
  LORES = 'LORES',
  HIRES = 'HIRES',
}

export interface ProductImagesMetadataOptions {
  THUMBNAIL: IProductImageMetadata;
  LORES: IProductImageMetadata;
  HIRES: IProductImageMetadata;
}
