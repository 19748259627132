import { FC, memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { FilterButton } from 'components/buttons';
import { Tabs } from 'components/tabs';
import { AlertButtonsIds, AlertChecklistIds, AlertIds } from 'components/common/alert/types';
import { Cards } from 'components/cards';
import { FilterIcon, PrintIcon } from 'components/Icons';
import MyOpportunityCard from 'components/myOpportunities/card/MyOpportunityCard';
import 'components/pages/myOpportunities/myOpportunities.scss';
import MyOpportunitiesPreview from 'components/pages/myOpportunities/myOpportunitiesPreview';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';
import routerService from 'services/router/routerService';
import { show } from 'store/alert/alertActions';
import {
  setMyOpportunitiesSearch,
  toggleControlMenu,
  updateLoadMoreOffset,
} from 'store/filters/filtersActions';
import FiltersSelector from 'store/filters/filtersSelector';
import LoadingSelector from 'store/loading/LoadingSelector';
import {
  getHitRateScore,
  getMyOpportunities,
  removeOpportunityDeepLink,
  selectMyOpportunity,
  setActiveTab,
} from 'store/myOpportunities/myOpportunitiesActions';
import MyOpportunitiesSelector from 'store/myOpportunities/myOpportunitiesSelector';
import {
  IRootState,
  ISearchParamsFilters,
  OpportunitiesTabs,
  OpportunitiesTabsOptions,
} from 'store/types';
import {
  emptyStateAnimationList,
  IAlert,
  IMyOpportunitiesProps,
  LifecycleOptions,
  ResponseTypes,
} from 'types';
import { SearchInput } from 'components/inputs';
import { withMultipleUnit } from 'utils';
import { DEBOUNCE_SEARCH_DELAY } from 'constants/constantsVaribles';
import featureFlagsService from 'services/permissions/featureFlagsService';
import reasonsSelector from 'store/reasons/reasonsSelector';
import i18n from 'i18n/i18n';
import UserProfileSelector from 'store/userProfile/userProfileSelector';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';
import MyOpportunitiesEmptyState from './myOpportunitiesEmptyState';

const MyOpportunities: FC<IMyOpportunitiesProps> = ({
  getMyOpportunities,
  myOpportunities,
  selectedMyOpportunityId,
  selectMyOpportunity,
  isLoading,
  activeFilters,
  hasMore,
  nextPageOffset,
  activeTab,
  setActiveTab,
  opportunitiesGroupContent,
  opportunitiesGroupCount,
  totalCountOpenTab,
  toggleControlMenu,
  updateLoadMoreOffset,
  isFilterOn,
  currency,
  isRtl,
  showPrintMenu,
  showProductImages,
  setMyOpportunitiesSearch,
  deepLinkOpportunityId,
  removeOpportunityDeepLink,
  showTargetSales,
  showTargetUnits,
  showResponseImpactOnSales,
  reasonIdToTextMapper,
  getHitRateScore,
  animatedEmptyState,
}) => {
  const [t] = useTranslation();

  const isOpenTabActive = activeTab === OpportunitiesTabsOptions.Open;

  const isImpactOnSalesChartEnable = !isOpenTabActive && featureFlagsService.mayViewImpactOnSales();

  const isDeeplinkOpportunity = !!deepLinkOpportunityId;

  const { search } = activeFilters;

  const [searchText, setSearchText] = useState(search);

  // set prev search value when switching between tabs
  useEffect(() => {
    setSearchText(search);
  }, [activeTab]);

  // request motivating animation data
  useEffect(() => {
    if (animatedEmptyState && isOpenTabActive) {
      getHitRateScore(emptyStateAnimationList.length);
    }
  }, [animatedEmptyState]);

  const updateSearchParams = (cardId?: string) => {
    const selectedCardId = cardId || deepLinkOpportunityId;

    // build search filters
    const searchFilters: ISearchParamsFilters = {
      tab: activeTab.toString(),
      cardId: selectedCardId,
    };

    const params = buildQueryParamsFromSearchFilters(searchFilters);

    routerService.updateSearchParams(params);
  };

  // update Query params when active tab changes
  useEffect(() => {
    updateSearchParams();
  }, [activeTab, deepLinkOpportunityId]);

  useEffect(() => {
    getMyOpportunities(activeFilters, showResponseImpactOnSales, deepLinkOpportunityId);
  }, [activeFilters, deepLinkOpportunityId]);

  const onCardClick = useCallback(
    (cardId: string) => {
      selectMyOpportunity(cardId);

      updateSearchParams(cardId);
    },
    [selectedMyOpportunityId],
  );

  const loadMore = useCallback(() => {
    if (hasMore && getMyOpportunities !== null && !isLoading) {
      updateLoadMoreOffset(nextPageOffset);
    }
  }, [nextPageOffset, hasMore, isLoading]);

  const itemContent = useCallback(
    (index) => {
      const currentOpportunity = myOpportunities && myOpportunities![index];

      if (!currentOpportunity) {
        return null;
      }

      const { language } = i18n;

      const { selectedReason } = currentOpportunity;

      const respondReasonDate = currentOpportunity.selectedReason?.updateDate || 0;

      const isNoIssueFound = selectedReason?.responseType === ResponseTypes.NO_ISSUE_FOUND;

      const isExpired = currentOpportunity.lifecycle === LifecycleOptions.EXPIRED;

      const currentOpportunityId = currentOpportunity.id;

      const isSelected = selectedMyOpportunityId === currentOpportunityId;

      const targetSales = showTargetSales ? currentOpportunity.targetSales : 0;

      const targetUnits = showTargetUnits ? currentOpportunity.targetUnits : 0;

      let selectedReasonFooter: { title: string; description?: string } | undefined;

      if (
        selectedReason &&
        selectedReason.reasonId &&
        reasonIdToTextMapper[selectedReason.reasonId]
      ) {
        selectedReasonFooter = {
          title: reasonIdToTextMapper[selectedReason.reasonId][language],
          description: selectedReason.description,
        };
      }

      return (
        <MyOpportunityCard
          action={currentOpportunity.action}
          isRtl={isRtl}
          targetUnits={targetUnits}
          currency={currency}
          isSelected={isSelected}
          onCardClick={onCardClick}
          respondReasonDate={respondReasonDate}
          isImpactOnSalesChartEnable={isImpactOnSalesChartEnable}
          isExpired={isExpired}
          isNoIssueFound={isNoIssueFound}
          productImage={currentOpportunity.lowResolutionProductImage}
          targetSales={targetSales}
          productCategories={currentOpportunity.productCategories}
          productName={currentOpportunity.productName}
          storeName={currentOpportunity.storeName}
          id={currentOpportunityId}
          lastSalesValues={currentOpportunity.lastSalesValues}
          postLastSalesValues={currentOpportunity.postLastSalesValues}
          productSkusAndSizes={currentOpportunity.productSkusAndSizes}
          selectedReason={selectedReasonFooter}
        />
      );
    },
    [myOpportunities, selectedMyOpportunityId],
  );

  const groupContent = useCallback(
    (index: number) => {
      const groupHeader = opportunitiesGroupContent && opportunitiesGroupContent![index];

      return <div className="cards-header">{groupHeader}</div>;
    },
    [opportunitiesGroupContent],
  );

  const onTabClicked = (tabToSelect: number) => {
    if (tabToSelect !== activeTab) {
      setSearchText('');
      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.TAB, {
        tab_name: OpportunitiesTabsOptions.Open === tabToSelect ? 'Open' : 'Completed',
      });
      setActiveTab(tabToSelect);
    }
  };

  const onSearchChange = debounce((text: string) => {
    setMyOpportunitiesSearch(text);
  }, DEBOUNCE_SEARCH_DELAY);

  const onTextChange = (value: string) => {
    setSearchText(value);
    onSearchChange(value);
  };

  const removeDeeplinkFilter = () => {
    removeOpportunityDeepLink(activeTab);
  };

  const myOpportunitiesTab = OpportunitiesTabs.map((tab) => {
    const label = t(tab.label);

    let amount;

    if (tab.id === OpportunitiesTabsOptions.Open) {
      amount = withMultipleUnit(totalCountOpenTab, 1);
    }

    return {
      label,
      id: tab.id,
      amount,
    };
  });

  const openPrintDialog = () => {
    const checklist = [
      {
        id: AlertChecklistIds.PrintReasons,
        label: t(`MY_OPPORTUNITIES.PRINT.REASON_LIST`),
        isChecked: false,
      },
    ];
    if (showProductImages) {
      checklist.push({
        id: AlertChecklistIds.PrintImage,
        label: t(`MY_OPPORTUNITIES.PRINT.PRODUCT_IMAGE`),
        isChecked: false,
      });
    }

    const options: IAlert = {
      id: AlertIds.OpenPrintMenu,
      title: t(`MY_OPPORTUNITIES.PRINT.TITLE`),
      text: t(`MY_OPPORTUNITIES.PRINT.MESSAGE`),
      buttons: [
        { id: AlertButtonsIds.Cancel, text: t(`BUTTON.CANCEL`) },
        { id: AlertButtonsIds.Continue, text: t(`BUTTON.CONTINUE`) },
      ],
      checklist,
      withOverlay: true,
      alertCustomClassName: 'print-menu',
    };

    showPrintMenu(options);
  };

  return (
    <div className="page">
      <div className="page-header">
        <span>{t('PAGES.MY_OPPORTUNITIES')}</span>
        <SearchInput
          id="opportunities-search-input"
          defaultValue={searchText}
          key={activeTab}
          containerClassName="my-opportunities-search-container"
          onTextChanged={onTextChange}
          isRtl={isRtl}
        />
      </div>
      <div className="page-container">
        <div className="page-list">
          <div className="my-opportunities-list-container">
            <div className="list-control-header">
              <Tabs
                id="my-opportunities-tab"
                isRtl={isRtl}
                containerClassName="counter-tabs-container"
                tabs={myOpportunitiesTab}
                activeTab={activeTab}
                onChange={onTabClicked}
                textClassName="medium-tabs"
              />
              <div className="deep-link-filter">
                {isDeeplinkOpportunity && myOpportunities && (
                  <FilterButton
                    label={myOpportunities![0].productName}
                    id="test"
                    onRemoveFilter={removeDeeplinkFilter}
                  />
                )}
              </div>
              <div className="list-controls">
                <div
                  id="opportunities-print-button"
                  className={cn('print', { invisible: !isOpenTabActive })}
                  onClick={openPrintDialog}
                >
                  <PrintIcon />
                </div>
                <div
                  className="filter"
                  id="opportunities-filter-button"
                  onClick={toggleControlMenu}
                >
                  <FilterIcon className={cn(isFilterOn && 'active')} />
                </div>
              </div>
            </div>
            <Cards
              dataTestId="my-opportunities-list"
              cards={myOpportunities}
              isLoading={isLoading}
              onLoadMore={loadMore}
              emptyState={<MyOpportunitiesEmptyState />}
              itemContent={itemContent}
              groupCounts={opportunitiesGroupCount}
              groupContent={groupContent}
            />
          </div>
        </div>
        <div className="page-preview">
          <MyOpportunitiesPreview />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { availability: taskExpiryDays } = state.settings.taskExpiryDays;

  const {
    hasMore,
    opportunities: myOpportunities,
    opportunitiesGroupContent,
    opportunitiesGroupCount,
    activeTab,
    totalCountOpenTab,
    nextPageOffset,
    deepLinkOpportunityId,
    animatedEmptyState,
  } = state.myOpportunities;

  const { currency } = state.settings.general;

  const {
    showProductImages,
    showTargetSales,
    showTargetUnits,
    showResponseImpactOnSales,
  } = state.settings.featureFlags;

  const { isRtl } = state.settings;

  const selectedMyOpportunity = MyOpportunitiesSelector.getSelectedOpportunitySelector(state);

  const reasonIdToTextMapper = reasonsSelector.getMapReasonIdToTextSelector(state);

  return {
    activeFilters: FiltersSelector.getMyOpportunitiesFilterSelector(state),
    isLoading: LoadingSelector.isGetMyOpportunitiesLoading(state),
    productCategories: MyOpportunitiesSelector.getSelectedProductCategoriesSelector(state),
    selectedMyOpportunityId: selectedMyOpportunity?.id,
    filterList: FiltersSelector.getMyOpportunitiesFilterList(state),
    haveOpportunitiesBeenDeployed: UserProfileSelector.haveOpportunitiesBeenDeployed(state),
    isFilterOn: FiltersSelector.isMyOpportunitiesFilterOn(state),
    hasMore,
    myOpportunities,
    opportunitiesGroupContent,
    opportunitiesGroupCount,
    showProductImages,
    totalCountOpenTab,
    activeTab,
    nextPageOffset,
    isRtl,
    taskExpiryDays,
    currency,
    deepLinkOpportunityId,
    showTargetSales,
    showTargetUnits,
    showResponseImpactOnSales,
    reasonIdToTextMapper,
    animatedEmptyState,
  };
};

const mapDispatchToProps = {
  getMyOpportunities,
  selectMyOpportunity,
  setActiveTab,
  toggleControlMenu,
  setMyOpportunitiesSearch,
  updateLoadMoreOffset,
  showPrintMenu: show,
  removeOpportunityDeepLink,
  getHitRateScore,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(MyOpportunities));
