import './previewCardsList.scss';
import { FC, memo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import PreviewCard from 'components/openOpportunities/preview/previewCard/previewCard';
import {
  animationType,
  IPopupModal,
  IRootState,
  OpenOpportunitiesDrillDownTabsTypes,
  PopupIds,
} from 'types';
import { Cards } from 'components/cards';
import { IPreviewCardListProps } from './types';
import OpportunitiesList from 'components/drilldown/opportunities/OpportunitiesList';
import PreviewEmptyState from 'components/openOpportunities/preview/previewEmptyState';
import loadingSelector from 'store/loading/LoadingSelector';
import { ProgressAnimation } from 'components/animation';
import { show as showOpportunityPopup } from 'store/popup/popupActions';
import {
  addHierarchyFilter,
  getDrillDownItems,
} from 'store/openOpportunities/openOpportunitiesActions';
import openOpportunitiesSelector from 'store/openOpportunities/openOpportunitiesSelector';
import { isEmptyArray } from 'utils';
import UserProfileSelector from 'store/userProfile/userProfileSelector';

export const PreviewCardsList: FC<IPreviewCardListProps> = ({
  cardType,
  getDrillDownItems,
  drillDownList,
  addHierarchyFilter,
  filters,
  opportunitiesDrillDownList,
  showOpportunityPopup,
  lastHierarchyItem,
  weeksTimePeriod = 4,
  issuesFound = 0,
  isRtl,
  hasDrilldownItems = true,
  isLoading,
  haveOpportunitiesBeenDeployed,
}) => {
  const { selectedDropdownOption, selectedTab, hierarchyIndex, searchValue } = filters;

  const { nextPageOffset, hasMore, cards, totalCount, highestValue, opportunities } = drillDownList;

  const isNoOpportunities = cards === null && opportunities === null;

  const showOpportunities =
    selectedTab === OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY &&
    opportunitiesDrillDownList;

  const isStoreLastHierarchyItem = lastHierarchyItem?.hierarchyLevel === hierarchyIndex;

  const showDrilldownItems =
    hasDrilldownItems &&
    selectedTab !== OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY &&
    cards;

  // get drill down items when filters change and after cards
  useEffect(() => {
    if (hasDrilldownItems) {
      getDrillDownItems(cardType);
    }
  }, [filters, cardType, hasDrilldownItems]);

  const onLoadMore = useCallback(() => {
    if (hasMore) {
      getDrillDownItems(cardType, nextPageOffset);
    }
  }, [nextPageOffset, hasMore, cardType]);

  const onOpportunityClick = useCallback(
    (selectedOpportunityId: string) => {
      const options: IPopupModal = {
        id: PopupIds.OpenOpportunitiesOpportunity,
        selectedOpportunityId,
      };
      showOpportunityPopup(options);
    },
    [showOpportunityPopup],
  );

  const emptyState = (
    <PreviewEmptyState
      haveOpportunitiesBeenDeployed={haveOpportunitiesBeenDeployed}
      isStoreOpportunities={isStoreLastHierarchyItem}
      cardType={cardType}
      storeName={isStoreLastHierarchyItem ? lastHierarchyItem?.name : ''}
      issuesFound={issuesFound}
      searchText={searchValue}
      weeksTimePeriod={weeksTimePeriod}
      isNoOpportunities={isNoOpportunities}
    />
  );

  const itemContent = useCallback(
    (index: number) => {
      const currentCard = cards![index];

      if (!currentCard) {
        return null;
      }

      return (
        <PreviewCard
          card={currentCard}
          progressTotalValue={highestValue}
          viewOption={selectedDropdownOption}
          isRtl={isRtl}
          onCardClick={() => addHierarchyFilter(currentCard)}
          cardType={cardType}
          isLoading={isLoading}
        />
      );
    },
    [cardType, isRtl, isLoading, cards, selectedDropdownOption],
  );

  if (isLoading) {
    return <ProgressAnimation animationType={animationType.Spinner} isLoading />;
  }

  if (showDrilldownItems) {
    return (
      <Cards
        dataTestId="open-opportunities-preview-cards"
        itemContent={itemContent}
        cards={cards}
        totalCount={totalCount}
        onLoadMore={onLoadMore}
        isLoading={isLoading}
        emptyState={emptyState}
      />
    );
  }

  if (showOpportunities) {
    return (
      <OpportunitiesList
        isLoading={isLoading}
        totalCount={totalCount}
        emptyState={emptyState}
        list={opportunitiesDrillDownList?.list || []}
        opportunitiesGroupContent={opportunitiesDrillDownList?.groupContent}
        opportunitiesGroupCount={opportunitiesDrillDownList?.groupCount}
        onOpportunityClick={onOpportunityClick}
        onLoadMore={onLoadMore}
        shouldCalcHeight={false}
      />
    );
  }
  if (!hasDrilldownItems) {
    return emptyState;
  }

  return null;
};

const mapStateToProps = (state: IRootState) => {
  const { opportunitiesCountByWeek } = state.openOpportunities;

  const {
    list: drillDownList,
    hierarchyPath,
    totalFindingsByReasons: issuesFound,
  } = state.openOpportunities.drillDown;

  const lastHierarchyItem = hierarchyPath[hierarchyPath.length - 1];

  const opportunitiesDrillDownList = openOpportunitiesSelector.getOpportunitiesDrillDownSelector(
    state,
  );

  const weeksTimePeriod = openOpportunitiesSelector.getWeeksTimePeriodSelector(state);

  const isDrillDownLoading = loadingSelector.isOpenOpportuntiesDrilldownLoading(state);

  const isOpportunitiesLoading = loadingSelector.isOpportuntiesLoading(state);

  const haveOpportunitiesBeenDeployed = UserProfileSelector.haveOpportunitiesBeenDeployed(state);

  const isLoading =
    isDrillDownLoading ||
    isOpportunitiesLoading ||
    (drillDownList.cards === undefined && drillDownList.opportunities === undefined);

  const hasDrilldownItems =
    opportunitiesCountByWeek !== null && !isEmptyArray(opportunitiesCountByWeek);

  const { isRtl } = state.settings;

  return {
    drillDownList,
    isRtl,
    opportunitiesDrillDownList,
    lastHierarchyItem,
    issuesFound,
    weeksTimePeriod,
    hasDrilldownItems,
    isLoading,
    haveOpportunitiesBeenDeployed,
  };
};

const mapDispatchToProps = {
  getDrillDownItems,
  showOpportunityPopup,
  addHierarchyFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PreviewCardsList));
