import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import TrendsActionTypes from './trendsActionTypes';
import {
  DrillDownTypes,
  IFilterItem,
  ISelectedCategory,
  SelectedDateIntervals,
  TrendsTabOptions,
} from 'types';

/* **** HIT RATE REQUESTS **** */

// GET hierarchy or category data -> not leaf
export const getHierarchyDrillDownDataHitRate = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number,
  endpoint: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType, pageOffset },
    baseAction: TrendsActionTypes.getHierarchyDrillDownDataHitRate,
  };
};

export const getStoresHitRate = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Store`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: TrendsActionTypes.getStoresHitRate,
  };
};

export const getProductsHitRate = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Product`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: TrendsActionTypes.getProductsHitRate,
  };
};

/* **** RESPONSE RATE REQUESTS **** */

export const getHierarchyDrillDownDataResponseRate = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number,
  endpoint: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate/${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType, pageOffset },
    baseAction: TrendsActionTypes.getHierarchyDrillDownDataResponseRate,
  };
};

export const getStoresResponseRate = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate/Store`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: TrendsActionTypes.getStoresResponseRate,
  };
};

export const getProductResponseRate = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate/Product`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: TrendsActionTypes.getProductResponseRate,
  };
};

/* **** SALES INCREASE REQUESTS **** */

// Hierarchy level
export const getHierarchyDrillDownDataSalesIncrease = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number,
  endpoint: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { drillDownType, pageOffset },
    baseAction: TrendsActionTypes.getHierarchyDrillDownDataSalesIncrease,
  };
};

// Category
export const getCategoryDrillDownDataSalesIncrease = (
  filter: object,
  pageOffset: number,
  endpoint: string,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset },
    baseAction: TrendsActionTypes.getCategoryDrillDownDataSalesIncrease,
  };
};

export const getStoresSalesIncrease = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/RevenueByStoreQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: TrendsActionTypes.getStoresSalesIncrease,
  };
};

export const getProductSalesIncrease = (
  filter: object,
  drillDownType: DrillDownTypes,
  pageOffset: number = 0,
) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/RevenueByProductQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    payload: { pageOffset, drillDownType },
    baseAction: TrendsActionTypes.getProductSalesIncrease,
  };
};

export const getSalesIncreaseDetails = (filter: object) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/RevenueHistogramQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: TrendsActionTypes.getSalesIncreaseHistogram,
  };
};

export const getHitRateHistogram = (filter: object, endpoint: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/My/HistogramQuery${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: TrendsActionTypes.getHitRateHistogram,
  };
};

export const getHitRateChainWideHistogram = (filter: object, endpoint: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/HitRate/Peers/HistogramQuery${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: TrendsActionTypes.getHitRateChainWideHistogram,
  };
};

export const getResponseRateHistogram = (filter: object, endpoint: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate/My/HistogramQuery${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: TrendsActionTypes.getResponseRateHistogram,
  };
};

export const getResponseRateChainWideHistogram = (filter: object, endpoint: string) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/ResponseRate/Peers/HistogramQuery${endpoint}`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: TrendsActionTypes.getResponseRateChainWideHistogram,
  };
};

export const geSalesIncreaseChainWideDetails = (filter: object) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/All/RevenueHistogramQuery`,
    meta: {
      method: HttpMethod.POST,
      body: {
        ...filter,
      },
    },
    baseAction: TrendsActionTypes.geSalesIncreaseChainWideHistogram,
  };
};

export const toggleDirection = (drillDownType: DrillDownTypes) => ({
  type: TrendsActionTypes.toggleDirection,
  payload: { drillDownType },
});

export const toggleExpended = (drillDownType: DrillDownTypes) => ({
  type: TrendsActionTypes.toggleExpended,
  payload: { drillDownType },
});

export const removeDrillDownFilter = (filter: IFilterItem) => ({
  type: TrendsActionTypes.removeDrillDownFilter,
  payload: { filter },
});

export const setSelectedMonth = (selectedMonth: number | null) => ({
  type: TrendsActionTypes.setSelectedMonth,
  payload: { selectedMonth },
});

export const sliceHierarchyPath = (hierarchyItemIndex: number, drillDownType: DrillDownTypes) => ({
  type: TrendsActionTypes.sliceHierarchyPath,
  payload: { hierarchyItemIndex, drillDownType },
});

export const onDrillDownSearch = (search: string, drillDownType: DrillDownTypes) => ({
  type: TrendsActionTypes.onDrillDownSearch,
  payload: { search, drillDownType },
});

export const setOtherDrillDownSelectedCategory = (value: string, label: string, id: string) => ({
  type: TrendsActionTypes.setOtherDrillDownSelectedCategory,
  payload: { value, label, id },
});

export const addFilter = (filter: IFilterItem, drillDownType: DrillDownTypes) => ({
  type: TrendsActionTypes.addFilter,
  payload: { filter, drillDownType },
});

export const setDateInterval = (
  type: SelectedDateIntervals,
  selectedDates: { from: number; to: number } | null = null,
) => ({
  type: TrendsActionTypes.setDateInterval,
  payload: { type, selectedDates },
});

export const setSelectedTab = (selectedTab: TrendsTabOptions) => ({
  type: TrendsActionTypes.setSelectedTab,
  payload: { selectedTab },
});

// Get drill down data -> call for all drill-downs
// Navigate to the needed action in the middleware
export const getDrillDown = (
  drillDownType: DrillDownTypes,
  storeHierarchyDepth: number = 0,
  productHierarchyDepth: number = 0,
  selectedCategory?: ISelectedCategory,
  pageOffset: number = 0,
) => ({
  type: TrendsActionTypes.getDrillDown,
  payload: {
    drillDownType,
    selectedCategory,
    pageOffset,
    productHierarchyDepth,
    storeHierarchyDepth,
  },
});

// Get overview details
// Navigate to the needed action in the middleware
export const getHistogram = (
  selectedTab: TrendsTabOptions,
  storeHierarchyDepth: number,
  productHierarchyDepth: number,
  selectedCategory?: ISelectedCategory,
) => ({
  type: TrendsActionTypes.getHistogram,
  payload: {
    selectedTab,
    selectedCategory,
    storeHierarchyDepth,
    productHierarchyDepth,
  },
});

const TrendsActions = {
  toggleDirection,
  sliceHierarchyPath,
  onDrillDownSearch,
  toggleExpended,
  getDrillDown,
  removeDrillDownFilter,
  addFilter,
  setSelectedTab,
  getHistogram,
  geSalesIncreaseChainWideDetails,
  setDateInterval,
  setSelectedMonth,
};

export default TrendsActions;
