import cn from 'clsx';
import { FC } from 'react';

import './emptyState.scss';

import { EmptyStateProps, EmptyStateThemes } from 'types';

export const EmptyState: FC<EmptyStateProps> = ({
  title,
  text,
  icon: Icon,
  clickableText,
  onClick,
  theme = EmptyStateThemes.Dark,
  dataTestId,
}) => {
  return (
    <div className="empty-state-container" data-testid={dataTestId}>
      <Icon />
      <span className={cn('empty-state-title', theme)}>{title}</span>
      {text && <span className={cn('empty-state-text', 'light')}>{text}</span>}
      {clickableText && (
        <span className="empty-state-text clickable" onClick={onClick}>
          {clickableText}
        </span>
      )}
    </div>
  );
};
