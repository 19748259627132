import { ReactNode } from 'react';

export enum RoutePath {
  // Opportunities = 'opportunities',
  MyOpportunities = 'myopportunities',
  OpenOpportunities = 'openopportunities',
  Findings = 'findings',
  Trends = 'trends',
  Summary = 'summary',
  Settings = 'settings',
  KnowledgeCenter = 'knowledgecenter',
  ContactUs = 'contactus',
  Logout = 'logout',
  Home = '',
  Env = 'env',
}

export interface ISecureRoutes {
  children: ReactNode;
  path: string;
  exact?: boolean;
}

export interface QueryParam {
  key: QueryParamTypes | string;
  value: any;
}

export enum QueryParamTypes {
  Date = 'date',
  From = 'from',
  To = 'to',
  OpId = 'opid', // opportunity id
  OID = 'oid', // other id
  OtherLevelName = 'oname',
  CategoryType = 'categorytype',
  CategoryValue = 'categoryvalue',
  CategoryLabel = 'categorylabel',
  Tab = 'tab',
  StoreLevel = 'sl',
  StoreLevelId = 'sid',
  StoreLevelName = 'sn',
  ProductLevel = 'pl',
  ProductLevelId = 'pid',
  ProductLevelName = 'pn',
  StoreLevelOrder = 'slo',
  ProductLevelOrder = 'plo',
  OtherLevelOrder = 'olo',
}

export interface IResolvedURLParams {
  path: string;
  query: string;
  isDeepLink: boolean;
  isLegacyDeepLink: boolean;
  tab?: string;
  id?: string;
}
