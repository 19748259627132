import animationDataDog from 'components/animation/lotties/emptyStateAnimations/dog.json';
import animationDataFireworks from 'components/animation/lotties/loaderAnimation.json';
import animationDataSpinner from 'components/animation/lotties/loaderAnimationCB4Theme.json';
import animationDataProgressBar from 'components/animation/lotties/loadingBar.json';
import animationDataBowling from 'components/animation/lotties/emptyStateAnimations/bowling.json';
import animationDataStars from 'components/animation/lotties/emptyStateAnimations/stars.json';
import animationDataPapers from 'components/animation/lotties/emptyStateAnimations/papers.json';
import animationDataEmoji from 'components/animation/lotties/emptyStateAnimations/emoji.json';

export enum ProgressAnimationProps {
  small,
  large,
}

export enum animationType {
  Spinner,
  Fireworks,
  ProgressBar,
}

export enum emptyStateAnimationType {
  Bowling = 'BOWLING',
  Dog = 'DOG',
  Stars = 'STARS',
  Papers = 'PAPERS',
  Emoji = 'EMOJI',
}

export interface IAnimationProps {
  animationType: animationType | emptyStateAnimationType;
  className?: string;
  isLoading?: boolean;
  animationStyle?: object;
  dataTestId?: string;
}

export const mapAnimationTypeToJson = {
  [animationType.Spinner]: animationDataSpinner,
  [animationType.Fireworks]: animationDataFireworks,
  [animationType.ProgressBar]: animationDataProgressBar,
};

type emptyStateAnimationMapping = {
  [key in emptyStateAnimationType]: {
    data: object | undefined;
    duration: number;
    loop?: boolean;
    style?: object;
    className: string;
  };
};

export const mapEmptyStateAnimation: emptyStateAnimationMapping = {
  [emptyStateAnimationType.Bowling]: {
    data: animationDataBowling,
    duration: 3,
    className: emptyStateAnimationType.Bowling,
  },
  [emptyStateAnimationType.Papers]: {
    data: animationDataPapers,
    duration: 5.3,
    className: emptyStateAnimationType.Papers,
  },
  [emptyStateAnimationType.Stars]: {
    data: animationDataStars,
    duration: 3.7,
    style: { position: 'absolute' },
    className: emptyStateAnimationType.Stars,
  },
  [emptyStateAnimationType.Dog]: {
    data: animationDataDog,
    duration: 3,
    loop: true,
    className: emptyStateAnimationType.Dog,
  },
  [emptyStateAnimationType.Emoji]: {
    data: animationDataEmoji,
    duration: 0,
    loop: true,
    style: { width: '60%', height: '60%' },
    className: emptyStateAnimationType.Emoji,
  },
};

export const emptyStateAnimationList = [
  emptyStateAnimationType.Bowling,
  emptyStateAnimationType.Stars,
  emptyStateAnimationType.Dog,
  emptyStateAnimationType.Papers,
  emptyStateAnimationType.Emoji,
];
