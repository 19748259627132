import { OpenOpportunitiesCardTypes } from './types';
import { StoreGrayIcon, StoreGreenIcon, StoreRedIcon, StoreYellowIcon } from 'components/Icons';

export const getCardDecoration = (type: OpenOpportunitiesCardTypes) => {
  let icon;
  let className = 'blue';

  switch (type) {
    case OpenOpportunitiesCardTypes.NO_OPPORTUNITIES:
      icon = <StoreGrayIcon />;
      className = 'gray';
      break;
    case OpenOpportunitiesCardTypes.COMPLETED:
      icon = <StoreGreenIcon />;
      className = 'green';

      break;
    case OpenOpportunitiesCardTypes.IN_PROGRESS:
      icon = <StoreYellowIcon />;
      className = 'yellow';

      break;
    case OpenOpportunitiesCardTypes.NO_RESPONSE:
      icon = <StoreRedIcon />;
      className = 'red';

      break;
    default:
      icon = null;
  }

  return { className, icon };
};
