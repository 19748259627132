import { ChangeEvent, FC, useEffect, useRef } from 'react';
import cn from 'clsx';

import { IField } from 'types';

export const InputField: FC<IField> = ({
  isError,
  value,
  errorMessage,
  type,
  className,
  onChange,
  placeholder,
  title,
  disabled,
  id,
  focus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const showErrorMessage = isError && errorMessage;

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={cn([className], { isError })}>
      {title && <span className="title">{title}</span>}
      <input
        id={`input-field-${id}`}
        type={type}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        className="input"
        onChange={onInputChange}
        ref={inputRef}
      />
      <div className="error-message-container">
        {showErrorMessage && <span className="error-message">{errorMessage}</span>}
      </div>
    </div>
  );
};
