const LocalStorageKeys = {
  tokens: 'cb4-tokens-storage',
  env: 'cb4-env',
};

export const getTokens = () => {
  const tokens = localStorage.getItem(LocalStorageKeys.tokens);

  const tokensObj = tokens && JSON.parse(tokens);

  const idToken = tokensObj && tokensObj.idToken ? tokensObj.idToken : '';

  const refreshToken = tokensObj && tokensObj.refreshToken ? tokensObj.refreshToken : '';

  const token = tokensObj && tokensObj.token ? tokensObj.token : '';

  return { idToken, refreshToken, token };
};

export const setTokens = (tokens) => {
  const tokensString = JSON.stringify(tokens);
  localStorage.setItem(LocalStorageKeys.tokens, tokensString);
};

export const setEnv = (env) => {
  localStorage.setItem(LocalStorageKeys.env, env);
};

export const getEnv = () => {
  return localStorage.getItem(LocalStorageKeys.env);
};

export const clearTokens = () => {
  localStorage.removeItem(LocalStorageKeys.tokens);
};

export const clearAll = () => {
  localStorage.clear();
};

const LocalStorage = {
  getTokens,
  setTokens,
  getEnv,
  setEnv,
  clearAll,
};

export default LocalStorage;
