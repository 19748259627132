const getPrefix = (action: string): string => `LoadingActionTypes.${action}`;

const AddLoading = getPrefix('AddLoading');

const RemoveLoading = getPrefix('RemoveLoading');

const LoadingActionTypes = {
  AddLoading,
  RemoveLoading,
};

export default LoadingActionTypes;
