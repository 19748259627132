import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import { FilterBuilder } from 'store/filters/filterBuilder';
import { buildRequestFilterBody } from 'store/filters/filterHelper';
import MyOpportunitiesActionTypes from 'store/myOpportunities/myOpportunitiesActionTypes';
import {
  deepLinkOpportunityId,
  IFilterCategoryLabels,
  IMyOpportunityFilters,
  IMyOpportunityFiltersRequest,
  ISetReasonRequestBody,
  ReasonState,
  ResponseTypes,
} from 'types';

export const addCategoryFilter = (
  filterBuilder: FilterBuilder,
  categories: IFilterCategoryLabels,
  categoryName: string,
) => {
  for (let i = 0; i < categories.length; i++) {
    const category = categoryName === 'storeName' ? 'storeName' : `${categoryName}.${i}`;

    if (categories[i]?.selectedLabels.length) {
      filterBuilder.and().openBracket();
      for (let j = 0; j < categories[i].selectedLabels.length; j++) {
        if (j !== 0) {
          filterBuilder.or();
        }
        filterBuilder.term(category, categories[i].selectedLabels[j]);
      }
      filterBuilder.closeBracket();
    }
  }
};

export const getMyOpportunities = (
  activeFilter: IMyOpportunityFilters,
  showResponseImpactOnSales: boolean,
  deepLinkOpportunityId: deepLinkOpportunityId = '',
) => {
  const { groupBy } = activeFilter;

  const filter: IMyOpportunityFiltersRequest = buildRequestFilterBody(
    activeFilter,
    deepLinkOpportunityId,
    showResponseImpactOnSales,
  );

  return {
    type: APIConstants.API_REQUEST,
    payload: { filter, groupBy, deepLinkOpportunityId },
    url: `/v1/report/TaskRawDataQuery`,
    meta: {
      method: HttpMethod.POST,
      body: { ...filter },
    },
    baseAction: MyOpportunitiesActionTypes.getMyOpportunities,
  };
};

export const getLiveInventory = (
  storeId: string,
  product: string,
  lang: string,
  itemNumber?: string,
) => {
  let params = `?store=${storeId}&product=${product}&lang=${lang}`;

  if (itemNumber) {
    params += `&itemnumber=${itemNumber}`;
  }

  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/inventory${params}`,
    meta: {
      method: HttpMethod.GET,
    },
    baseAction: MyOpportunitiesActionTypes.getLiveInventory,
  };
};

export const submitOpportunityReason = (body: ISetReasonRequestBody, taskId: string) => {
  const { reasonId, remark: description, updateDate, state } = body;

  const responseType =
    state === ReasonState.DONE ? ResponseTypes.ISSUE_FOUND : ResponseTypes.NO_ISSUE_FOUND;

  const selectedReason = { reasonId, description, updateDate, responseType };

  return {
    payload: { selectedReason },
    type: APIConstants.API_REQUEST,
    url: `/v2/task/${taskId}/response`,
    meta: {
      method: HttpMethod.POST,
      body,
    },
    baseAction: MyOpportunitiesActionTypes.submitOpportunityReason,
  };
};

export const getHitRateScore = (value: number) => {
  return {
    type: APIConstants.API_REQUEST,
    url: `/v1/report/score/hitRate/${value}`,
    meta: {
      method: HttpMethod.POST,
      body: {},
    },
    baseAction: MyOpportunitiesActionTypes.getHitRateScore,
  };
};

export const selectMyOpportunity = (opportunityId: string) => ({
  type: MyOpportunitiesActionTypes.selectMyOpportunity,
  payload: { opportunityId },
});

export const removeOpportunityDeepLink = (id: string) => ({
  type: MyOpportunitiesActionTypes.removeOpportunityDeepLink,
  payload: { id },
});

export const setActiveTab = (activeTab: string) => ({
  type: MyOpportunitiesActionTypes.setActiveTab,
  payload: { activeTab },
});

const MyOpportunitiesActions = {
  getMyOpportunities,
  selectMyOpportunity,
  setActiveTab,
  removeOpportunityDeepLink,
  getHitRateScore,
};

export default MyOpportunitiesActions;
