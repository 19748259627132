import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import {
  ActionTypes,
  IndicatorLineStatus,
  IPreviewHeader,
  mapActionTypeToIndicatorLineStatus,
} from 'types';
import './previewHeader.scss';
import IndicatorLine from 'components/indicator/IndicatorLine';
import { AddressIcon } from 'components/Icons';
import { DecoratedText } from 'components/text';
import featureFlagsService from '../../../../../services/permissions/featureFlagsService';

const PreviewHeader: FC<IPreviewHeader> = ({
  productSku,
  productName = '',
  storeName,
  productImage,
  onProductImageClick,
  isExpired,
  isNoIssueFound,
  action,
}) => {
  const [t] = useTranslation();

  const [shouldDisplayProductImage, setShouldDisplayProductImage] = useState(
    featureFlagsService.mayViewProductImages() && productImage,
  );

  const isNoIssueFoundOrExpired = isExpired || isNoIssueFound;

  const indicatorState = isNoIssueFoundOrExpired
    ? IndicatorLineStatus.None
    : mapActionTypeToIndicatorLineStatus[action];

  const isIaAction = action === ActionTypes.IA;

  const onImageLoadError = () => setShouldDisplayProductImage(false);

  return (
    <div
      className={cn('preview-header-container', {
        'no-issue-found': isNoIssueFoundOrExpired,
        ia: isIaAction,
      })}
    >
      <div className="preview-details-container">
        <div className="preview-header-top">
          <IndicatorLine status={indicatorState} />
          <div className="preview-header-top-text-container">
            <div className="preview-title-container">
              <div className="preview-header-title">{productName}</div>
              {isExpired && (
                <div className="expired-task">
                  <span>{t('MY_OPPORTUNITIES.OPPORTUNITY_EXPIRED')}</span>
                </div>
              )}
            </div>
            <div className="preview-header-id">
              {`${t('MY_OPPORTUNITIES.ITEM_ID')}:`}
              <div className="preview-header-id-text">
                {productSku && productSku[0] ? productSku[0].sku : ''}
              </div>
            </div>
          </div>
        </div>
        <DecoratedText
          containerClass="preview-header-footer"
          textClass="preview-header-footer-text"
          icon={<AddressIcon />}
          text={storeName || ''}
          isHidden={!storeName}
        />
      </div>
      <div className="preview-image-share-container">
        {shouldDisplayProductImage && productImage && (
          <img
            onError={onImageLoadError}
            id="product-image"
            alt="product"
            src={productImage}
            className="product-image"
            onClick={onProductImageClick}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewHeader;
