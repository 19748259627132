import { FC } from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

import { ISecureRoutes } from 'routes/types';
import { useKeycloak } from 'services/auth/keycloak/web';

export const SecureRoute: FC<ISecureRoutes> = ({ path, exact, children, ...rest }) => {
  const auth = useKeycloak();

  const isAuth = !!auth.keycloak?.authenticated;

  if (!isAuth) {
    return (
      <Route {...rest}>
        <Redirect to="/" />
      </Route>
    );
  }

  return (
    <Route path={path} exact={exact} {...rest}>
      {children}
    </Route>
  );
};
