import { FC, useCallback } from 'react';
import cn from 'clsx';

import './drillDownItem.scss';

import RateCounter from 'components/rateCounter/RateCounter';
import ProgressLine from 'components/progressLine/progressLine';
import { ThreeDots } from 'components/Icons';
import ActiveOptionMenu from './activeOptionMenu/ActiveOptionMenu';
import { IDrillDownItemProps } from 'types';
import { toLanguageNumberFormat, toShortCurrencyFormat } from 'utils';

const DrillDownItem: FC<IDrillDownItemProps> = ({
  value,
  name,
  count,
  totalCount,
  shouldShowOptions,
  onShowOpportunitiesClick,
  rate,
  onFilterClick,
  isActive,
  onActiveToggle,
  shouldDisplayShortCurrency,
  shouldDisplayTotalAndPercent,
  currency,
  isLastHierarchyLevel,
  progressTotalValue,
}) => {
  const displayedCount = toLanguageNumberFormat(count);

  const displayedTotalCount = toLanguageNumberFormat(totalCount);

  const onClick = () => {
    if (shouldShowOptions) {
      onActiveToggle();
    } else {
      onFilterClick({ name, value });
    }
  };

  const onFilter = () => {
    onFilterClick({ name, value });
    onActiveToggle();
  };

  const onShowOpportunities = () => {
    onShowOpportunitiesClick({ name, value });
    onActiveToggle();
  };

  const renderValue = useCallback(() => {
    let displayedValue = `${displayedCount}`;

    if (shouldDisplayTotalAndPercent) {
      displayedValue = `${Math.round(rate)}%`;
    } else if (shouldDisplayShortCurrency) {
      displayedValue = toShortCurrencyFormat(count, currency, 2);
    }

    return displayedValue;
  }, [shouldDisplayShortCurrency, value, count, rate]);

  return (
    <div className="drill-down-item">
      <div
        data-testid="drill-down-item"
        className={cn('drill-down-container', { 'is-options-bar-active': isActive })}
        onClick={onClick}
      >
        <div className="drill-down-count-container">
          <span className="value-container">{renderValue()}</span>
          {shouldDisplayTotalAndPercent && (
            <RateCounter rate={Number(displayedCount)} total={Number(displayedTotalCount)} />
          )}
        </div>
        <div className="name-line-container">
          <span className="name">{name}</span>
          <ProgressLine total={progressTotalValue} value={progressTotalValue ? count : rate} />
        </div>
        {isLastHierarchyLevel && <ThreeDots title="three-dots-icon" />}
      </div>
      <ActiveOptionMenu
        onClose={onActiveToggle}
        isActive={isActive}
        name={name}
        onFilter={onFilter}
        onShowOpportunities={onShowOpportunities}
      />
    </div>
  );
};

export default DrillDownItem;
