import { createRef, FC, useEffect, useState } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';

import { IAccordion, ReasonState } from 'types';
import Panel from './panel/Panel';
import './accordion.scss';
import { getDateAndTimeFormat } from 'utils';

const DEFAULT_PANEL_ID = -1;

export const Accordion: FC<IAccordion> = ({
  label,
  options,
  onSubmitClick,
  scrollIntoTabId,
  selectedPanelId,
  selectedPanelDescription,
  id,
  reasonCompleteDate,
  reasonReceivedDate,
}) => {
  const [expendedPanel, setExpendedPanel] = useState(DEFAULT_PANEL_ID);

  const accordionRef = createRef<HTMLDivElement>();

  const [t] = useTranslation();

  const onPanelClick = (index: number) =>
    setExpendedPanel(expendedPanel === index ? DEFAULT_PANEL_ID : index);

  useEffect(() => {
    if (scrollIntoTabId === id) {
      accordionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoTabId, id]);

  const onSubmit = (panelId: number, reasonState: ReasonState, reasonDescription: string) => {
    setExpendedPanel(DEFAULT_PANEL_ID);
    onSubmitClick(panelId, reasonState, reasonDescription);
  };

  const completedDate = getDateAndTimeFormat(reasonCompleteDate || 0);

  const receivedDate = getDateAndTimeFormat(reasonReceivedDate || 0);

  return (
    <div ref={accordionRef} className="reasons-list-accordion" id={`reasons-list-accordion-${id}`}>
      <span className="reasons-list-accordion-header">{label}</span>
      {options.map((option) => {
        const {
          id,
          label,
          reasonState,
          isReasonDescriptionMandatory,
          minimumCharsCount,
          key,
        } = option;

        const isSelected = selectedPanelId === option.id;

        const isPanelExpended =
          expendedPanel === option.id || (!!selectedPanelDescription && isSelected);

        const description = isSelected ? selectedPanelDescription || '' : '';

        return (
          <Panel
            reasonState={reasonState}
            label={label}
            isOptionSelected={isSelected}
            id={id}
            description={description}
            isReasonDescriptionMandatory={isReasonDescriptionMandatory}
            minimumCharsCount={minimumCharsCount}
            key={key}
            onPanelClick={onPanelClick}
            isExpended={isPanelExpended}
            onSubmitClick={onSubmit}
          />
        );
      })}
      <div className="complete-received-date">
        <span className="received-date">
          {t('MY_OPPORTUNITIES.RECEIVED', { date: receivedDate })}
        </span>
        <span className={cn('complete-date', { visible: !!reasonCompleteDate })}>
          {t('MY_OPPORTUNITIES.COMPLETED_DATE', { date: completedDate })}
        </span>
      </div>
    </div>
  );
};
