import { FC, useEffect, useState, memo } from 'react';
import cn from 'clsx';
import './previewCard.scss';

import { IPreviewCardProps } from './types';
import { getCardDecoration } from 'components/openOpportunities/helpers';
import { FullArrowIcon } from 'components/Icons';
import { OpenOpportunitiesDropDownOptionsTypes } from 'store/openOpportunities/types';
import { OpenOpportunitiesCardTypes } from 'types';
import RateCounter from 'components/rateCounter/RateCounter';
import { PERCENTAGE_SYMBOL } from 'constants/constantsVaribles';
import ProgressLine from 'components/progressLine/progressLine';

const PreviewCard: FC<IPreviewCardProps> = ({
  progressTotalValue,
  cardType,
  viewOption,
  card,
  isRtl,
  isLoading = false,
  onCardClick,
}) => {
  // const { className } = getCardDecoration(cardType);

  const [rate, setRate] = useState(0);

  const [className, setClassName] = useState('');

  const showRateStats = viewOption === OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE;

  const showOpenOpportunitiesRate = cardType === OpenOpportunitiesCardTypes.NO_OPPORTUNITIES;

  useEffect(() => {
    if (!isLoading) {
      setClassName(getCardDecoration(cardType).className);
    }
  }, [isLoading, cardType]);

  // calculate rate value when needed
  useEffect(() => {
    if (showRateStats) {
      if (showOpenOpportunitiesRate) {
        setRate(Number(card!.open));
      } else {
        setRate(Number(card!.total) - Number(card!.open));
      }
    }
  }, [showOpenOpportunitiesRate, showRateStats, card]);

  return (
    <div
      className={cn('preview-card-container', className, isRtl && 'rtl')}
      data-testid="open-opportunities-preview-card"
      role="button"
      tabIndex={0}
      onClick={onCardClick}
    >
      <div className="values-wrapper">
        <span className="value font-bold">
          {card.value}
          {!isLoading && showRateStats && PERCENTAGE_SYMBOL}
        </span>
        {!isLoading && showRateStats && <RateCounter rate={rate} total={card?.total || 0} />}
      </div>

      <div className="title-line-wrapper">
        <span className="title">{card.level}</span>
        <ProgressLine
          className="open-opportunities-progress-line"
          total={showRateStats ? undefined : progressTotalValue}
          value={card.value}
        />
      </div>

      <FullArrowIcon />
    </div>
  );
};

export default memo(PreviewCard);
