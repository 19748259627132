import { flow, set } from 'lodash/fp';

import {
  DrillDownTypes,
  IActionType,
  IFindingsDrillDownModal,
  ILeaderBoardRow,
  ISummaryModel,
  LEADER_BOARD_LIST_SIZE,
  OpenOpportunitiesRowApi,
  SelectedDateIntervals,
  SummaryComparisonTab,
} from 'types';
import SummaryActionTypes from './summaryActionTypes';
import {
  getDrillDownListByHitRate,
  getFindingsReasonsFilterGroup,
  getHitRate,
  getHitRateHistogram,
  getProductsDrillDownListByHitRate,
  getResponseRate,
  getLeaderBoard,
  getResponseRateHistogram,
  getStoresDrillDownListByHitRate,
} from 'store/api/apiParser';
import { isEmptyArray } from 'utils';
import {
  calculateComparison,
  getUpdatedActiveReasons,
  getLeaderBoardBottomData,
  getLeaderBoardTopData,
  getMyIndex,
} from './summaryHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const DropBoxInitialState: IFindingsDrillDownModal = {
  list: null,
  opportunities: null,
  totalCount: 0,
  highestValue: 100,
  isLoading: false,
};

export const initialState: ISummaryModel = {
  // high-level-kpi section
  totalOpportunitiesCaptured: 0,
  totalOpportunitiesCapturedChainWide: 0,
  salesIncrease: 0,
  salesIncreaseChainWide: 0,

  // weekly comparison section
  numberOfWeeksForResponseRateComparison: 0,
  responseRateValue: 0,
  responseRateCompareToValue: 0,
  numberOfWeeksForHitRateComparison: 0,
  hitRateValue: 0,
  hitRateCompareToValue: 0,
  numberOfOpenTasks: 0,
  openTasksWeekOf: 0,
  activeFilters: {
    // high-level-kpi section
    kpiDateFilter: SelectedDateIntervals.twelveMonthsId,

    // top-findings section
    topFindingsDateFilter: SelectedDateIntervals.twelveMonthsId,

    // hit-rate-leaders section
    leaderBoardDateFilter: SelectedDateIntervals.monthId,

    isLeaderBoardPeersSelected: false,

    activeReasonFilters: [],

    // how-do-i-compare-to-my-peers section
    activeComparisonTab: SummaryComparisonTab.RESPONSE_RATE,
  },
  [DrillDownTypes.Other]: DropBoxInitialState,
  issueFoundTotalCount: null,
  totalCount: 0,
  reasonFilterGroupsMap: null,
  reasonFilterToColorMap: {},
  reasonFilterGroups: null,
  hitRate: {
    total: 0,
    data: null,
    chainWideData: [],
  },
  responseRate: {
    total: 0,
    data: null,
    chainWideData: [],
  },
  leaderBoardTopData: [],
  leaderBoardBottomData: [],
  selectedStoreFilter: null,
};

const summaryReducer = (state: ISummaryModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    case SummaryActionTypes.getMyIssueFoundByReasons.SUCCESS: {
      const { queryStats = { issueFoundTotalCount: 0 } } = payload;

      const { issueFoundTotalCount } = queryStats;

      return flow(set('totalOpportunitiesCaptured', issueFoundTotalCount))(state);
    }

    case SummaryActionTypes.getAllIssueFoundByReasons.SUCCESS: {
      const { queryStats = { issueFoundTotalCount: 0 } } = payload;

      const { issueFoundTotalCount } = queryStats;

      return flow(set('totalOpportunitiesCapturedChainWide', issueFoundTotalCount))(state);
    }

    case SummaryActionTypes.getMySalesIncrease.SUCCESS: {
      const { queryStats = { totalIncrease: 0 } } = payload;

      const { totalIncrease } = queryStats;

      return flow(set('salesIncrease', totalIncrease))(state);
    }

    case SummaryActionTypes.getAllSalesIncrease.SUCCESS: {
      const { queryStats = { totalIncrease: 0 } } = payload;

      const { totalIncrease } = queryStats;

      return flow(set('salesIncreaseChainWide', totalIncrease))(state);
    }

    case SummaryActionTypes.getResponseRateComparison.SUCCESS: {
      const { rows } = payload;

      const numberOfWeeksForResponseRateComparison = rows.length;

      const rateComparison = getResponseRate(rows);

      const {
        value: responseRateValue,
        compareToValue: responseRateCompareToValue,
      } = calculateComparison(rateComparison);

      return flow([
        set(`responseRateValue`, responseRateValue),
        set(`responseRateCompareToValue`, responseRateCompareToValue),
        set(`numberOfWeeksForResponseRateComparison`, numberOfWeeksForResponseRateComparison),
      ])(state);
    }

    case SummaryActionTypes.getHitRateComparison.SUCCESS: {
      const { rows } = payload;

      const numberOfWeeksForHitRateComparison = rows.length;

      const rateComparison = getHitRate(rows);

      const { value: hitRateValue, compareToValue: hitRateCompareToValue } = calculateComparison(
        rateComparison,
      );

      return flow([
        set(`hitRateValue`, hitRateValue),
        set(`hitRateCompareToValue`, hitRateCompareToValue),
        set(`numberOfWeeksForHitRateComparison`, numberOfWeeksForHitRateComparison),
      ])(state);
    }

    case SummaryActionTypes.getOpenOpportunities.SUCCESS: {
      const { rows = [] } = payload;

      const openedTasks = rows.filter(
        (item: OpenOpportunitiesRowApi) => item.state.toUpperCase() === 'OPENED',
      );

      const numberOfOpenTasks = openedTasks.reduce(
        (sum: number, item: OpenOpportunitiesRowApi) => sum + item.count,
        0,
      );

      const weeksOf: Array<number> = openedTasks.map((item: OpenOpportunitiesRowApi) =>
        Number(item.week),
      );

      let openTasksWeekOf = 0;

      if (!isEmptyArray(weeksOf)) {
        openTasksWeekOf = Math.min(...weeksOf);
      }

      return flow([
        set(`numberOfOpenTasks`, numberOfOpenTasks),
        set(`openTasksWeekOf`, openTasksWeekOf),
      ])(state);
    }

    case SummaryActionTypes.setStoreFilter: {
      const { store = null } = payload;

      return set('selectedStoreFilter', store, state);
    }

    case SummaryActionTypes.setKpiDateFilter: {
      const { selectedKpiDateFilter } = payload;

      AnalyticSrv.sendDateChangedEvent(
        selectedKpiDateFilter,
        AnalyticsEventCategory.SUMMARY_KPIS_TIME,
      );

      return flow(set(`activeFilters.kpiDateFilter`, selectedKpiDateFilter))(state);
    }

    case SummaryActionTypes.setTopFindingsDateFilter: {
      const { selectedTopFindingsDateFilter } = payload;

      AnalyticSrv.sendDateChangedEvent(
        selectedTopFindingsDateFilter,
        AnalyticsEventCategory.SUMMARY_TOP_FINDINGS_TIME,
      );

      return flow(set(`activeFilters.topFindingsDateFilter`, selectedTopFindingsDateFilter))(state);
    }

    case SummaryActionTypes.setLeaderBoardDateFilter: {
      const { selectedLeaderBoardDateFilter } = payload;

      AnalyticSrv.sendDateChangedEvent(
        selectedLeaderBoardDateFilter,
        AnalyticsEventCategory.SUMMARY_LEADER_BOARD_TIME,
      );

      return flow(set(`activeFilters.leaderBoardDateFilter`, selectedLeaderBoardDateFilter))(state);
    }

    case SummaryActionTypes.setLeaderBoardPeersSelected: {
      const { isPeersSelected } = payload;

      AnalyticSrv.sendChangeEvent(AnalyticsEventCategory.SUMMARY_LEADER_BOARD_TAB, {
        tab_name: isPeersSelected ? 'My Stores' : 'Chain-Wide',
      });

      return flow(set(`activeFilters.isLeaderBoardPeersSelected`, isPeersSelected))(state);
    }

    case SummaryActionTypes.setActiveComparisonTab: {
      const { selectedRateComparisonTab } = payload;

      AnalyticSrv.sendChangeEvent(AnalyticsEventCategory.SUMMARY_TRENDS_TAB, {
        tab_name:
          selectedRateComparisonTab === SummaryComparisonTab.HIT_RATE ? 'hitRate' : 'responseRate',
      });

      return flow(set(`activeFilters.activeComparisonTab`, selectedRateComparisonTab))(state);
    }

    case SummaryActionTypes.getFindingsReasons.SUCCESS: {
      const { queryStats = { issueFoundTotalCount: 0 }, rows, totalCount } = payload;

      const { reasons } = requestPayload;

      const { issueFoundTotalCount } = queryStats;

      const { reasonFilterToColorMap: prevReasonFilterToColorMap } = state;

      const {
        reasonFilterGroupsMap,
        reasonFilterGroups,
        reasonFilterToColorMap,
      } = getFindingsReasonsFilterGroup(rows, reasons, prevReasonFilterToColorMap);

      const {
        activeReasonFilters,
        reasonFilterGroupsMap: updatedReasonFilterGroupsMap,
      } = getUpdatedActiveReasons(reasonFilterGroupsMap, reasonFilterGroups[0], true);

      return flow([
        set(`activeFilters.activeReasonFilters`, activeReasonFilters),
        set(`reasonFilterGroupsMap`, updatedReasonFilterGroupsMap),
        set('reasonFilterGroups', reasonFilterGroups),
        set('reasonFilterToColorMap', reasonFilterToColorMap),
        set('issueFoundTotalCount', issueFoundTotalCount),
        set('totalCount', totalCount),
      ])(state);
    }

    case SummaryActionTypes.toggleGroupSelection: {
      const { isActive, groupId } = payload;

      const { reasonFilterGroupsMap } = state;

      if (!reasonFilterGroupsMap) {
        return state;
      }

      const {
        activeReasonFilters,
        reasonFilterGroupsMap: updatedReasonFilterGroupsMap,
      } = getUpdatedActiveReasons(reasonFilterGroupsMap, groupId, isActive);

      return flow([
        set(`activeFilters.activeReasonFilters`, activeReasonFilters),
        set(`reasonFilterGroupsMap`, updatedReasonFilterGroupsMap),
      ])(state);
    }

    case SummaryActionTypes.getDrillDown: {
      const { drillDownType } = payload;

      return flow([set(`${drillDownType}.isLoading`, true)])(state);
    }

    case SummaryActionTypes.getHierarchyDrillDownData.SUCCESS: {
      const { rows, totalCount = 0 } = payload;

      const list = getDrillDownListByHitRate(rows);

      return flow([
        set(`other.list`, list),
        set(`other.totalCount`, totalCount),
        set(`other.isLoading`, false),
      ])(state);
    }

    case SummaryActionTypes.getProducts.SUCCESS: {
      const { rows, totalCount = 0 } = payload;

      const list = getProductsDrillDownListByHitRate(rows);

      return flow([
        set(`other.list`, list),
        set(`other.totalCount`, totalCount),
        set(`other.isLoading`, false),
      ])(state);
    }

    case SummaryActionTypes.getStores.SUCCESS: {
      const { rows, totalCount = 0 } = payload;

      const list = getStoresDrillDownListByHitRate(rows);

      return flow([
        set(`other.list`, list),
        set(`other.totalCount`, totalCount),
        set(`other.isLoading`, false),
      ])(state);
    }

    case SummaryActionTypes.getLeaderBoardData.SUCCESS: {
      const { rows = [] } = payload;

      let leaderBoardTopData: Array<ILeaderBoardRow> = [];

      let leaderBoardBottomData: Array<ILeaderBoardRow> = [];

      if (!isEmptyArray(rows)) {
        const { userHighestHierarchyLowestLevel } = requestPayload;

        const leaderBoardRows = getLeaderBoard(rows);

        const myIndex = getMyIndex(leaderBoardRows, userHighestHierarchyLowestLevel!);

        const currentLevel = userHighestHierarchyLowestLevel!.level;

        leaderBoardTopData = getLeaderBoardTopData(leaderBoardRows, myIndex);

        if (leaderBoardTopData.length === LEADER_BOARD_LIST_SIZE.REGULAR) {
          leaderBoardBottomData = getLeaderBoardBottomData(leaderBoardRows, myIndex, currentLevel);
        }
      } else {
        leaderBoardTopData = [];
        leaderBoardBottomData = [];
      }

      return flow([
        set(`leaderBoardTopData`, leaderBoardTopData),
        set(`leaderBoardBottomData`, leaderBoardBottomData),
      ])(state);
    }

    case SummaryActionTypes.getHitRateHistogram.SUCCESS: {
      const { rows, queryStats: { totalHitRate } = { totalHitRate: 0 } } = payload;

      const list = getHitRateHistogram(rows);

      return flow([set(`hitRate.data`, list), set(`hitRate.total`, Math.round(totalHitRate))])(
        state,
      );
    }

    case SummaryActionTypes.getHitRateChainWideHistogram.SUCCESS: {
      const { rows } = payload;

      const list = getHitRateHistogram(rows);

      return flow([set(`hitRate.chainWideData`, list)])(state);
    }

    case SummaryActionTypes.getResponseRateHistogram.SUCCESS: {
      const { rows, queryStats: { totalResponseRate } = { totalResponseRate: 0 } } = payload;

      const list = getResponseRateHistogram(rows);

      return flow([
        set(`responseRate.data`, list),
        set(`responseRate.total`, Math.round(totalResponseRate)),
      ])(state);
    }

    case SummaryActionTypes.getResponseRateChainWideHistogram.SUCCESS: {
      const { rows } = payload;

      const list = getResponseRateHistogram(rows);

      return flow([set(`responseRate.chainWideData`, list)])(state);
    }

    default:
      return state;
  }
};

export default summaryReducer;
