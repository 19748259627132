import { getReasons } from 'store/reasons/reasonsActions';
import { getSettings } from 'store/settings/settingsActions';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';
import { IActionType } from 'types';
import SettingsActionTypes from './settingsActionTypes';
import i18n from 'i18n/i18n';
import { setDateLocale } from 'utils';

const settingsMiddleware = ({ dispatch, getState }: any) => (next: any) => (
  action: IActionType,
) => {
  const { type, payload } = action;

  switch (type) {
    case UserProfileActionTypes.getUserScope.SUCCESS: {
      const projectId = payload.projectIds ? payload.projectIds[0] : null;
      dispatch(getSettings(projectId));
      break;
    }
    case SettingsActionTypes.getSettings.PENDING: {
      const { language, isRtl } = getState().settings;
      i18n.changeLanguage(language);
      setDateLocale(language);

      if (isRtl) {
        document.body.classList.add('rtl');
      }
      break;
    }
    case SettingsActionTypes.updateLanguage: {
      const { chosenLanguage } = payload;
      i18n.changeLanguage(chosenLanguage);
      setDateLocale(chosenLanguage);

      const isRtl = i18n.dir(chosenLanguage) === 'rtl';

      if (isRtl) {
        document.body.classList.add('rtl');
      } else {
        document.body.classList.remove('rtl');
      }
      break;
    }
    case SettingsActionTypes.getSettings.SUCCESS: {
      const { flavour } = payload;
      dispatch(getReasons(flavour));

      break;
    }
    default:
      break;
  }

  next(action);
};

export default settingsMiddleware;
