import { useEffect, useRef, useState } from 'react';

import { MEDIA_COLLAPSE_WIDTH, MEDIA_HIDE_WIDTH } from 'constants/constantsVaribles';

export const useMediaQuery = (collapseCallback: Function, hideCallback: Function) => {
  const [width, setWidth] = useState(0);

  const firstRenderCollapseRef = useRef(true);

  const firstRenderHideRef = useRef(true);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  // handle screen size changes with the right callback
  useEffect(() => {
    if (window.innerWidth <= MEDIA_HIDE_WIDTH && firstRenderHideRef.current) {
      hideCallback();
      firstRenderHideRef.current = false;
    } else if (window.innerWidth <= MEDIA_COLLAPSE_WIDTH && firstRenderCollapseRef.current) {
      collapseCallback();
      firstRenderCollapseRef.current = false;
    }
  }, [width]);

  // subscribe to event listeners
  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('load', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('load', handleWindowResize);
    };
  }, []);
};
