import {
  ICard,
  ISale,
  ResponseTypes,
  LifecycleOptions,
  IMyOpportunityFilters,
  IAlert,
  CurrencySymbolOptions,
  IndicatorLineStatus,
  IProductSkuAndSize,
  IAnimationData,
} from 'types';

export type deepLinkOpportunityId = string;

export interface IMyOpportunitiesProps {
  isLoading: boolean;
  hasMore: boolean;
  nextPageOffset: number;
  totalCount: number;
  totalCountOpenTab: number;
  getMyOpportunities: (
    filters: IMyOpportunityFilters,
    showResponseImpactOnSales: boolean,
    deepLinkOpportunityId: deepLinkOpportunityId,
  ) => void;
  selectMyOpportunity: (id: string) => void;
  selectedMyOpportunityId: string | null | undefined;
  myOpportunities: IMyOpportunities | null;
  activeFilters: IMyOpportunityFilters;
  activeTab: number;
  setActiveTab: Function;
  clearFilters(): void;
  opportunitiesGroupContent: Array<string>;
  opportunitiesGroupCount: Array<number>;
  toggleControlMenu(): void;
  updateLoadMoreOffset(nextPageOffset: number): void;
  isFilterOn: boolean;
  filterList: Array<string>;
  currency: CurrencySymbolOptions;
  taskExpiryDays: number;
  isRtl?: boolean;
  showProductImages: boolean;
  showPrintMenu(options: IAlert): void;
  setMyOpportunitiesSearch(text: string): void;
  deepLinkOpportunityId: deepLinkOpportunityId;
  removeOpportunityDeepLink: (id: any) => void;
  showTargetSales: boolean;
  showTargetUnits: boolean;
  showResponseImpactOnSales: boolean;
  reasonIdToTextMapper: object;
  haveOpportunitiesBeenDeployed: boolean;
  getHitRateScore(value: number): void;
  animatedEmptyState: boolean;
}

export interface IMyOpportunity extends ICard {
  action: ActionTypes;
  flavour: FlavourTypes;
  lifecycle: LifecycleOptions;
  lastSalesValues: ISalesValues;
  lastSimilarSalesValues: ISalesValues;
  lastUnitsValues: ISalesValues | null;
  lastSimilarUnitsValues: ISalesValues | null;
  lastSalesValuesTotal: number;
  lastSimilarSalesValuesTotal: number;
  lastUnitsValuesTotal: number;
  lastSimilarUnitsValuesTotal: number;
  deployDate: number;
  originalDeployDate: number;
  lowResolutionProductImage: string | null;
  highResolutionProductImage: string | null;
  selectedReason?: ISelectedReason;
  state?: string;
  inventoryLatestUpdateDate?: number;
  planogramAttributes: { [key: string]: string };
  inventoryLevel?: number;
  potentialRevenue: number;
  potentialUnitsSales: number;
  storeId: string;
  storeIdentifier: string;
  extendedSkus: Array<IProductSkuAndSize>;
}

export interface ISaleValue extends ISale {}

export type ISalesValues = Array<ISaleValue>;

export type IMyOpportunities = Array<IMyOpportunity>;

export interface ISelectedReason {
  reasonId?: number;
  description?: string;
  updateDate: number;
  responseType: ResponseTypes;
}

export interface IMyOpportunitiesEmptyState {
  search: string;
  isFilterOn: boolean;
  isOpenTabActive: boolean;
  taskExpiryDays: number;
  filterList: string[];
  animatedEmptyState: boolean;
  motivationAnimationData: IAnimationData | null;
  haveOpportunitiesBeenDeployed: boolean;
  isEmptyStateAnimationLoading?: boolean;
  clearFilters(): void;
}

export enum FlavourTypes {
  ASSORTMENT = 'ASSORTMENT',
  FASHION = 'FASHION',
}

export enum ActionTypes {
  AV = 'AV',
  IA = 'IA',
}

export const ActionTypeTranslationId = {
  AV: 'AVAILABILITY_VISIBILITY',
  IA: 'INTRODUCE_TO_ASSORTMENT',
};

export enum FreeTextOptions {
  MANDATORY = 'MANDATORY',
}

export const mapActionTypeToIndicatorLineStatus = {
  [ActionTypes.IA]: IndicatorLineStatus.BLUE,
  [ActionTypes.AV]: IndicatorLineStatus.YELLOW,
};
