const getPrefix = (action: string): string => `SideMenuActionTypes.${action}`;

const SelectActionId = getPrefix('SelectActionId');

const ToggleMenuCollapseExpand = getPrefix('ToggleMenuCollapseExpand');

const ToggleMenuHideExpand = getPrefix('ToggleMenuHideExpand');

const CollapseSideMenu = getPrefix('CollapseSideMenu');

const HideSideMenu = getPrefix('HideSideMenu');

const SideMenuActionTypes = {
  SelectActionId,
  ToggleMenuCollapseExpand,
  ToggleMenuHideExpand,
  CollapseSideMenu,
  HideSideMenu,
};

export default SideMenuActionTypes;
