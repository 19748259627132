import { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DrillDownTypes, IFilterItem, IFindingsDrillDownWrapper, IRootState } from 'types';
import {
  addFilter,
  onDrillDownSearch,
  onShowOpportunities,
  removeDrillDownFilter,
  sliceHierarchyPath,
  toggleDirection,
  toggleExpended,
  toggleFreeTextOnly,
} from 'store/findings/findingsActions';
import DrillDown from 'components/drilldown/DrillDown';
import findingsSelector from 'store/findings/findingsSelector';

const FindingsDrillDownWrapper: FC<IFindingsDrillDownWrapper> = ({
  id,
  drillDown,
  expendedDrillDown,
  onExpendedToggle,
  onFreeTextToggle,
  onToggleDirection,
  onFilterClick,
  onHierarchyPathClick,
  onRemoveFilter,
  onTitleClick,
  onShowOpportunitiesClick,
  onSearchChange,
  type,
  onLoadMore,
  onOpportunityClick,
  currency,
  isFiltersApplied,
  isRtl,
}) => {
  const [t] = useTranslation();

  const onFilter = (filter: IFilterItem, type: DrillDownTypes) => {
    const updatedFilter = { ...filter };

    if (type === DrillDownTypes.Other && drillDown) {
      const { selectedCategory } = drillDown.activeFilters;
      // set values for other drill down
      updatedFilter.hierarchyValue = selectedCategory?.value;
      updatedFilter.categoryType = selectedCategory?.type;
    }

    onFilterClick(updatedFilter, type);
  };

  if (!drillDown) {
    return null;
  }

  const {
    activeFilters: {
      order: { direction },
      isOpportunitySelected,
    },
    mutualFilters: { hierarchyIndex, isFreeTextOnly, search },
    hierarchyPath,
    hierarchyName,
    isFiltersVisible,
    hierarchyDepth,
    highestValue,
  } = drillDown;

  // check if it's the last level
  const isLeaf = hierarchyIndex > hierarchyDepth;

  const isTitleClickable = type === DrillDownTypes.Other;

  const onClick = isTitleClickable ? onTitleClick : undefined;

  const isExpended = expendedDrillDown === type;

  const isHidden = !isExpended && !!expendedDrillDown;

  const { isLoading } = drillDown;

  return (
    <DrillDown
      id={id}
      isFiltersApplied={isFiltersApplied}
      searchText={search}
      highestValue={highestValue}
      headerSubTitle={t('DRILL_DOWNS.BY_ISSUES')}
      isNumericData
      shouldDisplayTotalAndPercent={false}
      shouldDisplayShortCurrency={false}
      currency={currency}
      isHidden={isHidden}
      isFreeTextOnly={isFreeTextOnly}
      onFreeTextToggle={() => onFreeTextToggle(type)}
      isOpportunitySelected={isOpportunitySelected}
      opportunities={drillDown.opportunities}
      onTitleClick={onClick}
      isOptionBarEnable
      hierarchyName={hierarchyName}
      isLoading={isLoading}
      isFiltersVisible={isFiltersVisible}
      onSearchChange={(text) => onSearchChange(text, type)}
      onHierarchyPathClick={(index) => onHierarchyPathClick(index, type)}
      isTitleClickable={isTitleClickable}
      hierarchyPath={hierarchyPath}
      onLoadMore={() => onLoadMore(type)}
      onRemoveFilter={(filter) => onRemoveFilter(filter)}
      list={drillDown.list}
      totalCount={drillDown.totalCount}
      onExpendedToggle={() => onExpendedToggle(type)}
      onFilterClick={(filter) => onFilter(filter, type)}
      onShowOpportunitiesClick={() => onShowOpportunitiesClick(type)}
      onToggleDirection={() => onToggleDirection(type)}
      order={direction}
      onOpportunityClick={onOpportunityClick}
      filters={drillDown.filters}
      isLastHierarchyLevel={isLeaf}
      isExpended={isExpended}
      isRtl={isRtl}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { expendedDrillDown } = state.findings.filters;

  const isFiltersApplied = findingsSelector.checkIsFiltersApplied(state);

  const { currency } = state.settings.general;

  const { isRtl } = state.settings;

  return {
    expendedDrillDown,
    currency,
    isFiltersApplied,
    isRtl,
  };
};

const mapDispatchToProps = {
  onToggleDirection: toggleDirection,
  onRemoveFilter: removeDrillDownFilter,
  onHierarchyPathClick: sliceHierarchyPath,
  onSearchChange: onDrillDownSearch,
  onExpendedToggle: toggleExpended,
  onFreeTextToggle: toggleFreeTextOnly,
  onFilterClick: addFilter,
  onShowOpportunitiesClick: onShowOpportunities,
};

export default connect(mapStateToProps, mapDispatchToProps)(FindingsDrillDownWrapper);
