import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';

import { ContactUs } from 'components/contactUs';
import { withContent } from 'components/layout/WithContent';
import Loading from 'components/pages';
import developerOptions from 'components/pages/developer/developerOptions';
import Findings from 'components/pages/Findings/Findings';
import KnowledgeCenterConnected from 'components/pages/knowledgeCenter';
import { Logout } from 'components/pages/logout/logout';
import MyOpportunities from 'components/pages/myOpportunities/myOpportunities';
import OpenOpportunities from 'components/pages/openOpportunities/OpenOpportunities';
import { NoMatch } from 'components/pages/noMatch';
import SettingsConnected from 'components/pages/settings';
import summary from 'components/pages/summary/Summary';
import Trends from 'components/pages/trends/Trends';
import { SecureRoute } from 'routes/SecureRoute';
import { RoutePath } from 'routes/types';
import { useKeycloak } from 'services/auth/keycloak/web';
import permissionsService from 'services/permissions/permissionsSrv';
import LoadingSelector from 'store/loading/LoadingSelector';
import { IRootState, UserRole } from 'store/types';

const Routes = () => {
  const location = useLocation();

  const { initialized, isUserInfoLoaded } = useKeycloak();

  const userProfile = useSelector((state: IRootState) => state.userProfile);

  const isUserInfoReady = useSelector(LoadingSelector.isGetUserScopeLoading);

  const isGetSettingsLoading = useSelector(LoadingSelector.isGetSettingsLoading);

  const isGetReasonLoading = useSelector(LoadingSelector.isGetReasonLoading);

  const paths = location.pathname.split('/');

  let [, actionName] = paths;

  actionName = actionName.toLowerCase();

  const validateRoutePermissions = useCallback(() => {
    switch (actionName) {
      case RoutePath.Env: {
        return true;
      }
      case RoutePath.MyOpportunities: {
        return permissionsService.mayViewOpportunities();
      }
      case RoutePath.Summary:
      case RoutePath.Trends:
      case RoutePath.Findings:
      case RoutePath.OpenOpportunities: {
        return permissionsService.mayViewDashboards();
      }
      default: {
        break;
      }
    }

    return true;
  }, [userProfile, location]);

  // This is the gateway to the App, if keycloak is not ready/not required/ or init data (ex user data) not ready
  // the app loading will be showed.
  // when opening developer option env, keycloak doesn't need to be initialized
  if (
    actionName !== RoutePath.Env &&
    actionName !== RoutePath.Logout &&
    actionName !== RoutePath.ContactUs &&
    (userProfile.role === UserRole.NONE ||
      !initialized ||
      !isUserInfoLoaded ||
      isUserInfoReady ||
      isGetSettingsLoading ||
      isGetReasonLoading)
  ) {
    return <Loading />;
  }

  // After login has finish decide where to go navigate following the granted permissions
  // if no permissions to enter the requested route, the decision where to route will be done while loading
  const isRouteAllowed = validateRoutePermissions();

  if (!isRouteAllowed) {
    return <Loading withRouteFallback />;
  }

  return (
    <Switch>
      <Route path="/env" exact component={developerOptions} />
      <Route path="/logout" exact component={Logout} />
      <SecureRoute path="/" exact>
        <Loading withRouteFallback />
      </SecureRoute>
      <SecureRoute path="/summary" exact>
        {withContent(summary)}
      </SecureRoute>
      <SecureRoute path="/myopportunities/:oid?" exact>
        {withContent(MyOpportunities)}
      </SecureRoute>
      <SecureRoute path="/openopportunities" exact>
        {withContent(OpenOpportunities)}
      </SecureRoute>
      <SecureRoute path="/findings" exact>
        {withContent(Findings)}
      </SecureRoute>
      <SecureRoute path="/trends" exact>
        {withContent(Trends)}
      </SecureRoute>
      <SecureRoute path="/contactus" exact>
        {withContent(ContactUs)}
      </SecureRoute>
      <SecureRoute path="/settings" exact>
        {withContent(SettingsConnected)}
      </SecureRoute>
      <SecureRoute path="/knowledgecenter" exact>
        {withContent(KnowledgeCenterConnected)}
      </SecureRoute>
      <SecureRoute path="*">{NoMatch}</SecureRoute>
    </Switch>
  );
};

export default Routes;
