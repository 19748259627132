import './SideMenu.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISideMenuButtonProps, IMenuAction } from 'components/layout/types';
import { SideMenuButton } from 'components/buttons/SideMenuButton';
import { OpportunitiesIcon } from 'components/Icons';
import { RoutePath } from 'routes/types';
import permissionsService from 'services/permissions/permissionsSrv';

const SideMenuOpportunities: FC<ISideMenuButtonProps> = ({
  isMenuCollapsed,
  selectedActionMenuId,
  selectMenuAction,
}) => {
  const [t] = useTranslation();

  const mayViewMyOpportunities: boolean = permissionsService.mayViewOpportunities();

  const menuActions: Array<IMenuAction> = [
    {
      label: t('PAGES.MY_OPPORTUNITIES'),
      id: RoutePath.MyOpportunities,
      icon: OpportunitiesIcon,
      canUserView: mayViewMyOpportunities,
    },
  ];

  if (!mayViewMyOpportunities) {
    return null;
  }

  return (
    <div className="side-menu-opportunities">
      {menuActions.map((menuAction) => {
        return (
          <SideMenuButton
            id={menuAction.id}
            key={menuAction.id}
            icon={menuAction.icon}
            isMenuCollapsed={isMenuCollapsed}
            isActive={menuAction.id === selectedActionMenuId}
            onClick={() => selectMenuAction(menuAction.id)}
            label={menuAction.label}
            hide={!menuAction.canUserView}
          />
        );
      })}
      <div className="separator" />
    </div>
  );
};

export default SideMenuOpportunities;
