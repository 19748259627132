import { AlertButtonsIds, AlertIds } from 'components/common/alert/types';
import { IActionType, ISelectedPrintingOption, PrintOption } from 'types';
import AlertActionTypes from 'store/alert/alertActionTypes';
import { show } from 'store/print/printActions';

const alertMiddleware = ({ dispatch }: any) => (next: any) => (action: IActionType) => {
  const { type, payload } = action;

  switch (type) {
    case AlertActionTypes.ButtonClick: {
      const { id, buttonId, checklist } = payload;

      if (buttonId === AlertButtonsIds.Continue) {
        if (id === AlertIds.OpenPrintMenu) {
          const id = PrintOption.myOpportunities;

          const selectedPrintingOption: ISelectedPrintingOption = {
            PrintImage: false,
            PrintReasons: false,
          };

          checklist.forEach((item: { id: string; isChecked: boolean }) => {
            selectedPrintingOption[item.id] = item.isChecked;
          });

          dispatch(
            show({
              id,
              selectedPrintingOption,
            }),
          );
        }
      }

      break;
    }
    default:
      break;
  }

  next(action);
};

export default alertMiddleware;
