import { FC, useCallback, useEffect, useState } from 'react';

import { IDrillDownList, IFilterItem } from 'types';
import DrillDownItem from './drillDownItem/DrillDownItem';
import './drillDownList.scss';
import { Cards } from 'components/cards';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const CARD_HEIGHT = 65;

const DrillDownList: FC<IDrillDownList> = ({
  onShowOpportunitiesClick,
  onFilterClick,
  list,
  onLoadMore,
  totalCount,
  isLastHierarchyLevel,
  hierarchyName,
  isLoading,
  emptyState,
  isOptionBarEnable,
  shouldDisplayShortCurrency,
  shouldDisplayTotalAndPercent,
  currency,
  isNumericData,
  highestValue,
}) => {
  const [activeDrillDownRowIndex, setActiveDrillDownRowIndex] = useState(-1);

  // Any list change, if the menu open - close it
  useEffect(() => setActiveDrillDownRowIndex(-1), [list]);

  const onActiveDrillDownRowChange = useCallback(
    (index: number) => {
      if (activeDrillDownRowIndex === index) {
        setActiveDrillDownRowIndex(-1);
      } else {
        setActiveDrillDownRowIndex(index);
      }
    },
    [activeDrillDownRowIndex],
  );

  const onFilter = useCallback(
    ({ name, value, drillDownType }: IFilterItem) => {
      const updatedFilter: IFilterItem = { name, value, hierarchyName, drillDownType };
      onFilterClick(updatedFilter);
      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.DRILL_DOWN_FILTER, {
        filter_name: name,
      });
    },
    [onFilterClick],
  );

  const onShowOpportunity = useCallback(
    (filter: IFilterItem) => {
      onFilter(filter);
      onShowOpportunitiesClick();
      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.DRILL_DOWN_OPPORTUNITIES);
    },
    [onShowOpportunitiesClick],
  );

  const itemContent = useCallback(
    (index: number) => {
      const drillDownItem = list![index];

      if (!drillDownItem) {
        return null;
      }

      const { name, count, rate, totalCount, value } = drillDownItem;

      const isActive = index === activeDrillDownRowIndex;

      const shouldShowOptions = isLastHierarchyLevel && isOptionBarEnable;

      return (
        <DrillDownItem
          progressTotalValue={isNumericData ? highestValue : undefined}
          isLastHierarchyLevel={isLastHierarchyLevel}
          currency={currency}
          shouldDisplayTotalAndPercent={shouldDisplayTotalAndPercent}
          shouldDisplayShortCurrency={shouldDisplayShortCurrency}
          isActive={isActive}
          onActiveToggle={() => onActiveDrillDownRowChange(index)}
          totalCount={totalCount}
          rate={rate}
          key={`${name}-${index}`}
          count={count}
          shouldShowOptions={shouldShowOptions}
          onFilterClick={onFilter}
          name={name}
          value={value}
          onShowOpportunitiesClick={onShowOpportunity}
        />
      );
    },
    [list, activeDrillDownRowIndex],
  );

  const height = totalCount * CARD_HEIGHT;

  return (
    <Cards
      dataTestId="drill-down-list"
      emptyState={emptyState}
      isLoading={isLoading}
      cards={list}
      containerHeight={height}
      onLoadMore={onLoadMore}
      itemContent={itemContent}
      totalCount={list?.length}
    />
  );
};

export default DrillDownList;
