import { setActiveTabFilter } from 'store/filters/filtersActions';
import { filterValue } from 'store/filters/types';
import MyOpportunitiesActionTypes from 'store/myOpportunities/myOpportunitiesActionTypes';
import { DEEP_LINK_NOT_AVAILABLE, IActionType, OpportunitiesTabsOptions } from 'types';
import { getMyOpportunities, removeOpportunityDeepLink } from './myOpportunitiesActions';

const myOpportunitiesMiddleware = ({ dispatch, getState }: any) => (next: any) => (
  action: IActionType,
) => {
  const { type, payload, requestPayload } = action;

  switch (type) {
    case MyOpportunitiesActionTypes.setActiveTab: {
      const { activeTab } = payload;

      const activeTabFilterReflection =
        activeTab === OpportunitiesTabsOptions.Open ? filterValue.OPENED : filterValue.CLOSED;
      dispatch(setActiveTabFilter(activeTabFilterReflection));
      break;
    }

    case MyOpportunitiesActionTypes.getMyOpportunities.SUCCESS: {
      const { rows } = payload;

      const { deepLinkOpportunityId } = requestPayload;

      if (deepLinkOpportunityId && !rows.length) {
        dispatch(removeOpportunityDeepLink(DEEP_LINK_NOT_AVAILABLE));
      }

      break;
    }

    case MyOpportunitiesActionTypes.removeOpportunityDeepLink:
    case MyOpportunitiesActionTypes.submitOpportunityReason.FAILURE: {
      const { activeFilter } = getState().filters.myOpportunities;

      const filter = { ...activeFilter };

      const { showResponseImpactOnSales } = getState().settings.featureFlags;

      const { id } = payload;

      // in deeplink scn we set the active tab to be the relevant filter tab
      // so in case filter is removed the correct tab might not match the activeFilter tab
      // so to stay in the same tab after filter is removed
      // we are being consistent with the deeplink filter tab and set the activeFilter corresponding (by default we request open tab)
      if (
        type === MyOpportunitiesActionTypes.removeOpportunityDeepLink &&
        id === OpportunitiesTabsOptions.Completed
      ) {
        filter.activeTab = filterValue.CLOSED;
      }

      dispatch(getMyOpportunities(filter, showResponseImpactOnSales));
      break;
    }

    default:
      break;
  }

  next(action);
};

export default myOpportunitiesMiddleware;
