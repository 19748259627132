import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `KnowledgeCenterActionTypes.${action}`;

const getVideos = APIConstants.createAPIRequestAction(getPrefix('getVideos'));

const setSelectedVideo = getPrefix('setSelectedVideo');

const KnowledgeCenterActionTypes = {
  getVideos,
  setSelectedVideo,
};

export default KnowledgeCenterActionTypes;
