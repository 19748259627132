const s4 = (): string =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

const generateFake = (): string => {
  return `fake${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const isValid = (guid: string): boolean => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
};

const generateFromUserId = (userId: string): string => {
  if (isValid(userId)) {
    return `${userId.substring(0, 4).split('').reverse().join('')}${userId.substring(4)}`;
  }

  return generateFake();
};

const stripDash = (guid: string): string => guid.replace(/-/g, '');

const GuidUtils = {
  generateFake,
  generateFromUserId,
  isValid,
  stripDash,
};

export default GuidUtils;
