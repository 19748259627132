import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `SummaryActionTypes.${action}`;

const getOpenOpportunities = APIConstants.createAPIRequestAction(getPrefix('getOpenOpportunities'));

const getIssueFoundByReasons = getPrefix('getIssueFoundByReasons');

const getMyIssueFoundByReasons = APIConstants.createAPIRequestAction(
  getPrefix('getMyIssueFoundByReasons'),
);

const getAllIssueFoundByReasons = APIConstants.createAPIRequestAction(
  getPrefix('getAllIssueFoundByReasons'),
);

const setKpiDateFilter = getPrefix('setKpiDateFilter');

const setTopFindingsDateFilter = getPrefix('setTopFindingsDateFilter');

const setLeaderBoardDateFilter = getPrefix('setLeaderBoardDateFilter');

const setActiveComparisonTab = getPrefix('setActiveComparisonTab');

const getSalesIncrease = getPrefix('getSalesIncrease');

const getMySalesIncrease = APIConstants.createAPIRequestAction(getPrefix('getMySalesIncrease'));

const getAllSalesIncrease = APIConstants.createAPIRequestAction(getPrefix('getAllSalesIncrease'));

const getRateComparison = getPrefix('getRateComparison');

const getResponseRateComparison = APIConstants.createAPIRequestAction(
  getPrefix('getResponseRateComparison'),
);

const getHitRateComparison = APIConstants.createAPIRequestAction(getPrefix('getHitRateComparison'));

const setStoreFilter = getPrefix('setStoreFilter');

const getFindingsReasons = APIConstants.createAPIRequestAction(getPrefix('getFindingsReasons'));

const toggleGroupSelection = getPrefix('toggleGroupSelection');

const getHierarchyDrillDownData = APIConstants.createAPIRequestAction(
  getPrefix('getHierarchyDrillDownData'),
);

const getProducts = APIConstants.createAPIRequestAction(getPrefix('getProducts'));

const getStores = APIConstants.createAPIRequestAction(getPrefix('getStores'));

const getDrillDown = getPrefix('getDrillDown');

const getLeaderBoardPrepare = getPrefix('getLeaderBoardPrepare');

const getLeaderBoardData = APIConstants.createAPIRequestAction(getPrefix('getLeaderBoardData'));

const setLeaderBoardPeersSelected = getPrefix('setLeaderBoardPeersSelected');

const getRateHistograms = getPrefix('getRateHistograms');

const getHitRateHistogram = APIConstants.createAPIRequestAction(getPrefix('getHitRateHistogram'));

const getHitRateChainWideHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getHitRateChainWideHistogram'),
);

const getResponseRateHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getResponseRateHistogram'),
);

const getResponseRateChainWideHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getResponseRateChainWideHistogram'),
);

const SummaryActionTypes = {
  getIssueFoundByReasons,
  getMyIssueFoundByReasons,
  getAllIssueFoundByReasons,
  setKpiDateFilter,
  setTopFindingsDateFilter,
  setLeaderBoardDateFilter,
  setActiveComparisonTab,
  getSalesIncrease,
  getMySalesIncrease,
  getAllSalesIncrease,
  getRateComparison,
  getResponseRateComparison,
  getHitRateComparison,
  getFindingsReasons,
  toggleGroupSelection,
  getDrillDown,
  getHierarchyDrillDownData,
  getProducts,
  getStores,
  getLeaderBoardPrepare,
  getLeaderBoardData,
  setLeaderBoardPeersSelected,
  getRateHistograms,
  getHitRateHistogram,
  getHitRateChainWideHistogram,
  getResponseRateHistogram,
  getResponseRateChainWideHistogram,
  setStoreFilter,
  getOpenOpportunities,
};

export default SummaryActionTypes;
