import { FC } from 'react';

import { IVideoDetails } from 'types';
import { getFullDate } from 'utils';
import './videoDetails.scss';

const VideoDetails: FC<IVideoDetails> = ({ title, date, info }) => {
  const formattedDate = getFullDate(date);

  return (
    <div className="video-details">
      <div className="video-details-header">
        <span className="video-details-title">{title}</span>
        <span className="video-details-date">{formattedDate}</span>
      </div>
      <div className="video-details-info">{info}</div>
    </div>
  );
};

export default VideoDetails;
