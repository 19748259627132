import { ChangeEvent, FC } from 'react';
import cn from 'clsx';

import { IField } from 'types';

export const TextArea: FC<IField> = ({
  disabled,
  isError,
  errorMessage,
  value,
  className,
  onChange,
  placeholder,
  title,
  id,
}) => {
  const showErrorMessage = isError && errorMessage;

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={cn([className])}>
      {title && <span className="title">{title}</span>}
      <textarea
        id={`text-area-${id}`}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        className="textarea"
        onChange={onTextChange}
      />
      <div className="error-message-container">
        {showErrorMessage && <span className="error-message">{errorMessage}</span>}
      </div>
    </div>
  );
};
