const getPrefix = (action: string): string => `EnvironmentSettingsActionTypes.${action}`;

const showEnvironmentDebugSettings = getPrefix('showEnvironmentDebugSettings');

const setEnvironmentDebugSettings = getPrefix('setEnvironmentDebugSettings');

const EnvironmentActionTypes = {
  showEnvironmentDebugSettings,
  setEnvironmentDebugSettings,
};

export default EnvironmentActionTypes;
