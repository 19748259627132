import { FC } from 'react';

import { DrillDownTypes, IDrillDowns } from 'types';
import FindingsDrillDownWrapper from './FindingsDrillDownWrapper';

const DrillDowns: FC<IDrillDowns> = ({
  storeDrillDown,
  productDrillDown,
  otherDrillDown,
  onLoadMore,
  onTitleClick,
  onOpportunityClick,
}) => {
  return (
    <>
      <FindingsDrillDownWrapper
        id="findings-drilldown-store"
        onOpportunityClick={onOpportunityClick}
        drillDown={storeDrillDown}
        type={DrillDownTypes.Store}
        onTitleClick={onTitleClick}
        onLoadMore={onLoadMore}
      />
      <FindingsDrillDownWrapper
        id="findings-drilldown-product"
        onOpportunityClick={onOpportunityClick}
        drillDown={productDrillDown}
        type={DrillDownTypes.Product}
        onTitleClick={onTitleClick}
        onLoadMore={onLoadMore}
      />
      <FindingsDrillDownWrapper
        id="findings-drilldown-other"
        onOpportunityClick={onOpportunityClick}
        drillDown={otherDrillDown}
        type={DrillDownTypes.Other}
        onTitleClick={onTitleClick}
        onLoadMore={onLoadMore}
      />
    </>
  );
};

export default DrillDowns;
