import AlertActionTypes from 'store/alert/alertActionTypes';
import { IAlertModel, IAlertRadioItem } from 'types';

export const show = ({
  id,
  title,
  text,
  buttons,
  alertCustomClassName,
  treeSelection,
  withOverlay,
  checklist,
  withDatePicker,
  radioList,
  selectedDates,
}: IAlertModel) => ({
  type: AlertActionTypes.Show,
  payload: {
    id,
    text,
    title,
    buttons,
    radioList,
    checklist,
    alertCustomClassName,
    withOverlay,
    withDatePicker,
    selectedDates,
    treeSelection,
  },
});

export const hide = (id: string) => ({
  type: AlertActionTypes.Hide,
  payload: { id },
});

export const setChecklist = (checklist: Array<any>) => ({
  type: AlertActionTypes.SetChecklist,
  payload: { checklist },
});

export const setDateRange = ({ from, to }: { from: number; to: number }) => ({
  type: AlertActionTypes.SetDateRange,
  payload: { from, to },
});

export const setTreeSelection = (selection: string, treeId: string) => ({
  type: AlertActionTypes.SetTreeSelection,
  payload: { selection, treeId },
});

const buttonClick = (id: string, buttonId: string, checklist: any) => ({
  type: AlertActionTypes.ButtonClick,
  payload: { id, buttonId, checklist },
});

const setRadioList = (radioList: Array<IAlertRadioItem>) => ({
  type: AlertActionTypes.SetRadioList,
  payload: { radioList },
});

const AlertActions = {
  show,
  hide,
  buttonClick,
  setDateRange,
  setChecklist,
  setRadioList,
};

export default AlertActions;
