import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ICategories, ICategoriesFeatureFlags } from 'types';
import CategoriesSection from './CategoriesSection';
import Category from './Category';
import './categories.scss';
import { getFullDate, isEmptyArray } from 'utils';
import SizesAndSkusSection from './SizesAndSkusSection';
import { getLiveInventory } from 'store/myOpportunities/myOpportunitiesActions';
import featureFlagsService from 'services/permissions/featureFlagsService';

export const Categories: FC<ICategories> = ({
  productCategories,
  productSkusAndSizes,
  inventoryUpdate,
  potentialRevenue,
  potentialUnits,
  monthlySalesTarget,
  featureFlags,
  monthlySalesTargetUnits,
  onLiveInventoryClick,
  planogramAttributes,
  flavour,
}) => {
  const [t] = useTranslation();

  const categoriesFeatureFlags: ICategoriesFeatureFlags = featureFlags || {
    showInventoryUpdate: featureFlagsService.mayViewInventoryUpdate(),
    showTargetSales: featureFlagsService.mayViewTargetSales(),
    showTargetUnits: featureFlagsService.mayViewTargetUnits(),
    showPotentialRevenue: featureFlagsService.mayViewPotentialRevenue(),
    showPotentialUnits: featureFlagsService.mayViewPotentialUnits(),
    isLiveInventoryEnabled: featureFlagsService.mayViewLiveInventory(),
  };

  const {
    showInventoryUpdate,
    showPotentialUnits,
    showTargetUnits,
    showPotentialRevenue,
    showTargetSales,
    isLiveInventoryEnabled,
  } = categoriesFeatureFlags;

  const withSeparator = (data: any[]) => !isEmptyArray(data);

  const inventoryUpdateTitle = (
    <div className="inventory-update-title">
      <span>{t('MY_OPPORTUNITIES.INVENTORY_UPDATED_ON')}</span>
      <div className="inventory-update-date">
        {inventoryUpdate && getFullDate(inventoryUpdate.date)}
      </div>
    </div>
  );

  return (
    <div className="categories-container">
      <CategoriesSection
        withSeparator={withSeparator(productCategories)}
        categories={productCategories}
      />

      <CategoriesSection
        withSeparator={withSeparator(planogramAttributes)}
        categories={planogramAttributes}
      />

      <SizesAndSkusSection
        isLiveInventoryEnabled={isLiveInventoryEnabled}
        productSkusAndSizes={productSkusAndSizes}
        onLiveInventoryClick={onLiveInventoryClick}
        withSeparator={withSeparator(productSkusAndSizes)}
        flavour={flavour}
      />

      <div className="feature-flags-details-container">
        {showInventoryUpdate && inventoryUpdate && (
          <Category withSeparator name={inventoryUpdateTitle} value={inventoryUpdate.value} />
        )}

        {showTargetUnits && (
          <Category
            withSeparator
            name={t('MY_OPPORTUNITIES.SALES_TARGET_UNITS')}
            value={monthlySalesTargetUnits}
          />
        )}
        {showTargetSales && (
          <Category
            withSeparator
            name={t('MY_OPPORTUNITIES.SALES_TARGET')}
            value={monthlySalesTarget}
          />
        )}
        {showPotentialRevenue && (
          <Category
            withSeparator
            name={t('MY_OPPORTUNITIES.POTENTIAL_SALES')}
            value={potentialRevenue}
          />
        )}
        {showPotentialUnits && (
          <Category
            withSeparator
            name={t('MY_OPPORTUNITIES.POTENTIAL_UNITS')}
            value={potentialUnits}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = { getLiveInventory };

export default connect(null, mapDispatchToProps)(Categories);
