import { CurrencyCodeOptions } from 'constants/Currency';
import { CurrencySymbolOptions } from 'types';
import i18n from 'i18n/i18n';

const THOUSAND = 1000;

const MEGA = THOUSAND * THOUSAND;

export const toFixed = (value: number, decimalPoints: number = 0): string => {
  if (Number.isInteger(decimalPoints) && !Number.isInteger(value)) {
    return value.toFixed(decimalPoints);
  }

  return String(value);
};

export const withMultipleUnit = (value: number, decimalPoints: number = 0) => {
  if (value >= MEGA) {
    return `${toFixed(value / MEGA, decimalPoints)}M`;
  }
  if (value >= THOUSAND) {
    return `${toFixed(value / THOUSAND, decimalPoints)}K`;
  }

  return toFixed(value, decimalPoints);
};

export const getPercent = (value: number, total: number) => Math.round((value / total) * 100);

export const toPercentage = (value: number, decimalPoints: number = 0): string => {
  return `${toFixed(value * 100, decimalPoints)}%`;
};

export const toCurrency = (value: number, currency: CurrencySymbolOptions) => {
  const roundedValue = Math.round(value);

  const currencyName = CurrencyCodeOptions[currency];

  const { language } = i18n;

  // default options
  const options: any = { minimumFractionDigits: 0 };

  // if the currency symbol exists add it
  if (currencyName) {
    options.style = 'currency';
    options.currency = currencyName;
    options.currencyDisplay = 'narrowSymbol';
  }

  return Intl.NumberFormat(language, options).format(roundedValue);
};

export const toShortCurrencyFormat = (
  value: number,
  currency: string,
  minimumFractionDigits: number,
) => {
  const currencyName = CurrencyCodeOptions[currency];

  if (!currencyName) {
    return '';
  }

  const options: any = {
    minimumFractionDigits,
    notation: 'compact',
    style: 'currency',
    currency: currencyName,
    currencyDisplay: 'narrowSymbol',
  };

  const { language } = i18n;

  return Intl.NumberFormat(language, options).format(value);
};

export const toLanguageNumberFormat = (value: number) => {
  const roundedValue = Math.round(value);

  const { language } = i18n;

  const options: any = { minimumFractionDigits: 0 };

  return Intl.NumberFormat(language, options).format(roundedValue);
};

const NumberUtils = {
  toCurrency,
  toLanguageNumberFormat,
};

export default NumberUtils;
