import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import './peersSwitch.scss';
import { IPeersSwitchProps } from 'types';

const PeersSwitch: FC<IPeersSwitchProps> = ({
  hasPeers,
  onChange,
  peersLabel,
  isPeersSelected,
}) => {
  const [t] = useTranslation();

  return (
    <div className="peers-switch">
      {hasPeers && (
        <div
          className={cn('peers-label', { selected: isPeersSelected })}
          onClick={() => onChange(true)}
        >
          {peersLabel || t('SUMMARY.MY_STORE')}
        </div>
      )}

      <div
        className={cn('peers-label', { selected: !isPeersSelected || !hasPeers })}
        onClick={() => onChange(false)}
      >
        {t('SUMMARY.CHAIN_WIDE-DASHED')}
      </div>
    </div>
  );
};

export default PeersSwitch;
