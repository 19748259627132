import { FC, memo, useCallback, useEffect, useState } from 'react';
import cn from 'clsx';
import { Slider } from 'antd';
import { useTranslation } from 'react-i18next';

import { IDateSliderProps, ISliderData } from './types';
import './DateSlider.scss';
import { getShortDateFormat } from 'utils';

const DateSlider: FC<IDateSliderProps> = ({
  marks,
  selectedDatesIndexes,
  className,
  title,
  onDateChange,
  isRtl,
}) => {
  const [sliderData, setSliderData] = useState<ISliderData>({
    interval: 0,
    indexes: [],
    sliderMarks: {},
  });

  const [sliderPosition, setSliderPosition] = useState<[number, number]>([0, 0]);

  const { i18n } = useTranslation();

  const { interval, indexes, sliderMarks } = sliderData;

  const calcInterval = (markersLength: number) => {
    return 100 / Math.max(markersLength - 1, 1);
  };

  const calcStep = (index: number, interval: number) => {
    return Math.round(index * interval);
  };

  // set slider position
  useEffect(() => {
    if (interval) {
      setSliderPosition([
        calcStep(selectedDatesIndexes[0], interval),
        calcStep(selectedDatesIndexes[1], interval),
      ]);
    }
  }, [interval, selectedDatesIndexes]);

  // fetch when slider dates change
  const handleAfterDateChange = useCallback(
    (range: [number, number]) => {
      // setSliderPosition(range);

      const newDateRangeIndexes: [number, number] = [
        indexes.indexOf(range[0]),
        indexes.indexOf(range[1]),
      ];

      if (onDateChange) {
        onDateChange(newDateRangeIndexes);
      }
    },
    [onDateChange, indexes],
  );

  // init or update slider marks
  useEffect(() => {
    const data: ISliderData = {
      sliderMarks: {},
      interval: calcInterval(marks.length),
      indexes: [],
    };

    marks.forEach((mark, index) => {
      const stepValue = calcStep(index, data.interval);

      const isSelected = index >= selectedDatesIndexes[0] && index <= selectedDatesIndexes[1];
      data.indexes.push(stepValue);

      data.sliderMarks![stepValue] = {
        label: (
          <div className={cn('slider-date-mark-wrapper', !isSelected && 'disabled')}>
            <strong className="slider-date-mark-label">{getShortDateFormat(mark.date)}</strong>
            <div className="slider-date-mark-value">{mark.opportunities}</div>
          </div>
        ),
      };
    });

    setSliderData(data);
  }, [marks, selectedDatesIndexes, i18n.language]);

  return (
    <div className={cn('date-slider-wrapper', className, isRtl && 'rtl')}>
      <span className="title">{title}</span>
      <Slider
        className="date-slider"
        reverse={isRtl}
        value={sliderPosition}
        tooltipVisible={false}
        step={null}
        range
        marks={sliderMarks}
        onChange={handleAfterDateChange}
      />
    </div>
  );
};

export default memo(DateSlider);
