import _ from 'lodash';

import { getDeviceInfo, getEnvType } from 'utils';
import GuidUtils from 'utils/GuidUtils';
import {
  AnalyticsAuthEventsId,
  AnalyticsEventActions,
  AnalyticsEventCategory,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
} from './AnalyticsTypes';
import { history } from 'store/configureStore';
import { direction } from 'store/filters/types';

/**
 * Analytics Service
 */
class AnalyticsService {
  private env: string | null;

  public constructor() {
    this.env = null;
    window.dataLayer = window.dataLayer || [];
  }

  public init() {
    window.gtag_debug = { trace: true };

    const domain = getEnvType();
    this.gtag('js', new Date());
    this.gtag('config', GOOGLE_ANALYTICS_MEASUREMENT_ID, {
      transport_url: `https://${domain}.c-b4.com/gtag`,
    });
  }

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private gtag(...args: any) {
    if (window.dataLayer) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }
  }

  // gtag set user properties
  private executeSet(dimension: string, value?: string | number): void {
    this.gtag('set', { user_properties: { [dimension]: value } });
  }

  public setClientId(clientId?: string) {
    this.executeSet('clientId', clientId);
  }

  public setUserId(userId: string) {
    const userIdNum = GuidUtils.stripDash(userId);
    this.executeSet('user_id', userIdNum);
  }

  public setUserRole(userRole?: string) {
    this.executeSet('userRole', userRole);
  }

  public setDeviceInfo() {
    const deviceInfo = getDeviceInfo();
    this.executeSet('deviceInfo', deviceInfo);
  }

  public setEnvironment() {
    const domain = getEnvType();
    this.executeSet('env', domain);
  }

  // gtag event
  private executeEvent(
    category: AnalyticsEventCategory,
    action: AnalyticsEventActions,
    schema?: { [key: string]: any },
  ) {
    const { pathname } = history.location;

    const clonedSchema = _.cloneDeep(schema);

    this.gtag('event', action, {
      category,
      location: pathname,
      ...clonedSchema,
    });
  }

  // page view event
  public sendPageViewEvent() {
    this.executeEvent(AnalyticsEventCategory.PAGE_VIEW, AnalyticsEventActions.PAGE_VIEW);
  }

  // click event
  public sendClickEvent(category: AnalyticsEventCategory, params?: { [key: string]: any }) {
    this.executeEvent(category, AnalyticsEventActions.CLICKED, { ...params });
  }

  // change event
  public sendChangeEvent(category: AnalyticsEventCategory, params?: { [key: string]: any }) {
    this.executeEvent(category, AnalyticsEventActions.CHANGED, { ...params });
  }

  // view event
  public sendViewEvent(category: AnalyticsEventCategory, params?: { [key: string]: any }) {
    this.executeEvent(category, AnalyticsEventActions.VIEWED, { ...params });
  }

  // select event
  public sendSelectEvent(category: AnalyticsEventCategory, params?: { [key: string]: any }) {
    this.executeEvent(category, AnalyticsEventActions.SELECTED, { ...params });
  }

  // video play event
  public sendVideoPlayEvent(videoId = '', time: number) {
    this.executeEvent(AnalyticsEventCategory.VIDEO_PLAY_TIME, AnalyticsEventActions.PLAY_VIDEO, {
      video_id: videoId,
      time,
    });
  }

  // auth events
  public sendAuthEvent(authEventId: AnalyticsAuthEventsId) {
    this.executeEvent(AnalyticsEventCategory.LOGIN_FLOW, AnalyticsEventActions.CHANGED, {
      event_id: authEventId,
    });
  }

  // date changed event
  public sendDateChangedEvent(
    timeSpan: string,
    category: AnalyticsEventCategory = AnalyticsEventCategory.DATE_CHANGED,
  ) {
    this.executeEvent(category, AnalyticsEventActions.DATE_CHANGED, {
      time_span: timeSpan,
    });
  }

  // sort event
  public sendSortEvent(isOrderChanged: boolean, sortField: string, direction?: direction) {
    if (isOrderChanged && direction) {
      this.executeEvent(AnalyticsEventCategory.SORT_DIRECTION, AnalyticsEventActions.SORT, {
        direction,
        field: sortField,
      });
    } else {
      this.executeEvent(AnalyticsEventCategory.SORT_BY, AnalyticsEventActions.SORT, {
        field: sortField,
      });
    }
  }

  // date slider event
  public sendDateSliderEvent(timeSpan: string) {
    this.executeEvent(AnalyticsEventCategory.DATE_SLIDER, AnalyticsEventActions.DATE_CHANGED, {
      time_span: timeSpan,
    });
  }

  // search event
  public sendSearchEvent() {
    this.executeEvent(AnalyticsEventCategory.SEARCH, AnalyticsEventActions.SEARCH);
  }

  // message delete event
  public sendMessageDeleteEvent(label = '') {
    this.executeEvent(AnalyticsEventCategory.MESSAGING, AnalyticsEventActions.MSG_DELETE, {
      label,
    });
  }

  // message viewed event
  public sendMessageViewedEvent(label = '', unread: number) {
    this.executeEvent(AnalyticsEventCategory.MESSAGING, AnalyticsEventActions.MSG_VIEWED, {
      label,
      unread_messages: unread,
    });
  }

  // message sent event
  public sendMessageSentEvent(label = '') {
    this.executeEvent(AnalyticsEventCategory.MESSAGING, AnalyticsEventActions.MSG_SENT, { label });
  }

  // message retry event
  public sendMessageRetryEvent(label = '') {
    this.executeEvent(AnalyticsEventCategory.MESSAGING, AnalyticsEventActions.MSG_RETRY, { label });
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;
