import { FC } from 'react';
import { connect } from 'react-redux';

import Popup from 'components/common/popup/Popup';
import './productImagePopup.scss';
import { IRootState } from 'types';
import MyOpportunitiesSelector from 'store/myOpportunities/myOpportunitiesSelector';

export interface IProductImagePopup {
  imageSrc: string;
}

const ProductImagePopup: FC<IProductImagePopup> = ({ imageSrc }) => {
  return (
    <Popup popupClassName="product-image-popup-container" withOverlay>
      <Popup.Content>
        <img alt="product" className="product-image" src={imageSrc} />
      </Popup.Content>
    </Popup>
  );
};

const mapStateToProps = (state: IRootState) => {
  const selectedOpportunity = MyOpportunitiesSelector.getSelectedOpportunitySelector(state);

  const imageSrc = selectedOpportunity?.highResolutionProductImage || '';

  return {
    imageSrc,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImagePopup);
