import { CurrencySymbolOptions } from 'types';

export const CurrencyCodeOptions = {
  [CurrencySymbolOptions.EUR]: 'EUR',
  [CurrencySymbolOptions.USD]: 'USD',
  [CurrencySymbolOptions.PLN]: 'PLN',
  [CurrencySymbolOptions.ILS]: 'ILS',
  [CurrencySymbolOptions.CZK]: 'CZK',
  [CurrencySymbolOptions.INR]: 'INR',
  [CurrencySymbolOptions.JPY]: 'JPY',
  [CurrencySymbolOptions.GBP]: 'GBP',
  [CurrencySymbolOptions.CNY]: 'CNY',
  [CurrencySymbolOptions.KRW]: 'KRW',
  [CurrencySymbolOptions.SEK]: 'SEK',
};
