import { createSelector } from 'reselect';

import { IRootState, mapTabsToTextValue, OpportunitiesCounter } from 'types';
import {
  getInteractiveFilterTabHierarchyName,
  getResponseRateAndOpenOpByWeeks,
  getTopHierarchy,
  getWeeksTimePeriod,
} from './openOpportunitiesHelper';
import { getMapReasonIdToTextSelector } from 'store/reasons/reasonsSelector';
import { getOpportunitiesDrillDown } from 'store/helpers/drillDown';

const getSettingsSelector = (state: IRootState) => state.settings;

const getPopupSelectedOpportunityId = (state: IRootState) => state.popup.selectedOpportunityId;

const getUserProfileSelector = (state: IRootState) => state.userProfile;

const getOpportunitiesCountSelector = (state: IRootState) =>
  state.openOpportunities.opportunitiesCountByWeek;

const getHierarchyIndexSelector = (state: IRootState) =>
  state.openOpportunities.drillDown.filters.hierarchyIndex;

const getOpportunitiesSelector = (state: IRootState) =>
  state.openOpportunities.drillDown.list.opportunities;

const getCurrentNavigationTabsSelector = (state: IRootState) =>
  state.openOpportunities.drillDown.navigationTabs;

const getTotalStoreBreakdownCountSelector = (state: IRootState) =>
  state.openOpportunities.storeBreakDown.reduce((prev, current) => prev + (current.count || 0), 0);

const getWeeksTimePeriodSelector = (state: IRootState) => {
  const { selectedWeeks } = state.openOpportunities;

  return getWeeksTimePeriod(selectedWeeks);
};

const getResponseRateSelector = (state: IRootState) => {
  const { selectedWeeks, opportunitiesCountByWeek } = state.openOpportunities;

  const { responseRate } = getResponseRateAndOpenOpByWeeks(
    selectedWeeks,
    opportunitiesCountByWeek || [],
  );

  return responseRate;
};

const getDateMarksSelector = createSelector(
  [getOpportunitiesCountSelector],
  (opportunitiesCountByWeek) => {
    if (!opportunitiesCountByWeek) {
      return [];
    }

    const dateMarks = opportunitiesCountByWeek.map((item: OpportunitiesCounter) => {
      return {
        date: item.week,
        opportunities: item.open,
      };
    });

    return dateMarks;
  },
);

const getOpenOpportunitiesSelector = (state: IRootState) => {
  const { selectedWeeks, opportunitiesCountByWeek } = state.openOpportunities;

  const { open } = getResponseRateAndOpenOpByWeeks(selectedWeeks, opportunitiesCountByWeek || []);

  return open;
};

const getNavigationTabsSelector = createSelector(
  [
    getSettingsSelector,
    getUserProfileSelector,
    getCurrentNavigationTabsSelector,
    getHierarchyIndexSelector,
  ],
  (settingsModel, userProfile, tabs, hierarchyIndex): { label: string; value: number }[] => {
    const mappedTabs = Object.keys(tabs).map((key, i) => {
      return {
        label: mapTabsToTextValue[key],
        value: i,
      };
    });

    const topHierarchyLevel = getTopHierarchy(userProfile).index;

    const otherTabLabel = getInteractiveFilterTabHierarchyName(
      settingsModel,
      hierarchyIndex,
      topHierarchyLevel,
    );

    if (otherTabLabel) {
      mappedTabs[0].label = otherTabLabel;
    } else {
      mappedTabs.shift();
    }

    return mappedTabs;
  },
);

const getOpportunitiesDrillDownSelector = createSelector(
  [getOpportunitiesSelector, getMapReasonIdToTextSelector],
  (opportunities, mapReasonIdToText) => {
    return getOpportunitiesDrillDown(opportunities || null, mapReasonIdToText);
  },
);

const getSelectedOpportunity = createSelector(
  [getPopupSelectedOpportunityId, getOpportunitiesDrillDownSelector],
  (selectedOpportunityId, opportunitiesDrillDown) => {
    if (!selectedOpportunityId || !opportunitiesDrillDown) {
      return null;
    }

    const selectedOpportunity = opportunitiesDrillDown?.list?.find(
      (opportunity) => opportunity.id === selectedOpportunityId,
    );

    return selectedOpportunity || null;
  },
);

const OpenOpportunitiesSelector = {
  getTotalStoreBreakdownCountSelector,
  getResponseRateSelector,
  getOpenOpportunitiesSelector,
  getDateMarksSelector,
  getNavigationTabsSelector,
  getOpportunitiesDrillDownSelector,
  getWeeksTimePeriodSelector,
  getSelectedOpportunity,
};

export default OpenOpportunitiesSelector;
