import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import { ArrowIcon, CalendarIcon } from 'components/Icons';
import './DateButton.scss';
import { IDateButton } from './types';
import { getDatesWeeksDiff, getShortDateFormat } from 'utils';

export const DateButton: FC<IDateButton> = ({
  dates,
  label,
  arrowDirection,
  onClick,
  hasWeeksLabel,
}) => {
  const [t] = useTranslation();

  const buildWeeksLabel = () => {
    let label;

    if (dates) {
      const diff = getDatesWeeksDiff(dates[0], dates[1]);

      if (diff === 0) {
        label = `(${t('OPEN_OPPORTUNITIES.WEEK')})`;
      } else {
        label = `(${diff + 1} ${t('OPEN_OPPORTUNITIES.WEEKS')})`;
      }
    }

    return label;
  };

  const buildLabel = () => {
    let result;
    if (dates && dates[0] === dates[1]) {
      result = getShortDateFormat(dates[0]);
    } else if (dates && dates[0] !== dates[1]) {
      result = `${getShortDateFormat(dates[0])} - ${getShortDateFormat(dates[1])}`;
    } else if (label) {
      result = label;
    }

    return result;
  };

  return (
    <div className={cn('date-button', label && 'uppercase')} onClick={onClick}>
      <CalendarIcon />
      <div className="label">{buildLabel()}</div>
      {hasWeeksLabel && <span className="dates-week-label">{buildWeeksLabel()}</span>}
      <ArrowIcon className={arrowDirection} />
    </div>
  );
};
