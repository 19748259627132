const getPrefix = (action: string): string => `PopupActionTypes.${action}`;

const Show = getPrefix('Show');

const Hide = getPrefix('Hide');

const PopupActionTypes = {
  Show,
  Hide,
};

export default PopupActionTypes;
