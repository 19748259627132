import { KeycloakError, KeycloakPromise, KeycloakTokenParsed } from 'keycloak-js';

export interface AuthClientError {
  error: string;

  // eslint-disable-next-line camelcase
  error_description: string;
}

export interface AuthClientInitOptions {
  [paramName: string]: any;
}

/**
 * A client for the Auth server.
 */
export interface AuthClient {
  /**
   * The base64 encoded token that can be sent in the Authorization header in
   * requests to services.
   */
  token?: string;

  /**
   * The base64 encoded refresh token that can be used to retrieve a new token.
   */
  refreshToken?: string;

  /**
   * The base64 encoded ID token.
   */
  idToken?: string;

  /**
   * The user tenant info.
   */
  tokenParsed?: KeycloakTokenParsedExtended;

  /**
   * Called when the adapter is initialized.
   */
  onReady?(authenticated?: boolean): void;

  /**
   * Called when a user is successfully authenticated.
   */
  onAuthSuccess?(): void;

  /**
   * Called if there was an error during authentication.
   */
  onAuthError?(errorData: AuthClientError): void;

  /**
   * Called when the token is refreshed.
   */
  onAuthRefreshSuccess?(): void;

  /**
   * Called if there was an error while trying to refresh the token.
   */
  onAuthRefreshError?(): void;

  /**
   * Called if the user is logged out (will only be called if the session
   * status iframe is enabled, or in Cordova mode).
   */
  onAuthLogout?(): void;

  /**
   * Called when the access token is expired. If a refresh token is available
   * the token can be refreshed with Auth#updateToken, or in cases where
   * it's not (ie. with implicit flow) you can redirect to loading screen to
   * obtain a new access token.
   */
  onTokenExpired?(): void;

  /**
   * Called to initialize the adapter.
   * @param initOptions Initialization options.
   * @returns A promise to set functions to be invoked on success or error.
   */
  init(initOptions: AuthClientInitOptions): KeycloakPromise<boolean, KeycloakError>;

  /**
   * If the token expires within `minValidity` seconds, the token is refreshed.
   * If the session status iframe is enabled, the session status is also
   * checked.
   *
   * @returns A promise to set functions that can be invoked if the token is
   *          still valid, or if the token is no longer valid.
   */
  updateToken(minValidity: number): KeycloakPromise<boolean, boolean>;

  loadUserInfo(): KeycloakPromise<{}, void>;
}

/**
 * Set of tokens provided by AuthClient
 */
export type AuthClientTokens = Pick<AuthClient, 'idToken' | 'refreshToken' | 'token'>;

export interface KeycloakTokenParsedExtended extends KeycloakTokenParsed {
  utr?: string;
}

export interface IAuthUserInfo {
  email: string;
  // eslint-disable-next-line camelcase
  email_verified: boolean;
  // eslint-disable-next-line camelcase
  preferred_username: string;
  sub: string;
  mayViewDashboard: boolean;
  alias: string;
}

export interface IUserTenantRelations {
  uid: string;
  pid: string;
  cid: string;
}

export interface IUserScopeInfo {
  stores: Object;
  userHighestHierarchyPath: Object;
  userHighestHierarchyLowestLevel: Object;
  userHierarchyPaths: Array<any>;
  firstTenantDeployDate: string;
  lastTenantDeployDate: string;
  firstUserDeployDate: string;
  lastUserDeployDate: string;
  dashboards: Array<string>;
  role: string;
  alias: string;
  selfReportTimeSpent: boolean;
  projectIds: Array<number>;
  fullName: string;
  mayViewDashboard: boolean;
  mayViewTask: boolean;
  mayViewSalesIncrease: boolean;
  availableStoreHierarchyLevels: Object;
  availableProductHierarchyLevels: Object;
}

/**
 * ReactAuth event types
 */
export type AuthClientEvent =
  | 'onReady'
  | 'onInitError'
  | 'onAuthSuccess'
  | 'onAuthError'
  | 'onAuthRefreshSuccess'
  | 'onAuthRefreshError'
  | 'onAuthLogout'
  | 'onTokenExpired';

/**
 * Props that can be passed to AuthProvider
 */
export type AuthProviderProps<T extends AuthClient> = {
  /**
   * The single AuthClient instance to be used by your application.
   */
  authClient: T | null;

  /**
   * A flag to enable automatic token refresh. Defaults to true.
   * This is useful if you need to disable it (not recommended).
   *
   * @default true
   */
  autoRefreshToken?: boolean;

  /**
   * The config to be used when initializing AuthClient instance.
   */
  initOptions?: AuthClientInitOptions;

  /**
   * An optional loading check function to customize LoadingComponent display condition.
   * Return `true` to display LoadingComponent, `false` to hide it.
   *
   * @param authClient the current AuthClient instance.
   *
   * @returns {boolean} Set to true to display LoadingComponent, false to hide it.
   */
  isLoadingCheck?: (authClient: T) => boolean;

  /**
   * An optional component to display while AuthClient instance is being initialized.
   */
  LoadingComponent?: JSX.Element;

  /**
   * An optional function to receive AuthClient events as they happen.
   */
  onEvent?: (eventType: AuthClientEvent, error?: AuthClientError) => void;

  /**
   * An optional function to receive AuthClient tokens when changed.
   *
   * @param {AuthClientTokens} tokens The current AuthClient tokens set.
   */
  onTokens?: (tokens: AuthClientTokens) => void;

  onUserInfoLoaded?: (userInfo: IAuthUserInfo, userTenant: IUserTenantRelations) => void;
};

export type AuthProviderState = {
  initialized: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  isUserInfoLoaded: boolean;
};

export const MIN_VALIDITY = 30;
