import LoadingActionTypes from 'store/loading/LoadingActionTypes';

const addLoading = (type: string) => ({
  type: LoadingActionTypes.AddLoading,
  payload: { type },
});

const removeLoading = (type: string) => ({
  type: LoadingActionTypes.RemoveLoading,
  payload: { type },
});

const LoadingActions = {
  addLoading,
  removeLoading,
};

export default LoadingActions;
