import { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import './reasons.scss';
import { ReasonsList } from './reasonsList';
import { Tabs } from 'components/tabs';
import { IReasonWrapper, IRootState, ISetReasonRequestBody, ReasonState } from 'types';
import MyOpportunitiesSelector from 'store/myOpportunities/myOpportunitiesSelector';
import packagejson from '../../../../../package.json';
import { submitOpportunityReason } from 'store/myOpportunities/myOpportunitiesActions';

export const ReasonsWrapper: FC<IReasonWrapper> = ({
  reasonList,
  alias,
  selectedOpportunityId,
  selectedReasonDescription,
  selectedReasonId,
  userId,
  isRtl,
  submitOpportunityReason,
  reasonCompleteDate,
  reasonReceivedDate,
}) => {
  const tabs =
    reasonList?.map((reason) => ({
      id: reason.order,
      label: reason.label,
    })) || [];

  const [activeTab, setActiveTab] = useState(tabs![0]?.id);

  const [scrollIntoTabId, setScrollIntoTabId] = useState<number | undefined>(undefined);

  const onSubmitClick = (reasonId: number, reasonState: ReasonState, reasonDescription: string) => {
    if (selectedOpportunityId) {
      const oppReason: ISetReasonRequestBody = {
        state: reasonState,
        alias,
        reasonId,
        responseAlias: alias,
        uniqueTaskId: selectedOpportunityId,
        userGuid: userId,
        updateDate: Date.now(),
        version: packagejson.version,
      };
      if (reasonDescription !== '') {
        oppReason.remark = reasonDescription;
      }
      submitOpportunityReason(oppReason, selectedOpportunityId);
    }
  };

  const onTabChange = useCallback((tabID: number) => {
    setScrollIntoTabId(undefined);
    setActiveTab(tabID);
  }, []);

  const onTabClick = useCallback((id: number) => {
    setActiveTab(id);
    setScrollIntoTabId(id);
  }, []);

  return (
    <div className="reasons-wrapper-container">
      <Tabs
        id="reasons-tab"
        isRtl={isRtl}
        containerClassName="reasons-tabs-container"
        tabs={tabs}
        activeTab={activeTab}
        onChange={onTabClick}
        textClassName="medium-tabs"
      />
      <ReasonsList
        reasonCompleteDate={reasonCompleteDate}
        reasonReceivedDate={reasonReceivedDate}
        selectedReasonId={selectedReasonId}
        selectedReasonDescription={selectedReasonDescription}
        onSubmitClick={onSubmitClick}
        scrollIntoTabId={scrollIntoTabId}
        list={reasonList}
        onTabChange={onTabChange}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const reasonList = MyOpportunitiesSelector.getReasonListSelector(state);

  const selectedOpportunity = MyOpportunitiesSelector.getSelectedOpportunitySelector(state);

  const { activeTab: opportunityActiveTab } = state.myOpportunities;

  const selectedReason = selectedOpportunity?.selectedReason;

  const selectedReasonId = selectedReason?.reasonId;

  const { isRtl } = state.settings;

  return {
    reasonList,
    userId: state.userProfile.uid,
    alias: state.userProfile.email,
    selectedReasonId,
    opportunityActiveTab,
    isRtl,
    selectedOpportunityId: selectedOpportunity?.id,
    reasonReceivedDate: selectedOpportunity?.originalDeployDate,
    reasonCompleteDate: selectedReason?.updateDate,
    selectedReasonDescription: selectedReason?.description,
  };
};

const mapDispatchToProps = {
  submitOpportunityReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReasonsWrapper);
