import './SideMenuControl.scss';
import cn from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FilterControlConnected from 'components/myOpportunities/filters/filterControl';
import { CloseIcon } from 'components/Icons';
import { WideButton } from 'components/buttons';
import { applyMyOpportunitiesFilters, toggleControlMenu } from 'store/filters/filtersActions';
import { filterValue, IRootState } from 'types';

export interface ISideMenuControl {
  isControlMenuExpanded: boolean;
  hasFilterModified: boolean;
  toggleControlMenu(): void;
  applyMyOpportunitiesFilters(): void;
  matchFilterOpportunities: number | null;
  activeTab: filterValue;
}

export const SideMenuControl = ({
  isControlMenuExpanded,
  toggleControlMenu,
  hasFilterModified,
  applyMyOpportunitiesFilters,
  matchFilterOpportunities,
  activeTab,
}: ISideMenuControl) => {
  const [t] = useTranslation();

  const applyButtonText =
    matchFilterOpportunities !== null
      ? `${t('COMMON.APPLY')} (${matchFilterOpportunities}) ${activeTab} ${t(
          'MY_OPPORTUNITIES.FILTER.OPPORTUNITIES',
        )}`
      : t('COMMON.APPLY');

  return (
    <div role="toolbar" className={cn('side-menu-control', isControlMenuExpanded && 'expanded')}>
      <div className="control-title">
        <div className="title">{t('MY_OPPORTUNITIES.FILTER.FILTER_OPTIONS')}</div>
        <div className="close" id="opportunity-filter-close-button" onClick={toggleControlMenu}>
          <CloseIcon />
        </div>
      </div>
      {isControlMenuExpanded && (
        <>
          <FilterControlConnected />
          <WideButton
            label={applyButtonText}
            onClick={applyMyOpportunitiesFilters}
            id="filter-apply"
            isActive={hasFilterModified}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { isControlMenuExpanded } = state.filters;

  const {
    hasFilterModified,
    matchFilterOpportunities,
    activeTab,
  } = state.filters.myOpportunities.filters;

  return {
    activeTab,
    hasFilterModified,
    isControlMenuExpanded,
    matchFilterOpportunities,
  };
};

const mapDispatchToProps = {
  toggleControlMenu,
  applyMyOpportunitiesFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuControl);
