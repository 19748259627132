import './previewFilters.scss';
import { FC, memo } from 'react';
import cn from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { IPreviewFiltersProps } from './types';
import { Dropdown } from 'components/dropdown';
import {
  direction,
  IDropdownOption,
  IRootState,
  OpenOpportunitiesDropDownOptionsTypes,
  OrderButton,
} from 'types';
import { Tabs } from 'components/tabs';
import { getCardDecoration } from 'components/openOpportunities/helpers';
import OpenOpportunitiesSelector from 'store/openOpportunities/openOpportunitiesSelector';
import {
  selectDropDownOption,
  selectTab,
  toggleSortDirection,
} from 'store/openOpportunities/openOpportunitiesActions';

export const PreviewFilters: FC<IPreviewFiltersProps> = ({
  isRtl,
  onSelectTab,
  tabs,
  selectedTab,
  dropDownOptions,
  selectedDropdownOption,
  onSelectOption,
  sortDirection = direction.ASC,
  onSort,
  drillDownList,
  type,
}) => {
  const [t] = useTranslation();

  const { className } = getCardDecoration(type);

  const isDropdownDisabled = dropDownOptions.length === 1;

  const navigationTabs =
    tabs?.map((tab) => ({
      id: tab.value,
      label: t(tab.label),
    })) || [];

  const selectedTabIndex = navigationTabs.find((tab) => tab.id === selectedTab)?.id || 0;

  const dropdownItems: IDropdownOption[] =
    dropDownOptions?.map((option) => ({
      label: t(`OPEN_OPPORTUNITIES.${option}`),
      key: option,
    })) || [];

  const selectedDropDownItem: IDropdownOption | undefined = dropdownItems?.find(
    (option) => option.key === selectedDropdownOption,
  );

  const onSelectDropdownOption = (item: IDropdownOption) =>
    onSelectOption(item.key as OpenOpportunitiesDropDownOptionsTypes);

  const isEmptyState = () => {
    let result = false;
    if (drillDownList) {
      const { cards, opportunities } = drillDownList;
      result = !cards && !opportunities;
    }

    return result;
  };

  if (isEmptyState()) {
    return null;
  }

  return (
    <div
      className={cn('preview-filters-container', className)}
      data-testid="open-opportunities-preview-filters"
    >
      <div className="tabs-wrapper">
        <Tabs
          id="open-opportunities-filters-tab"
          isRtl={isRtl}
          containerClassName="open-opportunities-filters-tabs-container"
          tabs={navigationTabs}
          activeTab={selectedTabIndex}
          onChange={onSelectTab}
          textClassName="medium-tabs"
        />
      </div>
      <div className="other-filters-wrapper">
        <Dropdown
          options={dropdownItems}
          onChange={onSelectDropdownOption}
          selectedOption={selectedDropDownItem}
          disabled={isDropdownDisabled}
        />
        <OrderButton
          id="sort-open-opportunities"
          label=""
          buttonKey="sort-open-opportunities"
          onClick={onSort}
          sortDirection={sortDirection}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { isRtl } = state.settings;

  const { dropDownOptions, list: drillDownList } = state.openOpportunities.drillDown;

  const tabs = OpenOpportunitiesSelector.getNavigationTabsSelector(state);

  return {
    isRtl,
    tabs,
    dropDownOptions,
    drillDownList,
  };
};

const mapDispatchToProps = {
  onSelectTab: selectTab,
  onSort: toggleSortDirection,
  onSelectOption: selectDropDownOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PreviewFilters));
