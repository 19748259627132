import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cn from 'clsx';

import { ProgressAnimation } from 'components/animation';
import { animationType } from 'components/animation/types';
import FindingsPieChart from 'components/findings/charts/FindingsPieChart';
import PreviewHeader from 'components/findings/previewHeader/PreviewHeader';
import ReasonsFilterGroups from 'components/findings/reasons/ReasonsFilterGroups';
import {
  changeNumberRepresentation,
  clearAllSelection,
  toggleGroupSelection,
  toggleReasonFilter,
} from 'store/findings/findingsActions';
import FindingsSelector from 'store/findings/findingsSelector';
import LoadingSelector from 'store/loading/LoadingSelector';
import { IFindingsPieChartItem, IFindingsPreview, IRootState, PieChartSizeTypes } from 'types';
import ChartEmptyState from 'components/findings/charts/ChartEmptyState';

const FindingsPreview: FC<IFindingsPreview> = ({
  valueType,
  issueFoundTotalCount,
  totalCount,
  changeNumberRepresentation,
  toggleReasonFilter,
  reasonsFilterGroups,
  toggleGroupSelection,
  isLoading,
  clearAllSelection,
  searchText,
  isFiltersApplied,
  isRtl,
}) => {
  const [t] = useTranslation();

  const BuildPieData = () => {
    const chartData: Array<IFindingsPieChartItem> = [];

    reasonsFilterGroups?.reasonsGroups.forEach((reasonFilterGroup) => {
      const { id, value, label: name, color, selectedReasonsCount } = reasonFilterGroup;

      // count all unselected reasons count
      const unselectedReasonsCount = value - selectedReasonsCount;

      // If there is any selected reasons, add as cell
      if (selectedReasonsCount) {
        const selectedReasonsCell = {
          name,
          color,
          id,
          value: selectedReasonsCount,
          isActive: true,
        };
        chartData.push(selectedReasonsCell);
      }

      // If there is any unselected reasons, add as cell
      if (unselectedReasonsCount) {
        const unselectedReasonsCell = {
          name,
          color,
          id,
          value: unselectedReasonsCount,
          isActive: false,

          // Prevent legend duplication
          noLegend: selectedReasonsCount > 0,
        };
        chartData.push(unselectedReasonsCell);
      }
    });

    return chartData;
  };

  if (!reasonsFilterGroups) {
    return <ProgressAnimation animationType={animationType.Spinner} />;
  }

  if (issueFoundTotalCount === 0) {
    return <ChartEmptyState isFiltersApplied={isFiltersApplied} searchText={searchText} />;
  }

  const { noActiveReasons, reasonsGroups } = reasonsFilterGroups;

  return (
    <div className="findings-preview">
      <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
      <PreviewHeader
        onValueTypeChange={changeNumberRepresentation}
        valueType={valueType}
        totalCount={totalCount}
        totalIssueFound={issueFoundTotalCount}
      />
      <div className="findings-preview-reasons">
        <FindingsPieChart
          isRtl={isRtl}
          isAllCellsActive={noActiveReasons}
          totalCount={issueFoundTotalCount}
          data={BuildPieData()}
          onCellClick={toggleGroupSelection}
          sizeType={PieChartSizeTypes.REGULAR}
          canToggleActiveCell
        />
        <div className="reasons-groups-container">
          <div className="header">
            <span className="title">{t('COMMON.FILTER_BY')}</span>
            <span
              className={cn('clear', { 'no-selection': noActiveReasons })}
              onClick={clearAllSelection}
            >
              {t('COMMON.CLEAR_ALL')}
            </span>
          </div>
          <ReasonsFilterGroups
            toggleReasonFilter={toggleReasonFilter}
            reasonsGroups={reasonsGroups}
            valueType={valueType}
            issueFoundTotalCount={issueFoundTotalCount}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { issueFoundTotalCount, reasonFilterGroupsMap, totalCount, valueType } = state.findings;

  const searchText = FindingsSelector.getSearchFilter(state);

  const { isRtl } = state.settings;

  const isFiltersApplied = FindingsSelector.checkIsFiltersApplied(state);

  return {
    isLoading: LoadingSelector.isGetFindingsReasons(state),
    issueFoundTotalCount,
    searchText,
    totalCount,
    valueType,
    reasonFilterGroupsMap,
    isFiltersApplied,
    isRtl,
    reasonsFilterGroups: FindingsSelector.getReasonsFilterGroups(state),
  };
};

const mapDispatchToProps = {
  changeNumberRepresentation,
  toggleReasonFilter,
  toggleGroupSelection,
  clearAllSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(FindingsPreview);
