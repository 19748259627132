import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ICardPrintPageHeader } from 'types';
import { getDateAndTimeFormat, isEmptyArray } from 'utils';

const Header: FC<ICardPrintPageHeader> = ({ totalCount, filterList }) => {
  const [t] = useTranslation();

  const printDate = getDateAndTimeFormat(Date.now());

  return (
    <thead>
      <tr>
        <td>
          <div className="header-text">
            <div className="summary">
              <span>{t('MY_OPPORTUNITIES.PRINT.LIST_OF_TASK', { totalCount })}</span>
              <span className="header-date">
                &nbsp;{t('MY_OPPORTUNITIES.PRINT.PRINTED_ON', { date: printDate })}
              </span>
            </div>
            {!isEmptyArray(filterList) && (
              <div className="filter">
                <span>{t('COMMON.FILTER_BY')}:&nbsp;</span>
                {filterList.toString()}
              </div>
            )}
          </div>
        </td>
      </tr>
    </thead>
  );
};

export default Header;
