import { FC } from 'react';

import SideMenuControlConnected from 'components/layout/sideMenuControl/SideMenuControl';
import { StoneHexagonIcon, StoneTriIcon } from 'components/Icons';
import SideMenuConnected from 'components/layout/SideMenu';
import './Content.scss';
import { IContentProps } from './types';

const Content: FC<IContentProps> = ({ children }) => {
  return (
    <div className="App">
      <div className="spacer" />
      <SideMenuConnected />
      <SideMenuControlConnected />
      <StoneTriIcon />
      <StoneHexagonIcon />
      <div className="content">{children}</div>
      <div className="spacer" />
    </div>
  );
};

export default Content;
