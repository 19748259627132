import { FC } from 'react';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';

import { VirtualizedListProps } from 'types';

const OVER_SCAN = 50;

export const VirtualizedList: FC<VirtualizedListProps> = ({
  loadMore,
  itemContent,
  groupCounts,
  groupContent,
  totalCount,
  containerHeight = '100%',
  id,
}) => {
  const endReached = (index: number) => {
    loadMore();

    // eslint-disable-next-line no-console
    return Promise.resolve(console.log(index));
  };

  if (groupCounts && groupContent) {
    return (
      <GroupedVirtuoso
        className="virtuoso-group-list"
        id={id}
        style={{ height: containerHeight }}
        endReached={endReached}
        overscan={OVER_SCAN}
        groupCounts={groupCounts}
        groupContent={groupContent}
        itemContent={itemContent}
      />
    );
  }

  return (
    <Virtuoso
      className="virtuoso-list"
      id={id}
      endReached={endReached}
      style={{ height: containerHeight }}
      overscan={OVER_SCAN}
      totalCount={totalCount}
      itemContent={itemContent}
    />
  );
};
