import {
  direction,
  IDrillDownHierarchy,
  IOpportunitiesModel,
  OpenOpportunitiesCardTypes,
} from 'types';

export enum OpenOpportunitiesApiStoreResponseTypes {
  NO_RESPONSE = 'NO_RESPONSE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  NO_TASKS = 'NO_TASKS',
}

export type StoreBreakdownResponse = {
  count: number | null;
  status: OpenOpportunitiesCardTypes;
};

export type OpenOpportunitiesRowApi = { count: number; week: string; state: string };

export type OpportunitiesCounter = {
  open: number;
  total: number;
  week: number;
};

export interface IOpenOpportunitiesDrillDownCard {
  level: string;
  value: number;
  open?: number;
  total?: number;
  storeId?: string;
}

export interface IOpenOpportunitiesDrillDownFilters {
  searchValue: string;
  sortDirection: direction;
  hierarchyIndex: number;
  selectedDropdownOption: OpenOpportunitiesDropDownOptionsTypes;
  selectedTab: number;
}

export interface IOpenOpportunitiesDrillDownList {
  cards: IOpenOpportunitiesDrillDownCard[] | null | undefined;
  opportunities: IOpportunitiesModel | null | undefined;
  totalCount: number;
  highestValue: number;
  hasMore: boolean;
  nextPageOffset: number;
}

export interface IOpenOpportunitiesDrillDown {
  hierarchyPath: IDrillDownHierarchy[];
  hierarchyDepth: number;
  topHierarchy: { index: number; id: string };
  availableStoreHierarchyLevels: { [key: string]: string };
  list: IOpenOpportunitiesDrillDownList;
  navigationTabs: { [key: string]: number };
  dropDownOptions: OpenOpportunitiesDropDownOptionsTypes[];
  filters: IOpenOpportunitiesDrillDownFilters;
  totalFindingsByReasons: number;
}

export interface IOpenOpportunitiesModel {
  opportunitiesCountByWeek: OpportunitiesCounter[] | null;
  selectedWeeks: [number, number];
  storeBreakDown: StoreBreakdownResponse[];
  selectedCard: OpenOpportunitiesCardTypes;
  drillDown: IOpenOpportunitiesDrillDown;
}

export enum OpenOpportunitiesDropDownOptionsTypes {
  NUMBER_OF_STORES = 'NUMBER_OF_STORES',
  INVESTIGATED_OPPORTUNITIES = 'INVESTIGATED_OPPORTUNITIES',
  RESPONSE_RATE = 'RESPONSE_RATE',
  OPEN_OPPORTUNITIES = 'OPEN_OPPORTUNITIES',
  RECEIVED_DATE = 'RECEIVED_DATE',
}

export enum OpenOpportunitiesDrillDownTabsTypes {
  BY_STORE = 1,
  BY_OPPORTUNITY = 2,
  OTHER = 0,
}

export interface IOpenOpportunitiesTab {
  key: OpenOpportunitiesDrillDownTabsTypes;
  selectedDropdownOption: OpenOpportunitiesDropDownOptionsTypes;
  hierarchyLevel: number;
}

export const mapDropdownOptionsToOrderFieldName = {
  [OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE]: 'responseRate',
  [OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES]: 'openedTasks',
  [OpenOpportunitiesDropDownOptionsTypes.INVESTIGATED_OPPORTUNITIES]: 'closedTasks',
  [OpenOpportunitiesDropDownOptionsTypes.RECEIVED_DATE]: 'deployDate',
  [OpenOpportunitiesDropDownOptionsTypes.NUMBER_OF_STORES]: 'storeCount',
};

export const mapTabsToTextValue = {
  [OpenOpportunitiesDrillDownTabsTypes.OTHER]: 'COMMON.ALL',
  [OpenOpportunitiesDrillDownTabsTypes.BY_STORE]: 'OPEN_OPPORTUNITIES.BY_STORE',
  [OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY]: 'OPEN_OPPORTUNITIES.BY_OPPORTUNITY',
};
