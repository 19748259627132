import { FC } from 'react';

import { IFilterButton } from 'components/buttons/filterButton/types';
import { CloseIcon } from 'components/Icons';
import './filterButton.scss';

export const FilterButton: FC<IFilterButton> = ({ label, id, onRemoveFilter }) => {
  return (
    <div data-testid="filter-button" className="filter-container">
      <span>{label}</span>
      <CloseIcon title="remove-icon" onClick={() => onRemoveFilter(id)} />
    </div>
  );
};
