import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ActionButton, WideButton } from 'components/buttons';
import { ProgressAnimation } from 'components/animation';
import { animationType } from 'components/animation/types';
import './loading.scss';
import { ILoading } from 'components/pages/loading/types';
import { RoutePath } from 'routes/types';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';
import permissionsService from 'services/permissions/permissionsSrv';
import routerService from 'services/router/routerService';
import UserProfileSelector from 'store/userProfile/userProfileSelector';

export const Loading: FC<ILoading> = ({ withRouteFallback = false }) => {
  const [t] = useTranslation();

  const [secondsToRetry, setSecondsToRetry] = useState(20);

  const isScopeError = useSelector(UserProfileSelector.isScopeError);

  const reloadApp = () => {
    // document.location.href = 'index.html';
    window.location.reload();
  };

  useEffect(() => {
    if (withRouteFallback) {
      // the default route is contactUs
      let fallbackRoute = RoutePath.ContactUs;

      if (permissionsService.mayViewDashboards()) {
        fallbackRoute = RoutePath.Summary;
      } else if (permissionsService.mayViewOpportunities()) {
        fallbackRoute = RoutePath.MyOpportunities;
      }
      routerService.navigateToPage(fallbackRoute);
    }
  }, [withRouteFallback]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (isScopeError) {
      intervalId = setInterval(() => {
        setSecondsToRetry((secondsToRetry) => secondsToRetry - 1);
      }, 1000);
      AnalyticSrv.sendClickEvent(AnalyticsEventCategory.LOGIN_FLOW, {
        status: 'User Scope not ready',
      });
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isScopeError]);

  useEffect(() => {
    if (secondsToRetry === 0) {
      reloadApp();
    }
  }, [secondsToRetry]);

  const renderLoadingIndicator = () => (
    <div id="login-loader">
      <img className="loading" alt="loading" src="/login-loader.gif" />
    </div>
  );

  const renderRetryProgress = () => (
    <div id="loading-progress" className="loading">
      <div className="loading-message">{t(`COMMON.LOGIN_ERROR_MESSAGE`)}</div>
      <div className="loading-countdown">
        {t(`COMMON.LOGIN_RECONNECTING`, {
          seconds: secondsToRetry,
        })}
      </div>
      <div className="progress-bar">
        <ProgressAnimation
          animationType={animationType.ProgressBar}
          animationStyle={{
            width: '80%',
            height: 'auto',
          }}
        />
      </div>
      <WideButton
        id="loading-logout"
        isActive
        label={t('COMMON.LOGOUT')}
        onClick={() => routerService.routeToLogout()}
      />
      <div className="contact-us">
        <span className="need-support">{t('CONTACT_US.NEED_SUPPORT')}</span>
        <ActionButton
          text={t('CONTACT_US.CLICK_TO_CONTACT')}
          onClick={() => routerService.routeToContactUs()}
        />
      </div>
    </div>
  );

  return (
    <div className="loading-container">
      {isScopeError ? renderRetryProgress() : renderLoadingIndicator()}
    </div>
  );
};
