export interface ISummaryCard {
  cardId: SummaryCardIds;
  cardType: string;
  headerText: string;
  mainText: string;
  arrowIconDirection?: ArrowIconDirection | undefined;
  subText?: string;
  footerInfoText: string;
  onSeeDetailsClick: () => void;
  showRatePopup?: (cardId: SummaryCardIds) => void;
  isLoading?: boolean;
  shouldDisplayAction?: boolean;
}

export enum SummaryCardIds {
  OPEN_OPPORTUNITIES = 'open-opportunities',
  RESPONSE_RATE = 'response-rate',
  HIT_RATE = 'hit-rate',
  SALES_INCREASE = 'sales-increase',
  TOTAL_OPPORTUNITIES_CAPTURED = 'total-opportunities-captured',
}

export enum SummaryCardTypes {
  OPEN_OPPORTUNITIES = 'open-opportunities',
  RATE = 'rate',
  KPI = 'kpi',
}

export enum ArrowIconDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface ISummaryTopFindingsItem {
  value?: string;
  name: string;
  count: number | string;
  totalCount?: number;
  rate: number;
}

export interface ISummaryTopThreeFindings {
  list: Array<ISummaryTopFindingsItem>;
  title: string;
  isLoading: boolean;
  emptyStateLabel: string;
  onSeeDetailsClick(): void;
}

export interface ILeaderBoardRow {
  rank: number;
  name: string;
  storeId?: string;
  hitRate: number;
  issueFoundCount: number;
  isSelected?: boolean;
}

export interface ILeaderBoard {
  leaderBoardTopData: Array<ILeaderBoardRow>;
  leaderBoardBottomData: Array<ILeaderBoardRow>;
  emptyStateLabel?: string;
  isLoading?: boolean;
}

export const LEADER_BOARD_LIST_SIZE = {
  MAXIMUM: 6,
  REGULAR: 3,
};

export interface IPeersSwitchProps {
  hasPeers: boolean | undefined;
  onChange: (isPeersSelected: boolean) => void;
  peersLabel?: string;
  isPeersSelected: boolean;
}

export interface IRateComparisonCard {
  tabId: number;
  rate: number;
  peersLevel: string;
  onSeeDetailsClick: () => void;
}
