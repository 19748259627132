import { FC } from 'react';
import { connect } from 'react-redux';

import Modal from 'components/common/Modal/Modal';
import { IRootState, PrintOption, IPrint } from 'types';
import PrintActions from 'store/print/printActions';
import MyOpportunitiesPrintPage from 'components/myOpportunities/print/Print';

const Print: FC<IPrint> = ({ hide, id }) => {
  if (id === null) {
    return null;
  }

  return (
    <Modal
      withOverlay={false}
      onEsc={hide}
      isPrintMode
      onClickOutside={() => {}}
      onKeydown={() => {}}
    >
      {id === PrintOption.myOpportunities && <MyOpportunitiesPrintPage />}
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  id: state.print.id,
});

const mapDispatchToProps = {
  hide: PrintActions.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(Print);
