import './progressLine.scss';
import { FC } from 'react';
import cn from 'clsx';

import { PERCENTAGE_SYMBOL } from 'constants/constantsVaribles';

type ProgressLineProps = {
  total?: number;
  value: number;
  className?: string;
};

const ProgressLine: FC<ProgressLineProps> = ({ value, total, className }) => {
  const getProgressLine = () => {
    if (total === undefined) {
      return value;
    }
    if (total === 0) {
      return 100;
    }

    return (value * 100) / total;
  };

  return (
    <div
      data-testid="progress-line-wrapper"
      className={cn('progress-line-wrapper', className)}
      style={{ width: `${getProgressLine()}${PERCENTAGE_SYMBOL}` }}
    />
  );
};

export default ProgressLine;
