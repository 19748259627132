export enum PrintOption {
  myOpportunities = 'myOpportunities',
}

export interface ISelectedPrintingOption {
  PrintReasons: boolean;
  PrintImage: boolean;
}

export type IPrintModel = {
  id: PrintOption | null;
  selectedPrintingOption: ISelectedPrintingOption;
};
