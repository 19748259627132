import sideMenuActionTypes from 'store/sideMenu/sideMenuActionTypes';

export const selectActionId = (selectedActionMenuId: string) => ({
  type: sideMenuActionTypes.SelectActionId,
  payload: { selectedActionMenuId },
});

export const toggleMenuCollapseExpand = () => ({
  type: sideMenuActionTypes.ToggleMenuCollapseExpand,
  payload: {},
});

export const toggleMenuHideExpand = () => ({
  type: sideMenuActionTypes.ToggleMenuHideExpand,
  payload: {},
});

export const collapseSideMenu = () => ({
  type: sideMenuActionTypes.CollapseSideMenu,
  payload: {},
});

export const hideSideMenu = () => ({
  type: sideMenuActionTypes.HideSideMenu,
  payload: {},
});
