import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import { getCurrentDate, isEmptyArray, toLanguageNumberFormat } from 'utils';
import FindingsPieChart from 'components/findings/charts/FindingsPieChart';
import {
  FindingsPieChartLocation,
  IFindingsPieChartItem,
  IReasonFilterGroup,
  PieChartSizeTypes,
} from 'components/findings/types';
import { SummaryTopThreeFindings } from 'components/summary/SummaryTopThreeFindings';
import {
  animationType,
  DrillDownCategoriesTypes,
  DrillDownTypes,
  IDate,
  ISearchParamsFilters,
  ITopFindings,
} from 'types';
import LoadingSelector from 'store/loading/LoadingSelector';
import SummarySelector from 'store/summary/summarySelector';
import { IRootState } from 'store/types';
import {
  getDrillDown,
  getFindingsReasons,
  toggleGroupSelection,
} from 'store/summary/summaryActions';
import 'components/pages/summary/summary.scss';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';
import { ProgressAnimation } from 'components/animation';
import RouterService from 'services/router/routerService';
import { getStoresCategory } from 'store/filters/filterHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const TopFindingsWrapper: FC<ITopFindings> = ({
  isGetFindingsReasonsLoading,
  topFindingsDateFilter,
  activeReasonFilters,
  issueFoundTotalCount,
  otherDrillDown,
  toggleGroupSelection,
  getDrillDown,
  getFindingsReasons,
  reasonsList,
  reasonsFilterGroups,
  selectedStoreFilter,
  isRtl,
  selectedCategory,
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (!isEmptyArray(activeReasonFilters)) {
      getDrillDown(DrillDownTypes.Other, otherDrillDown?.selectedCategory);
    }
  }, [activeReasonFilters, selectedStoreFilter]);

  useEffect(() => {
    if (reasonsList) {
      getFindingsReasons(topFindingsDateFilter, reasonsList, selectedStoreFilter?.storeName);
    }
  }, [topFindingsDateFilter, reasonsList, selectedStoreFilter]);

  const handleRouteToFindings = () => {
    const customDate: IDate = {
      type: topFindingsDateFilter,
      from: getCurrentDate(),
      to: getCurrentDate(),
    };

    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date: customDate,
      [DrillDownTypes.Other]: {
        category: selectedStoreFilter ? getStoresCategory() : selectedCategory,
        filter: {
          value: selectedStoreFilter?.id || '',
          name: selectedStoreFilter?.storeName || '',
          hierarchyName: '',
        },
      },
    };

    AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_FINDINGS);

    const queryParams = buildQueryParamsFromSearchFilters(searchFilters);
    RouterService.routeToFindings(queryParams);
  };

  const getTopThreeFindingsList = () => {
    if (
      selectedStoreFilter &&
      otherDrillDown?.selectedCategory?.value === DrillDownCategoriesTypes.Store
    ) {
      return [];
    }

    return otherDrillDown?.list || [];
  };

  const BuildPieData = () => {
    const chartData: Array<IFindingsPieChartItem> = [];

    reasonsFilterGroups?.reasonsGroups.forEach((reasonFilterGroup: IReasonFilterGroup) => {
      const { id, value, label: name, color, selectedReasonsCount } = reasonFilterGroup;

      // count all unselected reasons count
      const unselectedReasonsCount = value - selectedReasonsCount;

      // If there is any selected reasons, add as cell
      if (selectedReasonsCount) {
        const selectedReasonsCell = {
          name,
          color,
          id,
          value: selectedReasonsCount,
          isActive: true,
        };
        chartData.push(selectedReasonsCell);
      }

      // If there is any unselected reasons, add as cell
      if (unselectedReasonsCount) {
        const unselectedReasonsCell = {
          name,
          color,
          id,
          value: unselectedReasonsCount,
          isActive: false,

          // Prevent legend duplication
          noLegend: selectedReasonsCount > 0,
        };
        chartData.push(unselectedReasonsCell);
      }
    });

    return chartData;
  };

  return (
    <>
      <div className="top-findings-chart">
        {isGetFindingsReasonsLoading && <ProgressAnimation animationType={animationType.Spinner} />}
        <div className="chart-total">
          <span className={cn('header')}>{t('COMMON.TOTAL')}</span>
          <h2 className="total-number">
            {issueFoundTotalCount && toLanguageNumberFormat(issueFoundTotalCount)}
          </h2>
        </div>

        <FindingsPieChart
          isRtl={isRtl}
          isAllCellsActive={false}
          location={FindingsPieChartLocation.Summary}
          onCellClick={toggleGroupSelection}
          totalCount={issueFoundTotalCount}
          data={BuildPieData()}
          sizeType={PieChartSizeTypes.SMALL}
          emptyStateLabel={t('SUMMARY.PIE_CHART_EMPTY_STATE')}
          className="summary-findings-chart"
          canToggleActiveCell={false}
        />
      </div>
      <SummaryTopThreeFindings
        title={otherDrillDown?.selectedCategory?.label || ''}
        list={getTopThreeFindingsList()}
        isLoading={otherDrillDown?.isLoading || false}
        onSeeDetailsClick={handleRouteToFindings}
        emptyStateLabel={t('SUMMARY.TOP_THREE_EMPTY_STATE_LABEL')}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { groups: reasonsList } = state.reasons;

  const { selectedCategory } = state.findings.filters[DrillDownTypes.Other].activeFilters;

  const { issueFoundTotalCount, reasonFilterGroupsMap, selectedStoreFilter } = state.summary;

  const { topFindingsDateFilter, activeReasonFilters } = state.summary.activeFilters;

  const { isRtl } = state.settings;

  const otherDrillDown = SummarySelector.getOtherDrillDown(state);

  const reasonsFilterGroups = SummarySelector.getReasonsFilterGroups(state);

  const isGetFindingsReasonsLoading = LoadingSelector.isGetSummaryFindingsReasons(state);

  return {
    isGetFindingsReasonsLoading,
    issueFoundTotalCount,
    topFindingsDateFilter,
    activeReasonFilters,
    reasonsList,
    otherDrillDown,
    reasonFilterGroupsMap,
    reasonsFilterGroups,
    selectedStoreFilter,
    isRtl,
    selectedCategory,
  };
};

const mapDispatchToProps = { getFindingsReasons, toggleGroupSelection, getDrillDown };

export default connect(mapStateToProps, mapDispatchToProps)(TopFindingsWrapper);
