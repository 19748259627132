import cn from 'clsx';
import { FC } from 'react';

import './emptyState.scss';

import { EmptyStateAnimation } from 'components/animation';
import { EmptyStateWithAnimationProps } from './types';
import { emptyStateAnimationType, mapEmptyStateAnimation } from 'components/animation/types';

export const EmptyStateWithAnimation: FC<EmptyStateWithAnimationProps> = ({
  title,
  footerTitle,
  footerLink,
  subTitle,
  animationType,
  onClickFooterLink,
  dataTestId,
}) => {
  return (
    <div className="empty-state-container" data-testid={dataTestId}>
      {title && (
        <span
          style={{ animation: `delayedFadeIn ${mapEmptyStateAnimation[animationType].duration}s` }}
          className={cn('empty-state-title with-animation', 'dark')}
        >
          {title}
        </span>
      )}
      {animationType && (
        <EmptyStateAnimation
          dataTestId="empty-state-animation-type"
          animationType={animationType}
        />
      )}
      {subTitle && (
        <span
          style={{
            animation: `delayedFadeIn ${
              mapEmptyStateAnimation[animationType].duration +
              (animationType === emptyStateAnimationType.Stars ? 1 : 0)
            }s`,
          }}
          className={cn('empty-state-text with-animation', 'light')}
        >
          {subTitle}
        </span>
      )}
      {footerTitle && (
        <div className="empty-state-footer">
          <span className={cn('empty-state-footer-title', 'light')}>{footerTitle}</span>
          {footerLink && (
            <span className="empty-state-text clickable" onClick={onClickFooterLink}>
              {footerLink}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
