import { set } from 'lodash/fp';
import { Action } from 'history';

import { IMPACT_SALES_WEEKS_TO_SHOW_POST_DEPLOY } from 'store/myOpportunities/types';
import { deepLinkOpportunityId } from 'components/pages/myOpportunities/types';
import { FilterBuilder } from 'store/filters/filterBuilder';
import FiltersActionTypes from 'store/filters/filtersActionTypes';
import { addCategoryFilter } from 'store/myOpportunities/myOpportunitiesActions';
import { MILLISECONDS, SIZE } from 'constants/constantsVaribles';
import {
  categoryLabelsMapper,
  categoryName,
  DefaultGroupByOption,
  direction,
  fieldName,
  filterKey,
  filterValue,
  groupBy,
  ICategoriesLabels,
  IDate,
  IFilterCategory,
  IFilterCategoryLabels,
  IGroupByOption,
  IGroupBySource,
  IMyOpportunityFilters,
  IResolvedLegacySearchParams,
  IResolvedSearchParams,
  ISearchParamsFilters,
  mapGroupByToTranslationKey,
  mapGroupSourceToFilterKey,
  mapOrderByToFieldName,
  mapQueryParamsToDateIntervals,
  orderBy,
  TaskLifeCycleValue,
} from './types';
import {
  DrillDownCategoriesTypes,
  DrillDownTypes,
  IDrillDownHierarchy,
  IDrillDownLegacyHierarchy,
  IFilterItem,
  ILegacyOtherDrilDownFilter,
  IOtherSearchDrilldown,
  ISearchDrilldown,
  ISelectedCategory,
  LegacyDrillDownCategoriesTypes,
  parseLegacyDrillDownCategoryToCategory,
} from 'components/drilldown/types';
import { QueryParamTypes, RoutePath } from 'routes/types';
import { getSearchParams, resolveLocationChangeParams } from 'utils/NavigationUtils';
import { HierarchyLevel, mapHierarchyLevelToOrder } from 'store/userProfile/types';
import { getCurrentDate, isEmptyArray } from 'utils';
import i18n from 'i18n/i18n';
import { parseURLONQueryString } from 'utils/UrlMappingUtils';

export const getResponseImpactFilter = (
  filterBuilder: FilterBuilder,
  showResponseImpactOnSales: boolean,
  activeTab: filterValue,
) => {
  filterBuilder.and().term(filterKey.STATE, activeTab);

  if (activeTab === filterValue.CLOSED && showResponseImpactOnSales) {
    filterBuilder
      .and()
      .relative(filterKey.DEPLOY_DATE, MILLISECONDS.WEEK * IMPACT_SALES_WEEKS_TO_SHOW_POST_DEPLOY);
  } else {
    filterBuilder.and().term(filterKey.LIFECYCLE, TaskLifeCycleValue.ACTIVE);
  }

  return filterBuilder;
};

export const getLabelBaseFilter = (
  fieldNameValue: categoryName,
  opportunityStateValue: filterValue,
  showResponseImpactOnSales: boolean,
) => {
  const filterBuilder = new FilterBuilder();

  getResponseImpactFilter(filterBuilder, showResponseImpactOnSales, opportunityStateValue);

  const filterOutput = filterBuilder.build();

  return {
    filter: filterOutput,
    orders: [
      {
        fieldName: fieldNameValue,
        direction: 'ASC',
      },
    ],
    page: { from: 0, size: 500 },
  };
};

export const getCaseCategory = (type: string) => {
  let caseCategoryMapper: categoryLabelsMapper;
  let caseCategoryName: categoryName;
  if (type === FiltersActionTypes.getStoreFilterLabels.SUCCESS) {
    caseCategoryMapper = categoryLabelsMapper.STORE_CATEGORY;
    caseCategoryName = categoryName.STORE_CATEGORY;
  } else if (type === FiltersActionTypes.getPlanogramFilterLabels.SUCCESS) {
    caseCategoryMapper = categoryLabelsMapper.PLANOGRAM_ATTRIBUTES;
    caseCategoryName = categoryName.PLANOGRAM_ATTRIBUTES;
  } else {
    caseCategoryMapper =
      type === FiltersActionTypes.getProductFilterLabels.SUCCESS
        ? categoryLabelsMapper.PRODUCT_CATEGORY
        : categoryLabelsMapper.STORE_NAME;
    caseCategoryName =
      type === FiltersActionTypes.getProductFilterLabels.SUCCESS
        ? categoryName.PRODUCT_CATEGORY
        : categoryName.STORE_NAME;
  }

  return { caseCategoryMapper, caseCategoryName };
};

export const getFilterOrdersForNonDefaultGroupBy = (
  order: orderBy,
  dir: direction,
  groupKey: string,
) => {
  const orders = [
    {
      fieldName: groupKey,
      direction: direction.DESC,
    },
    {
      fieldName: mapOrderByToFieldName[order],
      direction: dir,
    },
    {
      fieldName: fieldName.storeName,
      direction: direction.ASC,
    },
    order !== orderBy.productName && {
      fieldName: fieldName.productName,
      direction: direction.ASC,
    },
    order !== orderBy.salesTarget && {
      fieldName: fieldName.targetSales,
      direction: direction.DESC,
    },
  ].filter(Boolean);

  return orders;
};

export const OrderAndOrderByKey = {
  [groupBy.receivedWeek]: {
    [orderBy.salesTarget]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.deployDate,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.deployDate,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
      ],
    },
    [orderBy.receivedDate]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.deployDate,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          direction: direction.DESC,
          fieldName: fieldName.targetSales,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.deployDate,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          direction: direction.DESC,
          fieldName: fieldName.targetSales,
        },
      ],
    },
    [orderBy.productName]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.deployDate,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.deployDate,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
    },
  },
  [groupBy.opportunityType]: {
    [orderBy.salesTarget]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.action,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.action,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
      ],
    },
    [orderBy.receivedDate]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.action,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.deployDate,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.action,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.deployDate,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
    },
    [orderBy.productName]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.action,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.action,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
    },
  },
  [groupBy.storeName]: {
    [orderBy.salesTarget]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.storeName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.storeName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
      ],
    },
    [orderBy.receivedDate]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.storeName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.deployDate,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.storeName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.deployDate,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
    },
    [orderBy.productName]: {
      [direction.DESC]: [
        {
          fieldName: fieldName.storeName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
      [direction.ASC]: [
        {
          fieldName: fieldName.storeName,
          direction: direction.DESC,
        },
        {
          fieldName: fieldName.productName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.storeName,
          direction: direction.ASC,
        },
        {
          fieldName: fieldName.targetSales,
          direction: direction.DESC,
        },
      ],
    },
  },
};

export const getBaseOrders: any = (
  group = DefaultGroupByOption,
  order = orderBy.salesTarget,
  dir = direction.DESC,
) => {
  let orders;
  if (group.source === IGroupBySource.DEFAULT) {
    orders = OrderAndOrderByKey[group.key][order][dir];
  } else {
    orders = getFilterOrdersForNonDefaultGroupBy(order, dir, group.key);
  }

  return orders;
};

export const buildSingleOpportunityRequestFilter = (
  deepLinkOpportunityId: deepLinkOpportunityId,
) => {
  const filterBuilder = new FilterBuilder();

  filterBuilder.term(filterKey.ID, deepLinkOpportunityId);

  return filterBuilder.build();
};

export const buildRequestFilter = (
  activeFilter: IMyOpportunityFilters,
  showResponseImpactOnSales: boolean,
) => {
  const { activeTab, productCategory, storeCategory, storeName, planogram, search } = activeFilter;

  const filterBuilder = new FilterBuilder();

  getResponseImpactFilter(filterBuilder, showResponseImpactOnSales, activeTab);

  if (search !== '') {
    filterBuilder.freeText(search.toLowerCase());
  }

  addCategoryFilter(filterBuilder, productCategory, 'productCategories');
  addCategoryFilter(filterBuilder, storeCategory, 'storeCategories');
  addCategoryFilter(filterBuilder, storeName, 'storeName');
  addCategoryFilter(filterBuilder, planogram, 'planogramAttributes');

  return filterBuilder.build();
};

export const buildRequestFilterBody = (
  activeFilter: IMyOpportunityFilters,
  deepLinkOpportunityId: deepLinkOpportunityId,
  showResponseImpactOnSales: boolean,
) => {
  const { nextPageOffset, groupBy, orderBy, direction } = activeFilter;

  let filter = buildRequestFilter(activeFilter, showResponseImpactOnSales);

  if (deepLinkOpportunityId) {
    filter = buildSingleOpportunityRequestFilter(deepLinkOpportunityId);
  }

  const orders = getBaseOrders(groupBy, orderBy, direction);

  return {
    filter,
    orders,
    page: {
      from: nextPageOffset,
      size: SIZE,
    },
  };
};

// the method modifies the activeFilter list and returns only the selected item list if needed
export const pushSelectedLabelsListByCategory = (
  newFilterState: Array<typeof set>,
  categoriesLevels: Array<number>,
  categoriesMap: { [key: number]: ICategoriesLabels },
  categoryName: categoryName,
) => {
  let selectedList: Array<string> = [];

  const categoryList: IFilterCategoryLabels = [];

  categoriesLevels.forEach((level) => {
    if (categoriesMap[level]?.selectedLabels.length > 0) {
      selectedList = [...categoriesMap[level].selectedLabels];
      categoryList[level] = { selectedLabels: selectedList };

      newFilterState.push(
        set(
          `myOpportunities[activeFilter][${categoryName}][${level}].selectedLabels`,
          selectedList,
        ),
      );
    } else {
      newFilterState.push(
        set(`myOpportunities[activeFilter][${categoryName}][${level}].selectedLabels`, []),
      );
    }
  });

  return categoryList;
};

// the method clears the filterLabelsToClear filter (clears UI's filter state)
export const clearFilterByCategory = (
  filterLabelsToClear: Array<typeof set>,
  categoriesLevels: Array<number>,
  categoriesMap: { [key: number]: ICategoriesLabels },
  categoryLabelsMapper: categoryLabelsMapper,
) => {
  categoriesLevels.forEach((level) => {
    if (categoriesMap[level]?.selectedLabels?.length > 0) {
      filterLabelsToClear.push(
        set(`myOpportunities[filters][${categoryLabelsMapper}][${level}][selectedLabels]`, []),
      );

      const categorySelectedLabels = categoriesMap[level]?.selectedLabels;

      let encodedLabel = null;
      for (let j = 0; j < categorySelectedLabels.length; j++) {
        encodedLabel = encodeURI(categorySelectedLabels[j]);
        filterLabelsToClear.push(
          set(
            `myOpportunities[filters][${categoryLabelsMapper}][${level}][labelsMap][${encodedLabel}].isSelected]`,
            false,
          ),
        );
      }
    }
  });
};

export const getStoresCategory = (): ISelectedCategory => {
  return {
    value: DrillDownCategoriesTypes.Store,
    type: DrillDownCategoriesTypes.Store,
    label: i18n.t('COMMON.STORES'),
  };
};

export const buildDrilldownFilterFromQuery = (
  filter: IDrillDownHierarchy,
  drilldownType: DrillDownTypes,
  category?: ISelectedCategory,
) => {
  const filterItem: IFilterItem = {
    drillDownType: drilldownType,
    hierarchyName: filter.hierarchyName || category?.label || '',
    name: filter.name,
    value: filter.value || filter.name,
  };

  if (category && drilldownType === DrillDownTypes.Other) {
    filterItem.categoryType = category.type;
    filterItem.hierarchyValue = category.value;
  }

  return filterItem;
};

const buildHierarchyPathFromSearchParams = (
  searchParams: URLSearchParams,
  drillDownType: DrillDownTypes,
) => {
  let hierarchyIndex = 1;
  let hierarchyLevelCounter = 1;

  const hierarchyPath: IDrillDownHierarchy[] = [];

  const maxHierarchy = mapHierarchyLevelToOrder[HierarchyLevel.LEAF];

  while (hierarchyLevelCounter <= maxHierarchy) {
    // get storeLevel/productLevel
    const level = searchParams.get(
      (drillDownType === DrillDownTypes.Store
        ? QueryParamTypes.StoreLevel
        : QueryParamTypes.ProductLevel) + hierarchyLevelCounter,
    );

    // get storeLevelId/productLevelId
    const id = searchParams.get(
      (drillDownType === DrillDownTypes.Store
        ? QueryParamTypes.StoreLevelId
        : QueryParamTypes.ProductLevelId) + hierarchyLevelCounter,
    );

    // get storeLevelName/productLevelName
    const name = searchParams.get(
      (drillDownType === DrillDownTypes.Store
        ? QueryParamTypes.StoreLevelName
        : QueryParamTypes.ProductLevelName) + hierarchyLevelCounter,
    );

    // if not null add params to hierarchy path
    if (level && name) {
      hierarchyIndex = hierarchyLevelCounter;
      hierarchyPath.push({
        name,
        value: id || name,
        hierarchyName: level,
        hierarchyLevel: hierarchyIndex,
      });
    }

    hierarchyLevelCounter += 1;
  }

  return hierarchyPath;
};

const parseSearchParamsCategory = (
  searchParams: URLSearchParams,
): ISelectedCategory | undefined => {
  const categoryType = searchParams.get(QueryParamTypes.CategoryType);

  const categoryLabel = searchParams.get(QueryParamTypes.CategoryLabel);

  const categoryValue = searchParams.get(QueryParamTypes.CategoryValue);

  if (categoryType && categoryValue) {
    return {
      value: categoryValue,
      type: categoryType as DrillDownCategoriesTypes,
      label: categoryLabel || categoryValue,
    };
  }

  return undefined;
};

const parseOtherDrilldownFilterFromSearchParams = (
  searchParams: URLSearchParams,
  category: ISelectedCategory,
): IDrillDownHierarchy | undefined => {
  const otherDrilldownFilterId = searchParams.get(QueryParamTypes.OID);

  const otherDrilldownFilterName = searchParams.get(QueryParamTypes.OtherLevelName);

  let filter: IDrillDownHierarchy | undefined;

  if (otherDrilldownFilterId) {
    filter = {
      value: otherDrilldownFilterId,
      name: otherDrilldownFilterName || otherDrilldownFilterId,
      hierarchyName: category?.label || '',
    };
  }

  return filter;
};

const buildDrilldownsFromResolvedSearchParams = (
  storeHierarchy: IDrillDownHierarchy[] = [],
  storeOrder = direction.DESC,
  productHierarchy: IDrillDownHierarchy[] = [],
  productOrder = direction.DESC,
  otherOrder = direction.DESC,
  category?: ISelectedCategory,
  otherHierarchy?: IDrillDownHierarchy,
) => {
  const storeFilter = !isEmptyArray(storeHierarchy)
    ? storeHierarchy[storeHierarchy.length - 1]
    : undefined;

  const storeHierarchyIndex = storeFilter ? (storeFilter?.hierarchyLevel || 0) + 1 : 1;

  const productFilter = !isEmptyArray(productHierarchy)
    ? productHierarchy[productHierarchy.length - 1]
    : undefined;

  const productHierarchyIndex = productFilter ? (productFilter?.hierarchyLevel || 0) + 1 : 1;

  const storeDrilldown: ISearchDrilldown = {
    filter: storeFilter,
    hierarchyPath: storeHierarchy,
    hierarchyIndex: storeHierarchyIndex,
    order: storeOrder,
  };

  const productDrilldown: ISearchDrilldown = {
    filter: productFilter,
    hierarchyPath: productHierarchy,
    hierarchyIndex: productHierarchyIndex,
    order: productOrder,
  };

  const otherDrilldown: IOtherSearchDrilldown = {
    filter: otherHierarchy,
    category,
    order: otherOrder,
  };

  return {
    storeDrilldown,
    productDrilldown,
    otherDrilldown,
  };
};

const parseLegacyOtherDrillDownFilter = (
  categoryId: string,
  legacyOtherFilter?: ILegacyOtherDrilDownFilter,
): IDrillDownHierarchy | undefined => {
  let filter: IDrillDownHierarchy | undefined;

  if (legacyOtherFilter) {
    filter = {
      name: legacyOtherFilter[categoryId][0],
      value: legacyOtherFilter[categoryId][0], // to change
      hierarchyName: legacyOtherFilter[categoryId][0], // to change
    };
  }

  return filter;
};

const parseLegacyHierarchyPath = (legacyPath: IDrillDownLegacyHierarchy[] = []) => {
  const updatedHierarchyPath = [...legacyPath];

  // remove 0 hierarchy level (ALL)
  if (legacyPath && legacyPath[0].level === HierarchyLevel.ALL) {
    updatedHierarchyPath.shift();
  }

  const hierarchyPath: IDrillDownHierarchy[] =
    updatedHierarchyPath?.map((item) => {
      return {
        name: item?.name || item.id,
        value: item.id,
        hierarchyLevel: mapHierarchyLevelToOrder[item.level],
        hierarchyName: item.id, // to change
      };
    }) || [];

  return hierarchyPath;
};

const parseLegacyCategory = (
  categoryId?: string,
  categoryLabel?: string,
  categoryType?: LegacyDrillDownCategoriesTypes,
): ISelectedCategory | undefined => {
  if (categoryId && categoryType) {
    const updatedCategoryId =
      categoryId === HierarchyLevel.LEAF ? HierarchyLevel.STORES : categoryId;

    return {
      value: updatedCategoryId,
      type: parseLegacyDrillDownCategoryToCategory[categoryType],
      label: categoryLabel || updatedCategoryId,
    };
  }

  return undefined;
};

const resolveLegacyURLSearchParams = (searchParams: IResolvedLegacySearchParams) => {
  const { date, from, to, ol, on, of, oo, oa, so, sp, po, pp, tab } = searchParams;

  const resolvedParams: IResolvedSearchParams = {
    tab,
    dateType: date ? mapQueryParamsToDateIntervals[date] : undefined,
    dateFrom: from,
    dateTo: to,
  };

  // store hierarchy
  if (sp) {
    resolvedParams.storeDrillDownHierarchy = parseLegacyHierarchyPath(sp);
  }

  // product hierarchy
  if (pp) {
    resolvedParams.productDrillDownHierarchy = parseLegacyHierarchyPath(pp);
  }

  // other category & filter
  if (oa && ol) {
    resolvedParams.category = parseLegacyCategory(oa, on, ol);
    resolvedParams.otherDrillDownHierarchy = parseLegacyOtherDrillDownFilter(oa, of);
  }

  // drill downs order
  resolvedParams.storeDrillDownOrder = so ? (so as direction) : direction.DESC;
  resolvedParams.productDrillDownOrder = po ? (po as direction) : direction.DESC;
  resolvedParams.otherDrillDownOrder = oo ? (oo as direction) : direction.DESC;

  return resolvedParams;
};

const resolveLocalURLSearchParams = (searchParams: URLSearchParams) => {
  const date = searchParams.get(QueryParamTypes.Date);

  const from = searchParams.get(QueryParamTypes.From);

  const to = searchParams.get(QueryParamTypes.To);

  const tab = searchParams.get(QueryParamTypes.Tab) || undefined;

  const cardId = searchParams.get(QueryParamTypes.OpId) || undefined;

  const storeOrder = searchParams.get(QueryParamTypes.StoreLevelOrder);

  const productOrder = searchParams.get(QueryParamTypes.ProductLevelOrder);

  const otherOrder = searchParams.get(QueryParamTypes.OtherLevelOrder);

  const resolvedParams: IResolvedSearchParams = { tab, cardId };

  // date
  if (date) {
    resolvedParams.dateType = mapQueryParamsToDateIntervals[date];
  }

  if (from) {
    resolvedParams.dateFrom = Number(from);
  }

  if (to) {
    resolvedParams.dateTo = Number(to);
  }

  // other drill down category & filter
  resolvedParams.category = parseSearchParamsCategory(searchParams);

  if (resolvedParams.category) {
    resolvedParams.otherDrillDownHierarchy = parseOtherDrilldownFilterFromSearchParams(
      searchParams,
      resolvedParams.category,
    );
  }

  // store drill down hierarchy
  const storeHierarchy = buildHierarchyPathFromSearchParams(searchParams, DrillDownTypes.Store);

  if (storeHierarchy) {
    resolvedParams.storeDrillDownHierarchy = storeHierarchy;
  }

  // product drill down hierarchy
  const productHierarchy = buildHierarchyPathFromSearchParams(searchParams, DrillDownTypes.Product);

  if (productHierarchy) {
    resolvedParams.productDrillDownHierarchy = productHierarchy;
  }

  // drill downs order
  resolvedParams.storeDrillDownOrder = storeOrder ? (storeOrder as direction) : direction.DESC;
  resolvedParams.productDrillDownOrder = productOrder
    ? (productOrder as direction)
    : direction.DESC;
  resolvedParams.otherDrillDownOrder = otherOrder ? (otherOrder as direction) : direction.DESC;

  return resolvedParams;
};

const buildSearchFiltersFromResolvedParams = (resolvedParams: IResolvedSearchParams) => {
  const {
    dateType,
    dateFrom,
    dateTo,
    tab,
    cardId,
    productDrillDownHierarchy,
    storeDrillDownHierarchy,
    otherDrillDownHierarchy,
    category,
    storeDrillDownOrder,
    productDrillDownOrder,
    otherDrillDownOrder,
  } = resolvedParams;

  const searchFilters: ISearchParamsFilters = { tab, cardId };

  // set date filter
  if (dateType) {
    const date: IDate = {
      type: dateType,
      from: dateFrom || getCurrentDate(),
      to: dateTo || getCurrentDate(),
    };
    searchFilters.date = date;
  }

  // set drill downs filters
  if (productDrillDownHierarchy && storeDrillDownHierarchy) {
    const {
      storeDrilldown,
      productDrilldown,
      otherDrilldown,
    } = buildDrilldownsFromResolvedSearchParams(
      storeDrillDownHierarchy,
      storeDrillDownOrder,
      productDrillDownHierarchy,
      productDrillDownOrder,
      otherDrillDownOrder,
      category,
      otherDrillDownHierarchy,
    );

    searchFilters[DrillDownTypes.Store] = storeDrilldown;
    searchFilters[DrillDownTypes.Product] = productDrilldown;
    searchFilters[DrillDownTypes.Other] = otherDrilldown;
  }

  return searchFilters;
};

// create search filters from legacy query string
export const getLegacySearchFilters = (query: string) => {
  const legacyParams = parseURLONQueryString(query);

  const resolvedSearchParams: IResolvedSearchParams = resolveLegacyURLSearchParams(legacyParams);

  return buildSearchFiltersFromResolvedParams(resolvedSearchParams);
};

// create search filters from local query string
export const getSearchFilters = (query: string) => {
  const searchParams = getSearchParams(query);

  const resolvedSearchParams = resolveLocalURLSearchParams(searchParams);

  return buildSearchFiltersFromResolvedParams(resolvedSearchParams);
};

export const buildSearchFiltersOnLocationChange = (
  location: any,
  action: Action,
  isFirstRendering: boolean,
  currentPath: RoutePath,
): ISearchParamsFilters | null => {
  const { isDeepLink, path, query, isLegacyDeepLink } = resolveLocationChangeParams(
    location,
    action,
    isFirstRendering,
  );

  if (isDeepLink && path === currentPath && query) {
    let searchFilters: ISearchParamsFilters;

    // parse legacy url
    if (isLegacyDeepLink) {
      searchFilters = getLegacySearchFilters(query);
      // parse local url
    } else {
      searchFilters = getSearchFilters(query);
    }

    return searchFilters;
  }

  return null;
};

export const getFilterCategoriesLevels = (categories: IFilterCategory[]) => {
  return categories.map((category) => category.level);
};

export const buildStatsFilter = (
  productCategories: IFilterCategory[],
  storeCategories: IFilterCategory[],
  storeNamesCategories: IFilterCategory[],
  planogramAttributes: IFilterCategory[],
  showResponseImpactOnSales: boolean,
  activeTab: filterValue,
  categoriesMap: any,
) => {
  const productCategory = pushSelectedLabelsListByCategory(
    [],
    getFilterCategoriesLevels(productCategories),
    categoriesMap.productCategoriesLabels,
    categoryName.PRODUCT_CATEGORY,
  );

  const storeCategory = pushSelectedLabelsListByCategory(
    [],
    getFilterCategoriesLevels(storeCategories),
    categoriesMap.storeCategoriesLabels,
    categoryName.STORE_CATEGORY,
  );

  const storeNamesCategory = pushSelectedLabelsListByCategory(
    [],
    getFilterCategoriesLevels(storeNamesCategories),
    categoriesMap.storeNamesLabels,
    categoryName.STORE_NAME,
  );

  const planogram = pushSelectedLabelsListByCategory(
    [],
    getFilterCategoriesLevels(planogramAttributes),
    categoriesMap.planogramAttributesLabels,
    categoryName.PLANOGRAM_ATTRIBUTES,
  );

  const filterBuilder = new FilterBuilder();

  getResponseImpactFilter(filterBuilder, showResponseImpactOnSales, activeTab);

  addCategoryFilter(filterBuilder, productCategory, 'productCategories');
  addCategoryFilter(filterBuilder, storeCategory, 'storeCategories');
  addCategoryFilter(filterBuilder, storeNamesCategory, 'storeName');
  addCategoryFilter(filterBuilder, planogram, 'planogramAttributes');

  const filterOutput = filterBuilder.build();

  return filterOutput;
};

export const constructGroupByOptions = (groupBySource: IGroupBySource, data?: string[]) => {
  let groupByOptions: IGroupByOption[] = [];

  // construct options from categories / hierarchy
  if (data) {
    groupByOptions = data
      .map((value, level) => {
        if (value !== undefined) {
          return {
            value,
            key: `${mapGroupSourceToFilterKey[groupBySource]}.${level}`,
            source: groupBySource,
          };
        }

        return null;
      })
      .filter((value): value is IGroupByOption => value !== null);

    // add default received week option as the first option
    groupByOptions.unshift({
      value: `MY_OPPORTUNITIES.GROUP.${mapGroupByToTranslationKey[groupBy.receivedWeek]}`,
      key: groupBy.receivedWeek,
      source: IGroupBySource.DEFAULT,
    });

    // construct from default options
  } else {
    groupByOptions = Object.keys(groupBy).map((option) => {
      return {
        value: `MY_OPPORTUNITIES.GROUP.${mapGroupByToTranslationKey[option]}`,
        key: groupBy[option],
        source: groupBySource,
      };
    });
  }

  return groupByOptions;
};
