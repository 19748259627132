import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import KnowledgeCenterActionTypes from 'store/knowledgecenter/knowledgeCenterActionTypes';

// TODO Define filters interface
export const getVideos = (limit: number = 1) => ({
  type: APIConstants.API_REQUEST,
  url: `/unifiedpush-server/rest/database/knowledge_center?limit=${limit}`,
  meta: {
    method: HttpMethod.GET,
  },
  baseAction: KnowledgeCenterActionTypes.getVideos,
});

export const setSelectedVideo = (selectedVideoId: string) => ({
  type: KnowledgeCenterActionTypes.setSelectedVideo,
  payload: { selectedVideoId },
});

const KnowledgeCenterActions = {
  getVideos,
  setSelectedVideo,
};

export default KnowledgeCenterActions;
