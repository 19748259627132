import './categoriesAcordion.scss';
import { ChangeEvent, useCallback } from 'react';
import { connect } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import { CheckboxField } from 'components/checkbox';
import { setFilterSelection } from 'store/filters/filtersActions';
import { IRootState } from 'types';
import { ISubCategoriesAccordionProps } from './types';
import { Accordion } from 'components/accordion';
import { isEmptyArray } from 'utils';

const ROW_HEIGHT = '30';

export const SubCategoriesAccordion = ({
  filters,
  categories,
  setFilterSelection,
  searchText,
  isRtl,
}: ISubCategoriesAccordionProps) => {
  const [t] = useTranslation();

  const renderPanelHeader = useCallback(
    ({ panel, activeKey }: any) => {
      const selectedFilter = filters?.[panel.mapper]?.[panel.index]?.selectedLabels;

      let selectedFilterList = null;
      if (selectedFilter && panel.id.toString() !== activeKey) {
        selectedFilterList = selectedFilter.map((selectedFilter: any) => (
          <span className="label" key={selectedFilter}>
            {selectedFilter}
          </span>
        ));
      }

      const hasSelectedLabels = selectedFilterList && !isEmptyArray(selectedFilterList);

      return (
        <div className="filter-label-header">
          <div className="title">{panel.title}</div>
          {hasSelectedLabels && <div className="labels">{selectedFilterList}</div>}
        </div>
      );
    },
    [filters],
  );

  const onCheckboxClicked = (
    checkedEvent: ChangeEvent<HTMLInputElement>,
    label: string,
    categoryMapper: any,
    index: any,
  ) => {
    const {
      target: { checked },
    } = checkedEvent;

    setFilterSelection(label, checked, categoryMapper, index);
  };

  const renderItemContent = useCallback(
    (index: number, panel: any, categoryLabels: any) => {
      const categoryLabel = filters?.[panel.mapper]?.[panel.index];

      const filterLabel = categoryLabels[index];

      const filter = categoryLabel.labelsMap[filterLabel];

      return (
        <CheckboxField
          id={`filter-checkbox-${panel.mapper}-${index}`}
          onChange={(e) => onCheckboxClicked(e, filter.label, panel.mapper, panel.index)}
          isChecked={filter?.isSelected}
          label={filter?.label}
          count={`(${filter?.count})`}
          key={filter?.label}
          height={ROW_HEIGHT}
        />
      );
    },
    [filters],
  );

  const renderPanelContent = useCallback(
    (panel: any) => {
      const categoryLabel = filters?.[panel?.mapper]?.[panel?.index];

      let categoryLabels = categoryLabel?.labels;

      if (!categoryLabels) {
        return <div />;
      }

      if (searchText) {
        categoryLabels = categoryLabels.filter((label: any) => {
          return (
            categoryLabel?.labelsMap?.[label]?.isSelected ||
            label.toLowerCase().includes(searchText.toLowerCase())
          );
        });
      }

      if (!categoryLabels.length) {
        return (
          <div className="no-results-categories">
            {t('MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_FOUND')}
          </div>
        );
      }

      return (
        <div
          className="sub-category-filter-options"
          style={{
            height: categoryLabels.length * parseInt(ROW_HEIGHT, 10),
          }}
        >
          <Virtuoso
            endReached={() => {}}
            overscan={300}
            totalCount={categoryLabels.length}
            itemContent={(index) => renderItemContent(index, panel, categoryLabels)}
          />
        </div>
      );
    },
    [filters, searchText],
  );

  return (
    <div className={cn('categories-accordion sub-categories', isRtl && 'rtl')}>
      <Accordion
        isRtl={isRtl}
        panels={categories}
        renderPanelHeader={renderPanelHeader}
        renderPanelContent={renderPanelContent}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { filters } = state.filters.myOpportunities;

  const { search: searchText } = filters;

  const { isRtl } = state.settings;

  return {
    filters,
    searchText,
    isRtl,
  };
};

const mapDispatchToProps = {
  setFilterSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoriesAccordion);
