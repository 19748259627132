import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `FindingsActionTypes.${action}`;

const getFindingsReasons = APIConstants.createAPIRequestAction(getPrefix('getFindingsReasons'));

const getHierarchyDrillDownData = APIConstants.createAPIRequestAction(
  getPrefix('getHierarchyDrillDownData'),
);

const getProducts = APIConstants.createAPIRequestAction(getPrefix('getProducts'));

const getStores = APIConstants.createAPIRequestAction(getPrefix('getStores'));

const getOpportunities = APIConstants.createAPIRequestAction(getPrefix('getOpportunities'));

const changeNumberRepresentation = getPrefix('changeNumberRepresentation');

const toggleReasonFilter = getPrefix('toggleReasonFilter');

const toggleGroupSelection = getPrefix('toggleGroupSelection');

const clearAllSelection = getPrefix('clearAllSelection');

const setDateInterval = getPrefix('setDateInterval');

const setFiltersFromSearchParams = getPrefix('setFiltersFromSearchParams');

const toggleDirection = getPrefix('toggleDirection');

const sliceHierarchyPath = getPrefix('onHierarchyPathClick');

const onDrillDownSearch = getPrefix('onDrillDownSearch');

const toggleExpended = getPrefix('toggleExpended');

const toggleFreeTextOnly = getPrefix('toggleFreeTextOnly');

// adding a filter to drill down
const addFilter = getPrefix('addFilter');

const removeDrillDownFilter = getPrefix('removeDrillDownFilter');

const onShowOpportunities = getPrefix('onShowOpportunities');

const setOtherDrillDownSelectedCategory = getPrefix('setOtherDrillDownSelectedCategory');

const getDrillDown = getPrefix('getDrillDown');

const FindingsActionTypes = {
  getFindingsReasons,
  changeNumberRepresentation,
  toggleGroupSelection,
  toggleReasonFilter,
  clearAllSelection,
  onShowOpportunities,
  setDateInterval,
  toggleDirection,
  sliceHierarchyPath,
  toggleExpended,
  removeDrillDownFilter,
  onDrillDownSearch,
  addFilter,
  setOtherDrillDownSelectedCategory,
  toggleFreeTextOnly,
  getProducts,
  getStores,
  getHierarchyDrillDownData,
  getDrillDown,
  getOpportunities,
  setFiltersFromSearchParams,
};

export default FindingsActionTypes;
