import cn from 'clsx';
import { FC, ReactNode } from 'react';
import './decoratedText.scss';

type SpanWithIconProps = {
  containerClass: string;
  textClass: string;
  icon: ReactNode;
  text: string;
  isHidden?: boolean;
};

export const DecoratedText: FC<SpanWithIconProps> = ({
  icon,
  text,
  containerClass,
  textClass,
  isHidden = false,
}) => (
  <div data-testid="decorated-text-container" className={cn([containerClass], { hide: isHidden })}>
    {icon}
    <span className={textClass}>{text}</span>
  </div>
);
