import { createSelector } from 'reselect';

import { mapOpportunityProductCategories } from 'store/helpers/drillDown';
import { IRootState, IReason } from 'types';
import { getReasonsSelector } from 'store/reasons/reasonsSelector';
import { mapOpportunityPlanogramAttributes } from './myOpportunitiesHelper';

const getMySelectedOpportunitySelector = (state: IRootState) =>
  state.myOpportunities.selectedMyOpportunity;

export const getSettingsSelector = (state: IRootState) => state.settings;

export const getSelectedOpportunitySelector = createSelector(
  [getMySelectedOpportunitySelector],
  (getMySelectedOpportunitySelector) => {
    if (!getMySelectedOpportunitySelector) {
      return null;
    }

    return getMySelectedOpportunitySelector;
  },
);

export const getPlanogramAttributesSelector = createSelector(
  [getMySelectedOpportunitySelector, getSettingsSelector],
  (getMySelectedOpportunitySelector, settings) => {
    if (!getMySelectedOpportunitySelector) {
      return [];
    }

    const { planogramAttributes: settingsPlanogramAttributes } = settings;

    const {
      planogramAttributes: opportunityPlanogramAttributes,
    } = getMySelectedOpportunitySelector;

    return mapOpportunityPlanogramAttributes(
      settingsPlanogramAttributes,
      opportunityPlanogramAttributes,
    );
  },
);

const getReasonListSelector = createSelector(
  [getSelectedOpportunitySelector, getReasonsSelector, getSettingsSelector],
  (getSelectedOpportunitySelector, getReasonsSelector, getSettingsSelector): Array<IReason> => {
    if (!getSelectedOpportunitySelector || !getReasonsSelector || !getSettingsSelector) {
      return [];
    }

    const { language } = getSettingsSelector;

    const { action, id: selectedOpportunityId } = getSelectedOpportunitySelector;

    const reasonListGroup = getReasonsSelector[action];

    const reasonsList = reasonListGroup?.map((groupedReasons) => {
      const label = groupedReasons.label[language];

      const { order } = groupedReasons;

      const options = groupedReasons.options.map((option) => ({
        id: option.id,
        isReasonDescriptionMandatory: option.isReasonDescriptionMandatory,
        minimumCharsCount: option.minimumCharsCount,
        label: option.text[language],
        reasonState: option.reasonState,
        key: `${option.id}_${selectedOpportunityId}`,
      }));

      return { label, options, order, key: `${order}_${selectedOpportunityId}` };
    });

    return reasonsList || [];
  },
);

const getSelectedProductCategoriesSelector = createSelector(
  [getMySelectedOpportunitySelector, getSettingsSelector],
  (getMySelectedOpportunitySelector, getSettingsSelector) => {
    if (!getMySelectedOpportunitySelector || !getSettingsSelector) {
      return null;
    }

    let productCategories = null;

    if (getMySelectedOpportunitySelector) {
      productCategories = mapOpportunityProductCategories(
        getSettingsSelector.productCategories,
        getMySelectedOpportunitySelector.productCategories,
      );
    }

    return productCategories;
  },
);

const MyOpportunitiesSelector = {
  getSelectedOpportunitySelector,
  getSelectedProductCategoriesSelector,
  getReasonListSelector,
  getPlanogramAttributesSelector,
};

export default MyOpportunitiesSelector;
