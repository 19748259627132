import { FC, useCallback } from 'react';

import './videoCard.scss';
import { IVideoCardProps } from 'types';
import VideoDetails from 'components/knowledgeCenter/videoDetails/VideoDetails';
import { VideoPoster } from 'components/videoPoster';

export const VideoCard: FC<IVideoCardProps> = ({
  posterSrc,
  onVideoCardClick,
  info,
  id,
  date,
  duration,
  title,
}) => {
  const onClick = useCallback(() => onVideoCardClick(id), [id, onVideoCardClick]);

  return (
    <div className="video-card-wrapper">
      <div className="video-card-container" onClick={onClick}>
        <VideoPoster posterSrc={posterSrc} duration={duration} />
        <VideoDetails title={title} date={date} info={info} />
      </div>
    </div>
  );
};
