import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';

import { SearchInput } from 'components/inputs';
import './headerFilters.scss';
import { isEmptyArray } from 'utils';
import { IHeaderFilters } from 'types';
import { FilterButton } from 'components/buttons/filterButton/filterButton';

const HeaderFilters: FC<IHeaderFilters> = ({
  filters,
  onRemoveFilter,
  onSearchChange,
  isVisible,
  isRtl,
}) => {
  const [t] = useTranslation();

  const renderFilters = useCallback(() => {
    if (isEmptyArray(filters)) {
      return <span className="no-filters-text">{t('FINDINGS.NO_FILTERS')}</span>;
    }

    return filters.map(({ name, hierarchyName, value, drillDownType }) => (
      <FilterButton
        key={`${hierarchyName}-${name}-${Math.random()}`}
        label={`${hierarchyName}: ${name}`}
        id={hierarchyName}
        onRemoveFilter={() => onRemoveFilter({ hierarchyName, value, name, drillDownType })}
      />
    ));
  }, [filters]);

  return (
    <div data-testid="header-filters" className={cn('headers-filter', { visible: isVisible })}>
      <div className="filters-search-container">
        <SearchInput id="drill-down-input" onTextChanged={onSearchChange} isRtl={isRtl} />
        <div className="filters-container">{renderFilters()}</div>
      </div>
    </div>
  );
};

export default HeaderFilters;
