import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { animationType } from 'components/animation/types';
import { ICardsProps } from 'types';
import './cards.scss';
import { VirtualizedList } from 'components/list';
import { ProgressAnimation } from 'components/animation';
import { EmptyState } from 'components/emptyState';
import { isEmptyArray } from 'utils';
import { NoResultIcon } from 'components/Icons';

export const Cards: FC<ICardsProps> = ({
  cards,
  onLoadMore,
  groupContent,
  groupCounts,
  emptyState,
  itemContent,
  isLoading,
  totalCount,
  containerHeight,
  dataTestId,
}) => {
  const [t] = useTranslation();

  const EmptyStateNode = emptyState || (
    <EmptyState icon={NoResultIcon} title={t('MY_OPPORTUNITIES.EMPTY_STATE.NO_RESULT_FOUND')} />
  );

  const isEmptyState = isEmptyArray(cards) && !isLoading;

  const isCardsVisible = !isEmptyState && cards;

  return (
    <div className="cards-container" data-testid={dataTestId}>
      {(!cards || isLoading) && <ProgressAnimation animationType={animationType.Spinner} />}
      {isEmptyState && EmptyStateNode}
      {isCardsVisible && (
        <VirtualizedList
          id={dataTestId}
          containerHeight={containerHeight}
          groupCounts={groupCounts}
          groupContent={groupContent}
          loadMore={onLoadMore}
          totalCount={totalCount}
          itemContent={itemContent}
        />
      )}
    </div>
  );
};
