const getPrefix = (action: string): string => `PrintActionTypes.${action}`;

const Show = getPrefix('Show');

const Hide = getPrefix('Hide');

const PrintActionTypes = {
  Show,
  Hide,
};

export default PrintActionTypes;
