import { createSelector } from 'reselect';

import permissionsService from 'services/permissions/permissionsSrv';
import {
  DrillDownCategoriesTypes,
  DrillDownTypes,
  IFindingsDrillDown,
  IFindingsModel,
  IDrillDownHierarchy,
  IReasonFilterGroups,
  IRootState,
  ISelectedCategory,
  ISettingsModel,
} from 'types';
import { isEmptyArray } from 'utils';
import i18n from 'i18n/i18n';
import {
  getOtherDrillDownCategories,
  isSettingsLoadedSuccessfully,
} from 'store/settings/settingsHepler';
import { getMapReasonIdToTextSelector } from 'store/reasons/reasonsSelector';
import { getOpportunitiesDrillDown } from 'store/helpers/drillDown';
import {
  getOtherDrillDownIsHidden,
  getOtherSelectedCategoryHelper,
  getReasonsFilterGroupsHelper,
} from './findingsHelper';
import { getSearch, verifyIsFiltersApplied } from 'store/trends/trendsHelper';

const getReasons = (state: IRootState) => state.reasons;

const getExpendedDrillDown = (state: IRootState) => state.findings.filters.expendedDrillDown;

const getDrillDownFilters = (state: IRootState) => state.findings.filters;

const getFindingsSelector = (state: IRootState) => state.findings;

const getReasonsGroups = (state: IRootState) => state.findings.reasonFilterGroups;

const getUserProfileSelector = (state: IRootState) => state.userProfile;

const getSettingsSelector = (state: IRootState) => state.settings;

const getPopupSelectedOpportunityId = (state: IRootState) => state.popup.selectedOpportunityId;

const getReasonGroupsMap = (state: IRootState) => state.findings.reasonFilterGroupsMap;

const getReasonFilterToColorMap = (state: IRootState) => state.findings.reasonFilterToColorMap;

const getFindingsStoreDrillDownHierarchyName = (
  settings: ISettingsModel,
  findings: IFindingsModel,
) => {
  const { storeHierarchy } = settings;

  const {
    mutualFilters: { hierarchyIndex },
    activeFilters: { isOpportunitySelected },
  } = findings.filters.store;
  let name = storeHierarchy[hierarchyIndex] || i18n.t('COMMON.STORES');

  if (isOpportunitySelected) {
    name = i18n.t('COMMON.OPPORTUNITIES');
  }

  return name;
};

const getFindingsProductDrillDownHierarchyName = (
  settings: ISettingsModel,
  findings: IFindingsModel,
) => {
  const { productHierarchy } = settings;

  const {
    mutualFilters: { hierarchyIndex },
    activeFilters: { isOpportunitySelected },
  } = findings.filters.product;
  let name = productHierarchy[hierarchyIndex] || i18n.t('COMMON.PRODUCTS');

  if (isOpportunitySelected) {
    name = i18n.t('COMMON.OPPORTUNITIES');
  }

  return name;
};

const getReasonsFilterGroups = createSelector(
  [getReasonsGroups, getReasonGroupsMap, getSettingsSelector, getReasonFilterToColorMap],
  (
    getReasonsGroups,
    getReasonGroupsMap,
    getSettingsSelector,
    reasonFilterToColorMap,
  ): IReasonFilterGroups | null => {
    return getReasonsFilterGroupsHelper(
      getReasonsGroups,
      getReasonGroupsMap,
      getSettingsSelector,
      reasonFilterToColorMap,
    );
  },
);

const getHierarchyPath = (hierarchyPath: Array<IDrillDownHierarchy>) => {
  const updatedHierarchyPath = [...hierarchyPath];

  // Add all to start
  if (!isEmptyArray(hierarchyPath)) {
    updatedHierarchyPath.unshift({
      name: i18n.t('COMMON.ALL'),
      value: 'all',
      hierarchyName: '',
      hierarchyLevel: 1,
    });
  }

  return updatedHierarchyPath;
};

const getStoreDrillDown = createSelector(
  [
    getDrillDownFilters,
    getSettingsSelector,
    getFindingsSelector,
    isSettingsLoadedSuccessfully,
    getUserProfileSelector,
    getMapReasonIdToTextSelector,
  ],
  (
    findingsFilter,
    settings,
    findings,
    isSettingsLoaded,
    mapReasonIdToTextSelector,
  ): IFindingsDrillDown | undefined => {
    const { list, totalCount, isLoading, highestValue } = findings[DrillDownTypes.Store];

    const {
      product: { filters: productFilters },
      other: { filters: otherFilters },
      store: { activeFilters, mutualFilters, hasMore, hierarchyPath: currentHierarchyPath },
    } = findingsFilter;

    const isUserSingleStoreManager = permissionsService.isSingleStoreManager();

    if (!isSettingsLoaded || isUserSingleStoreManager) {
      return undefined;
    }

    const hierarchyName = getFindingsStoreDrillDownHierarchyName(settings, findings);

    const { storeHierarchyDepth } = settings;

    const { opportunities } = findings[DrillDownTypes.Store];

    const parsedOpportunities = getOpportunitiesDrillDown(opportunities, mapReasonIdToTextSelector);

    const hierarchyPath = getHierarchyPath(currentHierarchyPath);

    const filters = [...productFilters, ...otherFilters];

    return {
      list,
      totalCount,
      hierarchyPath,
      hasMore,
      filters,
      hierarchyDepth: storeHierarchyDepth,
      hierarchyName,
      opportunities: parsedOpportunities,
      activeFilters,
      isLoading,
      mutualFilters,
      highestValue,
    };
  },
);

const getProductDrillDown = createSelector(
  [
    getDrillDownFilters,
    getSettingsSelector,
    getFindingsSelector,
    isSettingsLoadedSuccessfully,
    getMapReasonIdToTextSelector,
  ],
  (
    findingsFilter,
    settings,
    findings,
    isSettingsLoaded,
    mapReasonIdToTextSelector,
  ): IFindingsDrillDown | undefined => {
    const { list, totalCount, isLoading, highestValue } = findings[DrillDownTypes.Product];

    const {
      product: { hasMore, activeFilters, mutualFilters, hierarchyPath: currentHierarchyPath },
      other: { filters: otherFilters },
      store: { filters: storeFilters },
    } = findingsFilter;

    if (!isSettingsLoaded) {
      return undefined;
    }

    const hierarchyName = getFindingsProductDrillDownHierarchyName(settings, findings);

    const { opportunities } = findings[DrillDownTypes.Product];

    const parsedOpportunities = getOpportunitiesDrillDown(opportunities, mapReasonIdToTextSelector);

    const hierarchyPath = getHierarchyPath(currentHierarchyPath);

    const { productHierarchyDepth } = settings;

    const filters = [...storeFilters, ...otherFilters];

    return {
      list,
      totalCount,
      hierarchyPath,
      filters,
      hierarchyDepth: productHierarchyDepth,
      hierarchyName,
      opportunities: parsedOpportunities,
      activeFilters,
      isLoading,
      mutualFilters,
      hasMore,
      highestValue,
    };
  },
);

// priority for selected category:
// selected by user -> first product category -> first store category -> store -> product
const getOtherSelectedCategory = (settings: ISettingsModel, findings: IFindingsModel) => {
  const {
    activeFilters: { isOpportunitySelected, selectedCategory },
  } = findings.filters[DrillDownTypes.Other];
  let category = selectedCategory;

  const categories = getOtherDrillDownCategories(settings);

  const { productCategories, storeCategories } = categories;

  if (isOpportunitySelected) {
    category = {
      label: i18n.t('COMMON.OPPORTUNITIES'),
      value: DrillDownCategoriesTypes.Opportunity,
      type: DrillDownCategoriesTypes.Opportunity,
    };
  }
  // no category was selected yet
  if (!category) {
    // default
    category = getOtherSelectedCategoryHelper(productCategories, storeCategories);
  }

  return category;
};

const checkIsCategorySelected = (
  selectedCategory: ISelectedCategory,
  type: DrillDownCategoriesTypes,
  value: string,
) => {
  const { type: selectedCategoryType, value: selectedCategoryValue } = selectedCategory;

  return type === selectedCategoryType && value === selectedCategoryValue;
};

const getOtherDrillDown = createSelector(
  [
    getDrillDownFilters,
    getSettingsSelector,
    getFindingsSelector,
    isSettingsLoadedSuccessfully,
    getMapReasonIdToTextSelector,
  ],
  (
    findingsFilters,
    settings,
    findings,
    isSettingsLoaded,
    mapReasonIdToTextSelector,
  ): IFindingsDrillDown | undefined => {
    const {
      expendedDrillDown,
      product: { filters: productFilters },
      other: { activeFilters, mutualFilters, hasMore, hierarchyPath, filters: otherFilters },
      store: { filters: storeFilters },
    } = findingsFilters;

    const isHidden = getOtherDrillDownIsHidden(settings);

    if (!isSettingsLoaded || isHidden) {
      return undefined;
    }

    const selectedCategory = getOtherSelectedCategory(settings, findings);

    const { list, totalCount, isLoading, highestValue } = findings[DrillDownTypes.Other];

    const { opportunities } = findings[DrillDownTypes.Other];

    const parsedOpportunities = getOpportunitiesDrillDown(opportunities, mapReasonIdToTextSelector);

    // default- show only its own filters

    const filters = [...otherFilters];

    const isOtherExpanded = expendedDrillDown === DrillDownTypes.Other;

    // if expended other drill down shows all filters
    if (isOtherExpanded) {
      filters.unshift(...productFilters);
      filters.unshift(...storeFilters);
    }

    const { label: hierarchyName } = selectedCategory;

    const isFiltersVisible = filters.length > 0 && !isOtherExpanded;

    activeFilters.selectedCategory = selectedCategory;

    return {
      list,
      isLoading,
      totalCount,
      activeFilters,
      mutualFilters,
      hierarchyPath,
      hasMore,
      filters,
      isFiltersVisible,
      opportunities: parsedOpportunities,
      hierarchyName,
      hierarchyDepth: 0,
      highestValue,
    };
  },
);

const getOtherCategories = createSelector(
  [getSettingsSelector, getFindingsSelector],
  (settings, findings) => {
    const drillDownCategories = getOtherDrillDownCategories(settings);

    const selectedCategory = getOtherSelectedCategory(settings, findings);

    const { productCategories, storeCategories } = drillDownCategories;

    const isSingleStore = permissionsService.isSingleStoreManager();

    const mappedProductCategories = productCategories.map((category) => {
      const { value, label } = category;

      const type = DrillDownCategoriesTypes.ProductCategory;

      const isChecked = checkIsCategorySelected(selectedCategory, type, value);

      return {
        label,
        value,
        type,
        isChecked,
      };
    });

    const mappedStoreCategories = storeCategories.map((category) => {
      const { label, value } = category;

      const type = DrillDownCategoriesTypes.StoreCategory;

      const isChecked = checkIsCategorySelected(selectedCategory, type, value);

      return {
        label,
        value,
        type,
        isChecked,
      };
    });

    // Static categories

    const isProductSelected = checkIsCategorySelected(
      selectedCategory,
      DrillDownCategoriesTypes.Product,
      DrillDownCategoriesTypes.Product,
    );

    const isOpportunitySelected = checkIsCategorySelected(
      selectedCategory,
      DrillDownCategoriesTypes.Opportunity,
      DrillDownCategoriesTypes.Opportunity,
    );

    const categories: any = [];

    // add product categories if exist
    if (!isEmptyArray(productCategories)) {
      categories.push({
        id: DrillDownCategoriesTypes.ProductCategory,
        title: i18n.t('DRILL_DOWNS.PRODUCT_CATEGORIES'),
        list: mappedProductCategories,
      });
    }

    // add store categories if exist
    if (!isEmptyArray(storeCategories)) {
      categories.push({
        id: DrillDownCategoriesTypes.StoreCategory,
        title: i18n.t('DRILL_DOWNS.STORE_CATEGORIES'),
        list: mappedStoreCategories,
      });
    }

    // always add product
    categories.push({
      id: DrillDownCategoriesTypes.Product,
      title: i18n.t('COMMON.PRODUCT'),
      list: [
        {
          type: DrillDownCategoriesTypes.Product,
          label: i18n.t('COMMON.PRODUCTS'),
          value: DrillDownCategoriesTypes.Product,
          isChecked: isProductSelected,
        },
      ],
    });

    // add store if should
    if (!isSingleStore) {
      const type = DrillDownCategoriesTypes.Store;

      const isStoreSelected = checkIsCategorySelected(selectedCategory, type, type);
      categories.push({
        id: type,
        title: i18n.t('COMMON.STORE'),
        list: [
          {
            type,
            label: i18n.t('COMMON.STORES'),
            value: DrillDownCategoriesTypes.Store,
            isChecked: isStoreSelected,
          },
        ],
      });
    }

    // always add opportunity as last one
    categories.push({
      id: DrillDownCategoriesTypes.Opportunity,
      title: i18n.t('COMMON.OPPORTUNITY'),
      list: [
        {
          type: DrillDownCategoriesTypes.Opportunity,
          label: i18n.t('COMMON.OPPORTUNITIES'),
          value: DrillDownCategoriesTypes.Opportunity,
          isChecked: isOpportunitySelected,
        },
      ],
    });

    return categories;
  },
);

const getSelectedOpportunity = createSelector(
  [
    getPopupSelectedOpportunityId,
    getExpendedDrillDown,
    getFindingsSelector,
    getMapReasonIdToTextSelector,
  ],
  (selectedOpportunityId, expendedDrillDown, findings, mapReasonIdToText) => {
    if (!selectedOpportunityId || !expendedDrillDown) {
      return null;
    }

    const { opportunities } = findings[expendedDrillDown];

    const parsedOpportunities = getOpportunitiesDrillDown(opportunities, mapReasonIdToText);

    const selectedOpportunity = parsedOpportunities?.list?.find(
      (opportunity) => opportunity.id === selectedOpportunityId,
    );

    return selectedOpportunity || null;
  },
);

const checkIsFiltersApplied = createSelector([getDrillDownFilters], (filters): boolean => {
  const { isFreeTextOnly: isStoreFreeText } = filters[DrillDownTypes.Store].mutualFilters;

  const { isFreeTextOnly: isProductFreeText } = filters[DrillDownTypes.Product].mutualFilters;

  const { isFreeTextOnly: isOtherFreeText } = filters[DrillDownTypes.Other].mutualFilters;

  const isFreeTextOnly = isStoreFreeText || isProductFreeText || isOtherFreeText;

  return verifyIsFiltersApplied(filters) || isFreeTextOnly;
});

const getSearchFilter = createSelector([getDrillDownFilters], (filters): string => {
  return getSearch(filters);
});

const FindingsSelector = {
  getReasons,
  getReasonsFilterGroups,
  getStoreDrillDown,
  getProductDrillDown,
  getOtherDrillDown,
  getOtherCategories,
  getSelectedOpportunity,
  checkIsFiltersApplied,
  getSearchFilter,
};

export default FindingsSelector;
