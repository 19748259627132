import EnvironmentActionTypes from 'store/environment/environmentActionTypes';

const showEnvironmentDebugSettings = (shouldShowDevOptions: boolean) => ({
  type: EnvironmentActionTypes.showEnvironmentDebugSettings,
  payload: { shouldShowDevOptions },
});

export const setEnvironmentDebugSettings = (currentEnvironment: string) => ({
  type: EnvironmentActionTypes.setEnvironmentDebugSettings,
  payload: { currentEnvironment },
});

const EnvironmentActions = {
  showEnvironmentDebugSettings,
  setEnvironmentDebugSettings,
};

export default EnvironmentActions;
