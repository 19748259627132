import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { OrderButton } from 'components/buttons';
import 'components/myOpportunities/filters/ordersButtons/ordersButtons.scss';
import { setMyOpportunitiesFiltersOrder } from 'store/filters/filtersActions';
import { IRootState, orderBy, direction } from 'store/types';

export interface IOrderProps {
  orderSelection: string;
  direction: { [key: string]: direction };
  setMyOpportunitiesFiltersOrder(orderBy: string): void;
}

const SortButtons: FC<IOrderProps> = ({
  orderSelection,
  direction,
  setMyOpportunitiesFiltersOrder,
}) => {
  const [t] = useTranslation();

  return (
    <div className="sort-options">
      <div className="sub-title">{t('MY_OPPORTUNITIES.FILTER.SORT_BY')}</div>
      <div className="sort-buttons">
        <OrderButton
          id="flip-order-sales-target"
          label={t('MY_OPPORTUNITIES.FILTER.SALES_TARGET')}
          isSelected={orderSelection === orderBy.salesTarget}
          buttonKey={orderBy.salesTarget}
          sortDirection={direction[orderBy.salesTarget]}
          onClick={setMyOpportunitiesFiltersOrder}
        />
        <OrderButton
          id="flip-order-received-date"
          label={t('MY_OPPORTUNITIES.FILTER.RECEIVED_DATE')}
          isSelected={orderSelection === orderBy.receivedDate}
          buttonKey={orderBy.receivedDate}
          sortDirection={direction[orderBy.receivedDate]}
          onClick={setMyOpportunitiesFiltersOrder}
        />
        <OrderButton
          id="flip-order-product-name"
          label={t('MY_OPPORTUNITIES.FILTER.PRODUCT_NAME')}
          isSelected={orderSelection === orderBy.productName}
          buttonKey={orderBy.productName}
          sortDirection={direction[orderBy.productName]}
          onClick={setMyOpportunitiesFiltersOrder}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  orderSelection: state.filters.myOpportunities.filters.orderBy,
  direction: state.filters.myOpportunities.filters.direction,
});

const mapDispatchToProps = {
  setMyOpportunitiesFiltersOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortButtons);
