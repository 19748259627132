import { FC } from 'react';
import Lottie from 'react-lottie-player';
import cn from 'clsx';

import { IAnimationProps, mapEmptyStateAnimation } from 'components/animation/types';
import './animations.scss';

export const EmptyStateAnimation: FC<IAnimationProps> = ({
  animationType,
  className,
  animationStyle = { width: '100%', height: '100%' },
  dataTestId,
}) => {
  const animation = mapEmptyStateAnimation[animationType];

  return (
    <div
      data-testid={dataTestId}
      className={cn('empty-state-animation', className, animation.className)}
      style={{ position: animation.style?.position || 'relative' }}
    >
      <Lottie
        loop={animation.loop || false}
        play
        style={{ ...animationStyle, ...animation.style }}
        animationData={animation.data}
      />
    </div>
  );
};
