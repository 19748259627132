import { ISettingsModel } from 'store/types';

/**
 * Feature Flags Service
 */
class FeatureFlagsService {
  constructor() {
    this.settingsMetaData = undefined;
  }

  private settingsMetaData: ISettingsModel | undefined;

  public setMetaData(metaData: ISettingsModel) {
    this.settingsMetaData = metaData;
  }

  public mayViewKnowledgeCenter() {
    return this.settingsMetaData?.featureFlags.isVideoKnowledgeCenterEnabled === true;
  }

  public mayViewProductImages() {
    return this.settingsMetaData?.featureFlags.showProductImages === true;
  }

  public mayViewLiveInventory() {
    return this.settingsMetaData?.featureFlags.isLiveInventoryEnabled === true;
  }

  public mayViewTargetSales() {
    return this.settingsMetaData?.featureFlags.showTargetSales === true;
  }

  public mayViewTargetUnits() {
    return this.settingsMetaData?.featureFlags.showTargetUnits === true;
  }

  public mayViewPotentialRevenue() {
    return this.settingsMetaData?.featureFlags.showPotentialRevenue === true;
  }

  public mayViewPotentialUnits() {
    return this.settingsMetaData?.featureFlags.showPotentialUnits === true;
  }

  public mayViewInventoryData() {
    return this.settingsMetaData?.featureFlags.showInventoryData === true;
  }

  public mayViewInventoryUpdate() {
    return this.mayViewInventoryData() && !this.mayViewLiveInventory();
  }

  public mayViewLeaderBoard() {
    return this.settingsMetaData?.featureFlags.showLeaderBoard === true;
  }

  public mayViewImpactOnSales() {
    return this.settingsMetaData?.featureFlags.showResponseImpactOnSales === true;
  }

  public mayViewTaskUnitsGraph() {
    return this.settingsMetaData?.featureFlags.showTaskUnitsGraph === true;
  }
}

const featureFlagsService = new FeatureFlagsService();

export default featureFlagsService;
