import { ICategory } from 'components/myOpportunities/cardPreview/types';
import {
  emptyStateAnimationDecisionValues,
  IHitRateScore,
  OpportunitiesTabsOptions,
} from './types';
import { ISearchParamsFilters } from 'store/filters/types';
import { deepLinkOpportunityId } from 'components/pages/myOpportunities/types';
import GuidUtils from 'utils/GuidUtils';

export const mapOpportunityPlanogramAttributes = (
  settingsPlanogramAttributes: { [key: string]: string },
  opportunitityPlanogramAttributes?: { [key: string]: string },
) => {
  let mappedPlanogramAttributes: ICategory[] = [];

  if (opportunitityPlanogramAttributes) {
    mappedPlanogramAttributes = Object.keys(opportunitityPlanogramAttributes)
      .filter((key) => settingsPlanogramAttributes[key])
      .map((key) => {
        return {
          name: settingsPlanogramAttributes[key],
          value: opportunitityPlanogramAttributes[key],
        } as ICategory;
      });
  }

  return mappedPlanogramAttributes;
};

export const calculateAnimationKey = (hitRateScore: IHitRateScore) => {
  const { curHitRate, avgChainHitRate, totalIssueCount } = hitRateScore;

  const {
    MIN_ISSUE_RESPONDED_THRESHOLD,
    MAX_HIT_RATE,
    FIFTY_PERCENT_HIT_RATE,
    TWENTY_PERCENT_HIT_RATE,
  } = emptyStateAnimationDecisionValues;

  let emptyStateAnimationKey: number = NaN;

  if (totalIssueCount >= MIN_ISSUE_RESPONDED_THRESHOLD) {
    if (curHitRate > MAX_HIT_RATE) {
      emptyStateAnimationKey = 0;
    } else if (curHitRate >= FIFTY_PERCENT_HIT_RATE) {
      if (curHitRate > avgChainHitRate) {
        emptyStateAnimationKey = 1;
      } else {
        emptyStateAnimationKey = 2;
      }
    } else if (curHitRate > avgChainHitRate) {
      emptyStateAnimationKey = 3;
    } else if (
      Math.round(curHitRate) !== Math.round(avgChainHitRate) &&
      curHitRate <= TWENTY_PERCENT_HIT_RATE
    ) {
      emptyStateAnimationKey = 4;
    }
  }

  return emptyStateAnimationKey;
};

export const buildMyOpportunitiesFilters = (searchFilters: ISearchParamsFilters) => {
  const { tab, cardId } = searchFilters;

  const selectedTab = tab
    ? (Number(tab) as OpportunitiesTabsOptions)
    : OpportunitiesTabsOptions.Open;

  let deepLinkId: deepLinkOpportunityId = '';

  if (cardId && GuidUtils.isValid(cardId)) {
    deepLinkId = cardId;
  }

  return { selectedTab, deepLinkId };
};
