import cn from 'clsx';
import { ChangeEvent, FC, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  AlertIds,
  IAlertButton,
  IAlertListItem,
  IAlertRadioItem,
  IRootState,
  ITreeGroup,
  ITreeOption,
  SelectedDateIntervals,
} from 'types';
import { CheckboxField } from 'components/checkbox';
import { AlertButton } from 'components/common/alert/AlertButton';
import Modal from 'components/common/Modal/Modal';
import AlertActions, { setTreeSelection } from 'store/alert/alertActions';
import {
  setDateInterval as setFindingsDateInterval,
  setOtherDrillDownSelectedCategory as setFindingsCategorySelection,
} from 'store/findings/findingsActions';
import {
  setDateInterval as setTrendsDateInterval,
  setOtherDrillDownSelectedCategory as setTrendsCategorySelection,
} from 'store/trends/trendsActions';
import DatePicker from './DatePicker';
import OtherHierarchyTree from './OtherHierarchyTree';

export interface IAlert {
  id: string;
  onButtonClick(id: string, buttonId: string, checklist: any): void;
  hide(id: string): void;
  checklist?: Array<IAlertListItem>;
  buttons?: Array<IAlertButton>;
  title?: string;
  text?: string;
  withOverlay: boolean;
  isVisible: boolean;
  alertCustomClassName?: string;
  treeSelection?: Array<ITreeGroup>;
  withDatePicker?: boolean;
  radioList?: Array<IAlertRadioItem>;
  selectedDates?: { from: number; to: number };
  setChecklist(checklist: Array<IAlertListItem>): void;
  setRadioList(radioList: Array<IAlertRadioItem>): void;
  setTreeSelection(selection: string, treeId: string): void;
  setFindingsCategorySelection(value: string, label: string, id?: string): void;
  setTrendsCategorySelection(value: string, label: string, id?: string): void;
  setDateRange({ from, to }: { from: number; to: number }): void;
  setFindingsDateInterval(type: string, datesRange?: { from: number; to: number }): void;
  setTrendsDateInterval(type: string, datesRange?: { from: number; to: number }): void;
}

const Alert: FC<IAlert> = ({
  hide,
  onButtonClick,
  id,
  checklist,
  buttons,
  withOverlay,
  title,
  text,
  isVisible,
  alertCustomClassName,
  setChecklist,
  withDatePicker,
  selectedDates,
  radioList,
  setRadioList,
  setFindingsDateInterval,
  setDateRange,
  treeSelection,
  setTreeSelection,
  setFindingsCategorySelection,
  setTrendsDateInterval,
  setTrendsCategorySelection,
  ...rest
}) => {
  const onClick = (buttonId: string) => {
    hide(id);
    onButtonClick(id, buttonId, checklist);
  };

  const onRequestClose = () => {
    hide(id);
  };

  const renderButtons = () => {
    if (!buttons) {
      return null;
    }

    return buttons.map((btn: IAlertButton) => (
      <Fragment key={`alert-button-${id}-${btn.id}`}>
        <AlertButton
          className={btn.id}
          text={btn.text}
          onClick={() => onClick(btn.id)}
          icon={btn.icon}
        />
      </Fragment>
    ));
  };

  const onCheckboxClicked = (checkedEvent: ChangeEvent<HTMLInputElement>, index: number) => {
    const {
      target: { checked },
    } = checkedEvent;

    checkedEvent.stopPropagation();

    const newCheckList = checklist !== undefined ? [...checklist] : [];
    newCheckList[index].isChecked = checked;

    setChecklist(newCheckList);
  };

  const onDateChange = (from: number, to: number) => {
    if (id === AlertIds.OpenFindingsDateMenu) {
      setFindingsDateInterval(SelectedDateIntervals.customId, { from, to });
      hide(id);
    } else if (id === AlertIds.OpenTrendsDateMenu) {
      setTrendsDateInterval(SelectedDateIntervals.customId, { from, to });
      hide(id);
    } else {
      setDateRange({ from, to });
    }
  };

  const RenderChecklist = ({ checklist }: any) => {
    if (!checklist) {
      return null;
    }

    return (
      <div className="checklist" id="alert-checkbox-container">
        {checklist.map((item: IAlertListItem, index: number) => (
          <CheckboxField
            id={item.id}
            key={item.id}
            label={item.label}
            isChecked={item.isChecked}
            onChange={(e) => onCheckboxClicked(e, index)}
          />
        ))}
      </div>
    );
  };

  const onRadioButtonClick = (radioItem: IAlertRadioItem) => {
    if (radioList) {
      if (radioItem.hideOnClick) {
        hide(id);
        if (id === AlertIds.OpenFindingsDateMenu) {
          setFindingsDateInterval(radioItem.id);
        } else if (id === AlertIds.OpenTrendsDateMenu) {
          setTrendsDateInterval(radioItem.id);
        }
      } else {
        const newToggledRadioList = radioList.map((item) => {
          const { id: radioId, label, hideOnClick } = item;

          const isChecked = radioItem.id === radioId;

          return {
            id: radioId,
            label,
            isChecked,
            hideOnClick,
          };
        });
        setRadioList(newToggledRadioList);
      }
    }
  };

  const RenderRadioButtons = (): any => {
    if (!radioList) {
      return null;
    }

    return radioList.map((radioItem: IAlertRadioItem) => {
      const { id, isChecked, label } = radioItem;

      return (
        <div
          key={id}
          onClick={() => onRadioButtonClick(radioItem)}
          className={cn('radio-item', { checked: isChecked })}
        >
          {label}
        </div>
      );
    });
  };

  const onChangeTreeSelection = (selection: ITreeOption, treeId: string) => {
    const { hideOnClick, value, label, id: selectionId } = selection;

    if (hideOnClick) {
      hide(id);
    }
    if (id === AlertIds.OpenFindingsOtherDrillDownOptions) {
      setFindingsCategorySelection(value, label, selectionId);
    } else if (id === AlertIds.OpenTrendsOtherDrillDownOptions) {
      setTrendsCategorySelection(value, label, selectionId);
    } else {
      setTreeSelection(value, treeId);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      onEsc={onRequestClose}
      onClickOutside={onRequestClose}
      withOverlay={withOverlay}
      {...rest}
    >
      <div className={cn('alert-container', [alertCustomClassName])}>
        {title && <div className="title">{title}</div>}
        {text && <div className="text">{text}</div>}
        <RenderChecklist checklist={checklist} />
        <RenderRadioButtons />
        <OtherHierarchyTree treeSelection={treeSelection} onChange={onChangeTreeSelection} />
        {withDatePicker && <DatePicker selectedDates={selectedDates} onChange={onDateChange} />}
        {buttons && <div className="buttons-container">{renderButtons()}</div>}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    isVisible,
    id,
    withDatePicker,
    selectedDates,
    title,
    text,
    buttons,
    alertCustomClassName,
    checklist,
    withOverlay,
    radioList,
    treeSelection,
  } = state.alert;

  return {
    isVisible,
    id,
    title,
    text,
    buttons,
    alertCustomClassName,
    checklist,
    withOverlay,
    withDatePicker,
    selectedDates,
    radioList,
    treeSelection,
  };
};

const mapDispatchToProps = {
  onButtonClick: AlertActions.buttonClick,
  hide: AlertActions.hide,
  setChecklist: AlertActions.setChecklist,
  setDateRange: AlertActions.setDateRange,
  setRadioList: AlertActions.setRadioList,
  setFindingsDateInterval,
  setTrendsDateInterval,
  setFindingsCategorySelection,
  setTrendsCategorySelection,
  setTreeSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
