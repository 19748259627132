import {
  DrillDownTypes,
  IFindingsDrillDownModal,
  IReasonFilterGroupsMap,
  IReasonFilterToColorMap,
  ISelectedCategory,
  ISummaryTopFindingsItem,
  RateHistogram,
  SelectedDateIntervals,
  ILeaderBoardRow,
  Store,
} from 'types';

export interface ISummaryModel {
  selectedStoreFilter: Store | null;
  totalOpportunitiesCaptured: number;
  totalOpportunitiesCapturedChainWide: number;
  salesIncrease: number;
  salesIncreaseChainWide: number;
  numberOfWeeksForResponseRateComparison: number;
  responseRateValue: number;
  responseRateCompareToValue: number;
  numberOfWeeksForHitRateComparison: number;
  hitRateValue: number;
  hitRateCompareToValue: number;
  numberOfOpenTasks: number;
  openTasksWeekOf: number;
  activeFilters: ISummaryActiveFilters;
  leaderBoardTopData: Array<ILeaderBoardRow>;
  leaderBoardBottomData: Array<ILeaderBoardRow>;
  issueFoundTotalCount: number | null;
  totalCount: number;
  reasonFilterGroups: Array<number> | null;
  reasonFilterGroupsMap: IReasonFilterGroupsMap | null;
  reasonFilterToColorMap: IReasonFilterToColorMap;
  [DrillDownTypes.Other]: IFindingsDrillDownModal;
  hitRate: {
    total: number;
    data: RateHistogram | null;
    chainWideData: RateHistogram;
  };
  responseRate: {
    total: number;
    data: RateHistogram | null;
    chainWideData: RateHistogram;
  };
}

export interface ITopFindingsDrillDown {
  list: Array<ISummaryTopFindingsItem> | null;
  isLoading: boolean;
  totalCount: number;
  selectedCategory?: ISelectedCategory;
  categories?: Array<{ title: string; list: Array<string> }>;
  isFiltersVisible?: boolean;
}

export interface IRateRow {
  total: number;
  deployDate: number;
  value: number;
}

export interface IResponseRateComparisonApiResponseRow {
  totalNumberOfTasks: number;
  deployDate: number;
  numberOfResponses: number;
}

export interface IHitRateComparisonApiResponseRow {
  totalIssueFound: number;
  deployDate: number;
  numberOfResponses: number;
}

export interface ISummaryActiveFilters {
  kpiDateFilter: SelectedDateIntervals;
  topFindingsDateFilter: SelectedDateIntervals;
  leaderBoardDateFilter: SelectedDateIntervals;
  isLeaderBoardPeersSelected: boolean;
  activeReasonFilters: Array<number>;
  activeComparisonTab: SummaryComparisonTab;
}

export enum SummaryComparisonTab {
  RESPONSE_RATE = 1,
  HIT_RATE = 2,
}

export type LeaderBoardResponseApi = Array<{
  noIssueFoundCount: number;
  name?: string;
  storeName?: string;
  storeId?: string;
  hitRate: number;
  issueFoundCount: number;
}>;

export type SalesIncreaseMyHistogramApi = Array<{
  month: string;
  revenueIncrease: number;
}>;

export enum RateRequestScope {
  'MY' = '/My',
  'ALL' = '',
  'PEERS' = '/Peers',
}
