import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';
import { connect } from 'react-redux';

import OpenOpportunitiesPreview from './OpenOpportunitiesPreview';
import 'components/pages/openOpportunities/OpenOpportunities.scss';
import { IOpenOpportunitiesProps } from './types';
import { IRootState, OpenOpportunitiesCardTypes } from 'types';
import OpenOpportunitiesCard from 'components/openOpportunities/card/openOpportunitiesCard';
import DateSlider from 'components/dateSlider/DateSlider';
import { DateButton } from 'components/buttons';
import { ArrowDirection } from 'components/buttons/dateButton/types';
import StoreBreakdownList from 'components/openOpportunities/storeBreakdownList/storeBreakdownList';
import {
  changeDateRange,
  clearFilters,
  getDrillDownItems,
  getOpenOpportunities,
  getStoreBreakdown,
  selectCard,
} from 'store/openOpportunities/openOpportunitiesActions';
import OpenOpportunitiesSelector from 'store/openOpportunities/openOpportunitiesSelector';
import LoadingSelector from 'store/loading/LoadingSelector';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { EmptyState } from 'components/emptyState';
import { TrophyIcon } from 'components/Icons';

const OpenOpportunities: FC<IOpenOpportunitiesProps> = ({
  dateMarks,
  openOpportunities = 0,
  responseRate = 0,
  changeDateRange,
  selectedWeeks,
  selectedCard,
  selectCard,
  clearFilters,
  getOpenOpportunities,
  getStoreBreakdown,
  getDrillDownItems,
  isLoadingOpenOpportunities,
  isRtl,
}) => {
  const [t] = useTranslation();

  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const shouldSliderExist = dateMarks.length > 1;

  const [dateBtnArrowDir, setDateBtnArrowDir] = useState(ArrowDirection.None);

  const completedEmptyStateDetails = {
    title: t('OPEN_OPPORTUNITIES.EMPTY_STATE.ALL_DONE_TITLE'),
    icon: TrophyIcon,
    text: t('OPEN_OPPORTUNITIES.EMPTY_STATE.ALL_DONE_TEXT'),
  };

  const isAllCompletedState = !openOpportunities && responseRate >= 100;

  useEffect(() => {
    let dir = ArrowDirection.None;

    if (isSliderVisible && shouldSliderExist) {
      dir = isRtl ? ArrowDirection.Right : ArrowDirection.Left;
    } else if (shouldSliderExist) {
      dir = isRtl ? ArrowDirection.Left : ArrowDirection.Right;
    }

    setDateBtnArrowDir(dir);
  }, [isRtl, isSliderVisible, dateMarks.length]);

  useEffect(() => {
    getOpenOpportunities();
  }, []);

  // listen for changes in selected weeks
  useEffect(() => {
    if (selectedWeeks && dateMarks.length > 0) {
      const range: [number, number] = [
        dateMarks[selectedWeeks[0]].date,
        dateMarks[selectedWeeks[1]].date,
      ];
      getStoreBreakdown(range);
      AnalyticSrv.sendDateSliderEvent(range.toString());
    }
  }, [selectedWeeks]);

  const handleOnDatesChange = useCallback(
    (dates: [number, number]) => {
      changeDateRange(dates);
      getDrillDownItems(selectedCard);
    },
    [selectedCard],
  );

  const handleOnCardClick = useCallback((key: OpenOpportunitiesCardTypes) => {
    clearFilters();
    selectCard(key);
  }, []);

  return (
    <div className="page">
      <div className="page-header">
        <span className="title">{t('PAGES.OPPORTUNITIES')}</span>
        <div className="date-slider-toggle-container">
          <DateButton
            arrowDirection={dateBtnArrowDir}
            hasWeeksLabel
            dates={[dateMarks[selectedWeeks[0]]?.date, dateMarks[selectedWeeks[1]]?.date]}
            onClick={() => setIsSliderVisible(!isSliderVisible)}
          />
          {shouldSliderExist && (
            <DateSlider
              className={cn('oo-date-slider', isSliderVisible && 'visible')}
              marks={dateMarks}
              onDateChange={handleOnDatesChange}
              selectedDatesIndexes={selectedWeeks}
              isRtl={isRtl}
            />
          )}
        </div>
      </div>
      <div className="page-container">
        <div className="page-list">
          <div className="open-opportunities-list-container">
            <div className="list-tabs-cards">
              <OpenOpportunitiesCard
                onCardClick={handleOnCardClick}
                type={OpenOpportunitiesCardTypes.RESPONSE_RATE}
                value={responseRate}
                symbol="%"
                isRtl={isRtl}
                isLoading={isLoadingOpenOpportunities}
                isSelected={selectedCard === OpenOpportunitiesCardTypes.RESPONSE_RATE}
              />
              <OpenOpportunitiesCard
                onCardClick={handleOnCardClick}
                type={OpenOpportunitiesCardTypes.OPEN_OPPORTUNITIES}
                value={openOpportunities}
                isRtl={isRtl}
                isLoading={isLoadingOpenOpportunities}
                isSelected={selectedCard === OpenOpportunitiesCardTypes.OPEN_OPPORTUNITIES}
              />
            </div>
            {isAllCompletedState && (
              <div className="completed-empty-state">
                <EmptyState
                  title={completedEmptyStateDetails.title}
                  icon={completedEmptyStateDetails.icon}
                  text={completedEmptyStateDetails.text}
                />
              </div>
            )}
            <StoreBreakdownList
              isAllCompletedState={isAllCompletedState}
              selectedCard={selectedCard}
              onCardClick={handleOnCardClick}
            />
          </div>
        </div>
        <div className="page-preview">
          <OpenOpportunitiesPreview selectedCard={selectedCard} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { selectedWeeks, selectedCard } = state.openOpportunities;

  const { isRtl } = state.settings;

  const responseRate = OpenOpportunitiesSelector.getResponseRateSelector(state);

  const openOpportunities = OpenOpportunitiesSelector.getOpenOpportunitiesSelector(state);

  const dateMarks = OpenOpportunitiesSelector.getDateMarksSelector(state);

  const isLoadingOpenOpportunities = LoadingSelector.isGetOpenOpportunitiesLoading(state);

  return {
    isLoadingOpenOpportunities,
    dateMarks,
    responseRate,
    openOpportunities,
    selectedWeeks,
    selectedCard,
    isRtl,
  };
};

const mapDispatchToProps = {
  getOpenOpportunities,
  changeDateRange,
  getStoreBreakdown,
  selectCard,
  getDrillDownItems,
  clearFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(OpenOpportunities));
