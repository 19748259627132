import { isEmpty } from 'lodash';

import { FilterBuilder } from 'store/filters/filterBuilder';
import { direction, fieldName, filterKey, SelectedDateIntervals } from 'store/filters/types';
import { DateIntervals } from 'constants/constantsVaribles';
import {
  IOpenOpportunitiesDrillDownFilters,
  mapDropdownOptionsToOrderFieldName,
  OpenOpportunitiesDrillDownTabsTypes,
  OpenOpportunitiesDropDownOptionsTypes,
  OpportunitiesCounter,
} from './types';
import { OpenOpportunitiesCardTypes } from 'components/openOpportunities/types';
import { DrillDownTypes, IDrillDownHierarchy } from 'components/drilldown/types';
import {
  HierarchyLevel,
  ISettingsModel,
  IUserProfileModel,
  mapHierarchyLevelToOrder,
  mapResponseHierarchyToHierarchyLevel,
} from 'types';
import { mapDrillDownTypeToFilterField } from 'constants/hierarchy';
import i18n from 'i18n/i18n';
import { isEmptyArray } from 'utils';

const HIERARCHY_FILTER_KEY = mapDrillDownTypeToFilterField[DrillDownTypes.Store];

const PAGE_SIZE = 50;

const addDeploymentDateRangeFilter = (
  filterBuilder: FilterBuilder,
  weeksRange: [number, number],
) => {
  filterBuilder.and().time(filterKey.DEPLOY_DATE, weeksRange[0], weeksRange[1] + 1);

  filterBuilder.and().term(filterKey.LIFECYCLE, 'ACTIVE');
};

const addDeploymentDateSinceFilter = (
  filterBuilder: FilterBuilder,
  weeksToFetch: number,
  isActive = true,
) => {
  const dateInterval =
    weeksToFetch === 1 ? SelectedDateIntervals.weekId : SelectedDateIntervals.monthId;
  filterBuilder.and().relative(filterKey.DEPLOY_DATE, DateIntervals[dateInterval]);

  if (isActive) {
    filterBuilder.and().term(filterKey.LIFECYCLE, 'ACTIVE');
  }
};

export const getResponseRateAndOpenOpByWeeks = (
  selectedWeeks: [number, number],
  opportunitiesCount: OpportunitiesCounter[],
) => {
  let responseRate = 0;

  const relativeArr = opportunitiesCount.slice(selectedWeeks[0], selectedWeeks[1] + 1);

  const total = relativeArr.reduce(
    (sum: number, item: OpportunitiesCounter) => sum + item.total,
    0,
  );

  const open = relativeArr.reduce((sum: number, item: OpportunitiesCounter) => sum + item.open, 0);

  if (total !== 0) {
    responseRate = Math.floor(((total - open) * 100) / total);
  }

  return { open, responseRate };
};

export const getDrillDownItemsEndpointRoute = (
  hierarchyIndex: number,
  shouldGetStores: boolean,
  shouldGetStoreCount: boolean,
) => {
  let urlString;

  if (shouldGetStoreCount) {
    urlString = `StoreCountByHierarchyQuery/LEVEL${hierarchyIndex}`;
  } else if (shouldGetStores) {
    urlString = 'TaskStateByStoreQuery';
  } else {
    urlString = `TaskStateByHierarchyQuery/LEVEL${hierarchyIndex}`;
  }

  return urlString;
};

export const getDropDownOptions = (
  tab: OpenOpportunitiesDrillDownTabsTypes,
  cardType: OpenOpportunitiesCardTypes,
) => {
  const options: Set<OpenOpportunitiesDropDownOptionsTypes> = new Set<OpenOpportunitiesDropDownOptionsTypes>();

  if (tab === OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY) {
    options.add(OpenOpportunitiesDropDownOptionsTypes.RECEIVED_DATE);
  } else if (tab === OpenOpportunitiesDrillDownTabsTypes.OTHER) {
    options.add(OpenOpportunitiesDropDownOptionsTypes.NUMBER_OF_STORES);
  } else if (tab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE) {
    options.add(OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE);
  }

  switch (cardType) {
    case OpenOpportunitiesCardTypes.OPEN_OPPORTUNITIES:
    case OpenOpportunitiesCardTypes.RESPONSE_RATE:
      if (
        tab === OpenOpportunitiesDrillDownTabsTypes.OTHER ||
        tab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE
      ) {
        options.add(OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES);
        options.add(OpenOpportunitiesDropDownOptionsTypes.INVESTIGATED_OPPORTUNITIES);
        options.add(OpenOpportunitiesDropDownOptionsTypes.RESPONSE_RATE);
      }
      if (tab === OpenOpportunitiesDrillDownTabsTypes.OTHER) {
        options.add(OpenOpportunitiesDropDownOptionsTypes.NUMBER_OF_STORES);
      }
      break;
    case OpenOpportunitiesCardTypes.NO_RESPONSE:
      if (tab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE) {
        options.add(OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES);
      }
      break;
    case OpenOpportunitiesCardTypes.IN_PROGRESS:
      if (tab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE) {
        options.add(OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES);
        options.add(OpenOpportunitiesDropDownOptionsTypes.INVESTIGATED_OPPORTUNITIES);
      }
      break;
    case OpenOpportunitiesCardTypes.COMPLETED:
      if (tab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE) {
        options.add(OpenOpportunitiesDropDownOptionsTypes.INVESTIGATED_OPPORTUNITIES);
      }
      break;
    case OpenOpportunitiesCardTypes.NO_OPPORTUNITIES:
      break;

    default:
      break;
  }

  return Array.from(options).sort().reverse();
};

export const getSelectedDropdownOption = (
  options: any[],
  cardType: OpenOpportunitiesCardTypes,
  prevOption?: OpenOpportunitiesDropDownOptionsTypes,
  selectedTab: OpenOpportunitiesDrillDownTabsTypes = OpenOpportunitiesDrillDownTabsTypes.OTHER,
) => {
  // set prev option to be selected if exists in the list
  if (prevOption && options.indexOf(prevOption) > -1) {
    return options.indexOf(prevOption);
  }

  // otherwise decide what option is selected in the new list
  const isOpenOpportunitiesCard = cardType === OpenOpportunitiesCardTypes.OPEN_OPPORTUNITIES;

  const isOpportunitiesTab = selectedTab === OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY;

  const selectedOptionIndex =
    isOpenOpportunitiesCard && !isOpportunitiesTab
      ? options.indexOf(OpenOpportunitiesDropDownOptionsTypes.OPEN_OPPORTUNITIES)
      : 0;

  return selectedOptionIndex;
};

export const getTopHierarchyPath = (
  topHierarchy: any,
  availableStoreHierarchy: { [key: string]: string },
) => {
  const { id, index } = topHierarchy;

  const firstHierarchyLevel: IDrillDownHierarchy = {
    name: i18n.t(id),
    value: id,
    hierarchyLevel: index,
    hierarchyName: availableStoreHierarchy[topHierarchy.index],
  };

  return firstHierarchyLevel;
};

export const checkIsStoreLeaf = (hierarchyIndex: number, storeHierarchyLevel: number) => {
  return hierarchyIndex === storeHierarchyLevel;
};

export const checkIsOpportunityLeaf = (
  hierarchyIndex: number,
  opportunityHierarchyLevel: number,
) => {
  return hierarchyIndex === opportunityHierarchyLevel;
};

export const getTopHierarchy = (userProfile: IUserProfileModel) => {
  const level = userProfile.userHighestHierarchyLowestLevel?.level ?? HierarchyLevel.ALL;

  const id = userProfile.userHighestHierarchyLowestLevel?.id ?? 'COMMON.ALL';

  return { index: mapHierarchyLevelToOrder[level] + 1, id };
};

export const getInteractiveFilterTabHierarchyName = (
  settings: ISettingsModel,
  hierarchyIndex: number,
  topHierarchyIndex: number,
) => {
  const { storeHierarchy, storeHierarchyDepth } = settings;

  let name = storeHierarchy[hierarchyIndex];

  if (storeHierarchyDepth < hierarchyIndex) {
    name = storeHierarchy[topHierarchyIndex];
  }

  return name;
};

const addStoreProgressStateFilter = (
  filterBuilder: FilterBuilder,
  cardType: OpenOpportunitiesCardTypes,
) => {
  const value = cardType === OpenOpportunitiesCardTypes.NO_OPPORTUNITIES ? 'NO_TASKS' : cardType;

  if (
    cardType !== OpenOpportunitiesCardTypes.OPEN_OPPORTUNITIES &&
    cardType !== OpenOpportunitiesCardTypes.RESPONSE_RATE
  ) {
    filterBuilder.and().term('storeProgressState', value);
  }
};

const addDrillDownSearchFilter = (
  filterBuilder: FilterBuilder,
  shouldHaveField: boolean,
  filters: IOpenOpportunitiesDrillDownFilters,
) => {
  const { searchValue, hierarchyIndex, selectedTab } = filters;

  if (!isEmpty(searchValue)) {
    const isStore = selectedTab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE;
    let field = `${HIERARCHY_FILTER_KEY}.${hierarchyIndex}`;

    if (isStore) {
      field = fieldName.storeName;
    }
    if (shouldHaveField) {
      filterBuilder.and().freeText(searchValue, [field]);
    } else {
      filterBuilder.and().freeText(searchValue);
    }
  }
};

const addDrillDownHierarchyFilters = (
  filterBuilder: FilterBuilder,
  hierarchyPath: Array<IDrillDownHierarchy>,
  opportunitiesLeafHierarchyLevel: number,
) => {
  const updatedHierarchyPath = [...hierarchyPath];

  // start iterating from second element of hierarchy path if first one is ALL
  if (hierarchyPath.length > 0 && hierarchyPath[0].hierarchyName === 'all') {
    updatedHierarchyPath.shift();
  }

  // go through the hierarchy path
  for (let i = 0; i < updatedHierarchyPath.length; i++) {
    // default filter key
    let key = `${HIERARCHY_FILTER_KEY}.${i + 1}`;

    const { value } = updatedHierarchyPath[i];

    const itemHierarchyLevel = updatedHierarchyPath[i].hierarchyLevel || i;

    // if it's leaf
    if (checkIsOpportunityLeaf(itemHierarchyLevel, opportunitiesLeafHierarchyLevel)) {
      key = filterKey.STORE_ID;
    }

    filterBuilder.and().term(key, value);
  }
};

export const buildFindingsReasonsFilters = (
  weeksTimePeriod: number,
  hierarchyPath: IDrillDownHierarchy[],
  opportunitiesLeafHierarchyLevel: number,
) => {
  const filterBuilder = new FilterBuilder();

  addDeploymentDateSinceFilter(filterBuilder, weeksTimePeriod, false);
  addDrillDownHierarchyFilters(filterBuilder, hierarchyPath, opportunitiesLeafHierarchyLevel);

  const filter = filterBuilder.build();

  const orders = [{ direction: direction.DESC, fieldName: fieldName.count }];

  return { filter, orders };
};

export const buildOpenOpportunitiesFilter = (weeksRange?: [number, number]) => {
  const filterBuilder = new FilterBuilder();

  if (weeksRange) {
    addDeploymentDateRangeFilter(filterBuilder, weeksRange);
  } else {
    addDeploymentDateSinceFilter(filterBuilder, 4);
  }

  return filterBuilder.build();
};

export const getDrillDownOrdersFilter = (filters: IOpenOpportunitiesDrillDownFilters) => {
  const { selectedTab, selectedDropdownOption, sortDirection } = filters;

  const orders = [];

  const mainFieldName = mapDropdownOptionsToOrderFieldName[selectedDropdownOption];
  orders.push({ direction: sortDirection, fieldName: mainFieldName });

  switch (selectedTab) {
    case OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY: {
      orders.push({ direction: direction.DESC, fieldName: fieldName.targetSales });
      orders.push({ direction: direction.ASC, fieldName: fieldName.storeName });
      orders.push({ direction: direction.ASC, fieldName: fieldName.productName });
      break;
    }
    case OpenOpportunitiesDrillDownTabsTypes.BY_STORE: {
      orders.push({ direction: direction.ASC, fieldName: fieldName.storeName });
      break;
    }
    case OpenOpportunitiesDrillDownTabsTypes.OTHER: {
      orders.push({ direction: direction.ASC, fieldName: 'level' });
      break;
    }
    default:
      break;
  }

  return orders;
};

export const buildOpenOpportunitiesDrillDownFilters = (
  filters: IOpenOpportunitiesDrillDownFilters,
  opportunitiesLeafHierarchyLevel: number,
  hierarchyPath: IDrillDownHierarchy[],
  cardType: OpenOpportunitiesCardTypes,
  pageOffset: number,
  weeksRange: [number, number],
) => {
  const filterBuilder = new FilterBuilder();

  const { selectedTab } = filters;

  const shouldHaveSearchField = selectedTab !== OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY;

  addDeploymentDateRangeFilter(filterBuilder, weeksRange);
  addDrillDownSearchFilter(filterBuilder, shouldHaveSearchField, filters);
  addStoreProgressStateFilter(filterBuilder, cardType);
  addDrillDownHierarchyFilters(filterBuilder, hierarchyPath, opportunitiesLeafHierarchyLevel);

  // add opportunities filter
  if (selectedTab === OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY) {
    filterBuilder.and().term('state', 'OPENED');
  }

  const filter = filterBuilder.build();

  const orders = getDrillDownOrdersFilter(filters);

  const filterBody: any = {
    orders,
    page: {
      from: pageOffset,
      size: PAGE_SIZE,
    },
  };

  if (!isEmpty(filter)) {
    filterBody.filter = filter;
  }

  return filterBody;
};

export const getWeeksTimePeriod = (selectedWeeks: [number, number]) => {
  return Math.abs(selectedWeeks[0] - selectedWeeks[1]) + 1;
};

export const getAvailableStoreHierarchyLevels = (storeHierarchyLevels: {
  [key: string]: string;
}) => {
  const mappedStoreHierarchyLevels = {};

  Object.keys(storeHierarchyLevels).forEach((key) => {
    const level = mapHierarchyLevelToOrder[mapResponseHierarchyToHierarchyLevel[key]] + 1;
    mappedStoreHierarchyLevels[level] = storeHierarchyLevels[key];
  });

  return mappedStoreHierarchyLevels;
};

export const prepareHierarchyPathForQueryFilter = (path: IDrillDownHierarchy[]) => {
  let updatedPath = [...path];

  // remove 'all' hierarchy item
  if (!isEmptyArray(updatedPath) && updatedPath[0].value === 'all') {
    updatedPath.shift();
  }

  // decrease hierarchy index by 1
  updatedPath = updatedPath.map((item) => {
    if (item.hierarchyLevel) {
      return { ...item, hierarchyLevel: item.hierarchyLevel - 1 };
    }

    return item;
  });

  return updatedPath;
};

export const getCurrentHierarchyName = (id: number, path: IDrillDownHierarchy[]) => {
  let tab = 'By stores';
  if (id === OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY) {
    tab = 'By opportunities';
  } else if (id === OpenOpportunitiesDrillDownTabsTypes.OTHER) {
    tab = `By ${path[path.length - 1].name}`;
  }

  return tab;
};
