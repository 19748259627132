export const getObjectKeys = (obj: object) => {
  return obj ? Object.keys(obj) : [];
};

export const getObjectValues = (obj: object) => {
  return obj ? Object.values(obj) : [];
};

export const isEmpty = (obj: object) => {
  if (!obj) {
    return true;
  }

  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

const ObjectUtils = {
  getObjectKeys,
  isEmpty,
};

export default ObjectUtils;
