import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'clsx';
import { isEmpty } from 'lodash';

import IndicatorLine from 'components/indicator/IndicatorLine';
import {
  ActionTypes,
  ICardProps,
  IndicatorLineStatus,
  mapActionTypeToIndicatorLineStatus,
} from 'types';
import MyOpportunityCardFooter from './MyOpportunityCardFooter';
import './myOpportunityCard.scss';
import ImpactOnSalesChart from 'components/myOpportunities/impactOnSalesChart/ImpactOnSalesChart';

const MyOpportunityCard: FC<ICardProps> = ({
  isSelected,
  targetSales,
  productCategories,
  productName,
  storeName,
  onCardClick,
  id,
  isExpired,
  isNoIssueFound,
  productSkusAndSizes,
  isImpactOnSalesChartEnable,
  currency,
  lastSalesValues,
  postLastSalesValues,
  respondReasonDate,
  isRtl,
  action,
  selectedReason,
}) => {
  const [t] = useTranslation();

  const isNoIssueFoundOrExpired = isNoIssueFound || isExpired;

  const impactOnSalesChartValues = [...lastSalesValues, ...postLastSalesValues];

  const getSku = () => {
    if (isEmpty(productSkusAndSizes)) {
      return '';
    }

    return productSkusAndSizes[0]?.sku || '';
  };

  const onClick = useCallback(() => {
    onCardClick(id);
  }, [id, onCardClick]);

  const indicatorLineState = isNoIssueFoundOrExpired
    ? IndicatorLineStatus.None
    : mapActionTypeToIndicatorLineStatus[action];

  const isIaAction = action === ActionTypes.IA;

  return (
    <div
      className={cn('card-wrapper', {
        'card-with-impact-on-sales-chart': isImpactOnSalesChartEnable,
        'no-issue-found-or-expired': isNoIssueFoundOrExpired,
        'completed-with-reason': !!selectedReason,
        ia: isIaAction,
      })}
    >
      <div
        role="button"
        tabIndex={0}
        className={cn('card-container', { 'active-card': isSelected })}
        onClick={onClick}
      >
        <div className="card-header">
          <IndicatorLine status={indicatorLineState} />
          <div className="card-header-text-container">
            <div className="title-container">
              <div className="card-title">{productName}</div>
              {isExpired && (
                <div className="expired-task">
                  <span>{t('MY_OPPORTUNITIES.OPPORTUNITY_EXPIRED')}</span>
                </div>
              )}
            </div>
            <div className="card-id">
              {`${t('MY_OPPORTUNITIES.ITEM_ID')}:`}
              <div className="card-id-text">{getSku()}</div>
            </div>
          </div>
        </div>
        <MyOpportunityCardFooter
          currency={currency}
          productCategories={productCategories![0]}
          storeName={storeName}
          targetSales={targetSales}
        />

        {isImpactOnSalesChartEnable && (
          <ImpactOnSalesChart
            isRtl={isRtl}
            currency={currency}
            isNoIssueFoundOrExpired={isNoIssueFoundOrExpired}
            chartValues={impactOnSalesChartValues}
            respondReasonDate={respondReasonDate}
          />
        )}
        {selectedReason && (
          <div className="reason-code-footer">
            <span className="reason">{selectedReason.title}</span>
            <span className="description">{selectedReason.description}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOpportunityCard;
