import { ISale, CurrencySymbolOptions, ActionTypes, IProductSkuAndSize } from 'types';

export interface ICard {
  productName: string;
  productSkusAndSizes: Array<IProductSkuAndSize>;
  productCategories: { [key: number]: string };
  storeName: string;
  targetSales: number;
  targetUnits: number;
  lastSalesValues: Array<ISale>;
  postLastSalesValues: Array<ISale>;
  id: string;
}

export interface ICardProps extends ICard {
  onCardClick(id: any): void;

  productImage: string | null;
  respondReasonDate: number;
  isImpactOnSalesChartEnable?: boolean;
  isSelected?: boolean;
  isNoIssueFound?: boolean;
  isExpired: boolean;
  action: ActionTypes;
  currency: CurrencySymbolOptions;
  isRtl?: boolean;
  selectedReason?: { title: string; description?: string };
}

export enum ResponseTypes {
  ISSUE_FOUND = 'ISSUE_FOUND',
  NO_ISSUE_FOUND = 'NO_ISSUE_FOUND',
}

export enum LifecycleOptions {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
}

export interface IImpactOnSalesChart {
  chartValues: Array<ISale>;
  respondReasonDate: number;
  currency: CurrencySymbolOptions;
  isNoIssueFoundOrExpired: boolean;
  isRtl?: boolean;
}
