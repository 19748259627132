import { FC } from 'react';

import LineChart from 'components/trends/lineChart/LineChart';
import RateComparisonCard from 'components/summary/rateComparisonCard/RateComparisonCard';
import 'components/pages/summary/summary.scss';
import { isEmptyArray } from 'utils';
import { animationType, IRateComparisonChartContainer } from 'types';
import { ProgressAnimation } from 'components/animation';

const RateComparisonChartContainer: FC<IRateComparisonChartContainer> = ({
  activeComparisonTab,
  rate,
  lineChartData,
  isRtl,
  emptyStateLabel,
  peersLevel,
  isLoading,
  onSeeDetailsClick,
}) => {
  if (Array.isArray(lineChartData) && isEmptyArray(lineChartData)) {
    return (
      <div className="empty-state-container">
        <span>{emptyStateLabel}</span>
      </div>
    );
  }

  return (
    <>
      <ProgressAnimation animationType={animationType.Spinner} isLoading={isLoading} />
      <RateComparisonCard
        tabId={activeComparisonTab}
        rate={rate}
        peersLevel={peersLevel}
        onSeeDetailsClick={onSeeDetailsClick}
      />
      {lineChartData && (
        <LineChart
          isWeeklyResolution={false}
          isRtl={isRtl}
          shouldDisplayYearLineLabel
          isSmallChart
          isSummaryPage
          shouldDisplayVerticalGrid
          data={lineChartData}
          shouldDisplayLegend={false}
        />
      )}
    </>
  );
};

export default RateComparisonChartContainer;
