import cn from 'clsx';
import { FC } from 'react';
import './RateCounter.scss';

type RateCounterProps = {
  rate: number;
  total: number;
  className?: string;
};

const RateCounter: FC<RateCounterProps> = ({ rate, total, className }) => (
  <div className={cn('rate-counter-wrapper', className)}>
    {rate}/{total}
  </div>
);

export default RateCounter;
