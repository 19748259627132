import {
  CurrencySymbolOptions,
  DrillDownTypes,
  IFilterItem,
  IFindingsDrillDown,
  IReasonFilterGroups,
} from 'types';

export interface IFindingsPieChartItem {
  value: number;
  color: string;
  isActive: boolean;
  noLegend?: boolean;
  id: number;
}

export enum FindingsPieChartLocation {
  Findings,
  Summary,
}

export interface IFindingsPieChart {
  totalCount: number | null;
  data: Array<IFindingsPieChartItem>;
  isAllCellsActive: boolean;
  onCellClick(groupId: number, isActive: boolean): void;
  sizeType: PieChartSizeTypes;
  emptyStateLabel?: string;
  canToggleActiveCell: boolean;
  location?: FindingsPieChartLocation;
  isRtl?: boolean;
  className?: string;
}

export enum AlignChartLegend {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ValueType {
  Percent = '%',
  Number = '#',
}

export enum PieChartSizeTypes {
  SMALL = 'small',
  REGULAR = 'regular',
}

export interface IReasonFilter {
  label: string;
  value: number;
  id: number;
  isActive: boolean;
}

export interface IReasonFilterGroup {
  color: string;
  value: number;
  label: string;
  id: number;
  selectedReasonsCount: number;
  reasonFilter: Array<IReasonFilter>;
}

export const EMPTY_STATE_THEME = [
  { color: 'rgba(170,170,170,0.6)', value: 20 },
  { color: 'rgba(153,153,153,0.6)', value: 15 },
  { color: 'rgba(136,136,136,0.6)', value: 10 },
  { color: 'rgba(119,119,119,0.6)', value: 5 },
  { color: 'rgba(102,102,102,0.6)', value: 50 },
];

export const EMPTY_STATE_DEFAULT_LABEL = '';

export interface IReasonFilterProps extends IReasonFilter {
  type: ValueType;

  onReasonClick(id: number): void;
}

export interface IReasonFilterGroupProps {
  list: Array<IReasonFilter>;
  type: ValueType;
  color: string;
  groupId: number;
  onReasonClick(reasonId: number, groupId: number): void;
}

export interface IFindingsPreviewHeader {
  totalCount: number;
  totalIssueFound: number;
  valueType: ValueType;
  onValueTypeChange(valueType: ValueType): void;
}

export interface IFindingsPreview {
  isLoading: boolean;
  totalCount: number;
  issueFoundTotalCount: number;
  valueType: ValueType;
  reasonsFilterGroups: IReasonFilterGroups | null;
  searchText: string;
  isFiltersApplied: boolean;
  isRtl?: boolean;
  changeNumberRepresentation(valueType: ValueType): void;
  toggleGroupSelection(groupId: number, isActive: boolean): void;
  clearAllSelection(): void;
  toggleReasonFilter(reasonId: number, groupId: number): void;
}

export interface IDrillDowns {
  storeDrillDown?: IFindingsDrillDown;
  productDrillDown?: IFindingsDrillDown;
  otherDrillDown?: IFindingsDrillDown;
  onOpportunityClick(selectedOpportunityId: string): void;
  onLoadMore(drillDownType: DrillDownTypes): void;
  onTitleClick(): void;
}

export interface IFindingsDrillDownWrapper {
  id: string;
  expendedDrillDown: DrillDownTypes | null;
  type: DrillDownTypes;
  currency: CurrencySymbolOptions;
  drillDown?: IFindingsDrillDown;
  isFiltersApplied: boolean;
  isRtl?: boolean;
  onOpportunityClick(selectedOpportunityId: string): void;
  onLoadMore(drillDownType: DrillDownTypes): void;
  onToggleDirection(type: DrillDownTypes): void;
  onRemoveFilter(filter: IFilterItem): void;
  onHierarchyPathClick(hierarchyItemIndex: number, type: DrillDownTypes): void;
  onSearchChange(text: string, type: DrillDownTypes): void;
  onExpendedToggle(type: DrillDownTypes): void;
  onFreeTextToggle(type: DrillDownTypes): void;
  onFilterClick(filter: IFilterItem, type: DrillDownTypes): void;
  onShowOpportunitiesClick(type: DrillDownTypes): void;
  onTitleClick(): void;
}
