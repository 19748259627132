import { LOCATION_CHANGE } from 'connected-react-router';

import {
  IActionType,
  OpenOpportunitiesDrillDownTabsTypes,
  OpenOpportunitiesDropDownOptionsTypes,
} from 'types';
import OpenOpportunitiesActionTypes from './openOpportunitiesActionTypes';
import {
  buildFindingsReasonsFilters,
  buildOpenOpportunitiesDrillDownFilters,
  getDrillDownItemsEndpointRoute,
  getWeeksTimePeriod,
} from './openOpportunitiesHelper';
import {
  clearFilters,
  getFilteredDrillDownItems,
  getFindingsReasons,
  getOpportunities,
} from './openOpportunitiesActions';

const openOpportunitiesMiddleware = ({ dispatch, getState }: any) => (next: any) => (
  action: IActionType,
) => {
  const { type, payload } = action;

  switch (type) {
    case OpenOpportunitiesActionTypes.getDrillDownItems: {
      const { pageOffset = 0, cardType } = payload;

      const { selectedWeeks, opportunitiesCountByWeek } = getState().openOpportunities;

      const deploymentWeeksRange: [number, number] = [
        opportunitiesCountByWeek[selectedWeeks[0]]?.week,
        opportunitiesCountByWeek[selectedWeeks[1]]?.week,
      ];

      const weeksTimePeriod = getWeeksTimePeriod(selectedWeeks);

      const { filters, hierarchyPath, navigationTabs } = getState().openOpportunities.drillDown;

      const { hierarchyIndex, selectedTab, selectedDropdownOption } = filters;

      const shouldGetStores = selectedTab === OpenOpportunitiesDrillDownTabsTypes.BY_STORE;

      const shouldGetStoreCount =
        selectedDropdownOption === OpenOpportunitiesDropDownOptionsTypes.NUMBER_OF_STORES;

      const urlString = getDrillDownItemsEndpointRoute(
        hierarchyIndex,
        shouldGetStores,
        shouldGetStoreCount,
      );

      const opportunitiesLeafHierarchyLevel =
        navigationTabs[OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY];

      const filter = buildOpenOpportunitiesDrillDownFilters(
        filters,
        opportunitiesLeafHierarchyLevel,
        hierarchyPath,
        cardType,
        pageOffset,
        deploymentWeeksRange,
      );

      if (selectedTab === OpenOpportunitiesDrillDownTabsTypes.BY_OPPORTUNITY) {
        const { productImagesMetadata } = getState().settings.general;

        const findingsReasonsFilter = buildFindingsReasonsFilters(
          weeksTimePeriod,
          hierarchyPath,
          opportunitiesLeafHierarchyLevel,
        );
        dispatch(getOpportunities(filter, productImagesMetadata || []));
        dispatch(getFindingsReasons(findingsReasonsFilter));
      } else {
        dispatch(getFilteredDrillDownItems(filter, urlString));
      }

      break;
    }

    // clear filters when navigating between pages
    case LOCATION_CHANGE: {
      const { isFirstRendering } = payload;

      if (!isFirstRendering) {
        dispatch(clearFilters());
      }

      break;
    }

    default:
      break;
  }

  next(action);
};

export default openOpportunitiesMiddleware;
