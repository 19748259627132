import PrintActionTypes from 'store/print/printActionTypes';
import { IPrintModel } from './types';

export const show = ({ id, selectedPrintingOption }: IPrintModel) => ({
  type: PrintActionTypes.Show,
  payload: { id, selectedPrintingOption },
});

export const hide = () => ({
  type: PrintActionTypes.Hide,
});

const PrintActions = {
  show,
  hide,
};

export default PrintActions;
