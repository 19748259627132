import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import permissionsSrv from 'services/permissions/permissionsSrv';
import { getMonthDayFormat, toLanguageNumberFormat, toPercentage } from 'utils';
import {
  AlertButtonsIds,
  AlertIds,
  DrillDownTypes,
  IAlertModel,
  IDate,
  IRootState,
  ISearchParamsFilters,
  IWeeklyComparisonWrapper,
  SelectedDateIntervals,
  SummaryCardIds,
  SummaryCardTypes,
  TrendsTabOptions,
} from 'types';
import SummaryCard from 'components/summary/summaryCard/SummaryCard';
import 'components/pages/summary/summary.scss';
import { getOpenOpportunities, getRateComparison } from 'store/summary/summaryActions';
import { show as showRatePopup } from 'store/alert/alertActions';
import SummarySelector from 'store/summary/summarySelector';
import RouterService from 'services/router/routerService';
import LoadingSelector from 'store/loading/LoadingSelector';
import { buildQueryParamsFromSearchFilters } from 'routes/routesHelper';
import { MILLISECONDS } from 'constants/constantsVaribles';
import { getStoresCategory } from 'store/filters/filterHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

const WeeklyComparisonWrapper: FC<IWeeklyComparisonWrapper> = ({
  numberOfWeeksForResponseRateComparison,
  numberOfWeeksForHitRateComparison,
  responseRateValue,
  responseRateCompareToValue,
  hitRateValue,
  hitRateCompareToValue,
  numberOfOpenTasks,
  openTasksDate,
  lastUserDeployDate,
  showRatePopup,
  responseRateArrowDirection,
  hitRateArrowDirection,
  getRateComparison,
  getOpenOpportunities,
  isLoadingOpenOpportunities,
  isLoadingResponseRateComparison,
  isLoadingHitRateComparison,
  selectedStoreFilter,
  selectedCategory,
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    getRateComparison();
    getOpenOpportunities(selectedStoreFilter?.storeName);
  }, [selectedStoreFilter]);

  const handleRouteToTrends = (cardId: SummaryCardIds) => {
    const weekFromDeployDate = new Date(lastUserDeployDate).getTime() - MILLISECONDS.WEEK + 1;

    const customDate: IDate = {
      type: SelectedDateIntervals.customId,
      from: weekFromDeployDate,
      to: lastUserDeployDate + 1,
    };

    const trendsSelectedTab =
      cardId === SummaryCardIds.HIT_RATE ? TrendsTabOptions.HitRate : TrendsTabOptions.ResponseRate;

    // build search filters
    const searchFilters: ISearchParamsFilters = {
      date: customDate,
      tab: trendsSelectedTab,
      [DrillDownTypes.Other]: {
        category: selectedStoreFilter ? getStoresCategory() : selectedCategory,
        filter: {
          value: selectedStoreFilter?.id || '',
          name: selectedStoreFilter?.storeName || '',
          hierarchyName: '',
        },
      },
    };

    AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_TRENDS, {
      tab_name: trendsSelectedTab,
      time_span: SelectedDateIntervals.lastDeployment,
    });

    const queryParams = buildQueryParamsFromSearchFilters(searchFilters);
    RouterService.routeToTrends(queryParams);
  };

  const onQuestionMarkClick = useCallback(
    (cardId: SummaryCardIds) => {
      const cardType = cardId === SummaryCardIds.RESPONSE_RATE ? 'RESPONSE_RATE' : 'HIT_RATE';

      const alertId =
        cardId === SummaryCardIds.RESPONSE_RATE
          ? AlertIds.OpenSummaryResponseRateInfo
          : AlertIds.OpenSummaryHitRateInfo;

      const options: IAlertModel = {
        isVisible: true,
        id: alertId,
        withOverlay: true,
        title: t(`COMMON.${cardType}`),
        text: t(`SUMMARY.${cardType}_POPUP`),
        buttons: [{ id: AlertButtonsIds.OK, text: AlertButtonsIds.OK }],
        alertCustomClassName: 'summary-rate-popup',
      };

      showRatePopup(options);
    },
    [showRatePopup],
  );

  const handleShowRatePopup = (cardId: SummaryCardIds) => {
    onQuestionMarkClick(cardId);
  };

  const responseRateSubText =
    numberOfWeeksForResponseRateComparison > 1
      ? t('SUMMARY.VS_AVERAGE', {
          average: toPercentage(responseRateCompareToValue),
        })
      : '';

  const hitRateSubText =
    numberOfWeeksForHitRateComparison > 1
      ? t('SUMMARY.VS_AVERAGE', {
          average: toPercentage(hitRateCompareToValue),
        })
      : '';

  const navigateToOpportunities = () => {
    if (permissionsSrv.mayViewOpportunities() && permissionsSrv.isSingleStoreManager()) {
      AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_MY_OPPORTUNITIES);
      RouterService.routeToMyOpportunities();
    } else if (!permissionsSrv.isSingleStoreManager()) {
      AnalyticSrv.sendClickEvent(AnalyticsEventCategory.SUMMARY_TO_OPEN_OPPORTUNITIES);
      RouterService.routeToOpenOpportunities();
    }
  };

  const allowOpportunitiesNavigation =
    !permissionsSrv.isSingleStoreManager() ||
    (permissionsSrv.mayViewOpportunities() && permissionsSrv.isSingleStoreManager());

  return (
    <>
      <SummaryCard
        cardId={SummaryCardIds.OPEN_OPPORTUNITIES}
        cardType={SummaryCardTypes.OPEN_OPPORTUNITIES}
        headerText={t('PAGES.OPPORTUNITIES')}
        mainText={`${toLanguageNumberFormat(numberOfOpenTasks)}`}
        footerInfoText={t('SUMMARY.SINCE_WEEK_OF', { week: openTasksDate })}
        onSeeDetailsClick={navigateToOpportunities}
        isLoading={isLoadingOpenOpportunities}
        shouldDisplayAction={allowOpportunitiesNavigation}
      />
      <SummaryCard
        cardId={SummaryCardIds.RESPONSE_RATE}
        cardType={SummaryCardTypes.RATE}
        headerText={t('COMMON.RESPONSE_RATE')}
        mainText={`${toPercentage(responseRateValue)}`}
        subText={responseRateSubText}
        arrowIconDirection={responseRateArrowDirection}
        footerInfoText={t('MY_OPPORTUNITIES.WEEK_OF', {
          week: getMonthDayFormat(lastUserDeployDate),
        })}
        showRatePopup={() => handleShowRatePopup(SummaryCardIds.RESPONSE_RATE)}
        onSeeDetailsClick={() => handleRouteToTrends(SummaryCardIds.RESPONSE_RATE)}
        isLoading={isLoadingResponseRateComparison}
      />

      <SummaryCard
        cardId={SummaryCardIds.HIT_RATE}
        cardType={SummaryCardTypes.RATE}
        headerText={t('COMMON.HIT_RATE')}
        mainText={`${toPercentage(hitRateValue)}`}
        subText={hitRateSubText}
        arrowIconDirection={hitRateArrowDirection}
        footerInfoText={t('MY_OPPORTUNITIES.WEEK_OF', {
          week: getMonthDayFormat(lastUserDeployDate),
        })}
        showRatePopup={() => handleShowRatePopup(SummaryCardIds.HIT_RATE)}
        onSeeDetailsClick={() => handleRouteToTrends(SummaryCardIds.HIT_RATE)}
        isLoading={isLoadingHitRateComparison}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    numberOfOpenTasks,
    numberOfWeeksForResponseRateComparison,
    numberOfWeeksForHitRateComparison,
    responseRateValue,
    responseRateCompareToValue,
    hitRateValue,
    hitRateCompareToValue,
    selectedStoreFilter,
  } = state.summary;

  const { lastUserDeployDate } = state.userProfile;

  const responseRateArrowDirection = SummarySelector.getResponseRateArrowDirection(state);

  const hitRateArrowDirection = SummarySelector.getHitRateArrowDirection(state);

  const openTasksDate = SummarySelector.getOpenTasksDate(state);

  const isLoadingOpenOpportunities = LoadingSelector.isGetOpenOpportunitiesLoading(state);

  const isLoadingResponseRateComparison = LoadingSelector.isGetSummaryResponseRateComparison(state);

  const isLoadingHitRateComparison = LoadingSelector.isGetSummaryHitRateComparison(state);

  return {
    numberOfWeeksForResponseRateComparison,
    numberOfWeeksForHitRateComparison,
    responseRateValue,
    responseRateCompareToValue,
    hitRateValue,
    hitRateCompareToValue,
    responseRateArrowDirection,
    hitRateArrowDirection,
    numberOfOpenTasks,
    lastUserDeployDate,
    openTasksDate,
    isLoadingOpenOpportunities,
    isLoadingResponseRateComparison,
    isLoadingHitRateComparison,
    selectedStoreFilter,
  };
};

const mapDispatchToProps = {
  getOpenOpportunities,
  showRatePopup,
  getRateComparison,
};

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyComparisonWrapper);
