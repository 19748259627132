import { flow, set } from 'lodash/fp';
import { isEmpty } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';

import { SIZE } from 'constants/constantsVaribles';
import {
  DefaultGroupByOption,
  direction,
  DrillDownCategoriesTypes,
  DrillDownTypes,
  fieldName,
  IActionType,
  IFilterItem,
  IFindingsDrillDownFilters,
  IFindingsDrillDownModal,
  IFindingsModel,
  IHierarchy,
  SelectedDateIntervals,
  ValueType,
} from 'types';
import FindingsActionTypes from './findingsActionTypes';
import {
  accumulateGroupContentAndGroupCount,
  getDrillDownListByHitRate,
  getFindingsReasonsFilterGroup,
  getOpportunities,
  getProductsByHitRate,
  getStoresByHitRate,
} from 'store/api/apiParser';
import { buildStateToPushPostDrillDownRequest } from 'store/helpers/drillDown';
import { getCurrentDate, isEmptyArray } from 'utils';
import { RoutePath } from 'routes/types';
import { buildSearchFiltersOnLocationChange } from 'store/filters/filterHelper';
import { buildFiltersFromSearchParams } from './findingsHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';

export const DropDownFiltersInitialState: IFindingsDrillDownFilters = {
  // Filters that effects only the specific drill down
  activeFilters: {
    order: {
      direction: direction.DESC,
      fieldName: fieldName.issueFoundCount,
    },
    selectedCategory: undefined,
    isOpportunitySelected: false,
  },
  // holds the drillDown mutual/interactive data.
  // when a drillDown has changed and update the data under mutualFilter, its explicit.
  // meaning - effects the rest of the drillDowns as well, which also need to change and update.
  mutualFilters: {
    isFreeTextOnly: false,
    search: '',
    hierarchyIndex: 1,
  },
  nextPageOffset: 0,
  filters: [],
  hasMore: true,
  hierarchyPath: [],
};

const DropBoxInitialState: IFindingsDrillDownModal = {
  list: null,
  opportunities: null,
  highestValue: 1,
  totalCount: 0,
  isLoading: false,
};

export const initialState: IFindingsModel = {
  reasonFilterGroupsMap: null,
  reasonFilterToColorMap: {},
  reasonFilterGroups: null,
  totalCount: 0,
  issueFoundTotalCount: 0,
  valueType: ValueType.Percent,
  [DrillDownTypes.Store]: DropBoxInitialState,
  [DrillDownTypes.Product]: DropBoxInitialState,
  [DrillDownTypes.Other]: DropBoxInitialState,
  filters: {
    date: {
      type: SelectedDateIntervals.monthId,
      from: getCurrentDate(),
      to: getCurrentDate(),
    },
    expendedDrillDown: null,
    activeReasonFilters: [],
    order: {
      direction: direction.DESC,
      fieldName: fieldName.count,
    },
    [DrillDownTypes.Store]: DropDownFiltersInitialState,
    [DrillDownTypes.Product]: DropDownFiltersInitialState,
    [DrillDownTypes.Other]: DropDownFiltersInitialState,
  },
};

const findingsReducer = (state: IFindingsModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    case FindingsActionTypes.getDrillDown: {
      const { drillDownType } = payload;

      return flow([set(`${drillDownType}.isLoading`, true)])(state);
    }
    case FindingsActionTypes.getFindingsReasons.SUCCESS: {
      const { queryStats = { issueFoundTotalCount: 0 }, rows, totalCount } = payload;

      const { reasons } = requestPayload;

      const stateToPush = [];

      const {
        filters: { activeReasonFilters },
        reasonFilterToColorMap: prevReasonFilterToColorMap,
      } = state;

      const { issueFoundTotalCount } = queryStats;

      const {
        reasonFilterGroupsMap,
        reasonFilterGroups,
        reasonFilterToColorMap,
      } = getFindingsReasonsFilterGroup(rows, reasons, prevReasonFilterToColorMap);

      if (!isEmptyArray(activeReasonFilters)) {
        stateToPush.push(set(`filters[activeReasonFilters]`, []));
      }
      stateToPush.push(set('reasonFilterGroups', reasonFilterGroups));
      stateToPush.push(set('reasonFilterGroupsMap', reasonFilterGroupsMap));
      stateToPush.push(set('reasonFilterToColorMap', reasonFilterToColorMap));
      stateToPush.push(set('issueFoundTotalCount', issueFoundTotalCount));
      stateToPush.push(set('totalCount', totalCount));

      return flow(stateToPush)(state);
    }

    case FindingsActionTypes.changeNumberRepresentation: {
      const { valueType } = payload;

      return flow([set('valueType', valueType)])(state);
    }

    case FindingsActionTypes.toggleReasonFilter: {
      const { reasonId, groupId } = payload;

      const {
        reasonFilterGroupsMap,
        filters: { activeReasonFilters },
      } = state;

      if (!reasonFilterGroupsMap) {
        return state;
      }

      const { reasonFilter } = reasonFilterGroupsMap[groupId];

      const reasonFilterIndex = reasonFilter.findIndex((reason) => reason.id === reasonId);

      const reasonFilterItem = reasonFilter[reasonFilterIndex];

      const { isActive, id } = reasonFilterItem;

      const updatedIsActive = !isActive;
      let updateActiveReasonFilter = [...activeReasonFilters];

      if (updatedIsActive) {
        updateActiveReasonFilter.push(id);
      } else {
        updateActiveReasonFilter = updateActiveReasonFilter.filter((reasonId) => reasonId !== id);
      }

      return flow([
        set(
          `reasonFilterGroupsMap[${groupId}].reasonFilter[${reasonFilterIndex}].isActive`,
          updatedIsActive,
        ),
        set(`filters[activeReasonFilters]`, updateActiveReasonFilter),
      ])(state);
    }

    case FindingsActionTypes.toggleGroupSelection: {
      const { isActive, groupId } = payload;

      const {
        reasonFilterGroupsMap,
        filters: { activeReasonFilters },
      } = state;

      if (!reasonFilterGroupsMap) {
        return state;
      }

      const { reasonFilter } = reasonFilterGroupsMap[groupId];

      const updatedReasonFilter = reasonFilter.map((reason) => ({
        value: reason.value,
        id: reason.id,
        label: reason.label,
        isActive,
      }));

      let updateActiveReasonFilter = [...activeReasonFilters];

      if (isActive) {
        reasonFilter.forEach(({ id }) => updateActiveReasonFilter.push(id));
      } else {
        updateActiveReasonFilter = updateActiveReasonFilter.filter(
          (reasonId) => !reasonFilter.find(({ id }) => id === reasonId),
        );
      }

      return flow([
        set(`reasonFilterGroupsMap[${groupId}].reasonFilter`, updatedReasonFilter),
        set(`filters[activeReasonFilters]`, updateActiveReasonFilter),
      ])(state);
    }

    case FindingsActionTypes.clearAllSelection: {
      const { reasonFilterGroupsMap } = state;

      if (!reasonFilterGroupsMap) {
        return state;
      }

      const updateReasonFilterGroup = { ...reasonFilterGroupsMap };
      Object.values(updateReasonFilterGroup).forEach((reasonFilterGroup) => {
        const { reasonFilter: currentReasonFilter } = reasonFilterGroup;
        reasonFilterGroup.reasonFilter = currentReasonFilter.map((reasonFilterItem) => {
          const { value, id, label } = reasonFilterItem;

          return { value, id, label, isActive: false };
        });
      });

      return flow([
        set(`reasonFilterGroupsMap`, updateReasonFilterGroup),
        set(`filters[activeReasonFilters]`, []),
      ])(state);
    }

    case FindingsActionTypes.setDateInterval: {
      const { type, selectedDates } = payload;

      const stateToUpdate = [set('filters[date].type', type)];

      if (selectedDates) {
        const { from, to } = selectedDates;
        stateToUpdate.push(set('filters[date].from', from));
        stateToUpdate.push(set('filters[date].to', to));
      }

      AnalyticSrv.sendDateChangedEvent(type, AnalyticsEventCategory.DATE_CHANGED);

      return flow(stateToUpdate)(state);
    }

    case FindingsActionTypes.toggleDirection: {
      const { drillDownType } = payload;

      const { direction: currentDirection } = state.filters[drillDownType].activeFilters.order;
      let updateDirection = direction.ASC;
      if (currentDirection === direction.ASC) {
        updateDirection = direction.DESC;
      }

      return flow([
        set(`filters[${drillDownType}].activeFilters[order].direction`, updateDirection),
      ])(state);
    }

    case FindingsActionTypes.toggleExpended: {
      const { drillDownType } = payload;

      const {
        activeFilters: { isOpportunitySelected },
        mutualFilters: { search, isFreeTextOnly },
      } = state.filters[drillDownType];

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const updatedExpendedDrillDown = expendedDrillDown === drillDownType ? null : drillDownType;

      const stateToPush = [set(`filters[expendedDrillDown]`, updatedExpendedDrillDown)];

      if (!isEmpty(search)) {
        stateToPush.push(set(`filters[${drillDownType}].mutualFilters.search`, ''));
      }
      // prevent jump on transitions
      if (!updatedExpendedDrillDown) {
        // small list that contains max 3 items to prevent jump on transitions
        const tempList = list.slice(0, 3);
        stateToPush.push(set(`${drillDownType}.list`, tempList));
        stateToPush.push(set(`${drillDownType}.totalCount`, tempList.length));
      }

      // checking if we close opportunities
      if (isOpportunitySelected) {
        stateToPush.push(
          set(`filters[${drillDownType}].activeFilters.isOpportunitySelected`, false),
        );
        if (isFreeTextOnly) {
          stateToPush.push(set(`filters[${drillDownType}].mutualFilters.isFreeTextOnly`, false));
        }

        stateToPush.push(
          set(`filters[${drillDownType}].activeFilters.order.fieldName`, fieldName.issueFoundCount),
        );

        const isOtherDrillDown = drillDownType === DrillDownTypes.Other;

        if (isOtherDrillDown) {
          // in other -> set the selectedCategory to default
          stateToPush.push(
            set(`filters[${drillDownType}].activeFilters.selectedCategory`, undefined),
          );
        }
      }

      return flow(stateToPush)(state);
    }

    case FindingsActionTypes.removeDrillDownFilter: {
      const {
        filter: { value, hierarchyName, drillDownType },
      } = payload;

      const { filters } = state.filters[drillDownType];

      // Remove the filter from array
      const updateFilters = filters.filter(
        (filterItem: IFilterItem) =>
          !(filterItem.value === value && filterItem.hierarchyName === hierarchyName),
      );

      return flow([
        set(`filters[${drillDownType}].filters`, updateFilters),
        set(`filters[${drillDownType}].hierarchyPath`, []),
        set(`filters[${drillDownType}].mutualFilters.hierarchyIndex`, 1),
      ])(state);
    }

    case FindingsActionTypes.addFilter: {
      const { filter, drillDownType } = payload;

      const {
        filters,
        hierarchyPath,
        mutualFilters: { hierarchyIndex },
      } = state.filters[drillDownType];

      const { name, value, hierarchyName, hierarchyValue, categoryType } = filter;

      const isFilterExists = filters.find(
        (filterItem: any) =>
          filterItem.hierarchyName === hierarchyName && filterItem.value === value,
      );

      const stateToPush = [];

      // Checking if the filter already exists
      if (!isFilterExists) {
        const nextHierarchyIndex = hierarchyIndex + 1;

        const currentFilter: IFilterItem = {
          name,
          value,
          hierarchyName,
          drillDownType,
        };

        if (categoryType && hierarchyValue) {
          currentFilter.categoryType = categoryType;
          currentFilter.hierarchyValue = hierarchyValue;
        }

        const updateFilters: Array<IFilterItem> = [currentFilter];

        let updateHierarchyPath: IHierarchy[] = [
          ...hierarchyPath,
          {
            name,
            value,
            hierarchyName,
            hierarchyLevel: hierarchyIndex,
          },
        ];

        // For all drill down only the last filter visible
        // for other all filters visible
        if (drillDownType === DrillDownTypes.Other) {
          updateFilters.unshift(...filters);
          updateHierarchyPath = [];
        }
        stateToPush.push(set(`filters[${drillDownType}].filters`, updateFilters));
        stateToPush.push(set(`filters[${drillDownType}].hierarchyPath`, updateHierarchyPath));
        stateToPush.push(
          set(`filters[${drillDownType}].mutualFilters.hierarchyIndex`, nextHierarchyIndex),
        );
      }

      return flow(stateToPush)(state);
    }

    case FindingsActionTypes.onDrillDownSearch: {
      const { search, drillDownType } = payload;

      return flow([set(`filters[${drillDownType}].mutualFilters.search`, search)])(state);
    }

    case FindingsActionTypes.sliceHierarchyPath: {
      const { hierarchyItemIndex, drillDownType } = payload;

      const { hierarchyPath } = state.filters[drillDownType];

      // get new update hierarchy path
      const updateHierarchyPath = hierarchyPath.slice(0, hierarchyItemIndex);

      let filters: any = [];

      // update filter to hold only the last hierarchy
      if (hierarchyItemIndex > 0) {
        filters = [hierarchyPath[hierarchyItemIndex - 1]];
      }

      return flow([
        set(`filters[${drillDownType}].hierarchyPath`, updateHierarchyPath),
        set(`filters[${drillDownType}].mutualFilters.hierarchyIndex`, hierarchyItemIndex + 1),
        set(`filters[${drillDownType}].activeFilters.isOpportunitySelected`, false),
        set(`filters[${drillDownType}].activeFilters.order.fieldName`, fieldName.issueFoundCount),
        set(`filters[${drillDownType}].filters`, filters),
      ])(state);
    }

    case FindingsActionTypes.getProducts.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const {
        order: { direction },
      } = state.filters[drillDownType].activeFilters;

      const products = getProductsByHitRate(rows);

      const isExpended = expendedDrillDown === drillDownType;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        products,
        isExpended,
        totalCount,
        direction,
      );

      return flow(stateToPush)(state);
    }

    case FindingsActionTypes.getStores.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const {
        order: { direction },
      } = state.filters[drillDownType].activeFilters;

      const isExpended = expendedDrillDown === drillDownType;

      const stores = getStoresByHitRate(rows);

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        stores,
        isExpended,
        totalCount,
        direction,
      );

      return flow(stateToPush)(state);
    }

    case FindingsActionTypes.getHierarchyDrillDownData.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const isExpended = expendedDrillDown === drillDownType;

      const responseList = getDrillDownListByHitRate(rows);

      const {
        order: { direction },
      } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        responseList,
        isExpended,
        totalCount,
        direction,
      );

      return flow(stateToPush)(state);
    }

    case FindingsActionTypes.getOpportunities.SUCCESS: {
      const { drillDownType, pageOffset, productImagesMetadata } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { opportunities } = state[drillDownType];
      let prevOpportunities = opportunities;
      if (!opportunities || pageOffset === 0) {
        prevOpportunities = {
          list: [],
          groupContent: [],
          groupCount: [],
        };
      }

      const {
        opportunities: opportunitiesList,
        cardsGroupHeader,
        cardsGroupCount,
      } = getOpportunities(rows, DefaultGroupByOption, productImagesMetadata);

      const { groupContent, groupCount } = accumulateGroupContentAndGroupCount(
        prevOpportunities.groupCount,
        prevOpportunities.groupContent,
        cardsGroupCount,
        cardsGroupHeader,
      );

      const list = [...prevOpportunities.list, ...opportunitiesList];

      const updatedOpportunities = { list, groupContent, groupCount };

      const hasMore = list.length < totalCount;

      const nextPageOffset = pageOffset + SIZE;

      return flow([
        set(`${drillDownType}.opportunities`, updatedOpportunities),
        set(`filters[${drillDownType}].hasMore`, hasMore),
        set(`${drillDownType}.totalCount`, totalCount),
        set(`${drillDownType}.isLoading`, false),
        set(`filters[${drillDownType}].nextPageOffset`, nextPageOffset),
      ])(state);
    }

    case FindingsActionTypes.setOtherDrillDownSelectedCategory: {
      const { value, label, id: type } = payload;

      const drillDownType = DrillDownTypes.Other;

      const { isFreeTextOnly } = state.filters[drillDownType].mutualFilters;

      const stateToPush = [];

      const selectedCategory = { value, label, type };
      let updatedFieldName = fieldName.issueFoundCount;

      const isOpportunitySelected = selectedCategory.type === DrillDownCategoriesTypes.Opportunity;

      if (isOpportunitySelected) {
        updatedFieldName = fieldName.deployDate;
        stateToPush.push(set(`filters[expendedDrillDown]`, DrillDownTypes.Other));
      } else if (isFreeTextOnly) {
        stateToPush.push(set(`filters[${drillDownType}].mutualFilters.isFreeTextOnly`, false));
      }
      stateToPush.push(set(`filters[${drillDownType}].filters`, []));
      stateToPush.push(
        set(`filters[${drillDownType}].activeFilters.isOpportunitySelected`, isOpportunitySelected),
      );
      stateToPush.push(
        set(`filters[${drillDownType}].activeFilters.selectedCategory`, selectedCategory),
      );
      stateToPush.push(
        set(`filters[${drillDownType}].activeFilters.order.fieldName`, updatedFieldName),
      );

      return flow(stateToPush)(state);
    }
    case FindingsActionTypes.onShowOpportunities: {
      const { drillDownType } = payload;

      return flow([
        set(`filters[${drillDownType}].activeFilters.isOpportunitySelected`, true),
        set(`filters[${drillDownType}].activeFilters.order.fieldName`, fieldName.deployDate),
        set(`filters[expendedDrillDown]`, drillDownType),
      ])(state);
    }
    case FindingsActionTypes.toggleFreeTextOnly: {
      const { drillDownType } = payload;

      const {
        mutualFilters: { isFreeTextOnly: prevIsFreeTextOnly },
      } = state.filters[drillDownType];

      const isFreeTextOnly = !prevIsFreeTextOnly;

      return flow([set(`filters[${drillDownType}].mutualFilters.isFreeTextOnly`, isFreeTextOnly)])(
        state,
      );
    }
    // listen for location change and in case this is a
    // deep link (location.state.isDeepLink || router.isFirstRendering)
    // then update filters with search params
    case LOCATION_CHANGE: {
      const { location, isFirstRendering, action } = payload;

      const searchFilters = buildSearchFiltersOnLocationChange(
        location,
        action,
        isFirstRendering,
        RoutePath.Findings,
      );

      if (searchFilters) {
        const { filters } = state;

        const newFilters = buildFiltersFromSearchParams(searchFilters, filters);

        return flow([set(`filters`, newFilters)])(state);
      }

      return state;
    }

    default:
      return state;
  }
};

export default findingsReducer;
