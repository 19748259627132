import { FC } from 'react';
import { connect } from 'react-redux';

import { LifecycleOptions, ResponseTypes, IRootState, PopupIds } from 'types';
import PreviewHeader from 'components/myOpportunities/cardPreview/cardDetails/previewHeader/PreviewHeader';
import Popup from 'components/common/popup/Popup';
import './opportunityPopup.scss';
import PreviewContent from 'components/myOpportunities/cardPreview/cardDetails/PreviewContent';
import FindingsSelector from 'store/findings/findingsSelector';
import OpenOpportunitiesSelector from 'store/openOpportunities/openOpportunitiesSelector';
import { getProductCategories } from 'store/helpers/drillDown';
import { mapOpportunityPlanogramAttributes } from 'store/myOpportunities/myOpportunitiesHelper';
import { IOpportunityPopup } from './types';

const OpportunityPopup: FC<IOpportunityPopup> = ({
  productCategories,
  planogramAttributes,
  selectedOpportunity,
}) => {
  if (!selectedOpportunity) {
    return null;
  }

  const {
    lifecycle,
    selectedReason,
    productImage,
    storeName,
    productName,
    lastSalesValuesTotal,
    lastUnitsValuesTotal,
    lastSimilarSalesValues,
    lastSimilarUnitsValues,
    lastSalesValues,
    lastUnitsValues,
    targetSales,
    lastSimilarSalesValuesTotal,
    lastSimilarUnitsValuesTotal,
    productSkusAndSizes,
    action,
    inventoryLatestUpdateDate,
    inventoryLevel,
    potentialUnitsSales,
    potentialRevenue,
    targetUnits,
    flavour,
  } = selectedOpportunity;

  const isExpired = lifecycle === LifecycleOptions.EXPIRED;

  const isNoIssueFound = selectedReason?.type === ResponseTypes.NO_ISSUE_FOUND;

  const inventoryUpdate = {
    date: inventoryLatestUpdateDate || 0,
    value: inventoryLevel || 0,
  };

  return (
    <Popup popupClassName="opportunity-popup-container" withOverlay>
      <Popup.Header>
        <PreviewHeader
          action={action}
          isExpired={isExpired}
          isNoIssueFound={isNoIssueFound}
          productName={productName}
          productSku={productSkusAndSizes}
          storeName={storeName}
          productImage={productImage}
        />
      </Popup.Header>
      <Popup.Content>
        <PreviewContent
          planogramAttributes={planogramAttributes}
          productSkusAndSizes={productSkusAndSizes}
          lastSimilarSalesValuesTotal={lastSimilarSalesValuesTotal}
          lastSimilarUnitsValuesTotal={lastSimilarUnitsValuesTotal}
          lastSalesValuesTotal={lastSalesValuesTotal}
          lastUnitsValuesTotal={lastUnitsValuesTotal}
          targetSales={targetSales}
          lastSalesValues={lastSalesValues}
          lastUnitsValues={lastUnitsValues}
          lastSimilarSalesValues={lastSimilarSalesValues}
          lastSimilarUnitsValues={lastSimilarUnitsValues}
          inventoryUpdate={inventoryUpdate}
          potentialUnits={potentialUnitsSales}
          potentialSales={potentialRevenue}
          targetUnits={targetUnits}
          productCategories={productCategories}
          flavour={flavour}
          showReason={!isExpired}
        />
      </Popup.Content>
    </Popup>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { id: popupId } = state.popup;

  const {
    general: { currency },
    productCategories: settingsProductCategories,
    planogramAttributes: settingsPlanogramAttributes,
    featureFlags: { showTargetSales, showInventoryData },
    isRtl,
  } = state.settings;

  const selectedOpportunity =
    popupId === PopupIds.FindingsOpportunity
      ? FindingsSelector.getSelectedOpportunity(state)
      : OpenOpportunitiesSelector.getSelectedOpportunity(state);

  const productCategories = getProductCategories(selectedOpportunity, settingsProductCategories);

  const planogramAttributes = mapOpportunityPlanogramAttributes(
    settingsPlanogramAttributes,
    selectedOpportunity?.planogramAttributes,
  );

  return {
    currency,
    isRtl,
    showTargetSales,
    showInventoryData,
    productCategories,
    selectedOpportunity,
    planogramAttributes,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityPopup);
