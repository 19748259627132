import cn from 'clsx';
import { FC } from 'react';
import './Alert.scss';

export interface IAlertButton {
  icon?: Function;
  text: string;
  onClick?: () => void;
  className?: string;
}

/**
 * Primary UI component for user interaction
 */
export const AlertButton: FC<IAlertButton> = ({
  className,
  icon: Icon,
  text,
  onClick,
  ...props
}) => {
  return (
    <button type="button" className={cn(className, `alert-button`)} onClick={onClick} {...props}>
      {Icon && <Icon />}
      {text && text}
    </button>
  );
};
