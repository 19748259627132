import { DrillDownCategoriesTypes, IRootState, ISettingsModel } from 'types';
import { getObjectKeys, getObjectValues } from 'utils';
import { httpResponseStatus } from 'store/api/APIConstants';

export const isSettingsLoadedSuccessfully = (state: IRootState) => {
  const { serverResponseStatus } = state.settings;

  return serverResponseStatus === httpResponseStatus.Success;
};

const getCategoriesByType = (
  categories: object,
  hierarchy: object,
  categoryType: DrillDownCategoriesTypes,
) => {
  const categoryKeys = getObjectKeys(categories);

  const hierarchyValues = getObjectValues(hierarchy);

  // filter hierarchies from categories
  const filteredCategoryKeys = categoryKeys.filter(
    (categoryKey) => !hierarchyValues.find((Hierarchy) => Hierarchy === categories[categoryKey]),
  );

  return filteredCategoryKeys.map((key) => {
    const label = categories[key];

    // category endpoint format
    // e.g. PRODUCT_CATEGORY_ID_1
    const value = `${categoryType}_ID_${key}`;

    const type = categoryType;

    return { label, value, type };
  });
};

export const getOtherDrillDownCategories = (settings: ISettingsModel) => {
  if (!settings) {
    return {
      storeCategories: [],
      productCategories: [],
    };
  }

  const { productCategories, storeCategories, productHierarchy, storeHierarchy } = settings;

  // Get all product categories
  const mappedProductCategories = getCategoriesByType(
    productCategories,
    productHierarchy,
    DrillDownCategoriesTypes.ProductCategory,
  );

  // Get all store categories
  const mappedStoreCategories = getCategoriesByType(
    storeCategories,
    storeHierarchy,
    DrillDownCategoriesTypes.StoreCategory,
  );

  return {
    storeCategories: mappedStoreCategories,
    productCategories: mappedProductCategories,
  };
};
