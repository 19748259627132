import { flow, set } from 'lodash/fp';

import i18n from 'i18n/i18n';
import { getSettings } from 'store/api/apiParser';
import SettingsActionTypes from 'store/settings/settingsActionTypes';
import { FlavourTypes, IActionType, ISettingsModel, CurrencySymbolOptions } from 'types';
import { httpResponseStatus } from 'store/api/APIConstants';
import { parseObjToOrderedList } from 'utils';
import featureFlagsService from 'services/permissions/featureFlagsService';

export const initialState: ISettingsModel = {
  featureFlags: {
    showInventoryData: false,
    showLeaderBoard: false,
    showPotentialRevenue: false,
    showPotentialUnits: false,
    showTargetSales: false,
    showTargetUnits: false,
    isLiveInventoryEnabled: true,
    isAdditionalProductInfoEnabled: false,
    isInAppMessagingEnabled: true,
    showOnboardingVideoOnFirstTimeLogin: true,
    showProductImages: false,
    showResponseImpactOnSales: false,
    isVideoKnowledgeCenterEnabled: true,
    showTaskUnitsGraph: false,
  },
  general: {
    additionalProductInfoAddress: null,
    currency: CurrencySymbolOptions.EUR,
    productImagesMetadata: [],
  },
  taskExpiryDays: {
    availability: 100,
    increaseOrder: 28,
    introduceToAssortment: 28,
    order: 28,
    visibility: 28,
  },
  taskItemsHeaders: [{ value: 'PRODUCT_SKU', title: 'SKU' }],
  firstDayOfWeek: 'MONDAY',
  flavour: FlavourTypes.ASSORTMENT,
  messagingMetadata: {
    roleRestrictions: { roleToRoles: {}, roleToUsers: {}, roleRestrictionsExists: false },
    region: 'EU',
  },
  notifications: { pushNotificationText: 'New tasks were added', pushNotificationSound: 'default' },
  planogramAttributes: {},
  productCategories: [],
  productHierarchy: { 1: 'Hier1', 2: 'Hier2', 3: 'Hier3', 4: 'Hier4' },
  storeCategories: [],
  storeHierarchy: {},
  productHierarchyDepth: 0,
  storeHierarchyDepth: 0,
  isRtl: false,
  language: 'en-US',
  serverResponseStatus: null,
};

const settingsReducer = (state: ISettingsModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case SettingsActionTypes.updateLanguage: {
      const { chosenLanguage } = payload;

      const isRtl = i18n.dir(chosenLanguage) === 'rtl';

      return flow([set('language', chosenLanguage), set('isRtl', isRtl)])(state);
    }

    case SettingsActionTypes.updatePassword.SUCCESS: {
      return flow([set('serverResponseStatus', httpResponseStatus.Success)])(state);
    }

    case SettingsActionTypes.updatePassword.FAILURE: {
      return flow([set('serverResponseStatus', httpResponseStatus.Failure)])(state);
    }

    case SettingsActionTypes.resetServerResponseStatus: {
      return flow([set('serverResponseStatus', null)])(state);
    }

    case SettingsActionTypes.getSettings.SUCCESS: {
      const settings = getSettings(payload);

      const productCategories = parseObjToOrderedList(settings.productCategories);

      const storeCategories = parseObjToOrderedList(settings.storeCategories);

      featureFlagsService.setMetaData(settings);

      return flow([
        set('featureFlags', settings.featureFlags),
        set('general', settings.general),
        set('taskItemsHeaders', settings.taskItemsHeaders),
        set('taskExpiryDays', settings.taskExpiryDays),
        set('firstDayOfWeek', settings.firstDayOfWeek),
        set('flavour', settings.flavour),
        set('messagingMetadata', settings.messagingMetadata),
        set('notifications', settings.notifications),
        set('productCategories', productCategories),
        set('productHierarchy', settings.productHierarchy),
        set('storeCategories', storeCategories),
        set('storeHierarchy', settings.storeHierarchy),
        set('storeHierarchyDepth', settings.storeHierarchyDepth),
        set('productHierarchyDepth', settings.productHierarchyDepth),
        set('planogramAttributes', settings.planogramAttributes),
        set('serverResponseStatus', httpResponseStatus.Success),
      ])(state);
    }

    default:
      return state;
  }
};

export default settingsReducer;
