export const isEmptyArray = (arr?: Array<any> | null) => !!arr && arr.length === 0;

export const isEmptyOrNull = (arr?: Array<any>) => !arr || arr.length === 0;

export const parseObjToOrderedList = (obj: { [key: number]: string }): Array<string> => {
  const arr: Array<string> = [];
  Object.entries(obj).forEach(([key, value]) => {
    arr[key] = value;
  });

  return arr;
};

export const transformToArray = (content: any) => (content instanceof Array ? content : [content]);
