import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `OpenOpportunitiesActionTypes.${action}`;

const getOpenOpportunities = APIConstants.createAPIRequestAction(getPrefix('getOpenOpportunities'));

const getStoreBreakdown = APIConstants.createAPIRequestAction(getPrefix('getStoreBreakdown'));

const getFilteredDrillDownItems = APIConstants.createAPIRequestAction(
  getPrefix('getFilteredDrillDownItems'),
);

const getOpportunities = APIConstants.createAPIRequestAction(getPrefix('getOpportunities'));

const getFindingsReasons = APIConstants.createAPIRequestAction(getPrefix('getFindingsReasons'));

const addHierarchyFilter = getPrefix('addHierarchyFilter');

const sliceHierarchyFilter = getPrefix('sliceHierarchyFilter');

const updateSearchFilter = getPrefix('updateSearchFilter');

const getDrillDownItems = getPrefix('getDrillDownItems');

const selectCard = getPrefix('selectCard');

const changeDateRange = getPrefix('changeDateRange');

const selectTab = getPrefix('selectTab');

const selectDropDownOption = getPrefix('selectDropDownOption');

const toggleSortDirection = getPrefix('toggleSortDirection');

const clearFilters = getPrefix('clearFilters');

const OpenOpportunitiesActionTypes = {
  getOpenOpportunities,
  getStoreBreakdown,
  getDrillDownItems,
  getFilteredDrillDownItems,
  selectCard,
  changeDateRange,
  selectTab,
  selectDropDownOption,
  updateSearchFilter,
  addHierarchyFilter,
  sliceHierarchyFilter,
  toggleSortDirection,
  clearFilters,
  getOpportunities,
  getFindingsReasons,
};

export default OpenOpportunitiesActionTypes;
