import APIConstants from 'store/api/APIConstants';

const getPrefix = (action: string): string => `TrendsActionTypes.${action}`;

const getHierarchyDrillDownDataHitRate = APIConstants.createAPIRequestAction(
  getPrefix('getHierarchyDrillDownDataHitRate'),
);

const getStoresHitRate = APIConstants.createAPIRequestAction(getPrefix('getStoresHitRate'));

const getProductsHitRate = APIConstants.createAPIRequestAction(getPrefix('getProductsHitRate'));

const getHierarchyDrillDownDataResponseRate = APIConstants.createAPIRequestAction(
  getPrefix('getHierarchyDrillDownDataResponseRate'),
);

const getStoresResponseRate = APIConstants.createAPIRequestAction(
  getPrefix('getStoresResponseRate'),
);

const getProductResponseRate = APIConstants.createAPIRequestAction(
  getPrefix('getProductResponseRate'),
);

const getHierarchyDrillDownDataSalesIncrease = APIConstants.createAPIRequestAction(
  getPrefix('getHierarchyDrillDownDataSalesIncrease'),
);

const getCategoryDrillDownDataSalesIncrease = APIConstants.createAPIRequestAction(
  getPrefix('getCategoryDrillDownDataSalesIncrease'),
);

const getStoresSalesIncrease = APIConstants.createAPIRequestAction(
  getPrefix('getStoresSalesIncrease'),
);

const getProductSalesIncrease = APIConstants.createAPIRequestAction(
  getPrefix('getProductSalesIncrease'),
);

const getSalesIncreaseHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getSalesIncreaseHistogram'),
);

const geSalesIncreaseChainWideHistogram = APIConstants.createAPIRequestAction(
  getPrefix('geSalesIncreaseChainWideHistogram'),
);

const getHitRateHistogram = APIConstants.createAPIRequestAction(getPrefix('getHitRateHistogram'));

const getHitRateChainWideHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getHitRateChainWideHistogram'),
);

const getResponseRateHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getResponseRateHistogram'),
);

const getResponseRateChainWideHistogram = APIConstants.createAPIRequestAction(
  getPrefix('getResponseRateChainWideHistogram'),
);

const sliceHierarchyPath = getPrefix('onHierarchyPathClick');

const onDrillDownSearch = getPrefix('onDrillDownSearch');

const toggleExpended = getPrefix('toggleExpended');

const toggleDirection = getPrefix('toggleDirection');

// adding a filter to drill down
const addFilter = getPrefix('addFilter');

const removeDrillDownFilter = getPrefix('removeDrillDownFilter');

const setOtherDrillDownSelectedCategory = getPrefix('setOtherDrillDownSelectedCategory');

const getDrillDown = getPrefix('getDrillDown');

const setSelectedMonth = getPrefix('setSelectedMonth');

const getHistogram = getPrefix('getHistogram');

const setSelectedTab = getPrefix('setSelectedTab');

const setDateInterval = getPrefix('setDateInterval');

const TrendsActionTypes = {
  sliceHierarchyPath,
  toggleExpended,
  removeDrillDownFilter,
  onDrillDownSearch,
  addFilter,
  setOtherDrillDownSelectedCategory,
  getProductsHitRate,
  getStoresHitRate,
  getHierarchyDrillDownDataHitRate,
  getDrillDown,
  toggleDirection,
  getStoresResponseRate,
  getProductResponseRate,
  getHierarchyDrillDownDataResponseRate,
  getHierarchyDrillDownDataSalesIncrease,
  getProductSalesIncrease,
  getStoresSalesIncrease,
  getCategoryDrillDownDataSalesIncrease,
  setSelectedTab,
  getHistogram,
  getResponseRateHistogram,
  getHitRateHistogram,
  getSalesIncreaseHistogram,
  geSalesIncreaseChainWideHistogram,
  getHitRateChainWideHistogram,
  getResponseRateChainWideHistogram,
  setDateInterval,
  setSelectedMonth,
};

export default TrendsActionTypes;
