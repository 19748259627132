import { Context, PureComponent } from 'react';
import isEqual from 'react-fast-compare';

import { IAuthContextProps } from './context';
import {
  AuthClient,
  AuthClientError,
  AuthClientEvent,
  AuthClientInitOptions,
  AuthProviderProps,
  AuthProviderState,
  IAuthUserInfo,
  IUserTenantRelations,
  KeycloakTokenParsedExtended,
  MIN_VALIDITY,
} from './types';
import FetchService from 'services/fetchService/FetchService';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsAuthEventsId } from 'services/analytics/AnalyticsTypes';
import { BIND_WITH_SSO, UPS_REST_PATH } from 'store/api/APIConstants';

const isUserAuthenticated = (authClient: AuthClient) => !!authClient.idToken && !!authClient.token;

/**
 * Create an AuthProvider component to wrap a React app with, it will take care of common AuthClient
 * lifecycle handling (such as initialization and token refresh).
 *
 * @param AuthContext the Auth context to be used by the created AuthProvider
 *
 * @returns the AuthProvider component
 */
export function createAuthProvider<T extends AuthClient>(
  AuthContext: Context<IAuthContextProps<T>>,
) {
  const defaultInitOptions: AuthClientInitOptions = {
    onLoad: 'login-required',
  };

  const initialState: AuthProviderState = {
    initialized: false,
    isAuthenticated: false,
    isLoading: true,
    isUserInfoLoaded: false,
  };

  return class KeycloakProvider extends PureComponent<AuthProviderProps<T>, AuthProviderState> {
    state = {
      ...initialState,
    };

    componentDidMount() {
      this.init();
    }

    componentDidUpdate({
      authClient: prevAuthClient,
      initOptions: prevInitOptions,
    }: AuthProviderProps<T>) {
      const { initOptions, authClient } = this.props;

      if (authClient !== prevAuthClient || !isEqual(initOptions, prevInitOptions)) {
        // De-init previous AuthClient instance
        prevAuthClient!.onReady = undefined;
        prevAuthClient!.onAuthSuccess = undefined;
        prevAuthClient!.onAuthError = undefined;
        prevAuthClient!.onAuthRefreshSuccess = undefined;
        prevAuthClient!.onAuthRefreshError = undefined;
        prevAuthClient!.onAuthLogout = undefined;
        prevAuthClient!.onTokenExpired = undefined;

        // Reset state
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ ...initialState });
        // Init new AuthClient instance
        this.init();
      }
    }

    getUserTenant = (
      tokenParsed: KeycloakTokenParsedExtended | undefined,
    ): IUserTenantRelations => {
      let userTenant: IUserTenantRelations = {
        uid: '',
        pid: '',
        cid: '',
      };

      try {
        if (tokenParsed && tokenParsed.utr) {
          userTenant = JSON.parse(tokenParsed.utr);
        }
      } catch (e) {
        AnalyticSrv.sendAuthEvent(AnalyticsAuthEventsId.USER_TENANT_FAILURE);
      }

      return userTenant;
    };

    hasUTR = (userTenant: IUserTenantRelations): boolean =>
      !!(userTenant.cid && userTenant.pid && userTenant.uid);

    syncUTRAndResolveUserInfo = () => {
      const { authClient } = this.props;
      AnalyticSrv.sendAuthEvent(AnalyticsAuthEventsId.BIND_WITH_SSO);
      FetchService.postUPSData(`${UPS_REST_PATH}${BIND_WITH_SSO}`)
        .then(() => {
          // eslint-disable-next-line no-console
          console.log('UpdateToken');
          authClient?.updateToken(MIN_VALIDITY).success(() => {
            AnalyticSrv.sendAuthEvent(AnalyticsAuthEventsId.AUTH_TOKEN_REFRESH);
          });
        })
        .catch(() => {
          AnalyticSrv.sendAuthEvent(AnalyticsAuthEventsId.BIND_WITH_SSO_FAILURE);
        });
    };

    init = () => {
      const { initOptions, authClient } = this.props;

      if (authClient === null) {
        return;
      }

      // Attach Keycloak listeners
      authClient.onReady = this.updateState('onReady');
      authClient.onAuthSuccess = this.updateState('onAuthSuccess');
      authClient.onAuthError = this.onError('onAuthError');
      authClient.onAuthRefreshSuccess = this.updateState('onAuthRefreshSuccess');
      authClient.onAuthRefreshError = this.onError('onAuthRefreshError');
      authClient.onAuthLogout = this.updateState('onAuthLogout');
      authClient.onTokenExpired = this.refreshToken('onTokenExpired');

      authClient
        .init({ ...defaultInitOptions, ...initOptions })
        .success(() => {
          AnalyticSrv.sendAuthEvent(AnalyticsAuthEventsId.AUTH_SUCCESS);
        })
        .error(this.onError('onInitError'));
    };

    onError = (event: AuthClientEvent) => (error?: AuthClientError) => {
      const { onEvent } = this.props;

      // Notify Events listener
      if (onEvent) {
        onEvent(event, error);
      }
    };

    updateState = (event: AuthClientEvent) => () => {
      const { authClient, onEvent, onTokens, isLoadingCheck, onUserInfoLoaded } = this.props;

      const {
        initialized: prevInitialized,
        isAuthenticated: prevAuthenticated,
        isLoading: prevLoading,
      } = this.state;

      // Notify Events listener
      if (onEvent) {
        onEvent(event);
      }

      // Check Loading state
      const isLoading = authClient && isLoadingCheck ? isLoadingCheck(authClient) : false;

      // Check if user is authenticated
      const isAuthenticated = !!authClient && isUserAuthenticated(authClient);

      // Avoid double-refresh if state hasn't changed
      if (!prevInitialized || isAuthenticated !== prevAuthenticated || isLoading !== prevLoading) {
        this.setState({
          initialized: true,
          isAuthenticated,
          isLoading,
        });
      }

      // Notify token listener, if any
      const { idToken, refreshToken, token, tokenParsed } = authClient!;

      if (onTokens && (event === 'onAuthSuccess' || event === 'onAuthRefreshSuccess')) {
        onTokens({
          idToken,
          refreshToken,
          token,
        });
      }

      const userTenant = this.getUserTenant(tokenParsed);

      // bind users tenant
      if (!this.hasUTR(userTenant)) {
        this.syncUTRAndResolveUserInfo();
      }

      try {
        if (onUserInfoLoaded && event === 'onAuthSuccess') {
          authClient!.loadUserInfo().success((userInfo) => {
            onUserInfoLoaded(userInfo as IAuthUserInfo, userTenant as IUserTenantRelations);
            this.setState({ isUserInfoLoaded: true });
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`provider error failed to parse user tenant data:`, err);
      }
    };

    refreshToken = (event: AuthClientEvent) => () => {
      const { autoRefreshToken, authClient, onEvent } = this.props;

      // Notify Events listener
      if (onEvent) {
        onEvent(event);
      }

      if (autoRefreshToken !== false) {
        // Refresh Keycloak token
        authClient!.updateToken(5);
      }
    };

    render() {
      const { children, authClient, LoadingComponent } = this.props;

      const { initialized, isLoading, isUserInfoLoaded } = this.state;

      if (!!LoadingComponent && (!initialized || isLoading)) {
        return LoadingComponent;
      }

      return (
        <AuthContext.Provider value={{ initialized, authClient: authClient!, isUserInfoLoaded }}>
          {children}
        </AuthContext.Provider>
      );
    }
  };
}

export default createAuthProvider;
