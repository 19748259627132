import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { IRootState, PopupIds } from 'types';
import './selectedReason.scss';
import { ISelectedReasonProps } from './types';
import { CheckMarkIcon } from 'components/Icons';
import FindingsSelector from 'store/findings/findingsSelector';

const SelectedReason: FC<ISelectedReasonProps> = ({ selectedReason }) => {
  const [t] = useTranslation();

  if (!selectedReason) {
    return null;
  }

  const { title, description, completedDate } = selectedReason;

  return (
    <div className="selected-reason">
      <div className="selected-reason-title">
        <span className="reason-date">
          {t('MY_OPPORTUNITIES.COMPLETED_DATE', { date: completedDate })}
        </span>
        <div className="check-mark-icon-wrapper">
          <CheckMarkIcon className="reason-completed-icon" />
        </div>
      </div>
      <div className="selected-reason-code">
        <span className="reason-title">{title}</span>
        <span className="reason-desc">{description}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { id: popupId } = state.popup;

  const selectedOpportunity =
    popupId === PopupIds.FindingsOpportunity
      ? FindingsSelector.getSelectedOpportunity(state)
      : null;

  const selectedReason = selectedOpportunity?.selectedReason || null;

  return {
    selectedReason,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedReason);
