import { createRef, FC, useState } from 'react';
import cn from 'clsx';

import { VideoPoster } from 'components/videoPoster';
import './videoPlayer.scss';
import AnalyticSrv from 'services/analytics/AnalyticSrv';

type VideoPlayerProps = {
  duration?: number;
  videoSrc: string;
  posterSrc: string;
  subtitlesSrc: string;
  autoPlay?: boolean;
  title: string;
};

export const VideoPlayer: FC<VideoPlayerProps> = ({
  duration,
  videoSrc,
  autoPlay,
  posterSrc,
  subtitlesSrc,
  title,
}) => {
  const [shouldHidePoster, setShouldHidePoster] = useState(false);

  const [isPaused, setIsPaused] = useState(true);

  const [startPlayingTime, setStartPlayingTime] = useState(0);

  const ref = createRef<HTMLVideoElement>();

  const onPlay = () => {
    if (ref.current) {
      ref.current.play();
      setShouldHidePoster(true);
      setIsPaused(false);
      setStartPlayingTime(Date.now);
    }
  };

  const onPause = () => {
    if (ref.current) {
      const timePlay = Math.round((Date.now() - startPlayingTime) / 1000);

      ref.current.pause();
      setIsPaused(true);

      AnalyticSrv.sendVideoPlayEvent(title, timePlay);
    }
  };

  const isVideoShowing = autoPlay || shouldHidePoster;

  return (
    <div className="video-player-container">
      <video
        src={videoSrc}
        className={cn([{ 'video-player-visible': isVideoShowing }])}
        ref={ref}
        autoPlay={autoPlay || !isPaused}
        controls
        onPause={onPause}
        controlsList="nodownload"
        disablePictureInPicture
        crossOrigin=""
      >
        <source src={videoSrc} type="video/mp4" />
        <track default kind="subtitles" src={subtitlesSrc} />
      </video>
      {!isVideoShowing && (
        <VideoPoster posterSrc={posterSrc} onClick={onPlay} duration={duration} />
      )}
    </div>
  );
};
