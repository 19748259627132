import { flow, set } from 'lodash/fp';
import { isEmpty } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  direction,
  DrillDownCategoriesTypes,
  DrillDownTypes,
  IActionType,
  IFilterItem,
  SelectedDateIntervals,
  TrendsTabOptions,
} from 'types';
import TrendsActionTypes from './trendsActionTypes';
import {
  getDrillDownListByEarning,
  getDrillDownListByHitRate,
  getDrillDownListByResponseRate,
  getHitRateHistogram,
  getProductCategoriesByEarning,
  getProductCategoriesByResponseRate,
  getProductsByEarning,
  getProductsByHitRate,
  getProductsByResponseRate,
  getResponseRateHistogram,
  getSalesIncreaseChainWideTotal,
  getSalesIncreaseHistogram,
  getStoreCategoriesByEarning,
  getStoreCategoriesByResponseRate,
  getStoresByEarning,
  getStoresByHitRate,
  getStoresByResponseRate,
  getUserScope,
} from 'store/api/apiParser';
import { getCurrentDate } from 'utils';
import { buildStateToPushPostDrillDownRequest } from 'store/helpers/drillDown';
import { ITrendsDrillDownFilters, ITrendsDrillDownModel, ITrendsModel } from './types';
import { RoutePath } from 'routes/types';
import { buildSearchFiltersOnLocationChange } from 'store/filters/filterHelper';
import { buildFiltersFromSearchParams } from './trendsHelper';
import AnalyticSrv from 'services/analytics/AnalyticSrv';
import { AnalyticsEventCategory } from 'services/analytics/AnalyticsTypes';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';

export const DropDownFiltersInitialState: ITrendsDrillDownFilters = {
  // Filters that effects only the specific drill down
  activeFilters: {
    orderDirection: direction.DESC,
    selectedCategory: undefined,
  },
  // holds the drillDown mutual/interactive data.
  // when a drillDown has changed and update the data under mutualFilter, its explicit.
  // meaning - effects the rest of the drillDowns as well, which also need to change and update.
  mutualFilters: {
    search: '',
    hierarchyIndex: 1,
  },
  nextPageOffset: 0,
  filters: [],
  hasMore: true,
  hierarchyPath: [],
};

const DropBoxInitialState: ITrendsDrillDownModel = {
  list: null,
  totalCount: 0,
  highestValue: 1,
  isLoading: false,
};

export const initialState: ITrendsModel = {
  [DrillDownTypes.Store]: DropBoxInitialState,
  [DrillDownTypes.Product]: DropBoxInitialState,
  [DrillDownTypes.Other]: DropBoxInitialState,
  hitRate: {
    total: 0,
    tooltip: null,
    data: null,
    chainWideData: [],
  },
  responseRate: {
    total: 0,
    tooltip: null,
    data: null,
    chainWideData: [],
  },
  salesIncrease: {
    totalValueMyStores: 0,
    totalValueChainWide: 0,
    data: null,
  },
  filters: {
    date: {
      type: SelectedDateIntervals.twelveMonthsId,
      from: getCurrentDate(),
      to: getCurrentDate(),
    },
    selectedMonth: null,
    selectedTab: TrendsTabOptions.Earning,
    expendedDrillDown: null,
    [DrillDownTypes.Store]: DropDownFiltersInitialState,
    [DrillDownTypes.Product]: DropDownFiltersInitialState,
    [DrillDownTypes.Other]: DropDownFiltersInitialState,
  },
};

const trendsReducer = (state: ITrendsModel = initialState, action: IActionType) => {
  if (!state) {
    return initialState;
  }

  const { type, payload, requestPayload } = action;

  switch (type) {
    case UserProfileActionTypes.getUserScope.SUCCESS: {
      const userProfile = getUserScope(payload);

      // set selected tab to response rate if sales increase is not displayed
      if (!userProfile.mayViewSalesIncrease) {
        return flow([set(`filters.selectedTab`, TrendsTabOptions.ResponseRate)])(state);
      }

      return state;
    }

    case TrendsActionTypes.getDrillDown: {
      const { drillDownType } = payload;

      return flow([set(`${drillDownType}.isLoading`, true)])(state);
    }

    case TrendsActionTypes.setSelectedTab: {
      const { selectedTab } = payload;

      const {
        selectedTab: prevSelectedTab,
        date: { type },
      } = state.filters;

      const stateToPush = [
        set(`filters.selectedTab`, selectedTab),
        set(`filters.selectedMonth`, null),
      ];

      const changeFromEarning =
        prevSelectedTab === TrendsTabOptions.Earning && selectedTab !== TrendsTabOptions.Earning;
      if (changeFromEarning && type === SelectedDateIntervals.all) {
        stateToPush.push(set(`filters.date.type`, SelectedDateIntervals.twelveMonthsId));
      }

      AnalyticSrv.sendSelectEvent(AnalyticsEventCategory.TAB, {
        tab_name: selectedTab === TrendsTabOptions.Earning ? 'Sales increase' : selectedTab,
      });

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.setSelectedMonth: {
      const { selectedMonth } = payload;

      return flow([set(`filters.selectedMonth`, selectedMonth)])(state);
    }

    case TrendsActionTypes.toggleDirection: {
      const { drillDownType } = payload;

      const { orderDirection: currentDirection } = state.filters[drillDownType].activeFilters;
      let updateDirection = direction.ASC;
      if (currentDirection === direction.ASC) {
        updateDirection = direction.DESC;
      }

      return flow([set(`filters[${drillDownType}].activeFilters.orderDirection`, updateDirection)])(
        state,
      );
    }

    case TrendsActionTypes.setDateInterval: {
      const { type, selectedDates } = payload;

      const stateToUpdate = [set('filters[date].type', type), set(`filters.selectedMonth`, null)];

      if (selectedDates) {
        const { from, to } = selectedDates;
        stateToUpdate.push(set('filters[date].from', from));
        stateToUpdate.push(set('filters[date].to', to));
      }

      AnalyticSrv.sendDateChangedEvent(type, AnalyticsEventCategory.DATE_CHANGED);

      return flow(stateToUpdate)(state);
    }

    case TrendsActionTypes.removeDrillDownFilter: {
      const {
        filter: { value, hierarchyName, drillDownType },
      } = payload;

      const { filters } = state.filters[drillDownType];

      // Remove the filter from array
      const updatedFilters = filters.filter(
        (filterItem: IFilterItem) =>
          !(filterItem.value === value && filterItem.hierarchyName === hierarchyName),
      );

      return flow([
        set(`filters[${drillDownType}].filters`, updatedFilters),
        set(`filters[${drillDownType}].hierarchyPath`, []),
        set(`filters[${drillDownType}].mutualFilters.hierarchyIndex`, 1),
      ])(state);
    }

    case TrendsActionTypes.addFilter: {
      const {
        filter: { name, value, hierarchyName, hierarchyValue, categoryType },
        drillDownType,
      } = payload;

      const {
        filters,
        hierarchyPath,
        mutualFilters: { hierarchyIndex },
      } = state.filters[drillDownType];

      const isFilterExist = filters.find(
        (filterItem: any) =>
          filterItem.hierarchyName === hierarchyName && filterItem.value === value,
      );

      const stateToPush = [];

      // Checking if the filter already exists
      if (!isFilterExist) {
        const currentFilter: IFilterItem = {
          name,
          value,
          hierarchyName,
          drillDownType,
        };

        const nextHierarchyIndex = hierarchyIndex + 1;

        if (hierarchyValue && categoryType) {
          currentFilter.categoryType = categoryType;
          currentFilter.hierarchyValue = hierarchyValue;
        }

        const updatedFilters: Array<IFilterItem> = [currentFilter];

        let updateHierarchyPath = [
          ...hierarchyPath,
          {
            name,
            value,
            hierarchyName,
          },
        ];

        // For all drill down only the last filter visible
        // for other all filters visible
        if (drillDownType === DrillDownTypes.Other) {
          updatedFilters.unshift(...filters);
          updateHierarchyPath = [];
        }
        stateToPush.push(set(`filters[${drillDownType}].filters`, updatedFilters));
        stateToPush.push(set(`filters[${drillDownType}].hierarchyPath`, updateHierarchyPath));
        stateToPush.push(
          set(`filters[${drillDownType}].mutualFilters.hierarchyIndex`, nextHierarchyIndex),
        );
      }

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.toggleExpended: {
      const { drillDownType } = payload;

      const { search } = state.filters[drillDownType].mutualFilters;

      const { expendedDrillDown } = state.filters;

      const { list } = state[drillDownType];

      const updateExpendedDrillDown = expendedDrillDown === drillDownType ? null : drillDownType;

      const stateToPush = [set(`filters[expendedDrillDown]`, updateExpendedDrillDown)];

      if (!isEmpty(search)) {
        stateToPush.push(set(`filters[${drillDownType}].mutualFilters.search`, ''));
      }
      // prevent jump on transitions
      if (!updateExpendedDrillDown) {
        // small list that contains max 3 items to prevent jump on transitions
        const tempList = list.slice(0, 3);
        stateToPush.push(set(`${drillDownType}.list`, tempList));
        stateToPush.push(set(`${drillDownType}.totalCount`, tempList.length));
      }

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.onDrillDownSearch: {
      const { search, drillDownType } = payload;

      return flow([set(`filters[${drillDownType}].mutualFilters.search`, search)])(state);
    }

    case TrendsActionTypes.sliceHierarchyPath: {
      const { hierarchyItemIndex, drillDownType } = payload;

      const { hierarchyPath } = state.filters[drillDownType];

      // get new update hierarchy path
      const updateHierarchyPath = hierarchyPath.slice(0, hierarchyItemIndex);

      let filters: any = [];

      // update filter to hold only the last hierarchy
      if (hierarchyItemIndex > 0) {
        filters = [hierarchyPath[hierarchyItemIndex - 1]];
      }

      return flow([
        set(`filters[${drillDownType}].hierarchyPath`, updateHierarchyPath),
        set(`filters[${drillDownType}].mutualFilters.hierarchyIndex`, hierarchyItemIndex + 1),
        set(`filters[${drillDownType}].filters`, filters),
      ])(state);
    }

    case TrendsActionTypes.setOtherDrillDownSelectedCategory: {
      const { value, label, id: type } = payload;

      const drillDownType = DrillDownTypes.Other;

      const stateToPush = [];

      const selectedCategory = { value, label, type };

      stateToPush.push(
        set(`filters[${drillDownType}].activeFilters.selectedCategory`, selectedCategory),
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getHierarchyDrillDownDataHitRate.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const isExpended = expendedDrillDown === drillDownType;

      const responseList = getDrillDownListByHitRate(rows);

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        responseList,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getStoresHitRate.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const isExpended = expendedDrillDown === drillDownType;

      const stores = getStoresByHitRate(rows);

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        stores,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getProductsHitRate.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const products = getProductsByHitRate(rows);

      const isExpended = expendedDrillDown === drillDownType;

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        products,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getHierarchyDrillDownDataResponseRate.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const {
        expendedDrillDown,
        other: {
          activeFilters: { selectedCategory },
        },
      } = state.filters;

      const isExpended = expendedDrillDown === drillDownType;

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      let responseList: any = [];

      const isStoreCategorySelected =
        selectedCategory?.type === DrillDownCategoriesTypes.StoreCategory;

      const isProductCategorySelected =
        selectedCategory?.type === DrillDownCategoriesTypes.ProductCategory;

      const isCategorySelected = isStoreCategorySelected || isProductCategorySelected;

      if (drillDownType === DrillDownTypes.Other && isCategorySelected) {
        // categories returned
        if (isStoreCategorySelected) {
          responseList = getStoreCategoriesByResponseRate(rows);
        } else {
          responseList = getProductCategoriesByResponseRate(rows);
        }
      } else {
        responseList = getDrillDownListByResponseRate(rows);
      }

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        responseList,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getStoresResponseRate.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const stores = getStoresByResponseRate(rows);

      const isExpended = expendedDrillDown === drillDownType;

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        stores,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getProductResponseRate.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const products = getProductsByResponseRate(rows);

      const isExpended = expendedDrillDown === drillDownType;

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        products,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getHierarchyDrillDownDataSalesIncrease.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const {
        rows,
        totalCount = 0,
        queryStats: { totalIncrease } = { totalIncrease: 0 },
      } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const isExpended = expendedDrillDown === drillDownType;

      const responseList = getDrillDownListByEarning(rows);

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        responseList,
        isExpended,
        totalCount,
        orderDirection,
      );

      stateToPush.push(set(`salesIncrease.totalValueMyStores`, totalIncrease));

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getCategoryDrillDownDataSalesIncrease.SUCCESS: {
      const { pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const drillDownType = DrillDownTypes.Other;

      const { list } = state[drillDownType];

      const {
        expendedDrillDown,
        [drillDownType]: {
          activeFilters: { selectedCategory },
        },
      } = state.filters;

      const isExpended = expendedDrillDown === drillDownType;

      let responseList: any = [];

      if (selectedCategory?.type === DrillDownCategoriesTypes.ProductCategory) {
        responseList = getProductCategoriesByEarning(rows);
      } else {
        responseList = getStoreCategoriesByEarning(rows);
      }

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        responseList,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getStoresSalesIncrease.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const { rows, totalCount = 0 } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const stores = getStoresByEarning(rows);

      const isExpended = expendedDrillDown === drillDownType;

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        stores,
        isExpended,
        totalCount,
        orderDirection,
      );

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getProductSalesIncrease.SUCCESS: {
      const { drillDownType, pageOffset } = requestPayload;

      const {
        rows,
        totalCount = 0,
        queryStats: { totalIncrease } = { totalIncrease: 0 },
      } = payload;

      const { list } = state[drillDownType];

      const { expendedDrillDown } = state.filters;

      const products = getProductsByEarning(rows);

      const isExpended = expendedDrillDown === drillDownType;

      const { orderDirection } = state.filters[drillDownType].activeFilters;

      const stateToPush = buildStateToPushPostDrillDownRequest(
        drillDownType,
        pageOffset,
        list,
        products,
        isExpended,
        totalCount,
        orderDirection,
      );

      stateToPush.push(set(`salesIncrease.totalValueMyStores`, totalIncrease));

      return flow(stateToPush)(state);
    }

    case TrendsActionTypes.getSalesIncreaseHistogram.SUCCESS: {
      const { rows } = payload;

      const { response, total } = getSalesIncreaseHistogram(rows);

      return flow([
        set(`salesIncrease.data`, response),
        set(`salesIncrease.totalValueMyStores`, total),
      ])(state);
    }

    case TrendsActionTypes.geSalesIncreaseChainWideHistogram.SUCCESS: {
      const { rows } = payload;

      const totalValue = getSalesIncreaseChainWideTotal(rows);

      return flow([set(`salesIncrease.totalValueChainWide`, totalValue)])(state);
    }

    case TrendsActionTypes.getHitRateHistogram.SUCCESS: {
      const { rows, queryStats: { totalHitRate } = { totalHitRate: 0 } } = payload;

      const list = getHitRateHistogram(rows);

      return flow([set(`hitRate.data`, list), set(`hitRate.total`, totalHitRate)])(state);
    }

    case TrendsActionTypes.getHitRateChainWideHistogram.SUCCESS: {
      const { rows } = payload;

      const list = getHitRateHistogram(rows);

      return flow([set(`hitRate.chainWideData`, list)])(state);
    }

    case TrendsActionTypes.getResponseRateHistogram.SUCCESS: {
      const { rows, queryStats: { totalResponseRate } = { totalResponseRate: 0 } } = payload;

      const list = getResponseRateHistogram(rows);

      return flow([set(`responseRate.data`, list), set(`responseRate.total`, totalResponseRate)])(
        state,
      );
    }

    case TrendsActionTypes.getResponseRateChainWideHistogram.SUCCESS: {
      const { rows } = payload;

      const list = getResponseRateHistogram(rows);

      return flow([set(`responseRate.chainWideData`, list)])(state);
    }

    // listen for location change and in case this is a
    // deep link (location.state.isDeepLink || router.isFirstRendering)
    // then update filters with search params
    case LOCATION_CHANGE: {
      const { location, isFirstRendering, action } = payload;

      const searchFilters = buildSearchFiltersOnLocationChange(
        location,
        action,
        isFirstRendering,
        RoutePath.Trends,
      );

      if (searchFilters) {
        const { filters } = state;

        const newFilters = buildFiltersFromSearchParams(searchFilters, filters);

        return flow([set(`filters`, newFilters)])(state);
      }

      return state;
    }

    default:
      return state;
  }
};

export default trendsReducer;
