import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton, FlavourTypes, ISizesAndSkusSection } from 'types';
import Category from './Category';

const SizesAndSkusSection: FC<ISizesAndSkusSection> = ({
  isLiveInventoryEnabled,
  productSkusAndSizes,
  withSeparator,
  onLiveInventoryClick,
  flavour,
}) => {
  const [t] = useTranslation();

  const isFashion = flavour === FlavourTypes.FASHION;

  const showSkuColumn = !isFashion && productSkusAndSizes[0].size;

  const sizesAndSkusList = (
    <div className="sizes-list-wrapper">
      <table>
        <thead>
          <tr>
            <th>{t('MY_OPPORTUNITIES.SIZE')}</th>
            {showSkuColumn && <th>{t('MY_OPPORTUNITIES.SKU')}</th>}
          </tr>
        </thead>
        <tbody>
          {productSkusAndSizes.map((item) => (
            <tr key={`${item.sku}_${item.size}`}>
              <td>{item.size ? item.size : '-'}</td>
              {showSkuColumn && <td>{item.sku}</td>}
            </tr>
          ))}
        </tbody>
      </table>

      {isLiveInventoryEnabled && (
        <ActionButton
          className="show-inventory-btn"
          text={t('MY_OPPORTUNITIES.SHOW_INVENTORY')}
          onClick={onLiveInventoryClick}
        />
      )}
    </div>
  );

  return (
    <div className="inventory-wrapper">
      <Category withSeparator={withSeparator} name={t('COMMON.PRODUCT')} value={sizesAndSkusList} />
    </div>
  );
};

export default SizesAndSkusSection;
