import { IUserProfileModel, UserRole } from 'store/types';

/**
 * Permissions Service
 */
class PermissionsService {
  constructor() {
    this.userProfile = undefined;
  }

  private userProfile: IUserProfileModel | undefined;

  public isSingleStoreManager() {
    return !!(this.userProfile?.stores && this.userProfile.stores.length === 1);
  }

  public setUserProfile(userProfile: IUserProfileModel) {
    this.userProfile = userProfile;
  }

  public isStoreManager() {
    return this.userProfile?.role === UserRole.STORE_MANAGER;
  }

  public mayViewOpportunities() {
    return this.userProfile?.mayViewOpportunities === true;
  }

  public mayViewDashboards() {
    return this.userProfile?.mayViewDashboard === true;
  }

  public mayViewEarnings() {
    return this.userProfile?.mayViewSalesIncrease === true;
  }
}

const permissionsService = new PermissionsService();

export default permissionsService;
