import { FC } from 'react';
import Lottie from 'react-lottie-player';
import cn from 'clsx';

import { IAnimationProps, mapAnimationTypeToJson } from 'components/animation/types';
import './animations.scss';

export const ProgressAnimation: FC<IAnimationProps> = ({
  animationType,
  className,
  isLoading = true,
  animationStyle = { width: 80, height: 80 },
}) => {
  const animationData = mapAnimationTypeToJson[animationType];

  return (
    <>
      {isLoading && (
        <div className={cn('progress-animation', className)}>
          <Lottie loop play style={animationStyle} animationData={animationData} />
        </div>
      )}
    </>
  );
};
