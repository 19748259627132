import { IAuthUserInfo, IUserTenantRelations } from 'services/auth/keycloak/core';
import APIConstants, { HttpMethod } from 'store/api/APIConstants';
import UserProfileActionTypes from 'store/userProfile/userProfileActionTypes';

export const getUserScope = () => ({
  type: APIConstants.API_REQUEST,
  payload: {},
  url: `/v1/users/scope`,
  meta: {
    method: HttpMethod.GET,
  },
  baseAction: UserProfileActionTypes.getUserScope,
});

export const setUserInfo = (userInfo: IAuthUserInfo, userTenant: IUserTenantRelations) => ({
  type: UserProfileActionTypes.setUserInfo,
  payload: { userInfo, userTenant },
});

export const submitContactUs = (formData: any) => ({
  type: APIConstants.API_REQUEST,
  payload: { formData },
  url: '/enformed/3jou9ew0',
  meta: {
    formData: true,
    method: HttpMethod.POST,
    body: {
      ...formData,
    },
  },
  baseAction: UserProfileActionTypes.submitContactUs,
});

export const resetServerResponseStatus = () => ({
  type: UserProfileActionTypes.resetServerResponseStatus,
  payload: {},
});

const UserProfileActions = {
  getUserScope,
  setUserInfo,
  submitContactUs,
  resetServerResponseStatus,
};

export default UserProfileActions;
